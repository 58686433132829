import { useToast } from "app/hooks/Toast/useToast";
import AuditGroupChecklistItemInstanceService from "app/services/05-QUA/AuditGroupChecklistItemInstanceService";
import AuditInstanceService from "app/services/05-QUA/AuditInstanceService";
import { AuditGroupCheckListItemInstanceValues } from "app/shared/types/AuditInstanceTypes";
import { TranslationErrors, TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuditGroupCheckAllValues } from "../../../types";
import { AuditGroupCheckListItemInstanceListDto } from "app/dtos/05-QUA/AuditInstance/AuditGroupCheckListItemInstanceListDto";
import { AuditGroupCheckListItemInstanceIssueDto } from "app/dtos/05-QUA/AuditInstance/AuditGroupCheckListItemInstanceIssueDto";

type IUseAuditChecklistModal = {
    onChange: (id: number, selectedValue: AuditGroupCheckListItemInstanceValues, fk_task: number | null) => void;
    onClose: () => void;
    onComplete: (checklistId: number) => void;
    onCheckAllChecklist: (value: AuditGroupCheckListItemInstanceValues) => void;
    checklistId: number;
};

export const useAuditChecklistModal = ({
    onChange,
    onClose,
    onComplete,
    checklistId,
    onCheckAllChecklist,
}: IUseAuditChecklistModal) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();

    const [checklistItems, setChecklistItems] = useState<AuditGroupCheckListItemInstanceListDto[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

    const [selectedChecklistValue, setSelectedChecklistValue] = useState<AuditGroupCheckAllValues | null>(null);
    const [isCheckAllModalOpen, setIsCheckAllModalOpen] = useState<boolean>(false);

    const handleCheckListItemchange = async (
        itemId: number,
        selectedValue: AuditGroupCheckListItemInstanceValues,
        fK_Task: number | null
    ) => {
        const selectedChecklist = checklistItems.find(({ id }) => id === itemId);

        if (!selectedChecklist) return;

        setChecklistItems((prev) =>
            prev.map((item) =>
                item.id === selectedChecklist.id
                    ? {
                          ...item,
                          value: selectedValue,
                          fK_Task,
                          issue: item.value === "KO" && selectedValue !== "KO" ? null : item.issue,
                      }
                    : { ...item }
            )
        );

        onChange(itemId, selectedValue, fK_Task);

        if (selectedValue === "KO") setIsLoading(true);

        const { status } = await AuditInstanceService.CompleteAuditChecklistItem(itemId, { value: selectedValue });

        if (!status()) {
            handleToast({
                title: t(TranslationModals.AUDIT_CHECKLIST_ITEM_FAILED_UPDATE),
                variant: "danger",
                type: "alert",
            });
            setChecklistItems((prev) =>
                prev.map((item) => (item.id === selectedChecklist.id ? selectedChecklist : item))
            );

            onChange(itemId, selectedChecklist.value, fK_Task);
            return;
        }

        if (selectedValue === "KO") getData();
    };

    const handleSubmit = async () => {
        const isAllItemsCheckedAsNA = checklistItems.every(({ value }) => value === "NA");
        if (isAllItemsCheckedAsNA) {
            await handleComplete();
            onComplete(checklistId);
            return;
        }

        const isItemsChecked = checklistItems.some(({ value }) => value === "KO" || value === "OK");
        if (!isItemsChecked) {
            setIsCheckAllModalOpen(true);
            return;
        }
        const isAllItemsChecked = checklistItems.every(({ value }) => value !== "");

        if (!isAllItemsChecked) {
            handleToast({
                title: t(TranslationErrors.AUDIT_CHECKLIST_ERROR_FORM_VALUES),
                variant: "danger",
                type: "alert",
            });
            return;
        }
        await handleComplete();
        onComplete(checklistId);
    };

    const handleComplete = async () => {
        setIsSubmiting(true);
        setIsCheckAllModalOpen(false);
        const { status } = await AuditInstanceService.CompleteAuditChecklist(checklistId);

        if (!status()) {
            handleToast({
                title: t(TranslationModals.AUDIT_CHECKLIST_FAILED_COMPLETE),
                variant: "danger",
                type: "alert",
            });
            setIsSubmiting(false);
            return;
        }
        handleToast({
            title: t(TranslationModals.AUDIT_CHECKLIST_COMPLETED),
            variant: "success",
            type: "alert",
        });
        setIsSubmiting(true);
    };

    const getData = async () => {
        setIsLoading(true);
        setChecklistItems([]);
        const { data, status } = await AuditGroupChecklistItemInstanceService.GetData({
            extraParams: `ChecklistId=${checklistId}`,
        });

        if (!status()) {
            handleToast({
                title: t(TranslationKeys.AUDIT_CHECKLIST_INSTANC_NOT_EXIST),
                variant: "danger",
                type: "alert",
            });
            setIsLoading(false);
            onClose();
            return;
        }

        setChecklistItems(data.list);
        setIsLoading(false);
    };

    const handleCheckAllOpenModal = (value: AuditGroupCheckAllValues) => {
        setSelectedChecklistValue(value);
        setIsCheckAllModalOpen(true);
    };

    const handleCheckAll = async () => {
        if (selectedChecklistValue == null) return;
        setIsCheckAllModalOpen(false);
        setIsSubmiting(true);
        const { status } = await AuditInstanceService.CompleteAuditChecklistMultiple(
            checklistId,
            selectedChecklistValue
        );
        if (!status()) {
            handleToast({
                title: t(TranslationModals.AUDIT_CHECKLIST_FAILED_COMPLETE),
                variant: "danger",
                type: "alert",
            });

            return;
        }
        onCheckAllChecklist(selectedChecklistValue);
        await handleComplete();
        setSelectedChecklistValue(null);
    };

    useEffect(() => {
        getData();
    }, []);

    return {
        checklistItems,
        isLoading,
        isSubmiting,
        handleCheckListItemchange,
        handleSubmit,
        selectedChecklistValue,
        isCheckAllModalOpen,
        handleCheckAllOpenModal,
        handleCheckAll,
        setIsCheckAllModalOpen,
    };
};
