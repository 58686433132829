import { ApiPaths } from "app/shared/Constants";
import FetchService from "../Fetch/FetchService";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { AuditGroupCheckListItemInstanceListDto } from "app/dtos/05-QUA/AuditInstance/AuditGroupCheckListItemInstanceListDto";

export default class AuditGroupChecklistItemInstanceService {
    // GET a list of AuditGroupCheckListItemModel
    public static async GetData(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<AuditGroupCheckListItemInstanceListDto>>({
            url: `${ApiPaths.QUA_API}/AuditGroupChecklistItemInstance`,
            paginationParams: params,
        });
    }
}
