import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { ColumnsType } from "app/components_v2/Table/types";
import { faCirclePlus, faMagnifyingGlass, faPen } from "@fortawesome/pro-regular-svg-icons";
import { faQrcode } from "@fortawesome/pro-light-svg-icons";
import { hasPermissionToAdd, hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { PaginationDefaults, PrivatePaths, SecScreen } from "app/shared/Constants";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { TableInputText } from "app/components_v2/Table/TableInputText/TableInputText";
import { TableSelectCompany } from "app/components_v2/Table/TableSelectCompany/TableSelectCompany";
import {
    TranslationCommon,
    TranslationKeys,
    TranslationTitles,
    WorkingPositionTranslations,
} from "app/translation/translationKeys";
import { useNavigate } from "react-router-dom";
import { useSession, useTitle } from "app/hooks";
import { useTranslation } from "react-i18next";
import { WorkingPositionGridMobileBody } from "./WorkingPositionGridMobileBody/WorkingPositionGridMobileBody";
import { WorkingPositionModel } from "app/models/02-TAR/WorkingPosition/WorkingPositionModel";
import { PlanAPPCCCounters } from "app/pages/05-QUA/PlanAPPCC/components/PlanAPPCCCounters/PlanAPPCCCounters";
import { formatBigNumber } from "app/helpers/Utilities/number/formatBigNumbers";
import { useQrGrid } from "./useQrGrid";

export const WorkingPositionGrid = () => {
    const session = useSession();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const {
        currentTab,
        data,
        filterIcon,
        handleChangeSort,
        handleCompanyChange,
        handleExport,
        handlePageIndexChange,
        handleSubmitQuery,
        isLoading,
        onCompanyChange,
        onQueryChange,
        pageIndex,
        query,
        selectCompany,
        setShowSearchInput,
        showSearchInput,
        sortDirection,
        sortField,
        tabs,
        total,
    } = useQrGrid();

    useTitle(t(TranslationTitles.WORKING_POSITION_PAGE_TITLE));

    const columns: ColumnsType<WorkingPositionModel>[] = [
        {
            label: t(TranslationKeys.WORKING_POSITION_CODE_LABEL),
            dataIndex: "CustomerInstanceWorkingPosition.QrCode",
            render: ({ customerInstanceWorkingPosition }) => (
                <CellTitle title={customerInstanceWorkingPosition?.qrCode || "-"} />
            ),
            sortedType: "asc",
        },
        {
            label: t(TranslationKeys.WORKING_POSITION_NAME_LABEL),
            dataIndex: "name",
            render: (record) => <CellTitle title={record.name} />,
            sortedType: "default",
        },
        {
            label: t(TranslationKeys.WORKING_POSITION_DESCRIPTION_LABEL),
            dataIndex: "description",
            render: (record) => <>{record.description ? <CellTitle title={record.description} /> : "-"}</>,
            sortedType: "default",
        },
        {
            label: t(WorkingPositionTranslations.WORKING_POSITION_TASK_COUNT),
            dataIndex: "AssociatedTasksCount",
            sortedType: "default",
            className: "workingPositionGrid__taskCount",
            render: ({ associatedTasksCount, id }) => (
                <div className="workingPositionGrid__center">
                    <PlanAPPCCCounters
                        readonly={!hasPermissionToView(session, SecScreen.TASK)}
                        title={formatBigNumber(associatedTasksCount, 2)}
                        to={`/${PrivatePaths.TASK_PAGE}?workingPositionId=${id}`}
                    />
                </div>
            ),
        },
        {
            label: t(WorkingPositionTranslations.WORKING_POSITION_APPCC_COUNT),
            dataIndex: "AssociatedTasksAppccCount",
            sortedType: "default",
            className: "workingPositionGrid__appccCount",
            render: ({ id, associatedTasksAppccCount }) => (
                <div className="workingPositionGrid__center">
                    <PlanAPPCCCounters
                        readonly={!hasPermissionToView(session, SecScreen.BASE_TASK_MANAGEMENT)}
                        title={formatBigNumber(associatedTasksAppccCount, 2)}
                        to={`/${PrivatePaths.REGISTRY_MANAGEMENT}?workingPositionId=${id}`}
                    />
                </div>
            ),
        },
        {
            label: t(TranslationCommon.EDIT),
            dataIndex: "edit",
            alignCenter: true,
            className: "workingPositionGrid__actions",
            render: (record) => (
                <CellIcons
                    icons={[
                        {
                            icon: faPen,
                            onClick: () => navigate(`edit/${record.id}`),
                            title: t(TranslationCommon.EDIT),
                        },
                    ]}
                />
            ),
        },
    ];

    return (
        <PageContainer paddingTop>
            <TableCollapsable
                cols={columns.filter((col) => !col.hidden)}
                data={data}
                placeholder={<TableError icon={faQrcode} />}
                title={`${t(TranslationTitles.WORKING_POSITION_TITLE)} ${
                    selectCompany.company.value === "-1" ? `(${selectCompany.company.label})` : ""
                } `}
                subTitle={`${total} ${total === 1 ? t(TranslationKeys.CODE) : t(TranslationKeys.CODES)}`}
                isLoading={isLoading}
                pageIndex={pageIndex}
                pageSize={PaginationDefaults.PAGE_SIZE}
                total={total}
                onChangePageIndex={handlePageIndexChange}
                sortField={sortField}
                sortDirection={sortDirection}
                onChangeSortDirectionField={handleChangeSort}
                actionButtons={[
                    {
                        icon: faCirclePlus,
                        onClick: () => navigate("new"),
                        hidden: !hasPermissionToAdd(SecScreen.QR),
                        title: t(TranslationCommon.CREATE),
                    },
                    {
                        icon: faMagnifyingGlass,
                        onClick: () => {
                            setShowSearchInput(true);
                        },
                        inputComponent: {
                            component: (
                                <TableInputText
                                    onChange={onQueryChange}
                                    value={query}
                                    fetch={handleSubmitQuery}
                                    cleanFunction={handleSubmitQuery}
                                />
                            ),
                            show: showSearchInput,
                        },
                        title: t(TranslationCommon.SEARCH),
                    },
                    {
                        icon: filterIcon,
                        onClick: () => {
                            onCompanyChange({ isOpen: true });
                            setShowSearchInput(false);
                        },
                        inputComponent: {
                            component: (
                                <TableSelectCompany
                                    onChange={handleCompanyChange}
                                    value={selectCompany.company}
                                    onClickOutside={() => onCompanyChange({ isOpen: false })}
                                />
                            ),
                            show: selectCompany.isOpen,
                        },
                        title: t(TranslationCommon.FILTER),
                    },
                ]}
                onClickExportCsv={handleExport}
                onDoubleClick={(record) => navigate(`edit/${record.id}`)}
                tabs={tabs}
                currentTab={currentTab}
                mobileBody={(row, _i, rowPosition) => (
                    <WorkingPositionGridMobileBody workingPosition={row} key={row.id} rowPosition={rowPosition} />
                )}
            />
        </PageContainer>
    );
};
