import { v4 } from "uuid";
import { BaseTaskFieldModel } from "app/models/02-TAR/BaseTask/BaseTaskFieldModel";
import { BaseTaskModel } from "app/models/02-TAR/BaseTask/BaseTaskModel";
import { AssetsToReport, DynamicFieldsModel, WizardTaskAllSteps } from "app/models/02-TAR/TaskWizard";
import { convertUTCtoLocaleDate, getHoursFromDateUTC } from "app/helpers/Utilities/date/Date.utilities";
import { selectRecurringTaskCustomValue, selectRecurringTaskValue, setDays } from "../../../utilities";
import { AssetModel2 } from "app/models/05-QUA/AssetModels/AssetModel2";
import { assetTaskFromBack } from "app/models/02-TAR/Task/TaskBody";
import { MeasurementTypes } from "app/shared/Constants";
import { AssetDynamicFieldModel } from "app/models/05-QUA/AssetModels";

export const baseTaskToAllSteps = (
    task: WizardTaskAllSteps,
    baseTask: BaseTaskModel,
    assetOptions: AssetModel2[],
    offset?: number
): WizardTaskAllSteps => {
    const {
        name,
        description,
        fotoExample,
        isPhotoRequired,
        isCritical,
        fotoExampleId,
        baseTaskCheckList,
        baseTaskFields,
        id,
        reportType,
        baseTaskRangeHours,
        baseTaskScheduler,
        neverEnds,
        numRecurrencies,
        taskType,
        endDate,
        taskTemporalityType,
        minPhotosAllowed,
    } = baseTask;

    const taskFieldsValidated: BaseTaskFieldModel[] = baseTaskFields || [];

    const hasChecklist = !!baseTaskCheckList?.length && reportType === "CHECKLIST";
    const isRecurrent = baseTaskScheduler?.typeCode !== "ONE_TIME";
    const assets = getAsset(taskFieldsValidated);

    return {
        ...task,
        taskTitle: name ?? task.taskTitle,
        taskDesc: description ?? task.taskDesc,
        somethingToReportCheckBoxImage: fotoExample && fotoExample.length !== 0 ? true : false,
        somethingToReportImage: fotoExample && fotoExample.length !== 0 ? fotoExample : "",
        fotoExampleId: fotoExampleId ? Number(fotoExampleId) : undefined,
        isPhotoRequired,
        minPhotosAllowed: minPhotosAllowed || (isPhotoRequired ? 1 : null),
        criticalTask: isCritical,
        fK_BaseTask: id,
        fK_PlanAPPCC: baseTask.fK_PlanAPPCC !== undefined ? String(baseTask.fK_PlanAPPCC) : "-1",

        // Temporality
        temporalityType: taskTemporalityType,
        taskHours:
            taskTemporalityType === "PERIODICAL" || (taskTemporalityType === "ONE_DAY" && !!baseTaskRangeHours.length)
                ? baseTaskRangeHours.map(({ hour, maxHour }) => {
                      const startHourFormated = getHoursFromDateUTC(
                          convertUTCtoLocaleDate(new Date(hour), offset, false)
                      );
                      const endHourFormated = maxHour
                          ? getHoursFromDateUTC(convertUTCtoLocaleDate(new Date(maxHour), offset, false))
                          : undefined;
                      return {
                          id: v4(),
                          hour: startHourFormated,
                          maxHour: endHourFormated,
                          type: maxHour ? "range" : "hour",
                      };
                  })
                : [],
        recurringTask: {
            isRecurrent,
            options: [],
            value:
                baseTaskScheduler?.calendarDayNumber !== 0
                    ? "custom"
                    : baseTaskScheduler?.repeatEvery !== 1
                    ? "custom"
                    : selectRecurringTaskValue(baseTaskScheduler?.typeCode || "ONE_TIME"),
            custom: {
                customValue:
                    baseTaskScheduler?.calendarDayNumber !== 0
                        ? "everyMonth"
                        : selectRecurringTaskCustomValue(baseTaskScheduler?.typeCode || "ONE_TIME"),
                days: setDays(baseTaskScheduler),
                repeatEvery: baseTaskScheduler?.repeatEvery || 1,
                selectedOptions: {
                    text: "",
                    value:
                        baseTaskScheduler?.calendarDayNumber !== 0
                            ? "everyMonthDayOfMonth"
                            : baseTaskScheduler?.calendarWeekNumber !== 0
                            ? "everyMonthFirstDayOfWeek"
                            : "",
                },
            },
            text: baseTaskScheduler?.name || "",
        },
        finish: {
            checked: neverEnds ? "never" : endDate ? "el" : numRecurrencies ? "after" : "never",
            value: neverEnds ? null : numRecurrencies ? numRecurrencies : endDate ? endDate : null,
        },
        taskType,

        // step 3

        checkList: hasChecklist
            ? baseTaskCheckList.map(({ id, name, isDeleted }) => ({
                  id: v4(),
                  dbId: Number(id),
                  isEnabled: true,
                  name,
                  isDeleted,
                  fK_BaseTaskCheckList: id,
              }))
            : [],
        reportType,
        dynamicFields: mapBaseTaskFieldsToDynamicFields(taskFieldsValidated, assets, assetOptions),
    };
};

export const mapBaseTaskFieldsToDynamicFields = (
    baseTaskFields: BaseTaskFieldModel[],
    assets: assetTaskFromBack[],
    assetOptions: AssetModel2[]
): DynamicFieldsModel[] => {
    const dynamicFields: DynamicFieldsModel[] = [];

    const assetDynamicFields = baseTaskFields.filter((x) => x.asset2 != null);
    const dynamicFieldsWithoutAsset = baseTaskFields.filter((x) => x.asset2 == null);

    if (!!assetDynamicFields.length) {
        dynamicFields.push({
            id: v4(),
            dbId: undefined,
            label: "",
            isDeleted: false,
            dropDownMultipleOptions: assetOptions.map(({ id, name }) => ({ id, label: name })),
            dropDownMultipleValues: assets.map((a) => ({
                id: v4(),
                assetsFields: assetDynamicFields
                    .filter(({ fK_Asset }) => fK_Asset === a.id)
                    .map<AssetsToReport>(
                        ({
                            fK_AssetFieldRange,
                            assetFieldRange,
                            dynamicFieldType,
                            label,
                            id,
                            isDeleted,
                            isHidden,
                            baseTaskFieldOptions,
                            isRequired,
                        }) => ({
                            id: id || 0,
                            max: assetFieldRange?.max != null ? assetFieldRange?.max : null,
                            min: assetFieldRange?.min != null ? assetFieldRange?.min : null,
                            unit: assetFieldRange?.unit || MeasurementTypes.CELSIUS,
                            name: label || "",
                            type: label || "",
                            assetDynamicField: dynamicFieldType as AssetDynamicFieldModel,
                            assetFieldRangeId: fK_AssetFieldRange,
                            isDeleted,
                            isHidden,
                            isRequired: isRequired || true,
                            assetFieldOption: !!baseTaskFieldOptions?.length
                                ? baseTaskFieldOptions.map(({ label }) => ({
                                      fK_AssetField: 0,
                                      id: 0,
                                      isDeleted: false,
                                      name: label,
                                  }))
                                : [],
                        })
                    ),
                dbId: a.id,
                isDeleted: a.isDeleted,
                label: a.name,
                value: String(a.id),
            })),
            dynamicFieldsType: "DROPDOWN_MULTIPLE_ASSETS_RANGE",
            isRequired: true,
            order: assetDynamicFields[0]?.order || 0,
            taskFieldOptions: [],
        });
    }

    dynamicFieldsWithoutAsset.forEach(
        ({ dynamicFieldType, id, isRequired, label, baseTaskFieldOptions, isDeleted, order }) => {
            dynamicFields.push({
                dbId: id,
                dynamicFieldsType: dynamicFieldType || "DATE",
                id: v4(),
                isDeleted,
                isRequired: !!isRequired,
                label: label || "",
                taskFieldOptions:
                    baseTaskFieldOptions?.map((taskfield) => ({
                        label: taskfield.label,
                        id: v4(),
                    })) || [],
                dropDownMultipleOptions: [],
                dropDownMultipleValues: [],
                order,
            });
        }
    );

    return dynamicFields.sort((a, b) => a.order - b.order);
};

const getAsset = (baseTaskFieldsValidated: BaseTaskFieldModel[]): assetTaskFromBack[] => {
    const assets: assetTaskFromBack[] = [];
    const assetIds: number[] = [];
    baseTaskFieldsValidated.forEach((asset) => {
        if (asset.asset2 != null && asset.fK_Asset != null && !assetIds.includes(asset.fK_Asset)) {
            assets.push(asset.asset2);
            assetIds.push(asset.fK_Asset);
        }
    });
    return assets || [];
};
