import { ApiPaths } from "app/shared/Constants";
import FetchService from "../Fetch/FetchService";
import { UpdateTaskInstanceFieldsDto } from "app/dtos/02-TAR/TaskInstanceField/UpdateTaskInstanceFieldDto";

export default class TaskInstanceFieldService {
    public static async UpdateIsOperative(fkTaskinstance: number, fkAsset2: number, isOperative?: boolean) {
        return FetchService.put({
            url: `${ApiPaths.TAR_API}/TaskinstanceField/${fkTaskinstance}/${fkAsset2}`,
            body: { isOperative },
        });
    }

    public static async UpdateByTaskInstaceIdAsync(
        fkTaskinstance: number,
        updateTaskInstanceFieldDto: UpdateTaskInstanceFieldsDto
    ) {
        return FetchService.put({
            url: `${ApiPaths.TAR_API}/TaskinstanceField/${fkTaskinstance}`,
            body: updateTaskInstanceFieldDto,
        });
    }
}
