import { UserType } from "app/shared/types/UserTypes";
import { UserTypeTranslations } from "app/translation/translationKeys";

export const GetUserTypeDescription = (userType: UserType, t: Function) => {
    const userTypeDescription: Record<UserType, string> = {
        ADMIN: "admin",
        ANALIST: t(UserTypeTranslations.ANALIST),
        SUPERVISOR: t(UserTypeTranslations.SUPERVISOR),
        WORKER: t(UserTypeTranslations.WORKER),
        ADMIN_CLIENT: t(UserTypeTranslations.ADMIN_CLIENT),
    };

    return userTypeDescription[userType];
};
