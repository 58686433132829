import { UserType } from "app/shared/types/UserTypes";
import { TaskInstanceFeedbackMessageModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFeedbackMessageModel";
import { StarSizeModel, StarColorModel } from "app/shared/types/StarTypes";
import { USER_TYPE } from "app/shared/Constants";

type CalculateStarProps = {
    taskIFeedbackMsg?: TaskInstanceFeedbackMessageModel;
    userId?: number;
    starSize?: StarSizeModel;
    starColor?: StarColorModel;
    isMyTask?: boolean;
    userType?: UserType;
    taskUserType?: string;
};

type CalculateReturn = {
    starColorFeedback: StarColorModel;
    starSizeFeedback: StarSizeModel;
};

export const calculateStar = ({
    starSize,
    starColor,
    taskIFeedbackMsg,
    userId,
    isMyTask,
    userType,
    taskUserType,
}: CalculateStarProps): CalculateReturn => {
    const starColorFeedback: StarColorModel = "primary";
    const starSizeFeedback: StarSizeModel = "xl";

    if (!taskIFeedbackMsg)
        return {
            starColorFeedback,
            starSizeFeedback,
        };

    const senderUserId = taskIFeedbackMsg.senderUserId;

    if (starColor && starSize) {
        return {
            starColorFeedback: starColor,
            starSizeFeedback: starSize,
        };
    }

    // Si es mi tarea
    if (isMyTask) {
        return {
            starColorFeedback: senderUserId != null ? "yellow" : "primary",
            starSizeFeedback: "xs",
        };
    }

    // Soy supervisor y la tarea es de un analista no la puedo valorar, pero las estrellas son azules
    if (
        userType === USER_TYPE.SUPERVISOR &&
        (taskUserType === USER_TYPE.SUPERVISOR ||
            taskUserType === USER_TYPE.ANALIST ||
            taskUserType === USER_TYPE.ADMIN_CLIENT) &&
        senderUserId == null
    ) {
        return {
            starColorFeedback: "primary",
            starSizeFeedback: "xs",
        };
    }

    // Soy supervisor y la tarea es de un analista no la puedo valorar
    if (
        userType === USER_TYPE.SUPERVISOR &&
        (taskUserType === USER_TYPE.ANALIST || taskUserType === USER_TYPE.ADMIN_CLIENT)
    ) {
        return {
            starColorFeedback: "yellow",
            starSizeFeedback: "xs",
        };
    }

    // Si soy supervisor y la tarea no es de un analista ni esta valorada
    if (userType === USER_TYPE.SUPERVISOR && senderUserId === null) {
        return {
            starColorFeedback: "primary",
            starSizeFeedback: "xl",
        };
    }

    // Si soy analista y no esta valorada
    if ((userType === USER_TYPE.ANALIST || userType === USER_TYPE.ADMIN_CLIENT) && senderUserId === null) {
        return {
            starColorFeedback: "primary",
            starSizeFeedback: "xl",
        };
    }

    // Tarea con valoración
    if (senderUserId !== null) {
        return {
            starColorFeedback: "yellow",
            starSizeFeedback: senderUserId === userId ? "xl" : "xs",
        };
    }

    return {
        starColorFeedback,
        starSizeFeedback,
    };
};
