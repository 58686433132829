import { IssueAttachDocDto } from "app/dtos/05-QUA/Issue/IssueAttachDocDto";
import { compressImage } from "app/helpers/Media/compress/compressImage";
import { convertFileToBase64 } from "app/helpers/Media/converter/webPConverter";
import { useToast } from "app/hooks/Toast/useToast";
import { IssueAttachmentModel } from "app/models/05-QUA/IssueModels/IssueAttachmentModel";
import IssueService from "app/services/05-QUA/IssueService";
import { TranslationCommon, TranslationModals } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";

export const useIssueBodyAttachment = (id: number, onAddNewAttachments: (value: IssueAttachmentModel[]) => void) => {
    const { handleToast, removeToast } = useToast();
    const { t } = useTranslation();

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const fileArray = Array.from(files);
            try {
                const base64Promises = fileArray.map(async (file) => {
                    const attachment = await compressImage({ file });
                    const base64DataUrl = convertFileToBase64(attachment);
                    return base64DataUrl;
                });

                const base64Results = await Promise.all(base64Promises);

                const issueAttachments: IssueAttachDocDto[] = base64Results
                    .filter((attachment) => attachment !== null)
                    .map((attachment) => ({ fileBase64Content: attachment! }));

                handleUploadFiles(issueAttachments);
            } catch (error) {
                handleToast({ title: t(TranslationCommon.IMAGE_UPLOAD_FAILED), variant: "danger", type: "alert" });
            }
        }
    };

    const handleUploadFiles = async (issueAttachments: IssueAttachDocDto[]) => {
        const toastId = v4();
        handleToast({
            title: t(TranslationModals.ISSUE_IMAGE_LOADING_TITLE),
            variant: "primary",
            type: "alert",
            id: toastId,
            isLoading: true,
        });
        const { data, status } = await IssueService.AttachDoc(id, issueAttachments);

        removeToast(toastId);
        if (!status()) {
            handleToast({ title: t(TranslationCommon.IMAGE_UPLOAD_FAILED), variant: "danger", type: "alert" });
            return;
        }

        handleToast({ title: t(TranslationCommon.IMAGE_UPLOAD_SUCCEED), variant: "success", type: "alert" });
        onAddNewAttachments(data);
    };

    return {
        handleFileChange,
    };
};
