import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { SystemNotificationModel } from "app/models/02-TAR/SystemNotification/SystemNotificationModel";
import { NotificationVariantModel } from "app/models/NotificationVariant";
import { NotificationPill } from "app/components_v2/NotificationPill/NotificationPill";
import { SystemNotificationSkelleton } from "app/components_v2/__skelletons/SystemNotificationSkelleton";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { faBell } from "@fortawesome/pro-regular-svg-icons";
import { notificationVariantRecord } from "../constants/notificationConstants";
import Pagination, { PaginationProps } from "app/components_v2/Pagination/Pagination";

type SystemNotificationListProps = {
    list: SystemNotificationModel[];
    isLoading: boolean;
    pagination: PaginationProps;
    handleMarkAllSeen: () => Promise<void>;
    handleSeenNotification: ({ id, redirectURL }: SystemNotificationModel) => Promise<void>;
};

export const SystemNotificationList: FC<SystemNotificationListProps> = ({
    list,
    isLoading,
    handleMarkAllSeen,
    handleSeenNotification,
    pagination,
}) => {
    const { t } = useTranslation();

    const getVariant = (variant?: string): NotificationVariantModel => {
        if (!variant) return "primary";
        return notificationVariantRecord[variant] || "primary";
    };

    return (
        <>
            <div className="systemNotifications__seen">
                <p className="systemNotifications__seen__text" onClick={handleMarkAllSeen}>
                    {t(TranslationKeys.MARK_ALL_AS_VIEWED)}
                </p>
            </div>
            <div className="systemNotifications">
                <div className="systemNotifications__list">
                    {isLoading ? (
                        <SystemNotificationSkelleton quantity={8} />
                    ) : list.length !== 0 ? (
                        list.map((notification) => {
                            const {
                                body,
                                title,
                                sendDate,
                                seenDate,
                                id,
                                variant,
                                profilePictureURL,
                                nameInitials,
                                userId,
                            } = notification;
                            return (
                                <NotificationPill
                                    body={body}
                                    title={title}
                                    date={new Date(sendDate)}
                                    key={id}
                                    seen={!seenDate}
                                    onClick={() => handleSeenNotification(notification)}
                                    nameInitials={nameInitials}
                                    userId={userId}
                                    variant={getVariant(variant)}
                                    profilePictureURL={profilePictureURL}
                                />
                            );
                        })
                    ) : (
                        <TableError description={t(TranslationKeys.RECEIVED_NOTIFICATION)} icon={faBell} />
                    )}
                </div>
            </div>
            {pagination.total > 0 && (
                <div className="systemNotifications__pagination">
                    <Pagination {...pagination} />
                </div>
            )}
        </>
    );
};
