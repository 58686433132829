import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useSuccessManager } from "app/hooks/SuccessHandler/useSuccessManager";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { faCirclePlus, faListCheck, faMagnifyingGlass, faPen, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { TranslationCommon, TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import ServiceResponse from "app/models/ServiceResponse/ServiceResponse";
import AuditGroupService from "app/services/05-QUA/AuditGroupService";
import AuditGroupCheckListService from "app/services/05-QUA/AuditGroupCheckListService";
import AuditGroupCheckListItemService from "app/services/05-QUA/AuditGroupCheckListItemService";
import { PaginationDefaults } from "app/shared/Constants";
import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";
import { ColumnsType } from "app/components_v2/Table/types";
import { AuditGroupCheckListModel } from "app/models/05-QUA/AuditModels/AuditGroupCheckListModels";
import { AuditGroupModel } from "app/models/05-QUA/AuditModels/AuditGroupModels";
import { AuditBoxModal } from "../AuditBox/AuditBoxModal/AuditBoxModal";
import { AuditGroupCheckListModal } from "./AuditGroupCheckListModal/AuditGroupCheckListModal";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { Badge } from "app/components_v2/Badge/Badge";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { TableInputText } from "app/components_v2/Table/TableInputText/TableInputText";
import { ActionButtonsModel } from "app/components_v2/Table/TableTabHeader/TableTabHeader";
import { multiplyAndFormat } from "app/helpers/Utilities/number/multiplyAndFormat";

type AuditGroupGridProps = {
    auditGroup: AuditGroupModel;
    fetchAuditGroups: () => Promise<void>;
    onChange: (auditGroup: AuditGroupModel) => void;
    remainingPercentage: () => number;
};

const INITIAL_AUDIT_GROUP_CHECKLIST: AuditGroupCheckListModel = {
    id: 0,
    name: "",
    fK_AuditGroup: 0,
    checkListItemCount: 0,
    auditGroupCheckListItems: [],
};

export const AuditGroupGrid: FC<AuditGroupGridProps> = ({
    auditGroup,
    fetchAuditGroups,
    onChange,
    remainingPercentage,
}) => {
    const { t } = useTranslation();
    const { getErrorMessage } = useFetchErrors();
    const { handleErrorManager } = useErrorManager();
    const { handleSuccessManager } = useSuccessManager();

    const [internalAuditGroup, setInternalAuditGroup] = useState<AuditGroupModel>({
        ...auditGroup,
        auditGroupCheckLists: [...auditGroup.auditGroupCheckLists.slice(0, PaginationDefaults.PAGE_SIZE)],
    });
    const [auditGroupCheckList, setAuditGroupCheckList] = useState<AuditGroupCheckListModel>({
        ...INITIAL_AUDIT_GROUP_CHECKLIST,
        fK_AuditGroup: internalAuditGroup.id,
    });
    const [isOpenGrid, setIsOpenGrid] = useState<boolean>(false);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [total, setTotal] = useState<number>(auditGroup.auditGroupCheckLists.length);
    const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
    const [query, setQuery] = useState<string>("");
    // Modals
    const [showEditAuditGroupModal, setShowEditAuditGroupModal] = useState<boolean>(false);
    const [showDeleteAuditGroupModal, setShowDeleteAuditGroupModal] = useState<boolean>(false);
    const [showEditAuditGroupCheckListModal, setShowEditAuditGroupCheckListModal] = useState<boolean>(false);
    const [showDeleteAuditGroupCheckListModal, setShowDeleteAuditGroupCheckListModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingEdit, setIsLoadingEdit] = useState<boolean>(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);

    const columns: ColumnsType<AuditGroupCheckListModel>[] = [
        {
            label: t(TranslationKeys.AUDIT_GROUP_CHECKLIST_NAME),
            dataIndex: "name",
            render: (record) => <CellTitle title={record.name} />,
        },
        {
            label: t(TranslationKeys.AUDIT_GROUP_CHECKLIST_ITEM_COUNT),
            dataIndex: "checkListItemCount",
            className: "auditGroupGrid__actions",
            render: (record) => (
                <Badge
                    title={String(record.checkListItemCount)}
                    variant="blue"
                    icon={faListCheck}
                    iconPosition="right"
                />
            ),
        },
        {
            label: t(TranslationKeys.ACTIONS),
            dataIndex: "name",
            className: "auditGroupGrid__actions",
            alignCenter: true,
            render: (record) => (
                <CellIcons
                    icons={[
                        {
                            icon: faPen,
                            onClick: () => {
                                setAuditGroupCheckList(record);
                                setShowEditAuditGroupCheckListModal(true);
                            },
                        },
                        {
                            icon: faTrash,
                            onClick: () => {
                                setAuditGroupCheckList(record);
                                setShowDeleteAuditGroupCheckListModal(true);
                            },
                        },
                    ]}
                />
            ),
        },
    ];

    const handleCleanFetch = () => {
        setQuery("");
        fetchAuditGroupCheckList(1, "");
        setShowSearchInput(false);
    };

    const actionButtons: ActionButtonsModel[] = [
        {
            icon: faCirclePlus,
            onClick: () => {
                setAuditGroupCheckList({
                    ...INITIAL_AUDIT_GROUP_CHECKLIST,
                    fK_AuditGroup: internalAuditGroup.id,
                });
                setShowEditAuditGroupCheckListModal(true);
            },
        },
        {
            icon: faPen,
            onClick: () => setShowEditAuditGroupModal(true),
        },
        {
            icon: faTrash,
            onClick: () => setShowDeleteAuditGroupModal(true),
        },
        {
            icon: faMagnifyingGlass,
            onClick: () => setShowSearchInput(true),
            inputComponent: {
                component: (
                    <TableInputText
                        onChange={setQuery}
                        value={query}
                        fetch={() => {
                            fetchAuditGroupCheckList(1, query);
                            setPageIndex(1);
                        }}
                        cleanFunction={handleCleanFetch}
                        variant="appcc"
                    />
                ),
                show: showSearchInput,
            },
        },
    ];

    const fetchAuditGroupCheckList = async (pi?: number, query?: string) => {
        setIsLoading(true);
        setPageIndex(pi ?? 0);
        const extraParams = new URLSearchParams();
        extraParams.append("AuditGroupId", String(auditGroup.id));
        const { data, status, getParsedError } = await AuditGroupCheckListService.GetData({
            query,
            pageIndex: pi !== undefined ? pi - 1 : 0,
            pageSize: PaginationDefaults.PAGE_SIZE,
            extraParams: extraParams.toString(),
        });

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            setIsLoading(false);
            return;
        }

        setInternalAuditGroup((prev) => ({ ...prev, auditGroupCheckLists: data.list }));
        setTotal(data.count);
        setIsLoading(false);
    };

    const handleEditAuditGroup = async (value: string, ponderationPercentage?: number) => {
        setIsLoadingEdit(true);

        const { data, status, getParsedError } = await AuditGroupService.Edit(auditGroup.id, {
            id: internalAuditGroup.id,
            name: value,
            fK_Audit: internalAuditGroup.fK_Audit,
            auditGroupCheckLists: [],
            ponderation:
                (ponderationPercentage !== undefined ? ponderationPercentage : internalAuditGroup.ponderation) / 100,
        });

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            reset(setIsLoadingEdit, setShowEditAuditGroupModal);
            return;
        }

        handleSuccessManager(t(TranslationModals.AUDIT_GROUP_EDIT_TOAST));

        setInternalAuditGroup(data);
        onChange(data);
        reset(setIsLoadingEdit, setShowEditAuditGroupModal);
        fetchAuditGroups();
    };

    const handleDeleteAuditGroup = async () => {
        setIsLoadingDelete(true);

        const { status, getParsedError } = await AuditGroupService.Delete(internalAuditGroup.id);

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            reset(setIsLoadingEdit, setShowEditAuditGroupModal);
            return;
        }

        handleSuccessManager(t(TranslationModals.AUDIT_GROUP_DELETE_TOAST));

        fetchAuditGroups();
        reset(setIsLoadingDelete, setShowDeleteAuditGroupModal);
    };

    const handleSubmitAuditCheckList = async ({
        id,
        name,
        auditGroupCheckListItems,
        checkListItemCount,
        fK_AuditGroup,
    }: AuditGroupCheckListModel) => {
        setIsLoading(true);

        const auditGroupCheckListToSave: AuditGroupCheckListModel = {
            id,
            name,
            auditGroupCheckListItems: [],
            checkListItemCount,
            fK_AuditGroup,
        };

        const auditGroupCheckListPromise =
            id === 0
                ? AuditGroupCheckListService.Save(auditGroupCheckListToSave)
                : AuditGroupCheckListService.Edit(id, auditGroupCheckListToSave);

        const { data: auditCheckListData, status, getParsedError } = await auditGroupCheckListPromise;

        const errorMessage = getErrorMessage(getParsedError());

        if (handleErrorManager(status(), errorMessage)) {
            reset(setIsLoading, setShowEditAuditGroupCheckListModal);
            return;
        }

        const auditGroupCheckListItemsPromises: Promise<ServiceResponse<unknown>>[] = auditGroupCheckListItems.map(
            (item) => {
                item.fK_AuditGroupCheckList = auditCheckListData.id;

                return item.isDeleted && item.id !== 0
                    ? AuditGroupCheckListItemService.Delete(item.id)
                    : item.id === 0
                      ? AuditGroupCheckListItemService.Save(item)
                      : AuditGroupCheckListItemService.Edit(item.id, item);
            }
        );
        await Promise.all([...auditGroupCheckListItemsPromises]);

        // FIXME: Extreure el recalcul de la ponderació en un endpoint a banda
        await AuditGroupCheckListService.Edit(auditCheckListData.id, auditGroupCheckListToSave);

        await fetchAuditGroupCheckList(pageIndex, query);

        reset(setIsLoading, setShowEditAuditGroupCheckListModal);
        handleSuccessManager(t(TranslationModals.AUDIT_GROUP_CHECKLIST_EDIT_TOAST));
    };

    const handleExport = (params?: IDownLoadCsvReturn) => AuditGroupCheckListService.Export({ ...params });

    const handleDeleteAuditGroupCheckList = async () => {
        setIsLoading(true);

        const { status, getParsedError } = await AuditGroupCheckListService.Delete(auditGroupCheckList.id);

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            reset(setIsLoadingDelete, setShowDeleteAuditGroupCheckListModal);
            return;
        }

        handleSuccessManager(t(TranslationModals.AUDIT_GROUP_CHECKLIST_DELETE_TOAST));

        fetchAuditGroupCheckList(pageIndex, query);
        reset(setIsLoading, setShowDeleteAuditGroupCheckListModal);
    };

    const reset = (setIsLoading: (value: boolean) => void, setShowModal: (value: boolean) => void) => {
        setIsLoading(false);
        setShowModal(false);
    };

    useEffect(() => {
        setInternalAuditGroup({
            ...auditGroup,
            auditGroupCheckLists: [...auditGroup.auditGroupCheckLists.slice(0, PaginationDefaults.PAGE_SIZE)],
        });
    }, [auditGroup]);

    return (
        <div>
            {showEditAuditGroupModal && (
                <AuditBoxModal
                    isLoading={isLoadingEdit}
                    onSubmit={handleEditAuditGroup}
                    onCloseModal={() => setShowEditAuditGroupModal(false)}
                    value={internalAuditGroup.name}
                    ponderation={internalAuditGroup.ponderation}
                    type="EDIT_AUDIT_GROUP"
                />
            )}
            {showDeleteAuditGroupModal && (
                <ConfirmModal
                    title={t(TranslationModals.DELETE_AUDIT_GROUP_TITLE).replace("{0}", internalAuditGroup.name)}
                    description={t(TranslationModals.DELETE_AUDIT_GROUP_BODY)}
                    onClose={() => setShowDeleteAuditGroupModal(false)}
                    onConfirm={handleDeleteAuditGroup}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onConfirmText={t(TranslationCommon.DELETE)}
                    isLoading={isLoadingDelete}
                    type="delete"
                    variant="appcc"
                />
            )}
            {showEditAuditGroupCheckListModal && (
                <AuditGroupCheckListModal
                    data={auditGroupCheckList}
                    onCloseModal={() => setShowEditAuditGroupCheckListModal(false)}
                    onSubmit={handleSubmitAuditCheckList}
                    isLoading={isLoading}
                />
            )}
            {showDeleteAuditGroupCheckListModal && (
                <ConfirmModal
                    title={t(TranslationModals.DELETE_AUDIT_GROUP_CHECKLIST_TITLE).replace(
                        "{0}",
                        auditGroupCheckList.name
                    )}
                    description={t(TranslationModals.DELETE_AUDIT_GROUP_CHECKLIST_BODY)}
                    onClose={() => setShowDeleteAuditGroupCheckListModal(false)}
                    onConfirm={handleDeleteAuditGroupCheckList}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onConfirmText={t(TranslationCommon.DELETE)}
                    isLoading={isLoadingDelete}
                    type="delete"
                    variant="appcc"
                />
            )}

            <TableCollapsable
                title={`${internalAuditGroup.name} (${multiplyAndFormat(internalAuditGroup.ponderation, 100)}%)`}
                subTitle={`${total} ${t(TranslationKeys.AUDIT_GROUP_CHECKLISTS)}`}
                cols={columns}
                data={internalAuditGroup.auditGroupCheckLists}
                variant="appcc"
                placeholder={
                    <div className="padding20">
                        <TableError
                            icon={faListCheck}
                            title={t(TranslationKeys.AUDIT_GROUP_CHECKLIST_EMPTY_TITLE)}
                            description={t(TranslationKeys.AUDIT_GROUP_CHECKLIST_EMPTY_BODY)}
                        />
                    </div>
                }
                isOpen={isOpenGrid}
                actionButtons={actionButtons}
                pageIndex={pageIndex}
                pageSize={PaginationDefaults.PAGE_SIZE}
                total={total}
                isLoading={isLoading}
                onClickCollapsable={() => setIsOpenGrid(!isOpenGrid)}
                onChangePageIndex={(pi) => fetchAuditGroupCheckList(pi, query)}
                onClickExportCsv={handleExport}
                onDoubleClick={(row) => {
                    setAuditGroupCheckList(row);
                    setShowEditAuditGroupCheckListModal(true);
                }}
                fitHeight
            />
        </div>
    );
};
