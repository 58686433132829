import { FC, ReactNode, useContext } from "react";
import { useSession } from "app/hooks";
import { useTranslation } from "react-i18next";
import { DynamicFieldTranslations, TranslationKeys } from "app/translation/translationKeys";
import { Collapsable } from "../Collapsable/Collapsable";
import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";
import { TaskInstanceFieldValueModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldValueModel";
import { isCorrectIncorrectDynamicField } from "app/helpers/Task/dynamicFields/dynamicFieldHelper";
import IssueService from "app/services/05-QUA/IssueService";
import { IssueModalContext } from "app/state/context/issueModalContext/issueModalContext";
import { useNavigate } from "react-router-dom";
import { PrivatePaths, SecScreen } from "app/shared/Constants";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { groupTaskInstanceFieldsByAssetId } from "app/helpers/taskInstanceFields/groupTaskInstanceFieldsByAssetId";
import { GroupedTaskField } from "app/pages/02-TAR/01-TAR-Dashboard/TaskDetail/TaskFields/GroupedTaskField/GroupedTaskField";
import { TaskDetailDynamicFieldItem } from "./TaskDetailDynamicFieldItem/TaskDetailDynamicFieldItem";

type TaskDetailDynamicFieldsProps = {
    taskInstanceFields: TaskInstanceFieldModel[];
    onChange?: (taskInstanceFields: TaskInstanceFieldModel[]) => void;
    readonly: boolean;
    errors?: number[];
    taskinstanceId: number;
    statusCode: string;
    isSelectorLoading?: boolean;
};

type dropdownItem = { id: number; label: string; value: string };

export const TaskDetailDynamicFields: FC<TaskDetailDynamicFieldsProps> = ({
    taskInstanceFields,
    onChange,
    readonly,
    errors = [],
    taskinstanceId,
    statusCode,
    isSelectorLoading = false,
}) => {
    const { t } = useTranslation();
    const { open } = useContext(IssueModalContext);
    const nav = useNavigate();
    const session = useSession();

    const offset = session?.user.timeZoneOffset;

    const handleInputChange = (id: number, newValue: string | dropdownItem[]) => {
        const data: TaskInstanceFieldModel[] = taskInstanceFields.map((field) => {
            if (field.id !== id) return field;
            const taskInstanceFieldValues: TaskInstanceFieldValueModel[] = Array.isArray(newValue)
                ? newValue.map((dropItem) => ({ ...dropItem, min: null, max: null, measureUnit: "", fK_Asset: null }))
                : [{ ...field.taskInstanceFieldValues[0], value: newValue }];

            return {
                ...field,
                taskInstanceFieldValues: taskInstanceFieldValues,
            };
        });

        onChange && onChange(data);

        const updatedField = data.find(({ id: taskFieldId }) => taskFieldId === id);
        Array.isArray(newValue) && handleDynamicFieldIssue(updatedField, id, newValue);
    };

    const handleAssetFieldInputchange = (taskinstanceField: TaskInstanceFieldModel) => {
        const data: TaskInstanceFieldModel[] = taskInstanceFields.map((field) => {
            if (field.id !== taskinstanceField.id) return field;

            return taskinstanceField;
        });

        onChange && onChange(data);
    };

    const handleDropDownMultipleChange = (id: number, selectedValues: OptionModel[]) => {
        const data: TaskInstanceFieldModel[] = taskInstanceFields.map((field) => {
            if (field.id !== id) return field;

            return {
                ...field,
                taskInstanceFieldValues: field.taskInstanceFieldValues.map<TaskInstanceFieldValueModel>((tifv) => ({
                    ...tifv,
                    value: selectedValues.find(({ value }) => Number(value) === tifv.id) ? "true" : "false",
                })),
            };
        });

        onChange && onChange(data);
    };

    const handleDeleteDropDownMultipleItem = (id: string) => {
        const data: TaskInstanceFieldModel[] = taskInstanceFields.map((field) => {
            return {
                ...field,
                taskInstanceFieldValues: field.taskInstanceFieldValues.map<TaskInstanceFieldValueModel>((tifv) => ({
                    ...tifv,
                    value: tifv.id === Number(id) ? "false" : tifv.value,
                })),
            };
        });

        onChange && onChange(data);
    };

    const handleDynamicFieldIssue = async (
        updatedField: TaskInstanceFieldModel | undefined,
        id: number,
        newValue: dropdownItem[]
    ) => {
        if (!updatedField) return;

        if (!isCorrectIncorrectDynamicField(updatedField.dynamicFieldType)) return;

        const currentSelection = newValue.find(({ value }) => value === "true");
        if (!currentSelection) return;

        const previousTaskInstanceField = taskInstanceFields.find(
            ({ id: taskInstanceFieldId }) => taskInstanceFieldId === id
        );
        if (!previousTaskInstanceField) return;

        const previousValues = previousTaskInstanceField.taskInstanceFieldValues.map(({ value }) => value);
        const isEmptyValues = previousValues.every((value) => value === "");
        const previousSelected = previousTaskInstanceField.taskInstanceFieldValues.find(
            ({ value }) => value === "true"
        );

        // If selected the same option or first selection is CORRECT do nothing
        if (
            previousSelected?.label === currentSelection.label ||
            (isEmptyValues && currentSelection.label === DynamicFieldTranslations.CORRECT)
        )
            return;

        if (!isEmptyValues && currentSelection.label === DynamicFieldTranslations.CORRECT) {
            await IssueService.DeleteIssueByTaskInstanceFieldIdAsync(id);
            return;
        }

        if (currentSelection.label === DynamicFieldTranslations.INCORRECT) {
            open({
                issueType: "TASK",
                taskinstanceId: previousTaskInstanceField.fK_TaskInstance,
                taskInstanceFieldId: id,
                onCancel: () => handleInputChange(id, addValueToOptions(updatedField.taskInstanceFieldValues, "")),
                issueServiceType: "ISSUE",
            });
            return;
        }
    };

    const addValueToOptions = (
        taskInstanceFieldValues: { id: number; label: string }[],
        selectedLabel: string
    ): dropdownItem[] => {
        return taskInstanceFieldValues.map((taskInstanceField) => ({
            ...taskInstanceField,
            value: t(taskInstanceField.label) === t(selectedLabel) ? "true" : "false",
        }));
    };

    const redirectIssue = (id: number | null | undefined) =>
        id != null && hasPermissionToView(session, SecScreen.ISSUE) && nav(`/${PrivatePaths.ISSUE}/edit/${id}`);

    const hasDynamicFieldIssue = (taskInstanceFieldValues: TaskInstanceFieldValueModel[]) =>
        taskInstanceFieldValues.some(
            ({ label, value }) => label === DynamicFieldTranslations.INCORRECT && value === "true"
        );

    const getContent = () => {
        const fields: ReactNode[] = [];
        const grouppedFields = groupTaskInstanceFieldsByAssetId(
            taskInstanceFields.filter(({ fK_Asset2 }) => fK_Asset2 != null)
        );
        const taskInstanceFieldsWithOutAssets = taskInstanceFields.filter(({ fK_Asset2 }) => fK_Asset2 == null);
        const fieldLength = taskInstanceFieldsWithOutAssets.length || 1;
        let hasGrouppedfieldsDrawed = false;
        for (let i = 0; i < fieldLength; i++) {
            const taskInstanceField = taskInstanceFields.filter(({ fK_Asset2 }) => fK_Asset2 == null)[i];

            if (
                !!grouppedFields.length &&
                !hasGrouppedfieldsDrawed &&
                (taskInstanceField == null || grouppedFields[0][0].order <= taskInstanceField.order)
            ) {
                hasGrouppedfieldsDrawed = true;
                fields.push(
                    <div className="taskFields__content">
                        {groupTaskInstanceFieldsByAssetId(
                            taskInstanceFields.filter(({ fK_Asset2 }) => fK_Asset2 != null)
                        ).map((groupedFields, i) => (
                            <GroupedTaskField
                                key={i}
                                groupedFields={groupedFields}
                                customSetFields={handleAssetFieldInputchange}
                                statusCode={statusCode}
                                taskinstanceId={taskinstanceId}
                                readOnly={readonly}
                            />
                        ))}
                    </div>
                );
            }

            if (taskInstanceField == null) continue;
            fields.push(
                <TaskDetailDynamicFieldItem
                    errors={errors}
                    handleDeleteDropDownMultipleItem={handleDeleteDropDownMultipleItem}
                    handleDropDownMultipleChange={handleDropDownMultipleChange}
                    handleInputChange={handleInputChange}
                    handleSelectChange={(id, taskInstanceFieldValues, label) =>
                        handleInputChange(id, addValueToOptions(taskInstanceFieldValues, label))
                    }
                    readonly={readonly}
                    taskInstanceField={taskInstanceField}
                    offset={offset}
                    selectOptionsReadOnlyIconOptions={
                        hasDynamicFieldIssue(taskInstanceField.taskInstanceFieldValues)
                            ? {
                                  value: faTriangleExclamation,
                                  variant: "error",
                                  onClick: () => redirectIssue(taskInstanceField.fK_Issue),
                              }
                            : undefined
                    }
                    isSelectorLoading={isSelectorLoading}
                />
            );
        }

        return fields;
    };

    return (
        <Collapsable title={t(TranslationKeys.DATAS_TO_REPORT)} isCollapsable={false}>
            {getContent().map((field, i) => (
                <div key={i}>{field}</div>
            ))}
        </Collapsable>
    );
};
