import { AnalistSupervisorDetailModel } from "app/models/03-REP/Analytics/AnalistSupervisorDetailModel";
import { AnalistWorkerDetailModel } from "app/models/03-REP/Analytics/AnalistWorkerDetailModel";
import { Avatar } from "../Avatar/Avatar";
import { AvatarRatingSkelleton } from "../__skelletons/AvatarRatingSkelleton/AvatarRatingSkelleton";
import { AvatarStarRatingModel } from "./types";
import AnalyticsService from "app/services/03-REP/AnalyticsService";
import { buildParams } from "app/pages/03-REP/01-AnalystRankingPage/helpers/helpers";
import { DatesReviewTask } from "app/hooks/Dates/types";
import { FC, useCallback, useState } from "react";
import { StarRatingBadge } from "../__stars/StarRatingBadge/StarRatingBadge";
import { useDateRange } from "app/pages/03-REP/01-AnalystRankingPage/Hooks/useDateRange";
import { ValorationDetailModal } from "../__modals/ValorationDetailModal/ValorationDetailModal";
import { useToast } from "app/hooks/Toast/useToast";
import { useFetchErrors } from "app/hooks/useFetchErrors";

type AvatarStarRatingProps = AvatarStarRatingModel & {
    startSize?: "md" | "xs";
    showRating?: boolean;
    text?: string;
    objectFit?: "contain";
    background?: "white";
    showFullRatingText?: boolean;
    starRatingBadgeDirection?: "col" | "row";
    areStarsClickable?: boolean;
};

const today = new Date(new Date().toISOString());
const oneWeekAgo = today.getTime() - 7 * 24 * 60 * 60 * 1000;
const INITIAL_START_DATE = new Date(oneWeekAgo);
const INITIAL_END_DATE = new Date(today.setDate(today.getDate() - 1));
export const AvatarStarRating: FC<AvatarStarRatingProps> = ({
    fullName,
    starRatingAVG,
    total,
    trend,
    userId,
    isLoading,
    startSize = "xs",
    showRating,
    avatarImg,
    text,
    objectFit,
    background,
    showFullRatingText = false,
    starRatingBadgeDirection = "row",
    isSupervisor,
    areStarsClickable = true,
    hexColor,
}) => {
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();

    const [showModal, setShowModal] = useState<boolean>(false);
    const today = new Date(new Date().toISOString());
    const oneWeekAgo = today.getTime() - 7 * 24 * 60 * 60 * 1000;
    const [supervisorDetail, setSupervisorDetail] = useState<AnalistSupervisorDetailModel>();
    const [workerDetail, setWorkerDetail] = useState<AnalistWorkerDetailModel>();
    const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);

    const { startDateInput, endDateInput } = useDateRange({
        initialStartDate: new Date(new Date(oneWeekAgo).toISOString()),
        initialEndDate: new Date(today.setDate(today.getDate() - 1)),
    });

    const fetchUserData = useCallback(
        async ({ startDate, endDate }: { startDate?: Date; endDate?: Date } = {}) => {
            setIsLoadingModal(true);
            const params = buildParams({
                startDateInput: startDate || startDateInput,
                endDateInput: endDate || endDateInput,
            });
            const { status, data, getParsedError, httpCodeStatus } = isSupervisor
                ? await AnalyticsService.GetSupervisorDetail(userId || 0, {
                      extraParams: params.toString(),
                  })
                : await AnalyticsService.GetWorkerDetail(userId || 0, {
                      extraParams: params.toString(),
                  });

            if (!status()) {
                handleToast({
                    title: getErrorMessage(getParsedError(), httpCodeStatus),
                    type: "alert",
                    variant: "danger",
                });
                setShowModal(false);
                setIsLoadingModal(false);
                return;
            }
            setIsLoadingModal(false);
            isSupervisor
                ? setSupervisorDetail(data as AnalistSupervisorDetailModel)
                : setWorkerDetail(data as AnalistWorkerDetailModel);
            setIsLoadingModal(false);
        },
        [startDateInput, endDateInput, AnalyticsService, userId, isSupervisor]
    );

    const handleOpenModal = () => {
        setShowModal(true);
        fetchUserData();
    };

    const onDateChange = ({ endDate, startDate }: Partial<DatesReviewTask>) => {
        if (!endDate) return;
        fetchUserData({ startDate, endDate });
    };

    return (
        <>
            {isLoading ? (
                <AvatarRatingSkelleton showText={!!text && !!starRatingAVG} showRating={!isSupervisor} />
            ) : (
                <div className="avatarRating">
                    <Avatar
                        avatarName={fullName}
                        colorId={userId}
                        size="2xl"
                        img={avatarImg || undefined}
                        border
                        objectFit={objectFit}
                        background={background}
                        hexColor={hexColor}
                    />
                    <div className="avatarRating__userInfo">
                        <p className="avatarRating__userInfo__name">{fullName}</p>
                        {showRating && (
                            <div
                                className={areStarsClickable ? "pointer" : ""}
                                onClick={() => areStarsClickable && handleOpenModal()}
                            >
                                <StarRatingBadge
                                    isLoading={false}
                                    starRatingAVG={starRatingAVG}
                                    trend={trend}
                                    total={total}
                                    showFullRatingText={showFullRatingText}
                                    direction={starRatingBadgeDirection}
                                    size="md"
                                    startSize={startSize}
                                    justifyContent="flex-start"
                                />
                            </div>
                        )}
                    </div>
                    {showModal && (
                        <ValorationDetailModal
                            showModal={setShowModal}
                            startDate={INITIAL_START_DATE}
                            endDate={INITIAL_END_DATE}
                            onDateChange={onDateChange}
                            data={supervisorDetail ? supervisorDetail : workerDetail}
                            isLoading={isLoadingModal}
                            isSupervisor={isSupervisor}
                        />
                    )}
                </div>
            )}
        </>
    );
};
