import { FC, useState } from "react";
import { Section } from "../Section/Section";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { CheckboxTheme } from "app/components_v2/CheckBox/types";

type Props = {
    show?: boolean;
};

export const CheckboxSection: FC<Props> = ({ show }) => {
    const [isCheckboxRounded, setIsCheckboxRounded] = useState(false);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(true);
    const [theme, setTheme] = useState<CheckboxTheme>("dark");
    return (
        <Section title="Checkbox" show={show}>
            <p className="designSys__section__element__title" id="pepe">
                Checkbox:
            </p>
            <button onClick={() => setIsCheckboxRounded(!isCheckboxRounded)}>
                Rounded {!isCheckboxRounded ? "SI" : "NO"}
            </button>
            <button onClick={() => setTheme((prev) => (prev === "dark" ? "light" : "dark"))}>Theme {theme}</button>
            <>
                <CheckBox
                    theme={theme}
                    checked={isCheckboxChecked}
                    onChange={setIsCheckboxChecked}
                    label="texto largo"
                    sublabel="texto aun más largo"
                    htmlFor="checkbox"
                    rounded={isCheckboxRounded}
                />
                <CheckBox
                    theme={theme}
                    checked={isCheckboxChecked}
                    onChange={(value) => {
                        setIsCheckboxChecked(value);
                    }}
                    label="texto largo"
                    sublabel="texto aun más largo"
                    htmlFor="checkbox"
                    disabled={true}
                    rounded={isCheckboxRounded}
                />
                <CheckBox
                    theme={theme}
                    checked={isCheckboxChecked}
                    onChange={(value) => {
                        setIsCheckboxChecked(value);
                    }}
                    label="texto largo"
                    sublabel="texto aun más largo"
                    htmlFor="checkbox"
                    intermediate={true}
                    disabled={false}
                    rounded={isCheckboxRounded}
                />
                <CheckBox
                    theme={theme}
                    checked={isCheckboxChecked}
                    onChange={(value) => {
                        setIsCheckboxChecked(value);
                    }}
                    label="texto largo"
                    sublabel="texto aun más largo"
                    htmlFor="checkbox"
                    intermediate={true}
                    disabled={true}
                    rounded={isCheckboxRounded}
                />
            </>
            <>
                <CheckBox
                    theme={theme}
                    checked={isCheckboxChecked}
                    onChange={setIsCheckboxChecked}
                    label="texto largo"
                    sublabel="texto aun más largo"
                    htmlFor="checkbox"
                    rounded={isCheckboxRounded}
                    color="green"
                />
                <CheckBox
                    theme={theme}
                    checked={isCheckboxChecked}
                    onChange={(value) => {
                        setIsCheckboxChecked(value);
                    }}
                    label="texto largo"
                    sublabel="texto aun más largo"
                    htmlFor="checkbox"
                    disabled={true}
                    rounded={isCheckboxRounded}
                    color="green"
                />
                <CheckBox
                    theme={theme}
                    checked={isCheckboxChecked}
                    onChange={(value) => {
                        setIsCheckboxChecked(value);
                    }}
                    label="texto largo"
                    sublabel="texto aun más largo"
                    htmlFor="checkbox"
                    intermediate={true}
                    disabled={false}
                    rounded={isCheckboxRounded}
                    color="green"
                />
                <CheckBox
                    theme={theme}
                    checked={isCheckboxChecked}
                    onChange={(value) => {
                        setIsCheckboxChecked(value);
                    }}
                    label="texto largo"
                    sublabel="texto aun más largo"
                    htmlFor="checkbox"
                    intermediate={true}
                    disabled={true}
                    rounded={isCheckboxRounded}
                    color="green"
                />
            </>
            <>
                <CheckBox
                    theme={theme}
                    checked={isCheckboxChecked}
                    onChange={setIsCheckboxChecked}
                    label="texto largo"
                    sublabel="texto aun más largo"
                    htmlFor="checkbox"
                    rounded={isCheckboxRounded}
                    color="orange"
                />
                <CheckBox
                    theme={theme}
                    checked={isCheckboxChecked}
                    onChange={(value) => {
                        setIsCheckboxChecked(value);
                    }}
                    label="texto largo"
                    sublabel="texto aun más largo"
                    htmlFor="checkbox"
                    disabled={true}
                    rounded={isCheckboxRounded}
                    color="orange"
                />
                <CheckBox
                    theme={theme}
                    checked={isCheckboxChecked}
                    onChange={(value) => {
                        setIsCheckboxChecked(value);
                    }}
                    label="texto largo"
                    sublabel="texto aun más largo"
                    htmlFor="checkbox"
                    intermediate={true}
                    disabled={false}
                    rounded={isCheckboxRounded}
                    color="orange"
                />
                <CheckBox
                    theme={theme}
                    checked={isCheckboxChecked}
                    onChange={(value) => {
                        setIsCheckboxChecked(value);
                    }}
                    label="texto largo"
                    sublabel="texto aun más largo"
                    htmlFor="checkbox"
                    intermediate={true}
                    disabled={true}
                    rounded={isCheckboxRounded}
                    color="orange"
                />
            </>
            <>
                <CheckBox
                    theme={theme}
                    checked={isCheckboxChecked}
                    onChange={setIsCheckboxChecked}
                    label="texto largo"
                    sublabel="texto aun más largo"
                    htmlFor="checkbox"
                    rounded={isCheckboxRounded}
                    color="gray"
                />
                <CheckBox
                    theme={theme}
                    checked={isCheckboxChecked}
                    onChange={(value) => {
                        setIsCheckboxChecked(value);
                    }}
                    label="texto largo"
                    sublabel="texto aun más largo"
                    htmlFor="checkbox"
                    disabled={true}
                    rounded={isCheckboxRounded}
                    color="gray"
                />
                <CheckBox
                    theme={theme}
                    checked={isCheckboxChecked}
                    onChange={(value) => {
                        setIsCheckboxChecked(value);
                    }}
                    label="texto largo"
                    sublabel="texto aun más largo"
                    htmlFor="checkbox"
                    intermediate={true}
                    disabled={false}
                    rounded={isCheckboxRounded}
                    color="gray"
                />
                <CheckBox
                    theme={theme}
                    checked={isCheckboxChecked}
                    onChange={(value) => {
                        setIsCheckboxChecked(value);
                    }}
                    label="texto largo"
                    sublabel="texto aun más largo"
                    htmlFor="checkbox"
                    intermediate={true}
                    disabled={true}
                    rounded={isCheckboxRounded}
                    color="gray"
                />
            </>
            <>
                <CheckBox
                    theme={theme}
                    checked={isCheckboxChecked}
                    onChange={setIsCheckboxChecked}
                    label="texto largo"
                    sublabel="texto aun más largo"
                    htmlFor="checkbox"
                    rounded={isCheckboxRounded}
                    color="red"
                />
                <CheckBox
                    theme={theme}
                    checked={isCheckboxChecked}
                    onChange={(value) => {
                        setIsCheckboxChecked(value);
                    }}
                    label="texto largo"
                    sublabel="texto aun más largo"
                    htmlFor="checkbox"
                    disabled={true}
                    rounded={isCheckboxRounded}
                    color="red"
                />
                <CheckBox
                    theme={theme}
                    checked={isCheckboxChecked}
                    onChange={(value) => {
                        setIsCheckboxChecked(value);
                    }}
                    label="texto largo"
                    sublabel="texto aun más largo"
                    htmlFor="checkbox"
                    intermediate={true}
                    disabled={false}
                    rounded={isCheckboxRounded}
                    color="red"
                />
                <CheckBox
                    theme={theme}
                    checked={isCheckboxChecked}
                    onChange={(value) => {
                        setIsCheckboxChecked(value);
                    }}
                    label="texto largo"
                    sublabel="texto aun más largo"
                    htmlFor="checkbox"
                    intermediate={true}
                    disabled={true}
                    rounded={isCheckboxRounded}
                    color="red"
                />
            </>
        </Section>
    );
};
