import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";
import { Collapsable } from "app/components_v2/Collapsable/Collapsable";
import { GroupedTaskField } from "./GroupedTaskField/GroupedTaskField";
import { groupTaskInstanceFieldsByAssetId } from "app/helpers/taskInstanceFields/groupTaskInstanceFieldsByAssetId";

type TaskFieldsProps = {
    fields: TaskInstanceFieldModel[];
    customSetFields: (e: TaskInstanceFieldModel) => void;
    readOnly?: boolean;
    customErrors?: Map<number | string, string> | undefined;
    errorRef?: (ref: HTMLDivElement | null) => void;
    disabled?: boolean;
    statusCode: string;
    taskinstanceId: number;
};

export const TaskFields: FC<TaskFieldsProps> = ({
    fields,
    customSetFields,
    readOnly = false,
    customErrors,
    errorRef,
    disabled,
    statusCode,
    taskinstanceId,
}) => {
    const { t } = useTranslation();
    const title = t(TranslationKeys.TASK_DETAIL_DATA_TO_REPORT_TITLE);

    return (
        <div className="taskFields__container">
            <Collapsable title={title}>
                <div className="taskFields__content">
                    {groupTaskInstanceFieldsByAssetId(fields).map((groupedFields, i) => (
                        <GroupedTaskField
                            key={i}
                            groupedFields={groupedFields}
                            customSetFields={customSetFields}
                            readOnly={readOnly}
                            customErrors={customErrors}
                            disabled={disabled}
                            errorRef={errorRef}
                            statusCode={statusCode}
                            taskinstanceId={taskinstanceId}
                        />
                    ))}
                </div>
            </Collapsable>
        </div>
    );
};
