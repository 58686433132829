import { FC } from "react";
import { NotificationIcon } from "../NotificationIcon/NotificationIcon";
import { NotificationVariantModel } from "app/models/NotificationVariant";
import {
    getDayMonthAndTimeFormatted,
    getDayMonthFormatedWithYearAbbreviated,
} from "app/helpers/Utilities/date/Date.utilities";

type NotificationPillProps = {
    title: string;
    body?: string;
    date?: Date;
    seen?: boolean;
    onClick?: () => void;
    variant?: NotificationVariantModel;
    profilePictureURL?: string;
    nameInitials?: string;
    userId?: number;
};

export const NotificationPill: FC<NotificationPillProps> = ({
    body,
    title,
    date,
    seen,
    onClick,
    variant = "primary",
    profilePictureURL,
    nameInitials,
    userId,
}) => {
    const formatDate = () => {
        if (!date) return "";
        const dateYear = date.getFullYear();
        const currentYear = new Date().getFullYear();

        if (dateYear === currentYear) return getDayMonthAndTimeFormatted(date, true);

        return getDayMonthFormatedWithYearAbbreviated(date.toString(), " ", 0);
    };

    return (
        <div className="notificationPill" onClick={onClick}>
            <NotificationIcon
                nameInitials={nameInitials}
                variant={variant}
                profilePictureURL={profilePictureURL}
                userId={userId}
            />

            <div className="notificationPill__content">
                <div className="notificationPill__content__header">
                    <h2 className={`notificationPill__content__header__title--clamp${body ? "1" : "3"}`}>{title}</h2>
                    {date && <p className="notificationPill__content__header__date">{formatDate()}</p>}
                </div>
                {body && <p className="notificationPill__content__body">{body}</p>}
            </div>
            {seen && <span className="notificationPill__notification" />}
        </div>
    );
};
