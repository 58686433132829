import { IssueServiceType } from "app/shared/types/IssueTypes";
import { convertAndCompressImages } from "app/helpers/Media/convertAndCompressImage";
import { FormEvent, useContext, useEffect, useRef, useState } from "react";
import { FormIssueModel } from "app/models/05-QUA/IssueModels/FormIssueModels";
import { IssueInsertModel, IssueModel } from "app/models/05-QUA/IssueModels/IssueModel";
import { ActionType, OptionsSearch } from "app/models/FormComponentsModel";
import ServiceResponse from "app/models/ServiceResponse/ServiceResponse";
import IssueService from "app/services/05-QUA/IssueService";
import { IssueModalContext } from "app/state/context/issueModalContext/issueModalContext";
import { IssueModalFormErrorModel } from "./types";
import { MAX_ALLOWED_ISSUE_SUPERVISORS } from "app/shared/Constants";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { scrollIntoView } from "app/helpers/Utilities/window/scrollIntoView";
import { useFetchErrors } from "../useFetchErrors";
import { useSession } from "../useSession";
import { useToast } from "../Toast/useToast";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import {
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import AuditInstanceIssueService from "app/services/05-QUA/AuditInstanceIssueService";
import { ImageDragFile } from "app/components_v2/__draggableFiles/DragFile/types";
import RepSelectorService from "app/services/03-REP/RepSelectorService";
import QuaSelectorService from "app/services/05-QUA/QuaSelectorService";
import { getInitials } from "app/helpers/Avatar/getInitials";
import { fillIssueExtraParams } from "./helpers/fillIssueModalExtraparams";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import AssetIssueService from "app/services/05-QUA/AssetIssueService";
import ManualIssueService from "app/services/05-QUA/ManualIssueService";
import TaskIssueService from "app/services/05-QUA/TaskIssueService";
import { FetchService } from "app/services";
import { EditIssueDto } from "app/dtos/05-QUA/Issue/EditIssueDto";
import { EditIssueAttachmentDto } from "app/dtos/05-QUA/Issue/EditIssueAttachmentDto";

const INITIAL_FORM_VALUES: FormIssueModel = {
    description: "",
    supervisors: [],
    fk_Asset: "-1",
    attachments: [],
    isCritic: false,
    createTask: false,
    fK_IssueClassification: "-1",
};

const INITIAL_FORM_ERRORS: IssueModalFormErrorModel = {
    errorDescription: "",
    errorSupervisor: "",
};

type UseIssueModalProps = {
    type: IssueServiceType;
    issueId: number | undefined;
    onOpenWizardTask?: (value: boolean) => void;
    setIssueId?: (value: number) => void;
};

export const useIssueModal = ({ type, onOpenWizardTask, setIssueId, issueId }: UseIssueModalProps) => {
    const {
        issueType,
        taskInstanceId,
        reset,
        assetId,
        assetNotWorking,
        auditInfo,
        auditInstanceId,
        auditGroupChecklistInstanceId,
        companyId,
        taskInstanceFieldId,
        issueServiceType,
        close,
    } = useContext(IssueModalContext);

    const { handleToast, removeToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const session = useSession();
    const { t } = useTranslation();

    const [formValues, setFormValues] = useState<FormIssueModel>({
        ...INITIAL_FORM_VALUES,
        fk_Asset: assetId ? String(assetId) : INITIAL_FORM_VALUES.fk_Asset,
    });
    const [errorFormValues, setErrorFormValues] = useState<IssueModalFormErrorModel>(INITIAL_FORM_ERRORS);
    const [supervisorsOptions, setSupervisorsOptions] = useState<OptionModel[]>([]);
    const [assetOptions, setAssetOptions] = useState<OptionModel[]>([]);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
    const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
    const [issue, setIssue] = useState<IssueModel | null>(null);
    const selectsRef = useRef<HTMLDivElement | null>(null);

    const { supervisors, description, fk_Asset, attachments, isCritic, createTask, fK_IssueClassification } =
        formValues;

    const serviceMap: Record<
        IssueServiceType,
        | typeof IssueService
        | typeof AuditInstanceIssueService
        | typeof AssetIssueService
        | typeof ManualIssueService
        | typeof TaskIssueService
    > = {
        ISSUE: IssueService,
        AUDIT: AuditInstanceIssueService,
        ASSET: AssetIssueService,
        MANUAL: ManualIssueService,
        TASK: TaskIssueService,
    };
    const service = serviceMap[type || issueServiceType];
    const actionType: ActionType = !!issueId ? "edit" : "create";

    const handleInputChange = (values: Partial<FormIssueModel>) => setFormValues((prev) => ({ ...prev, ...values }));

    const handleErrorChange = (values: Partial<IssueModalFormErrorModel>) =>
        setErrorFormValues((prev) => ({ ...prev, ...values }));

    const handleAddSupervisors = (values: OptionModel[]) => {
        if (values.length > MAX_ALLOWED_ISSUE_SUPERVISORS) {
            handleToast({
                variant: "danger",
                title: t(TranslationErrors.ERROR_MESSAGE_MAX_20_SUPERVISORS),
                type: "alert",
            });
            return;
        }
        handleErrorChange({ errorSupervisor: "" });
        handleInputChange({ supervisors: values });
    };

    const handleDeleteSupervisors = (id: string) => {
        const selectedSupervisors = supervisors.find(({ value }) => value === id);
        if (!selectedSupervisors) return;
        const supervisorsFiltered = supervisors.filter(({ value }) => value !== id);
        handleInputChange({ supervisors: supervisorsFiltered });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>, onCloseModal?: (isSubmitting: boolean) => void) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isFormValidate()) return;

        setIsFormSubmitting(true);

        const toastId = v4();
        if (attachments.length)
            handleToast({
                title: t(TranslationModals.ISSUE_IMAGE_LOADING_TITLE),
                subtitle: t(TranslationModals.ISSUE_IMAGE_LOADING_DESCRIPTION),
                variant: "primary",
                type: "alert",
                isLoading: true,
                id: toastId,
            });

        if (actionType === "create") {
            await handleCreateIssue(toastId, onCloseModal);
            setIsFormSubmitting(false);
            return;
        }

        await handleEditIssue(toastId, onCloseModal);
        setIsFormSubmitting(false);
    };

    const handleCreateIssue = async (toastId: string, onCloseModal?: (isSubmitting: boolean) => void) => {
        const isAssetSelected = !!fk_Asset.length && fk_Asset !== "-1";
        const companyIdFetch = companyId || session?.workingCompany?.companyId;

        const issueToCreate: IssueInsertModel = {
            description,
            issueType,
            audioBase64Content: "",
            fK_TaskInstance: taskInstanceId,
            issueAttachments: attachments.map(({ base64 }) => ({
                fileBase64Content: base64 || "",
            })),
            fK_Asset: isAssetSelected ? Number(fk_Asset) : null,
            supervisorIds: supervisors.map(({ value }) => Number(value)),
            fK_Company: Number(companyIdFetch),
            assetNotWorking: assetNotWorking != null ? assetNotWorking : undefined,
            isCreated: issueType === "AUDIT" || !!taskInstanceFieldId ? false : true,
            fK_AuditInstanceId: auditInstanceId || undefined,
            fK_AuditInstanceChecklistItemId: auditGroupChecklistInstanceId || undefined,
            isCritical: isCritic,
            fK_IssueClassification: fK_IssueClassification === "-1" ? null : Number(fK_IssueClassification),
            fK_TaskInstanceField: taskInstanceFieldId,
        };

        const { data, status } = await service.Save(issueToCreate);

        removeToast(toastId);

        if (!status()) {
            handleToast({
                title: t(TranslationErrors.ERROR_MESSAGE_ISSUE_NOT_CREATED),
                type: "alert",
                variant: "danger",
            });
            return;
        }

        handleToast({
            title: t(TranslationKeys.ISSUE_CREATED_SUCCESSFULLY),
            type: "alert",
            variant: "success",
        });

        reset();
        onCloseModal && onCloseModal(true);

        // If createTask = true => open Wizard Task
        if (createTask) {
            setIssueId && setIssueId(data);
            onOpenWizardTask && onOpenWizardTask(true);
        }
    };

    const handleEditIssue = async (toastId: string, onCloseModal?: (isSubmitting: boolean) => void) => {
        if (!issueId) return;
        if (!issue) return;
        if (issueType !== "AUDIT") return;

        const editIssueDto: EditIssueDto = {
            description: description,
            attachments: attachments.map<EditIssueAttachmentDto>(({ base64, fileName, dbId, id, isDeleted }) => ({
                fileBase64Content: base64 || "",
                fileName,
                id: dbId || 0,
                frontId: id,
                imageBase64Content: base64 || "",
                isDeleted: !!isDeleted,
            })),
            isCritical: isCritic,
            issueClassificationId: fK_IssueClassification === "-1" ? null : Number(fK_IssueClassification),
        };

        const { status } = await AuditInstanceIssueService.Edit(issueId, editIssueDto);
        removeToast(toastId);

        if (!status()) {
            handleToast({
                title: t(TranslationErrors.ERROR_MESSAGE_ISSUE_NOT_CREATED),
                type: "alert",
                variant: "danger",
            });
            return;
        }

        handleToast({
            title: t(TranslationKeys.ISSUE_CREATED_SUCCESSFULLY),
            type: "alert",
            variant: "success",
        });

        reset();
        onCloseModal && onCloseModal(true);

        // If createTask = true => open Wizard Task
        // And If issue before edit has no task assigned
        if (createTask && issue.fK_Task == null) {
            setIssueId && setIssueId(issueId);
            onOpenWizardTask && onOpenWizardTask(true);
        }
    };

    const isFormValidate = (): boolean => {
        let isValid = true;
        handleErrorChange(INITIAL_FORM_ERRORS);
        if (!description.length) {
            handleErrorChange({ errorDescription: t(TranslationCommon.INPUT_NOT_EMPTY) });
            isValid = false;
        }

        if (!supervisors.length && issueType !== "AUDIT" && !taskInstanceFieldId) {
            if (isValid) scrollIntoView(selectsRef.current);
            handleErrorChange({
                errorSupervisor: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION),
            });
            isValid = false;
        }
        return isValid;
    };

    const getData = async () => {
        setIsDataLoading(true);
        const companyIdFetch = companyId || session?.workingCompany?.companyId;
        const userType = session?.user.userType || "WORKER";
        const extraParams = fillIssueExtraParams({
            companyId: companyIdFetch,
            userId: session?.user.id,
            userType,
        });

        const userSelectorPromise =
            userType === "WORKER"
                ? RepSelectorService.GetSupervisorAndAnalyst({
                      extraParams,
                  })
                : SegSelectorService.GetUserNamesWithIds({
                      extraParams,
                  });

        const assetPromise = QuaSelectorService.GetAssets({
            extraParams: `companyId=${companyIdFetch}&IsDeleted=false`,
        });

        const issuePromise: Promise<ServiceResponse<IssueModel>> = !!issueId
            ? type === "AUDIT"
                ? AuditInstanceIssueService.GetOne(issueId)
                : IssueService.GetOne(issueId)
            : FetchService.emptyResponse<IssueModel>();

        Promise.all([userSelectorPromise, assetPromise, issuePromise])
            .then(([userSelectorSr, assetSr, issueSr]) => {
                supervisorsOrAnalistsResponseManager(userSelectorSr);
                assetResponseManager(assetSr);
                issueResponseManager(issueSr);
            })
            .finally(() => {
                setIsDataLoading(false);
            });
    };

    const supervisorsOrAnalistsResponseManager = ({ data, status, getParsedError }: ServiceResponse<OptionModel[]>) => {
        if (!status()) {
            handleToast({
                variant: "danger",
                title: getErrorMessage(getParsedError()),
                type: "alert",
            });
            setSupervisorsOptions([]);
            return;
        }

        setSupervisorsOptions(
            data.map(({ label, profilePictureURL, value }) => ({
                label,
                profilePictureURL,
                value,
                initials: getInitials(label),
            }))
        );
    };

    const assetResponseManager = ({ data, status, getParsedError }: ServiceResponse<OptionsSearch[]>) => {
        if (!status()) {
            handleToast({
                variant: "danger",
                title: getErrorMessage(getParsedError()),
                type: "alert",
            });
            setAssetOptions([]);
            return;
        }

        setAssetOptions([{ label: t(TranslationCommon.SELECT_OPTION), value: "-1" }, ...data]);
    };

    const issueResponseManager = ({ data, status, getParsedError }: ServiceResponse<IssueModel>) => {
        if (!status()) {
            handleToast({
                variant: "danger",
                title: getErrorMessage(getParsedError()),
                type: "alert",
            });
            return;
        }
        if (data == null) return;
        setIssue(data);
        setFormValues({
            attachments: data.issueAttachments.map((attachment, i) => ({
                fileName: `Issue_Photo_${i + 1}`,
                base64: attachment.fileBase64Content,
                id: v4(),
                dbId: attachment.id,
                isErrored: false,
                isLoading: false,
                url: attachment.fileName,
            })),
            createTask: !!data.fK_Task,
            description: data.description,
            fk_Asset: data.fK_Asset ? String(data.fK_Asset) : "-1",
            fK_IssueClassification: data.fK_IssueClassification ? String(data.fK_IssueClassification) : "-1",
            isCritic: !!data.isCritical,
            supervisors: data.receivers,
        });
    };

    const handleChangeDragFile = async (imagesFiles: ImageDragFile[]) => {
        const newAttachments = [...attachments, ...imagesFiles];
        handleInputChange({
            attachments: newAttachments,
        });

        const photosConverted = await convertAndCompressImages(imagesFiles);
        handleInputChange({
            attachments: newAttachments.map((photo) => {
                const photoConverted = photosConverted.find(({ id }) => photo.id === id);
                return {
                    ...photo,
                    isLoading: false,
                    isErrored: false,
                    base64: photoConverted ? photoConverted.imageBase64Content : photo.base64,
                };
            }),
        });
    };

    const handleDeleteImage = (id: string) => {
        handleInputChange({
            attachments: attachments
                .map((attachment) => (attachment.id === id ? { ...attachment, isDeleted: true } : attachment))
                .filter((attachment) => (!attachment.isDeleted && !attachment.dbId) || attachment.dbId),
        });
    };

    const handleEditImage = (image: ImageDragFile, editedImageId: string) => {
        const attachmentsWithEditedImage = attachments.map((photo) =>
            photo.id === editedImageId ? { ...image, isLoading: false } : photo
        );
        handleInputChange({ attachments: attachmentsWithEditedImage });
    };

    useEffect(() => {
        getData();
    }, []);

    return {
        ...errorFormValues,
        ...formValues,
        assetOptions,
        auditInfo,
        auditInstanceId,
        errorFormValues,
        formValues,
        handleAddSupervisors,
        handleChangeDragFile,
        handleDeleteImage,
        handleDeleteSupervisors,
        handleEditImage,
        handleInputChange,
        handleSubmit,
        isDataLoading,
        isFormSubmitting,
        issueType,
        selectsRef,
        supervisorsOptions,
        taskInstanceFieldId,
        close,
        actionType,
    };
};
