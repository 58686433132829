import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSuccessManager } from "app/hooks/SuccessHandler/useSuccessManager";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useSelectCompanyTable } from "app/components_v2/Table/hooks/useSelectCompanyTable";
import NotificationDistributionListService from "app/services/01-SEG/NotificationDistributionListService";
import { NotificationDistributionListTranslations, TranslationKeys } from "app/translation/translationKeys";
import { fillExtraParams } from "../helpers/notificationDistributionListHelper";
import { PaginationDefaults } from "app/shared/Constants";
import {
    INITIAL_NOTIFICATION_DISTRIBUTION_LIST_SELECTED,
    INITIAL_NOTIFICATION_DISTRIBUTION_LIST_SF,
} from "../constants/NotificationDistributionListConstants";
import { SortedTypeModel } from "app/components_v2/Table/types";
import {
    NotificationDistributionListGridModel,
    NotificationDistributionListGridSF,
} from "../models/NotificationDistributionListGridModels";
import { NotificationDistributionListModel } from "app/models/01-SEG/NotificationDistributionList/NotificationDistributionListModel";
import {
    NotificationDistributionListFormValues,
    SelectedNotificationDistributionList,
} from "../models/NotificationDistributionListFormValues";
import { ActionType } from "app/models/FormComponentsModel";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { NotificationDistributionListDto } from "app/dtos/01-SEG/NotificationDistributionListDto/NotificationDistributionListDto";

export const useNotificationDistributionList = () => {
    const { t } = useTranslation();
    const { getErrorMessage } = useFetchErrors();
    const { handleErrorManager } = useErrorManager();
    const { handleSuccessManager } = useSuccessManager();
    const { filterIcon, handleCompanyChange, selectCompany } = useSelectCompanyTable();
    const { changeWorkingCompanyByCompanyId } = useChangeWorkingSession();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const [notificationDistributionList, setNotificationDistributionList] = useState<
        NotificationDistributionListModel[]
    >([]);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [sortField, setSortField] = useState<string>("");
    const [sortDirection, setSortDirection] = useState<SortedTypeModel>("default");
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [currentNotificationDistributionList, setCurrentNotificationDistributionList] =
        useState<SelectedNotificationDistributionList>(INITIAL_NOTIFICATION_DISTRIBUTION_LIST_SELECTED);
    const [sfValues, setSfValues] = useState<NotificationDistributionListGridSF>(
        INITIAL_NOTIFICATION_DISTRIBUTION_LIST_SF
    );
    const [isSecondaryFilterOpened, setIsSecondaryFilterOpened] = useState<boolean>(false);
    const [confirmModalIsLoading, setConfirmModalIsLoading] = useState<boolean>(false);

    const getNotificationDistributionList = async ({
        pi,
        sortF,
        sortD,
        companyId,
        sfData,
    }: NotificationDistributionListGridModel = {}) => {
        setIsLoading(true);

        const selectedCompany = companyId ?? selectCompany.company.value;
        const filterParams = sfData || sfValues;
        const { functionality } = filterParams;

        const { data, status, getParsedError } = await NotificationDistributionListService.GetData({
            pageSize: PaginationDefaults.PAGE_SIZE,
            pageIndex: pi !== undefined ? pi : pageIndex - 1,
            sortField: sortF ?? sortField,
            sortDirection: sortD ?? sortDirection,
            extraParams: fillExtraParams({ functionality }, selectedCompany),
        });

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            setIsLoading(false);
            setNotificationDistributionList([]);
            setTotal(0);
        }
        setNotificationDistributionList(data.list);
        setTotal(data.count);
        setIsLoading(false);
    };

    const onChangePageIndex = (pi: number) => {
        setPageIndex(pi);
        getNotificationDistributionList({ pi: pi - 1 });
    };

    const onChangeSortDirectionField = (sortF: string, sortD?: SortedTypeModel) => {
        setSortField(sortF);
        setSortDirection(sortD ?? "default");
        getNotificationDistributionList({ sortF, sortD });
    };

    const onChangeCompany = ({ label, value }: OptionModel) => {
        handleCompanyChange({
            company: { label, value },
            isOpen: false,
        });
        setPageIndex(1);
        getNotificationDistributionList({ pi: 0, companyId: value });
        value !== "-1" && changeWorkingCompanyByCompanyId(Number(value));
    };

    const onAddNew = () => {
        setCurrentNotificationDistributionList(INITIAL_NOTIFICATION_DISTRIBUTION_LIST_SELECTED);
        setShowCreateModal(true);
    };

    const onClickOutsideCompany = () => handleCompanyChange({ isOpen: false });

    const onClickEdit = ({
        id,
        fK_Company,
        fK_DistributionList,
        users,
        emails,
        functionality,
        companyName,
        notifyWorkers,
        notifySupervisors,
        notifyAnalists,
        notifyAdminClients,
    }: NotificationDistributionListModel) => {
        setCurrentNotificationDistributionList({
            id,
            functionality,
            companyName,
            fK_Company: fK_Company === null ? "" : String(fK_Company),
            fK_DistributionList: String(fK_DistributionList),
            userList: users.map(({ fK_User, username, profilePictureURL, initials }) => ({
                label: username,
                value: fK_User.toString(),
                profilePictureURL,
                initials,
            })),
            emails: emails?.emails.split(";") || [],
            notifyWorkers,
            notifySupervisors,
            notifyAnalists,
            notifyAdminClients,
        });
        setShowEditModal(true);
    };

    const onClickDelete = ({
        id,
        functionality,
        companyName,
        fK_Company,
        fK_DistributionList,
        users,
        emails,
        notifyWorkers,
        notifySupervisors,
        notifyAnalists,
        notifyAdminClients,
    }: NotificationDistributionListModel) => {
        setCurrentNotificationDistributionList({
            id,
            functionality,
            companyName,
            fK_Company: fK_Company === null ? "" : String(fK_Company),
            fK_DistributionList: String(fK_DistributionList),
            userList: users.map(({ fK_User, username, profilePictureURL, initials }) => ({
                label: username,
                value: fK_User.toString(),
                profilePictureURL,
                initials,
            })),
            emails: emails?.emails.split(";") || [],
            notifyWorkers,
            notifySupervisors,
            notifyAnalists,
            notifyAdminClients,
        });
        setShowDeleteModal(true);
    };

    const handleSubmit = async (
        {
            companyList,
            fK_DistributionList,
            userList,
            fK_Company,
            emails,
            notifyWorkers,
            notifySupervisors,
            notifyAnalists,
            notifyAdminClients,
        }: NotificationDistributionListFormValues,
        type: ActionType
    ) => {
        const body: NotificationDistributionListDto = {
            companyList: companyList.map(({ value }) => Number(value)),
            fK_DistributionList: Number(fK_DistributionList),
            userIdList: userList.map(({ value }) => Number(value)),
            fK_Company: !!fK_Company?.length ? Number(fK_Company) : null,
            emails: !!emails.length ? emails.join(";") : "",
            notifyWorkers,
            notifySupervisors,
            notifyAnalists,
            notifyAdminClients,
        };
        const service =
            type === "create"
                ? NotificationDistributionListService.Save(body)
                : NotificationDistributionListService.Edit(currentNotificationDistributionList.id, body);
        const closeModal = type === "create" ? setShowCreateModal : setShowEditModal;
        const successMessage =
            type === "create"
                ? t(NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_SAVE_SUCCESS)
                : t(NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_EDIT_SUCCESS);

        const { status, getParsedError } = await service;

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            closeModal(false);
            setIsLoading(false);
            return;
        }

        handleSuccessManager(successMessage);
        changeWorkingCompanyByCompanyId(Number(currentNotificationDistributionList.fK_Company));
        getNotificationDistributionList();
        closeModal(false);
        setIsLoading(false);
    };

    const handleDelete = async () => {
        setIsLoading(true);
        setConfirmModalIsLoading(true);

        const { status, getParsedError } = await NotificationDistributionListService.Delete(
            currentNotificationDistributionList.id
        );
        setConfirmModalIsLoading(false);

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            setShowDeleteModal(false);
            setIsLoading(false);
            return;
        }

        handleSuccessManager(t(NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_SUCCESS_DELETE));
        getNotificationDistributionList();

        setShowDeleteModal(false);
        setIsLoading(false);
    };

    const handleChangeSFValues = (values: Partial<NotificationDistributionListGridSF>) =>
        setSfValues((prev) => ({ ...prev, ...values }));

    const onClickFilterIcon = () => handleCompanyChange({ isOpen: true });

    const handleSaveSF = async (values: NotificationDistributionListGridSF) => {
        handleChangeSFValues(values);
        setIsSecondaryFilterOpened(false);
        getNotificationDistributionList({ pi: 0, sfData: values });
        setPageIndex(1);
    };

    useEffect(() => {
        handleCompanyChange({
            company: {
                label: translateCustomerTypeKeys(TranslationKeys.ALL_COMPANIES, {
                    firstReplace: "PLURAL",
                    isGenderSensitive: true,
                }),
                value: "-1",
            },
        });
        getNotificationDistributionList({ companyId: "-1" });
    }, []);

    return {
        currentNotificationDistributionList,
        filterIcon,
        handleSaveSF,
        handleSubmit,
        isLoading,
        isSecondaryFilterOpened,
        notificationDistributionList,
        onAddNew,
        onChangeCompany,
        onChangePageIndex,
        onChangeSFValues: handleChangeSFValues,
        onChangeShowCreateModal: setShowCreateModal,
        onChangeShowDeleteModal: setShowDeleteModal,
        onChangeShowEditModal: setShowEditModal,
        onChangeSortDirectionField,
        onClickDelete,
        onClickEdit,
        onClickFilterIcon,
        onClickOutsideCompany,
        onDelete: handleDelete,
        onOpenSecondaryFilter: setIsSecondaryFilterOpened,
        pageIndex,
        selectCompany,
        sfValues,
        showCreateModal,
        showDeleteModal,
        showEditModal,
        sortDirection,
        sortField,
        total,
        confirmModalIsLoading,
    };
};
