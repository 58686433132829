import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
    AuditGroupCheckListItemTranslations,
    BaseAuditGroupCheckListItemTranslations,
    TranslationCommon,
    TranslationKeys,
} from "app/translation/translationKeys";
import { isEqual } from "lodash";
import { AuditGroupCheckListModalProps } from "./types/AuditGroupCheckListModalTypes";
import { useAuditGroupCheckListModal } from "./hooks/useAuditGroupCheckListModal";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { Input, Label, SelectOptions } from "app/components_v2/__inputs";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { AuditGroupCheckListItem } from "./AuditGroupCheckListItem/AuditGroupCheckListItem";

export const AuditGroupCheckListModal: FC<AuditGroupCheckListModalProps> = ({
    data,
    onCloseModal,
    onSubmit,
    isLoading,
}) => {
    const { t } = useTranslation();

    const {
        baseAuditGroupCheckListItemOptions,
        auditGroupCheckListItemSelectedOptions,
        isLoading: optionsLoading,
        errorMessage,
        itemErrorMessage,
        name,
        onChangeName,
        onChange,
        onChangePonderationLevel,
        onDeleteItem,
        handleSubmit,
        hasChanges,
        onHasChanges,
        nameCopy,
        auditGroupCheckListItemSelectedOptionsCopy,
        onChangeAuditGroupCheckListItemSelectedOptions,
    } = useAuditGroupCheckListModal(data, onSubmit);

    const handleCloseHasChangesModal = () => {
        onChangeAuditGroupCheckListItemSelectedOptions(auditGroupCheckListItemSelectedOptionsCopy);
        onChangeName(nameCopy);
        onCloseModal();
    };

    const handleCloseModal = () => {
        const isNotEqual =
            !isEqual(name, nameCopy) ||
            !isEqual(auditGroupCheckListItemSelectedOptions, auditGroupCheckListItemSelectedOptionsCopy);
        if (isNotEqual) {
            onHasChanges(true);
            return;
        }
        onCloseModal();
    };

    return (
        <>
            {hasChanges && (
                <ConfirmModal
                    title={t(
                        AuditGroupCheckListItemTranslations.AUDIT_GROUP_CHECK_LIST_ITEM_CANCEL_CHANGES_TITLE_LABEL
                    )}
                    description={t(
                        AuditGroupCheckListItemTranslations.AUDIT_GROUP_CHECK_LIST_ITEM_CANCEL_CHANGES_DESCRIPTION_LABEL
                    )}
                    onConfirmText={t(TranslationCommon.CONFIRM)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onConfirm={handleCloseHasChangesModal}
                    onClose={() => onHasChanges(false)}
                    type="delete"
                />
            )}
            <GenericModal
                size="xl"
                header={{
                    title: t(TranslationKeys.EDIT_AUDIT_GROUP_CHECKLIST),
                    onClose: handleCloseModal,
                    variant: "appcc",
                }}
                footer={
                    <GenericModalFooter
                        confirmButton={{
                            text: t(TranslationCommon.SAVE),
                            form: "edit",
                            type: "submit",
                            variant: "appcc",
                        }}
                        closeButton={{ text: t(TranslationCommon.CANCEL), onClick: handleCloseModal, variant: "appcc" }}
                        loading={isLoading}
                    />
                }
            >
                <form id="edit" onSubmit={handleSubmit} className="auditGroupCheckListModal">
                    <Input
                        label={t(TranslationKeys.AUDIT_GROUP_CHECKLIST_NAME_LABEL)}
                        placeholder={t(TranslationKeys.AUDIT_GROUP_CHECKLIST_NAME_LABEL)}
                        value={name}
                        onChange={onChangeName}
                        errorMessage={errorMessage}
                        focus
                    />
                    {!!auditGroupCheckListItemSelectedOptions.filter(({ isDeleted }) => !isDeleted).length && (
                        <div className="auditGroupCheckListModal__items">
                            <Label
                                label={t(
                                    AuditGroupCheckListItemTranslations.AUDIT_GROUP_CHECKLIST_ITEM_PONDERATION_LABEL
                                )}
                                className="auditGroupCheckListModal__items__label"
                            />
                            {auditGroupCheckListItemSelectedOptions
                                .filter(({ isDeleted }) => !isDeleted)
                                .sort((a, b) => (a.order || 0) - (b.order || 0))
                                .map((agcli, i) => (
                                    <AuditGroupCheckListItem
                                        key={i}
                                        data={agcli}
                                        onChangeItem={onChangePonderationLevel}
                                        onDeleteItem={onDeleteItem}
                                    />
                                ))}
                        </div>
                    )}
                    <SelectOptions
                        label={t(
                            BaseAuditGroupCheckListItemTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_SELECTOR_LABEL
                        )}
                        placeholder={t(
                            BaseAuditGroupCheckListItemTranslations.BASE_AUDIT_GROUP_CHECKLIST_ITEM_SELECTOR_LABEL
                        )}
                        onChange={onChange}
                        options={baseAuditGroupCheckListItemOptions}
                        errorMessage={itemErrorMessage}
                        isMulti={false}
                        isSearchable
                        isLoading={optionsLoading}
                    />
                </form>
            </GenericModal>
        </>
    );
};
