import { Button } from "app/components_v2/__buttons/Button/Button";
import { faPaperPlane, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FC } from "react";
import isiOS from "app/helpers/Mobile/isIos";

type AlertFloatingButtonsProps = {
    showDeleteButton: boolean;
    showOpenNotificationButton: boolean;
    onClickOpenNotificationModal: () => void;
    onClickDeleteAlerts: () => void;
};

export const AlertFloatingButtons: FC<AlertFloatingButtonsProps> = ({
    onClickDeleteAlerts,
    onClickOpenNotificationModal,
    showDeleteButton,
    showOpenNotificationButton,
}) => {
    return (
        <div className={`alertFloatingButtons ${isiOS() ? "floatingButton--ios" : ""}`}>
            {showDeleteButton && <Button iconLeft={faTrash} onClick={onClickDeleteAlerts} variant="danger" />}
            {showOpenNotificationButton && <Button iconLeft={faPaperPlane} onClick={onClickOpenNotificationModal} />}
        </div>
    );
};
