import { useEffect, useState } from "react";
import AlertNotificationService from "app/services/02-TAR/AlertNotificationService";
import { AlertNotificationDto } from "app/dtos/02-TAR/AlertNotification/AlertNotificationDto";
import { receivedNotificationsExtraParams } from "app/helpers/__extraParams/notifications/receivedNotificationsExtraParams";

type NotificationCounts = {
    received: number;
    sent: number;
};

export const useNotification = (id?: number, refresh?: any, notSeen?: boolean) => {
    const [receivedNotificationList, setReceivedNotificationList] = useState<AlertNotificationDto[]>([]);
    const [sentNotificationList, setSentNotificationList] = useState<AlertNotificationDto[]>([]);

    const [totalCounts, setTotalCounts] = useState<NotificationCounts>({ received: 0, sent: 0 });

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const onChangeCount = (value: Partial<NotificationCounts>) => {
        setTotalCounts((prev) => ({ ...prev, ...value }));
    };

    const fetchNotifications = async () => {
        setIsLoading(true);
        const received = AlertNotificationService.GetData({
            extraParams: receivedNotificationsExtraParams({
                notSeen,
                receiverUserId: id,
            }),
        });

        const sent = AlertNotificationService.GetData({
            extraParams: `SenderUserId=${id}`,
        });

        const [{ data: receivedData, status: receivedStatus }, { data: sentData, status: sentStatus }] =
            await Promise.all([received, sent]);

        if (receivedStatus() && sentStatus()) {
            setReceivedNotificationList(receivedData.list);
            setSentNotificationList(sentData.list);
            onChangeCount({ received: receivedData.count, sent: sentData.count });
        }
        setIsLoading(false);
    };

    const handleDeleteRecivedNotificationCounter = () => onChangeCount({ received: totalCounts.received - 1 });

    const handleDeleteSentNotificationCounter = () => onChangeCount({ sent: totalCounts.sent - 1 });

    useEffect(() => {
        fetchNotifications();
    }, [id, refresh]);

    return {
        receivedNotificationList,
        setReceivedNotificationList,
        sentNotificationList,
        setSentNotificationList,
        handleDeleteRecivedNotificationCounter,
        handleDeleteSentNotificationCounter,
        totalCounts,
        isLoading,
    };
};
