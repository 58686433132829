import { faPaperPlane } from "@fortawesome/pro-regular-svg-icons";
import { DateRangePicker } from "app/components_v2/__inputs";
import { NotificationsModal } from "app/components_v2/__modals/NotificationsModal/NotificationsModal";
import { AvatarStarRating } from "app/components_v2/AvatarStarRating/AvatarStarRating";
import { BreadcrumbModel, BreadCrumbs } from "app/components_v2/BreadCrumbs";
import { Icon } from "app/components_v2/Icon/Icon";
import { ReviewWorkerDto } from "app/dtos/02-TAR/ReviewWorker/ReviewWorkerDto";
import { translateHelper } from "app/helpers/Translations/translateCrumb";
import { isUserTypeSupervisorOrAnalistOrAdminClient } from "app/helpers/User/userType/userTypeCheck";
import { useGoBack } from "app/hooks";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { DateRange } from "app/models/utilities";
import { hasPermissionToAdd } from "app/routes/HelperRoleBasedAccess";
import { screenSize, SecScreen } from "app/shared/Constants";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "usehooks-ts";

type ReviewWorkerHeaderProps = {
    user?: ReviewWorkerDto;
    userId: number;
    isLoading: boolean;
    onDateChange: (dates: DateRange) => void;
    startDate?: Date;
    endDate?: Date;
};

export const ReviewWorkerHeader: FC<ReviewWorkerHeaderProps> = ({
    user,
    userId,
    isLoading,
    onDateChange,
    endDate,
    startDate,
}) => {
    const { t } = useTranslation();
    const { getOriginPathname } = useGoBack();
    const { width } = useWindowSize();
    const { translateCompany } = useCustomerTypeTranslation();

    const [isNotficationModalOpened, setIsNotificationModalOpened] = useState(false);

    const crumb: BreadcrumbModel[] = [
        {
            name: translateHelper(getOriginPathname(), t, translateCompany),
            to: `/${getOriginPathname()}`,
        },
    ];

    return (
        <>
            <div className="reviewWorker__crumbHeader">
                <div className="flex--between">
                    <BreadCrumbs crumbs={crumb} />
                    {hasPermissionToAdd(SecScreen.ALERTS) && (
                        <Icon icon={faPaperPlane} onClick={() => setIsNotificationModalOpened(true)} />
                    )}
                </div>
                <div className="reviewWorker__header__items">
                    <div className="reviewWorker__userAvatar">
                        <AvatarStarRating
                            fullName={`${user?.firstName} ${user?.lastName}`}
                            userId={userId}
                            starRatingAVG={user?.starRatingAVG || 0}
                            total={user?.totalTasksRatedCount || 0}
                            trend={Number((user?.trend || 0).toFixed(0))}
                            avatarImg={user?.profilePictureURL}
                            isLoading={isLoading}
                            isSupervisor={isUserTypeSupervisorOrAnalistOrAdminClient(user?.userType)}
                            showRating
                            showFullRatingText
                            starRatingBadgeDirection={width >= screenSize.TABLET ? "row" : "col"}
                        />
                    </div>
                    <div className="reviewWorker__header__items__date">
                        <DateRangePicker
                            inverted
                            onChange={onDateChange}
                            startDate={startDate}
                            endDate={endDate}
                            showMoreOptions
                        />
                    </div>
                </div>
            </div>

            {isNotficationModalOpened && (
                <NotificationsModal
                    onClose={() => setIsNotificationModalOpened(false)}
                    userId={userId}
                    profileURL={user?.profilePictureURL || undefined}
                    userName={`${user?.firstName} ${user?.lastName}`}
                />
            )}
        </>
    );
};
