import { FC } from "react";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { components, OptionProps } from "react-select";
import { Avatar } from "app/components_v2/Avatar/Avatar";

type OptionWithAvatarProps = OptionProps<OptionModel>;

export const OptionWithAvatar: FC<OptionWithAvatarProps> = ({ ...optionsProps }) => {
    const { data, getValue } = optionsProps;
    const { profilePictureURL, value, label, initials, avatarHexColor, subTitle } = data;

    if (value === "-1" || value === "" || !initials?.length)
        return <components.Option {...optionsProps}></components.Option>;

    const selectedValue = getValue();
    const isSelected = !!selectedValue.length && selectedValue[0].value === value;
    return (
        <components.Option {...optionsProps}>
            <div className={`optionsWithAvatar ${optionsProps.className}`}>
                <Avatar
                    colorId={!!value.length && !avatarHexColor?.length ? Number(value) : undefined}
                    img={profilePictureURL}
                    size="sm"
                    avatarName={label}
                    nameInitials={initials}
                    hexColor={avatarHexColor}
                />
                <div className="optionsWithAvatar__info">
                    <p className={`optionsWithAvatar__label ${isSelected ? "optionsWithAvatar__label--selected" : ""}`}>
                        {label}
                    </p>
                    {!!subTitle?.length && (
                        <p
                            className={`optionsWithAvatar__role ${
                                isSelected ? "optionsWithAvatar__role--selected" : ""
                            }`}
                        >
                            {subTitle}
                        </p>
                    )}
                </div>
            </div>
        </components.Option>
    );
};
