import { TaskStatus } from "app/shared/Constants";
import { convertUTCtoLocaleDate } from "app/helpers/Utilities/date/Date.utilities";
import { TaskInstanceListDto } from "app/dtos/02-TAR/TaskInstance/TaskInstanceListDto";

export const validateStatus = ({ statusCode, deadlineDate, finishedDate, taskType }: TaskInstanceListDto) => {
    if (taskType === "SPORADIC") return "PENDING";
    if (deadlineDate == null && statusCode === "COMPLETED") return "IN_TIME";

    const todayTime = new Date().getTime() / 1000 / 60;
    const deadlineDateTimeWithNoSeconds = new Date(deadlineDate || "");
    deadlineDateTimeWithNoSeconds.setSeconds(0);
    const deadlineDateTime = convertUTCtoLocaleDate(deadlineDateTimeWithNoSeconds).getTime() / 1000 / 60;
    let finishedDateTime = null;

    if (finishedDate) {
        const finishedDateMinusOneMinutes = new Date(finishedDate);
        finishedDateMinusOneMinutes.setSeconds(0);
        finishedDateTime = Number(
            (convertUTCtoLocaleDate(finishedDateMinusOneMinutes).getTime() / 1000 / 60).toFixed(0)
        );
    }

    switch (statusCode) {
        case TaskStatus.PENDING:
            if (deadlineDateTime - todayTime > 30) return "PENDING";
            else if (deadlineDateTime - todayTime <= 30 && deadlineDateTime - todayTime > -1) return "NEAR_END";
            return "TIMED_OUT";
        case TaskStatus.COMPLETED:
            if (finishedDateTime && finishedDateTime <= deadlineDateTime) return "IN_TIME";
            return "OUT_OF_TIME";
        default:
            return "PENDING";
    }
};
