import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { TranslationCommon } from "app/translation/translationKeys";
import { INITIAL_START_AUDIT_ERRORS, INITIAL_START_AUDIT_FORM_VALUES } from "../constants/StartAuditModalConstants";
import { StartAuditModalErrors, StartAuditModalFormValues } from "../types";
import AuditInstanceService from "app/services/05-QUA/AuditInstanceService";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import QuaSelectorService from "app/services/05-QUA/QuaSelectorService";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { getInitials } from "app/helpers/Avatar/getInitials";

export const useStartAuditModal = (onSubmit: (auditInstanceId: number, companyId: number) => void) => {
    const { t } = useTranslation();
    const session = useSession();
    const { handleToast } = useToast();
    const { changeWorkingCompanyByCompanyId } = useChangeWorkingSession();

    const [formValues, setFormValues] = useState<StartAuditModalFormValues>(INITIAL_START_AUDIT_FORM_VALUES);
    const [errorFormValues, setErrorFormValues] = useState<StartAuditModalErrors>(INITIAL_START_AUDIT_ERRORS);
    const [companyOptions, setCompanyOptions] = useState<OptionModel[]>([]);
    const [auditOptions, setAuditOptions] = useState<OptionModel[]>([]);
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

    const { auditId, companyId } = formValues;

    const handleInputChange = (values: Partial<StartAuditModalFormValues>) =>
        setFormValues((prev) => ({ ...prev, ...values }));

    const handleErrorChange = (values: Partial<StartAuditModalErrors>) =>
        setErrorFormValues((prev) => ({ ...prev, ...values }));

    const handleCompanyChange = (value: string) => {
        if (value === "-1") {
            handleInputChange({ auditId: "-1", companyId: "-1" });
            return;
        }

        handleInputChange({ companyId: value });
    };

    const handlestartAudit = async (): Promise<number | null> => {
        const { data, status } = await AuditInstanceService.StartAudit(Number(auditId), Number(companyId));

        if (!status()) {
            handleToast({ title: "No se ha Podido Iniciar la Auditoria", type: "alert", variant: "danger" });
            return null;
        }
        return data as number;
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!validate()) return;
        setIsSubmiting(true);

        const auditInstanceId = await handlestartAudit();
        if (!auditInstanceId) {
            setIsSubmiting(false);
            return;
        }

        onSubmit(auditInstanceId, Number(formValues.companyId));
        changeWorkingCompanyByCompanyId(Number(formValues.companyId));
        setIsSubmiting(false);
    };

    const getCompanies = async () => {
        const customerInstanceId = session?.user.id || 0;
        const { data, status } = await SegSelectorService.GetCompanyNamesWithIds({
            extraParams: `SupervisorId=${customerInstanceId}`,
        });

        if (!status()) {
            handleToast({ title: t(TranslationCommon.FAILED_DATA_LOADED), type: "alert", variant: "danger" });
            setCompanyOptions([]);
            return;
        }

        setCompanyOptions(
            data.map((data) => ({
                ...data,
                initials: getInitials(data.label),
            }))
        );
    };

    const getAudits = async () => {
        const { data, status } = await QuaSelectorService.GetAudits({ extraParams: "All=false" });

        if (!status()) {
            handleToast({ title: t(TranslationCommon.FAILED_DATA_LOADED), type: "alert", variant: "danger" });
            setAuditOptions([]);
            return;
        }

        setAuditOptions(data);
    };

    const validate = () => {
        let isValid = true;

        handleErrorChange(INITIAL_START_AUDIT_ERRORS);

        if (companyId === "-1") {
            handleErrorChange({ errorCompanyId: t(TranslationCommon.INPUT_NOT_EMPTY) });
            return false;
        }

        if (auditId === "-1") {
            handleErrorChange({ errorAuditId: t(TranslationCommon.INPUT_NOT_EMPTY) });
            isValid = false;
        }

        return isValid;
    };

    useEffect(() => {
        getCompanies();
        getAudits();
    }, []);

    return {
        handleSubmit,
        handleCompanyChange,
        companyOptions,
        handleInputChange,
        auditOptions,
        formValues,
        errorFormValues,
        isSubmiting,
    };
};
