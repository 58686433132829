import { FC } from "react";
import { faImage, faFile, faFilm, faMicrophone, faPhotoFilm } from "@fortawesome/pro-regular-svg-icons";
import { isVideoOrPhoto } from "app/helpers/__validates/media/validateIsVideoOrPhoto";
import { Tag } from "../Tag/Tag";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type AttachedFileProps = {
    message: string;
    urlClick?: string;
    other?: boolean;
    isSmall?: boolean;
    onClose?: (message: string) => void;
    leftIcon?: IconProp;
};

export const AttachedFile: FC<AttachedFileProps> = ({ message, urlClick, other, isSmall, onClose, leftIcon }) => {
    const icon =
        isVideoOrPhoto(message) === "audio"
            ? faMicrophone
            : isVideoOrPhoto(message) === "image"
            ? faImage
            : isVideoOrPhoto(message) === "video"
            ? faFilm
            : isVideoOrPhoto(message) === "file"
            ? faFile
            : faPhotoFilm;

    return (
        <Tag
            chatMode={other != null ? (other ? "other" : "mine") : undefined}
            message={message.includes("/security/") ? message.split("security/")[1] : message}
            onClose={onClose ? () => onClose(message) : undefined}
            size={isSmall ? "xs" : "md"}
            leftIcon={leftIcon || icon}
            urlClick={urlClick}
            variant="primary"
        />
    );
};
