import { BadgeVariant } from "app/components_v2/Badge/badgeTypes";
import { UserType } from "app/shared/types/UserTypes";

export const getUserTypeColor = (userType: UserType): BadgeVariant => {
    const userColors: Record<UserType, BadgeVariant> = {
        ANALIST: "purple",
        SUPERVISOR: "deepGreen",
        WORKER: "deepBlue",
        ADMIN: "purple",
        ADMIN_CLIENT: "grey",
    };

    return userColors[userType] || "grey";
};
