import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { FC, ReactNode } from "react";

type AddNewButtonProps = {
    children?: ReactNode;
    icon?: IconProp;
    variant?: TabHeaderVariants | "white";
    onClick: () => void;
};

export const AddNewButton: FC<AddNewButtonProps> = ({ children, icon, variant = "primary", onClick }) => {
    return (
        <button className="addNewButton" type="button" onClick={onClick}>
            <div className={`addNewButton__container addNewButton__container--${variant}`}>
                {icon && (
                    <div className="addNewButton__icon">
                        <FontAwesomeIcon
                            className={`addNewButton__icon__svg addNewButton__icon__svg--${variant}`}
                            icon={icon}
                        />
                    </div>
                )}
                {!!children && <p className={`addNewButton__text addNewButton__text--${variant}`}>{children}</p>}
            </div>
        </button>
    );
};
