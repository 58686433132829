import {
    NotificationDistributionListErrors,
    NotificationDistributionListFormValues,
    SelectedNotificationDistributionList,
} from "../models/NotificationDistributionListFormValues";
import { NotificationDistributionListGridSF } from "../models/NotificationDistributionListGridModels";

export const INITIAL_NOTIFICATION_DISTRIBUTION_LIST_SELECTED: SelectedNotificationDistributionList = {
    id: 0,
    functionality: "",
    companyName: "",
    fK_Company: "",
    fK_DistributionList: "",
    userList: [],
    emails: [],
    notifyWorkers: false,
    notifySupervisors: false,
    notifyAnalists: false,
    notifyAdminClients: false,
};

export const INITIAL_NOTIFICATION_DISTRIBUTION_LIST_ERRORS: NotificationDistributionListErrors = {
    errorFunctionality: "",
    errorCompany: "",
    errorUserList: "",
    errorEmails: "",
    errorNotSelected: "",
};

export const INITIAL_NOTIFICATION_DISTRIBUTION_LIST_FORM_VALUES: NotificationDistributionListFormValues = {
    companyList: [],
    fK_DistributionList: "",
    userList: [],
    emails: [],
    notifyUsers: false,
    notifyEmails: false,
    notifyWorkers: false,
    notifySupervisors: false,
    notifyAnalists: false,
    notifyAdminClients: false,
};

export const INITIAL_NOTIFICATION_DISTRIBUTION_LIST_SF: NotificationDistributionListGridSF = {
    functionality: "-1",
};

export const FunctionalityTypes: Record<number, string> = {
    1: "DISTRIBUTION_LIST_SPORADIC_TASK",
    2: "DISTRIBUTION_LIST_AUDIT_REPORT",
    3: "DISTRIBUTION_LIST_PATCH_NOTES",
    4: "DISTRIBUTION_LIST_ISSUE_DYNAMIC_FIELD",
};
