import { useEffect } from "react";
import { useSession, useTitle } from "app/hooks";
import { useTranslation } from "react-i18next";
import { useAsset } from "./hooks/useAsset";
import { useSensorBattery } from "app/pages/04-SEN/AutomaticRecordGrid/hooks/useSensorBattery";
import { useSensorSignal } from "app/pages/04-SEN/AutomaticRecordGrid/hooks/useSensorSignal";
import { isEqual } from "lodash";
import {
    AssetTranslations,
    AutomaticRecordTranslations,
    TranslationCommon,
    TranslationKeys,
    TranslationTitles,
} from "app/translation/translationKeys";
import { faCirclePlus, faFilter, faMagnifyingGlass, faPen, faSliders } from "@fortawesome/pro-regular-svg-icons";
import { faClipboardList } from "@fortawesome/pro-solid-svg-icons";
import { hasPermission, hasPermissionToAdd, hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { mapTabNumberToTabType } from "./helpers/fillExtraparams";
import { INITIAL_ASSET_SF_VALUES, INITIAL_ASSET_SORT_DIRECTION } from "../constants/assetConstants";
import { PaginationDefaults, PrivatePaths, SecScreen } from "app/shared/Constants";
import { ColumnsType } from "app/components_v2/Table/types";
import { Badge } from "app/components_v2/Badge/Badge";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { PlanAPPCCGridHeader } from "app/components_v2/PlanAPPCCGridHeader/PlanAPPCCGridHeader";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { TableInputText } from "app/components_v2/Table/TableInputText/TableInputText";
import { TableSelectCompany } from "app/components_v2/Table/TableSelectCompany/TableSelectCompany";
import { TabPropsReduced } from "app/components_v2/Tabs";
import { AssetFilter } from "app/components_v2/__filters/AssetFilter/AssetFilter";
import { AssetMobileRow } from "./components/AssetMobileRow/AssetMobileRow";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { AssetListDto } from "app/dtos/05-QUA/Asset/AssetListDto";
import { CellPhoto } from "app/components_v2/Table/CellPhoto/CellPhoto";
import { AssetGridIssueButton } from "./components/AssetGridIssueButton/AssetGridIssueButton";
import { ISSUE_STATUS } from "app/components_v2/__filters/IssueFilter/IssueFilter";

export const AssetGrid = () => {
    const { t } = useTranslation();

    const session = useSession();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();
    const {
        counters,
        currentTab,
        data,
        fetch,
        getPlans,
        handleExport,
        handleNavigate,
        handlePlanChange,
        handleSaveSf,
        isLoading,
        isSFOpen,
        onChangePageIndex,
        onChangeTab,
        onCompanyChange,
        onQueryChange,
        onQuerySubmit,
        onSortChange,
        pageIndex,
        planOptions,
        query,
        secondaryFilterValues,
        selectCompany,
        setisSFOpen,
        setShowSearchInput,
        showSearchInput,
        sortDirection,
        sortField,
        total,
        urlParams,
        handleChangecompany,
        assetCategory,
    } = useAsset();
    const { batteryBadgeTitle, batteryBadgeColor, batteryBadgeIcon } = useSensorBattery();
    const { signalBadgeTitle, signalBadgeColor, signalBadgeIcon } = useSensorSignal();

    const language = session?.user.language;
    const hasPermissionsToViewSensors = hasPermissionToView(session, SecScreen.AUTOMATIC_RECORD);
    const isTabWithSensor = mapTabNumberToTabType(currentTab) === "WITH_SENSOR";
    const assetPermission = assetCategory === "ASSET" ? SecScreen.ASSETS : SecScreen.ASSET_RANGE;

    const pageTitle =
        assetCategory === "ASSET" ? t(TranslationTitles.ASSET_PAGE_TITLE) : t(TranslationTitles.ASSET_RANGE_PAGE_TITLE);
    const title = `${pageTitle} ${selectCompany.company.value === "-1" ? `(${selectCompany.company.label})` : ""}`;

    useTitle(pageTitle);

    const tabs: TabPropsReduced[] = [
        {
            text:
                hasPermissionsToViewSensors && assetCategory === "ASSET"
                    ? t(AssetTranslations.WITHOUT_SENSOR)
                    : t(TranslationKeys.ACTIVE_TAB),
            rightCount: counters.withoutSensor,
            onClick: () => onChangeTab(0),
            type: "dark",
        },
        {
            text: t(AssetTranslations.WITH_SENSOR),
            rightCount: counters.withSensor,
            onClick: () => onChangeTab(1),
            type: "dark",
            hidden: !hasPermissionsToViewSensors || assetCategory === "ASSET_RANGE",
        },
        {
            text: t(AssetTranslations.DEACTIVATED),
            rightCount: counters.deactivated,
            onClick: () => onChangeTab(2),
            type: "dark",
        },
    ];

    const columns: ColumnsType<AssetListDto>[] = [
        {
            label: t(TranslationKeys.NAME),
            dataIndex: "name",
            className: `${isTabWithSensor ? "assetGrid__name" : ""}`,
            render: (record) => <CellTitle title={record.name} />,
            sortedType: "default",
        },
        {
            label: translateCustomerTypeKeys(AssetTranslations.ASSET_COMPANY, { firstReplace: "SINGULAR" }),
            dataIndex: "company.Name",
            className: "assetGrid__medium",
            alignCenter: true,
            render: (record) => <CellPhoto name={record.companyName} img={record.companyUrl} />,
            hidden: assetCategory === "ASSET_RANGE",
        },
        {
            label: t(AssetTranslations.ASSET_TOTAL_OPENED_ISSUES),
            dataIndex: "totalOpenedIssues",
            className: "assetGrid__medium",
            alignCenter: true,
            sortedType: "default",
            render: (record) => (
                <AssetGridIssueButton
                    to={`/${PrivatePaths.ISSUE}?tab=1&assetId=${record.id}&issueStatus=${ISSUE_STATUS.OPEN}`}
                    totalIssues={record.totalOpenedIssues}
                />
            ),
            hidden: !hasPermission(SecScreen.ISSUE),
        },
        {
            label: t(AutomaticRecordTranslations.SENSOR_SERIAL_NUMBER_SMALL_LABEL),
            className: "assetGrid__small",
            dataIndex: "sensorSerialNumber",
            render: (record) => <CellTitle title={record.sensorSerialNumber || "-"} />,
            sortedType: "default",
            hidden: !hasPermissionsToViewSensors || !isTabWithSensor,
        },
        {
            label: t(AutomaticRecordTranslations.BATTERY_LEVEL_LABEL),
            dataIndex: "battery",
            className: "assetGrid__tiny",
            alignCenter: true,
            render: ({ battery }) => (
                <div className="automaticRecord__center">
                    <Badge
                        size="md"
                        title={battery ? batteryBadgeTitle(battery) : "-"}
                        variant={battery ? batteryBadgeColor(battery) : "grey"}
                        icon={battery ? batteryBadgeIcon(battery) : undefined}
                        iconPosition="right"
                    />
                </div>
            ),
            hidden: !isTabWithSensor,
        },
        {
            label: t(AutomaticRecordTranslations.SIGNAL_LEVEL_LABEL),
            dataIndex: "signalLevel",
            alignCenter: true,
            className: "assetGrid__tiny",
            render: ({ signalLevel }) => (
                <div className="automaticRecord__center">
                    <Badge
                        size="md"
                        title={signalLevel ? signalBadgeTitle(signalLevel) : "-"}
                        variant={signalLevel ? signalBadgeColor(signalLevel) : "grey"}
                        icon={signalLevel ? signalBadgeIcon(signalLevel) : undefined}
                        iconPosition="right"
                    />
                </div>
            ),
            hidden: !isTabWithSensor,
        },
        {
            label: t(AssetTranslations.ASSET_NOT_WORKING_QUESTION),
            dataIndex: "notWorking",
            alignCenter: true,
            className: "assetGrid__small",
            render: (record) => (
                <div className="assetGrid__badge">
                    <Badge
                        title={t(
                            record.notWorking ? AssetTranslations.ASSET_NOT_WORKING : AssetTranslations.ASSET_REPAIRED
                        )}
                        size={"md"}
                        variant={record.notWorking ? "red" : "green"}
                    />
                </div>
            ),
            sortedType: "desc",
        },
        {
            label: t(TranslationKeys.ACTIONS),
            dataIndex: "edit",
            alignCenter: true,
            className: "assetGrid__actions",
            render: (record) => (
                <div className="assetGrid__edit">
                    <CellIcons
                        icons={[
                            {
                                icon: faPen,
                                onClick: () => handleNavigate(`edit/${record.id}`),
                                title: t(TranslationCommon.EDIT),
                            },
                        ]}
                    />
                </div>
            ),
            hidden: !hasPermission(assetPermission),
        },
    ];

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        getPlans();
    }, [language]);

    return (
        <>
            <AssetFilter
                filterData={secondaryFilterValues}
                initialFilterData={INITIAL_ASSET_SF_VALUES}
                showSecondaryFilter={isSFOpen}
                onSave={handleSaveSf}
                onCancel={() => setisSFOpen(false)}
                onClickOutside={() => setisSFOpen(false)}
            />
            <PageContainer
                header={
                    <PlanAPPCCGridHeader
                        title={title}
                        onChange={handlePlanChange}
                        planOptions={planOptions}
                        selectedValue={urlParams.get("planId") ?? "-1"}
                    />
                }
            >
                <TableCollapsable
                    cols={columns.filter((col) => !col.hidden)}
                    data={data}
                    tabs={tabs}
                    variant="appcc"
                    currentTab={currentTab}
                    placeholder={<TableError icon={faClipboardList} />}
                    isLoading={isLoading}
                    pageIndex={pageIndex}
                    pageSize={PaginationDefaults.PAGE_SIZE}
                    total={total}
                    onChangePageIndex={(pi) => {
                        onChangePageIndex(pi);
                        fetch({ pi: pi - 1 });
                    }}
                    sortField={sortField}
                    sortDirection={sortDirection}
                    onChangeSortDirectionField={(sortFieldParam, sortedTypeParam) => {
                        onSortChange(sortFieldParam, sortedTypeParam || INITIAL_ASSET_SORT_DIRECTION);
                        fetch({ sortD: sortedTypeParam || INITIAL_ASSET_SORT_DIRECTION, sortF: sortFieldParam });
                    }}
                    actionButtons={[
                        {
                            icon: faCirclePlus,
                            onClick: () => handleNavigate("new"),
                            hidden: !hasPermissionToAdd(assetPermission),
                            title: t(TranslationCommon.CREATE),
                        },
                        {
                            icon: faMagnifyingGlass,
                            onClick: () => {
                                setShowSearchInput(true);
                            },
                            inputComponent: {
                                component: (
                                    <TableInputText
                                        onChange={onQueryChange}
                                        value={query}
                                        fetch={onQuerySubmit}
                                        cleanFunction={onQuerySubmit}
                                        variant="appcc"
                                    />
                                ),
                                show: showSearchInput,
                            },
                            title: t(TranslationCommon.SEARCH),
                        },
                        {
                            icon: faFilter,
                            onClick: () => {
                                onCompanyChange({ isOpen: true });
                                setShowSearchInput(false);
                            },
                            inputComponent: {
                                component: (
                                    <TableSelectCompany
                                        onChange={handleChangecompany}
                                        value={selectCompany.company}
                                        onClickOutside={() => onCompanyChange({ isOpen: false })}
                                        variant="appcc"
                                    />
                                ),
                                show: selectCompany.isOpen,
                            },
                            title: translateCustomerTypeKeys(TranslationCommon.CHANGE_COMPANY, {
                                firstReplace: "SINGULAR",
                            }),
                        },
                        {
                            icon: faSliders,
                            onClick: () => setisSFOpen(true),
                            showMarkableIcon: !isEqual(secondaryFilterValues, INITIAL_ASSET_SF_VALUES),
                            title: t(TranslationCommon.FILTER),
                        },
                    ]}
                    onClickExportCsv={handleExport}
                    onDoubleClick={(record) => handleNavigate(`edit/${record.id}`)}
                    mobileBody={(row, _i, rowPosition) => (
                        <AssetMobileRow
                            key={row.id}
                            data={row}
                            onClick={() => handleNavigate(`edit/${row.id}`)}
                            rowPosition={rowPosition}
                        />
                    )}
                />
            </PageContainer>
        </>
    );
};
