import { FC, FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    AvatarColorTranslations,
    DepartmentNameTranslations,
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
} from "app/translation/translationKeys";
import { SecScreen } from "app/shared/Constants";
import { hasPermissionToAdd } from "app/routes/HelperRoleBasedAccess";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import ServiceResponse from "app/models/ServiceResponse/ServiceResponse";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { GenericModalFooter } from "../base/GenericModal/GenericModalFooter/GenericModalFooter";
import { Label, OptionsOutsideSelect } from "app/components_v2/__inputs";
import {
    AddDepartmentModalErrorFormValues,
    AddDepartmentModalFormValues,
} from "app/pages/01-SEG/Companies/Components/Department/types";
import { ADD_DEPARTMENT_MODAL_INITIAL_ERROR_FORM_VALUES } from "app/pages/01-SEG/Companies/Components/Department/constants/addDepartmentModalConstants";
import { useDepartmentName } from "app/hooks/useDepartmentName";
import { SelectWithActions } from "app/components_v2/SelectWithActions/SelectWithActions";
import { AvatarColorPicker } from "app/components_v2/AvatarColorPicker/AvatarColorPicker";
import { getInitials } from "app/helpers/Avatar/getInitials";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { useSession } from "app/hooks";
import { DepartmentNameModal } from "app/pages/01-SEG/Companies/Components/Department/DepartmentColorModal/DepartmentNameModal";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import Spinner from "app/components_v2/Spinner/Spinner";

type UsersToSend = {
    usersId: number[];
    fK_DepartmentName: string;
    allUsers: OptionsSearch[];
    hexColor: string;
};

type AddUsersModalProps = {
    title: string;
    onClose: () => void;
    service: () => Promise<ServiceResponse<OptionModel[]>>;
    handleAddUsers: (users: UsersToSend) => void;
    users: OptionModel[];
    fK_DepartmentName: string;
    hexColor: string;
    nameLabel: string;
    isSaving: boolean;
};

const AddUsersModal: FC<AddUsersModalProps> = ({
    title,
    onClose,
    service,
    handleAddUsers,
    users,
    fK_DepartmentName,
    hexColor,
    nameLabel,
    isSaving,
}) => {
    const { t } = useTranslation();
    const session = useSession();

    const [selectedUsers, setSelectedUsers] = useState<OptionModel[]>([]);
    const [usersOptions, setUsersOptions] = useState<OptionModel[]>([]);

    const [formValues, setFormValues] = useState<AddDepartmentModalFormValues>({ fK_DepartmentName, hexColor });
    const [errorFormValues, setErrorFormValues] = useState<AddDepartmentModalErrorFormValues>(
        ADD_DEPARTMENT_MODAL_INITIAL_ERROR_FORM_VALUES
    );
    const { errorDepartmentName, errorHexColor } = errorFormValues;

    const {
        isLoading: isLoadingSelector,
        options,
        lastItems,
        selectActions,
        selectedDeparmentName,
        isDepartmentNameDeleteModalLoading,
        isDepartmentNameDeleteModalVisible,
        isDepartmentNameModalVisible,
        addNewDepartmentName,
        onDeleteDepartmentName,
        onEditDepartmentName,
        closeDeleteModal,
        closeModal,
    } = useDepartmentName({
        onChange: (fK_DepartmentNameValue) =>
            setFormValues((prev) => ({ ...prev, fK_DepartmentName: fK_DepartmentNameValue || "-1" })),
        fK_DepartmentName,
    });

    const onInputChange = (values: Partial<AddDepartmentModalFormValues>) => {
        setFormValues((prev) => ({ ...prev, ...values }));
    };
    const onErrorChange = (values: Partial<AddDepartmentModalErrorFormValues>) => {
        setErrorFormValues((prev) => ({ ...prev, ...values }));
    };

    const getUsersInDepartmentAlready = async () => {
        const { data, status } = await service();
        if (!status) {
            console.error(t(TranslationErrors.GENERIC_ERROR));
            setSelectedUsers([]);
        }
        setSelectedUsers(users);

        setUsersOptions(data);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validate()) return;

        handleAddUsers({
            usersId: selectedUsers.map((user) => Number(user.value)),
            allUsers: usersOptions,
            fK_DepartmentName: formValues.fK_DepartmentName,
            hexColor: formValues.hexColor,
        });
    };

    const validate = () => {
        let isChecked = true;
        onErrorChange(ADD_DEPARTMENT_MODAL_INITIAL_ERROR_FORM_VALUES);

        if (!formValues.fK_DepartmentName.length || formValues.fK_DepartmentName === "-1") {
            onErrorChange({ errorDepartmentName: t(TranslationCommon.INPUT_NOT_EMPTY) });
            isChecked = false;
        }

        if (!formValues.hexColor.length) {
            onErrorChange({ errorHexColor: t(TranslationCommon.INPUT_NOT_EMPTY) });
            isChecked = false;
        }

        return isChecked;
    };

    useEffect(() => {
        getUsersInDepartmentAlready();
    }, []);

    return (
        <>
            {isDepartmentNameModalVisible && (
                <DepartmentNameModal
                    onAddNew={addNewDepartmentName}
                    onCancel={closeModal}
                    onEdit={onEditDepartmentName}
                    selectedDepartmentName={selectedDeparmentName}
                />
            )}
            {isDepartmentNameDeleteModalVisible && (
                <ConfirmModal
                    onClose={closeDeleteModal}
                    onConfirm={onDeleteDepartmentName}
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    title={t(DepartmentNameTranslations.DEPARTMENT_NAME_DELETE_TITLE)}
                    description={t(DepartmentNameTranslations.DEPARTMENT_NAME_DELETE_DESCRIPTION)}
                    isLoading={isDepartmentNameDeleteModalLoading}
                    type="delete"
                />
            )}
            <GenericModal
                header={{ title, onClose }}
                size="lg"
                footer={
                    hasPermissionToAdd(SecScreen.CENTERS_MANAGEMENT) && (
                        <GenericModalFooter
                            confirmButton={{
                                text: t(TranslationCommon.SAVE),
                                form: "addUsersModalForm",
                                type: "submit",
                            }}
                            closeButton={{
                                text: t(TranslationCommon.CANCEL),
                                buttonType: "tertiary",
                                onClick: onClose,
                            }}
                            loading={isSaving}
                        />
                    )
                }
            >
                <form onSubmit={handleSubmit} className="addUsersModal" id="addUsersModalForm">
                    {isLoadingSelector ? (
                        <div className="addUsersModal__loading">
                            <Spinner />
                        </div>
                    ) : (
                        <div className="addUsersModal__container">
                            <SelectWithActions
                                actions={selectActions}
                                lastItems={lastItems}
                                onChange={({ value }) =>
                                    onInputChange({
                                        fK_DepartmentName: value,
                                    })
                                }
                                options={options}
                                label={t(TranslationKeys.DEPARTMENT_NAME)}
                                placeholder={t(TranslationKeys.PLACEHOLDER_DEPARTMENT_NAME)}
                                selectedValue={
                                    formValues.fK_DepartmentName !== "-1" ? formValues.fK_DepartmentName : undefined
                                }
                                errorMessage={errorDepartmentName}
                                isLoading={isLoadingSelector}
                            />
                            <div className="addDepartmentModal__colorPicker">
                                <Label label={t(AvatarColorTranslations.AVATAR_COLOR_LABEL)} />
                                <AvatarColorPicker
                                    onChange={(hexColor) => onInputChange({ hexColor })}
                                    selectedColor={formValues.hexColor}
                                    initials={getInitials(
                                        options.find(
                                            ({ value }) => value === formValues.fK_DepartmentName && value !== "-1"
                                        )?.label ||
                                            session?.workingCompany?.companyName ||
                                            ""
                                    )}
                                />
                                {!!errorHexColor && (
                                    <ErrorMessage
                                        errorMessage={t(AvatarColorTranslations.AVATAR_COLOR_ERROR_MESSAGE)}
                                    />
                                )}
                            </div>
                            <div className="addUsersModal__dropdown">
                                <OptionsOutsideSelect
                                    onChange={setSelectedUsers}
                                    options={usersOptions}
                                    onDeleteItem={(item) => {
                                        setSelectedUsers((prev) => prev.filter(({ value }) => value !== item));
                                    }}
                                    values={selectedUsers}
                                    hidden={!hasPermissionToAdd(SecScreen.CENTERS_MANAGEMENT)}
                                    placeholder={t(TranslationKeys.SELECT_USERS)}
                                    label={t(TranslationKeys.USERS)}
                                    isMulti
                                />
                            </div>
                        </div>
                    )}
                </form>
            </GenericModal>
        </>
    );
};
export default AddUsersModal;
