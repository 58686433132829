import { getDateFormattedSelects } from "app/helpers/Utilities/date/Date.utilities";

export const fillWorkerProfileCountersExtraParams = (
    supervisorId?: number,
    deadlineDate?: Date,
    deadlineDate1?: Date,
    companyId?: number
) => {
    let extraParams = "";
    if (supervisorId) extraParams += `supervisorId=${supervisorId}&`;
    if (deadlineDate) extraParams += `deadlineDate=${getDateFormattedSelects(deadlineDate)}&`;
    if (deadlineDate1) extraParams += `deadlineDate1=${getDateFormattedSelects(deadlineDate1)}&`;
    if (companyId) extraParams += `companyId=${companyId}&`;
    return extraParams;
};
