import { Label } from "app/components_v2/__inputs/Label";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { FileWithPreviewInputModel } from "app/models/FormComponentsModel";
import { FC, useEffect, useRef, useState } from "react";
import DefaultImage from "assets/02-TAR/default-is-required-image.jpg";
import { Button } from "app/components_v2/__buttons/Button/Button";
import { faArrowUpFromBracket, faPen, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { EditImage } from "app/components_v2/EditImage/EditImage";
import { Modal } from "app/components_v2/__modals/base/Modal/Modal";
import { validTypes } from "app/shared/Constants";
import { webPConverter } from "app/helpers/Media/converter/webPConverter";

export const DragFileWithPreview: FC<FileWithPreviewInputModel> = ({
    name,
    onChange,
    value,
    htmlFor,
    label,
    customError,
    setChangeImg,
    onDeleteImg,
    disabled,
    className = "",
    onlyImages = true,
    isImageEditable,
}) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const fileRef = useRef<HTMLInputElement | null>(null);
    const handleOpen = () => {
        fileRef.current?.click();
    };

    const onFinishEditingImage = (image: string) => {
        setChangeImg(image);
        setIsModalOpen(false);
    };

    useEffect(() => {
        const reader = new FileReader();

        typeof value === "object" && reader.readAsDataURL(new Blob([value]));

        reader.onloadend = () => {
            if (typeof value === "object") {
                webPConverter(value, (convertedImg: string) => {
                    setChangeImg(convertedImg);
                });
            }
        };
    }, [value]);

    return (
        <>
            <div className={`mainInputFile__wrapper ${className}`}>
                {!value && label && <Label label={label} />}
                {typeof value === "string" ? (
                    !!value.length ? (
                        <div className="mainInputFile__imageContainer">
                            <img src={value} alt={value} loading="lazy" />
                        </div>
                    ) : (
                        <div className="mainInputFile__imageContainer">
                            <img src={DefaultImage} alt={DefaultImage} loading="lazy" />
                        </div>
                    )
                ) : (
                    !!value.name.length && (
                        <div className="mainInputFile__imageContainer">
                            <img src={URL.createObjectURL(value)} alt={value?.name} loading="lazy" />
                        </div>
                    )
                )}
                <input
                    width={0}
                    height={0}
                    type="file"
                    name={name}
                    id={htmlFor}
                    onChange={(e) => onChange && onChange(e)}
                    hidden={true}
                    ref={fileRef}
                    className="mainInputFile__input"
                    accept={onlyImages ? "image/*" : validTypes.join(",")}
                />
                {!disabled && (
                    <div className="dragFileWithPreview__buttons">
                        {!!value && (
                            <Button iconLeft={faTrash} onClick={onDeleteImg} variant="danger" buttonType="button" />
                        )}
                        {isImageEditable && onlyImages && !!value && (
                            <Button iconLeft={faPen} onClick={() => setIsModalOpen(true)} buttonType="button" />
                        )}
                        <Button
                            iconLeft={faArrowUpFromBracket}
                            text={!value ? "Subir foto de ejemplo" : ""}
                            onClick={handleOpen}
                            buttonType="button"
                            type="secondary"
                        />
                    </div>
                )}
                {!!customError?.length && <ErrorMessage errorMessage={customError} />}
            </div>
            {isModalOpen && (
                <Modal portal className="zIndexUp webcamCapture" showBlur={false}>
                    <div className="webcamCapture__container">
                        <div className="mediaCapture">
                            <div className="mediaCapture__image designSystemEditImage">
                                <EditImage
                                    file={typeof value === "string" ? undefined : value}
                                    url={typeof value === "string" ? value : undefined}
                                    onCancel={() => setIsModalOpen(false)}
                                    onAccept={onFinishEditingImage}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};
