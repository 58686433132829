import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { AddNewButton } from "app/components_v2/__buttons/AddNewButton/AddNewButton";
import { Label } from "app/components_v2/__inputs";
import { TranslationCommon } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type LabelAddAllProps = {
    label: string;
    onClick: () => void;
};

export const LabelAddAll: FC<LabelAddAllProps> = ({ label, onClick }) => {
    const { t } = useTranslation();

    return (
        <div className="createNotificationDistributionListUsersModal__addAll">
            <Label label={t(label)} />
            <div className="createNotificationDistributionListUsersModal__addAll__button">
                <AddNewButton onClick={onClick} icon={faPlus}>
                    {t(TranslationCommon.ADD_ALL)}
                </AddNewButton>
            </div>
        </div>
    );
};
