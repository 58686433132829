import { PaginatedList } from "app/models/ServiceResponse/PaginatedResult";
import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { IssueInsertModel, IssueModel } from "app/models/05-QUA/IssueModels/IssueModel";
import { IssueTypeCounters } from "app/dtos/05-QUA/Issue/IssueTypeCounters";
import { IssueAttachDocDto } from "app/dtos/05-QUA/Issue/IssueAttachDocDto";
import { IssueAttachmentModel } from "app/models/05-QUA/IssueModels/IssueAttachmentModel";
import { IssueStatusInsertModel } from "app/models/05-QUA/IssueModels/IssueStatusModels";
import { IssueCloseDto } from "app/dtos/05-QUA/Issue/IssueCloseDto";
import { IssueListDto } from "app/dtos/05-QUA/Issue/IssueListDto";

export default class IssueService {
    public static async GetOne(id: number) {
        return FetchService.get<IssueModel>({
            url: `${ApiPaths.QUA_API}/Issue/${id}`,
        });
    }

    public static async GetData(paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedList<IssueListDto>>({
            url: `${ApiPaths.QUA_API}/Issue`,
            paginationParams,
        });
    }

    public static async GetCounters(paginationParams?: PaginationParams) {
        return FetchService.get<IssueTypeCounters>({
            url: `${ApiPaths.QUA_API}/Issue/IssueTypeCounters`,
            paginationParams,
        });
    }

    // GET Issue List Formatted in CSV
    public static async Export(params?: PaginationParams) {
        return FetchService.get({
            url: `${ApiPaths.QUA_API}/Issue/Export`,
            paginationParams: params,
            csvExport: true,
        });
    }

    public static async Save(issue: IssueInsertModel) {
        return FetchService.post<number>({
            url: `${ApiPaths.QUA_API}/Issue`,
            body: issue,
        });
    }

    public static async Edit(id: number, issue: IssueModel) {
        return FetchService.put({
            url: `${ApiPaths.QUA_API}/Issue/${id}`,
            body: issue,
        });
    }

    public static async EditClassification(id: number, fK_IssueClassification: number) {
        return FetchService.put({
            url: `${ApiPaths.QUA_API}/Issue/IssueClassification/${id}`,
            body: { issueClassificationId: fK_IssueClassification },
        });
    }

    public static async MultipleSave(issues: IssueInsertModel[]) {
        return FetchService.post({
            url: `${ApiPaths.QUA_API}/Issue/Multiple`,
            body: issues,
        });
    }

    public static async DeleteIssueByChecklistIdAsync(id: number) {
        return FetchService.delete({
            url: `${ApiPaths.QUA_API}/Issue/${id}/DeleteIssueByChecklistIdAsync`,
        });
    }

    // TODO: When implementing CloseAsync & ReopenAsync use IssueStatusInsertModel
    public static async AttachDoc(id: number, files: IssueAttachDocDto[]) {
        return FetchService.put<IssueAttachmentModel[]>({
            url: `${ApiPaths.QUA_API}/issue/${id}/AttachDoc`,
            body: files,
        });
    }

    public static async CloseAsync(id: number, issueCloseDto: IssueCloseDto) {
        return FetchService.put<number>({
            url: `${ApiPaths.QUA_API}/issue/${id}/Close`,
            body: issueCloseDto,
        });
    }

    public static async ReopenAsync(id: number, issueStatus: IssueStatusInsertModel) {
        return FetchService.put<number>({
            url: `${ApiPaths.QUA_API}/issue/${id}/Reopen`,
            body: issueStatus,
        });
    }

    public static async DeleteIssueByTaskInstanceFieldIdAsync(taskInstanceFieldId: number) {
        return FetchService.delete({
            url: `${ApiPaths.QUA_API}/Issue/${taskInstanceFieldId}/DeleteIssueByTaskInstanceFieldIdAsync`,
        });
    }

    public static async DeleteIssueByTaskInstanceIdAsync(taskInstanceId: number) {
        return FetchService.delete({
            url: `${ApiPaths.QUA_API}/Issue/${taskInstanceId}/DeleteIssueByTaskInstanceIdAsync`,
        });
    }
}
