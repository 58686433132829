import { FC, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    AvatarColorTranslations,
    DepartmentNameTranslations,
    TranslationCommon,
    TranslationKeys,
} from "app/translation/translationKeys";
import { Label } from "app/components_v2/__inputs";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { AddDepartmentModalErrorFormValues, AddDepartmentModalFormValues } from "./types";
import {
    ADD_DEPARTMENT_MODAL_INITIAL_ERROR_FORM_VALUES,
    ADD_DEPARTMENT_MODAL_INITIAL_FORM_VALUES,
} from "./constants/addDepartmentModalConstants";
import { useDepartmentName } from "app/hooks/useDepartmentName";
import { SelectWithActions } from "app/components_v2/SelectWithActions/SelectWithActions";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { AvatarColorPicker } from "app/components_v2/AvatarColorPicker/AvatarColorPicker";
import { getInitials } from "app/helpers/Avatar/getInitials";
import Spinner from "app/components_v2/Spinner/Spinner";
import { useSession } from "app/hooks";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { DepartmentNameModal } from "./DepartmentColorModal/DepartmentNameModal";

type AddDepartmentModalProps = {
    isLoading: boolean;
    onClose: () => void;
    onSubmit: ({
        fK_DepartmentName,
        hexColor,
        name,
    }: {
        fK_DepartmentName: string;
        hexColor: string;
        name: string;
    }) => void;
};

export const AddDepartmentModal: FC<AddDepartmentModalProps> = ({ onClose, onSubmit, isLoading }) => {
    const { t } = useTranslation();
    const session = useSession();

    const [formValues, setFormValues] = useState<AddDepartmentModalFormValues>(
        ADD_DEPARTMENT_MODAL_INITIAL_FORM_VALUES
    );
    const [errorFormValues, setErrorFormValues] = useState<AddDepartmentModalErrorFormValues>(
        ADD_DEPARTMENT_MODAL_INITIAL_ERROR_FORM_VALUES
    );
    const { fK_DepartmentName, hexColor } = formValues;
    const { errorDepartmentName, errorHexColor } = errorFormValues;

    const {
        isLoading: isLoadingSelector,
        options,
        lastItems,
        selectActions,
        selectedDeparmentName,
        isDepartmentNameDeleteModalLoading,
        isDepartmentNameDeleteModalVisible,
        isDepartmentNameModalVisible,
        addNewDepartmentName,
        onDeleteDepartmentName,
        onEditDepartmentName,
        closeDeleteModal,
        closeModal,
    } = useDepartmentName({
        onChange: (fK_DepartmentNameValue) =>
            setFormValues((prev) => ({ ...prev, fK_DepartmentName: fK_DepartmentNameValue || "-1" })),
        fK_DepartmentName,
    });

    const onInputChange = (values: Partial<AddDepartmentModalFormValues>) => {
        setFormValues((prev) => ({ ...prev, ...values }));
    };
    const onErrorChange = (values: Partial<AddDepartmentModalErrorFormValues>) => {
        setErrorFormValues((prev) => ({ ...prev, ...values }));
    };

    const handleSumbit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const selectedColor = options.find(({ value }) => value === fK_DepartmentName);

        if (!validate() || !selectedColor) return;

        onSubmit({
            fK_DepartmentName,
            hexColor,
            name: selectedColor.label,
        });
    };

    const validate = (): boolean => {
        let isChecked = true;
        onErrorChange(ADD_DEPARTMENT_MODAL_INITIAL_ERROR_FORM_VALUES);

        if (!fK_DepartmentName.length || fK_DepartmentName === "-1") {
            onErrorChange({ errorDepartmentName: t(TranslationCommon.INPUT_NOT_EMPTY) });
            isChecked = false;
        }

        if (!hexColor.length) {
            onErrorChange({ errorHexColor: t(TranslationCommon.INPUT_NOT_EMPTY) });
            isChecked = false;
        }

        return isChecked;
    };

    return (
        <>
            {isDepartmentNameModalVisible && (
                <DepartmentNameModal
                    onAddNew={addNewDepartmentName}
                    onCancel={closeModal}
                    onEdit={onEditDepartmentName}
                    selectedDepartmentName={selectedDeparmentName}
                />
            )}
            {isDepartmentNameDeleteModalVisible && (
                <ConfirmModal
                    onClose={closeDeleteModal}
                    onConfirm={onDeleteDepartmentName}
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    title={t(DepartmentNameTranslations.DEPARTMENT_NAME_DELETE_TITLE)}
                    description={t(DepartmentNameTranslations.DEPARTMENT_NAME_DELETE_DESCRIPTION)}
                    isLoading={isDepartmentNameDeleteModalLoading}
                    type="delete"
                />
            )}
            <GenericModal
                header={{ title: t(TranslationKeys.NEW_DEPARTMENT), onClose }}
                size="sm"
                portal
                footer={
                    <GenericModalFooter
                        confirmButton={{
                            text: t(TranslationCommon.ACCEPT),
                            form: "addDepartmentModal",
                            type: "submit",
                            fullWidth: true,
                        }}
                        closeButton={{
                            text: t(TranslationCommon.CANCEL),
                            onClick: onClose,
                            buttonType: "tertiary",
                            fullWidth: true,
                        }}
                        loading={isLoading}
                    />
                }
            >
                {isLoadingSelector ? (
                    <div className="addDepartmentModal__loading">
                        <Spinner />
                    </div>
                ) : (
                    <form onSubmit={handleSumbit} className="addDepartmentModal" id="addDepartmentModal">
                        <SelectWithActions
                            actions={selectActions}
                            lastItems={lastItems}
                            onChange={({ value }) =>
                                onInputChange({
                                    fK_DepartmentName: value,
                                })
                            }
                            options={options}
                            label={t(TranslationKeys.DEPARTMENT_NAME)}
                            placeholder={t(TranslationKeys.PLACEHOLDER_DEPARTMENT_NAME)}
                            selectedValue={fK_DepartmentName !== "-1" ? fK_DepartmentName : undefined}
                            errorMessage={errorDepartmentName}
                            isLoading={isLoadingSelector}
                        />
                        <div className="addDepartmentModal__colorPicker">
                            <Label label={t(AvatarColorTranslations.AVATAR_COLOR_LABEL)} />
                            <AvatarColorPicker
                                onChange={(hexColor) => onInputChange({ hexColor })}
                                selectedColor={hexColor}
                                initials={getInitials(
                                    options.find(({ value }) => value === fK_DepartmentName && value !== "-1")?.label ||
                                        session?.workingCompany?.companyName ||
                                        ""
                                )}
                            />
                            {!!errorHexColor && (
                                <ErrorMessage errorMessage={t(AvatarColorTranslations.AVATAR_COLOR_ERROR_MESSAGE)} />
                            )}
                        </div>
                    </form>
                )}
            </GenericModal>
        </>
    );
};
