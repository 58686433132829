import { ActionButtonsModel } from "app/components_v2/Table/TableTabHeader/TableTabHeader";
import { AddDepartmentModal } from "./AddDepartmentModal";
import { Badge } from "app/components_v2/Badge/Badge";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { CloseDepartmentModal } from "./CloseDepartmentModal";
import { ColumnsType } from "app/components_v2/Table/types";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { DateRange } from "app/models/utilities";
import { DepartmentGridRow } from "../DepartmentGridRow/DepartmentGridRow";
import { DepartmentModel } from "app/models/01-SEG/Department/DepartmentModel";
import {
    CompanyTranslations,
    DepartmentTranslations,
    TranslationCommon,
    TranslationKeys,
    TranslationTitles,
} from "app/translation/translationKeys";
import {
    faCalendarXmark,
    faCirclePlus,
    faClose,
    faMagnifyingGlass,
    faPen,
    faSearch,
    faTrash,
    faTriangleExclamation,
} from "@fortawesome/pro-regular-svg-icons";
import { FC } from "react";
import { hasPermission, hasPermissionToAdd, hasPermissionToDelete } from "app/routes/HelperRoleBasedAccess";
import { Input } from "app/components_v2/__inputs";
import { PaginationDefaults, SecScreen } from "app/shared/Constants";
import { SumaryUsersAddedDeletedModal } from "../SumaryUsersAddedDeletedModal/SumaryUsersAddedDeletedModal";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { useDepartmentGrid } from "./useDepartmentGrid";
import { useTitle } from "app/hooks/CustomHooks";
import { useTranslation } from "react-i18next";
import AddUsersModal from "app/components_v2/__modals/AddUsersModal/AddUsersModal";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import { getDateFormattedSelects } from "app/helpers/Utilities/date/Date.utilities";
import { isTodayBetweenDates } from "app/helpers/closeDates/isTodayBetweenDates";
import { getTotalClossedDates } from "app/helpers/closeDates/getTotalClosedDates";

type DepartmentGridProps = {
    companyId: number;
};

export const DepartmentGrid: FC<DepartmentGridProps> = ({ companyId }) => {
    const { t } = useTranslation();
    const {
        departments,
        handleAddDepartment,
        setShowAddDepartmentModal,
        showAddDepartmentModal,
        status,
        pageIndex,
        query,
        total,
        onChangePageIndex,
        setShowSearchInput,
        showSearchInput,
        onSearch,
        setQuery,
        isLoadingAddNewDept,
        isLoadingDeleteDepartment,
        openDeleteModal,
        showDeleteDepartmentModal,
        onCloseDeleteModal,
        selectedDepartment,
        onDelete,
        showAddUsersToDepModal,
        isLoadingAddUsersToDepModal,
        openEditDepartment,
        onCloseEditDepartment,
        selectedUsersInDepartment,
        getConfirmModifyUserDescription,
        getConfirmModifyUserTitle,
        handleOpenModifyUsers,
        isConfirmAddUsersModalVisible,
        usersAdded,
        usersDeleted,
        handleConfirmModifyUsers,
        onCloseSumaryModal,
        getDeleteModalDescription,
        handleExport,
        onAddNew,
        onCloseCloseDepartment,
        openCloseDepartmentModal,
        onSubmitCloseDepartment,
        showCloseDepartmentModal,
        isLoadingCloseDepartment,
        onClosingDates,
        onAddNewRangeHour,
        onDeleteNewRangeHour,
    } = useDepartmentGrid({ companyId });

    const closeDepartments: DateRange[] | undefined =
        selectedDepartment != null
            ? selectedDepartment.closeDepartments?.map(({ closeDate, openDate }) => [closeDate, openDate])
            : [];

    useTitle(t(TranslationTitles.DEPARTMENT_AND_SUBDEPARTMENT_PAGE_TITLE));

    const columns: ColumnsType<DepartmentModel>[] = [
        {
            label: t(TranslationKeys.NAME),
            dataIndex: "name",
            render: ({ name }) => {
                return <CellTitle title={name} />;
            },
        },
        {
            label: t(DepartmentTranslations.DEPARTMENT_STATUS),
            dataIndex: "Status",
            render: ({ closeDepartments }) => {
                const clossedDates = getTotalClossedDates(closeDepartments);
                const totalClosures = clossedDates?.length || 0;
                const isClosed = !!totalClosures && isTodayBetweenDates(clossedDates || []);
                return (
                    <Badge
                        title={isClosed ? t(TranslationCommon.CLOSED) : t(TranslationCommon.OPEN)}
                        variant={isClosed ? "red" : "green"}
                    />
                );
            },
        },
        {
            label: t(CompanyTranslations.TOTAL_CLOSURES_LABEL),
            dataIndex: "Status",
            render: ({ closeDepartments }) => {
                const clossedDates = getTotalClossedDates(closeDepartments);
                const totalClosures = clossedDates?.length || 0;
                return <CellTitle title={!!totalClosures ? totalClosures : "-"} />;
            },
            sortedType: "default",
        },
        {
            label: t(TranslationKeys.ACTIONS),
            dataIndex: "edit",
            className: "issueGrid__edit",
            alignCenter: true,
            render: (record) => (
                <div className="assetGrid__edit">
                    <CellIcons
                        icons={[
                            {
                                icon: faCalendarXmark,
                                onClick: () => openCloseDepartmentModal(record),
                                title: t(DepartmentTranslations.CLOSE_DEPARTMENT),
                                hidden: !hasPermission(SecScreen.CLOSE_COMPANY_DEPT),
                            },
                            {
                                icon: faPen,
                                onClick: () => openEditDepartment(record),
                                title: t(TranslationCommon.EDIT),
                                hidden: !hasPermissionToAdd(SecScreen.TEAM_MANAGEMENT),
                            },
                            {
                                icon: faTrash,
                                onClick: () => openDeleteModal(record),
                                title: t(TranslationCommon.DELETE),
                                hidden: !hasPermissionToDelete(SecScreen.TEAM_MANAGEMENT),
                            },
                        ]}
                    />
                </div>
            ),
            hidden: !hasPermission(SecScreen.TEAM_MANAGEMENT),
        },
    ];

    const actionButtons: ActionButtonsModel[] = [
        {
            icon: faCirclePlus,
            onClick: onAddNew,
            hidden: !hasPermissionToAdd(SecScreen.TEAM_MANAGEMENT) || showSearchInput,
            title: t(TranslationCommon.CREATE),
        },
        {
            icon: faMagnifyingGlass,
            onClick: () => setShowSearchInput(true),
            inputComponent: {
                component: (
                    <Input
                        focus
                        onChange={setQuery}
                        iconRight={!!query.length ? faSearch : faClose}
                        value={query}
                        onClickIcon={onSearch}
                        onPressEnter={onSearch}
                        whiteInput
                        transparent
                    />
                ),
                show: showSearchInput,
            },
            title: t(TranslationCommon.SEARCH),
        },
    ];

    return (
        <div className="companiesDepartment">
            {hasPermission(SecScreen.CLOSE_COMPANY_DEPT) && showCloseDepartmentModal && (
                <CloseDepartmentModal
                    closeDates={closeDepartments}
                    isLoading={isLoadingCloseDepartment}
                    onClose={onCloseCloseDepartment}
                    onClosingDates={onClosingDates}
                    onAddNewRangeHour={onAddNewRangeHour}
                    onDeleteNewRangeHour={onDeleteNewRangeHour}
                    onSubmit={onSubmitCloseDepartment}
                />
            )}
            {showAddUsersToDepModal && selectedDepartment && (
                <AddUsersModal
                    service={() => SegSelectorService.GetUserNamesWithIds({ extraParams: "HasLoggedIn=ALL" })}
                    onClose={onCloseEditDepartment}
                    title={t(TranslationKeys.EDIT_DEPARTMENTS)}
                    handleAddUsers={handleOpenModifyUsers}
                    users={selectedUsersInDepartment}
                    fK_DepartmentName={
                        selectedDepartment.fK_DepartmentName ? String(selectedDepartment.fK_DepartmentName) : "-1"
                    }
                    hexColor={selectedDepartment.hexColor || ""}
                    nameLabel={t(TranslationKeys.DEPARTMENTS)}
                    isSaving={isLoadingAddUsersToDepModal}
                />
            )}

            {showAddDepartmentModal && (
                <AddDepartmentModal
                    onClose={() => setShowAddDepartmentModal(false)}
                    onSubmit={handleAddDepartment}
                    isLoading={isLoadingAddNewDept}
                />
            )}
            {showDeleteDepartmentModal && selectedDepartment && (
                <ConfirmModal
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={onCloseDeleteModal}
                    onConfirm={onDelete}
                    description={getDeleteModalDescription()}
                    title={`${t(DepartmentTranslations.DEPARTMENT_DELETE_MODAL_TITLE).replace(
                        "{0}",
                        selectedDepartment.name
                    )} `}
                    isLoading={isLoadingDeleteDepartment}
                    type="delete"
                />
            )}
            {isConfirmAddUsersModalVisible && (
                <SumaryUsersAddedDeletedModal
                    title={getConfirmModifyUserTitle()}
                    description={getConfirmModifyUserDescription() || ""}
                    onConfirm={handleConfirmModifyUsers}
                    onClose={onCloseSumaryModal}
                    usersAdded={usersAdded}
                    usersDeleted={usersDeleted}
                />
            )}
            <TableCollapsable
                cols={columns.filter((col) => !col.hidden)}
                data={departments}
                placeholder={<TableError title={t(TranslationKeys.NO_DEPARTMENTS)} icon={faTriangleExclamation} />}
                title={t(TranslationTitles.DEPARTMENT_TITLE)}
                subTitle={`${total} ${t(TranslationTitles.DEPARTMENT_TITLE)}`}
                isLoading={status === "loading"}
                pageIndex={pageIndex}
                pageSize={PaginationDefaults.PAGE_SIZE}
                total={total}
                onChangePageIndex={onChangePageIndex}
                actionButtons={actionButtons}
                onDoubleClick={(record) => openEditDepartment(record)}
                mobileBody={(row, _i, rowPosition) => (
                    <DepartmentGridRow
                        data={row}
                        key={row.id}
                        rowPosition={rowPosition}
                        onEdit={() => openEditDepartment(row)}
                        onDelete={() => openDeleteModal(row)}
                        onCloseDepartment={() => openCloseDepartmentModal(row)}
                    />
                )}
                onClickExportCsv={handleExport}
                fitHeight
            />
        </div>
    );
};
