import { Avatar } from "app/components_v2/Avatar/Avatar";
import BotAvatar from "assets/common/Avatar.png";
import { ChatBotSender } from "../types";
import { FC } from "react";
import { convertUTCtoLocaleDate, getDayMonthAndTimeFormatted } from "app/helpers/Utilities/date/Date.utilities";

type ChatBotMessageHeaderProps = {
    sender: ChatBotSender;
    date: Date;
};

export const ChatBotMessageHeader: FC<ChatBotMessageHeaderProps> = ({ date, sender }) => {
    return (
        <div className="chatBotMessageHeader">
            {sender === "assistant" && <Avatar colorId={1} avatarName="Laia" size="sm" name="Laia" img={BotAvatar} />}
            <p className="chatBotMessageHeader__date">{getDayMonthAndTimeFormatted(convertUTCtoLocaleDate(date))}</p>
        </div>
    );
};
