import { SortedTypeModel } from "app/components_v2/Table/types";
import { DocumentalFileSortField } from "../models/DocumentalFilePageModel";
import { DocumentalFileType } from "app/models/07-DOC/DocumentalFileType";

export const INITIAL_DOCUMENTAL_FILE_SORT_FIELD: DocumentalFileSortField = "Name";
export const INITIAL_DOCUMENTAL_FILE_SORT_DIRECTION: SortedTypeModel = "asc";
export const ALLOWED_DOCUMENTAL_FILE_EXTENSIONS: Record<DocumentalFileType, string[]> = {
    Document: ["txt", "rtf", "odt"],
    Excel: ["xls", "xlsx", "csv"],
    Docx: ["doc", "docx"],
    Pdf: ["pdf"],
    Image: ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "webp"],
    Video: ["mp4", "avi", "mov", "wmv", "flv", "mkv"],
    Audio: ["mp3", "wav", "aac", "ogg", "flac"],
};
export const MAX_FILES_UPLOAD_AT_THE_SAME_TIME = 5;
