import { AlertModel } from "app/components_v2/__modals/AlertNotificationsModal/types";
import { AlertNotificationDto } from "app/dtos/02-TAR/AlertNotification/AlertNotificationDto";
import { convertUTCtoLocaleDate } from "app/helpers/Utilities/date/Date.utilities";

export const AlertNotificationMapper = (
    receivedNotificationList: AlertNotificationDto[],
    sendedByMe?: boolean
): AlertModel[] => {
    return receivedNotificationList.map(
        ({
            audio,
            body,
            receiverProfilePictureURL,
            receivedDate,
            id,
            notificationFiles,
            senderUserId,
            isProgrammed,
            sendDate,
            title,
            senderUserName,
            senderProfilePictureURL,
            receiverNotificationList,
            isSent,
            fK_WorkingPosition,
            workingPositionName,
        }) => {
            const showQrInfo = !isSent && !!fK_WorkingPosition && receivedDate;
            return {
                date:
                    (isProgrammed && !sendedByMe && receivedDate) || (!isSent && !!fK_WorkingPosition && receivedDate)
                        ? convertUTCtoLocaleDate(new Date(receivedDate))
                        : convertUTCtoLocaleDate(new Date(sendDate)),
                fullName: showQrInfo ? workingPositionName : senderUserName || "",
                audioUrl: audio,
                receiverProfilePictureURL,
                message: body,
                id,
                notificationFiles: notificationFiles || [],
                senderUserId: showQrInfo ? fK_WorkingPosition : senderUserId,
                title,
                senderUserName: showQrInfo ? workingPositionName : senderUserName,
                senderProfilePictureURL: showQrInfo ? undefined : senderProfilePictureURL,
                receiverNotificationList,
            };
        }
    );
};
