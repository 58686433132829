import { convertUTCtoLocaleDate, getHoursFromDateUTC } from "app/helpers/Utilities/date/Date.utilities";
import { v4 } from "uuid";
import {
    selectRecurringTaskCustomValue,
    selectRecurringTaskValue,
    setDateRangeWeeklyDays,
    setDays,
} from "./setInitialValues";
import { MeasurementTypes } from "app/shared/Constants";
import { AssetDynamicFieldModel } from "app/models/05-QUA/AssetModels";
import { TaskBodyWithIdModel, TaskFieldModel2, TaskType, assetTaskFromBack } from "app/models/02-TAR/Task/TaskBody";
import {
    WizardTaskAllSteps,
    ReportDataList,
    AssetsToReport,
    ReportType,
    TaskTemporalityDateRangeFormModel,
    DynamicFieldsModel,
} from "app/models/02-TAR/TaskWizard";
import { INITIAL_TASK_TEMPORALITY_DATE_RANGE_FORM_MODEL } from "../constants/editTaskConstants";
import { AssetModel2 } from "app/models/05-QUA/AssetModels/AssetModel2";

export const fillFormValues = (
    values: TaskBodyWithIdModel,
    isDuplicate: boolean,
    assetOptions: AssetModel2[],
    offset?: number
): WizardTaskAllSteps => {
    const {
        description,
        name,
        fK_PlanAPPCC,
        fK_Company,
        fK_Department,
        fK_Subdepartment,
        fK_User,
        fK_WorkingPosition,
        workingPosition,
        user: userName,
        company: companyName,
        department: departmentName,
        subdepartment: subDepartmentName,
        isCritical,
        taskRangeHours,
        taskScheduler,
        neverEnds,
        numRecurrencies,
        endDate,
        fotoExampleId,
        fotoExample,
        isPhotoRequired,
        activeReassignedTo,
        taskCheckList,
        taskFields2,
        fK_BaseTask,
        taskType,
        startDate,
        taskTemporalityType,
        minPhotosAllowed,
        reportType,
    } = values;

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const taskFieldsValidated: TaskFieldModel2[] = taskFields2 || [];

    const isTaskFieldNone = taskFieldsValidated.filter((x) => !x.isDeleted).length === 0;
    const hasAssets = !isTaskFieldNone
        ? taskFieldsValidated.filter((x) => x.fK_Asset2 && !x.isDeleted).length > 0
        : false;

    const hasChecklist = !!taskCheckList?.length;
    const reportTypeToDelete: ReportType = !!reportType.length
        ? reportType
        : hasChecklist
        ? "CHECKLIST"
        : isTaskFieldNone
        ? "NONE"
        : hasAssets
        ? "ASSET"
        : "DYNAMIC_FIELD";

    const assets = getAsset(taskFieldsValidated);
    const isRecurrent = taskScheduler?.typeCode !== "ONE_TIME";

    const reportData: ReportDataList[] =
        reportTypeToDelete === "ASSET"
            ? assets.map(({ id, name }) => {
                  const selectedAssetfields = taskFieldsValidated.filter((x) => x.fK_Asset2 === id);
                  const isDeletedAsset = taskFieldsValidated
                      .filter((x) => x.fK_Asset2 === id)
                      .every(({ isDeleted }) => isDeleted);

                  return {
                      id: v4(),
                      dbId: id,
                      value: name || "",
                      label: name,
                      assetId: id,
                      isDeleted: isDeletedAsset,
                      assetsFields: selectedAssetfields.map<AssetsToReport>(
                          ({
                              fK_AssetFieldRange,
                              assetFieldRange,
                              dynamicFieldType,
                              label,
                              id,
                              isDeleted,
                              isHidden,
                              taskFieldOptions,
                              isRequired,
                          }) => ({
                              id: id || 0,
                              max: assetFieldRange?.max !== undefined ? assetFieldRange?.max : null,
                              min: assetFieldRange?.min !== undefined ? assetFieldRange?.min : null,
                              unit: assetFieldRange?.unit || MeasurementTypes.CELSIUS,
                              name: label || "",
                              type: label || "",
                              assetDynamicField: dynamicFieldType as AssetDynamicFieldModel,
                              assetFieldRangeId: fK_AssetFieldRange,
                              isDeleted,
                              isHidden,
                              isRequired: isRequired || true,
                              assetFieldOption: !!taskFieldOptions?.length
                                  ? taskFieldOptions.map(({ label }) => ({
                                        fK_AssetField: 0,
                                        id: 0,
                                        isDeleted: false,
                                        name: label,
                                    }))
                                  : [],
                          })
                      ),
                  };
              })
            : [];

    const dynamicFields = taskField2ModelToDynamicField(taskFieldsValidated, assets, assetOptions);

    return {
        fK_BaseTask: fK_BaseTask,
        taskTitle: name ?? "",
        taskDesc: description ?? "",
        belongsToAPPCC: fK_PlanAPPCC ? true : false,
        fK_PlanAPPCC: fK_PlanAPPCC ? String(fK_PlanAPPCC) : "-1",

        // Step 2
        companyForTask: {
            label: companyName || "",
            value: fK_Company ? String(fK_Company) : "",
            worksWithQr: false,
        },
        DepartmentForTask: {
            label: departmentName || "",
            value: fK_Department ? String(fK_Department) : "",
        },
        SubDepartementForTask: {
            label: subDepartmentName || "",
            value: fK_Subdepartment ? String(fK_Subdepartment) : "",
        },
        userForTask: {
            label: userName || "",
            value: fK_User ? String(fK_User) : "",
        },
        workingPositionForTask: {
            label: workingPosition?.customerInstanceWorkingPosition?.qrCode || "",
            value: fK_WorkingPosition ? String(fK_WorkingPosition) : "",
        },

        // Step 3
        somethingToReportCheckBox: false,
        reportType: reportTypeToDelete,
        somethingToReportDesc: taskFieldsValidated[0]?.label || "",
        somethingToReportCheckBoxImage: fotoExample && fotoExample.length !== 0 ? true : false,
        somethingToReportImage: fotoExample && fotoExample.length !== 0 ? fotoExample : "",
        imageBase64: null,
        fotoExampleId: Number(fotoExampleId),
        minPhotosAllowed: minPhotosAllowed || (isPhotoRequired ? 1 : null),
        criticalTask: isCritical,
        isPhotoRequired: isPhotoRequired,
        activeReassignedTo: activeReassignedTo,
        reportData: reportData,
        checkList:
            reportTypeToDelete === "CHECKLIST" && !!taskCheckList
                ? taskCheckList.map(({ id, name, isDeleted, isEnabled, fK_BaseTaskCheckList }) => ({
                      id: v4(),
                      dbId: isDuplicate ? undefined : Number(id),
                      name,
                      isDeleted,
                      isEnabled,
                      fK_BaseTaskCheckList,
                  }))
                : [],
        dynamicFields: reportTypeToDelete === "DYNAMIC_FIELD" ? dynamicFields : [],

        // Step 4
        temporalityType: taskTemporalityType,
        taskTemporalityDateRange: getDateRange(values),
        taskStart: getTaskStart(startDate, isDuplicate, taskType),
        taskHours: taskRangeHours
            ? taskRangeHours.map(({ hour, maxHour }) => {
                  const startHourFormated = getHoursFromDateUTC(convertUTCtoLocaleDate(new Date(hour), offset));
                  const endHourFormated = maxHour
                      ? getHoursFromDateUTC(convertUTCtoLocaleDate(new Date(maxHour), offset))
                      : undefined;
                  return {
                      id: v4(),
                      hour: startHourFormated,
                      maxHour: endHourFormated,
                      type: maxHour ? "range" : "hour",
                  };
              })
            : [],
        recurringTask: {
            isRecurrent,
            options: [],
            value:
                taskScheduler?.calendarDayNumber !== 0
                    ? "custom"
                    : taskScheduler?.repeatEvery !== 1
                    ? "custom"
                    : selectRecurringTaskValue(taskScheduler?.typeCode || "ONE_TIME"),
            custom: {
                customValue:
                    taskScheduler?.calendarDayNumber !== 0
                        ? "everyMonth"
                        : selectRecurringTaskCustomValue(taskScheduler?.typeCode || "ONE_TIME"),
                days: setDays(taskScheduler),
                repeatEvery: taskScheduler?.repeatEvery || 1,
                selectedOptions: {
                    text: "",
                    value:
                        taskScheduler?.calendarDayNumber !== 0
                            ? "everyMonthDayOfMonth"
                            : taskScheduler?.calendarWeekNumber !== 0
                            ? "everyMonthFirstDayOfWeek"
                            : "",
                },
            },
            text: taskScheduler?.name || "",
        },
        finish: {
            checked: neverEnds ? "never" : endDate ? "el" : numRecurrencies ? "after" : "never",
            value: neverEnds ? null : numRecurrencies ? numRecurrencies : endDate ? endDate : null,
        },
        taskType,
    };
};

const taskField2ModelToDynamicField = (
    dynamicFields: TaskFieldModel2[],
    assets: assetTaskFromBack[],
    assetOptions: AssetModel2[]
): DynamicFieldsModel[] => {
    const taskField: DynamicFieldsModel[] = [];

    const assetDynamicFields = dynamicFields.filter((x) => x.asset2 != null);
    const dynamicFieldsWithoutAsset = dynamicFields.filter((x) => x.asset2 == null);

    if (!!assetDynamicFields.length) {
        taskField.push({
            id: v4(),
            dbId: undefined,
            label: "",
            isDeleted: false,
            dropDownMultipleOptions: assetOptions.map(({ id, name }) => ({ id, label: name })),
            dropDownMultipleValues: assets.map((a) => ({
                id: v4(),
                assetsFields: assetDynamicFields
                    .filter(({ fK_Asset2 }) => fK_Asset2 === a.id)
                    .map<AssetsToReport>(
                        ({
                            fK_AssetFieldRange,
                            assetFieldRange,
                            dynamicFieldType,
                            label,
                            id,
                            isDeleted,
                            isHidden,
                            taskFieldOptions,
                            isRequired,
                        }) => ({
                            id: id || 0,
                            max: assetFieldRange?.max != null ? assetFieldRange?.max : null,
                            min: assetFieldRange?.min != null ? assetFieldRange?.min : null,
                            unit: assetFieldRange?.unit || MeasurementTypes.CELSIUS,
                            name: label || "",
                            type: label || "",
                            assetDynamicField: dynamicFieldType as AssetDynamicFieldModel,
                            assetFieldRangeId: fK_AssetFieldRange,
                            isDeleted,
                            isHidden,
                            isRequired: isRequired || true,
                            assetFieldOption: !!taskFieldOptions?.length
                                ? taskFieldOptions.map(({ label }) => ({
                                      fK_AssetField: 0,
                                      id: 0,
                                      isDeleted: false,
                                      name: label,
                                  }))
                                : [],
                        })
                    ),
                dbId: a.id,
                isDeleted: a.isDeleted,
                label: a.name,
                value: String(a.id),
            })),
            dynamicFieldsType: "DROPDOWN_MULTIPLE_ASSETS_RANGE",
            isRequired: true,
            order: assetDynamicFields[0]?.order || 0,
            taskFieldOptions: [],
        });
    }

    dynamicFieldsWithoutAsset.forEach(
        ({ dynamicFieldType, id, isRequired, label, taskFieldOptions, isDeleted, order }) => {
            taskField.push({
                dbId: id,
                dynamicFieldsType: dynamicFieldType || "DATE",
                id: v4(),
                isDeleted,
                isRequired: !!isRequired,
                label: label || "",
                taskFieldOptions:
                    taskFieldOptions?.map((taskfield) => ({
                        label: taskfield.label,
                        id: v4(),
                    })) || [],
                dropDownMultipleOptions: [],
                dropDownMultipleValues: [],
                order,
            });
        }
    );

    return taskField.sort((a, b) => a.order - b.order);
};

const getAsset = (taskFieldsValidated: TaskFieldModel2[]): assetTaskFromBack[] => {
    const assets: assetTaskFromBack[] = [];
    const assetIds: number[] = [];
    taskFieldsValidated.forEach((asset) => {
        if (asset.asset2 != null && asset.fK_Asset2 != null && !assetIds.includes(asset.fK_Asset2)) {
            assets.push(asset.asset2);
            assetIds.push(asset.fK_Asset2);
        }
    });
    return assets || [];
};

const getDateRange = ({
    taskTemporalityType,
    taskTemporalityDateRangeScheduler,
    taskTemporalityDateRange,
}: TaskBodyWithIdModel): TaskTemporalityDateRangeFormModel => {
    const isDateRangeWeeklyOrMonthly =
        taskTemporalityType === "RANGE_DATES" &&
        taskTemporalityDateRangeScheduler !== null &&
        taskTemporalityDateRangeScheduler.type !== "CUSTOM";

    const isDateRangeCustom = taskTemporalityType === "RANGE_DATES" && !!taskTemporalityDateRange.length;

    if (!isDateRangeWeeklyOrMonthly && !isDateRangeCustom) return INITIAL_TASK_TEMPORALITY_DATE_RANGE_FORM_MODEL;

    if (isDateRangeCustom) {
        return {
            ...INITIAL_TASK_TEMPORALITY_DATE_RANGE_FORM_MODEL,
            value: "CUSTOM",
            custom: {
                dateRange: taskTemporalityDateRange.map(({ endDate, isDeleted, startDate, id }) => ({
                    dbId: null,
                    id: v4(),
                    startDate: startDate.toString(),
                    endDate: endDate.toString(),
                    isDeleted,
                })),
            },
        };
    }
    if (!isDateRangeWeeklyOrMonthly) return INITIAL_TASK_TEMPORALITY_DATE_RANGE_FORM_MODEL;

    const { id, type, repeatEvery, monthlyDay, monthlyType, monthlyStartDay, monthlyEndDay } =
        taskTemporalityDateRangeScheduler;
    const isMonthlyDays = monthlyType !== "" && monthlyType !== "CUSTOM" && monthlyDay != null;
    const isMonthlyCustomDays =
        monthlyType !== "" && monthlyType === "CUSTOM" && monthlyStartDay != null && monthlyEndDay != null;

    return {
        dbId: id,
        custom: {
            dateRange: [],
        },
        monthly:
            type === "MONTHLY"
                ? {
                      day: isMonthlyDays ? String(monthlyDay) : "",
                      customDays: {
                          startDay: isMonthlyCustomDays ? String(monthlyStartDay) : "",
                          endDay: isMonthlyCustomDays ? String(monthlyEndDay) : "",
                      },
                      value: monthlyType || "FIRST_X_DAYS",
                      text: "",
                  }
                : INITIAL_TASK_TEMPORALITY_DATE_RANGE_FORM_MODEL.monthly,
        repeatEvery: String(repeatEvery),
        text: "",
        value: type,
        weeklyDays:
            type === "WEEKLY"
                ? setDateRangeWeeklyDays(taskTemporalityDateRangeScheduler)
                : INITIAL_TASK_TEMPORALITY_DATE_RANGE_FORM_MODEL.weeklyDays,
    };
};

const getTaskStart = (startDate: string, isDuplicate: boolean, taskType: TaskType) => {
    const today = new Date();
    if (isDuplicate) return today.toString();

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const startDateUTC = new Date(startDate);

    if (startDateUTC >= tomorrow) return startDate.toString();
    if (taskType === "SPORADIC") return today.toString();

    return tomorrow.toString();
};
