import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "../../Popover/Popover";
import { DropdownList } from "../../Dropdown/DropdownList/DropdownList";
import { IDropDownListItem } from "../../Dropdown/types";
import { FC, MouseEvent } from "react";
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons";

type DocumentalMoreOptionsProps = {
    options: IDropDownListItem[];
    isOpen: boolean;
    setElement: (element: Element | null) => void;
    target: Element | null;
    onClick: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void;
    onResize?: () => void;
    onScroll?: () => void;
    onClickOutside?: () => void;
};

export const DocumentalMoreOptions: FC<DocumentalMoreOptionsProps> = ({
    options,
    onClick,
    isOpen,
    onResize,
    onScroll,
    setElement,
    target,
    onClickOutside,
}) => {
    return (
        <>
            <div
                className="documentalMoreOptions"
                ref={setElement}
                onMouseUp={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onClick && onClick(e);
                }}
            >
                <FontAwesomeIcon className="documentalMoreOptions__svg" icon={faEllipsisVertical} />
            </div>
            {isOpen && (
                <Popover
                    target={target}
                    position={"bottomRight"}
                    onResize={onResize}
                    onScroll={onScroll}
                    onClickOutside={(e) => {
                        e.stopPropagation();
                        onClickOutside && onClickOutside();
                    }}
                    showBlur={false}
                    separation={4}
                >
                    <div className="documentalMoreOptions__container">
                        <DropdownList items={options} />
                    </div>
                </Popover>
            )}
        </>
    );
};
