import { IssueTranslation } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IssueBodyTitle } from "../IssueBodyTitle/IssueBodyTitle";
import { Tag } from "app/components_v2/Tag/Tag";
import { CompanyModel } from "app/models/01-SEG/Company/CompanyModels";
import { getInitials } from "app/helpers/Avatar/getInitials";

type IssueBodyCompanyProps = {
    company: CompanyModel;
};

export const IssueBodyCompany: FC<IssueBodyCompanyProps> = ({ company }) => {
    const { t } = useTranslation();
    const { name, logoFileURL, id } = company;
    return (
        <div className="issueBodycompany">
            <IssueBodyTitle>{t(IssueTranslation.ISSUE_BODY_RESPONSIBLE_TITLE)}</IssueBodyTitle>
            <Tag
                message={name}
                avatar={{ id: String(id), initials: getInitials(name), name, profilePictureURL: logoFileURL }}
                variant="white"
            />
        </div>
    );
};
