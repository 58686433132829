import { OptionCompany } from "app/components_v2/OptionCompany/OptionCompany";
import { Button } from "app/components_v2/__buttons/Button/Button";
import { AuthLayout } from "app/components_v2/__containers/AuthLayout/AuthLayout";
import { AlertNotificationsModal } from "app/components_v2/__modals/AlertNotificationsModal/AlertNotificationsModal";
import { AlertModel } from "app/components_v2/__modals/AlertNotificationsModal/types";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { OptionCompanySkelleton } from "app/components_v2/__skelletons/OptionCompanySkelleton/OptionCompanySkelleton";
import { CompanyDepartmentDto } from "app/dtos/01-SEG/DepartmentDto";
import { setLocalStorageSession } from "app/helpers/BrowserStorage/LocalStorageHandler";
import { useSession, useTitle } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { AlertNotificationMapper } from "app/mappers/AlertNotificationMapper";
import { UsrDepartmentModel } from "app/models/01-SEG/UserCompanyDepartment/UserCompanyDepartmentModel";
import UserScanQrService from "app/services/02-TAR/UserScanQrService";
import WorkingPositionService from "app/services/02-TAR/WorkingPositionService";
import { PrivatePaths } from "app/shared/Constants";
import { Actions } from "app/state/actions";
import {
    TranslationCommon,
    TranslationKeys,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

type SelectDepartmentModel = CompanyDepartmentDto & {
    isActive: boolean;
};

export const QrChecker = () => {
    const nav = useNavigate();
    const dispatch = useDispatch();
    const session = useSession();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const { t } = useTranslation();
    const { changeWorkingCompany } = useChangeWorkingSession();

    useTitle(t(TranslationTitles.SCAN_QR_PAGE_TITLE));

    const [departments, setDepartments] = useState<SelectDepartmentModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedDepartment, setSelectedDepartment] = useState<CompanyDepartmentDto | undefined>(undefined);
    const [acceptButtonIsLoading, setAcceptButtonIsLoading] = useState<boolean>(false);
    const [nameQR, setNameQR] = useState<string | undefined>();
    const [isAlertNotificationsModalVisible, setIsAlertNotificationsModalVisible] = useState<boolean>(false);
    const [alertNotifications, setAlertNotifications] = useState<AlertModel[]>([]);

    const fetch = async (qrCode: string) => {
        setIsLoading(true);
        const { status, data, getParsedError } = await WorkingPositionService.GetDepartments(qrCode);
        if (!status()) {
            handleToast({
                title: getErrorMessage(getParsedError()),
                variant: "danger",
                type: "alert",
            });
            setIsLoading(false);
            removeQr();
            navigateNextPage();
            return;
        }
        const departmentsId: number[] = [];
        if (session?.user?.companies) {
            const companies = session.user.companies;
            const departmentsToAdd: number[] = companies
                .flatMap(({ departments }) => departments?.map(({ departmentId }) => departmentId) || [])
                .filter((x) => x !== undefined);

            departmentsId.push(...departmentsToAdd);
        }

        setDepartments(
            data.list.map(({ departmentId, departmentName, companyId, companyName }) => ({
                departmentId,
                departmentName,
                companyId,
                companyName,
                isActive: departmentsId.includes(Number(departmentId)),
            }))
        );
        if (data.tab != null) setNameQR(data.tab);
        if (data.list.length === 1) handleOpenModal(data.list[0]);

        setIsLoading(false);
        return;
    };

    const handleFetch = async () => {
        const qr = localStorage.getItem("qrCode");
        if (!qr) {
            nav(`/${PrivatePaths.SELECT_DEPARTMENT}`);
            return;
        }
        await fetch(qr);
    };

    const handleOpenModal = (dept: CompanyDepartmentDto) => {
        setSelectedDepartment(dept);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        if (departments.length === 1) {
            removeQr();
            navigateNextPage();
            return;
        }
        setIsModalOpen(false);
        setSelectedDepartment(undefined);
    };

    const handleAccept = async (dept: CompanyDepartmentDto) => {
        const qr = localStorage.getItem("qrCode");
        if (!qr) return;
        if (!session?.user) return;
        setAcceptButtonIsLoading(true);
        const { status, getParsedError, data } = await UserScanQrService.Save(qr, {
            departmentId: Number(dept.departmentId),
            userId: session.user.id,
        });

        if (!status()) {
            handleToast({
                title: getErrorMessage(getParsedError()),
                variant: "danger",
                type: "alert",
            });
            setAcceptButtonIsLoading(false);
            handleCloseModal();
            return;
        }

        removeQr();

        if (!!data.length) {
            setIsAlertNotificationsModalVisible(true);
            setAlertNotifications(AlertNotificationMapper(data));
            return;
        }
        const selectedDepartmentDto: UsrDepartmentModel = {
            departmentId: dept.departmentId,
            departmentName: dept.departmentName,
        };
        handleSelectQr(Number(dept.companyId), dept.companyName, selectedDepartmentDto);
    };

    const handleCloseAlertModal = () => {
        setIsAlertNotificationsModalVisible(false);
        setAlertNotifications([]);
        if (!selectedDepartment) return;

        const selectedDepartmentDto: UsrDepartmentModel = {
            departmentId: selectedDepartment.departmentId,
            departmentName: selectedDepartment.departmentName,
        };

        handleSelectQr(Number(selectedDepartment.companyId), selectedDepartment.companyName, selectedDepartmentDto);
    };

    const handleSelectQr = (companyId: number, companyName: string, selectedDepartment: UsrDepartmentModel) => {
        if (
            session?.user?.temporaryCompanies &&
            session?.user?.companies &&
            !session?.user?.companies.some((c) =>
                c.departments?.some((d) => d.departmentId == selectedDepartment.departmentId)
            )
        ) {
            const selectedTemporaryCompanyDepartments = session?.user?.temporaryCompanies.find(
                (c) => c.companyId === companyId
            )?.departments;
            const foundSelectedDepartment = selectedTemporaryCompanyDepartments?.find(
                (d) => d.departmentId === selectedDepartment.departmentId
            );

            if (!foundSelectedDepartment) {
                // Insert Selected Department
                const updatedSession = {
                    ...session,
                    user: {
                        ...session.user,
                        temporaryCompanies: [
                            ...(session?.user?.temporaryCompanies.filter((c) => c.companyId !== companyId) || []),
                            {
                                companyId: companyId,
                                companyName: companyName,
                                departments: [...(selectedTemporaryCompanyDepartments || []), selectedDepartment],
                            },
                        ],
                    },
                };
                // Store Redux
                dispatch(Actions.setAppSession(updatedSession));
                setLocalStorageSession(updatedSession);
            }
        }

        // Update WorkingCompany
        changeWorkingCompany({
            workingCompany: { companyId, companyName },
            workingDepartment: undefined,
            workingSubDepartment: undefined,
        });
        dispatch(Actions.switchWizardMode(false));
        nav(`/${PrivatePaths.DASHBOARD}`);
    };

    const navigateNextPage = () => {
        setIsAlertNotificationsModalVisible(false);
        setAlertNotifications([]);
        if (!session) {
            nav(`/${PrivatePaths.SELECT_DEPARTMENT}`);
            return;
        }
        const goToRoute = PrivatePaths.DASHBOARD;

        nav(`/${goToRoute}`);
        return;
    };

    const removeQr = () => {
        localStorage.removeItem("qrCode");
        localStorage.removeItem("urlParamType");
    };

    useEffect(() => {
        handleFetch();
    }, []);

    return (
        <AuthLayout>
            {isAlertNotificationsModalVisible && (
                <AlertNotificationsModal data={alertNotifications} closeModal={handleCloseAlertModal} />
            )}
            {isModalOpen && selectedDepartment && nameQR && (
                <ConfirmModal
                    onConfirm={() => handleAccept(selectedDepartment)}
                    title={`${t(TranslationModals.QR_CHECKER_MODAL_TITLE).replace("{1}", nameQR)}`}
                    description={`${t(TranslationModals.QR_CHECKER_MODAL_DESCRIPTION).replace("{1}", nameQR)}`}
                    onClose={handleCloseModal}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    isLoading={acceptButtonIsLoading}
                />
            )}
            <div className="loginInputForm qrCheckerContainer">
                <h1 className="qrCheckerContainer__title">{t(TranslationKeys.QR_CHECKER_TITLE)}</h1>
                <div className="qrCheckerContainer__list">
                    {isLoading ? (
                        <OptionCompanySkelleton />
                    ) : (
                        departments.map(({ isActive, departmentId, departmentName, companyId, companyName }) => (
                            <OptionCompany
                                name={departmentName}
                                onClick={() =>
                                    handleOpenModal({ departmentName, departmentId, companyId, companyName })
                                }
                                isActive={isActive}
                                key={departmentId}
                            />
                        ))
                    )}
                </div>
                <div>
                    <Button text={t(TranslationCommon.CANCEL)} onClick={navigateNextPage} type="secondary" />
                </div>
            </div>
        </AuthLayout>
    );
};
