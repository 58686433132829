import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import FetchService from "../Fetch/FetchService";
import { PaginatedList } from "app/models/ServiceResponse/PaginatedResult";
import { DocumentalFolderListDto } from "app/dtos/07-DOC/documentalFolder/DocumentalFolderListDto";
import { ApiPaths } from "app/shared/Constants";
import { DocumentalFolderDto } from "app/dtos/07-DOC/documentalFolder/DocumentalFolderDto";

export default class DocumentalFolderService {
    public static async GetOne(id: number) {
        return FetchService.get<DocumentalFolderDto>({
            url: `${ApiPaths.DOC_API}/DocumentalFolder/${id}`,
        });
    }

    public static async GetData(paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedList<DocumentalFolderListDto>>({
            url: `${ApiPaths.DOC_API}/DocumentalFolder`,
            paginationParams,
        });
    }

    public static async Save(data: DocumentalFolderDto) {
        return FetchService.post<DocumentalFolderDto>({
            url: `${ApiPaths.DOC_API}/DocumentalFolder`,
            body: data,
        });
    }

    public static async Edit(data: DocumentalFolderDto) {
        return FetchService.put<DocumentalFolderDto>({
            url: `${ApiPaths.DOC_API}/DocumentalFolder/${data.id}`,
            body: data,
        });
    }

    public static async Delete(id: number) {
        return FetchService.delete({
            url: `${ApiPaths.DOC_API}/DocumentalFolder/${id}`,
        });
    }
}
