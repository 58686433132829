import { FC } from "react";
import { ProgresssBarWithLabel } from "app/components_v2/ProgressBarWithLabel/ProgressBarWithLabel";
import { multiplyAndFormat } from "app/helpers/Utilities/number/multiplyAndFormat";

export type TaskPillPercentageProps = {
    listMaxCount: number;
    listCount?: number;
};

export const TaskPillPercentage: FC<TaskPillPercentageProps> = ({ listCount, listMaxCount }) => {
    const calculatePercentage = (value: number, total: number): number => {
        const porcentaje = multiplyAndFormat(value / total, 100);
        return porcentaje;
    };

    return (
        <div className="taskPillPercentage">
            <ProgresssBarWithLabel
                percentage={calculatePercentage(listCount || 0, listMaxCount)}
                barLabel={`${listCount || 0}/${listMaxCount}`}
                barLabelSize="xs"
            />
        </div>
    );
};
