import { DocumentalFileType } from "app/models/07-DOC/DocumentalFileType";
import { DocumentalFileModalCreatedBy } from "../DocumentalFileModal";
import { FC } from "react";
import { DocumentalFileModalTitle } from "../DocumentalFileModalTitle/DocumentalFileModalTitle";
import { DocumentalFileModalText } from "../DocumentalFileModalText/DocumentalFileModalText";
import { Avatar } from "app/components_v2/Avatar/Avatar";
import { useTranslation } from "react-i18next";
import { DocumentalFileTranslations } from "app/translation/translationKeys";

type DocumentalFileModalBodyProps = {
    type: DocumentalFileType;
    name: string;
    createdAt: string;
    size: string;
    dimensions: string;
    createdBy: DocumentalFileModalCreatedBy;
};

export const DocumentalFileModalBody: FC<DocumentalFileModalBodyProps> = ({
    createdAt,
    createdBy,
    dimensions,
    name,
    size,
    type,
}) => {
    const { t } = useTranslation();
    const { fullName, imageUrl, initials, id } = createdBy;
    return (
        <div className="documentalFileModalBody">
            <div className="documentalFileModalBody__info">
                <div className="documentalFileModalBody__container">
                    <DocumentalFileModalTitle>
                        {t(DocumentalFileTranslations.DOCUMENTAL_FILE_MODAL_NAME)}
                    </DocumentalFileModalTitle>
                    <DocumentalFileModalText>{name}</DocumentalFileModalText>
                </div>
                <div className="documentalFileModalBody__container">
                    <DocumentalFileModalTitle>
                        {t(DocumentalFileTranslations.DOCUMENTAL_FILE_MODAL_CREATED_AT)}
                    </DocumentalFileModalTitle>
                    <DocumentalFileModalText>{createdAt}</DocumentalFileModalText>
                </div>
                <div className="documentalFileModalBody__container">
                    <DocumentalFileModalTitle>
                        {t(DocumentalFileTranslations.DOCUMENTAL_FILE_MODAL_CREATED_BY)}
                    </DocumentalFileModalTitle>
                    <div className="documentalFileModalBody__avatar">
                        <Avatar nameInitials={initials} img={imageUrl} avatarName={fullName} colorId={id} size="xs" />
                        <DocumentalFileModalText>{fullName}</DocumentalFileModalText>
                    </div>
                </div>
            </div>
            <div className="documentalFileModalBody__info">
                <div className="documentalFileModalBody__container">
                    <DocumentalFileModalTitle>
                        {t(DocumentalFileTranslations.DOCUMENTAL_FILE_MODAL_TYPE)}
                    </DocumentalFileModalTitle>
                    <DocumentalFileModalText>{type}</DocumentalFileModalText>
                </div>
                <div className="documentalFileModalBody__container">
                    <DocumentalFileModalTitle>
                        {t(DocumentalFileTranslations.DOCUMENTAL_FILE_MODAL_WEIGHT)}
                    </DocumentalFileModalTitle>
                    <DocumentalFileModalText>{size}</DocumentalFileModalText>
                </div>
                <div className="documentalFileModalBody__container">
                    <DocumentalFileModalTitle>
                        {t(DocumentalFileTranslations.DOCUMENTAL_FILE_MODAL_DIMENSIONS)}
                    </DocumentalFileModalTitle>
                    <DocumentalFileModalText>{dimensions}</DocumentalFileModalText>
                </div>
            </div>
        </div>
    );
};
