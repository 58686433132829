import { FC, useRef, useState } from "react";
import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { DataToReportList } from "app/components_v2/WizardTask/DataToReportList";
import { SelectWithDisplay, SelectWithDisplayItems } from "app/components_v2/SelectWithDisplay";
import { SelectOptions } from "app/components_v2/__inputs/SelectOptions/SelectOptions";
import { AssetModel2 } from "app/models/05-QUA/AssetModels/AssetModel2";
import { DynamicFields } from "../DynamicFields/DynamicFields";
import { Taskfield2Error, TaskfieldOptionsError } from "app/models/02-TAR/Task/EditTask";
import {
    ReportType,
    DynamicFieldsType,
    ReportDataList,
    CheckListModel,
    DynamicFieldsModel,
} from "app/models/02-TAR/TaskWizard";
import { PreviewChecklistModal } from "app/components_v2/__modals/PreviewChecklistModal/PreviewChecklistModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { Label } from "app/components_v2/__inputs";
import { PreviewDynamicFieldsModal } from "app/components_v2/__modals/PreviewDynamicFieldsModal/PreviewDynamicFieldsModal";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { SelectDataToReportAsset } from "./SelectDataToReportAsset/SelectDataToReportAsset";

export type FieldValue = { id: string; value: string; isDeleted?: boolean };

export type FieldValues = {
    selectableValue: ReportType;
    fieldValue: FieldValue | null;
    dynamicFieldsType?: DynamicFieldsType;
};

type SelectDataToReportProps = {
    options: OptionModel[];
    value: ReportType;
    reportData: ReportDataList[];
    customError?: string;
    assets: AssetModel2[];
    isAssetErrored?: boolean;
    isAssetsLoading: boolean;
    variant?: "editTask" | "modal";
    onChange: (field: Partial<FieldValues>) => void;
    onDeleteAsset: (id: number) => void;
    onAddNewChecklist: () => void;
    onDeleteCheckList: (id: string) => void;
    onBlurCheckList: () => void;
    disabled?: boolean;
    checkList: CheckListModel[];
    dynamicFieldsOptions: OptionModel[];
    isLoadingDynamicFields: boolean;
    dynamicFields: DynamicFieldsModel[];
    dynamicFieldsError: Taskfield2Error[];
    checklistError: TaskfieldOptionsError[];
    onDynamicFieldInputChange: (value: string, id: string) => void;
    onDynamicFieldCheckboxChange: (value: boolean, id: string) => void;
    onDeleteDynamicField: (id: string) => void;
    onDeleteDynamicDropDownField: (id: string, itemId: string) => void;
    onChangeDynamicDropDownField: (id: string, itemId: string, value: string) => void;
    onAddNewDynamicDropDownField: (id: string) => void;
    onChangeDynamicDropDownAssetField: (selectedValue: OptionModel, id: string) => void;
    onDeleteDynamicDropDownAssetField: (id: string, itemId: string) => void;
    disabledChecklist?: boolean;
    disabledDynamicFields?: boolean;
    hideSelect?: boolean;
};

export const SelectDataToReport: FC<SelectDataToReportProps> = ({
    options,
    value: selectedValue,
    reportData,
    customError,
    onChange,
    assets,
    isAssetErrored,
    isAssetsLoading,
    onDeleteAsset,
    disabled,
    checkList,
    onAddNewChecklist,
    onDeleteCheckList,
    onBlurCheckList,
    dynamicFieldsOptions,
    isLoadingDynamicFields,
    dynamicFields,
    onDynamicFieldCheckboxChange,
    onDynamicFieldInputChange,
    onDeleteDynamicField,
    onDeleteDynamicDropDownField,
    onChangeDynamicDropDownField,
    onAddNewDynamicDropDownField,
    onChangeDynamicDropDownAssetField,
    onDeleteDynamicDropDownAssetField,
    dynamicFieldsError,
    variant = "editTask",
    checklistError,
    disabledChecklist,
    disabledDynamicFields,
    hideSelect,
}) => {
    const { t } = useTranslation();
    const ref = useRef(false);
    const [isPreviewChecklistModalVisible, setIsPreviewChecklistModalVisible] = useState<boolean>(false);
    const [isPreviewDynamicFieldsModalVisible, setIsPreviewDynamicFieldsModalVisible] = useState<boolean>(false);

    const handlePreview = () => {
        if (selectedValue === "CHECKLIST") {
            setIsPreviewChecklistModalVisible(true);
            return;
        }
        if (selectedValue === "DYNAMIC_FIELD") {
            setIsPreviewDynamicFieldsModalVisible(true);
            return;
        }
    };

    const fillAssetsOptions = () => {
        const assetOptions: OptionModel[] = assets.map(({ name, assetId, id }) => ({
            label: `${name} ${assetId && `(${assetId})`}`,
            value: String(id),
        }));

        return assetOptions;
    };

    const fillAssetsItems = (): SelectWithDisplayItems[] => {
        return reportData
            .filter(({ isDeleted }) => !isDeleted)
            .map(({ value, assetId }) => {
                return {
                    id: Number(assetId) || 0,
                    label: value,
                };
            });
    };

    const handleDynamicFieldsChange = ({ value }: OptionModel) => {
        ref.current = true;
        onChange({
            dynamicFieldsType: (value as DynamicFieldsType) || "DATE",
            selectableValue: "DYNAMIC_FIELD",
        });
    };

    return (
        <>
            {isPreviewChecklistModalVisible && (
                <PreviewChecklistModal
                    checkList={checkList.filter(({ isDeleted }) => !isDeleted)}
                    onClose={() => setIsPreviewChecklistModalVisible(false)}
                />
            )}
            {isPreviewDynamicFieldsModalVisible && (
                <PreviewDynamicFieldsModal
                    dynamicfields={dynamicFields.filter(({ isDeleted }) => !isDeleted)}
                    onClose={() => setIsPreviewDynamicFieldsModalVisible(false)}
                />
            )}
            <div className="selectDataToReport">
                {!hideSelect && (
                    <div className="selectDataToReport__preview">
                        <Label label={t(TranslationKeys.DATAS_TO_REPORT)} />
                        <div className="selectDataToReport__preview__container">
                            <div className="selectDataToReport__select">
                                <SelectOptions
                                    selectedValue={selectedValue}
                                    options={options.map(({ label, value }) => ({ label, value }))}
                                    onChange={(newValue) => {
                                        const dataToReportPosibleOptions = [
                                            "DATA",
                                            "NONE",
                                            "ASSET",
                                            "CHECKLIST",
                                            "DYNAMIC_FIELD",
                                            "RANGE_REGISTER",
                                        ];
                                        if (!dataToReportPosibleOptions.includes(newValue.value)) return;
                                        onChange({
                                            selectableValue: newValue.value as ReportType,
                                            fieldValue: null,
                                        });
                                    }}
                                    isMulti={false}
                                    disabled={disabled || disabledChecklist || disabledDynamicFields}
                                />
                            </div>
                            {((selectedValue === "CHECKLIST" &&
                                !!checkList.filter(({ isDeleted, name }) => !isDeleted && !!name.length).length) ||
                                (selectedValue === "DYNAMIC_FIELD" &&
                                    !!dynamicFields.filter(({ isDeleted, label }) => !isDeleted && !!label.length)
                                        .length)) && (
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="baseTaskDataToReport__selector__previewIcon"
                                    onClick={handlePreview}
                                />
                            )}
                        </div>
                    </div>
                )}
                <div>
                    {selectedValue === "ASSET" && (
                        <SelectDataToReportAsset
                            isLoading={isAssetsLoading}
                            isErrored={!!isAssetErrored}
                            options={fillAssetsOptions()}
                            customError={customError}
                            items={fillAssetsItems()}
                            onDeleteAsset={onDeleteAsset}
                            onChange={onChange}
                            reportData={reportData}
                            selectedValue={selectedValue}
                            disabled={disabled}
                        />
                    )}
                    {selectedValue === "CHECKLIST" && (
                        <div
                            className={
                                variant === "editTask"
                                    ? `selectDataToReport__checklist`
                                    : `selectDataToReport__checklist--small`
                            }
                        >
                            <DataToReportList
                                items={checkList.map(({ id, isDeleted, name }) => ({
                                    id,
                                    value: name,
                                    isDeleted,
                                }))}
                                onAddNew={onAddNewChecklist}
                                onBlur={onBlurCheckList}
                                onInputChange={(values) =>
                                    onChange({
                                        selectableValue: selectedValue,
                                        fieldValue: values,
                                    })
                                }
                                customError={checklistError}
                                onDelete={onDeleteCheckList}
                                disabled={disabled || disabledChecklist}
                                placeholder={t(TranslationKeys.PLACEHOLDER_CHECKLIST)}
                            />
                        </div>
                    )}
                    {selectedValue === "DYNAMIC_FIELD" && (
                        <div className="dynamicFields">
                            {!!dynamicFields.length && (
                                <div className="dynamicFields__content">
                                    <DynamicFields
                                        isLoadingDynamicFields={isLoadingDynamicFields}
                                        dynamicFields={dynamicFields}
                                        onCheckboxChange={onDynamicFieldCheckboxChange}
                                        onInputChange={onDynamicFieldInputChange}
                                        onDeleteDynamicField={onDeleteDynamicField}
                                        onDeleteDynamicDropDownField={onDeleteDynamicDropDownField}
                                        onChangeDynamicDropDownField={onChangeDynamicDropDownField}
                                        onAddNewDynamicDropDownField={onAddNewDynamicDropDownField}
                                        onChangeDynamicDropDownAssetField={onChangeDynamicDropDownAssetField}
                                        onDeleteDynamicDropDownAssetField={onDeleteDynamicDropDownAssetField}
                                        errors={dynamicFieldsError}
                                        focus={ref.current}
                                        disabled={disabled || disabledChecklist}
                                    />
                                </div>
                            )}
                            <div className="selectDataToReport__select">
                                <SelectOptions
                                    isMulti={false}
                                    onChange={handleDynamicFieldsChange}
                                    options={dynamicFieldsOptions}
                                    errorMessage={customError}
                                    disabled={disabled || disabledDynamicFields}
                                    placeholder={t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_SELECT_DYNAMIC_FIELD)}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
