import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { ApiPaths } from "../../shared/Constants";
import FetchService from "../Fetch/FetchService";
import { SystemNotificationModel } from "app/models/02-TAR/SystemNotification/SystemNotificationModel";

export default class SystemNotificationService {
    // GET System Notification List
    public static async GetSystemNotifications(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<SystemNotificationModel>>({
            url: `${ApiPaths.TAR_API}/SystemNotification`,
            paginationParams: params,
        });
    }

    // PUT Mark One System Notification Seen
    public static async Edit(id: number | string) {
        return FetchService.put({
            url: `${ApiPaths.TAR_API}/SystemNotification/${id}`,
        });
    }

    // PUT Mark All System Notification Seen
    public static async MarkAllAsSeen() {
        return FetchService.put({
            url: `${ApiPaths.TAR_API}/SystemNotification/MarkAllAsSeen`,
        });
    }
}
