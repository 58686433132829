import { JSXElementConstructor, ReactElement, ReactNode, ReactPortal, forwardRef } from "react";
import { createPortal } from "react-dom";
import { GenericModalFooter } from "../base/GenericModal/GenericModalFooter/GenericModalFooter";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
type ConfirmModalProps = {
    onClose?: () => void;
    onConfirm: () => void;
    onConfirmText: string;
    onCloseText?: string;
    title?: string;
    description?: string;
    isLoading?: boolean;
    portal?: boolean;
    type?: "info" | "delete";
    children?:
        | string
        | number
        | boolean
        | ReactElement<any, string | JSXElementConstructor<any>>
        | Iterable<ReactNode>
        | ReactPortal
        | null
        | undefined;
    onConfirmIcon?: IconProp;
    onCloseIcon?: IconProp;
    variant?: TabHeaderVariants;
    icon?: ReactNode;
};

export const ConfirmModal = forwardRef<HTMLInputElement, ConfirmModalProps>(
    (
        {
            description,
            onConfirm,
            onConfirmText,
            isLoading,
            onClose,
            onCloseText,
            portal = true,
            title,
            type = "info",
            children,
            onCloseIcon,
            onConfirmIcon,
            variant = "primary",
            icon,
        },
        ref
    ) => {
        const ModalContent = (
            <GenericModal
                size="md"
                footer={
                    <GenericModalFooter
                        confirmButton={{
                            text: onConfirmText,
                            onClick: onConfirm,
                            danger: type === "delete",
                            iconRight: onConfirmIcon,
                            variant: type === "delete" ? "danger" : variant,
                        }}
                        closeButton={
                            onClose && {
                                text: onCloseText || "",
                                onClick: onClose,
                                iconLeft: onCloseIcon,
                                buttonType: "tertiary",
                                variant,
                            }
                        }
                        loading={isLoading}
                    />
                }
            >
                <div ref={ref} data-testid="confirmModal">
                    <div className="confirmModal__body">
                        <div className="confirmModal__header">
                            {!!icon && icon}
                            {title && <h2 className="confirmModal__body__title">{title}</h2>}
                        </div>
                        {description && <p className="confirmModal__body__description">{description}</p>}
                        {children}
                    </div>
                </div>
            </GenericModal>
        );
        return <>{portal ? createPortal(ModalContent, document.body) : ModalContent}</>;
    }
);
