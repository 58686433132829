import { useState, useContext } from "react";
import { useSession } from "app/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "app/hooks/Toast/useToast";
import { usePlanAPPCC } from "app/hooks/usePlanAPPCC";
import AssetService from "app/services/05-QUA/AssetService";
import { fillExtraparams, mapTabNumberToTabType } from "../helpers/fillExtraparams";
import { PaginationDefaults, PrivatePaths } from "app/shared/Constants";
import {
    INITIAL_ASSET_SORT_FIELD,
    INITIAL_ASSET_SORT_DIRECTION,
    INITIAL_ASSET_COUNTERS,
} from "../../constants/assetConstants";
import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";
import { AssetGridContext } from "../../state/context/AssetGridContext";
import { AssetGridSF } from "../../types";
import { AssetCategory, AssetGridModel } from "../models/AssetGridModel";
import { AssetTabCountersDto } from "app/dtos/05-QUA/Asset/AssetTabCountersDto";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";
import AssetRangeService from "app/services/05-QUA/AssetRangeService";
import { AssetListDto } from "app/dtos/05-QUA/Asset/AssetListDto";

export const useAsset = () => {
    const session = useSession();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { handleToast } = useToast();
    const { getPlans, onChangeUrlParams, planOptions, urlParams } = usePlanAPPCC();
    const { changeWorkingCompanyByCompanyId } = useChangeWorkingSession();

    const {
        currentTab,
        onChangeCurrentTab,
        onChangePageIndex,
        onCompanyChange,
        onSFChange,
        onSortChange,
        pageIndex,
        secondaryFilterValues,
        selectCompany,
        sortDirection,
        sortField,
        query,
        onQueryChange,
    } = useContext(AssetGridContext);

    const [data, setData] = useState<AssetListDto[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [total, setTotal] = useState<number>(0);
    const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
    const [isSFOpen, setisSFOpen] = useState<boolean>(false);
    const [counters, setCounters] = useState<AssetTabCountersDto>(INITIAL_ASSET_COUNTERS);
    const assetCategory: AssetCategory = pathname.includes(PrivatePaths.ASSETS_RANGE) ? "ASSET_RANGE" : "ASSET";
    const assetService = assetCategory === "ASSET" ? AssetService : AssetRangeService;

    const fetch = async ({ assetSf, pi, selectedCompany, sortD, sortF, tab }: AssetGridModel = {}) => {
        setIsLoading(true);

        const pageIndexExtraparam = pi !== undefined ? pi : pageIndex - 1;
        const planId = urlParams.get("planId") || "-1";
        const companyIdFetch = selectedCompany || selectCompany.company.value;
        const sortDirectionFetch = sortD || sortDirection;
        const sortFieldFetch = sortF || sortField;
        const currentTabFetch = mapTabNumberToTabType(tab !== undefined ? tab : currentTab);
        const filterData = assetSf || { ...secondaryFilterValues, tab: currentTabFetch };

        const extraParams = fillExtraparams({
            companyId: companyIdFetch,
            fK_PlanAPPCC: planId,
            tab: currentTabFetch,
            filterData,
            assetCategory,
        });
        const params: PaginationParams = {
            pageSize: PaginationDefaults.PAGE_SIZE,
            pageIndex: pageIndexExtraparam,
            query: query,
            sortDirection: sortDirectionFetch === "default" ? INITIAL_ASSET_SORT_DIRECTION : sortDirectionFetch,
            sortField: sortDirection === "default" ? INITIAL_ASSET_SORT_FIELD : sortFieldFetch,
            extraParams,
        };

        const assetListPromise = assetService.GetList(params);
        const assetCountersPromise = assetService.GetCounters(params);

        Promise.all([assetListPromise, assetCountersPromise])
            .then(([assetListSr, assetCountersSr]) => {
                if (!assetListSr.status()) {
                    setIsLoading(false);
                    handleToast({ title: assetListSr.error, variant: "danger" });
                    return;
                }
                if (!assetCountersSr.status()) {
                    setIsLoading(false);
                    handleToast({ title: assetCountersSr.error, variant: "danger" });
                    return;
                }

                setData(assetListSr.data.list);
                setTotal(assetListSr.data.count);
                setCounters(assetCountersSr.data);
            })
            .finally(() => setIsLoading(false));
    };

    const handleNavigate = (path: string) => {
        const planId = urlParams.get("planId");
        if (planId) {
            navigate(`${path}?planId=${planId}`);
            return;
        }
        navigate(`${path}`);
    };

    const onChangeTab = (tabIndex: number) => {
        if (tabIndex === currentTab) return;
        onChangeCurrentTab(tabIndex);
        onChangePageIndex(1);
        onSortChange(INITIAL_ASSET_SORT_FIELD, INITIAL_ASSET_SORT_DIRECTION);
        setIsLoading(true);
        fetch({ pi: 0, sortF: INITIAL_ASSET_SORT_FIELD, sortD: INITIAL_ASSET_SORT_DIRECTION, tab: tabIndex });
    };

    const onQuerySubmit = () => {
        if (!query.length) {
            onQueryChange("");
            setShowSearchInput(false);
        }
        fetch({ pi: 0 });
        onChangePageIndex(1);
    };

    const handleExport = (params?: IDownLoadCsvReturn) => {
        const companyIdFetch = selectCompany.company.value || session?.workingCompany?.companyId;
        return assetService.GetDataExport({
            query: query,
            sortDirection: sortDirection === "default" ? INITIAL_ASSET_SORT_DIRECTION : sortDirection,
            sortField: sortDirection === "default" ? INITIAL_ASSET_SORT_FIELD : sortField,
            extraParams: `${companyIdFetch !== "-1" ? `companyId=${companyIdFetch}` : ""}IsDeleted=${currentTab === 1}`,
            ...params,
        });
    };

    const handleCleanFetch = () => {
        onQueryChange("");
        setShowSearchInput(false);
        fetch({ pi: 0 });
        onChangePageIndex(1);
    };

    const handlePlanChange = (value: string) => {
        urlParams.set("planId", value);
        onChangeUrlParams(urlParams, { replace: true });
        handleCleanFetch();
    };

    const handleSaveSf = (filterData: AssetGridSF) => {
        onChangePageIndex(1);
        onSFChange({ ...filterData });
        fetch({ pi: 0, assetSf: filterData, tab: currentTab });
        setisSFOpen(false);
    };

    const handleChangecompany = ({ label, value }: OptionModel) => {
        onCompanyChange({
            company: { label, value },
            isOpen: false,
        });
        onChangePageIndex(1);
        fetch({ pi: 0, selectedCompany: value });

        if (value !== "-1") changeWorkingCompanyByCompanyId(Number(value));
    };

    return {
        counters,
        currentTab,
        data,
        fetch,
        getPlans,
        handleExport,
        handleNavigate,
        handlePlanChange,
        handleSaveSf,
        isLoading,
        isSFOpen,
        onChangePageIndex,
        onChangeTab,
        onCompanyChange,
        onQueryChange,
        onQuerySubmit,
        onSortChange,
        pageIndex,
        planOptions,
        query,
        secondaryFilterValues,
        selectCompany,
        setisSFOpen,
        setShowSearchInput,
        showSearchInput,
        sortDirection,
        sortField,
        total,
        urlParams,
        handleChangecompany,
        assetCategory,
    };
};
