import { CloseCompanyModel } from "app/models/01-SEG/CloseCompany/CloseCompanyModel";

export const getTotalClossedDates = (closeCompanies: Pick<CloseCompanyModel, "closeDate" | "openDate">[] | null) => {
    return closeCompanies?.filter(({ openDate }) => {
        if (!openDate) return false;
        const openDateObj = new Date(openDate);
        const today = new Date();
        openDateObj.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        return openDateObj >= today;
    });
};
