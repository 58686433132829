import { FC } from "react";
import { BarDataset } from "../types";
import { SupervisorAnalistDarkColor } from "app/shared/types/SupervisorColorTypes";
import { multiplyAndFormat } from "app/helpers/Utilities/number/multiplyAndFormat";

type BarChartCustomProps = {
    datasets: BarDataset[];
    labels: string[];
};

export const BarChartCustom: FC<BarChartCustomProps> = ({ datasets, labels }) => {
    const lengthValues = datasets[0].data.length;
    const findMaxValue = (data: BarDataset[]) => {
        return data.reduce((max, current) => {
            const currentMax = Math.max(...current.data);
            return Math.max(max, currentMax);
        }, 0);
    };

    const max = findMaxValue(datasets);

    const yaxis = (max: number) => {
        const step = max / 4;
        if (max === 1) return [1, 0];
        if (max === 2) return [2, 1, 0];
        if (max === 3) return [3, 2, 1, 0];
        if (max === 4) return [4, 3, 2, 1, 0];
        return Array.from({ length: 5 }, (_, index) => Math.round(index * step)).reverse();
    };

    const backgroundColor: Record<SupervisorAnalistDarkColor, string> = {
        blue: "#eaf6fe",
        darkRed: "#ffd0db",
        green: "#e8feda",
        red: "#ffe8ed",
        yellow: "#fefbe8",
    };

    const borderColor: Record<SupervisorAnalistDarkColor, string> = {
        blue: "#27648d",
        darkRed: "#e23e63",
        green: "#229500",
        red: "#e23e63",
        yellow: "#ca8504",
    };

    return (
        <>
            <div className="barchart">
                <div className="barchart__yaxis">
                    {yaxis(max).map((x, idx) => (
                        <div key={idx}>{x}</div>
                    ))}
                </div>
                <div className="barchart__body">
                    <div className="barchart__body__content">
                        {Array.from({ length: lengthValues }).map((_, key) => (
                            <div key={key} className="barchart__body__content__date">
                                {datasets.map(({ data, color }, key2) => (
                                    <div
                                        key={key2}
                                        className="barchart__body__content__date__bar"
                                        style={{
                                            height: `${multiplyAndFormat(data[key] / max, 100)}%`,
                                            backgroundColor: backgroundColor[color],
                                            border: `1px solid ${borderColor[color]}`,
                                            width: "20px",
                                        }}
                                    ></div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className="barchart__xaxis">
                        {labels.map((label, key) => (
                            <div key={key} className="barchart__xaxis__item">
                                {label}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};
