import { ImageDragFile } from "app/components_v2/__draggableFiles/DragFile/types";
import { compressImage } from "./compress/compressImage";
import { convertFileToBase64 } from "./converter/webPConverter";
import { ImageToSendTaskDetailDto } from "app/dtos/02-TAR/TaskInstance/ImageToSendTaskDetailDto";

export const convertAndCompressImages = async (photos: ImageDragFile[]): Promise<ImageToSendTaskDetailDto[]> => {
    try {
        const photosBase64: ImageToSendTaskDetailDto[] = [];
        for (const photo of photos) {
            if (photo.base64) {
                photosBase64.push({
                    id: photo.id,
                    imageBase64Content: photo.base64,
                    name: photo.fileName,
                    takenInRealTime: photo.takenInRealTime,
                });
                continue;
            }
            if (photo.file) {
                const attachment = await compressImage({ file: photo.file });
                const base64DataUrl = await convertFileToBase64(attachment);
                if (!base64DataUrl) continue;

                photosBase64.push({
                    id: photo.id,
                    imageBase64Content: base64DataUrl,
                    name: photo.fileName,
                    takenInRealTime: photo.takenInRealTime,
                });
            }
        }

        return photosBase64;
    } catch (error) {
        return [];
    }
};
