import { useState, useRef, useEffect } from "react";
import isiOS from "app/helpers/Mobile/isIos";
import { AUDIO_RECORDER_FORMAT_TYPE } from "../helpers/audioRecorderHelper";

type UseAudioRecorderParams = {
    disabled?: boolean;
    setAudioUrl?: (setAudioUrl: string | null) => void;
    audioBase64: string | null;
    setAudioBase64: (audioBase64: string | null) => void;
    isRecording: boolean;
    setIsRecording: (isRecording: boolean) => void;
};

export const useAudioRecorder = ({
    audioBase64,
    setAudioBase64,
    setAudioUrl,
    isRecording,
    setIsRecording,
}: UseAudioRecorderParams) => {
    const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    const audioChunks = useRef<Blob[]>([]);
    const mediaStreamRef = useRef<MediaStream | null>(null);

    const startRecording = async () => {
        if (isRecording) return;

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const context = new AudioContext();
            const recorder = new MediaRecorder(stream);

            mediaStreamRef.current = stream;

            recorder.ondataavailable = (event) => {
                audioChunks.current.push(event.data);
            };

            recorder.onstop = async () => {
                const audioBlob = new Blob(audioChunks.current, { type: AUDIO_RECORDER_FORMAT_TYPE });
                const reader = new FileReader();
                reader.readAsDataURL(audioBlob);
                reader.onloadend = () => {
                    const base64String = reader.result as string;
                    setAudioBase64(base64String);
                };
                setIsRecording(false);
                mediaStreamRef.current?.getTracks().forEach((track) => track.stop());
            };

            recorder.start();
            setMediaRecorder(recorder);
            setAudioContext(context);
            setIsRecording(true);
        } catch (error) {
            console.error("Error accessing the microphone", error);
        }
    };

    const stopRecording = () => {
        if (isRecording && mediaRecorder) {
            mediaRecorder.stop();
            audioContext?.close();
        }
    };

    const discardAudio = () => {
        setAudioBase64(null);
        setAudioUrl && setAudioUrl(null);
    };

    useEffect(() => {
        if (audioBase64 === null) audioChunks.current = [];
    }, [audioBase64]);

    return { startRecording, stopRecording, discardAudio };
};
