import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSession } from "app/hooks";
import { useUserType } from "app/hooks/useUserType";
import {
    faBuildings,
    faEnvelopes,
    faLock,
    faQrcode,
    faShieldKeyhole,
    faTicketAirline,
    faUsersRectangle,
} from "@fortawesome/pro-regular-svg-icons";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { Actions } from "app/state/actions";
import { TranslationTitles } from "app/translation/translationKeys";
import { PrivatePaths, SecScreen } from "app/shared/Constants";
import { IDropDownListItem } from "app/components_v2/Dropdown/types";
import { DropdownList } from "app/components_v2/Dropdown/DropdownList/DropdownList";
import { Popover } from "app/components_v2/Popover/Popover";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

type HeaderConfigDropdownProps = {
    onClickConfiguartionIcon: (path: string) => void;
    onClose: () => void;
    popoverTarget: Element | null;
};

export const HeaderConfigDropdown: FC<HeaderConfigDropdownProps> = ({
    onClickConfiguartionIcon,
    onClose,
    popoverTarget,
}) => {
    const { t } = useTranslation();
    const session = useSession();
    const dispatch = useDispatch();
    const { isAdmin, isAnalist, isAdminClient } = useUserType();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const isOnBoarding = session?.isOnBoarding;

    const onClickLicenses = () => {
        dispatch(Actions.updateIsOnBoarding(false));
        onClickConfiguartionIcon(`/${PrivatePaths.LICENSE_PAGE}`);
    };

    const items: IDropDownListItem[] = [
        {
            text: t(TranslationTitles.LICENSES_TITLE),
            icon: faLock,
            onClick: onClickLicenses,
            hidden: !isAdmin,
        },
        {
            text: t(TranslationTitles.HEADER_ON_BOARDING_TITLE),
            icon: faTicketAirline,
            onClick: () => onClickConfiguartionIcon(`/${PrivatePaths.ON_BOARDING}`),
            hidden: !isAnalist && !isAdmin && !isAdminClient,
        },
        {
            text: translateCustomerTypeKeys(TranslationTitles.COMPANIES_MAINTENANCE_TITLE, { firstReplace: "PLURAL" }),
            icon: faBuildings,
            onClick: () => onClickConfiguartionIcon(`/${PrivatePaths.CENTERS_MANAGEMENT}`),
            hidden: !hasPermissionToView(session, SecScreen.CENTERS_MANAGEMENT),
        },
        {
            text: t(TranslationTitles.ROLES_MAINTENANCE_TITLE),
            icon: faShieldKeyhole,
            onClick: () => onClickConfiguartionIcon(`/${PrivatePaths.ROLE_MANAGEMENT}`),
            hidden: !hasPermissionToView(session, SecScreen.ROLE_MANAGEMENT),
        },
        {
            text: t(TranslationTitles.USERS_TITLE),
            icon: faUsersRectangle,
            onClick: () => onClickConfiguartionIcon(`/${PrivatePaths.USER_MANAGEMENT}`),
            hidden: !hasPermissionToView(session, SecScreen.USER_MANAGEMENT),
        },
        {
            text: t(TranslationTitles.WORKING_POSITION_TITLE),
            icon: faQrcode,
            onClick: () => onClickConfiguartionIcon(`/${PrivatePaths.QR}`),
            hidden: !hasPermissionToView(session, SecScreen.QR) || isOnBoarding,
        },
        {
            text: t(TranslationTitles.EMAIL_DISTRIBUTION_LIST_TITLE),
            icon: faEnvelopes,
            onClick: () => onClickConfiguartionIcon(`/${PrivatePaths.DISTRIBUTION_LIST}`),
            hidden: !hasPermissionToView(session, SecScreen.DISTRIBUTION_LIST) || isOnBoarding,
        },
    ];

    return (
        <>
            <Popover
                portal={false}
                target={popoverTarget}
                onResize={onClose}
                onScroll={onClose}
                position={session?.isOnBoarding ? "bottomRight" : "bottomCenter"}
            >
                <DropdownList items={items} />
            </Popover>
        </>
    );
};
