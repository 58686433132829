import { AssetModel2 } from "app/models/05-QUA/AssetModels/AssetModel2";
import { OptionsSearch } from "app/models/FormComponentsModel";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import AssetService from "app/services/05-QUA/AssetService";
import { ASSET_PAGES } from "app/shared/AssetConstants";
import { TaskCaregoryTypes } from "app/shared/types/TaskCategoryTypes";
import { useState } from "react";

type DynamicFieldsError = {
    parsedError: string;
    isErrored: boolean;
};

export const useDynamicFields = () => {
    const [dynamicFieldsOptions, setDynamicFieldsOptions] = useState<OptionsSearch[]>([]);
    const [isLoadingDynamicFields, setIsLoadingDynamicFields] = useState<boolean>(false);
    const [dynamicFieldAssetOptions, setDynamicFieldAssetOptions] = useState<AssetModel2[]>([]);

    const getDynamicFields = async ({
        taskCategory,
    }: {
        taskCategory: TaskCaregoryTypes;
    }): Promise<DynamicFieldsError> => {
        setIsLoadingDynamicFields(true);
        const { data, status, getParsedError } = await TarSelectorService.GetDynamicFields({
            extraParams: `TaskCategory=${taskCategory}`,
        });
        if (!status()) {
            setDynamicFieldsOptions([]);
            return {
                isErrored: true,
                parsedError: getParsedError(),
            };
        }

        setDynamicFieldsOptions(data.map(({ label }) => ({ label, value: label })));
        return {
            isErrored: false,
            parsedError: "",
        };
    };

    const getDynamicFieldsAssets = async () => {
        const extraParams = new URLSearchParams();
        extraParams.append("AssetCategory", ASSET_PAGES.ASSET_RANGE);
        extraParams.append("IsDeleted", "false");
        const assetsSr = await AssetService.GetFullList({
            extraParams: extraParams.toString(),
        });
        const assetOptions = assetsSr.data.list;
        setDynamicFieldAssetOptions(assetOptions);
    };

    return {
        dynamicFieldsOptions,
        isLoadingDynamicFields,
        getDynamicFields,
        dynamicFieldAssetOptions,
        getDynamicFieldsAssets,
    };
};
