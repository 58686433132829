import { SecondaryFilter } from "app/components_v2/SecondaryFilter/SecondaryFilter";
import { FC, useEffect, useState } from "react";
import { AlertFilterModel } from "./types";
import Spinner from "app/components_v2/Spinner/Spinner";
import { isEqual } from "lodash";
import { AlertTabsModel } from "app/pages/02-TAR/11-Alerts/types";
import { useAlertFilter } from "./hooks/useAlertFilter";
import { DateRangePicker, SelectOptions } from "app/components_v2/__inputs";
import { useTranslation } from "react-i18next";
import {
    TranslationKeys,
    TranslationCommon,
    AlertModalTranslations,
    AlertTranslations,
} from "app/translation/translationKeys";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

type AlertFilterProps = {
    filterData: AlertFilterModel;
    initialFilterData: AlertFilterModel;
    isOpen: boolean;
    currentTab: AlertTabsModel;
    onSave: (values: AlertFilterModel) => void;
    onCancel?: () => void;
};

export const AlertFilter: FC<AlertFilterProps> = ({
    isOpen,
    onSave,
    onCancel,
    filterData,
    initialFilterData,
    currentTab,
}) => {
    const { t } = useTranslation();
    const { translateCompany } = useCustomerTypeTranslation();
    const {
        getUsers,
        usersOptions,
        isUserLoading,
        departmentsOptions,
        getDepartments,
        isDepartmentLoading,
        companiesOptions,
        getCompanies,
        isCompanyLoading,
        getQrs,
        isQrLoading,
        qrOptions,
    } = useAlertFilter();

    const [internalFilterData, setInternalFilterData] = useState<AlertFilterModel>(filterData);

    const handleInputChange = (values: Partial<AlertFilterModel>) =>
        setInternalFilterData((prev) => ({ ...prev, ...values }));

    const handleLeaveFilter = () => {
        handleInputChange(filterData || {});
        onCancel && onCancel();
    };

    const loadSelectors = (companyId: string) => {
        if (currentTab === "qr") {
            getQrs(companyId);
            return;
        }

        getUsers(companyId);
        getDepartments(companyId);
    };

    const onReset = () => {
        handleInputChange(initialFilterData || {});
        loadSelectors(initialFilterData.companyId);
    };

    const onChangeCompanyId = (companyId: string) => {
        handleInputChange({ companyId: companyId.toString(), userId: "-1", departmentId: "-1", qrId: "-1" });

        loadSelectors(companyId);
    };

    useEffect(() => {
        if (!isOpen) return;
        const { companyId } = internalFilterData;

        getCompanies();
        if (currentTab === "qr") {
            getQrs(companyId);
            return;
        }

        getUsers(companyId);
        getDepartments(companyId);
    }, [isOpen]);

    return (
        <SecondaryFilter
            isOpen={isOpen}
            onClickOutside={handleLeaveFilter}
            onCancel={handleLeaveFilter}
            onReset={onReset}
            onSave={() => onSave && onSave(internalFilterData)}
            showResetFilter={!isEqual(initialFilterData, internalFilterData)}
        >
            {isUserLoading || isDepartmentLoading || isCompanyLoading ? (
                <Spinner />
            ) : (
                <>
                    {currentTab === "received" && (
                        <SelectOptions
                            isMulti={false}
                            onChange={({ value }) => handleInputChange({ userId: value })}
                            options={usersOptions}
                            label={t(TranslationKeys.USER)}
                            selectedValue={internalFilterData.userId !== "-1" ? internalFilterData.userId : undefined}
                            placeholder={t(TranslationCommon.SELECT_OPTION)}
                        />
                    )}
                    {currentTab !== "qr" && (
                        <>
                            <SelectOptions
                                isMulti={false}
                                onChange={({ value }) => handleInputChange({ departmentId: value })}
                                options={departmentsOptions}
                                label={t(TranslationKeys.TEAMS)}
                                selectedValue={
                                    internalFilterData.departmentId !== "-1"
                                        ? internalFilterData.departmentId
                                        : undefined
                                }
                                placeholder={t(TranslationCommon.SELECT_OPTION)}
                            />
                        </>
                    )}
                    <SelectOptions
                        isMulti={false}
                        onChange={({ value }) => onChangeCompanyId(value)}
                        options={companiesOptions}
                        label={translateCompany("PLURAL")}
                        selectedValue={internalFilterData.companyId !== "-1" ? internalFilterData.companyId : undefined}
                        placeholder={t(TranslationCommon.SELECT_OPTION)}
                    />
                    <SelectOptions
                        isMulti={false}
                        onChange={({ value }) => handleInputChange({ qrId: value })}
                        options={qrOptions}
                        label={t(AlertModalTranslations.QR_LABEL)}
                        selectedValue={internalFilterData.qrId !== "-1" ? internalFilterData.qrId : undefined}
                        placeholder={t(TranslationCommon.SELECT_OPTION)}
                        isLoading={isQrLoading}
                    />
                    {currentTab === "sended" && (
                        <DateRangePicker
                            onChange={([seenStartDate, seenEndDate]) =>
                                handleInputChange({ seenStartDate, seenEndDate })
                            }
                            startDate={internalFilterData.seenStartDate}
                            endDate={internalFilterData.seenEndDate}
                            showMoreOptions
                            label={t(AlertTranslations.ALERT_SEEN_DATE_LABEL)}
                        />
                    )}
                    <DateRangePicker
                        onChange={([receivedStartDate, receivedEndDate]) =>
                            handleInputChange({ receivedStartDate, receivedEndDate })
                        }
                        startDate={internalFilterData.receivedStartDate}
                        endDate={internalFilterData.receivedEndDate}
                        showMoreOptions
                        label={t(AlertTranslations.ALERT_RECEIVED_DATE_LABEL)}
                    />
                </>
            )}
        </SecondaryFilter>
    );
};
