import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { CompanyOptionModel } from "app/dtos/01-SEG/Company/CompanyOptionModel";
import { SelectOptions } from "app/components_v2/__inputs/SelectOptions/SelectOptions";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { getInitials } from "app/helpers/Avatar/getInitials";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

type SelectCompanyProps = {
    changeCompanySelected: (value: string, text: string) => void;
    value: string;
    options: CompanyOptionModel[];
    customError?: string;
    disabled?: boolean;
};

export const SelectCompany: FC<SelectCompanyProps> = ({
    changeCompanySelected,
    options,
    value,
    customError,
    disabled,
}) => {
    const { t } = useTranslation();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const [selectorOptions, setSelectorOptions] = useState<OptionModel[]>([]);

    useEffect(() => {
        setSelectorOptions((prev) => [
            ...prev,
            ...options.map((data) => ({
                ...data,
                initials: getInitials(data.label),
            })),
        ]);
    }, []);

    return (
        <SelectOptions
            label={translateCustomerTypeKeys(TranslationKeys.COMPANY_BELONGS, {
                firstReplace: "SINGULAR",
                isGenderSensitive: true,
            })}
            options={selectorOptions}
            isMulti={false}
            onChange={(newValue) => changeCompanySelected(newValue.value, newValue.label)}
            selectedValue={value !== "-1" ? value : undefined}
            htmlFor="SelectCompany"
            disabled={disabled}
            placeholder={t(TranslationCommon.SELECT_OPTION)}
            errorMessage={customError}
        />
    );
};
