import { useToast } from "app/hooks/Toast/useToast";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { ActionType } from "app/models/FormComponentsModel";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import DepartmentColorService from "app/services/01-SEG/DepartmentNameService";
import { DepartmentNameModalProps } from "../DepartmentNameModal";
import { DepartmentNameModel } from "app/models/01-SEG/DepartmentName/DepartmentNameModel";

type UseDepartmentNameModal = Omit<DepartmentNameModalProps, "onCancel">;
const DEPARTMENT_NAME_MAX_LEGTH = 50;

export const useDepartmentNameModal = ({ onAddNew, onEdit, selectedDepartmentName }: UseDepartmentNameModal) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();

    const isSelectedDepartmentNameNotNull = selectedDepartmentName !== null;
    const action: ActionType = isSelectedDepartmentNameNotNull ? "edit" : "create";

    const [name, setName] = useState<string>(() =>
        action === "edit" && isSelectedDepartmentNameNotNull ? selectedDepartmentName.name : ""
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validate()) return;
        setIsLoading(true);
        if (action === "create") await handleAdd();
        if (action === "edit") await handleEdit();
        setIsLoading(false);
    };

    const handleAdd = async () => {
        const { data, getParsedError, status } = await DepartmentColorService.Save({
            customerInstanceId: 0,
            name,
            id: 0,
            isDeleted: false,
        });

        setIsLoading(false);

        if (!status()) {
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            return;
        }

        onAddNew(data);
    };

    const handleEdit = async () => {
        if (!selectedDepartmentName) return;

        const editedDepartmentColor: DepartmentNameModel = {
            ...selectedDepartmentName,
            name,
        };
        const { data, getParsedError, status } = await DepartmentColorService.Edit(editedDepartmentColor);

        setIsLoading(false);

        if (!status()) {
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        onEdit(data);
    };

    const validate = () => {
        if (name.length >= DEPARTMENT_NAME_MAX_LEGTH || !name.length) {
            setErrorMessage(t(TranslationKeys.INPUT_MAX_CHARACTERS).replace("{0}", `${DEPARTMENT_NAME_MAX_LEGTH}`));
            return false;
        }
        return true;
    };

    return { action, onNameChange: setName, isLoading, errorMessage, onSubmit: handleSubmit, name };
};
