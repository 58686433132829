import { SupervisorAnalistColors } from "app/shared/types/SupervisorColorTypes";
import { mergeClassNames } from "app/helpers/ClassNames/mergeClassNames";
import { AriaLabels } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { LegendItemMark } from "../LegendItemMark/LegendItemMark";

type LegendItemProps = {
    color: SupervisorAnalistColors;
    label: string;
    percentage?: number;
    onClick?: () => void;
};

export const LegendItem: FC<LegendItemProps> = ({ color, label, percentage, onClick }) => {
    const { t } = useTranslation();

    const legendItemClassname = mergeClassNames("legendItem", {
        [`legendItem--clickable`]: !!onClick,
        [`legendItem--clickable--${color}`]: !!onClick,
    });

    return (
        <div className={legendItemClassname} onClick={onClick} aria-label={t(AriaLabels.AL_LEGEND_ITEM)}>
            <div className="legendItem__info">
                <LegendItemMark color={color} rounded />
                <p className="legendItem__text">{label}</p>
            </div>
            {percentage != null && (
                <p className="legendItem__percentatge" aria-label={t(AriaLabels.AL_LEGEND_ITEM_PERCENTAGE)}>
                    {percentage}%
                </p>
            )}
        </div>
    );
};
