import { FC } from "react";
import { useSession } from "app/hooks";
import { faPen, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { PatchNotesTranslations } from "app/translation/translationKeys";
import { PatchNoteVersionModel } from "app/models/01-SEG/PatchNoteVersion/PatchNoteVersionModels";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { useTranslation } from "react-i18next";
import { getDayMonthAndTimeFormatted } from "app/helpers/Utilities/date/Date.utilities";

type MobilePatchNoteRowProps = {
    data: PatchNoteVersionModel;
    onClickEdit: () => void;
    onClickDelete: () => void;
};

export const MobilePatchNoteRow: FC<MobilePatchNoteRowProps> = ({ data, onClickEdit, onClickDelete }) => {
    const { t } = useTranslation();
    const session = useSession();
    const isCusomerInstanceNull = session?.user.customerInstanceId == null;

    const { name, title, publishDate, customerInstanceCount } = data;

    return (
        <div className="mobilePatchNoteRow">
            <div className="mobilePatchNoteRow__container">
                <div className="mobilePatchNoteRow__container__text">
                    <p className="mobilePatchNoteRow__container__text__title">{`${t(
                        PatchNotesTranslations.PATCH_NOTES_VERSION_LABEL
                    )}:`}</p>
                    <p>{name}</p>
                </div>
                <div className="mobilePatchNoteRow__container__text">
                    <p className="mobilePatchNoteRow__container__text__title">{`${t(
                        PatchNotesTranslations.PATCH_NOTES_PUBLISH_DATE_LABEL
                    )}:`}</p>
                    <p>{getDayMonthAndTimeFormatted(new Date(publishDate))}</p>
                </div>
                <div className="mobilePatchNoteRow__container__text">
                    <p className="mobilePatchNoteRow__container__text__title">{`${t(
                        PatchNotesTranslations.PATCH_NOTES_TITLE_LABEL
                    )}:`}</p>
                    <p>{title}</p>
                </div>

                <div className="mobilePatchNoteRow__container__text">
                    <p className="mobilePatchNoteRow__container__text__title">{`${t(
                        PatchNotesTranslations.PATCH_NOTES_CUSTOMERS_COUNT_LABEL
                    )}:`}</p>
                    <p>{customerInstanceCount}</p>
                </div>
            </div>
            <div className="mobilePatchNoteRow__actions">
                <CellIcons
                    icons={[
                        {
                            icon: faPen,
                            onClick: () => onClickEdit(),
                            hidden: !isCusomerInstanceNull,
                        },
                        {
                            icon: faTrash,
                            onClick: onClickDelete,
                            hidden: !isCusomerInstanceNull,
                        },
                    ]}
                />
            </div>
        </div>
    );
};
