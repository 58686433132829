import { useState, FormEvent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import { cloneDeep, isEqual } from "lodash";
import { RoleService, InstanceService } from "app/services";
import { TranslationCommon, TranslationErrors, TranslationModals } from "app/translation/translationKeys";
import MapModelsHelper from "app/helpers/Translations/translationHelper";
import { MAX_INITIALS_LENGTH, RegexPatterns, SecScreen } from "app/shared/Constants";
import { Actions } from "app/state/actions";
import { CustomerInstanceModel } from "app/models/01-SEG/CustomerInstance/CustomerInstanceModel";
import { ComponentStatus } from "app/models/FormComponentsModel";
import { ModuleScreenPatents, SecModule, SecScreenPatent } from "app/models/ModuleModels";
import { customerTypes } from "app/components_v2/wizards/WizardLicense/constants/LicensesConstants";
import { INITIAL_WIZARD_LICENSE_ERRORS } from "app/components_v2/wizards/WizardLicense/StepOne/constants/constants";
import { WizardLicenseStepOneErrors } from "app/components_v2/wizards/WizardLicense/StepOne/models/models";

export const useLicenseTabs = (instanceId: string | undefined) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { handleToast } = useToast();

    const [status, setStatus] = useState<ComponentStatus>("loading");
    const [model, setModel] = useState<CustomerInstanceModel>();
    const [modelCopy, setModelCopy] = useState<CustomerInstanceModel>();
    const [normalized, setNormalized] = useState<ModuleScreenPatents[]>([]);
    const [modules, setModules] = useState<SecModule[]>([]);
    const [modulesCopy, setModulesCopy] = useState<SecModule[]>([]);
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [validations, setValidations] = useState<WizardLicenseStepOneErrors>(INITIAL_WIZARD_LICENSE_ERRORS);
    const [isLoadingConfirmModal, setIsLoadingConfirmModal] = useState<boolean>(false);

    const fetchData = async () => {
        if (!instanceId) return;

        const res = await InstanceService.GetInstance(instanceId);
        if (res.status()) {
            setModel(res.data);
            setModelCopy(res.data);
            setStatus("complete");
        } else {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            setStatus("errored");
        }
    };

    const fetchConfig = async () => {
        setIsLoading(true);
        const confSr = await RoleService.GetRoleConfig();

        if (confSr.status()) {
            const saraza = confSr.data.list.map((m) => {
                const tmp: ModuleScreenPatents = {
                    moduleCode: m.code,
                };

                const saraza2 = m.screens.map((s) => ({
                    screenCode: s.code,
                    patents: s.patents.map((p) => p.code),
                }));

                tmp.screens = saraza2.filter((x) => x.screenCode !== SecScreen.LICENSES);
                setIsLoading(false);
                return tmp;
            });
            setNormalized(saraza);
            const mappedModules = model?.modules.map((m) => {
                const tmp: SecModule = {
                    moduleCode: m.moduleCode,
                    screenPatents: MapModelsHelper.fromScreenPatentCodeToScreenPatents(m.screenPatents),
                };

                return tmp;
            });

            if (!mappedModules) return;
            setModules(cloneDeep(mappedModules));
            setModulesCopy(cloneDeep(mappedModules));
        } else {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
        }
        setIsLoading(false);
    };

    const handleChangeLicenseForm = (values: Partial<CustomerInstanceModel>) =>
        setModel((prev) => {
            if (!prev) return undefined;
            return { ...prev, ...values };
        });

    const handleChangeModules = (code: string, value: SecScreenPatent) => {
        setModules((prev) => {
            const tmp = [...prev];
            const found = tmp.find((m) => m.moduleCode === code);
            if (found) {
                const screen = found.screenPatents.find((s) => s.screenCode === value.screenCode);
                if (screen) screen.patents = value.patents;
                else found.screenPatents = [...found.screenPatents, value];
            } else tmp.push({ moduleCode: code, screenPatents: [value] });
            return tmp;
        });
    };

    const handleDelete = async () => {
        if (!instanceId) return;
        setIsLoadingConfirmModal(true);
        const sr = await InstanceService.DeleteInstance(instanceId);
        if (sr.status()) {
            dispatch(Actions.setCustomerInstance(undefined));
            navigate(-1);
        } else
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });

        setIsLoadingConfirmModal(false);
    };

    const showModal = () => setShowConfirmModal(true);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (currentTab == 0) {
            handleSubmitLicenseForm();
            return;
        }
        handleSubmitModulesForm();
    };

    const handleSubmitLicenseForm = async () => {
        if (!model) return;

        const isValidated = await validate();
        if (!isValidated) return;

        setIsSaving(true);
        const updateSr = await InstanceService.EditInstance(model.id, model);
        if (!updateSr.status()) {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            setIsSaving(false);
            return;
        }
        handleToast({
            title: t(TranslationModals.SUCCESS_EDIT),
            type: "alert",
            variant: "success",
        });
        setModel(cloneDeep(model));
        setModelCopy(cloneDeep(model));
        setIsSaving(false);
    };

    const handleErrorChange = (values: Partial<WizardLicenseStepOneErrors>) =>
        setValidations((prev) => ({ ...prev, ...values }));

    const areInitialsUsedFetch = async (initials: string) => {
        if (!model) return;

        const { data } = await InstanceService.AreInitialsUsed(initials, {
            extraParams: `customerInstanceId=${model.id}`,
        });
        return data;
    };

    const validate = async () => {
        let check = true;

        if (!model) return;

        const { name, contactName, email, phoneNumber, initials, customerType } = model;
        handleErrorChange(INITIAL_WIZARD_LICENSE_ERRORS);

        if (!name.length) {
            handleErrorChange({ errorInstanceName: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }

        if (!contactName.length) {
            handleErrorChange({ errorContactName: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }

        if (!new RegExp(RegexPatterns.mail).test(email)) {
            handleErrorChange({ errorContactEmail: t(TranslationErrors.RESTABLISH_PASSWORD_EMAIL_ERROR_MESSAGE) });
            check = false;
        }

        if (!new RegExp(RegexPatterns.phoneNumber).test(phoneNumber)) {
            handleErrorChange({ errorPhoneNumber: t(TranslationErrors.INVALID_PHONE_NUMBER) });
            check = false;
        }

        if (!initials.length) {
            handleErrorChange({ errorInitials: t(TranslationErrors.INITIALS_CANNOT_BE_EMPTY) });
            check = false;
        } else if (initials.length !== MAX_INITIALS_LENGTH) {
            handleErrorChange({ errorInitials: t(TranslationErrors.INITIALS_MAX_LENGTH) });
            check = false;
        } else if (!RegexPatterns.onlyTwoAlphabeticalCharacters.test(initials)) {
            handleErrorChange({ errorInitials: t(TranslationErrors.INITIALS_INVALID_FORMAT) });
            check = false;
        } else if (await areInitialsUsedFetch(initials)) {
            handleErrorChange({ errorInitials: t(TranslationErrors.INITIALS_ALREADY_USED) });
            check = false;
        }

        if (!customerType.length) {
            handleErrorChange({ errorCustomerType: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        } else if (!customerTypes.includes(customerType)) {
            handleErrorChange({ errorCustomerType: t(TranslationErrors.INVALID_CUSTOMER_TYPE) });
            check = false;
        }

        return check;
    };

    const handleSubmitModulesForm = async () => {
        if (!model) return;

        setIsSaving(true);

        model.modules = modules.map((m) => ({
            moduleCode: m.moduleCode,
            screenPatents: MapModelsHelper.fromScreenPatentsToScreenPatentCode(m.screenPatents),
        }));

        const updateSr = await InstanceService.EditInstance(model.id, model);
        if (!updateSr.status()) {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            setIsSaving(false);
            return;
        }
        handleToast({
            title: t(TranslationModals.SUCCESS_EDIT),
            type: "alert",
            variant: "success",
        });
        setModulesCopy(cloneDeep(modules));
        setIsSaving(false);
    };

    const handleCancel = () => {
        if (currentTab == 0) {
            setModel(cloneDeep(modelCopy));
            return;
        }
        setModules(cloneDeep(modulesCopy));
    };

    const isDisabled = () => {
        if (currentTab == 0) return isEqual(model, modelCopy);
        return isEqual(modules, modulesCopy);
    };

    useEffect(() => {
        if (!instanceId) {
            setStatus("complete");
            return;
        }
        fetchData();
    }, []);

    return {
        currentTab,
        fetchData,
        fetchConfig,
        handleCancel,
        handleChangeLicenseForm,
        handleChangeModules,
        handleDelete,
        handleSubmit,
        isDisabled,
        isLoading,
        isSaving,
        model,
        modelCopy,
        modules,
        normalized,
        setCurrentTab,
        setModel,
        setShowConfirmModal,
        showConfirmModal,
        showModal,
        status,
        validations,
        isLoadingConfirmModal,
    };
};
