import { FC } from "react";
import { AuditCommentType } from "./types";
import { AuditCommentHeader } from "./AuditCommentHeader/AuditCommentHeader";

type AuditCommentProps = {
    type: AuditCommentType;
    readonly?: boolean;
    description: string;
    avatarName: string;
    createdAt: string;
    onEdit: () => void;
    onDelete: () => void;
};

export const AuditComment: FC<AuditCommentProps> = ({ createdAt, description, ...headerProps }) => {
    return (
        <div className="auditComment">
            <AuditCommentHeader {...headerProps} />
            <p className="auditComment__description">{description}</p>
            <p className="auditComment__createdAt">{createdAt}</p>
        </div>
    );
};
