import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import FetchService from "../Fetch/FetchService";
import { PaginatedList } from "app/models/ServiceResponse/PaginatedResult";
import { ApiPaths } from "app/shared/Constants";
import { DocumentalFileListDto } from "app/dtos/07-DOC/documentalFile/DocumentalFileListDto";
import { CreateDocumentalFileDto } from "app/dtos/07-DOC/documentalFile/CreateDocumentalFileDto";

export default class DocumentalFileService {
    public static async GetData(folderId: number, paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedList<DocumentalFileListDto>>({
            url: `${ApiPaths.DOC_API}/DocumentalFile/documentalFolder/${folderId}`,
            paginationParams,
        });
    }

    public static async UploadFiles(folderId: number, files: CreateDocumentalFileDto[]) {
        return FetchService.post<PaginatedList<DocumentalFileListDto>>({
            url: `${ApiPaths.DOC_API}/DocumentalFile/${folderId}`,
            body: files,
        });
    }

    public static async Delete(id: number) {
        return FetchService.delete({
            url: `${ApiPaths.DOC_API}/DocumentalFile/${id}`,
        });
    }
}
