import { AssetModelFormValues } from "app/models/05-QUA/AssetModels";
import { AssetModel2 } from "app/models/05-QUA/AssetModels/AssetModel2";
import { v4 } from "uuid";

export const fillAsset = ({
    assetFields,
    assetId,
    description,
    fK_AssetType,
    fK_Company,
    name,
    isRelatedWithTasks,
    notWorking,
    sensorSerialNumber,
    battery,
    signalLevel,
    planAPPCCList,
}: AssetModel2): AssetModelFormValues => {
    return {
        name,
        fK_Company,
        fK_AssetType,
        description,
        assetId,
        assetFields: assetFields.map(
            ({ assetDynamicField, label, assetFieldRange, id, isHidden, assetFieldOption, isRequired }) => ({
                assetDynamicField,
                id: v4(),
                dbId: id,
                isDeleted: false,
                label,
                assetFieldRange: assetDynamicField === "NUMBER" ? assetFieldRange : null,
                isHidden,
                isRequired,
                assetFieldOption: assetFieldOption.map(({ fK_AssetField, id, isDeleted, name }) => ({
                    dbId: id,
                    fK_AssetField,
                    id: v4(),
                    isDeleted,
                    name,
                })),
            })
        ),
        isRelatedWithTasks,
        notWorking,
        allowSensorSerialNumber: !!sensorSerialNumber.length,
        sensorSerialNumber: sensorSerialNumber,
        battery,
        signalLevel,
        planAPPCCList: planAPPCCList.map(({ id }) => id),
    };
};
