import { FC, FormEvent, useEffect, useState } from "react";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { ActionType } from "app/models/FormComponentsModel";
import { DocumentalFolderModalErrorFormModel, DocumentalFolderModalFormModel } from "./types";
import {
    INITIAL_DOCUMENTAL_FOLDER_MODAL_INITIAL_ERROR_FORM_VALUES,
    INITIAL_DOCUMENTAL_FOLDER_MODAL_INITIAL_FORM_VALUES,
} from "./constants/DocumentalFolderModalConstants";
import DocumentalFolderService from "app/services/07-DOC/DocumentalFolderService";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import { useTranslation } from "react-i18next";
import { useSession } from "app/hooks";
import { GenericModalFooter } from "../base/GenericModal/GenericModalFooter/GenericModalFooter";
import { DocumentalFolderTranslations, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { Input, OptionsOutsideSelect } from "app/components_v2/__inputs";
import { getInitials } from "app/helpers/Avatar/getInitials";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { DocumentalFolderDto } from "app/dtos/07-DOC/documentalFolder/DocumentalFolderDto";
import { useToast } from "app/hooks/Toast/useToast";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import Spinner from "app/components_v2/Spinner/Spinner";

type DocumentalFolderModalProps = {
    type: ActionType;
    folderId?: number;
    onClose: () => void;
    onSubmit: (documnetalFolderDto: DocumentalFolderDto, type: ActionType) => Promise<void>;
};

const formId = "documentalFolderForm";

export const DocumentalFolderModal: FC<DocumentalFolderModalProps> = ({ type, folderId, onClose, onSubmit }) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const session = useSession();

    const [formValues, setFormValues] = useState<DocumentalFolderModalFormModel>(
        INITIAL_DOCUMENTAL_FOLDER_MODAL_INITIAL_FORM_VALUES
    );
    const [errorFormValues, setErrorFormValues] = useState<DocumentalFolderModalErrorFormModel>(
        INITIAL_DOCUMENTAL_FOLDER_MODAL_INITIAL_ERROR_FORM_VALUES
    );
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [companyOptions, setCompanyOptions] = useState<OptionModel[]>([]);
    const [departmentOptions, setDepartmentOptions] = useState<OptionModel[]>([]);
    const [userOptions, setUserOptions] = useState<OptionModel[]>([]);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const title: Record<ActionType, string> = {
        create: t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_MODAL_TITLE_CREATE),
        edit: t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_MODAL_TITLE_EDIT),
    };
    const confirmButton: Record<ActionType, string> = {
        create: t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_MODAL_CONFIRM_BUTTON_CREATE),
        edit: t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_MODAL_CONFIRM_BUTTON_EDIT),
    };

    const onChange = (values: Partial<DocumentalFolderModalFormModel>) => {
        setFormValues((prev) => ({ ...prev, ...values }));
    };
    const onChangerErrors = (values: Partial<DocumentalFolderModalErrorFormModel>) => {
        setErrorFormValues((prev) => ({ ...prev, ...values }));
    };

    const onChangeCompany = (values: OptionModel[]) => {
        setFormValues((prev) => ({ ...prev, companyIds: values }));
    };
    const onDeleteCompany = (companyId: string) => {
        setFormValues((prev) => ({
            ...prev,
            companyIds: prev.companyIds.filter((cp) => cp.value !== companyId),
        }));
    };
    const onChangeDepartment = (values: OptionModel[]) => {
        setFormValues((prev) => ({ ...prev, departmentIds: values }));
    };
    const onDeleteDepartment = (departmentId: string) => {
        setFormValues((prev) => ({
            ...prev,
            departmentIds: prev.departmentIds.filter((cp) => cp.value !== departmentId),
        }));
    };
    const onChangeUser = (values: OptionModel[]) => {
        setFormValues((prev) => ({ ...prev, userIds: values }));
    };
    const onDeleteUser = (userId: string) => {
        setFormValues((prev) => ({
            ...prev,
            userIds: prev.userIds.filter((cp) => cp.value !== userId),
        }));
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validate()) return;
        setIsSaving(true);
        const documentalFolderDto: DocumentalFolderDto = {
            id: folderId || 0,
            customerInstanceId: session?.user?.customerInstanceId || 0,
            name: formValues.name,
            documentalFolderCompanyRoles: formValues.companyIds.map(({ value }) => ({
                id: Number(value),
                patent: "VIEW",
            })),
            documentalFolderDepartmentRoles: formValues.departmentIds.map(({ value }) => ({
                id: Number(value),
                patent: "VIEW",
            })),
            documentalFolderUserRoles: formValues.userIds.map(({ value }) => ({
                id: Number(value),
                patent: "VIEW",
            })),
        };
        await onSubmit(documentalFolderDto, type);
        setIsSaving(false);
    };

    const validate = (): boolean => {
        let isValid = true;
        onChangerErrors(INITIAL_DOCUMENTAL_FOLDER_MODAL_INITIAL_ERROR_FORM_VALUES);
        if (!formValues.name) {
            onChangerErrors({ errorName: t(TranslationCommon.INPUT_NOT_EMPTY) });
            isValid = false;
        }
        if (formValues.name.length > 50) {
            onChangerErrors({ errorName: t(TranslationKeys.INPUT_MAX_CHARACTERS).replace("{0}", "50") });
            isValid = false;
        }

        const isSomeWordToLong = formValues.name.split(" ").some((word) => word.length > 20);

        if (isSomeWordToLong) {
            onChangerErrors({ errorName: t(TranslationKeys.INPUT_WORD_TOO_LONG) });
            isValid = false;
        }

        if (!formValues.companyIds.length && !formValues.departmentIds.length && !formValues.userIds.length) {
            onChangerErrors({ errorAssigned: t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_MODAL_ERROR_ASSIGNED) });
            isValid = false;
        }

        return isValid;
    };

    const getFolder = async (cpOptions: OptionModel[], depOptions: OptionModel[], uOptions: OptionModel[]) => {
        if (!folderId) {
            setIsLoading(false);
            return;
        }
        const { data, status, getParsedError } = await DocumentalFolderService.GetOne(folderId);

        setIsLoading(false);

        if (!status()) {
            handleToast({ title: t(getErrorMessage(getParsedError())), variant: "danger", type: "alert" });

            return;
        }

        setFormValues({
            name: data.name,
            companyIds: data.documentalFolderCompanyRoles
                .map<OptionModel | undefined>((cp) => {
                    const selectedCompany = cpOptions.find((c) => c.value === cp.id.toString());
                    if (!selectedCompany) return;

                    return {
                        value: cp.id.toString(),
                        label: selectedCompany.label,
                        initials: getInitials(selectedCompany.label),
                        avatarHexColor: selectedCompany.avatarHexColor,
                        profilePictureURL: selectedCompany.profilePictureURL,
                        subTitle: selectedCompany.subTitle,
                    };
                })
                .filter((cp) => cp != null) as OptionModel[],
            departmentIds: data.documentalFolderDepartmentRoles
                .map<OptionModel | undefined>((dep) => {
                    const selectedDepartment = depOptions.find((d) => d.value === dep.id.toString());
                    if (!selectedDepartment) return;

                    return {
                        value: dep.id.toString(),
                        label: selectedDepartment.label,
                        initials: selectedDepartment.initials,
                        avatarHexColor: selectedDepartment.avatarHexColor,
                        subTitle: selectedDepartment.subTitle,
                    };
                })
                .filter((dep) => dep != null) as OptionModel[],
            userIds: data.documentalFolderUserRoles
                .map<OptionModel | undefined>((user) => {
                    const selectedUser = uOptions.find((u) => u.value === user.id.toString());
                    if (!selectedUser) return;

                    return {
                        value: user.id.toString(),
                        label: selectedUser.label,
                        initials: selectedUser.initials,
                        avatarHexColor: selectedUser.avatarHexColor,
                        subTitle: selectedUser.subTitle,
                        profilePictureURL: selectedUser.profilePictureURL,
                    };
                })
                .filter((user) => user != null) as OptionModel[],
        });
    };

    const getOptions = async () => {
        setIsLoading(true);
        const userId = session?.user?.id;
        const { data: companyData, status: companyStatus } = await SegSelectorService.GetCompanyNamesWithIds({
            extraParams: `SupervisorId=${userId}`,
        });
        const { data: departmentData, status: departmentStatus } = await SegSelectorService.GetDepartmentNamesWithIds({
            extraParams: `SupervisorId=${userId}`,
        });
        const { data: userData, status: userStatus } = await SegSelectorService.GetUserNamesWithIds({
            extraParams: `SupervisorId=${userId}`,
        });

        if (!companyStatus() || !departmentStatus() || !userStatus()) {
            setIsLoading(false);
            return;
        }

        setCompanyOptions(companyData.map((cp) => ({ ...cp, initials: getInitials(cp.label) })));
        setDepartmentOptions(departmentData);
        setUserOptions(userData);

        await getFolder(companyData, departmentData, userData);
        setIsLoading(false);
    };

    useEffect(() => {
        getOptions();
    }, []);

    return (
        <GenericModal
            size="md-tall"
            header={{ title: title[type], variant: "appcc", onClose }}
            footer={
                <GenericModalFooter
                    confirmButton={{ text: confirmButton[type], form: formId }}
                    closeButton={{ text: t(TranslationCommon.CANCEL), onClick: onClose }}
                    loading={isLoading || isSaving}
                />
            }
        >
            {isLoading ? (
                <div className="documentalFolderModal__loading">
                    <Spinner />
                </div>
            ) : (
                <form id={formId} className="documentalFolderModal" onSubmit={handleSubmit}>
                    <Input
                        label={t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_MODAL_NAME)}
                        placeholder={t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_MODAL_NAME)}
                        value={formValues.name}
                        onChange={(value) => onChange({ name: value })}
                        errorMessage={errorFormValues.errorName}
                    />
                    <p className="documentalFolderModal__title">
                        {t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_MODAL_ACCESS_TITLE)}
                    </p>
                    <OptionsOutsideSelect
                        onChange={onChangeCompany}
                        onDeleteItem={onDeleteCompany}
                        options={companyOptions}
                        values={formValues.companyIds}
                        label={t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_MODAL_COMPANY)}
                    />
                    <OptionsOutsideSelect
                        onChange={onChangeDepartment}
                        onDeleteItem={onDeleteDepartment}
                        options={departmentOptions}
                        values={formValues.departmentIds}
                        tagOptions={{ size: "md", clamp: 2 }}
                        label={t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_MODAL_DEPARTMENT)}
                        avatarSize="sm"
                        avatarVariant="primary"
                    />
                    <OptionsOutsideSelect
                        onChange={onChangeUser}
                        onDeleteItem={onDeleteUser}
                        options={userOptions}
                        values={formValues.userIds}
                        label={t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_MODAL_USER)}
                    />
                    {errorFormValues.errorAssigned && <ErrorMessage errorMessage={errorFormValues.errorAssigned} />}
                </form>
            )}
        </GenericModal>
    );
};
