import { DocumentalFolderModalErrorFormModel, DocumentalFolderModalFormModel } from "../types";

export const INITIAL_DOCUMENTAL_FOLDER_MODAL_INITIAL_FORM_VALUES: DocumentalFolderModalFormModel = {
    companyIds: [],
    departmentIds: [],
    userIds: [],
    name: "",
};

export const INITIAL_DOCUMENTAL_FOLDER_MODAL_INITIAL_ERROR_FORM_VALUES: DocumentalFolderModalErrorFormModel = {
    errorAssigned: "",
    errorName: "",
};
