import { FC } from "react";
import { useSensorBattery } from "../../hooks/useSensorBattery";
import { useSensorSignal } from "../../hooks/useSensorSignal";
import { useTranslation } from "react-i18next";
import { AutomaticRecordTranslations } from "app/translation/translationKeys";
import { getDateFormatted } from "app/helpers/Utilities/date/Date.utilities";
import { SensorEntriesListModel } from "app/models/04-SEN/SensorEntriesModels/SensorEntriesListModel";
import { Badge } from "app/components_v2/Badge/Badge";

type MobileAutomaticRecordProps = {
    data: SensorEntriesListModel;
};

export const MobileAutomaticRecord: FC<MobileAutomaticRecordProps> = ({ data }) => {
    const { t } = useTranslation();
    const { batteryBadgeTitle, batteryBadgeColor, batteryBadgeIcon } = useSensorBattery();
    const { signalBadgeTitle, signalBadgeColor, signalBadgeIcon } = useSensorSignal();

    const { assetName, sensorSerialNumber, temp, battery, signalLevel, dateTimeEntry } = data;

    return (
        <div className="mobileAutomaticRecord">
            <div className="mobileAutomaticRecord__titles">
                <div className="mobileAutomaticRecord__titles__title">
                    <p className="mobileAutomaticRecord__titles__subtitle">
                        {`${t(AutomaticRecordTranslations.AUTOMATIC_RECORD_ASSET_LABEL)}: `}
                    </p>
                    <p>{assetName}</p>
                </div>
                <div className="mobileAutomaticRecord__titles__title">
                    <p className="mobileAutomaticRecord__titles__subtitle">
                        {`${t(AutomaticRecordTranslations.SENSOR_SERIAL_NUMBER_SMALL_LABEL)}: `}
                    </p>
                    <p>{sensorSerialNumber}</p>
                </div>
                <div className="mobileAutomaticRecord__titles__title">
                    <p className="mobileAutomaticRecord__titles__subtitle">
                        {`${t(AutomaticRecordTranslations.DATE_TIME_ENTRY_LABEL)}: `}
                    </p>
                    <p>{getDateFormatted(new Date(dateTimeEntry))}</p>
                </div>
                <div className="mobileAutomaticRecord__titles__title">
                    <p className="mobileAutomaticRecord__titles__subtitle">
                        {`${t(AutomaticRecordTranslations.TEMP_LABEL)}: `}
                    </p>
                    <p>{`${temp} ºC`}</p>
                </div>
            </div>
            <div className="mobileAutomaticRecord__data">
                <Badge
                    size="md"
                    title={batteryBadgeTitle(battery, true)}
                    variant={batteryBadgeColor(battery)}
                    icon={batteryBadgeIcon(battery)}
                    iconPosition="right"
                />
                <Badge
                    size="md"
                    title={signalBadgeTitle(signalLevel, true)}
                    variant={signalBadgeColor(signalLevel)}
                    icon={signalBadgeIcon(signalLevel)}
                    iconPosition="right"
                />
            </div>
        </div>
    );
};
