import { AvatarName } from "app/components_v2/Avatar/AvatarName/AvatarName";
import { GetUserTypeDescription } from "app/helpers/User/userType/getUserTypeDescription";
import { UserType } from "app/shared/types/UserTypes";
import { AriaLabels } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export type RankingPillInfoAvatarNameProps = {
    name: string;
    jobDescription?: string;
    showUserType?: boolean;
    userType?: UserType;
};

export const RankingPillInfoAvatarName: FC<RankingPillInfoAvatarNameProps> = ({
    name,
    userType,
    jobDescription,
    showUserType,
}) => {
    const { t } = useTranslation();
    return (
        <div className="rankingPillInfo__avatar">
            <AvatarName name={name} capitalize clamp variant="primary" aria-label="avatar name" />

            {(jobDescription || (showUserType && userType)) && (
                <p
                    className="rankingPillInfo__avatar__userType text_clamp text_clamp-1"
                    aria-label={t(AriaLabels.AL_RANKING_PILL_INFO_AVATAR_DESCRIPTION)}
                >
                    {jobDescription || (userType && GetUserTypeDescription(userType, t))}
                </p>
            )}
        </div>
    );
};
