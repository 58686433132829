import { UserType } from "app/shared/types/UserTypes";

type IProps = {
    userId?: number;
    companyId?: number;
    userType?: UserType;
};

export const fillIssueExtraParams = ({ companyId }: IProps) => {
    const extraParams = new URLSearchParams();

    if (!!companyId) extraParams.append("companyId", companyId.toString() || "");

    return extraParams.toString();
};
