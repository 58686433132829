import { faListCheck, faMessageExclamation } from "@fortawesome/pro-regular-svg-icons";
import { Badge } from "app/components_v2/Badge/Badge";
import { MultiProgressBar } from "app/components_v2/MultiProgressBar/MultiProgressBar";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { RowPositionModel } from "app/components_v2/Table/types";
import { AuditGroupCheckListInstanceModel } from "app/models/05-QUA/AuditInstanceModels/AuditGroupCheckListInstanceModel";
import { getAuditGroupChecklistProgressBarValues } from "app/pages/05-QUA/Audit/helpers/getAuditGroupChecklistProgressBarValues";
import { AuditGroupCheckListInstanceTranslations, TranslationKeys } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type AuditGroupMobileProps = {
    row: AuditGroupCheckListInstanceModel;
    rowPosition: RowPositionModel;
    onClick: () => void;
};

export const AuditGroupMobile: FC<AuditGroupMobileProps> = ({ onClick, row, rowPosition }) => {
    const { t } = useTranslation();

    const { name, status, auditGroupCheckListItemInstances } = row;
    const isCompleted = status === "COMPLETED";
    const isAllItemsNA = auditGroupCheckListItemInstances.every(({ value }) => value === "NA");
    const multiProgressBarItems = getAuditGroupChecklistProgressBarValues(auditGroupCheckListItemInstances);

    return (
        <div className={`mobileAuditGroupRow--${rowPosition}`}>
            <div className="mobileAuditGroupRow__header">
                <div className="mobileAuditGroupRow__header__container">
                    <p className="mobileAuditGroupRow__name">{name}</p>
                    {/* <div className="mobileAuditGroupRow__badgeComment">
                        <Badge variant="teal" title="1" icon={faMessageExclamation} iconPosition="left" />
                    </div> */}
                </div>
                <CellIcons
                    icons={[
                        {
                            icon: faListCheck,
                            onClick: onClick,
                        },
                    ]}
                />
            </div>
            <div className="mobileAuditGroupRow__footer">
                <div className="mobileAuditGroupRow__progressbar">
                    <MultiProgressBar items={multiProgressBarItems.filter(({ value }) => value >= 1)} />
                </div>
                <Badge
                    title={
                        isCompleted
                            ? isAllItemsNA
                                ? t(
                                      AuditGroupCheckListInstanceTranslations.AUDIT_GROUP_CHECKLIST_INSTANCE_NOT_APPLICABLE
                                  )
                                : t(TranslationKeys.AUDIT_GROUP_CHECKLIST_STATUS_COMPLETED)
                            : t(TranslationKeys.AUDIT_GROUP_CHECKLIST_STATUS_IN_PROGRESS)
                    }
                    variant={isCompleted ? (isAllItemsNA ? "grey" : "green") : "blue"}
                />
            </div>
        </div>
    );
};
