import { isValidDate } from "app/helpers/Utilities/date/Date.utilities";

export const taskHistoryUrlParamsValidator = (searchParams: URLSearchParams) => {
    const taskHistorySearchParams = new URLSearchParams();

    const sporadicTask = searchParams.get("SporadicTasks");
    const inTime = searchParams.get("InTime");
    const outOfTime = searchParams.get("OutOfTime");
    const companyId = searchParams.get("CompanyId");
    const deadlineDate = searchParams.get("DeadlineDate");
    const deadlineDate1 = searchParams.get("DeadlineDate1");

    if (inTime !== null && (inTime === "true" || inTime === "false")) taskHistorySearchParams.append("InTime", inTime);
    if (outOfTime !== null && (outOfTime === "true" || outOfTime === "false"))
        taskHistorySearchParams.append("OutOfTime", outOfTime);
    if (sporadicTask !== null && (sporadicTask === "true" || sporadicTask === "false"))
        taskHistorySearchParams.append("SporadicTasks", sporadicTask);
    if (companyId !== null && Number(companyId) !== 0 && !isNaN(Number(companyId)))
        localStorage.setItem("companyId", companyId);
    if (deadlineDate !== null && isValidDate(deadlineDate))
        taskHistorySearchParams.append("DeadlineDate", deadlineDate);
    if (deadlineDate1 !== null && isValidDate(deadlineDate1))
        taskHistorySearchParams.append("DeadlineDate1", deadlineDate1);

    return taskHistorySearchParams;
};
