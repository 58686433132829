import { FormEvent, useEffect, useState, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { NotificationDistributionListTranslations, TranslationErrors } from "app/translation/translationKeys";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import { getInitials } from "app/helpers/Avatar/getInitials";
import { validateEmail } from "app/helpers/__validates/validateCompanyForm";
import {
    NotificationDistributionListErrors,
    NotificationDistributionListFormValues,
    SelectedNotificationDistributionList,
} from "../../../models/NotificationDistributionListFormValues";
import { DistributionListFilterType } from "../../../models/NotificationDistributionListGridModels";
import {
    FunctionalityTypes,
    INITIAL_NOTIFICATION_DISTRIBUTION_LIST_ERRORS,
} from "../../../constants/NotificationDistributionListConstants";

type UseEditNotificationDistributionList = {
    notificationDistributionList: SelectedNotificationDistributionList;
    onSubmit: (values: NotificationDistributionListFormValues) => Promise<void>;
    onClose: () => void;
};

export const useEditNotificationDistributionList = ({
    notificationDistributionList,
    onSubmit,
    onClose,
}: UseEditNotificationDistributionList) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const session = useSession();

    const {
        fK_Company,
        fK_DistributionList,
        userList,
        emails,
        functionality,
        companyName,
        notifyWorkers,
        notifySupervisors,
        notifyAnalists,
        notifyAdminClients,
    } = notificationDistributionList;

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [userOptions, setUserOptions] = useState<OptionModel[]>([]);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [addAllUsersModalOpen, setAddAllUsersModalOpen] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<NotificationDistributionListFormValues>({
        fK_DistributionList,
        fK_Company,
        userList,
        emails,
        companyList: [],
        notifyUsers: !!userList.length || notifyWorkers || notifySupervisors || notifyAnalists || notifyAdminClients,
        notifyEmails: !!emails.length,
        notifyWorkers,
        notifySupervisors,
        notifyAnalists,
        notifyAdminClients,
    });
    const [email, setEmail] = useState<string>("");
    const [notificationDistributionListErrors, setNotificationDistributionListErrors] =
        useState<NotificationDistributionListErrors>(INITIAL_NOTIFICATION_DISTRIBUTION_LIST_ERRORS);

    const {
        userList: selectedUsers,
        emails: selectedEmails,
        notifyUsers,
        notifyEmails,
        notifyWorkers: isSelectedNotifyWorkers,
        notifySupervisors: isSelectedNotifySupervisors,
        notifyAnalists: isSelectedNotifyAnalists,
        notifyAdminClients: isSelectedNotifyAdminClients,
    } = formValues;

    const title = `${t(functionality)} ${
        functionality !== NotificationDistributionListTranslations.DISTRIBUTION_LIST_PATCH_NOTES
            ? ` (${companyName})`
            : ""
    }`;

    const distributionListFilter: Record<number, DistributionListFilterType> = {
        1: "SporadicTasksPermission",
        2: "AuditReportPermission",
    };

    const handleInputChange = (values: Partial<NotificationDistributionListFormValues>) => {
        setNotificationDistributionListErrors(INITIAL_NOTIFICATION_DISTRIBUTION_LIST_ERRORS);
        setFormValues((prev) => ({ ...prev, ...values }));
    };

    const handleErrorChange = (values: Partial<NotificationDistributionListErrors>) =>
        setNotificationDistributionListErrors((prev) => ({ ...prev, ...values }));

    const handleDeleteUser = (id: string) => {
        const user = selectedUsers.find(({ value }) => value === id);
        if (!user) return;

        handleInputChange({ userList: selectedUsers.filter(({ value }) => value !== id) });
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!validate()) return;
        setIsSaving(true);
        await onSubmit({
            ...formValues,
            userList: notifyUsers ? selectedUsers : [],
            emails: notifyEmails ? selectedEmails : [],
            notifyWorkers: notifyUsers && isSelectedNotifyWorkers,
            notifySupervisors: notifyUsers && isSelectedNotifySupervisors,
            notifyAnalists: notifyUsers && isSelectedNotifyAnalists,
            notifyAdminClients: notifyUsers && isSelectedNotifyAdminClients,
        });
        setIsSaving(false);
    };

    const validate = () => {
        let isValid = true;

        if (!notifyUsers && !notifyEmails) {
            handleErrorChange({
                errorNotSelected: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION),
            });
            isValid = false;
        }
        const isNotAnyUserNotified =
            !selectedUsers.length &&
            !isSelectedNotifyWorkers &&
            !isSelectedNotifySupervisors &&
            !isSelectedNotifyAnalists &&
            !isSelectedNotifyAdminClients;
        if (notifyUsers && isNotAnyUserNotified) {
            handleErrorChange({ errorUserList: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
            isValid = false;
        }
        if (notifyEmails && !selectedEmails.length) {
            handleErrorChange({ errorEmails: t(TranslationErrors.ADD_AT_LEAST_ONE) });
            isValid = false;
        }

        return isValid;
    };

    const getUsers = async () => {
        setIsLoading(true);
        const supervisorParams = new URLSearchParams();
        if (session?.user.id) supervisorParams.append("userId", session?.user.id.toString());
        supervisorParams.append("includeMyself", "true");
        if (!!fK_Company.length) supervisorParams.append("CompaniesId", fK_Company);
        const key = !!fK_DistributionList.length ? Number(fK_DistributionList) : 0;

        if (FunctionalityTypes[key] === NotificationDistributionListTranslations.DISTRIBUTION_LIST_ISSUE_DYNAMIC_FIELD)
            supervisorParams.append("onlyAnalists", "true");
        if (
            FunctionalityTypes[key] === NotificationDistributionListTranslations.DISTRIBUTION_LIST_SPORADIC_TASK ||
            FunctionalityTypes[key] === NotificationDistributionListTranslations.DISTRIBUTION_LIST_AUDIT_REPORT
        )
            supervisorParams.append(distributionListFilter[key], "true");

        const { data, status, getParsedError } = await SegSelectorService.GetDistributionListUsers({
            extraParams: supervisorParams.toString(),
        });

        if (!status()) {
            handleToast({ title: getErrorMessage(getParsedError()), type: "notification", variant: "danger" });
            onClose();
            setIsLoading(false);
            return;
        }

        setUserOptions(data.map((d) => ({ ...d, initials: getInitials(d.label) })));
        setIsLoading(false);
    };

    const onConfirmAddAllUsers = () => {
        handleInputChange({
            userList: [
                ...selectedUsers,
                ...userOptions.filter((uo) => !selectedUsers.some((su) => uo.value === su.value)),
            ],
        });
        setAddAllUsersModalOpen(false);
    };

    const handleAddEmail = (e?: KeyboardEvent<HTMLInputElement>) => {
        e?.stopPropagation();
        e?.preventDefault();
        if (emails.includes(email)) {
            handleToast({ title: t(TranslationErrors.EMAIL_ALREADY_ADDED), variant: "danger", type: "alert" });
            return;
        }
        if (!validateEmail(email)) {
            handleErrorChange({ errorEmails: t(TranslationErrors.EMAIL_IS_INVALID) });
            return;
        }
        handleInputChange({ emails: [...selectedEmails, email] });
        setEmail("");
    };

    const handleDeleteEmail = (index: number) =>
        handleInputChange({ emails: selectedEmails.filter((_, i) => i !== index) });

    useEffect(() => {
        getUsers();
    }, []);

    return {
        addAllUsersModalOpen,
        email,
        handleAddEmail,
        handleDeleteEmail,
        handleDeleteUser,
        handleInputChange,
        handleSubmit,
        isLoading,
        isSaving,
        onConfirmAddAllUsers,
        setAddAllUsersModalOpen,
        setEmail,
        title,
        userOptions,
        formValues,
        notificationDistributionListErrors,
    };
};
