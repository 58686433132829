import ButtonGroup from "app/components_v2/__buttons/Buttongroup/ButtonGroup";
import { SelectOptions } from "app/components_v2/__inputs";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { DepartmentWithSubsModel, SubdepartmentUsersModel } from "app/dtos/01-SEG/DepartmentDto";
import { useToast } from "app/hooks/Toast/useToast";
import { DepartmentModel } from "app/models/01-SEG/Department/DepartmentModel";
import { DepartmentService } from "app/services";
import {
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import { FC, FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DepartmentCheckBox } from "./components/DepartmentCheckBox/DepartmentCheckBox";
import { CheckedDeps, CompanyWizardSteps, yesNoItems } from "./types";
import { ButtonGroupItem } from "app/components_v2/__buttons/Buttongroup/types";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import { TOTAL_ALLOWED_DEPARTMENTS_PER_COMPANY } from "app/shared/Constants";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { OptionWithAvatar } from "app/components_v2/__inputs/OptionWithAvatar/OptionWithAvatar";
import { getInitials } from "app/helpers/Avatar/getInitials";

type ErrorFormValuesMode = {
    copyExistingCompanyError: string;
    companySelectedIdError: string;
    departmentErrorMessage: string;
};

const INITIAL_ERROR_FORM_VALUES: ErrorFormValuesMode = {
    companySelectedIdError: "",
    copyExistingCompanyError: "",
    departmentErrorMessage: "",
};

const Step2: FC<CompanyWizardSteps> = ({ data, onChangeData, next, onStepChange }) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const [companies, setCompanies] = useState<OptionModel[]>([]);
    const [departments, setDepartments] = useState<DepartmentModel[]>();

    const [errorFormValues, setErrorFormValues] = useState<ErrorFormValuesMode>(INITIAL_ERROR_FORM_VALUES);

    const { companySelectedId, copyExistingCompany, checkedDepsAndSubdeps, departments: selectedDepartments } = data;

    const handleErrorChange = (values: Partial<ErrorFormValuesMode>) => {
        setErrorFormValues((prev) => ({ ...prev, ...values }));
    };

    const getCompanies = async () => {
        const companySr = await SegSelectorService.GetCompanyNamesWithIds();
        if (!companySr.status) {
            handleToast({
                title: translateCustomerTypeKeys(TranslationModals.TOAST_ERROR_COMPANY, {
                    firstReplace: "PLURAL",
                    isGenderSensitive: true,
                }),
            });
            return;
        }

        setCompanies(
            companySr.data.map((data) => ({
                ...data,
                initials: getInitials(data.label),
            }))
        );
    };

    const getDepartments = async () => {
        const departmentSr = await DepartmentService.GetData({
            extraParams: `CompanyId=${data.companySelectedId.value}`,
        });
        if (!departmentSr.status()) {
            handleToast({
                title: t(TranslationModals.TOAST_ERROR_DEPARTMENT),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        setDepartments(departmentSr.data.list);
    };

    const onSelectYesNo = ({ value }: ButtonGroupItem) => {
        const prevValue = copyExistingCompany !== undefined ? (copyExistingCompany ? "YES" : "NO") : undefined;
        if (value === prevValue) return;

        const selectedValue = value === "YES";

        onChangeData({
            companySelectedId: { label: "", value: "" },
            copyExistingCompany: selectedValue,
            departments: [],
        });
        if (!selectedValue) onStepChange && onStepChange(4);
    };

    const onSelectCompany = ({ value, label }: OptionModel) => {
        if (companySelectedId.value === value) return;
        onChangeData({
            companySelectedId: { value, label },
            departments: [],
            checkedDepsAndSubdeps: [],
        });
    };

    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let error = false;
        setErrorFormValues(INITIAL_ERROR_FORM_VALUES);

        if (copyExistingCompany === false) {
            next();
            return;
        }
        if (copyExistingCompany === undefined) {
            handleErrorChange({
                copyExistingCompanyError: t(TranslationErrors.WIZARD_COMPANY_ERROR_COPY_EXISTING_COMPANY),
            });
            error = true;
        }
        if (!companySelectedId.value.length) {
            handleErrorChange({
                companySelectedIdError: translateCustomerTypeKeys(
                    TranslationErrors.WIZARD_COMPANY_ERROR_SELECTED_COMPANY,
                    { firstReplace: "SINGULAR", isGenderSensitive: true }
                ),
            });
            error = true;
        }

        if (companySelectedId && !selectedDepartments.length && departments && !!departments.length) {
            handleToast({
                title: t(TranslationErrors.WIZARD_COMPANY_ERROR_SELECT_AT_LEAST_ONE_DEPARTMENT),
                type: "alert",
                variant: "danger",
            });
            error = true;
        }

        const totalDepartmentsTocopy = data.checkedDepsAndSubdeps?.filter(({ checked }) => checked).length || 0;
        if (totalDepartmentsTocopy > TOTAL_ALLOWED_DEPARTMENTS_PER_COMPANY) {
            handleToast({
                title: t(TranslationErrors.MAX_DEPARTMENTS_ALLOWED_ERROR),
                subtitle: translateCustomerTypeKeys(TranslationErrors.MAX_DEPARTMENTS_ALLOWED_ERROR_DESCRIPTION, {
                    firstReplace: "SINGULAR",
                }),
                type: "alert",
                variant: "danger",
            });
            error = true;
        }

        if (error) return;
        next();
    };

    const handleSelectDepartment = (department: DepartmentWithSubsModel, isChecked: boolean) => {
        if (!checkedDepsAndSubdeps) return;
        onChangeData({
            checkedDepsAndSubdeps: checkedDepsAndSubdeps.map(({ checked, id }) =>
                id === department.id ? { checked: isChecked, id } : { checked, id }
            ),
        });

        if (isChecked) {
            onChangeData({ departments: [...selectedDepartments, department] });
            return;
        }

        if (!isChecked) {
            onChangeData({
                departments: selectedDepartments.filter(({ id }) => id !== department.id),
            });
        }
    };

    const handleSelectSubdepartment = (subdept: SubdepartmentUsersModel, isChecked: boolean) => {
        const selectedDepartment = departments?.find(({ id }) => subdept.departmentId === id);
        if (!selectedDepartment) return;
        if (isChecked) {
            onChangeData({
                departments: selectedDepartments.map((department) =>
                    subdept.departmentId === department.id
                        ? { ...department, subs: [...department.subs, subdept] }
                        : department
                ),
            });
            return;
        }

        onChangeData({
            departments: selectedDepartments.map((department) =>
                subdept.departmentId === department.id
                    ? { ...department, subs: department.subs.filter(({ id }) => id !== subdept.id) }
                    : department
            ),
        });
    };

    const isSubdepartmentSelected = (department: DepartmentModel): number[] => {
        const subdepartments = selectedDepartments.find(({ id }) => id === department.id)?.subs;
        if (!subdepartments?.length) return [];

        return subdepartments.map(({ id }) => id);
    };

    useEffect(() => {
        !!companySelectedId.value.length && getDepartments();
    }, [companySelectedId]);

    useEffect(() => {
        if (departments?.length && !checkedDepsAndSubdeps?.length) {
            const getCheckedDepsAndSubdeps: CheckedDeps[] = departments.map((department) => ({
                checked: false,
                id: department.id,
            }));
            onChangeData({ checkedDepsAndSubdeps: getCheckedDepsAndSubdeps });
        }
    }, [departments]);

    useEffect(() => {
        if (!copyExistingCompany) return;
        getCompanies();
    }, [copyExistingCompany]);

    useEffect(() => {
        setErrorFormValues(INITIAL_ERROR_FORM_VALUES);
    }, [copyExistingCompany, companySelectedId, selectedDepartments]);

    return (
        <WizardForm validate={validate}>
            <div className="companyWizardStep2__buttonGroup">
                <ButtonGroup
                    label={translateCustomerTypeKeys(TranslationKeys.WIZARD_COMPANY_WANT_TO_CLONE, {
                        firstReplace: "PLURAL",
                        isGenderSensitive: true,
                    })}
                    items={yesNoItems(t)}
                    selectedValue={copyExistingCompany !== undefined ? (copyExistingCompany ? "YES" : "NO") : undefined}
                    onSelect={onSelectYesNo}
                    minWidth
                />
                {!!errorFormValues.copyExistingCompanyError.length && (
                    <ErrorMessage errorMessage={errorFormValues.copyExistingCompanyError} />
                )}
            </div>
            {copyExistingCompany && (
                <SelectOptions
                    label={translateCustomerTypeKeys(TranslationKeys.WIZARD_COMPANY_SELECT_COMPANY, {
                        firstReplace: "SINGULAR",
                        isGenderSensitive: true,
                    })}
                    options={companies}
                    selectedValue={!!companySelectedId.value.length ? companySelectedId.value : ""}
                    onChange={onSelectCompany}
                    isMulti={false}
                    isSearchable
                    errorMessage={errorFormValues.companySelectedIdError}
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                />
            )}
            {copyExistingCompany &&
                !!companySelectedId.value.length &&
                departments?.map((department) => (
                    <DepartmentCheckBox
                        key={department.id}
                        department={department}
                        selectedDepartments={selectedDepartments}
                        onSelectDepartment={handleSelectDepartment}
                        onSelectSubdepartment={handleSelectSubdepartment}
                        checkedDepsAndSubdeps={checkedDepsAndSubdeps?.find((checked) => checked.id === department.id)}
                        checkedSubdepartments={isSubdepartmentSelected(department)}
                    />
                ))}
        </WizardForm>
    );
};

export default Step2;
