import { FC } from "react";
import { OptionsOutsideSelect } from "app/components_v2/__inputs";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { NotificationDistributionListTranslations, TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { NotificationDistributionListFormValues } from "../../models/NotificationDistributionListFormValues";
import { LabelAddAll } from "../LabelAddAll/LabelAddAll";

type NotificationDistributionListUserOptionsProps = {
    errorUserList: string;
    isLoading: boolean;
    notifyAnalists: boolean;
    notifySupervisors: boolean;
    notifyWorkers: boolean;
    notifyAdminClients: boolean;
    selectedUsers: OptionModel[];
    userOptions: OptionModel[];
    onDeleteUser: (id: string) => void;
    onInputChange: (values: Partial<NotificationDistributionListFormValues>) => void;
    onAddAllUsersModalOpen: (value: boolean) => void;
};

export const NotificationDistributionListUserOptions: FC<NotificationDistributionListUserOptionsProps> = ({
    errorUserList,
    isLoading,
    notifyAnalists,
    notifySupervisors,
    notifyWorkers,
    notifyAdminClients,
    selectedUsers,
    userOptions,
    onDeleteUser,
    onInputChange,
    onAddAllUsersModalOpen,
}) => {
    const { t } = useTranslation();

    return (
        <div className="notificationDistributionListUserOptions">
            <CheckBox
                label={t(NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_WORKERS)}
                checked={notifyWorkers}
                onChange={(value) => onInputChange({ notifyWorkers: value })}
                htmlFor="notifyWorkers"
                size="sm"
            />
            <CheckBox
                label={t(NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_SUPERVISORS)}
                checked={notifySupervisors}
                onChange={(value) => onInputChange({ notifySupervisors: value })}
                htmlFor="notifySupervisors"
                size="sm"
            />
            <CheckBox
                label={t(NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_ANALISTS)}
                checked={notifyAnalists}
                onChange={(value) => onInputChange({ notifyAnalists: value })}
                htmlFor="notifyAnalists"
                size="sm"
            />
            <CheckBox
                label={t(NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_ADMIN_CLIENTS)}
                checked={notifyAdminClients}
                onChange={(value) => onInputChange({ notifyAdminClients: value })}
                htmlFor="notifyAdminClients"
                size="sm"
            />
            <div className="notificationDistributionListUserOptions__label">
                <LabelAddAll label={TranslationKeys.USERS} onClick={() => onAddAllUsersModalOpen(true)} />
                <OptionsOutsideSelect
                    onChange={(value) => onInputChange({ userList: value })}
                    options={userOptions}
                    onDeleteItem={onDeleteUser}
                    values={selectedUsers}
                    placeholder={t(TranslationKeys.SELECT_USERS)}
                    isLoading={isLoading}
                    isMulti
                    errorMessage={errorUserList}
                    autoInitials
                />
            </div>
        </div>
    );
};
