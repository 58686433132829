import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStep1 } from "./hooks/useStep1";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { CompanyWizardSteps, ERROR_INITIAL_VALUES } from "./types";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { Input, SelectOptions, Switch } from "app/components_v2/__inputs";
import Spinner from "app/components_v2/Spinner/Spinner";
import { Label } from "app/components_v2/__inputs/Label";
import { DragFile } from "app/components_v2/__draggableFiles/DragFile/DragFile";
import { convertAndCompressImages } from "app/helpers/Media/convertAndCompressImage";
import { v4 } from "uuid";
import { ImageDragFile } from "app/components_v2/__draggableFiles/DragFile/types";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

const Step1: FC<CompanyWizardSteps> = ({ data, onChangeData, next }) => {
    const { t } = useTranslation();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const { groupCompany, loading, errors, setErrors, validate } = useStep1(data, next);
    const { name, groupCompanyId, city, address, zip, worksWithQR, imageBase64Content } = data.company;
    const { nameError, cityError, addressError, zipError } = errors;

    const handleChangeImage = async (images: ImageDragFile[]) => {
        const imagesConverted = await convertAndCompressImages(images);
        const companyImage = imagesConverted[0];
        if (!companyImage) return;
        onChangeData({
            company: {
                ...data.company,
                imageBase64Content: companyImage.imageBase64Content,
            },
        });
    };

    useEffect(() => {
        setErrors(ERROR_INITIAL_VALUES);
    }, [data.company]);

    return (
        <WizardForm validate={validate}>
            {loading ? (
                <div className="fullHeight flex--center">
                    <Spinner />
                </div>
            ) : (
                <>
                    <div className="companyWizardStep1 ">
                        <Input
                            label={translateCustomerTypeKeys(TranslationKeys.WIZARD_COMPANY_NAME, {
                                firstReplace: "SINGULAR",
                                isGenderSensitive: true,
                            })}
                            placeholder={translateCustomerTypeKeys(TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_NAME, {
                                firstReplace: "SINGULAR",
                                isGenderSensitive: true,
                            })}
                            value={name}
                            onChange={(value) => onChangeData({ company: { ...data.company, name: value } })}
                            errorMessage={nameError}
                        />
                        <SelectOptions
                            label={translateCustomerTypeKeys(TranslationKeys.WIZARD_COMPANY_BELONGS_TO, {
                                firstReplace: "PLURAL",
                            })}
                            placeholder={t(TranslationCommon.SELECT_OPTION)}
                            options={groupCompany}
                            selectedValue={groupCompanyId !== 0 ? String(groupCompanyId) : ""}
                            onChange={({ value }: OptionsSearch) =>
                                onChangeData({
                                    company: { ...data.company, groupCompanyId: Number(value) },
                                })
                            }
                            isMulti={false}
                        />
                        <Input
                            label={t(TranslationKeys.LABEL_CITY)}
                            placeholder={t(TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_CITY)}
                            value={city}
                            onChange={(value) => onChangeData({ company: { ...data.company, city: value } })}
                            errorMessage={cityError}
                        />
                        <Input
                            label={t(TranslationKeys.WIZARD_COMPANY_WHERE_IS)}
                            placeholder={t(TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_DIRECTION)}
                            value={address}
                            onChange={(value) => onChangeData({ company: { ...data.company, address: value } })}
                            errorMessage={addressError}
                        />
                        <Input
                            label={t(TranslationKeys.LABEL_ZIP_CODE)}
                            placeholder={t(TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_POSTAL_CODE)}
                            value={zip}
                            onChange={(value) => onChangeData({ company: { ...data.company, zip: value } })}
                            errorMessage={zipError}
                            type="number"
                        />

                        <div className="companyWizardStep1__dragFile">
                            <Label
                                label={`${t(TranslationKeys.WIZARD_COMPANY_LOGO)} ${t(TranslationKeys.INPUT_OPTIONAL)}`}
                            />
                            <DragFile
                                onChange={handleChangeImage}
                                maxFiles={1}
                                placeholder={t(TranslationKeys.WIZARD_COMPANY_PLACEHOLDER_LOGO)}
                                height76
                                images={
                                    imageBase64Content
                                        ? [
                                              {
                                                  fileName: "Logo.jpg",
                                                  id: v4(),
                                                  isErrored: false,
                                                  isLoading: false,
                                                  base64: imageBase64Content,
                                              },
                                          ]
                                        : undefined
                                }
                                onDelete={() =>
                                    onChangeData({ company: { ...data.company, imageBase64Content: undefined } })
                                }
                            />
                        </div>
                        <div className="companyWizardStep1__worksWithQR">
                            <p className="inputLabel">{t(TranslationKeys.WORKS_WITH_QR)}</p>
                            <Switch
                                onChange={(value) =>
                                    onChangeData({
                                        company: {
                                            ...data.company,
                                            worksWithQR: value,
                                        },
                                    })
                                }
                                checked={worksWithQR}
                            />
                        </div>
                    </div>
                </>
            )}
        </WizardForm>
    );
};

export default Step1;
