import { FC } from "react";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { GenericModalFooter } from "../base/GenericModal/GenericModalFooter/GenericModalFooter";
import { useTranslation } from "react-i18next";
import { TranslationCommon } from "app/translation/translationKeys";
import { TaskDetailDynamicFields } from "app/components_v2/TaskDetailDynamicFields/TaskDetailDynamicFields";
import { DynamicFieldsModel } from "app/models/02-TAR/TaskWizard";
import { TaskStatus } from "app/shared/Constants";
import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";
import {
    isCorrectIncorrectDynamicField,
    isDropdownDynamicField,
} from "app/helpers/Task/dynamicFields/dynamicFieldHelper";

type PreviewDynamicFieldsModalProps = {
    onClose: () => void;
    dynamicfields: DynamicFieldsModel[];
};

export const PreviewDynamicFieldsModal: FC<PreviewDynamicFieldsModalProps> = ({ onClose, dynamicfields }) => {
    const { t } = useTranslation();
    const mapDynamicFieldsToTaskInstanceFieldsModel = () => {
        const taskInstanceFields: TaskInstanceFieldModel[] = [];
        let order = 0;
        dynamicfields.map(
            (
                { dropDownMultipleValues, dynamicFieldsType, isRequired, label, fK_TaskInstance, taskFieldOptions },
                i
            ) => {
                if (!!dropDownMultipleValues.length) {
                    dropDownMultipleValues
                        .filter(({ isDeleted }) => !isDeleted)
                        .forEach(({ assetsFields, value, label: assetName }) => {
                            assetsFields.forEach(
                                (
                                    { assetFieldOption, isHidden, isRequired, assetDynamicField, name, max, min, unit },
                                    i
                                ) => {
                                    taskInstanceFields.push({
                                        dynamicFieldType: assetDynamicField || "TEXT",
                                        fK_TaskInstance: 1,
                                        id: i + order,
                                        isHidden,
                                        isOperative: true,
                                        isRequired,
                                        label: name,
                                        order,
                                        fK_Asset2: Number(value),
                                        asset2: {
                                            assetFields: [],
                                            assetId: "",
                                            id: Number(value),
                                            name: assetName,
                                            description: "",
                                            customerInstanceId: 0,
                                            fK_Company: 0,
                                            fK_AssetType: 0,
                                            assetType: {
                                                id: 0,
                                                name: "",
                                                description: "",
                                                max,
                                                min,
                                                type: "NUMBER",
                                                assetTypeOptions: [],
                                            },
                                            isDeleted: false,
                                            isRelatedWithTasks: null,
                                            issueCount: 0,
                                            issueCountTotal: 0,
                                            notWorking: false,
                                            planAPPCCList: [],
                                            sensorSerialNumber: "",
                                        },
                                        assetFieldRange: { fK_MeasurementUnit: 0, max, min, id: i, unit: unit || "" },
                                        taskInstanceFieldValues:
                                            assetDynamicField === "DROPDOWN"
                                                ? assetFieldOption.map(({ name }, afoId) => ({
                                                      fK_Asset: i || 0,
                                                      id: afoId,
                                                      label: name,
                                                      max,
                                                      measureUnit: unit || "",
                                                      min,
                                                      value: "",
                                                  }))
                                                : [
                                                      {
                                                          fK_Asset: i || 0,
                                                          id: i,
                                                          label: name,
                                                          max,
                                                          measureUnit: unit || "",
                                                          min,
                                                          value: "",
                                                      },
                                                  ],
                                    });

                                    order++;
                                }
                            );
                        });

                    return;
                }

                taskInstanceFields.push({
                    isRequired,
                    label,
                    order,
                    fK_TaskInstance: fK_TaskInstance || i,
                    dynamicFieldType: dynamicFieldsType,
                    id: i,
                    taskInstanceFieldValues:
                        isCorrectIncorrectDynamicField(dynamicFieldsType) || isDropdownDynamicField(dynamicFieldsType)
                            ? taskFieldOptions.map((option, i) => ({
                                  id: i + order,
                                  label: t(option.label),
                                  value: "",
                                  max: null,
                                  min: null,
                                  measureUnit: "",
                                  fK_Asset: null,
                              }))
                            : [
                                  {
                                      id: i,
                                      label: label,
                                      value: "",
                                      max: null,
                                      measureUnit: "",
                                      min: null,
                                      fK_Asset: null,
                                  },
                              ],
                    taskFieldOptions: taskFieldOptions.map((option, i) => ({ id: i, label: option.label })),
                    isHidden: false,
                    isOperative: true,
                });

                order++;
            }
        );

        return taskInstanceFields.sort((a, b) => a.order - b.order);
    };

    return (
        <GenericModal
            size="md-tall"
            footer={
                <GenericModalFooter
                    closeButton={{
                        text: t(TranslationCommon.CLOSE),
                        onClick: onClose,
                    }}
                />
            }
        >
            <TaskDetailDynamicFields
                taskInstanceFields={mapDynamicFieldsToTaskInstanceFieldsModel()}
                readonly={false}
                errors={[]}
                statusCode={TaskStatus.COMPLETED}
                taskinstanceId={1}
            />
        </GenericModal>
    );
};
