import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSession } from "app/hooks";
import { TranslationCommon } from "app/translation/translationKeys";
import { OptionsSearch } from "app/models/FormComponentsModel";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { getInitials } from "app/helpers/Avatar/getInitials";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";

export const useAlertFilter = () => {
    const session = useSession();
    const { t } = useTranslation();

    const [isUserLoading, setIsUserLoading] = useState<boolean>(false);
    const [usersOptions, setUsersOptions] = useState<OptionModel[]>([]);
    const [isDepartmentLoading, setIsDepartmentLoading] = useState<boolean>(false);
    const [departmentsOptions, setDepartmentsOptions] = useState<OptionModel[]>([]);
    const [isCompanyLoading, setIsCompanyLoading] = useState<boolean>(false);
    const [companiesOptions, setCompaniesOptions] = useState<OptionModel[]>([]);
    const [qrOptions, setQrOptions] = useState<OptionModel[]>([]);
    const [isQrLoading, setIsQrLoading] = useState<boolean>(false);

    const getUsers = async (companyId: string) => {
        setIsUserLoading(true);
        const userId = session?.user?.id;
        const extraParams = new URLSearchParams();

        if (!!companyId.length && companyId != "-1") extraParams.append("companyId", companyId);
        if (!!userId) extraParams.append("supervisorId", String(userId));
        const { data, status } = await SegSelectorService.GetUserNamesWithIds({ extraParams: extraParams.toString() });
        const defaultOption: OptionsSearch = {
            label: t(TranslationCommon.SELECT_OPTION),
            value: "-1",
        };
        if (!status()) {
            setUsersOptions([defaultOption]);
            setIsUserLoading(false);
            return;
        }

        setUsersOptions([defaultOption, ...data]);
        setIsUserLoading(false);
    };

    const getDepartments = async (companyId: string) => {
        setIsDepartmentLoading(true);
        const extraParams = new URLSearchParams();
        const userId = session?.user?.id;
        if (!!companyId.length && companyId != "-1") extraParams.append("companyId", companyId);
        if (!!userId) extraParams.append("supervisorId", String(userId));

        const { data, status } = await SegSelectorService.GetDepartmentNamesWithIds({
            extraParams: extraParams.toString(),
        });
        const defaultOption: OptionsSearch = {
            label: t(TranslationCommon.SELECT_OPTION),
            value: "-1",
        };
        if (!status()) {
            setDepartmentsOptions([defaultOption]);
            setIsDepartmentLoading(false);
            return;
        }

        setDepartmentsOptions([defaultOption, ...data]);
        setIsDepartmentLoading(false);
    };

    const getCompanies = async () => {
        setIsCompanyLoading(true);
        const companyId = session?.workingCompany?.companyId;
        const userId = session?.user?.id;
        const extraParams = new URLSearchParams();
        if (!!companyId) extraParams.append("companyId", String(companyId));
        if (!!userId) extraParams.append("supervisorId", String(userId));
        const { data, status } = await SegSelectorService.GetCompanyNamesWithIds({
            extraParams: extraParams.toString(),
        });
        const defaultOption: OptionsSearch = {
            label: t(TranslationCommon.SELECT_OPTION),
            value: "-1",
        };
        if (!status()) {
            setCompaniesOptions([defaultOption]);
            setIsCompanyLoading(false);
            return;
        }

        setCompaniesOptions([
            defaultOption,
            ...data.map((data) => ({
                ...data,
                initials: getInitials(data.label),
            })),
        ]);
        setIsCompanyLoading(false);
    };

    const getQrs = async (companyId: string) => {
        setIsQrLoading(true);

        const extraParams = new URLSearchParams();
        if (!!companyId.length && companyId != "-1") extraParams.append("companyId", companyId);

        const { data, status } = await TarSelectorService.GetWorkingPostions({ extraParams: extraParams.toString() });
        const defaultOption: OptionsSearch = {
            label: t(TranslationCommon.SELECT_OPTION),
            value: "-1",
        };

        setIsQrLoading(false);
        if (!status()) {
            setQrOptions([defaultOption]);
            return;
        }

        setQrOptions([
            defaultOption,
            ...data.map((data) => ({
                ...data,
                initials: getInitials(data.label),
            })),
        ]);
    };

    return {
        isUserLoading,
        usersOptions,
        getUsers,
        isDepartmentLoading,
        departmentsOptions,
        getDepartments,
        isCompanyLoading,
        companiesOptions,
        getCompanies,
        getQrs,
        qrOptions,
        isQrLoading,
    };
};
