import { FC, MouseEvent } from "react";
import { Avatar } from "../Avatar/Avatar";
import { AVATAR_COLORS, AVATAR_COLORS_HEX_MAP } from "../Avatar/constants/avatarConstants";

type AvatarColorPickerProps = {
    initials: string;
    selectedColor: string;
    onChange: (hexColor: string) => void;
};

export const AvatarColorPicker: FC<AvatarColorPickerProps> = ({ onChange, selectedColor, initials }) => {
    const handleChange = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, color: string) => {
        e.preventDefault();
        onChange(AVATAR_COLORS_HEX_MAP[color]);
    };

    return (
        <div className="avatarColorPicker">
            {AVATAR_COLORS.map((color) => {
                return (
                    <button
                        key={color}
                        className={`avatarColorPicker__color ${
                            selectedColor === AVATAR_COLORS_HEX_MAP[color] ? "avatarColorPicker__color--selected" : ""
                        }`}
                        onClick={(e) => handleChange(e, color)}
                        type="button"
                        aria-selected={selectedColor === AVATAR_COLORS_HEX_MAP[color]}
                    >
                        <Avatar
                            hexColor={AVATAR_COLORS_HEX_MAP[color]}
                            nameInitials={initials}
                            avatarName={initials}
                            size="sm"
                        />
                    </button>
                );
            })}
        </div>
    );
};
