import { FC } from "react";
import { useTranslation } from "react-i18next";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { NotificationDistributionListTranslations, TranslationCommon } from "app/translation/translationKeys";
import { AddNewButton } from "app/components_v2/__buttons/AddNewButton/AddNewButton";
import { Input } from "app/components_v2/__inputs";
import { Tag } from "app/components_v2/Tag/Tag";

type NotificationDistributtionListEmailOptionsProps = {
    emails: string[];
    email: string;
    errorEmails: string;
    onDeleteEmail: (index: number) => void;
    onAddEmail: () => void;
    onChangeEmail: (value: string) => void;
};

export const NotificationDistributtionListEmailOptions: FC<NotificationDistributtionListEmailOptionsProps> = ({
    emails,
    onDeleteEmail,
    onChangeEmail,
    email,
    errorEmails,
    onAddEmail,
}) => {
    const { t } = useTranslation();

    return (
        <>
            {emails.map((email, i) => (
                <Tag key={i} message={email} onClose={() => onDeleteEmail(i)} variant="white" />
            ))}
            {emails.length < 20 && (
                <>
                    <Input
                        label={t(
                            NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_BY_EMAIL_LABEL
                        )}
                        placeholder={t(
                            NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_BY_EMAIL_PLACEHOLDER
                        )}
                        onChange={onChangeEmail}
                        errorMessage={errorEmails}
                        onPressEnter={onAddEmail}
                        value={email}
                    />
                    <AddNewButton onClick={() => onAddEmail()} icon={faPlus}>
                        {t(TranslationCommon.ADD)}
                    </AddNewButton>
                </>
            )}
        </>
    );
};
