import { multiplyAndFormat } from "../Utilities/number/multiplyAndFormat";

export const roundNumberArray = (array: number[], desiredTotal = 100): number[] => {
    if (desiredTotal === 0) return array.map((_) => 0);
    const totalOriginal = array.reduce((total, num) => total + num, 0);
    const roundedArray = array.map((num) => {
        const percentage = (num / totalOriginal) * Number(desiredTotal.toFixed(0));
        if (num > 0 && percentage < 1) {
            return 1;
        }
        return Math.round(percentage);
    });

    const totalRounded = roundedArray.reduce((total, num) => total + num, 0);
    const difference = Number(desiredTotal.toFixed(0)) - totalRounded;
    if (difference !== 0) {
        roundedArray[roundedArray.length - 1] += difference;
    }

    return roundedArray;
};

export const roundPercentages = (array: number[], decimals: number) => {
    const sum = array.reduce((acc, num) => acc + num, 0);

    const percentages = array.map((num) => {
        if (num === 0) return 0;
        const percentages = multiplyAndFormat(num / sum, 100, decimals);
        return Math.max(percentages, (1 / 10) * decimals);
    });

    // Ensure percentages sum up 100
    const sumPercentages = percentages.reduce((acc, percentages) => acc + percentages, 0);
    const difference = 100 - sumPercentages;

    percentages[percentages.length - 1] += difference;

    return percentages;
};
