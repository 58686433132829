import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { IssueTranslation, TranslationCommon } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { IssueBodyDescription } from "./IssueBodyDescription/IssueBodyDescription";
import { IssueModel } from "app/models/05-QUA/IssueModels/IssueModel";
import { IssueBodyAsset } from "./IssueBodyAsset/IssueBodyAsset";
import { IssueBodyResponsible } from "./IssueBodyResponsible/IssueBodyResponsible";
import { IssueBodyAttachments } from "./IssueBodyAttachments/IssueBodyAttachments";
import { IssueAttachmentModel } from "app/models/05-QUA/IssueModels/IssueAttachmentModel";
import { hasPermissionToAdd, hasPermissionToEdit, hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { PrivatePaths, SecScreen } from "app/shared/Constants";
import { useSession } from "app/hooks";
import { Collapsable } from "app/components_v2/Collapsable/Collapsable";
import { Button } from "app/components_v2/__buttons/Button/Button";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { IssueClassification } from "./IssueClassification/IssueClassification";
import { IssueBodyCompany } from "./IssueBodyCompany/IssueBodyCompany";

type IssueBodyProps = {
    issue: IssueModel;
    variant?: TabHeaderVariants;
    options?: IssueBodyOptions;
    onAddNewAttachments: (values: IssueAttachmentModel[]) => void;
};

type IssueBodyOptions = {
    issueClassificationReadOnly?: boolean;
    onChangeIssueClassification?: (values: Partial<IssueModel>) => void;
};

export const IssueBody: FC<IssueBodyProps> = ({ issue, variant = "primary", onAddNewAttachments, options }) => {
    const { t } = useTranslation();
    const session = useSession();
    const nav = useNavigate();

    const {
        issueType,
        asset,
        taskInstance,
        receivers,
        issueAttachments,
        id,
        fK_TaskInstance,
        fK_Task,
        isCreated,
        issueClassification,
        company,
    } = issue;

    const showAttachments = () => {
        if (!hasPermissionToView(session, SecScreen.ATTACH_FILES)) return false;
        if (!issueAttachments.length && !hasPermissionToAdd(SecScreen.ATTACH_FILES)) return false;
        return true;
    };

    return (
        <>
            <Collapsable title={t(TranslationCommon.DATA)} variant={variant}>
                <div className="issueBody">
                    <div className="issueBody__container">
                        {issue.auditGroupCheckListItemInstance?.name && (
                            <IssueBodyDescription
                                label={t(IssueTranslation.ISSUE_BODY_CHECKLIST_ITEM_TITLE)}
                                description={issue.auditGroupCheckListItemInstance.name}
                            />
                        )}
                        <IssueBodyDescription
                            label={t(IssueTranslation.ISSUE_BODY_DESCRIPTION_TITLE)}
                            description={issue.description}
                            audioURL={issue.audioURL}
                        />
                        {asset && <IssueBodyAsset asset={asset} taskInstance={taskInstance} />}
                        {hasPermissionToEdit(SecScreen.ISSUE) && (
                            <IssueClassification
                                issueClassification={issueClassification}
                                onEdit={options?.onChangeIssueClassification}
                                readOnly={options?.issueClassificationReadOnly}
                            />
                        )}
                        {!!receivers.length && <IssueBodyResponsible receivers={receivers} />}
                        {!!company && <IssueBodyCompany company={company} />}
                        {showAttachments() && (
                            <IssueBodyAttachments
                                attachments={issueAttachments}
                                onAddNewAttachments={onAddNewAttachments}
                                id={id}
                                variant={variant}
                            />
                        )}
                        {(issueType === "TASK" || issueType === "TASK_ASSET") && fK_TaskInstance && (
                            <div className="issueBody__goToTask">
                                <Button
                                    text={t(IssueTranslation.ISSUE_BODY_GO_TO_TASK)}
                                    iconRight={faArrowRight}
                                    onClick={() => nav(`/${PrivatePaths.DASHBOARD}/${fK_TaskInstance}`)}
                                    type="secondary"
                                    variant={variant}
                                />
                            </div>
                        )}
                        {issueType === "AUDIT" && isCreated && fK_Task && (
                            <div className="issueBody__goToTask">
                                <Button
                                    text={t(IssueTranslation.ISSUE_BODY_GO_TO_TASK)}
                                    iconRight={faArrowRight}
                                    onClick={() => nav(`/${PrivatePaths.TASK_HISTORY}?TaskId=${fK_Task}`)}
                                    type="secondary"
                                    variant={variant}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Collapsable>
        </>
    );
};
