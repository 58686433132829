import { PublicPaths } from "app/shared/Constants";
import { Navigate, Route, Routes } from "react-router-dom";
import { DocumentalFolderPage } from "./DocumentalFolderPage/DocumentalFolderPage";
import { DocumentalFilePage } from "./DocumentalFilePage/DocumentalFilePage";

export const DocumentalPage = () => {
    return (
        <Routes>
            <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
            <Route path={""} element={<DocumentalFolderPage />} />
            <Route path={"folder/:id"} element={<DocumentalFilePage />} />
        </Routes>
    );
};
