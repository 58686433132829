import { useWindowSize } from "usehooks-ts";
import { Avatar } from "../Avatar/Avatar";
import { StarRatingBadge } from "../__stars/StarRatingBadge/StarRatingBadge";
import { FC, ReactNode } from "react";
import { ANALYST_ENTITY_TYPE, USER_TYPE, screenSize } from "app/shared/Constants";
import { isUserTypeSupervisorOrAnalistOrAdminClient } from "app/helpers/User/userType/userTypeCheck";
import { AnalystEntityType } from "app/shared/types/AnalyticsTypes";
import { AnalystDates, AvatarModel, RatingModel } from "./types";
import { useSupervisorDetailModal } from "app/hooks/useSupervisorDetailModal";
import { DatesReviewTask } from "app/hooks/Dates/types";
import { ValorationDetailModal } from "../__modals/ValorationDetailModal/ValorationDetailModal";
import { UserType } from "app/shared/types/UserTypes";

type HeaderAnalystProps = {
    avatar: AvatarModel;
    avatarSmall?: AvatarModel;
    rating: RatingModel;
    objectFit?: "contain";
    avatarNameComponent?: ReactNode;
    userType?: UserType;
    entityType?: AnalystEntityType;
    dates?: AnalystDates;
};

const today = new Date(new Date().toISOString());
const oneWeekAgo = today.getTime() - 7 * 24 * 60 * 60 * 1000;
export const HeaderAnalyst: FC<HeaderAnalystProps> = ({
    avatar,
    avatarSmall,
    rating,
    objectFit,
    avatarNameComponent,
    userType = USER_TYPE.WORKER,
    entityType,
    dates,
}) => {
    const { width } = useWindowSize();
    const {
        dataChart,
        handleOpenModal,
        isLoadingModal,
        onDateChange: supervisorDateChange,
        showModal,
        onShowModalChange,
    } = useSupervisorDetailModal({
        startDateInput: dates?.startDate || new Date(oneWeekAgo),
        endDateInput: dates?.endDate || new Date(),
        userId: avatar.colorId,
        isSupervisor: false,
    });

    const openSupervisorDetailModal = () => {
        if (entityType !== ANALYST_ENTITY_TYPE.USER) return;
        handleOpenModal();
    };

    const handledateChange = (value: Partial<DatesReviewTask>) => {
        supervisorDateChange({ endDate: value.endDate, startDate: value.startDate });
    };

    return (
        <>
            {showModal && (
                <ValorationDetailModal
                    showModal={onShowModalChange}
                    endDate={dates?.endDate || new Date()}
                    onDateChange={handledateChange}
                    startDate={dates?.startDate || new Date(oneWeekAgo)}
                    data={dataChart}
                    isLoading={isLoadingModal}
                    isSupervisor={userType === "SUPERVISOR"}
                />
            )}
            <div className="headerAnalyst">
                <div className="headerAnalyst__info">
                    <Avatar
                        img={avatar.img || undefined}
                        avatarName={avatar.avatarName}
                        colorId={!avatar.hexColor?.length ? avatar.colorId : undefined}
                        hexColor={avatar.hexColor}
                        clamp
                        size="2xl"
                        border
                        objectFit={objectFit}
                        background="white"
                    />
                    <div className="headerAnalyst__info__names">
                        <p className="headerAnalyst__info__title">{avatar.avatarName}</p>
                        {avatarSmall && (
                            <Avatar
                                colorId={avatarSmall.colorId}
                                avatarName={avatarSmall.avatarName}
                                name={avatarSmall.avatarName}
                                img={avatarSmall.img || undefined}
                                size="xs"
                                variant="secondary"
                            />
                        )}
                    </div>
                    {avatarNameComponent}
                </div>
                {!isUserTypeSupervisorOrAnalistOrAdminClient(userType) && !!rating.starRatingAVG && (
                    <div
                        className={`${entityType === ANALYST_ENTITY_TYPE.USER ? "pointer" : ""}`}
                        onClick={openSupervisorDetailModal}
                    >
                        <StarRatingBadge
                            {...rating}
                            showFullRatingText={width >= screenSize.TABLET}
                            direction="row"
                            size="xl"
                            startSize={"md"}
                        />
                    </div>
                )}
            </div>
        </>
    );
};
