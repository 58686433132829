import { DepartmentNameModel } from "app/models/01-SEG/DepartmentName/DepartmentNameModel";
import DepartmentNameService from "app/services/01-SEG/DepartmentNameService";
import { useEffect, useState } from "react";
import { useToast } from "./Toast/useToast";
import { useFetchErrors } from "./useFetchErrors";
import {
    SelectActionsLastItemsModel,
    SelectActionsModel,
    SelectActionsOptions,
} from "app/components_v2/SelectWithActions/types";
import { faPen, faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { useSession } from "./useSession";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { useTranslation } from "react-i18next";
import { DepartmentNameTranslations, TranslationCommon } from "app/translation/translationKeys";

type IuseDepartmentName = {
    onChange: (fK_DepartmentName: string) => void;
    fK_DepartmentName: string;
};

export const useDepartmentName = ({ onChange, fK_DepartmentName }: IuseDepartmentName) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const session = useSession();

    const [selectedDeparmentName, setSelectedDeparmentName] = useState<DepartmentNameModel | null>(null);
    const [options, setOptions] = useState<SelectActionsOptions[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [isDepartmentNameModalVisible, setIsDepartmentNameModalVisible] = useState<boolean>(false);
    const [isDepartmentNameDeleteModalVisible, setIsDepartmentNameDeleteModalVisible] = useState<boolean>(false);
    const [isDepartmentNameDeleteModalLoading, setIsDepartmentNameDeleteModalLoading] = useState<boolean>(false);

    const customerInstanceId = session?.user.customerInstanceId || 0;

    const getDepartmentNames = async () => {
        setIsLoading(true);
        const { data, getParsedError, status } = await DepartmentNameService.GetData();
        setIsLoading(false);

        if (!status()) {
            setOptions([]);
            handleToast({
                title: t(DepartmentNameTranslations.DEPARTMENT_NAME_FAILED_RETRIEVING_DATA),
                subtitle: getErrorMessage(getParsedError()),
                variant: "danger",
                type: "alert",
            });
            return;
        }

        setOptions([
            {
                label: t(TranslationCommon.SELECT_OPTION),
                value: "-1",
                hideActions: true,
            },
            ...data.list.map(({ id, name }) => ({ value: String(id), label: name })),
        ]);
    };

    const addNewDepartmentName = ({ id, name }: DepartmentNameModel) => {
        onChange(String(id));
        setOptions((prev) => [...prev, { value: String(id), label: name }]);
        closeModal();
    };

    const onEditDepartmentName = ({ id, name }: DepartmentNameModel) => {
        setOptions((prev) =>
            prev.map((item) => (item.value === String(id) ? { label: name, value: String(id) } : item))
        );
        closeModal();
    };

    const onDeleteDepartmentName = async () => {
        setIsDepartmentNameDeleteModalLoading(true);
        const departmentNameId = selectedDeparmentName?.id || 0;
        const { status } = await DepartmentNameService.Delete(departmentNameId);
        setIsDepartmentNameDeleteModalLoading(false);

        if (!status()) {
            handleToast({
                title: t(DepartmentNameTranslations.DEPARTMENT_NAME_FAILED_DELETING_NAME),
                type: "alert",
                variant: "danger",
            });
            return;
        }

        setOptions((prev) => prev.filter(({ value }) => value !== String(departmentNameId)));
        if (fK_DepartmentName === String(departmentNameId)) onChange(fK_DepartmentName);

        closeDeleteModal();
    };

    const openEditModal = ({ label, value }: OptionsSearch) => {
        const departmentName: DepartmentNameModel = {
            customerInstanceId,
            name: label,
            id: Number(value),
            isDeleted: false,
        };

        setSelectedDeparmentName(departmentName);
        setIsDepartmentNameModalVisible(true);
    };

    const openDeleteModal = ({ label, value }: OptionsSearch) => {
        const departmentName: DepartmentNameModel = {
            customerInstanceId,
            name: label,
            id: Number(value),
            isDeleted: false,
        };

        setSelectedDeparmentName(departmentName);
        setIsDepartmentNameDeleteModalVisible(true);
    };

    const closeModal = () => {
        setSelectedDeparmentName(null);
        setIsDepartmentNameModalVisible(false);
    };

    const closeDeleteModal = () => {
        setSelectedDeparmentName(null);
        setIsDepartmentNameDeleteModalVisible(false);
    };

    const selectActions: SelectActionsModel[] = [
        {
            icon: faPen,
            onClick: openEditModal,
        },
        {
            icon: faTrash,
            onClick: openDeleteModal,
        },
    ];
    const lastItems: SelectActionsLastItemsModel[] = [
        {
            icon: faPlus,
            onClick: () => setIsDepartmentNameModalVisible(true),
            title: t(DepartmentNameTranslations.DEPARTMENT_NAME_ADD_ACTION),
        },
    ];

    useEffect(() => {
        getDepartmentNames();
    }, []);

    return {
        options,
        isLoading,
        selectedDeparmentName,
        isDepartmentNameModalVisible,
        isDepartmentNameDeleteModalVisible,
        isDepartmentNameDeleteModalLoading,
        closeDeleteModal,
        closeModal,
        selectActions,
        lastItems,
        addNewDepartmentName,
        onEditDepartmentName,
        onDeleteDepartmentName,
    };
};
