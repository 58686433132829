import { multiplyAndFormat } from "app/helpers/Utilities/number/multiplyAndFormat";
import { screenSize } from "app/shared/Constants";

export const formatFileSize = (size: number): string => {
    const units: string[] = ["bytes", "KB", "MB", "GB", "TB"];
    let formattedSize: number | string = size;
    let unitIndex: number = 0;

    while (formattedSize >= screenSize.LAPTOP && unitIndex < units.length - 1) {
        formattedSize /= screenSize.LAPTOP;
        unitIndex++;
    }

    formattedSize = multiplyAndFormat(formattedSize, 100) / 100; // Redondear a 2 decimales
    formattedSize = formattedSize.toLocaleString(); // Formatear número con separadores de miles

    return formattedSize + " " + units[unitIndex];
};
