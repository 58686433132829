import { useState } from "react";
import AssetService from "app/services/05-QUA/AssetService";
import { AssetModel2 } from "app/models/05-QUA/AssetModels/AssetModel2";
import { fillExtraParams } from "./fillExtraParams";

export const useGetAssets = () => {
    const [assets, setAssets] = useState<AssetModel2[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>();

    const getAssets = async (companyId: number | null, fK_PlanAPPCC?: string) => {
        setIsLoading(true);
        let extraParams = fillExtraParams({ companyId, fK_PlanAPPCC });
        const assetsSr = await AssetService.GetFullList({
            extraParams,
        });

        if (!assetsSr.status()) {
            console.error(assetsSr.error);
            setError(assetsSr.error);
            setIsLoading(false);
            return;
        }
        setAssets(assetsSr.data.list);
        setIsLoading(false);
    };

    return {
        assets,
        isLoading,
        error,
        getAssets,
    };
};
