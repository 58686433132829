import { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { MultiProgressBar } from "app/components_v2/MultiProgressBar/MultiProgressBar";

export const MultiProgressBarSection: FC<SectionsShowProp> = ({ show }) => {
    return (
        <Section title="MultiProgressBar" show={show}>
            <div style={{ width: "300px", display: "flex", flexDirection: "column", gap: "10px" }}>
                <MultiProgressBar
                    items={[
                        { color: "green", value: 2 },
                        { color: "yellow", value: 2 },
                        { color: "red", value: 2 },
                        { color: "gray", value: 2 },
                    ]}
                />
                <MultiProgressBar
                    items={[
                        { color: "green", value: 25000 },
                        { color: "yellow", value: 10 },
                        { color: "red", value: 2 },
                        { color: "gray", value: 0 },
                    ]}
                />
                <MultiProgressBar
                    items={[
                        { color: "green", value: 25 },
                        { color: "yellow", value: 10 },
                        { color: "red", value: 2 },
                    ]}
                />
                <MultiProgressBar
                    items={[
                        { color: "green", value: 25 },
                        { color: "yellow", value: 10 },
                    ]}
                />
                <MultiProgressBar items={[{ color: "green", value: 25 }]} />
            </div>
        </Section>
    );
};
