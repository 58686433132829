import { FC, ReactElement, useState } from "react";
import { useToast } from "app/hooks/Toast/useToast";
import { useWizard } from "app/hooks/useWizard";
import { useTranslation } from "react-i18next";
import { InstanceService } from "app/services";
import { TranslationErrors, TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import MapModelsHelper from "app/helpers/Translations/translationHelper";
import { WizardLicenseModelToInstanceWizardModel } from "app/mappers/WizardLicenseModelToInstanceWizardModel";
import { WizardLicenseModel } from "./types";
import { Wizard } from "../Wizard";
import { StepFour } from "./StepFour/StepFour";
import { StepOne } from "./StepOne/StepOne";
import { StepThree } from "./StepThree/StepThree";
import { StepTwo } from "./StepTwo/StepTwo";
import { INITIAL_FORM_VALUES } from "./constants/LicensesConstants";

type WizardLicenseProps = {
    onFinish: () => void;
};

export const WizardLicense: FC<WizardLicenseProps> = ({ onFinish }) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();

    const headerItems = [
        { title: t(TranslationKeys.WIZARD_LICENSE_TITLE_DATA) },
        { title: t(TranslationKeys.WIZARD_LICENSE_TITLE_MODULES) },
        { title: t(TranslationKeys.WIZARD_LICENSE_TITLE_ADMIN) },
        { title: t(TranslationKeys.WIZARD_HEADER_TASK_FINISH) },
    ];

    const totalSteps = headerItems.length;
    const { currentStepIndex, onStepChange, goTo, prev, next, isFirstStep, isLastStep } = useWizard(totalSteps);
    const [data, setData] = useState<WizardLicenseModel>(INITIAL_FORM_VALUES);
    const [isSaving, setisSaving] = useState<boolean>(false);

    const handleInputChange = (values: Partial<WizardLicenseModel>) => {
        setData((prev) => ({ ...prev, ...values }));
    };

    const handleFinish = async () => {
        setisSaving(true);
        const modules = data.permisions.map((m) => ({
            moduleCode: m.moduleCode,
            screenPatents: MapModelsHelper.fromScreenPatentsToScreenPatentCode(m.screenPatents),
        }));
        const instanceSr = await InstanceService.SaveInstance({
            ...WizardLicenseModelToInstanceWizardModel(data),
            modules,
        });
        if (!instanceSr.status()) {
            let error = instanceSr.getParsedError();
            if (
                error === TranslationErrors.USERNAME_ALREADY_REGISTERED ||
                error === TranslationErrors.USER_EMAIL_ALREADY_REGISTERED
            )
                error = t(error);

            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            setisSaving(false);
            return;
        }
        onFinish();
        setisSaving(false);
    };

    const steps: ReactElement[] = [
        <StepOne
            {...data}
            onChange={handleInputChange}
            currentStepIndex={currentStepIndex}
            isFirstSetp={isFirstStep}
            isLastStep={isLastStep}
            next={next}
            prev={prev}
        />,
        <StepTwo
            {...data}
            onChange={handleInputChange}
            currentStepIndex={currentStepIndex}
            isFirstSetp={isFirstStep}
            isLastStep={isLastStep}
            next={next}
            prev={prev}
        />,
        <StepThree
            {...data}
            onChange={handleInputChange}
            currentStepIndex={currentStepIndex}
            isFirstSetp={isFirstStep}
            isLastStep={isLastStep}
            next={next}
            prev={prev}
        />,
        <StepFour {...data} onFinish={handleFinish} />,
    ];

    return (
        <Wizard
            currentStepIndex={currentStepIndex}
            headerItems={headerItems}
            onStepChange={onStepChange}
            step={steps[currentStepIndex - 1]}
            totalSteps={totalSteps}
            goTo={goTo}
            validateNextStep={false}
            isLoading={isSaving}
            finishButtonText={t(TranslationKeys.WIZARD_LICENSE_TITLE)}
        />
    );
};
