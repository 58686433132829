import { FC } from "react";
import { HeaderModalProps } from "../GenericModal";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { Icon } from "app/components_v2/Icon/Icon";

export const GenericModalHeader: FC<HeaderModalProps> = ({
    title,
    subtitle,
    headerChildren,
    headerImage,
    onClose,
    actionButton,
    radius,
    variant = "primary",
}) => {
    return (
        <div
            className={`genericModalHeader genericModalHeader--${variant} ${
                radius ? "genericModalHeader--radius" : ""
            }`}
            style={{ backgroundImage: headerImage ? `url(${headerImage})` : "none" }}
        >
            <div className={`genericModalHeader__container genericModalHeader__container--${variant}`}>
                <div className="genericModalHeader__titles">
                    {!!subtitle?.length && (
                        <p
                            className={`genericModalHeader__subtitle${
                                actionButton ? "--withIcon" : ""
                            } genericModalHeader__subtitle--${variant}`}
                        >
                            {subtitle}
                        </p>
                    )}
                    <div className="genericModalHeader__titleContainer">
                        <p
                            className={`genericModalHeader__title${
                                actionButton ? "--withIcon" : ""
                            } genericModalHeader__title--${variant}`}
                        >
                            {title}
                        </p>
                        {actionButton && (
                            <Icon
                                dataTestId="genericModalSecondaryButton"
                                icon={actionButton.icon}
                                onClick={actionButton.onClick}
                                size="sm"
                                theme={variant === "primary" ? "dark" : "light"}
                            />
                        )}
                    </div>
                </div>
                {onClose && (
                    <Icon
                        icon={faXmark}
                        onClick={onClose}
                        dataTestId="genericModalCloseButton"
                        size="sm"
                        theme={variant === "primary" ? "dark" : "light"}
                    />
                )}
            </div>
            {headerChildren}
        </div>
    );
};
