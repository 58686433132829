import { DateRangePicker, Input, OptionsOutsideSelect, SelectOptions, TimePicker } from "app/components_v2/__inputs";
import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";
import { FC } from "react";
import { TaskDynamicField } from "../TaskDynamicField";
import { DateTimePicker } from "app/components_v2/__inputs/DateTimePicker/DateTimePicker";
import { isCorrectIncorrectDynamicField } from "app/helpers/Task/dynamicFields/dynamicFieldHelper";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import {
    formatDatesDateRange,
    formatDatesSimpleDate,
    formatDateWithHour,
    getDateFormattedWithHoursSelects,
    getDateFromRangeString,
} from "app/helpers/Utilities/date/Date.utilities";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { ReadOnlyIconProps } from "app/components_v2/__inputs/ReadOnlyInput/types";
import { TaskInstanceFieldValueModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldValueModel";

type dropdownItem = { id: number; label: string; value: string };

type TaskDetailDynamicFieldItemProps = {
    taskInstanceField: TaskInstanceFieldModel;
    errors: number[];
    readonly: boolean;
    offset?: number;
    selectOptionsReadOnlyIconOptions?: ReadOnlyIconProps;
    handleInputChange: (id: number, newValue: string | dropdownItem[]) => void;
    handleDropDownMultipleChange: (id: number, selectedValues: OptionModel[]) => void;
    handleDeleteDropDownMultipleItem: (id: string) => void;
    handleSelectChange: (id: number, taskInstanceFieldValues: TaskInstanceFieldValueModel[], label: string) => void;
    isSelectorLoading: boolean;
};

export const TaskDetailDynamicFieldItem: FC<TaskDetailDynamicFieldItemProps> = ({
    taskInstanceField,
    errors,
    readonly,
    offset,
    selectOptionsReadOnlyIconOptions,
    handleInputChange,
    handleDropDownMultipleChange,
    handleDeleteDropDownMultipleItem,
    handleSelectChange,
    isSelectorLoading,
}) => {
    const { dynamicFieldType, id, isRequired, label, taskInstanceFieldValues, fK_Issue } = taskInstanceField;

    const { t } = useTranslation();

    const handleDateTimeChange = (value: string, id: number) => {
        const newDateTime = new Date(value);
        handleInputChange(id, formatDateWithHour(newDateTime));
    };
    const hasError = (id: number) => (errors.includes(id) ? t(TranslationCommon.INPUT_NOT_EMPTY) : "");

    return (
        <div key={id} className="fullWidth">
            {dynamicFieldType === "TEXT" ? (
                <Input
                    onChange={(newValue) => handleInputChange(id, newValue)}
                    label={label}
                    isOptional={!isRequired}
                    placeholder={label}
                    errorMessage={hasError(id)}
                    readonly={readonly}
                    value={taskInstanceFieldValues[0].value}
                />
            ) : dynamicFieldType === "NUMBER" ? (
                <TaskDynamicField
                    onChange={(newValue) => handleInputChange(id, newValue)}
                    label={label}
                    errorMessage={hasError(id)}
                    fieldValue={taskInstanceFieldValues[0].value}
                    isRequired={isRequired}
                    readonly={readonly}
                />
            ) : dynamicFieldType === "TIME" ? (
                <TimePicker
                    label={label}
                    onChange={(newValue) => handleInputChange(id, newValue)}
                    value={taskInstanceFieldValues[0].value}
                    isOptional={!isRequired}
                    errorMessage={hasError(id)}
                    readonly={readonly}
                />
            ) : dynamicFieldType === "DATETIME" ? (
                <DateTimePicker
                    label={label}
                    isOptional={!isRequired}
                    value={getDateFormattedWithHoursSelects(new Date(taskInstanceFieldValues[0].value))}
                    onChange={(dateValue) => handleDateTimeChange(dateValue, id)}
                    readonly={readonly}
                    errorMessage={hasError(id)}
                />
            ) : dynamicFieldType === "DATERANGE" ? (
                <DateRangePicker
                    onChange={(dates) => handleInputChange(id, formatDatesDateRange(dates, offset))}
                    range
                    showMoreOptions
                    label={label}
                    isOptional={!isRequired}
                    errorMessage={hasError(id)}
                    readonly={readonly}
                    startDate={
                        taskInstanceFieldValues[0].value
                            ? getDateFromRangeString(taskInstanceFieldValues[0].value, true)
                            : undefined
                    }
                    endDate={getDateFromRangeString(taskInstanceFieldValues[0].value)}
                    placeholder={t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_DATE_RANGE)}
                />
            ) : dynamicFieldType === "DROPDOWN" ? (
                <SelectOptions
                    isMulti={false}
                    onChange={({ label }) => handleSelectChange(id, taskInstanceFieldValues, label)}
                    label={label}
                    isOptional={!isRequired}
                    errorMessage={hasError(id)}
                    readonly={readonly}
                    selectedValue={
                        taskInstanceFieldValues.find(({ value }) => value)?.id
                            ? String(taskInstanceFieldValues.find(({ value }) => value === "true")?.id)
                            : ""
                    }
                    options={taskInstanceFieldValues.map(({ id, label }) => ({
                        label: t(label),
                        value: String(id),
                    }))}
                    placeholder={t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_SELECT)}
                />
            ) : dynamicFieldType === "DROPDOWN_MULTIPLE" ? (
                <OptionsOutsideSelect
                    onChange={(values) => handleDropDownMultipleChange(id, values)}
                    onDeleteItem={handleDeleteDropDownMultipleItem}
                    label={label}
                    isOptional={!isRequired}
                    errorMessage={hasError(id)}
                    readonly={readonly}
                    options={taskInstanceFieldValues.map(({ id, label }) => ({
                        label: t(label),
                        value: String(id),
                    }))}
                    placeholder={t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_SELECT)}
                    values={taskInstanceFieldValues
                        .filter(({ value }) => value === "true")
                        .map(({ id: dropdownItemId, label }) => ({
                            value: String(dropdownItemId),
                            label,
                        }))}
                    tagOptions={{ clamp: 2, size: "auto" }}
                />
            ) : dynamicFieldType === "DROPDOWN_MULTIPLE_ASSET" ? (
                <OptionsOutsideSelect
                    onChange={(values) => handleDropDownMultipleChange(id, values)}
                    onDeleteItem={handleDeleteDropDownMultipleItem}
                    label={label}
                    isOptional={!isRequired}
                    errorMessage={hasError(id)}
                    readonly={readonly}
                    options={taskInstanceFieldValues.map(({ id, label }) => ({
                        label: t(label),
                        value: String(id),
                    }))}
                    placeholder={t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_SELECT)}
                    values={taskInstanceFieldValues
                        .filter(({ value }) => value === "true")
                        .map(({ id: dropdownItemId, label }) => ({
                            value: String(dropdownItemId),
                            label,
                        }))}
                    tagOptions={{ clamp: 2, size: "auto" }}
                    isLoading={isSelectorLoading}
                />
            ) : dynamicFieldType === "DROPDOWN_MULTIPLE_COMMON_ASSET" ? (
                <OptionsOutsideSelect
                    onChange={(values) => handleDropDownMultipleChange(id, values)}
                    onDeleteItem={handleDeleteDropDownMultipleItem}
                    label={label}
                    isOptional={!isRequired}
                    errorMessage={hasError(id)}
                    readonly={readonly}
                    options={taskInstanceFieldValues.map(({ id, label }) => ({
                        label: t(label),
                        value: String(id),
                    }))}
                    placeholder={t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_SELECT)}
                    values={taskInstanceFieldValues
                        .filter(({ value }) => value === "true")
                        .map(({ id: dropdownItemId, label }) => ({
                            value: String(dropdownItemId),
                            label,
                        }))}
                    tagOptions={{ clamp: 2, size: "auto" }}
                    isLoading={isSelectorLoading}
                />
            ) : dynamicFieldType === "DATE" ? (
                <DateRangePicker
                    onChange={([date]) => {
                        const dateFormated = date as Date;
                        dateFormated.setHours(0, 0, 0, 0);
                        handleInputChange(id, dateFormated.toString());
                    }}
                    label={label}
                    isOptional={!isRequired}
                    errorMessage={hasError(id)}
                    readonly={readonly}
                    startDate={
                        taskInstanceFieldValues[0].value ? new Date(taskInstanceFieldValues[0].value) : undefined
                    }
                    range={false}
                    placeholder={t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_DATE)}
                />
            ) : (
                isCorrectIncorrectDynamicField(dynamicFieldType) && (
                    <SelectOptions
                        isMulti={false}
                        onChange={({ label }) => handleSelectChange(id, taskInstanceFieldValues, label)}
                        label={label}
                        isOptional={!isRequired}
                        errorMessage={hasError(id)}
                        readonly={readonly}
                        selectedValue={
                            taskInstanceFieldValues.find(({ value }) => value)?.id
                                ? String(taskInstanceFieldValues.find(({ value }) => value === "true")?.id)
                                : ""
                        }
                        options={taskInstanceFieldValues.map(({ id, label }) => ({
                            label: t(label),
                            value: String(id),
                        }))}
                        placeholder={t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_SELECT)}
                        readOnlyIconOptions={selectOptionsReadOnlyIconOptions}
                    />
                )
            )}
        </div>
    );
};
