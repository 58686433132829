import { FC } from "react";

type DocumentalFolderSkelletonProps = {
    quantity?: number;
};
export const DocumentalFolderSkelleton: FC<DocumentalFolderSkelletonProps> = ({ quantity = 10 }) => {
    return (
        <div className="documentalFolderSkelleton">
            {Array.from({ length: quantity }).map((_, index) => (
                <div className="documentalFolder documentalFolderSkelleton__folder" key={index}>
                    <div className="documentalFolder__header">
                        <span className="documentalFolderSkelleton__icon"></span>
                    </div>
                    <div className="documentalFolder__body">
                        <span className="documentalFolderSkelleton__name"></span>
                        <div className="documentalFolder__info">
                            <span className="documentalFolderSkelleton__footer"></span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
