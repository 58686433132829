import { faFolder } from "@fortawesome/pro-regular-svg-icons";
import { IDropDownListItem } from "app/components_v2/Dropdown/types";
import { NotificationIcon } from "app/components_v2/NotificationIcon/NotificationIcon";
import { FC, MouseEvent, useState } from "react";
import { DocumentalMoreOptions } from "../DocumentalMoreOptions/DocumentalMoreOptions";
import { useTranslation } from "react-i18next";
import { DocumentalFolderTranslations } from "app/translation/translationKeys";

type DocumentalFolderProps = {
    name: string;
    date: string;
    totalFiles: number;
    moreOptionsItems?: IDropDownListItem[];
    onClick: () => void;
};

export const DocumentalFolder: FC<DocumentalFolderProps> = ({
    date,
    moreOptionsItems = [],
    name,
    totalFiles,
    onClick,
}) => {
    const { t } = useTranslation();
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
    const [documentalFolderElement, setDocumentalFolderElement] = useState<Element | null>(null);

    const handleClickMoreOptions = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
        e.stopPropagation();
        if (!isPopoverOpen) setIsPopoverOpen(true);
    };

    return (
        <div
            className="documentalFolder"
            onMouseUp={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClick();
            }}
        >
            <div className="documentalFolder__header">
                <NotificationIcon variant="primary" icon={faFolder} size="sm" />
                {moreOptionsItems.filter(({ hidden }) => !hidden).length > 0 && (
                    <DocumentalMoreOptions
                        options={moreOptionsItems.map((item) => ({
                            ...item,
                            onClick: () => {
                                item.onClick && item.onClick();
                                setIsPopoverOpen(false);
                            },
                        }))}
                        onClick={handleClickMoreOptions}
                        isOpen={isPopoverOpen}
                        onResize={() => isPopoverOpen && setIsPopoverOpen(false)}
                        onScroll={() => isPopoverOpen && setIsPopoverOpen(false)}
                        onClickOutside={() => isPopoverOpen && setIsPopoverOpen(false)}
                        setElement={setDocumentalFolderElement}
                        target={documentalFolderElement}
                    />
                )}
            </div>
            <div className="documentalFolder__body">
                <p className="documentalFolder__name">{name}</p>
                <div className="documentalFolder__info">
                    <p className="documentalFolder__date">{date}</p>
                    <span className="documentalFolder__divider">•</span>
                    <p className="documentalFolder__totalFiles">
                        {`${totalFiles} ${
                            totalFiles === 1
                                ? t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_FILE_S)
                                : t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_FILE_P)
                        }`}
                    </p>
                </div>
            </div>
        </div>
    );
};
