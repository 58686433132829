import { FC, useState } from "react";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { DocumentalFileType } from "app/models/07-DOC/DocumentalFileType";
import { DocumentalFileModalHeader } from "./DocumentalFileModalHeader/DocumentalFileModalHeader";
import { DocumentalFileModalBody } from "./DocumentalFileModalBody/DocumentalFileModalBody";
import { SliderModal } from "../base/SliderModal/SliderModal";
import { TaskPillPhoto } from "app/components_v2/TaskPillPhoto/TaskPillPhoto";
import { useTranslation } from "react-i18next";
import { DocumentalFileTranslations } from "app/translation/translationKeys";

export type DocumentalFileModalCreatedBy = {
    initials?: string;
    fullName: string;
    imageUrl?: string;
    id: number;
};

type DocumentalFileModalProps = {
    type: DocumentalFileType;
    name: string;
    createdAt: string;
    size: string;
    dimensions: string;
    createdBy: DocumentalFileModalCreatedBy;
    src: string;
    onClose: () => void;
};

export const DocumentalFileModal: FC<DocumentalFileModalProps> = ({ onClose, src, type, ...rest }) => {
    const { t } = useTranslation();

    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);
    return (
        <>
            {isPreviewModalOpen && (
                <SliderModal onClose={() => setIsPreviewModalOpen(false)}>
                    <div className="taskpill__sliderModal">
                        <TaskPillPhoto
                            taskInstancePhotos={[{ badgeTitle: "", photo: src }]}
                            fullScreen
                            showCarrousel
                            showZoom={false}
                        />
                    </div>
                </SliderModal>
            )}
            <GenericModal
                header={{ title: t(DocumentalFileTranslations.DOCUMENTAL_FILE_MODAL_TITLE), onClose, variant: "appcc" }}
            >
                <div className="documentalFileModal">
                    <DocumentalFileModalHeader src={src} type={type} onZoomClick={() => setIsPreviewModalOpen(true)} />
                    <DocumentalFileModalBody {...rest} type={type} />
                </div>
            </GenericModal>
        </>
    );
};
