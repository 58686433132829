import { Badge } from "app/components_v2/Badge/Badge";
import { UserValoration } from "app/components_v2/UserValoration/UserValoration";
import { multiplyAndFormat } from "app/helpers/Utilities/number/multiplyAndFormat";
import React, { FC } from "react";

type AuditPillStarsProps = {
    starAvg: number;
    grade: number;
};

export const AuditPillStars: FC<AuditPillStarsProps> = ({ grade, starAvg }) => {
    return (
        <div className="auditPillStars">
            <UserValoration
                valoration={starAvg}
                showIcons={false}
                justify="start"
                width="auto"
                variant="gray"
                maxStars={1}
            />
            <div className={`auditPillStars__badge${grade == null ? "--bigWidth" : ""}`}>
                <Badge
                    title={`${multiplyAndFormat(grade ?? 0, 100)}%`}
                    size="sm"
                    variant={
                        grade == null
                            ? "grey"
                            : grade > 0.9
                            ? "green"
                            : grade <= 0.9 && grade > 0.7
                            ? "yellow"
                            : grade <= 0.7 && grade > 0.5
                            ? "orange"
                            : "red"
                    }
                />
            </div>
        </div>
    );
};
