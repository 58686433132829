import { PREPOSITIONS_TO_AVOID } from "app/shared/Constants";

export const getInitials = (input: string): string => {
    const palabras = input.split(" ").filter((x) => !PREPOSITIONS_TO_AVOID.includes(x.toLocaleLowerCase()));

    if (palabras.length >= 2) {
        const primeraLetraPrimeraPalabra = palabras[0].charAt(0).toUpperCase();
        const primeraLetraSegundaPalabra = palabras[1].charAt(0).toUpperCase();

        return primeraLetraPrimeraPalabra + primeraLetraSegundaPalabra;
    } else if (palabras.length === 1) return palabras[0].substring(0, 1).toUpperCase();
    else return "";
};
