import { faCircleXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Label, OptionsOutsideSelect } from "app/components_v2/__inputs";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import {
    DynamicFieldDropDownMultipleOptionsModel,
    DynamicFieldDropDownMultipleValuesModel,
} from "app/models/02-TAR/TaskWizard";
import { TranslationKeys } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type DynamicFieldItemDropdownMultipleProps = {
    id: string;
    checked?: boolean;
    errorMessage?: string;
    disabled?: boolean;
    options: DynamicFieldDropDownMultipleOptionsModel[];
    values: DynamicFieldDropDownMultipleValuesModel[];
    hideDeleteButton?: boolean;
    onChangeCheckbox?: (value: boolean) => void;
    onDeleteItem: () => void;
    onChange: (selectedValue: OptionModel) => void;
    onDeleteValue: (itemId: string) => void;
};

export const DynamicFieldItemDropdownMultiple: FC<DynamicFieldItemDropdownMultipleProps> = ({
    id,
    checked,
    disabled,
    errorMessage,
    onChangeCheckbox,
    onChange,
    options,
    onDeleteItem,
    onDeleteValue,
    hideDeleteButton,
    values,
}) => {
    const { t } = useTranslation();

    const showCheckbox = checked !== undefined && !!onChangeCheckbox;

    const handleChange = (values: OptionModel[]) => {
        if (disabled) return;
        const selectedValue = values.at(-1);
        if (!selectedValue) return;
        onChange(selectedValue);
    };
    const handleDeleteValue = (optionId: string) => {
        if (disabled) return;
        onDeleteValue(optionId);
    };
    const handleDelete = () => {
        if (disabled) return;
        onDeleteItem();
    };

    return (
        <div className="dynamicFieldItem">
            <div className="dynamicFieldItem__container">
                <div className="dynamicFieldItem__labels">
                    <Label
                        label={t(TranslationKeys.DATA_TO_REPORT_DYNAMIC_DROPDOWN_MULTIPLE_ASSETS_RANGE)}
                        disabled={disabled}
                        size="sm"
                    />
                    {showCheckbox && (
                        <CheckBox
                            checked={checked}
                            onChange={onChangeCheckbox}
                            label={t(TranslationKeys.DATA_TO_REPORT_DYNAMIC_REQUIRED)}
                            htmlFor={`required-checkbox-${id}`}
                            size="sm"
                            disabled={disabled}
                        />
                    )}
                </div>
                <div className="dynamicFieldItem__input">
                    <OptionsOutsideSelect
                        onChange={handleChange}
                        onDeleteItem={handleDeleteValue}
                        options={options.map(({ id, label }) => ({ label, value: String(id) }))}
                        values={values
                            .filter(({ isDeleted }) => !isDeleted)
                            .map(({ value, label }) => ({ label, value }))}
                        disabled={disabled}
                        showBorderError={!!errorMessage?.length}
                    />
                    {!disabled && !hideDeleteButton && (
                        <FontAwesomeIcon
                            className="deleteInputIcon dynamicFieldItemDropdownMultiple__icon"
                            onClick={handleDelete}
                            icon={faCircleXmark}
                        />
                    )}
                </div>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            </div>
        </div>
    );
};
