import { DashboardUserInfo } from "../NewDashboard/types";

export const INITIAL_USER_INFO_VALUES: DashboardUserInfo = {
    fullName: "",
    starRatingAVG: 0,
    total: 0,
    trend: 0,
    userId: 0,
    isLoading: true,
    avatarImg: "",
    isSupervisor: false,
    text: "",
    areStarsClickable: false,
    hexColor: "",
};
