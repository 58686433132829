import { Avatar } from "app/components_v2/Avatar/Avatar";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { FC } from "react";
import { SingleValueProps, components } from "react-select";

export const SelectOptionsSingleValueWithAvatar: FC<SingleValueProps<OptionModel>> = ({ children, ...props }) => {
    const { data, isMulti } = props;
    const { profilePictureURL, value, label, initials, avatarHexColor } = data;
    if (value === "-1" || value === "" || !initials?.length || isMulti)
        return <components.SingleValue {...props}>{children}</components.SingleValue>;

    return (
        <components.SingleValue {...props}>
            <div className={`selectOptionsSingleValueWithAvatar ${props.className}`}>
                <Avatar
                    colorId={!!value.length && !avatarHexColor?.length ? Number(value) : undefined}
                    hexColor={avatarHexColor}
                    img={profilePictureURL}
                    size="xs"
                    avatarName={label}
                    nameInitials={initials}
                />
                {label}
            </div>
        </components.SingleValue>
    );
};
