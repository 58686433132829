import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";

export const groupTaskInstanceFieldsByAssetId = (fields: TaskInstanceFieldModel[]): TaskInstanceFieldModel[][] => {
    const groupedObjects: Record<string, TaskInstanceFieldModel[]> = {};

    // Group by Asset
    fields.forEach((field) => {
        const id = field.asset2?.id;
        if (id == null) return;
        if (!groupedObjects[id]) groupedObjects[id] = [];
        groupedObjects[id].push(field);
    });

    // Map to array and sort each Asset
    const fieldsMapped = Object.values(groupedObjects)
        .map((values) =>
            values.sort((field1, field2) => {
                // Order by NUMBER first, then TEXT
                if (field1.dynamicFieldType === "NUMBER" && field2.dynamicFieldType === "TEXT") return -1;
                if (field1.dynamicFieldType === "TEXT" && field2.dynamicFieldType === "NUMBER") return 1;

                // Order first by inserted order
                const orderComparison = (field1.order ?? 0) - (field2.order ?? 0);
                if (orderComparison !== 0) return orderComparison;

                return 0;
            })
        )
        // Sort groups by the lowest order value in each group
        .sort((groupA, groupB) => {
            const minOrderA = groupA[0]?.order ?? 0;
            const minOrderB = groupB[0]?.order ?? 0;
            return minOrderA - minOrderB;
        });

    return fieldsMapped;
};
