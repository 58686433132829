import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CompanyTranslations, QRTranslations } from "app/translation/translationKeys";
import { faCalendarXmark } from "@fortawesome/pro-regular-svg-icons";
import { SecScreen } from "app/shared/Constants";
import { hasPermission } from "app/routes/HelperRoleBasedAccess";
import { Badge } from "app/components_v2/Badge/Badge";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { QRManagementDto } from "app/dtos/01-SEG/QRManagementDto/QRManagementDto";
import { getTotalClossedDates } from "app/helpers/closeDates/getTotalClosedDates";

type MobileQRGridRowProps = {
    data: QRManagementDto;
    onCloseQR: () => void;
};

export const MobileQRGridRow: FC<MobileQRGridRowProps> = ({ data, onCloseQR }) => {
    const { t } = useTranslation();

    const { name, status, closeQr } = data;
    const clossedDates = getTotalClossedDates(closeQr);
    const totalClosures = clossedDates?.length || 0;

    return (
        <div className="mobileQRGridRow">
            <div className="mobileQRGridRow__header">
                <p className="mobileQRGridRow__name">{name}</p>
                <div className="mobileQRGridRow__icons">
                    <Badge title={t(status)} variant={status === "CLOSED" ? "red" : "green"} />
                    <CellIcons
                        icons={[
                            {
                                icon: faCalendarXmark,
                                onClick: onCloseQR,
                                title: t(QRTranslations.CLOSE_QR),
                                hidden: !hasPermission(SecScreen.QR_MANAGEMENT),
                            },
                        ]}
                    />
                </div>
            </div>
            <p className="mobileCompanyGridBody__body__totalCloures">
                {!!totalClosures
                    ? `${t(CompanyTranslations.TOTAL_CLOSURES).replace("{0}", totalClosures.toString())}`
                    : t(CompanyTranslations.NO_CLOSURES)}
            </p>
        </div>
    );
};
