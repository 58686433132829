import { FC, useEffect, useState } from "react";
import { useSession, useTitle } from "app/hooks";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { CompanyRankingProps, DateRange, getDataProps } from "../../types";
import RankingService from "app/services/03-REP/RankingService";
import { RankingLayout } from "app/components_v2/Layout/RankingLayout/RankingLayout";
import { TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { PaginationDefaults, PrivatePaths, SecScreen } from "app/shared/Constants";
import { RankingSortBy } from "app/components_v2/Layout/RankingLayout/RankingHeader/RankingHeader";
import { getRankingExtraParam } from "app/helpers/__extraParams/analist/getRankingExtraParam";
import { useHeaderTitle } from "app/hooks/useHeaderTitle";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { useBreadcrumbs } from "../../state/BreadcrumbsProvider";
import { useUserType } from "app/hooks/useUserType";
import { useNavigateToInitialScreen } from "app/hooks/useNavigateToInitialScreen";
import { rankingExtraParams } from "../../helpers/rankingExtraParams";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";
import { EntityRankingModel } from "app/models/03-REP/Ranking/EntityRankingModel";

export const RankingDepartment: FC<CompanyRankingProps> = ({
    startDate: startDateProp,
    endDate: endDateProp,
    onDateChange,
    onSelectedDepartment,
}) => {
    const { t } = useTranslation();
    const session = useSession();
    const nav = useNavigate();
    const { onChangeHeaderTitle } = useHeaderTitle();
    const { updateBreadcrumbs, breadcrumbs } = useBreadcrumbs();
    const { isSupervisor, isAnalist, isAdminClient } = useUserType();
    const { id } = useParams();
    const { navigateToInitialScreen } = useNavigateToInitialScreen();
    const { changeWorkingCompanyByCompanyId } = useChangeWorkingSession();

    !!id && useTitle(t(TranslationTitles.DEPARTMENT_RANKING_PAGE_TITLE));

    const [title, setTitle] = useState<string>("");
    const [startDate, setStartDate] = useState<Date>(startDateProp);
    const [endDate, setEndDate] = useState<Date | undefined>(endDateProp);
    const [sortBy, setSortBy] = useState<RankingSortBy>("STAR_ASC");
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [isHeaderLoading, setIsHeaderLoading] = useState<boolean>(true);
    const [data, setData] = useState<EntityRankingModel[]>([]);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);

    const handleDateChange = (dates: DateRange) => {
        const [start, end] = dates;
        if (start) {
            setStartDate(start);
            setEndDate(undefined);
            onDateChange({ startDate: start, endDate: undefined });
        }
        if (end) {
            setEndDate(end);
            onDateChange({ endDate: end });
        }
    };
    const getData = async ({ query, pageI }: getDataProps) => {
        setLoading(true);
        const { sortDirection, sortField } = getRankingExtraParam(sortBy);
        const hasIdParam = id !== undefined;

        const { data, error, status } = !hasIdParam
            ? await RankingService.GetAllDepartmentRanking({
                  pageIndex: pageI ? pageI - 1 : 0,
                  pageSize: PaginationDefaults.PAGE_SIZE,
                  extraParams: rankingExtraParams({ startDate, endDate }),
                  query,
                  sortDirection,
                  sortField,
              })
            : await RankingService.GetAllDepartmentRanking({
                  pageIndex: pageI ? pageI - 1 : 0,
                  pageSize: PaginationDefaults.PAGE_SIZE,
                  extraParams: rankingExtraParams({ startDate, endDate, companyId: id }),
                  query,
                  sortDirection,
                  sortField,
              });
        if (!status() || data.list === null) {
            console.error(error);
            setLoading(false);
            setIsHeaderLoading(false);
            setData([]);
            setTotal(0);
            return;
        }
        firstLoad &&
            data?.list?.length === 1 &&
            session?.user.userType === "ANALIST" &&
            nav(`/${PrivatePaths.COMPANY_RANKING}/team/${data.list[0].id}`);

        setData(data.list);
        setFirstLoad(false);
        setIsHeaderLoading(false);

        !id
            ? updateBreadcrumbs({
                  name: `${t(TranslationKeys.GLOBAL_RANKING_DEPARTMENTS)}`,
              })
            : updateBreadcrumbs({
                  name: `${t(TranslationTitles.RANKING_DEPARTMENT_TITLE).replace("{1}", data.title || "")}`,
              });

        setTotal(data.count);
        !id
            ? setTitle(t(TranslationKeys.GLOBAL_RANKING_DEPARTMENTS))
            : setTitle(
                  `${t(TranslationTitles.RANKING_DEPARTMENT_TITLE).replace(
                      "{1}",
                      data.title || t(TranslationTitles.DEPARTMENT_TITLE)
                  )}`
              );
        setLoading(false);
    };

    const urlAnalistSupervisor = (id: number) => {
        isSupervisor
            ? nav(`/${PrivatePaths.COMPANY_RANKING}/ranking-users/${id}`)
            : nav(`/${PrivatePaths.COMPANY_RANKING}/team/${id}`);
    };

    const onClick = (rank: EntityRankingModel) => {
        if (rank.companyId) changeWorkingCompanyByCompanyId(rank.companyId);
        onSelectedDepartment && onSelectedDepartment(rank);
        urlAnalistSupervisor(rank?.id);
    };

    useEffect(() => {
        if (endDate === undefined) return;
        getData({ pageI: 0 });
        setPageIndex(0);
    }, [startDate, endDate, sortBy]);

    useEffect(() => {
        if (!hasPermissionToView(session, SecScreen.COMPANY_RANKING)) navigateToInitialScreen();

        if (!!title.length) {
            onChangeHeaderTitle(title);
        }
    }, [title]);

    return (
        <RankingLayout
            breadcrumbs={isAnalist || isAdminClient ? breadcrumbs : undefined}
            startDate={startDate}
            endDate={endDate}
            onDateChange={handleDateChange}
            onPressEnter={(query) => getData({ query, pageI: 0 })}
            title={title}
            data={data}
            pageIndex={pageIndex}
            onPageIndexChange={(pi) => {
                setPageIndex(pi);
                getData({ pageI: pi });
            }}
            rankingTypePages="DEPARTMENT"
            onClickPill={onClick}
            total={total}
            isLoading={loading}
            headerLoading={isHeaderLoading}
            sortBy={sortBy}
            onChangeSortBy={setSortBy}
        />
    );
};
