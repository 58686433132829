import { faUpRightAndDownLeftFromCenter } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DocumentalFileType } from "app/models/07-DOC/DocumentalFileType";
import { FC } from "react";

type DocumentalFileModalHeaderProps = {
    type: DocumentalFileType;
    src: string;
    onZoomClick: () => void;
};

export const DocumentalFileModalHeader: FC<DocumentalFileModalHeaderProps> = ({ src, type, onZoomClick }) => {
    if (type !== "Image" && type !== "Audio") return null;

    if (type === "Image")
        return (
            <div className="documentalFileModalHeader__imagePreview">
                <img src={src} className="documentalFileModalHeader__image" />
                <div className="documentalFileModalHeader__zoom" onClick={onZoomClick}>
                    <FontAwesomeIcon
                        className="documentalFileModalHeader__zoom__icon"
                        icon={faUpRightAndDownLeftFromCenter}
                    />
                </div>
            </div>
        );

    // Audio
    return (
        <div className="documentalFileModalHeader__audio">
            <audio controls src={src} controlsList="nodownload" className="documentalFileModalHeader__audio__content" />
        </div>
    );
};
