import { SupervisorAnalistColors } from "app/shared/types/SupervisorColorTypes";
import { LegendItem } from "./LegendItem/LegendItem";
import { FC } from "react";

export type LegendItem = {
    label: string;
    percentage?: number;
    color: SupervisorAnalistColors;
    onClick?: () => void;
};

type LegendProps = {
    items: LegendItem[];
};

export const Legend: FC<LegendProps> = ({ items }) => {
    return (
        <div className="legend">
            {items.map((auditItem, i) => (
                <LegendItem key={i} {...auditItem} />
            ))}
        </div>
    );
};
