import { isHoursEquals } from "app/helpers/__validates/task/validateHours";
import { hasOverlappingRanges } from "app/helpers/__validates/validateOverlappingRanges";
import { validateHours } from "app/helpers/__validates/task/validateTaskHours";
import { CustomErrorTaskTemporalityModel, TaskTemporalityDateRangeError } from "app/models/02-TAR/Task/EditTask";
import { WizardTaskStep4 } from "app/models/02-TAR/TaskWizard";
import { DateRange } from "app/models/utilities";
import { NUMBER_OF_MONTHS_IN_A_YEAR, NUMBER_OF_WEEK_IN_A_YEAR } from "app/shared/Constants";
import {
    TaskTranslations,
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
} from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

type IUseValidateEditTaskTaskTemporality = {
    data: WizardTaskStep4;
    errors: TaskTemporalityDateRangeError;
    onErrorsChange: (values: Partial<CustomErrorTaskTemporalityModel>) => void;
};

export const useValidateEditTaskTaskTemporality = ({
    data,
    errors,
    onErrorsChange,
}: IUseValidateEditTaskTaskTemporality) => {
    const { t } = useTranslation();

    const { temporalityType, finish, taskStart, taskHours, recurringTask, taskTemporalityDateRange } = data;

    const validateTemporality = (): boolean => {
        if (temporalityType === "PERIODICAL") return validatePeriodicalTemporality();
        if (temporalityType === "ONE_DAY") return validateOneDayTemporality();
        if (temporalityType === "SPORADIC") return validateSporadicTemporality();
        if (temporalityType === "RANGE_DATES") return validateDateRangeTemporality();
        if (temporalityType === "NONE") return validateNoneTemporality();
        return true;
    };

    const validateNoneTemporality = () => {
        onErrorsChange({ temporalityType: t(TranslationCommon.SELECT_OPTION) });
        return false;
    };

    const validateTaskEnds = () => {
        let check = true;
        if (finish.checked === "el" && !finish.value) {
            onErrorsChange({ finishEl: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }

        if (finish.checked === "after" && typeof finish.value === "number" && finish.value <= 0) {
            onErrorsChange({ finishResp: t(TranslationKeys.MINIMUM_VALUE).replace("{1}", "1") });
            check = false;
        }

        return check;
    };

    const validatePeriodicalTemporality = (): boolean => {
        let check = true;

        if (!taskStart.length) {
            onErrorsChange({ taskStart: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }
        check = validateTaskEnds();

        if (!recurringTask) return check;

        const taskHoursErrors = validateHours(taskHours, t);
        onErrorsChange({ taskHours: taskHoursErrors });
        if (taskHoursErrors.value.length || taskHoursErrors.errors.length) check = false;

        if (isHoursEquals(taskHours)) {
            onErrorsChange({ taskHours: t(TranslationKeys.INVALID_HOURS_SAME_HOURS) });
            check = false;
        }
        if (recurringTask.isRecurrent && recurringTask.value === "custom" && recurringTask.custom.repeatEvery <= 0) {
            onErrorsChange({ customReps: t(TranslationKeys.MINIMUM_VALUE).replace("{1}", "1") });
            check = false;
        }

        if (
            recurringTask.isRecurrent &&
            recurringTask.value === "custom" &&
            recurringTask.custom.customValue === "week" &&
            !recurringTask.custom.days.some(({ isActive }) => isActive === true)
        ) {
            onErrorsChange({ customDaysBubles: t(TranslationKeys.SELECT_ONE_DAY) });
            check = false;
        }

        if (
            recurringTask.isRecurrent &&
            recurringTask.value === "custom" &&
            recurringTask.custom.customValue === "everyMonth" &&
            !recurringTask.custom.selectedOptions.value.length
        ) {
            onErrorsChange({ customMonth: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
            check = false;
        }
        return check;
    };

    const validateOneDayTemporality = (): boolean => {
        let check = true;
        if (!taskStart.length) {
            onErrorsChange({ taskStart: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }
        const taskHoursErrors = validateHours(taskHours, t);
        onErrorsChange({ taskHours: taskHoursErrors });
        if (taskHoursErrors.value.length || taskHoursErrors.errors.length) check = false;

        return check;
    };

    const validateSporadicTemporality = (): boolean => {
        let check = true;

        if (!taskStart.length) {
            onErrorsChange({ taskStart: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }
        return check;
    };

    const validateDateRangeTemporality = (): boolean => {
        let check = true;
        const { repeatEvery, value } = taskTemporalityDateRange;

        if (value === "CUSTOM") return validateDateRangeCustom() ? check : false;

        if (!repeatEvery.length) {
            onErrorsChange({ temporalityDateRangeRepeatEvery: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }
        if (check && Number(repeatEvery) <= 0) {
            onErrorsChange({ temporalityDateRangeRepeatEvery: t(TranslationKeys.MINIMUM_VALUE).replace("{1}", "1") });
            check = false;
        }
        if (value === "WEEKLY") return validateDateRangeWeekly() ? check : false;
        if (value === "MONTHLY") return validateDateRangeMonthly() ? check : false;

        return check;
    };

    const validateDateRangeWeekly = (): boolean => {
        let check = true;
        const { weeklyDays, repeatEvery } = taskTemporalityDateRange;
        if (!!repeatEvery && Number(repeatEvery) > NUMBER_OF_WEEK_IN_A_YEAR) {
            onErrorsChange({
                temporalityDateRangeRepeatEvery: t(TranslationErrors.TASK_TEMPORALITY_WEEK_GREATER_THAN),
            });
            check = false;
        }

        const totalSelectedDays = weeklyDays.filter(({ isActive }) => isActive === true).length;

        if (totalSelectedDays < 2) {
            onErrorsChange({
                temporalityDateRange: { ...errors, weekly: t(TranslationErrors.TASK_TEMPORALITY_SELECT_A_RANGE) },
            });
            check = false;
        }

        return check;
    };

    const validateDateRangeMonthly = (): boolean => {
        let check = true;
        const { monthly, repeatEvery } = taskTemporalityDateRange;
        const { value: monthlyValue } = monthly;

        if (!!repeatEvery && Number(repeatEvery) > NUMBER_OF_MONTHS_IN_A_YEAR) {
            onErrorsChange({
                temporalityDateRangeRepeatEvery: t(TranslationErrors.TASK_TEMPORALITY_MONTH_GREATER_THAN),
            });
            check = false;
        }

        if (monthlyValue === "FIRST_X_DAYS" || monthlyValue === "LAST_X_DAYS")
            return validateDateRangeMonthlyDays() ? check : false;
        if (monthlyValue === "CUSTOM") return validateDateRangeMonthlyCustom() ? check : false;

        return check;
    };

    // Validate Day input for First X Days or Last X Days Option
    const validateDateRangeMonthlyDays = () => {
        const { monthly } = taskTemporalityDateRange;
        const { day } = monthly;
        const numericDay = Number(day);
        if (!day.length) {
            onErrorsChange({ temporalityDateRange: { ...errors, monthlyDay: t(TranslationCommon.INPUT_NOT_EMPTY) } });
            return false;
        }
        if (numericDay < 2) {
            onErrorsChange({
                temporalityDateRange: {
                    ...errors,
                    monthlyDay: t(TranslationErrors.TASK_TEMPORALITY_SELECT_A_RANGE),
                },
            });
            return false;
        }

        if (numericDay <= 0 || numericDay > 31) {
            onErrorsChange({
                temporalityDateRange: {
                    ...errors,
                    monthlyDay: t(TranslationErrors.TASK_TEMPORALITY_MONTH_BETWEEN)
                        .replace("{0}", "1")
                        .replace("{1}", "31"),
                },
            });
            return false;
        }
        return true;
    };

    const validateDateRangeMonthlyCustom = () => {
        let check = true;
        const { monthly } = taskTemporalityDateRange;
        const { customDays } = monthly;
        const { startDay, endDay } = customDays;
        const numericStartDay = Number(startDay);
        const numericEndDay = Number(endDay);

        onErrorsChange({
            temporalityDateRange: {
                ...errors,
                monthlyStartDay: !startDay ? t(TranslationCommon.INPUT_NOT_EMPTY) : "",
                monthlyEndDay: !endDay ? t(TranslationCommon.INPUT_NOT_EMPTY) : "",
            },
        });

        check = !!startDay && !!endDay;

        if (check && Number(startDay) > Number(endDay)) {
            onErrorsChange({
                temporalityDateRange: {
                    ...errors,
                    monthlyEndDay: t(TranslationErrors.START_DAY_GREATER_THAN_END_DAY),
                },
            });
            check = false;
        }

        const isStartOrEndDayInvalid = [startDay, endDay].some((day) => {
            const numericDay = Number(day);
            return numericDay <= 0 || numericDay > 31 || isNaN(numericDay);
        });

        if (check && isStartOrEndDayInvalid) {
            onErrorsChange({
                temporalityDateRange: {
                    ...errors,
                    monthlyStartDay:
                        numericStartDay <= 0 || numericStartDay > 31 || isNaN(numericStartDay)
                            ? t(TranslationErrors.TASK_TEMPORALITY_MONTH_BETWEEN)
                                  .replace("{0}", "1")
                                  .replace("{1}", "31")
                            : "",
                    monthlyEndDay:
                        numericEndDay <= 0 || numericEndDay > 31 || isNaN(numericEndDay)
                            ? t(TranslationErrors.TASK_TEMPORALITY_MONTH_BETWEEN)
                                  .replace("{0}", "1")
                                  .replace("{1}", "31")
                            : "",
                },
            });
            check = false;
        }

        if (check && numericStartDay === numericEndDay) {
            onErrorsChange({
                temporalityDateRange: {
                    ...errors,
                    monthlyEndDay: t(TranslationErrors.TASK_TEMPORALITY_SELECT_A_RANGE),
                },
            });

            check = false;
        }

        return check;
    };

    const validateDateRangeCustom = () => {
        let check = true;
        const { custom } = taskTemporalityDateRange;
        const taskTemporalityDateRangeFiltered = custom.dateRange.filter(({ isDeleted }) => !isDeleted);

        if (!taskTemporalityDateRangeFiltered.length) {
            onErrorsChange({ dateRange: t(TranslationCommon.INPUT_NOT_EMPTY) });
            return false;
        }

        const dates: DateRange[] = taskTemporalityDateRangeFiltered.map(({ startDate, endDate }) => [
            new Date(startDate),
            new Date(endDate),
        ]);

        if (hasOverlappingRanges(dates)) {
            onErrorsChange({ dateRange: t(TaskTranslations.DATE_OVERLAPING) });
            check = false;
        }

        return check;
    };

    return { validateTemporality, validateTaskEnds };
};
