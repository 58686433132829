import { FC } from "react";

type LegendItemSkelletonProps = {
    hasPercentage?: boolean;
    quantity?: number;
};

export const LegendItemSkelleton: FC<LegendItemSkelletonProps> = ({ hasPercentage, quantity = 3 }) => {
    return (
        <>
            {Array.from({ length: quantity }).map((_, key) => (
                <div className="legendItem legendItemSkelleton" key={key}>
                    <div className="legendItem__info">
                        <div className="legendItemSkelleton__mark"></div>
                        <p className="legendItem__text legendItemSkelleton__text"></p>
                    </div>
                    {hasPercentage && <div className="legendItem__percentatge legendItemSkelleton__percentage"></div>}
                </div>
            ))}
        </>
    );
};
