import { Base64TypesModel } from "./types";

export const Base64Types: Record<Base64TypesModel, Base64TypesModel> = {
    audio: "audio",
    application: "application",
    image: "image",
    video: "video",
    pdf: "pdf",
    doc: "doc",
    xls: "xls",
    ppt: "ppt",
    txt: "txt",
    zip: "zip",
    rar: "rar",
    other: "other",
};
