import { Button } from "app/components_v2/__buttons/Button/Button";
import { FC, useState } from "react";
import { EditAuditLegendModal } from "app/pages/05-QUA/Audit/EditAudit/EditAuditLegendModal/EditAuditLegendModal";
import { faListUl, faCheck, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { AuditTranslation } from "app/translation/translationKeys";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { AuditGroupCheckAllValues } from "../../types";

export type PercentageProps = {
    completedPercentatge: number;
    notSpecifiedPercentatge: number;
    nonConformityPercentatge: number;
    notApplyPercentatge: number;
};

type AuditGroupCheckListModalActionsProps = {
    percentatges: PercentageProps;
    readonly?: boolean;
    onCheckAll: (value: AuditGroupCheckAllValues) => void;
};

export const AuditGroupCheckListModalActions: FC<AuditGroupCheckListModalActionsProps> = ({
    percentatges,
    readonly,
    onCheckAll,
}) => {
    const { t } = useTranslation();

    const [isLegendModalOpen, setIsLegendModalOpen] = useState<boolean>(false);

    const roundPercentatges = () => {
        let updatedNumbers = Object.values(percentatges).map((num) => Math.round(num));
        const total = updatedNumbers.reduce((a, b) => a + b, 0);
        if (total > 100) {
            const maxNumber = Math.max(...updatedNumbers);
            updatedNumbers = updatedNumbers.map((num) => (num === maxNumber ? num - 1 : num));
        }

        const percentageRounded: PercentageProps = {
            completedPercentatge: updatedNumbers[0],
            nonConformityPercentatge: updatedNumbers[1],
            notApplyPercentatge: updatedNumbers[2],
            notSpecifiedPercentatge: updatedNumbers[3],
        };

        return percentageRounded;
    };

    return (
        <>
            {isLegendModalOpen && (
                <EditAuditLegendModal onClose={() => setIsLegendModalOpen(false)} percentatge={roundPercentatges()} />
            )}
            <div className="auditGroupCheckListModalActions">
                <div className="auditGroupCheckListModalActions__button">
                    <Button
                        variant="appcc"
                        iconLeft={faListUl}
                        type="link"
                        onClick={() => setIsLegendModalOpen(true)}
                        text={t(AuditTranslation.AUDIT_SHOW_LEGEND)}
                    />
                </div>
                {!readonly && (
                    <div className="auditGroupCheckListModalActions__checkboxes">
                        <p className="auditGroupCheckListModalActions__checkAll">
                            {t(AuditTranslation.AUDIT_CHECK_ALL)}
                        </p>
                        <div className="auditGroupCheckListModalActions__checkboxes__container">
                            <CheckBox
                                checked={false}
                                onChange={() => !readonly && onCheckAll("OK")}
                                color="green"
                                rounded
                                size="xl"
                                theme="light"
                                innerIcon={faCheck}
                                readonly={readonly}
                            />
                            <CheckBox
                                checked={false}
                                onChange={() => !readonly && onCheckAll("NA")}
                                color="gray"
                                rounded
                                size="xl"
                                theme="light"
                                innerIcon={faXmark}
                                readonly={readonly}
                            />
                        </div>
                    </div>
                )}
                {/* <div className="auditGroupCheckListModalActions__comment"></div> */}
            </div>
        </>
    );
};
