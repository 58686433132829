import { fillTaskInstanceExtraParams } from "app/helpers/__extraParams/taskinstance/taskInstanceFillExtraParams";
import { useSession } from "app/hooks";
import { TranslationCommon } from "app/translation/translationKeys";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DashboardType } from "../types";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { getInitials } from "app/helpers/Avatar/getInitials";

type DataProps = {
    companyId: string;
    type: DashboardType;
};

export const useGetUserDeptSub = () => {
    const [users, setUsers] = useState<OptionModel[]>([]);
    const [departments, setDepartments] = useState<OptionModel[]>([]);
    const [subdepartments, setSubDepartments] = useState<OptionModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>("");
    const { t } = useTranslation();

    const session = useSession();

    const getUserDeptSub = async ({ companyId, type }: DataProps) => {
        if (companyId === "-1") {
            setUsers([]);
            setDepartments([]);
            setSubDepartments([]);

            return;
        }
        if (type === "MYTASKS") {
            getUser(Number(companyId));
            getDeptSubdept(Number(companyId));
            return;
        }
    };

    const getUser = async (companyId: number) => {
        setIsLoading(true);
        const { data, status } = await SegSelectorService.GetUserNamesWithIds({
            extraParams: fillTaskInstanceExtraParams({
                supervisorId: session?.user.id,
                companyId,
            }),
        });

        if (!status()) {
            setError(t(TranslationCommon.SOMETHING_WENT_WRONG));
            setIsLoading(false);
            return;
        }
        const ownLabel = `${session?.user.firstName} ${session?.user.lastName}`;
        setUsers([
            {
                value: session?.user.id.toString() || "",
                label: `${session?.user.firstName} ${session?.user.lastName}`,
                profilePictureURL: session?.user.profilePictureURL,
                initials: getInitials(ownLabel),
            },
            ...data,
        ]);
        setIsLoading(false);
    };

    const getDeptSubdept = async (companyId: number) => {
        setIsLoading(true);
        const departmentsQuery = SegSelectorService.GetDepartmentNamesWithIds({
            extraParams: fillTaskInstanceExtraParams({
                supervisorId: session?.user.id,
                companyId,
            }),
        });

        // TODO: --SUBDEPARTMENT--
        // const subdepartmentsQuery = SegSelectorService.GetSubDepartmentNamesWithIds({
        //     extraParams: fillTaskInstanceExtraParams({
        //         supervisorId: session?.user.id,
        //         companyId,
        //     }),
        // });

        // Promise.all([departmentsQuery, subdepartmentsQuery]).then(([departmentsSr, subdepartmentsSr]) => {
        //     if (!departmentsSr.status() && !subdepartmentsSr.status()) {
        Promise.all([departmentsQuery]).then(([departmentsSr]) => {
            if (!departmentsSr.status()) {
                setError(t(TranslationCommon.SOMETHING_WENT_WRONG));
                setIsLoading(false);
                return;
            }

            setDepartments(departmentsSr.data);
            // setSubDepartments(subdepartmentsSr.data);
            setIsLoading(false);
        });
    };

    return {
        getUserDeptSub,
        isLoading,
        error,
        users,
        departments,
    };
};
