import { getAssetTaskfieldMinMaxPlaceholder } from "app/helpers/Asset/getAssetTaskfieldMinMaxPlaceholder";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export type IssueBodyAssetFieldProps = {
    fieldValue?: string;
    name: string;
    min: number | null;
    max: number | null;
    measureUnit: string;
};

export const IssueBodyAssetField: FC<IssueBodyAssetFieldProps> = ({ max, measureUnit, min, fieldValue, name }) => {
    const { t } = useTranslation();

    const getAssetField = () => {
        const issueAssetField = `${name}: ${fieldValue?.length ? `${fieldValue} ${measureUnit}` : ""}`;
        const minMaxPlaceholder = getAssetTaskfieldMinMaxPlaceholder({ max, measureUnit, min, t });

        return `${issueAssetField} (${minMaxPlaceholder})`;
    };

    return <li className="issueBodyAssetField">{getAssetField()}</li>;
};
