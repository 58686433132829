import { FC, useState } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { DocumentalFileModal } from "app/components_v2/__modals/DocumentalFileModal/DocumentalFileModal";

export const DocumentalFileModalSection: FC<SectionsShowProp> = ({ show }) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isVisibleAudio, setIsVisibleAudio] = useState<boolean>(false);
    return (
        <Section title="DocumentalFileModalSection" show={show}>
            <button onClick={() => setIsVisible(true)}>Open Modal Foto</button>
            <button onClick={() => setIsVisibleAudio(true)}>Open Modal Audio</button>
            {isVisible && (
                <DocumentalFileModal
                    type="Image"
                    onClose={() => setIsVisible(false)}
                    createdAt="24 de Enero de 2025 a las 11:33 h"
                    createdBy={{
                        fullName: "Juan Perez",
                        initials: "JP",
                        id: 178,
                    }}
                    dimensions="200x200"
                    name="Archivo de prueba"
                    size="200 KB"
                    src="https://az4linkblobstorage.blob.core.windows.net/az-4link-blob-storage/dd9079d5-20ab-44f5-a8f3-ba27080633e9.png"
                />
            )}

            {isVisibleAudio && (
                <DocumentalFileModal
                    type="Audio"
                    onClose={() => setIsVisibleAudio(false)}
                    createdAt="24 de Enero de 2025 a las 11:33 h"
                    createdBy={{
                        fullName: "Juan Perez",
                        initials: "JP",
                        id: 178,
                    }}
                    dimensions="200x200"
                    name="Archivo de prueba"
                    size="200 KB"
                    src="https://file-examples.com/storage/feb114c26367c53d69358a1/2017/11/file_example_MP3_700KB.mp3"
                />
            )}
        </Section>
    );
};
