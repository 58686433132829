import { AddDepartmentModalErrorFormValues, AddDepartmentModalFormValues } from "../types";

export const ADD_DEPARTMENT_MODAL_INITIAL_FORM_VALUES: AddDepartmentModalFormValues = {
    fK_DepartmentName: "-1",
    hexColor: "",
};

export const ADD_DEPARTMENT_MODAL_INITIAL_ERROR_FORM_VALUES: AddDepartmentModalErrorFormValues = {
    errorDepartmentName: "",
    errorHexColor: "",
};
