import { CounterPillType } from "app/components_v2/RankingPill/types";

export const getTaskType = (status: string, total: number): CounterPillType => {
    if (total === 0) return "default";

    const statusMap: Record<string, CounterPillType> = {
        TIMED_OUT: "danger",
        OUT_OF_TIME: "yellow",
    };

    return statusMap[status] || "success";
};
