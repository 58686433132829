import { FC, useEffect, useState } from "react";
import { PaginationIcon } from "./PaginationIcon/PaginationIcon";
import { faChevronLeft, faChevronsRight, faChevronRight, faChevronsLeft } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { PaginationDefaults } from "app/shared/Constants";
import { TabHeaderVariants } from "../__containers/TabHeader/types";

export type PaginationProps = {
    total: number;
    pageSize?: number;
    pageIndex: number;
    variant?: TabHeaderVariants;
    onClick: (pageIndex: number) => void;
};

const Pagination: FC<PaginationProps> = ({
    pageIndex,
    pageSize = PaginationDefaults.PAGE_SIZE,
    total,
    onClick,
    variant = "primary",
}) => {
    const { t } = useTranslation();

    const [totalPageIndex, setTotalPageIndex] = useState(Math.ceil(total / pageSize));

    useEffect(() => {
        const totalPages = Math.ceil(total / pageSize);
        setTotalPageIndex(totalPages === 0 ? 1 : totalPages);
    }, [pageIndex, total]);

    return (
        <div className="pagination">
            <PaginationIcon
                icon={faChevronsLeft}
                isDisabled={pageIndex <= 1}
                onClick={() => onClick(1)}
                variant={variant}
            />
            <PaginationIcon
                icon={faChevronLeft}
                isDisabled={pageIndex <= 1}
                onClick={() => onClick(pageIndex - 1)}
                variant={variant}
            />
            <p className="pagination__details">
                {`${pageIndex} ${t(TranslationKeys.PAGINATION_DE)} ${totalPageIndex}`}
            </p>
            <PaginationIcon
                icon={faChevronRight}
                isDisabled={pageIndex === totalPageIndex}
                onClick={() => onClick(pageIndex + 1)}
                variant={variant}
            />
            <PaginationIcon
                icon={faChevronsRight}
                isDisabled={pageIndex === totalPageIndex}
                onClick={() => onClick(totalPageIndex)}
                variant={variant}
            />
        </div>
    );
};

export default Pagination;
