import { getDateFormattedSelects } from "app/helpers/Utilities/date/Date.utilities";

type IRankingExtraParams = {
    startDate: Date;
    endDate?: Date;
    companyId?: string;
    departmentId?: string;
    jobDescriptionId?: string;
};

export const rankingExtraParams = ({
    startDate,
    endDate,
    companyId,
    departmentId,
    jobDescriptionId,
}: IRankingExtraParams): string => {
    const extraParams = new URLSearchParams();
    extraParams.append("StartDate", getDateFormattedSelects(startDate));
    if (endDate) extraParams.append("EndDate", getDateFormattedSelects(endDate));
    if (companyId) extraParams.append("CompanyId", companyId);
    if (departmentId) extraParams.append("DepartmentId", departmentId);
    if (jobDescriptionId) extraParams.append("JobDescriptionId", jobDescriptionId);

    return extraParams.toString();
};
