import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { v4 } from "uuid";
import { useSession, useTitle } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useWizard } from "app/hooks/useWizard";
import { fillTaskInstanceExtraParams } from "app/helpers/__extraParams/taskinstance/taskInstanceFillExtraParams";
import { BaseTaskModel } from "app/models/02-TAR/BaseTask/BaseTaskModel";
import { EditTaskDisabledFieldsModel } from "app/models/02-TAR/Task/EditTask";
import { WizardTaskBody, WizardTaskBodyAndId } from "app/models/02-TAR/Task/TaskBody";
import { WizardTaskAllSteps } from "app/models/02-TAR/TaskWizard";
import { BaseTaskModal } from "app/pages/02-TAR/07-TAR-CRUD/EditTask/Components/BaseTaskModal/BaseTaskModal";
import { INITIAL_EDIT_TASK_DISABLEDS } from "app/pages/02-TAR/07-TAR-CRUD/EditTask/constants/editTaskConstants";
import { CurrentPage } from "app/pages/02-TAR/07-TAR-CRUD/TaskGrid/TaskGrid";
import { StepOne } from "app/pages/02-TAR/07-TAR-CRUD/WizardTasks/Step1/StepOne";
import { StepTwo } from "app/pages/02-TAR/07-TAR-CRUD/WizardTasks/Step2/StepTwo";
import { StepThree } from "app/pages/02-TAR/07-TAR-CRUD/WizardTasks/Step3/StepThree";
import { StepFour } from "app/pages/02-TAR/07-TAR-CRUD/WizardTasks/Step4/StepFour";
import { StepFive } from "app/pages/02-TAR/07-TAR-CRUD/WizardTasks/Step5/StepFive";
import { fillTaskBody, initialStepsValues } from "app/pages/02-TAR/07-TAR-CRUD/WizardTasks/utilities";
import { TaskInstanceService, TaskService } from "app/services";
import TaskAPPCCService from "app/services/05-QUA/TaskAPPCCService";
import { PrivatePaths } from "app/shared/Constants";
import {
    TaskTranslations,
    TranslationKeys,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import { Wizard } from "../Wizard";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { AssetModel2 } from "app/models/05-QUA/AssetModels/AssetModel2";
import { ASSET_PAGES } from "app/shared/AssetConstants";
import AssetService from "app/services/05-QUA/AssetService";
import { addMonths, getMonthName } from "app/helpers/Utilities/date/Date.utilities";

type WizardTaskProps = {
    onFinish: (taskGenerated: WizardTaskBodyAndId | null) => void;
    onClose: () => void;
    hasPlan: boolean;
    issueId?: number;
};

export const WizardTask: FC<WizardTaskProps> = ({ onFinish, onClose, hasPlan, issueId }) => {
    const { t } = useTranslation();
    const { handleToast, removeToast } = useToast();
    const session = useSession();
    const location = useLocation();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const offset = session?.user.timeZoneOffset;
    const currentPage: CurrentPage = location.pathname.includes(`/${PrivatePaths.REGISTRY_MANAGEMENT}`)
        ? "plan"
        : "task";
    const actualService = currentPage === "plan" ? TaskAPPCCService : TaskService;
    const actualGenerateService =
        currentPage === "plan"
            ? TaskInstanceService.GenerateAPPCCTaskInstances
            : TaskInstanceService.GenerateTaskInstances;

    useTitle(
        t(currentPage === "plan" ? TranslationTitles.NEW_TASK_APPCC_PAGE_TITLE : TranslationTitles.NEW_TASK_PAGE_TITLE)
    );

    const customerInstanceId = session?.user.customerInstanceId;
    const headerItems = [
        { title: t(TranslationKeys.WIZARD_HEADER_TASK_INFORMATION) },
        { title: t(TranslationKeys.WIZARD_HEADER_TASK_RESPONSIBLE) },
        { title: t(TranslationKeys.WIZARD_HEADER_TASK_RECORD_INFO) },
        { title: t(TranslationKeys.WIZARD_HEADER_TASK_DATE) },
        { title: t(TranslationKeys.WIZARD_HEADER_TASK_FINISH) },
    ];

    const totalSteps = headerItems.length;
    const { currentStepIndex, onStepChange, goTo, prev, next } = useWizard(totalSteps);
    const [data, setData] = useState<WizardTaskAllSteps>(initialStepsValues);
    const [baseTaskDisabledFields, setBaseTaskDisabledFields] =
        useState<EditTaskDisabledFieldsModel>(INITIAL_EDIT_TASK_DISABLEDS);
    const [isBaseTaskModalVisible, setIsBaseTaskModalVisible] = useState<boolean>(false);
    const [baseTask, setBaseTask] = useState<BaseTaskModel | null>(null);
    const [isSaving, setisSaving] = useState<boolean>(false);
    const [dynamicFieldAssetOptions, setDynamicFieldAssetOptions] = useState<AssetModel2[]>([]);

    const variant: TabHeaderVariants = hasPlan ? "appcc" : "primary";

    const handleUpdateFields = (values: Partial<WizardTaskAllSteps>) => setData((prev) => ({ ...prev, ...values }));

    const handleFinish = async () => {
        const body = fillTaskBody(data, customerInstanceId, undefined, offset);
        const taskGenerated = await onSubmit(body);
        onFinish(taskGenerated);
    };

    const onSubmit = async (body: WizardTaskBody): Promise<WizardTaskBodyAndId | null> => {
        setisSaving(true);
        const { status, data } = await actualService.Save(body);
        if (!status()) {
            handleToast({
                title: t(TranslationModals.FAILED_SAVE),
                type: "alert",
                variant: "danger",
            });
            return null;
        }
        if (body.taskType !== "SPORADIC") {
            await generateTasks(data.id, body.startDate);
        }
        handleToast({
            title: t(TranslationKeys.TASKS_CREATED),
            type: "alert",
            variant: "success",
        });
        setisSaving(true);
        return data;
    };

    const generateTasks = async (id: number, startDate: string) => {
        const deadlineDate = new Date(startDate);
        const deadlineDate1 = addMonths(deadlineDate, 1);
        const taskId = id;

        const toastId = v4();
        const day = deadlineDate.getDate();
        const month = getMonthName(deadlineDate.toString());
        handleToast({
            title: t(TranslationModals.GENERATING_TASKS_TITLE),
            subtitle: t(TranslationModals.GENERATING_TASKS_DESCRIPTION)
                .replace("{0}", String(day))
                .replace("{1}", month),
            variant: "primary",
            type: "alert",
            isLoading: true,
            id: toastId,
        });

        const generateTaskSr = await actualGenerateService(
            fillTaskInstanceExtraParams({
                deadlineDate,
                deadlineDate1,
                taskId,
                customerInstanceId,
            })
        );

        if (!generateTaskSr.status()) {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            removeToast(toastId);
            setisSaving(false);
            return;
        }

        removeToast(toastId);
        handleToast({
            title: t(TranslationKeys.TASKS_CREATED),
            type: "alert",
            variant: "success",
        });
        setisSaving(false);
    };

    const handleSaveBaseTaskModal = (
        task: WizardTaskAllSteps,
        disabledsFields: EditTaskDisabledFieldsModel,
        baseTask: BaseTaskModel
    ) => {
        task = {
            ...task,
            somethingToReportCheckBoxImage: task.isPhotoRequired,
            somethingToReportImage: task.somethingToReportImage ? task.somethingToReportImage : "",
        };
        setData(task);
        setIsBaseTaskModalVisible(false);
        setBaseTaskDisabledFields(disabledsFields);
        setBaseTask(baseTask);
    };

    const getDynamicFieldsAssets = async () => {
        if (!baseTask) return;

        const extraParams = new URLSearchParams();
        extraParams.append("AssetCategory", ASSET_PAGES.ASSET_RANGE);
        extraParams.append("IsDeleted", "false");
        if (!!baseTask.fK_PlanAPPCC) extraParams.append("FK_PlanAPPCC", baseTask.fK_PlanAPPCC.toString());
        const assetsSr = await AssetService.GetFullPublicList({
            extraParams: extraParams.toString(),
        });
        const assetOptions = assetsSr.data.list;
        setDynamicFieldAssetOptions(assetOptions);
    };

    const steps: ReactElement[] = [
        <StepOne
            {...data}
            onChange={handleUpdateFields}
            next={next}
            currentStepIndex={currentStepIndex}
            isFirstSetp={currentStepIndex === 0}
            isLastStep={currentStepIndex === totalSteps - 1}
            prev={prev}
            disabledFields={baseTaskDisabledFields}
            hasPlan={hasPlan}
        />,
        <StepTwo
            {...data}
            onChange={handleUpdateFields}
            title={translateCustomerTypeKeys(TranslationKeys.COMPANY_RESPONSIBLE_TITLE, { firstReplace: "SINGULAR" })}
            next={next}
            currentStepIndex={currentStepIndex}
            isFirstSetp={currentStepIndex === 0}
            isLastStep={currentStepIndex === totalSteps - 1}
            prev={prev}
            issueId={issueId}
        />,
        <StepThree
            {...data}
            onChange={handleUpdateFields}
            title={t(TranslationKeys.DATA_REPORT_TITLE)}
            next={next}
            currentStepIndex={currentStepIndex}
            isFirstSetp={currentStepIndex === 0}
            isLastStep={currentStepIndex === totalSteps - 1}
            prev={prev}
            disabledFields={baseTaskDisabledFields}
            allowAnyData={!!issueId || !!baseTask?.allowAnyData}
            fK_PlanAPPCC={data.fK_PlanAPPCC}
            dynamicFieldAssetOptions={dynamicFieldAssetOptions}
        />,
        <StepFour
            {...data}
            onChange={handleUpdateFields}
            title={t(TranslationKeys.DATE_START_REPETITIONS_TITLE)}
            next={next}
            currentStepIndex={currentStepIndex}
            isFirstSetp={currentStepIndex === 0}
            isLastStep={currentStepIndex === totalSteps - 1}
            prev={prev}
            issueId={issueId}
            disabledFields={baseTaskDisabledFields}
        />,
        <StepFive
            {...data}
            title={t(TranslationKeys.VERIFY_INFORMATION_TITLE)}
            next={next}
            currentStepIndex={currentStepIndex}
            isFirstSetp={currentStepIndex === 0}
            isLastStep={currentStepIndex === totalSteps - 1}
            prev={prev}
            onFinish={handleFinish}
        />,
    ];

    useEffect(() => {
        !!issueId &&
            setData({
                ...data,
                companyForTask: {
                    label: session?.workingCompany?.companyName ?? "",
                    value: String(session?.workingCompany?.companyId),
                    worksWithQr: false,
                },
                isCreated: false,
                taskType: "AUDIT",
                fK_Issue: issueId,
            });
    }, []);

    useEffect(() => {
        setIsBaseTaskModalVisible(true);
    }, []);

    useEffect(() => {
        getDynamicFieldsAssets();
    }, []);

    return (
        <>
            <Wizard
                currentStepIndex={currentStepIndex}
                headerItems={headerItems}
                onStepChange={onStepChange}
                step={steps[currentStepIndex - 1]}
                totalSteps={totalSteps}
                goTo={goTo}
                validateNextStep={false}
                isLoading={isSaving}
                finishButtonText={
                    !issueId ? t(TranslationKeys.PLANIFICATION_NEW_TASK) : t(TaskTranslations.TASK_SAVE_CONFIRM_BUTTON)
                }
                variant={variant}
            />
            {isBaseTaskModalVisible && !issueId && (
                <BaseTaskModal
                    onClose={onClose}
                    assetOptions={dynamicFieldAssetOptions}
                    onSave={handleSaveBaseTaskModal}
                    task={data}
                    hasPlan={location.pathname.includes(PrivatePaths.REGISTRY_MANAGEMENT)}
                />
            )}
        </>
    );
};
