import { FC } from "react";
import { RankingPillProps } from "./types";
import { RankingPillInfo } from "./RankingPillInfo/RankingPillInfo";
import { RankingPillCounters } from "./RankingPillCounters/RankingPillCounters";
import { roundNumberArray } from "app/helpers/RankingPill/roundNumberArray";

export const RankingPill: FC<RankingPillProps> = ({
    type = "default",
    name,
    colorId,
    profileURL,
    starsValoration,
    totalTasks,
    trend,
    counters,
    onClick,
    borderType = "shadow",
    nameInitials,
    userType,
    cursorDefault,
    numberOfStars,
    showBorderLeft = true,
    jobDescription,
    totalCounter,
    thin,
    pillStarsBottom,
    hexColor,
}) => {
    const totalPercentageCounters = counters?.reduce(
        (total, counterpill) =>
            total + (counterpill.percentage !== undefined ? Number(counterpill.percentage?.toFixed(2)) : 0),
        0
    );
    const roundedTrend = Number(trend?.toFixed(0));
    const counterNumberRounded = roundNumberArray(
        counters?.map(({ percentage }) => percentage || 0) || [],
        totalPercentageCounters || 0
    );

    const usertypeToLowerCase = userType ? `--${userType.toLocaleLowerCase()}` : "";
    const rankingPillTestId = `rankingPill${usertypeToLowerCase}`;
    return (
        <div
            className={`
                    ${userType && showBorderLeft ? `rankingPill--${userType.toLocaleLowerCase()}` : ""}
                    ${!!onClick ? "pointer" : ""}
                    rankingPill--${type} rankingPill--${borderType} 
                    rankingPill--white
                    ${thin ? "rankingPill--thin" : ""}
                    ${cursorDefault ? "cursorDefault" : ""}
                    ${totalCounter === undefined ? "rankingPill--thin" : ""}
                `}
            onClick={onClick}
            data-testid={rankingPillTestId}
        >
            <div
                className={`rankingPill${pillStarsBottom ? `__starsBottom` : "__container"}${
                    counters === undefined ? "--noCounters" : ""
                } ${starsValoration == null && pillStarsBottom ? "rankingPill__starsBottom--noStars" : ""}`}
                data-testid="rankingPillContainer"
            >
                <RankingPillInfo
                    hasCounters={!!counters}
                    name={name}
                    totalTasks={totalTasks || 0}
                    trend={roundedTrend}
                    type={type}
                    colorId={colorId}
                    nameInitials={nameInitials}
                    profileURL={profileURL}
                    starsValoration={starsValoration}
                    userType={userType}
                    numberOfStars={numberOfStars}
                    showUserType={userType && showBorderLeft}
                    jobDescription={jobDescription}
                    pillStarsBottom={pillStarsBottom}
                    hexColor={hexColor}
                />
                <div className={`rankingPill__counters`}>
                    {counters && (
                        <RankingPillCounters
                            counters={counters.map((counter, i) => ({
                                ...counter,
                                percentage:
                                    type === "percentage" && counter.percentage !== undefined
                                        ? counterNumberRounded[i]
                                        : undefined,
                            }))}
                            total={totalCounter}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
