import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PublicPaths } from "../../../shared/Constants";
import LicensesGrid from "./Components/LicensesGrid";
import LicenseTabs from "./Components/LicenseTabs";
import isiOS from "app/helpers/Mobile/isIos";

const LicensePage: FC = () => {
    return (
        <main className={`main_content ${isiOS() ? "main_content--mobile" : ""}`}>
            <Routes>
                <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />} />
                <Route path={""} element={<LicensesGrid />} />
                <Route path="edit/:instanceId" element={<LicenseTabs />} />
            </Routes>
        </main>
    );
};

export default LicensePage;
