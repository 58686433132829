import { getDateFormatted } from "app/helpers/Utilities/date/Date.utilities";
import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

export const useGetInvalidTranslation = () => {
    const { t } = useTranslation();

    const getInactiveTitleAndDate = (automaticActivationDate: Date | string | null) => {
        const isAutomaticDateHigherThanToday =
            automaticActivationDate == null || new Date(automaticActivationDate) <= new Date();
        if (isAutomaticDateHigherThanToday)
            return {
                title: t(TranslationKeys.TITLE_INACTIVE),
                date: t(TranslationKeys.TITLE_INACTIVE),
            };

        return {
            title: t(TranslationKeys.INACTIVE_SINCE).replace(
                "{1}",
                `${getDateFormatted(new Date(automaticActivationDate))}`
            ),
            date: String(getDateFormatted(new Date(automaticActivationDate))),
        };
    };

    return { getInactiveTitleAndDate };
};
