import { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { TaskTranslations, TranslationKeys } from "app/translation/translationKeys";
import { validations } from "../models";
import {
    RecurrentTaskModel,
    WizardTaskStep4,
    TaskHoursModel,
    TaskEnds,
    TaskTemporalityType,
    TaskTemporalityDateRangeFormModel,
} from "app/models/02-TAR/TaskWizard";
import { isHoursOutOfRange } from "app/helpers/__validates/task/validateTaskHours";
import { TaskType } from "app/models/02-TAR/Task/TaskBody";
import { days } from "../../../EditTask/utilities/initialValues";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { TASK_TEMPORALITY_TYPE, TASK_TEMPORALITY_TYPE_ARRAY } from "app/shared/Constants";
import { CustomErrorTaskTemporalityModel, EditTaskDisabledTemporality } from "app/models/02-TAR/Task/EditTask";
import { useValidateEditTaskTaskTemporality } from "../../../EditTask/hooks/useValidateEditTaskTaskTemporality";

type useStepFourProps = {
    taskHours: TaskHoursModel[];
    recurringTask: RecurrentTaskModel | null;
    onChange: (fields: Partial<WizardTaskStep4>) => void;
    taskStart: string;
    finish: TaskEnds;
    taskType: TaskType;
    next: () => void;
    issueId?: number;
    temporalityType: TaskTemporalityType;
    taskTemporalityDateRange: TaskTemporalityDateRangeFormModel;
    disabledFields: EditTaskDisabledTemporality;
};

const INITIAL_STEP4_ERROR_VALUES: CustomErrorTaskTemporalityModel = {
    taskStart: "",
    taskHours: {
        value: "",
        errors: [],
    },
    finishEl: "",
    finishResp: "",
    customReps: "",
    customDaysBubles: "",
    customMonth: "",
    selectOption: "",
    dateRange: "",
    temporalityType: "",
    temporalityDateRangeRepeatEvery: "",
    temporalityDateRange: {
        weekly: "",
        monthlyDay: "",
        monthlyStartDay: "",
        monthlyEndDay: "",
    },
};

export const useStepFour = ({
    onChange,
    recurringTask,
    taskHours,
    taskStart,
    finish,
    taskType,
    next,
    issueId,
    temporalityType,
    disabledFields,
    taskTemporalityDateRange,
}: useStepFourProps) => {
    const { t } = useTranslation();

    const [validations, setValidations] = useState<CustomErrorTaskTemporalityModel>(INITIAL_STEP4_ERROR_VALUES);
    const [showModalHoursOutOfTime, setShowModalHoursOutOfTime] = useState<boolean>(false);

    const handleErrorsChange = (values: Partial<validations>) => {
        setValidations((prev) => ({ ...prev, ...values }));
    };

    const { validateTemporality, validateTaskEnds } = useValidateEditTaskTaskTemporality({
        errors: validations.temporalityDateRange,
        data: {
            finish,
            recurringTask,
            taskHours,
            taskStart,
            taskTemporalityDateRange: taskTemporalityDateRange,
            taskType,
            temporalityType,
        },
        onErrorsChange: handleErrorsChange,
    });

    const getTaskTemporalityOptions = () => {
        const taskTemporalityOptions: OptionModel[] = [
            { label: t(TaskTranslations.TASK_TEMPORALITY_PERIODICAL), value: TASK_TEMPORALITY_TYPE.PERIODICAL },
            { label: t(TaskTranslations.TASK_TEMPORALITY_ONE_DAY), value: TASK_TEMPORALITY_TYPE.ONE_DAY },
        ];

        if (!issueId)
            taskTemporalityOptions.push({
                label: t(TaskTranslations.TASK_TEMPORALITY_SPORADIC),
                value: TASK_TEMPORALITY_TYPE.SPORADIC,
            });

        taskTemporalityOptions.push({
            label: t(TaskTranslations.TASK_TEMPORALITY_RANGE_DATES),
            value: TASK_TEMPORALITY_TYPE.RANGE_DATES,
        });
        return taskTemporalityOptions;
    };

    const handleChangeTaskTemporalityType = ({ value }: OptionModel) => {
        const selectedType = TASK_TEMPORALITY_TYPE_ARRAY.find((type) => type === value);

        if (!selectedType || selectedType === temporalityType) return;

        if (selectedType === "PERIODICAL") {
            handleSelectPeriodical();
            return;
        }

        if (selectedType === "ONE_DAY") {
            handleSelectOneDay();
            return;
        }

        if (selectedType === "SPORADIC") {
            handleSelectSporadic();
            return;
        }

        if (selectedType === "RANGE_DATES") {
            handleSelectDateRange();
            return;
        }

        onChange({ temporalityType: selectedType });
    };

    const handleSelectPeriodical = () => {
        if (!recurringTask) return;
        onChange({
            temporalityType: "PERIODICAL",
            taskType: taskType === "SPORADIC" ? "NORMAL" : taskType,
            recurringTask: {
                ...recurringTask,
                isRecurrent: true,
                value: "everyDay",
                text: `${t(TranslationKeys.EVERY_DAY)}`,
                custom: {
                    ...recurringTask.custom,
                    customValue: "day",
                    selectedOptions: { text: "", value: "" },
                },
            },
            finish: {
                checked: "never",
                value: null,
            },
        });
    };

    const handleSelectOneDay = () => {
        if (!recurringTask) return;
        onChange({
            temporalityType: "ONE_DAY",
            taskType: taskType === "SPORADIC" ? "NORMAL" : taskType,
            recurringTask: {
                ...recurringTask,
                isRecurrent: false,
                value: "everyDay",
                text: `${t(TranslationKeys.EVERY_DAY)}`,
            },
            finish: {
                checked: "never",
                value: null,
            },
        });
    };

    const handleSelectSporadic = () => {
        onChange({
            taskType: "SPORADIC",
            taskHours: [],
            finish: {
                checked: "never",
                value: null,
            },
            temporalityType: "SPORADIC",
            recurringTask: {
                isRecurrent: false,
                options: [],
                value: "",
                custom: {
                    customValue: "day",
                    days,
                    repeatEvery: 1,
                    selectedOptions: { text: "", value: "" },
                },
                text: "",
            },
        });
    };

    const handleSelectDateRange = () => {
        if (!recurringTask) return;
        onChange({
            taskHours: [],
            temporalityType: "RANGE_DATES",
            taskType: taskType === "SPORADIC" ? "NORMAL" : taskType,
            recurringTask: {
                ...recurringTask,
                isRecurrent: false,
                value: "everyDay",
                text: `${t(TranslationKeys.EVERY_DAY)}`,
            },
            finish: {
                checked: "never",
                value: null,
            },
        });
    };

    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleErrorsChange(INITIAL_STEP4_ERROR_VALUES);

        let check = true;
        const isValidTemporality = disabledFields.disabledType === "NONE" ? validateTemporality() : true;

        // Si la validación de la termporalidad está bien no sobrescribas la validación
        check = isValidTemporality ? check : false;

        if (disabledFields.disabledType === "PERIODICAL") {
            check = validateTaskEnds();
        }

        if (!check) return;
        if (isHoursOutOfRange(taskHours)) {
            setShowModalHoursOutOfTime(true);
            return;
        }
        if (!!issueId) onChange({ taskType: "AUDIT" });
        next();
    };

    const onConfirmModal = () => {
        setShowModalHoursOutOfTime(false);
        next();
    };

    const onCloseModal = () => {
        setShowModalHoursOutOfTime(false);
    };

    return {
        validate,
        validations,
        onConfirmModal,
        onCloseModal,
        showModalHoursOutOfTime,
        getTaskTemporalityOptions,
        handleChangeTaskTemporalityType,
    };
};
