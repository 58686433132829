import { FC } from "react";
import ButtonGroup from "app/components_v2/__buttons/Buttongroup/ButtonGroup";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { ButtonGroupItem } from "app/components_v2/__buttons/Buttongroup/types";
import { Label } from "app/components_v2/__inputs/Label";

type ReasignTypeSelectorProps = {
    items: ButtonGroupItem[];
    onSelect: (value: ButtonGroupItem) => void;
    value: string;
};

export const ReasignTypeSelector: FC<ReasignTypeSelectorProps> = ({ items, onSelect, value }) => {
    const { t } = useTranslation();
    return (
        <div className="reasignSelector">
            <Label label={t(TranslationKeys.REASIGN_TASK_CHANGE_WILL_BE)} />
            <ButtonGroup items={items} onSelect={onSelect} selectedValue={value} />
        </div>
    );
};
