import { DocumentalFileType } from "app/models/07-DOC/DocumentalFileType";
import { ALLOWED_DOCUMENTAL_FILE_EXTENSIONS } from "../constants/DocumentalFilePageConstants";

export const getDocumentalFileTypeByExtension = (extension: string): DocumentalFileType | null => {
    const normalizedExt = extension.toLowerCase();

    for (const [fileType, extensions] of Object.entries(ALLOWED_DOCUMENTAL_FILE_EXTENSIONS)) {
        if (extensions.includes(normalizedExt)) {
            return fileType as DocumentalFileType;
        }
    }

    return null;
};
