import { USER_TYPE } from "app/shared/Constants";

export const isUserTypeSupervisor = (value?: string | null): boolean => !!(value && value === USER_TYPE.SUPERVISOR);
export const isUserTypeWorker = (value?: string | null): boolean => !!(value && value === USER_TYPE.WORKER);
export const isUserTypeAnalist = (value?: string | null): boolean => !!(value && value === USER_TYPE.ANALIST);
export const isUserTypeAdminClient = (value?: string | null): boolean => !!(value && value === USER_TYPE.ADMIN_CLIENT);

export const isUserTypeSupervisorOrAnalist = (value?: string | null) =>
    isUserTypeSupervisor(value) || isUserTypeAnalist(value);
export const isUserTypeSupervisorOrAnalistOrAdminClient = (value?: string | null) =>
    isUserTypeSupervisor(value) || isUserTypeAnalist(value) || isUserTypeAdminClient(value);

export const isUserTypeAdmin = (value?: string | null): boolean => !!(value && value === USER_TYPE.ADMIN);
