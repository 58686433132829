import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToast } from "app/hooks/Toast/useToast";
import AssetService from "app/services/05-QUA/AssetService";
import { AssetTranslations, TranslationCommon } from "app/translation/translationKeys";
import { hasPermission } from "app/routes/HelperRoleBasedAccess";
import { IssueTypeCode, SecScreen } from "app/shared/Constants";
import { IssueModalContext } from "app/state/context/issueModalContext/issueModalContext";
import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";
import { Switch } from "app/components_v2/__inputs";
import { Taskfield } from "../Taskfield/Taskfield";
import { IssueModal } from "app/components_v2/__modals/IssueModal/IssueModal";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { DropdownTaskField } from "../DropdownTaskField/DropdownTaskField";
import TaskinstanceFieldService from "app/services/02-TAR/TaskinstanceFieldService";
import { useFetchErrors } from "app/hooks/useFetchErrors";

type GroupedTaskFieldProps = {
    groupedFields: TaskInstanceFieldModel[];
    customSetFields: (taskinstanceField: TaskInstanceFieldModel) => void;
    readOnly?: boolean;
    customErrors?: Map<number | string, string> | undefined;
    errorRef?: (ref: HTMLDivElement | null) => void;
    disabled?: boolean;
    statusCode: string;
    taskinstanceId: number;
};

export const GroupedTaskField: FC<GroupedTaskFieldProps> = ({
    groupedFields,
    customSetFields,
    readOnly,
    customErrors,
    errorRef,
    disabled,
    statusCode,
    taskinstanceId,
}) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const { fillValues, assetId, reset } = useContext(IssueModalContext);

    const [notWorking, setNotWorking] = useState(groupedFields[0].asset2?.notWorking || false);
    const [isIssueModalAssetNotWorkingOpen, setIsIssueModalAssetNotWorkingOpen] = useState<boolean>(false);
    const [isAssetFixed, setIsAssetFixed] = useState(false);
    const [isOperative, setIsOperative] = useState<boolean>(groupedFields[0].isOperative);
    const [isOperativeConfirmModalOpen, setIsOperativeConfirmModalOpen] = useState<boolean>(false);
    const [isOperativeLoading, setIsOperativeLoading] = useState<boolean>(false);
    const [isLoadingAssetRepair, setIsLoadingAssetRepair] = useState(false);

    const groupedFieldsFiltered = groupedFields.filter(({ isHidden }) => !isHidden);
    const showSwitchButtons =
        hasPermission(SecScreen.OPEN_MANUAL_ISSUE) &&
        groupedFields[0]?.asset2?.fK_Company != null &&
        groupedFields[0]?.asset2?.fK_Company != 0 &&
        (hasPermission(SecScreen.ASSET_NOT_WORKING) || hasPermission(SecScreen.ASSET_OPERATIVE));

    const handleNotWorkingAsset = async (value: boolean, assetId?: number) => {
        if (!assetId) return;

        fillValues({
            issueType: IssueTypeCode.TASK_ASSET,
            taskinstanceId: groupedFields[0].fK_TaskInstance,
            assetId,
            isAssetWorking: !value,
        });

        if (value) {
            setNotWorking(value);
            setIsIssueModalAssetNotWorkingOpen(true);
        } else setIsAssetFixed(true);
    };

    const handleFixedAsset = async () => {
        setIsLoadingAssetRepair(true);
        if (!assetId) {
            handleToast({ title: t(AssetTranslations.CANNOT_BE_REPAIRED), type: "alert", variant: "danger" });
            return;
        }

        groupedFields.forEach((taskfield) => {
            if (!taskfield || !taskfield.asset2) return;
            customSetFields({ ...taskfield, asset2: { ...taskfield.asset2, notWorking: false } });
        });

        setNotWorking(false);
        reset();
        await AssetService.NotWorking(assetId, { value: false });
        setIsAssetFixed(false);
        setIsLoadingAssetRepair(false);
    };

    const handleCloseIssueAssetNotWorkingModal = (isSubmitting: boolean) => {
        setIsIssueModalAssetNotWorkingOpen(false);
        groupedFields.forEach((taskfield) => {
            if (!taskfield || !taskfield.asset2) return;
            customSetFields({ ...taskfield, asset2: { ...taskfield.asset2, notWorking: isSubmitting } });
        });

        if (isSubmitting) return;

        setNotWorking(false);
        reset();
    };

    const handleIsNotOperative = async () => {
        setIsOperativeLoading(true);
        const { status, getParsedError } = await TaskinstanceFieldService.UpdateIsOperative(
            taskinstanceId,
            groupedFields[0].asset2?.id || 0
        );

        setIsOperativeLoading(false);
        if (!status()) {
            handleToast({ title: getErrorMessage(getParsedError()), variant: "danger", type: "alert" });
            return;
        }
        groupedFields.forEach((taskfield) => {
            if (!taskfield || !taskfield.asset2) return;
            customSetFields({ ...taskfield, isOperative: !isOperative });
        });

        setIsOperative((prev) => !prev);
        setIsOperativeConfirmModalOpen(false);
    };

    return (
        <div className="groupedTaskField">
            {isIssueModalAssetNotWorkingOpen && (
                <IssueModal onCloseModal={handleCloseIssueAssetNotWorkingModal} disableAsset />
            )}
            {isAssetFixed && (
                <ConfirmModal
                    title={t(AssetTranslations.ASSET_REPARATION_TITLE)}
                    description={t(AssetTranslations.ASSET_REPARATION_DESCRIPTION)}
                    onConfirm={handleFixedAsset}
                    onConfirmText={t(TranslationCommon.CONFIRM)}
                    onClose={() => setIsAssetFixed(false)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    isLoading={isLoadingAssetRepair}
                />
            )}
            {isOperativeConfirmModalOpen && (
                <ConfirmModal
                    title={
                        isOperative
                            ? t(AssetTranslations.ASSET_IS_OPERATIVE_TITLE)
                            : t(AssetTranslations.ASSET_IS_NOT_OPERATIVE_TITLE)
                    }
                    description={
                        isOperative
                            ? t(AssetTranslations.ASSET_IS_OPERATIVE_DESCRIPTION)
                            : t(AssetTranslations.ASSET_IS_NOT_OPERATIVE_DESCRIPTION)
                    }
                    onConfirm={handleIsNotOperative}
                    onConfirmText={t(TranslationCommon.CONFIRM)}
                    onClose={() => setIsOperativeConfirmModalOpen(false)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    isLoading={isOperativeLoading}
                />
            )}

            <div className="groupedTaskField__header">
                {groupedFields[0]?.asset2?.name && (
                    <h2 className="groupedTaskField__header__title">{`${groupedFields[0]?.asset2?.name}${
                        !!groupedFields[0]?.asset2?.assetId.length ? ` (${groupedFields[0]?.asset2?.assetId})` : ""
                    }`}</h2>
                )}
                {showSwitchButtons && (
                    <div className="groupedTaskField__header__notWorking">
                        <div className="groupedTaskField__switch">
                            {isOperative && hasPermission(SecScreen.ASSET_NOT_WORKING) && (
                                <Switch
                                    checked={notWorking}
                                    onChange={(value) => handleNotWorkingAsset(value, groupedFields[0].fK_Asset2)}
                                    disabled={readOnly}
                                    label={t(AssetTranslations.ASSET_NOT_WORKING_QUESTION)}
                                    reverse
                                />
                            )}
                            {!!groupedFieldsFiltered.length &&
                                !notWorking &&
                                hasPermission(SecScreen.ASSET_OPERATIVE) && (
                                    <Switch
                                        checked={!isOperative}
                                        onChange={() => setIsOperativeConfirmModalOpen(true)}
                                        disabled={readOnly}
                                        label={t(AssetTranslations.ASSET_IS_OPERATIVE_LABEL)}
                                        reverse
                                    />
                                )}
                        </div>
                    </div>
                )}
            </div>
            {!notWorking &&
                isOperative &&
                groupedFieldsFiltered.map((field) => {
                    const { dynamicFieldType, id } = field;

                    if (dynamicFieldType === "DROPDOWN")
                        return (
                            <DropdownTaskField
                                key={id}
                                field={field}
                                onChange={customSetFields}
                                errorMessage={customErrors?.get(id)}
                                readonly={readOnly}
                            />
                        );

                    return (
                        <Taskfield
                            key={id}
                            field={field}
                            handleInputChange={customSetFields}
                            customError={customErrors?.get(id)}
                            readonly={readOnly}
                            errorRef={errorRef}
                            disabled={disabled}
                            statusCode={statusCode}
                        />
                    );
                })}
        </div>
    );
};
