import { v4 } from "uuid";
import { BaseTaskFormModel } from "../EditBaseTask/models/EditBaseTaskFormModels";
import { BaseTaskCheckList } from "app/models/02-TAR/BaseTask/BaseTaskCheckListModel";
import { BaseTaskFieldOptionModel } from "app/models/02-TAR/BaseTask/BaseTaskFieldOptionModel";
import {
    AssetsToReport,
    CheckListModel,
    DynamicFieldsModel,
    DynamicFieldsType,
    DynamicTaskFieldOption,
} from "app/models/02-TAR/TaskWizard";
import { BaseTaskModel } from "app/models/02-TAR/BaseTask/BaseTaskModel";
import { BaseTaskFieldModel } from "app/models/02-TAR/BaseTask/BaseTaskFieldModel";
import { convertUTCtoLocaleDate, getHoursFromDateUTC } from "app/helpers/Utilities/date/Date.utilities";
import {
    selectRecurringTaskCustomValue,
    selectRecurringTaskValue,
    setDays,
} from "../../07-TAR-CRUD/EditTask/utilities";
import { AssetModel2 } from "app/models/05-QUA/AssetModels/AssetModel2";
import { assetTaskFromBack } from "app/models/02-TAR/Task/TaskBody";
import { MeasurementTypes } from "app/shared/Constants";
import { AssetDynamicFieldModel } from "app/models/05-QUA/AssetModels";

export const mapBaseTaskToDataTaskModel = (
    {
        counter,
        name,
        description,
        fotoExample,
        imageBase64Content,
        isPhotoRequired,
        isCritical,
        baseTaskCheckList,
        baseTaskFields,
        allowAnyData,
        reportType,
        baseTaskRangeHours,
        baseTaskScheduler,
        baseTaskTemporalityDateRange,
        taskType,
        startDate,
        neverEnds,
        numRecurrencies,
        endDate,
        taskTemporalityType,
        fotoExampleId,
        minPhotosAllowed,
    }: BaseTaskModel,
    assetOptions: AssetModel2[]
): BaseTaskFormModel => {
    const isRecurrent = baseTaskScheduler?.typeCode !== "ONE_TIME";

    const assets = getAsset(baseTaskFields || []);

    return {
        counter,
        taskTitle: name || "",
        taskDesc: description || "",
        belongsToAPPCC: false,
        fK_PlanAPPCC: "",
        DepartmentForTask: { label: "", value: "" },
        SubDepartementForTask: { label: "", value: "" },
        userForTask: { label: "", value: "" },
        criticalTask: isCritical,
        somethingToReportImage: fotoExample || "",
        imageBase64: imageBase64Content,
        isPhotoRequired,
        minPhotosAllowed: minPhotosAllowed || (isPhotoRequired ? 1 : null),
        checkList: reportType === "CHECKLIST" ? mapBaseTaskCheckListToCheckListModel(baseTaskCheckList) : [],
        dynamicFields:
            reportType === "DYNAMIC_FIELD"
                ? mapBaseTaskFieldsToDynamicFields(baseTaskFields || [], assets, assetOptions)
                : [],
        reportData: [],
        reportType: reportType || "none",
        somethingToReportCheckBox: false,
        allowAnyData,

        // BaseTask Temporality
        temporalityType: taskTemporalityType,
        taskStart: !!startDate?.length ? startDate.toString() : new Date().toString(),
        taskHours: baseTaskRangeHours
            ? baseTaskRangeHours.map(({ hour, maxHour }) => {
                  const startHourFormated = getHoursFromDateUTC(convertUTCtoLocaleDate(new Date(hour)));
                  const endHourFormated = maxHour
                      ? getHoursFromDateUTC(convertUTCtoLocaleDate(new Date(maxHour)))
                      : undefined;
                  return {
                      id: v4(),
                      hour: startHourFormated,
                      maxHour: endHourFormated,
                      type: maxHour ? "range" : "hour",
                  };
              })
            : [],
        recurringTask: {
            isRecurrent,
            options: [],
            value:
                baseTaskScheduler?.calendarDayNumber !== 0
                    ? "custom"
                    : baseTaskScheduler?.repeatEvery !== 1
                    ? "custom"
                    : selectRecurringTaskValue(baseTaskScheduler?.typeCode || "ONE_TIME"),
            custom: {
                customValue:
                    baseTaskScheduler?.calendarDayNumber !== 0
                        ? "everyMonth"
                        : selectRecurringTaskCustomValue(baseTaskScheduler?.typeCode || "ONE_TIME"),
                days: setDays(baseTaskScheduler),
                repeatEvery: baseTaskScheduler?.repeatEvery || 1,
                selectedOptions: {
                    text: "",
                    value:
                        baseTaskScheduler?.calendarDayNumber !== 0
                            ? "everyMonthDayOfMonth"
                            : baseTaskScheduler?.calendarWeekNumber !== 0
                            ? "everyMonthFirstDayOfWeek"
                            : "",
                },
            },
            text: baseTaskScheduler?.name || "",
        },
        finish: {
            checked: neverEnds ? "never" : endDate ? "el" : numRecurrencies ? "after" : "never",
            value: neverEnds ? null : numRecurrencies ? numRecurrencies : endDate ? endDate : null,
        },
        taskType,
        fotoExampleId,
    };
};

const mapBaseTaskCheckListToCheckListModel = (checklist?: BaseTaskCheckList[]): CheckListModel[] =>
    checklist?.map(({ id, isDeleted, name }) => ({
        id: v4(),
        isDeleted,
        name,
        dbId: id,
        isEnabled: true,
        fK_BaseTaskCheckList: id,
    })) || [];

export const mapCheckListToBaseCheckList = (
    checkList: CheckListModel[],
    isDuplicate: boolean | undefined
): BaseTaskCheckList[] =>
    checkList.map<BaseTaskCheckList>(({ id, isDeleted, name, dbId }) => ({
        baseTaskId: 0,
        id: isDuplicate ? 0 : id ? dbId || 0 : 0,
        name: name,
        isDeleted,
    }));

export const mapBaseTaskFieldsToDynamicFields = (
    baseTaskFields: BaseTaskFieldModel[],
    assets: assetTaskFromBack[],
    assetOptions: AssetModel2[]
): DynamicFieldsModel[] => {
    const dynamicFields: DynamicFieldsModel[] = [];

    const assetDynamicFields = baseTaskFields.filter((x) => x.asset2 != null);
    const dynamicFieldsWithoutAsset = baseTaskFields.filter((x) => x.asset2 == null);

    if (!!assetDynamicFields.length) {
        dynamicFields.push({
            id: v4(),
            dbId: undefined,
            label: "",
            isDeleted: false,
            dropDownMultipleOptions: assetOptions.map(({ id, name }) => ({ id, label: name })),
            dropDownMultipleValues: assets.map((a) => ({
                id: v4(),
                assetsFields: assetDynamicFields
                    .filter(({ fK_Asset }) => fK_Asset === a.id)
                    .map<AssetsToReport>(
                        ({
                            fK_AssetFieldRange,
                            assetFieldRange,
                            dynamicFieldType,
                            label,
                            id,
                            isDeleted,
                            isHidden,
                            baseTaskFieldOptions,
                            isRequired,
                        }) => ({
                            id: id || 0,
                            max: assetFieldRange?.max != null ? assetFieldRange?.max : null,
                            min: assetFieldRange?.min != null ? assetFieldRange?.min : null,
                            unit: assetFieldRange?.unit || MeasurementTypes.CELSIUS,
                            name: label || "",
                            type: label || "",
                            assetDynamicField: dynamicFieldType as AssetDynamicFieldModel,
                            assetFieldRangeId: fK_AssetFieldRange,
                            isDeleted,
                            isHidden,
                            isRequired: isRequired || true,
                            assetFieldOption: !!baseTaskFieldOptions?.length
                                ? baseTaskFieldOptions.map(({ label }) => ({
                                      fK_AssetField: 0,
                                      id: 0,
                                      isDeleted: false,
                                      name: label,
                                  }))
                                : [],
                        })
                    ),
                dbId: a.id,
                isDeleted: a.isDeleted,
                label: a.name,
                value: String(a.id),
            })),
            dynamicFieldsType: "DROPDOWN_MULTIPLE_ASSETS_RANGE",
            isRequired: true,
            order: assetDynamicFields[0]?.order || 0,
            taskFieldOptions: [],
        });
    }

    dynamicFieldsWithoutAsset.forEach(
        ({ dynamicFieldType, id, isRequired, label, baseTaskFieldOptions, isDeleted, order }) => {
            dynamicFields.push({
                dbId: id,
                dynamicFieldsType: dynamicFieldType || "DATE",
                id: v4(),
                isDeleted,
                isRequired: !!isRequired,
                label: label || "",
                taskFieldOptions:
                    baseTaskFieldOptions?.map((taskfield) => ({
                        label: taskfield.label,
                        id: v4(),
                    })) || [],
                dropDownMultipleOptions: [],
                dropDownMultipleValues: [],
                order,
            });
        }
    );

    return dynamicFields.sort((a, b) => a.order - b.order);
};

type MapDynamicFieldsBaseTask = {
    dynamicFields: DynamicFieldsModel[];
    baseTaskId?: string;
    isDuplicate?: boolean;
};

export const mapDynamicFieldsToBaseTaskFields = ({
    dynamicFields,
    baseTaskId,
    isDuplicate,
}: MapDynamicFieldsBaseTask): BaseTaskFieldModel[] => {
    const baseTaskFields: BaseTaskFieldModel[] = [];
    let order = 0;

    dynamicFields.forEach(
        ({ dropDownMultipleValues, dynamicFieldsType, isDeleted, isRequired, label, taskFieldOptions, dbId }) => {
            if (dropDownMultipleValues.length > 0) {
                dropDownMultipleValues.forEach((ddmv) =>
                    ddmv.assetsFields.forEach(
                        ({
                            name,
                            assetDynamicField,
                            assetFieldRangeId,
                            isDeleted,
                            isHidden,
                            assetFieldOption,
                            isRequired,
                            id,
                        }) => {
                            baseTaskFields.push({
                                isRequired,
                                dynamicFieldType: assetDynamicField || "TEXT",
                                id: isDuplicate ? 0 : id,
                                isDeleted: !!isDeleted,
                                label: name,
                                fK_Asset: Number(ddmv.value),
                                fK_AssetFieldRange: assetDynamicField === "NUMBER" ? assetFieldRangeId : undefined,
                                fK_BaseTask: !!baseTaskId?.length ? Number(baseTaskId) : 0,
                                isHidden,
                                baseTaskFieldOptions: assetFieldOption.map(({ name }) => ({
                                    label: name,
                                    id: 0,
                                    fK_BaseTaskField: 0,
                                })),
                                order,
                            });
                            order++;
                        }
                    )
                );
                return;
            }

            baseTaskFields.push({
                id: isDuplicate ? 0 : dbId || 0,
                label,
                isRequired,
                isDeleted,
                dynamicFieldType: dynamicFieldsType,
                baseTaskFieldOptions: taskFieldOptions.map<BaseTaskFieldOptionModel>(({ label }) => ({
                    label,
                    id: 0,
                    fK_BaseTaskField: 0,
                })),
                fK_BaseTask: !!baseTaskId?.length ? Number(baseTaskId) : 0,
                isHidden: false,
                order,
            });
            order++;
        }
    );

    return baseTaskFields;
};

const getAsset = (baseTaskFieldsValidated: BaseTaskFieldModel[]): assetTaskFromBack[] => {
    const assets: assetTaskFromBack[] = [];
    const assetIds: number[] = [];
    baseTaskFieldsValidated.forEach((asset) => {
        if (asset.asset2 != null && asset.fK_Asset != null && !assetIds.includes(asset.fK_Asset)) {
            assets.push(asset.asset2);
            assetIds.push(asset.fK_Asset);
        }
    });
    return assets || [];
};
