import { faBuildings } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

type OptionCompanyImageProps = {
    logoImage?: string;
    name?: string;
    size?: "xs" | "md" | "xl";
};

export const OptionCompanyImage: FC<OptionCompanyImageProps> = ({ name, logoImage, size = "xl" }) => {
    return (
        <div className={`optionCompany__imageContainer--${size}`}>
            {logoImage ? (
                <img src={logoImage} alt={name} className="optionCompany__image" loading="lazy" />
            ) : (
                <FontAwesomeIcon icon={faBuildings} className={`optionCompany__defaultCompany--${size}`} />
            )}
        </div>
    );
};
