import { faClone } from "@fortawesome/pro-regular-svg-icons";
import { FormLayout } from "app/components_v2/Layout/FormLayout/FormLayout";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { CompanyOptionModel } from "app/dtos/01-SEG/Company/CompanyOptionModel";
import { addMonths, convertUTCtoLocaleDate, getMonthName } from "app/helpers/Utilities/date/Date.utilities";
import { fillTaskInstanceExtraParams } from "app/helpers/__extraParams/taskinstance/taskInstanceFillExtraParams";
import { useSession } from "app/hooks";
import { useTitle } from "app/hooks/CustomHooks";
import { useToast } from "app/hooks/Toast/useToast";
import { BaseTaskModel } from "app/models/02-TAR/BaseTask/BaseTaskModel";
import { EditTaskDisabledFieldsModel } from "app/models/02-TAR/Task/EditTask";
import { TaskBodyWithIdModel } from "app/models/02-TAR/Task/TaskBody";
import { WizardTaskAllSteps, ReportType } from "app/models/02-TAR/TaskWizard";
import { OptionsSearchT } from "app/models/FormComponentsModel";
import {
    hasPermissionToAdd,
    hasPermissionToDelete,
    hasPermissionToEdit,
    hasPermissionToView,
} from "app/routes/HelperRoleBasedAccess";
import BaseTaskService from "app/services/02-TAR/BaseTaskService";
import TaskInstanceService from "app/services/02-TAR/TaskInstanceService";
import TaskService from "app/services/02-TAR/TaskService";
import TaskAPPCCService from "app/services/05-QUA/TaskAPPCCService";
import { PrivatePaths, SecScreen, TASK_TEMPORALITY_TYPE } from "app/shared/Constants";
import { IAppState } from "app/state/reducer";
import {
    TaskTranslations,
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import { isEqual } from "lodash";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { v4 } from "uuid";
import { CurrentPage } from "../TaskGrid/TaskGrid";
import { TaskBelongs, TaskData, TaskTemporality } from "./Components";
import { BaseTaskModal } from "./Components/BaseTaskModal/BaseTaskModal";
import { baseTaskToDisableds } from "./Components/BaseTaskModal/helpers/baseTaskToDisableds";
import { DataToReport } from "./Components/DataToReport/DataToReport";
import { INITIAL_EDIT_TASK_DISABLEDS } from "./constants/editTaskConstants";
import { useEditTask } from "./hooks/useEditTask";
import { fillFormValues, mapTask } from "./utilities";
import { initialStepsValues } from "./utilities/initialValues";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import BaseTaskAPPCCService from "app/services/05-QUA/BaseTaskAPPCCService";
import { ASSET_PAGES } from "app/shared/AssetConstants";
import AssetService from "app/services/05-QUA/AssetService";
import { AssetModel2 } from "app/models/05-QUA/AssetModels/AssetModel2";

export const EditTask = () => {
    const {
        data,
        onChange,
        setData,
        validate,
        validations,
        isEnabledUserDept,
        setEnableUserDept,
        dataCopy,
        setDataCopy,
        onCloseModalHoursOutOfTime,
        onConfirmModalHoursOutOfTime,
        showModalHoursOutOfTime,
        baseTaskDisabledFields,
        setBaseTaskDisabledFields,
    } = useEditTask(initialStepsValues);

    const [dynamicFieldAssetOptions, setDynamicFieldAssetOptions] = useState<AssetModel2[]>([]);

    const instanceId = useSelector<IAppState, number | undefined>((state) => state.customerInstanceId);
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const { id } = useParams();
    const nav = useNavigate();
    const { handleToast, removeToast } = useToast();
    const session = useSession();
    const location = useLocation();
    const [urlParams] = useSearchParams();

    const offset = session?.user.timeZoneOffset;

    const currentPage: CurrentPage = location.pathname.includes(`/${PrivatePaths.REGISTRY_MANAGEMENT}`)
        ? "plan"
        : "task";
    const actualService = currentPage === "plan" ? TaskAPPCCService : TaskService;
    const actualBaseService = currentPage === "plan" ? BaseTaskAPPCCService : BaseTaskService;
    const actualGenerateService =
        currentPage === "plan"
            ? TaskInstanceService.GenerateAPPCCTaskInstances
            : TaskInstanceService.GenerateTaskInstances;

    const [companies, setCompanies] = useState<CompanyOptionModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [showCancelReasignModal, setShowCancelReasignModal] = useState<boolean>(false);
    const [isCancelReasingLoading, setIsCancelReasingLoading] = useState<boolean>(false);
    const [reportTypeOptions, setReportTypeOptions] = useState<OptionsSearchT<ReportType>[]>([]);
    const [baseTask, setBaseTask] = useState<BaseTaskModel | null>(null);
    const [originalStartTask, setOriginalStartTask] = useState<string | null>(null);

    const hasPlan = location.pathname.includes(PrivatePaths.REGISTRY_MANAGEMENT);
    const taskGridSecScreen = hasPlan ? SecScreen.REGISTRY_MANAGEMENT : SecScreen.TASK;
    const isOnBoarding = session?.isOnBoarding;
    const variant: TabHeaderVariants = hasPlan ? "appcc" : "primary";

    const handleConfirmDeleteTask = async () => {
        await handleDeleteTask();
        handleNavigate(PrivatePaths.TASK_PAGE);
    };

    const isDuplicatePage = useCallback(() => {
        return pathname.split("/").includes("duplicate");
    }, [pathname]);

    const isFastNewPage = useCallback(() => {
        return pathname.split("/").includes("fastNew");
    }, [pathname]);

    const isEditPage = useCallback(() => pathname.split("/").includes("edit"), [pathname]);

    const [isBaseTaskModalVisible, setIsBaseTaskModalVisible] = useState<boolean>(isFastNewPage());

    useTitle(
        t(
            isFastNewPage()
                ? currentPage === "plan"
                    ? TranslationTitles.NEW_TASK_APPCC_PAGE_TITLE
                    : TranslationTitles.NEW_TASK_PAGE_TITLE
                : isDuplicatePage()
                ? currentPage === "plan"
                    ? TranslationTitles.DUPLICATE_TASK_APPCC_PAGE_TITLE
                    : TranslationTitles.DUPLICATE_TASK_PAGE_TITLE
                : currentPage === "plan"
                ? TranslationTitles.EDIT_TASK_APPCC_PAGE_TITLE
                : TranslationTitles.EDIT_TASK_PAGE_TITLE
        )
    );

    const getTaskTemporalityOptions = () => {
        const taskTemporalityOptions: OptionModel[] = [
            { label: t(TaskTranslations.TASK_TEMPORALITY_PERIODICAL), value: TASK_TEMPORALITY_TYPE.PERIODICAL },
            { label: t(TaskTranslations.TASK_TEMPORALITY_ONE_DAY), value: TASK_TEMPORALITY_TYPE.ONE_DAY },
        ];

        if (data.taskType !== "AUDIT")
            taskTemporalityOptions.push({
                label: t(TaskTranslations.TASK_TEMPORALITY_SPORADIC),
                value: TASK_TEMPORALITY_TYPE.SPORADIC,
            });

        taskTemporalityOptions.push({
            label: t(TaskTranslations.TASK_TEMPORALITY_RANGE_DATES),
            value: TASK_TEMPORALITY_TYPE.RANGE_DATES,
        });
        return taskTemporalityOptions;
    };

    const handleNavigate = (path: string) => {
        const planId = urlParams.get("planId");
        if (hasPlan && !!planId) {
            nav(`${path}?planId=${planId}`);
            return;
        }
        nav(`${path}`);
    };

    const fireToastSuccessEdit = () => {
        handleToast({
            title: t(TranslationModals.SUCCESS_EDIT_TITLE_TOAST),
            subtitle: t(TranslationModals.SUCCESS_EDIT_SUBTITLE_TOAST),
            variant: "success",
            type: "alert",
        });
    };

    const generateTask = async (taskId: number) => {
        const today = new Date();
        const tomorrow = new Date();
        const taskStart = new Date(data.taskStart);
        tomorrow.setDate(tomorrow.getDate() + 1);
        today.setHours(0, 0, 0, 0);
        tomorrow.setHours(0, 0, 0, 0);
        taskStart.setHours(0, 0, 0, 0);

        const todayOrTomorrow =
            isFastNewPage() || isDuplicatePage() ? convertUTCtoLocaleDate(today) : convertUTCtoLocaleDate(tomorrow);
        const deadlineDate = taskStart > today ? convertUTCtoLocaleDate(taskStart) : todayOrTomorrow;
        const deadlineDate1 = addMonths(convertUTCtoLocaleDate(new Date()), 1);

        const toastId = v4();
        const day = deadlineDate.getDate();
        const month = getMonthName(deadlineDate.toString());
        handleToast({
            title: t(TranslationModals.GENERATING_TASKS_TITLE),
            subtitle: t(TranslationModals.GENERATING_TASKS_DESCRIPTION)
                .replace("{0}", String(day))
                .replace("{1}", month),
            variant: "primary",
            type: "alert",
            isLoading: true,
            id: toastId,
        });

        const generateTaskSr = await actualGenerateService(
            fillTaskInstanceExtraParams({
                deadlineDate: deadlineDate,
                deadlineDate1: deadlineDate1,
                taskId: taskId,
                customerInstanceId: instanceId,
            })
        );

        if (!generateTaskSr.status()) {
            handleToast({
                title: t(TranslationModals.FAILED_EDIT),
                variant: "danger",
                type: "alert",
            });
            removeToast(toastId);
            setIsSaving(false);
            return;
        }

        removeToast(toastId);
        fireToastSuccessEdit();
        setIsSaving(false);
        return;
    };

    const setFormValuesOnEditDuplicate = (dataResponse: TaskBodyWithIdModel) => {
        const fotoExample = data.somethingToReportImage;
        setOriginalStartTask(data.taskStart);
        const modifiedData: WizardTaskAllSteps = {
            ...fillFormValues(dataResponse, false, dynamicFieldAssetOptions, offset),
            somethingToReportImage: fotoExample,
            activeReassignedTo: data.activeReassignedTo,
            companyForTask: data.companyForTask,
            imageBase64: data.imageBase64,
        };

        setDataCopy(modifiedData);
        setData(modifiedData);
    };

    const handleSaveTask = async () => {
        const baseTaskId = urlParams.get("baseTaskId");
        const taskGridPath = hasPlan ? PrivatePaths.REGISTRY_MANAGEMENT : PrivatePaths.TASK_PAGE;
        const baseTaskPath = hasPlan ? PrivatePaths.BASE_TASK_APPCC_MANAGEMENT : PrivatePaths.BASE_TASK_MANAGEMENT;

        const taskServiceSr = await actualService.Save(mapTask(data, instanceId, undefined, true, offset));
        if (!taskServiceSr.status()) {
            handleToast({
                title: t(TranslationModals.FAILED_SAVE),
                variant: "danger",
                type: "alert",
            });
            setIsSaving(false);
            return;
        }
        if (taskServiceSr.data.taskType !== "SPORADIC") {
            await generateTask(taskServiceSr.data.id);
        } else {
            fireToastSuccessEdit();
        }

        setFormValuesOnEditDuplicate(taskServiceSr.data as TaskBodyWithIdModel);
        if (!!baseTaskId?.length && baseTaskId !== null) {
            handleNavigate(`/${baseTaskPath}/edit/${baseTaskId}`);
            return;
        }

        handleNavigate(`/${taskGridPath}`);
    };

    const handleEditTask = async () => {
        const taskServiceSr = await actualService.Edit(mapTask(data, instanceId, id, undefined, offset));
        if (!taskServiceSr.status()) {
            handleToast({
                title: t(TranslationModals.FAILED_EDIT),
                variant: "danger",
                type: "alert",
            });
            setIsSaving(false);
            return;
        }
        if (taskServiceSr.data.taskType !== "SPORADIC") {
            await generateTask(Number(id));
        } else {
            fireToastSuccessEdit();
        }
        setIsSaving(false);
        const path = hasPlan ? PrivatePaths.REGISTRY_MANAGEMENT : PrivatePaths.TASK_PAGE;
        handleNavigate(`/${path}`);

        setFormValuesOnEditDuplicate(taskServiceSr.data as TaskBodyWithIdModel);
    };

    const handleDeleteTask = async () => {
        const sr = await actualService.Delete(Number(id));
        if (!sr.status()) {
            handleToast({
                title: sr.getParsedError(),
                variant: "danger",
                type: "alert",
            });
            return;
        }
    };

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validate(e)) return;
        saveTask();
    };

    const saveTask = () => {
        setIsSaving(true);
        if (isDuplicatePage() || isFastNewPage()) {
            handleSaveTask();
            return;
        }
        handleEditTask();
    };

    const showModal = () => setShowConfirmModal(true);

    const handleCancelReasign = async () => {
        if (!id) return;
        setIsCancelReasingLoading(true);
        const { status } = await actualService.CancelReasign(Number(id));
        if (!status()) {
            handleToast({
                title: t(TranslationErrors.ERROR_MESSAGE_CANCEL_REASIGNATION_FAILED),
                variant: "danger",
                type: "alert",
            });
            setIsCancelReasingLoading(false);
            setShowCancelReasignModal(false);
            return;
        }
        setIsSaving(true);
        setIsCancelReasingLoading(false);
        setShowCancelReasignModal(false);
        onChange({ activeReassignedTo: undefined });

        if (data.taskType !== "SPORADIC") {
            await generateTask(Number(id));
            return;
        }
        fireToastSuccessEdit();
        setIsSaving(false);
    };

    // Hace la llamada a la base de datos para conseguir la información
    const fetchAll = async () => {
        setIsLoading(true);
        const taskSr = await actualService.GetOne(id ?? 0);
        const companySr = await SegSelectorService.GetCompanyNamesWithIds({
            extraParams: `SupervisorId=${session?.user.id}`,
        });
        const assetOptions: AssetModel2[] = [];

        if (currentPage === "plan") {
            const extraParams = new URLSearchParams();
            extraParams.append("AssetCategory", ASSET_PAGES.ASSET_RANGE);
            extraParams.append("IsDeleted", "false");
            if (!!taskSr.data.fK_PlanAPPCC) extraParams.append("FK_PlanAPPCC", taskSr.data.fK_PlanAPPCC.toString());
            const assetsSr = await AssetService.GetFullPublicList({
                extraParams: extraParams.toString(),
            });
            assetOptions.push(...assetsSr.data.list);
        }

        if (taskSr.status() && companySr.status()) {
            setCompanies(companySr.data);
            setDynamicFieldAssetOptions(assetOptions);

            setOriginalStartTask(taskSr.data.startDate);
            let values = fillFormValues(taskSr.data, isDuplicatePage(), assetOptions, offset);
            const selectedCompany = companySr.data?.find(({ value }) => value === values.companyForTask.value);
            values = {
                ...values,
                companyForTask: {
                    ...values.companyForTask,
                    worksWithQr: !!selectedCompany?.worksWithQR,
                },
            };

            if (values.fK_BaseTask) await handleBaseTask(values.fK_BaseTask);

            setData(values);
            setDataCopy(values);
        } else {
            if (taskSr.httpCodeStatus === 404) {
                const path = hasPlan ? PrivatePaths.REGISTRY_MANAGEMENT : PrivatePaths.TASK_PAGE;
                handleNavigate(`/${path}`);
                return;
            }
            handleToast({
                title: t(TranslationCommon.FAILED_DATA_LOADED),
                variant: "danger",
                type: "alert",
            });
        }

        setIsLoading(false);
    };

    // Fetch solo si es para crear una nueva tarea
    const fetch = async () => {
        const assetOptions: AssetModel2[] = [];

        const { data, status } = await SegSelectorService.GetCompanyNamesWithIds({
            extraParams: `SupervisorId=${session?.user.id}`,
        });

        if (currentPage === "plan") {
            const extraParams = new URLSearchParams();
            extraParams.append("AssetCategory", ASSET_PAGES.ASSET_RANGE);

            const assetsSr = await AssetService.GetFullPublicList({
                extraParams: extraParams.toString(),
            });

            if (!assetsSr.status()) {
                handleToast({
                    title: t(TranslationCommon.FAILED_DATA_LOADED),
                    variant: "danger",
                    type: "alert",
                });
                return;
            }
            assetOptions.push(...assetsSr.data.list);
        }

        if (!status()) {
            handleToast({
                title: t(TranslationCommon.FAILED_DATA_LOADED),
                variant: "danger",
                type: "alert",
            });
        }
        if (!session?.workingCompany?.companyId && !isOnBoarding) {
            const path = hasPlan ? PrivatePaths.REGISTRY_MANAGEMENT : PrivatePaths.TASK_PAGE;
            handleNavigate(`/${path}`);
            return;
        }

        setDynamicFieldAssetOptions(assetOptions);
        setCompanies(data);
        const selectedCompany = data.find((c) => c.value === String(session.workingCompany?.companyId));
        onChange({
            companyForTask: {
                label: session?.workingCompany?.companyName || "",
                value: String(session?.workingCompany?.companyId),
                worksWithQr: !!selectedCompany?.worksWithQR,
            },
            taskStart: new Date().toString(),
        });
        setIsLoading(false);
    };

    const hideDeleteBtn = () => {
        if (isDuplicatePage()) return false;
        if (isFastNewPage()) return false;

        return true;
    };

    const handleCancel = async () => {
        if (isFastNewPage()) {
            await fetch();
            setIsCancelModalOpen(false);
            return;
        }
        await fetchAll();
        setIsCancelModalOpen(false);
    };

    const isDisabled = () => {
        if (isDuplicatePage() || isFastNewPage()) return !hasPermissionToAdd(taskGridSecScreen);
        return !hasPermissionToEdit(taskGridSecScreen);
    };

    const getMinDate = (): Date => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        return isDuplicatePage() || isFastNewPage() || data.taskType === "SPORADIC" ? new Date() : tomorrow;
    };

    const getDisableds = (baseTask: BaseTaskModel, status: boolean) => {
        const disableds = baseTaskToDisableds(baseTask);
        setBaseTaskDisabledFields(status ? disableds : INITIAL_EDIT_TASK_DISABLEDS);
    };

    const handleBaseTask = async (id: number) => {
        if (!hasPermissionToView(session, SecScreen.BASE_TASK_MANAGEMENT)) {
            setBaseTaskDisabledFields(INITIAL_EDIT_TASK_DISABLEDS);
            return;
        }

        const { data, status } = await actualBaseService.GetOne(id);
        getDisableds(data, status());

        const options = fillReportTypeOptions(data);
        setReportTypeOptions(options);
        setBaseTask(data);
    };

    const handleCloseBaseTaskModal = () => {
        const path = hasPlan ? PrivatePaths.REGISTRY_MANAGEMENT : PrivatePaths.TASK_PAGE;
        handleNavigate(`/${path}`);
    };

    const handleSaveBaseTaskModal = async (
        task: WizardTaskAllSteps,
        disabledsFields: EditTaskDisabledFieldsModel,
        baseTask: BaseTaskModel
    ) => {
        setData(task);
        setIsBaseTaskModalVisible(false);
        setBaseTaskDisabledFields(disabledsFields);
        const options = fillReportTypeOptions(baseTask);
        setReportTypeOptions(options);
        setBaseTask(baseTask);
        if (currentPage === "task") return;

        const extraParams = new URLSearchParams();
        extraParams.append("AssetCategory", ASSET_PAGES.ASSET_RANGE);
        extraParams.append("IsDeleted", "false");
        if (!!task.fK_PlanAPPCC) extraParams.append("FK_PlanAPPCC", task.fK_PlanAPPCC.toString());
        const assetsSr = await AssetService.GetFullPublicList({
            extraParams: extraParams.toString(),
        });

        setDynamicFieldAssetOptions(assetsSr.data.list);
    };

    const getTitle = (): string => {
        if (isDuplicatePage())
            return hasPlan ? t(TranslationKeys.DUPLICATE_REGISTER) : t(TranslationKeys.DUPLICATE_TASK);

        if (isFastNewPage()) return hasPlan ? t(TranslationKeys.NEW_REGISTER) : t(TranslationKeys.NEW_TASK);

        return hasPlan ? t(TranslationKeys.EDIT_REGISTER) : t(TranslationKeys.EDIT_TASK);
    };

    const fillReportTypeOptions = (baseTask: BaseTaskModel): OptionsSearchT<ReportType>[] => {
        const options: OptionsSearchT<ReportType>[] = [
            {
                label: t(TranslationKeys.DATA_TO_REPORT_NO),
                value: "NONE",
            },
        ];

        if (baseTask.allowAnyData) {
            options.push(
                {
                    label: t(TranslationKeys.CHECKLIST),
                    value: "CHECKLIST",
                },
                {
                    label: t(TranslationKeys.DATA_TO_REPORT_DYNAMIC_FIELDS),
                    value: "DYNAMIC_FIELD",
                }
            );

            if (hasPlan) {
                options.push({
                    label: t(TranslationKeys.DATA_TO_REPORT_ASSET),
                    value: "ASSET",
                });
            }
        }

        return options;
    };

    useEffect(() => {
        if ((isDuplicatePage() || isFastNewPage()) && isDisabled()) {
            const path = hasPlan ? PrivatePaths.REGISTRY_MANAGEMENT : PrivatePaths.TASK_PAGE;
            handleNavigate(`/${path}`);
            return;
        }

        if (isFastNewPage()) {
            fetch();
            return;
        }
        fetchAll();
    }, []);

    useEffect(() => {
        if (!baseTask) return;
        setReportTypeOptions(fillReportTypeOptions(baseTask));
    }, [session?.user.language]);

    return (
        <>
            {isBaseTaskModalVisible && (
                <BaseTaskModal
                    onClose={handleCloseBaseTaskModal}
                    onSave={handleSaveBaseTaskModal}
                    assetOptions={dynamicFieldAssetOptions}
                    task={data}
                    hasPlan={hasPlan}
                />
            )}
            {showModalHoursOutOfTime && (
                <ConfirmModal
                    onConfirm={() => {
                        onConfirmModalHoursOutOfTime();
                        saveTask();
                    }}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={onCloseModalHoursOutOfTime}
                    title={t(TranslationKeys.HOURS_OUT_OF_RANGE_TITLE)}
                    description={t(TranslationKeys.HOURS_OUT_OF_RANGE_DESCRIPTION)}
                    portal
                    variant={variant}
                />
            )}
            {showCancelReasignModal && (
                <ConfirmModal
                    onConfirm={handleCancelReasign}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    description={t(TranslationCommon.ARE_YOU_SURE)}
                    onClose={() => setShowCancelReasignModal(false)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    title={t(TranslationKeys.CANCEL_REASIGNATION_TITLE)}
                    isLoading={isCancelReasingLoading}
                    variant={variant}
                />
            )}

            {showConfirmModal && (
                <ConfirmModal
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={() => setShowConfirmModal(false)}
                    onConfirm={handleConfirmDeleteTask}
                    description={t(TranslationCommon.ARE_YOU_SURE)}
                    title={hasPlan ? t(TranslationKeys.DELETE_REGISTER) : t(TranslationModals.DELETE_TASK_TITLE)}
                    type="delete"
                    variant={variant}
                />
            )}

            {isCancelModalOpen && (
                <ConfirmModal
                    onClose={() => setIsCancelModalOpen(false)}
                    onConfirm={handleCancel}
                    isLoading={isLoading}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    description={t(TranslationCommon.ARE_YOU_SURE)}
                    title={t(TranslationKeys.UNDO_CHANGES)}
                    variant={variant}
                />
            )}

            <FormLayout
                isLoading={isLoading}
                variant="gray"
                headerOptions={{
                    buttonLabel: hasPlan ? t(TranslationKeys.DELETE_REGISTER) : t(TranslationModals.DELETE_TASK_TITLE),
                    showDeleteButton: hideDeleteBtn() && hasPermissionToDelete(taskGridSecScreen),
                    type: "delete",
                    onClick: showModal,
                    variant: currentPage === "plan" ? "appcc" : "primary",
                    secondaryButton:
                        hasPermissionToAdd(taskGridSecScreen) && location.pathname.includes("edit")
                            ? {
                                  label: hasPlan
                                      ? t(TranslationKeys.DUPLICATE_REGISTER)
                                      : t(TranslationKeys.DUPLICATE_TASK),
                                  icon: faClone,
                                  onClickSecondaryButton: () => {
                                      const path = hasPlan ? PrivatePaths.REGISTRY_MANAGEMENT : PrivatePaths.TASK_PAGE;
                                      handleNavigate(`/${path}/duplicate/${id}`);
                                      const startDateTask =
                                          originalStartTask != null ? originalStartTask : data.taskStart;
                                      onChange({
                                          taskStart:
                                              new Date(startDateTask) < new Date()
                                                  ? new Date().toString()
                                                  : startDateTask,
                                      });
                                  },
                              }
                            : undefined,
                    title: getTitle(),
                }}
                footer={
                    <>
                        {!isDisabled() && (
                            <GenericModalFooter
                                confirmButton={{
                                    text: t(TranslationCommon.SAVE),
                                    type: "submit",
                                    form: "editTaskId",
                                    variant,
                                }}
                                closeButton={
                                    !isFastNewPage()
                                        ? {
                                              text: t(TranslationCommon.CANCEL),
                                              onClick: () => setIsCancelModalOpen(true),
                                              disabled: isEqual(data, dataCopy),
                                              buttonType: "tertiary",
                                              variant,
                                          }
                                        : undefined
                                }
                                loading={isSaving}
                            />
                        )}
                    </>
                }
            >
                <div className="editTask">
                    <form onSubmit={onSubmit} id="editTaskId" className="editTask__container">
                        <div className="editTask__container__body">
                            <TaskData
                                {...data}
                                disabledFields={baseTaskDisabledFields}
                                onChange={onChange}
                                customError={validations}
                                isDisabled={isDisabled()}
                                hasPlanAPPCC={hasPlan}
                                variant={variant}
                                baseTaskType={baseTask?.type || ""}
                            />
                            <TaskBelongs
                                {...data}
                                companyId={Number(data.companyForTask.value) || 0}
                                onChange={onChange}
                                departmentId={Number(data.DepartmentForTask.value)}
                                isEnabledUserDept={isEnabledUserDept}
                                setEnableUserDept={setEnableUserDept}
                                customError={validations}
                                onCancelReasign={() => setShowCancelReasignModal(true)}
                                isEditing={!isDuplicatePage()}
                                companyValues={companies}
                                isDisabled={isDisabled()}
                                variant={variant}
                            />
                            <TaskTemporality
                                {...data}
                                onChange={onChange}
                                customError={validations}
                                isDisabled={isDisabled()}
                                min={getMinDate()}
                                variant={variant}
                                temporaryTypeOptions={getTaskTemporalityOptions()}
                                disabledFields={baseTaskDisabledFields}
                            />
                            <DataToReport
                                {...data}
                                companyId={Number(data.companyForTask.value)}
                                onChange={onChange}
                                customError={validations}
                                isDisabled={isDisabled()}
                                disabledFields={baseTaskDisabledFields}
                                allowAnyData={!!baseTask?.allowAnyData}
                                options={reportTypeOptions}
                                variant={variant}
                                dynamicFieldAssetOptions={dynamicFieldAssetOptions}
                            />
                        </div>
                    </form>
                </div>
            </FormLayout>
        </>
    );
};
