import { ChangeEvent, FC, useEffect, useState } from "react";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { Label } from "../Label";
import { ReadOnlyInput } from "../ReadOnlyInput/ReadOnlyInput";
import {
    getDateFormattedWithHoursSelects,
    getFormatedFullDateAndHours,
} from "app/helpers/Utilities/date/Date.utilities";

type DateTimePickerProps = {
    value: string;
    onChange: (value: string) => void;
    min?: string | number;
    label?: string;
    isOptional?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    errorMessage?: string;
};

export const DateTimePicker: FC<DateTimePickerProps> = ({
    value,
    onChange,
    min,
    label,
    isOptional,
    readonly,
    disabled,
    errorMessage,
}) => {
    const [date, setDate] = useState<Date | null>(null);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!checkValidDate(e.target.value)) {
            onChange("");
            return;
        }
        onChange(e.target.value);
    };

    const checkValidDate = (value: string): boolean => {
        const initialDate = new Date(value);
        if (!isNaN(initialDate.getTime())) {
            setDate(initialDate);
            return true;
        }
        setDate(null);
        return false;
    };

    useEffect(() => {
        checkValidDate(value);
    }, [value]);

    if (readonly)
        return (
            <ReadOnlyInput
                value={date ? getFormatedFullDateAndHours(date) : ""}
                label={label}
                placeholder="dd/mm/aa --:--"
            />
        );

    return (
        <div className="dateTimePicker">
            {!!label && <Label label={label} isOptional={isOptional} />}
            <input
                className={`dateTimePicker__dateTime ${
                    !!errorMessage?.length ? "dateTimePicker__dateTime--error" : ""
                }`}
                type="datetime-local"
                value={getDateFormattedWithHoursSelects(date ?? undefined)}
                min={min}
                onChange={handleChange}
                disabled={disabled}
                aria-invalid={!!errorMessage?.length}
            />
            {!!errorMessage?.length && <ErrorMessage errorMessage={errorMessage} />}
        </div>
    );
};
