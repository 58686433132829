import { FC } from "react";
import {
    convertUTCtoLocaleDate,
    getDateFormattedSeparator,
    getHourSecWithString,
} from "app/helpers/Utilities/date/Date.utilities";
import { useTranslation } from "react-i18next";
import { getValueTaskInstanceStatus } from "app/helpers/TaskInstance/getValueTaskInstanceStatus";
import { Badge } from "app/components_v2/Badge/Badge";
import { RowPositionModel } from "app/components_v2/Table/types";
import { validateStatus } from "app/helpers/__validates/validateTaskpillStatus";
import { TaskTranslations } from "app/translation/translationKeys";
import { TaskInstanceListDto } from "app/dtos/02-TAR/TaskInstance/TaskInstanceListDto";

type MobileTaskInstanceRowProps = {
    data: TaskInstanceListDto;
    onClick: () => void;
    rowPosition: RowPositionModel;
};

export const MobileTaskInstanceRow: FC<MobileTaskInstanceRowProps> = ({ data, onClick, rowPosition }) => {
    const { t } = useTranslation();
    const {
        taskName,
        asignedToUserName,
        asignedToDepartmentName,
        asignedToWorkingPositionName,
        deadlineDate,
        finishedByUserName,
        finishedDate,
        taskType,
    } = data;

    const asignedAvatarName = asignedToUserName?.length
        ? asignedToUserName
        : asignedToWorkingPositionName?.length
        ? asignedToWorkingPositionName
        : asignedToDepartmentName?.length
        ? asignedToDepartmentName
        : "";
    const asignedBadgeTitle =
        deadlineDate != null && taskType !== "SPORADIC"
            ? `${getDateFormattedSeparator(convertUTCtoLocaleDate(deadlineDate), "/")} ${getHourSecWithString(
                  convertUTCtoLocaleDate(deadlineDate)
              )}`
            : t(TaskTranslations.TASK_TEMPORALITY_SPORADIC);

    const statusInfo = validateStatus(data);
    const badgeTitle = `${getDateFormattedSeparator(
        convertUTCtoLocaleDate(finishedDate || new Date()),
        "/"
    )} ${getHourSecWithString(convertUTCtoLocaleDate(finishedDate || new Date()))}`;
    const { badgeVariant, icon, value } = getValueTaskInstanceStatus({
        t,
        type: statusInfo,
        date: badgeTitle,
    });

    return (
        <div className={`mobileTaskInstanceRow--${rowPosition}`} onClick={onClick}>
            <p className="mobileTaskInstanceRow__title">{taskName}</p>
            <div className="mobileTaskInstanceRow__body">
                <div className="mobileTaskInstanceRow__body__col">
                    <p className="mobileTaskInstanceRow__body__col__text">{asignedAvatarName}</p>
                    <Badge title={asignedBadgeTitle} variant="grey" />
                </div>
                <div className="mobileTaskInstanceRow__body__col">
                    {finishedByUserName && (
                        <p className="mobileTaskInstanceRow__body__col__text">{finishedByUserName}</p>
                    )}
                    <Badge title={value} variant={badgeVariant} icon={icon} iconPosition="left" />
                </div>
            </div>
        </div>
    );
};
