import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { useTranslation } from "react-i18next";
import { DocumentalFolderPageHeader } from "./DocumentalFolderPageHeader/DocumentalFolderPageHeader";
import { useEffect, useState } from "react";
import { DocumentalFolderListDto } from "app/dtos/07-DOC/documentalFolder/DocumentalFolderListDto";
import DocumentalFolderService from "app/services/07-DOC/DocumentalFolderService";
import { SecScreen } from "app/shared/Constants";
import { DocumentalFolder } from "app/components_v2/Documental/DocumentalFolder/DocumentalFolder";
import { useTitle } from "app/hooks";
import { DocumentalFolderTranslations, TranslationCommon, TranslationTitles } from "app/translation/translationKeys";
import { AddNewButton } from "app/components_v2/__buttons/AddNewButton/AddNewButton";
import { faFolder, faPen, faPlusCircle, faTrash } from "@fortawesome/pro-regular-svg-icons";
import Pagination from "app/components_v2/Pagination/Pagination";
import { DocumentalFolderSkelleton } from "app/components_v2/__skelletons/DocumentalFolderSkelleton/DocumentalFolderSkelleton";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { DocumentalFolderSortField } from "../models/DocumentalFolderPageModel";
import {
    INITIAL_DOCUMENTAL_FOLDER_PAGE_SORT_DIRECTION,
    INITIAL_DOCUMENTAL_FOLDER_PAGE_SORT_FIELD,
    PAGE_SIZE_DOCUMENTAL_FOLDER,
} from "../constants/DocumentalFolderPageConstants";
import { ListEmptyState } from "app/components_v2/ListEmptyState/ListEmptyState";
import { DocumentalFolderModal } from "app/components_v2/__modals/DocumentalFolderModal/DocumentalFolderModal";
import { DocumentalFolderDto } from "app/dtos/07-DOC/documentalFolder/DocumentalFolderDto";
import { useToast } from "app/hooks/Toast/useToast";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { hasPermissionToAdd, hasPermissionToDelete, hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { ActionType } from "app/models/FormComponentsModel";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { useNavigate } from "react-router-dom";
import { getDateWithTodayOrTomorrow } from "app/helpers/Utilities/date/Date.utilities";

type IGetData = {
    pi?: number;
    q?: string;
    sortF?: DocumentalFolderSortField;
    sortD?: SortedTypeModel;
};

export const DocumentalFolderPage = () => {
    const { t } = useTranslation();
    const nav = useNavigate();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();

    const [data, setData] = useState<DocumentalFolderListDto[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [query, setQuery] = useState<string>("");
    const [sortField, setSortField] = useState<DocumentalFolderSortField>(INITIAL_DOCUMENTAL_FOLDER_PAGE_SORT_FIELD);
    const [sortDirection, setSortDirection] = useState<SortedTypeModel>(INITIAL_DOCUMENTAL_FOLDER_PAGE_SORT_DIRECTION);
    const [isFolderModalOpen, setIsFolderModalOpen] = useState<boolean>(false);
    const [selectedFolder, setSelectedFolder] = useState<DocumentalFolderListDto | null>(null);
    const [isEditFolderModalOpen, setIsEditFolderModalOpen] = useState<boolean>(false);
    const [isDeleteFolderModalOpen, setIsDeleteFolderModalOpen] = useState<boolean>(false);
    const [isDeleteFolderModalLoading, setIsDeleteFolderModalLoading] = useState<boolean>(false);

    useTitle(t(TranslationTitles.DOCUMENTAL_MANAGER));

    const getData = async ({ pi, q, sortD, sortF }: IGetData = {}) => {
        setIsLoading(true);
        const piFetch = pi !== undefined ? pi : pageIndex - 1;
        const queryFetch = q !== undefined ? q : query;
        const sortDFetch = sortD !== undefined ? sortD : sortDirection;
        const sortFFetch = sortF !== undefined ? sortF : sortField;
        const sr = await DocumentalFolderService.GetData({
            pageIndex: piFetch,
            pageSize: PAGE_SIZE_DOCUMENTAL_FOLDER,
            query: queryFetch,
            sortDirection: sortDFetch,
            sortField: sortFFetch,
        });

        setIsLoading(false);
        if (!sr.status()) {
            setData([]);
            setTotal(0);
            return;
        }

        setData(sr.data.list);
        setTotal(sr.data.count);
    };

    const onChangePageIndex = async (pi: number) => {
        setPageIndex(pi);
        await getData({ pi: pi - 1 });
    };

    const onSearch = async (q: string) => {
        setPageIndex(1);
        setQuery(q);
        await getData({ pi: 0, q });
    };

    const onChangeOrderBy = async (sortF: DocumentalFolderSortField, sortD: SortedTypeModel) => {
        setSortField(sortF);
        setSortDirection(sortD);
        await getData({ sortD, sortF });
    };

    const onCreateNewFolder = async (newFolder: DocumentalFolderDto, type: ActionType) => {
        const service =
            type === "create" ? DocumentalFolderService.Save(newFolder) : DocumentalFolderService.Edit(newFolder);
        const { status, getParsedError } = await service;
        if (!status()) {
            handleToast({ title: t(getErrorMessage(getParsedError())), variant: "danger", type: "alert" });
            return;
        }

        setIsFolderModalOpen(false);
        onCloseModalFolder();
        await getData();
    };

    const onClickEditFolder = (folder: DocumentalFolderListDto) => {
        setIsEditFolderModalOpen(true);
        setSelectedFolder(folder);
    };

    const onCloseModalFolder = () => {
        setIsEditFolderModalOpen(false);
        setSelectedFolder(null);
        setIsFolderModalOpen(false);
    };

    const onClickDeleteFolder = (folder: DocumentalFolderListDto) => {
        setIsDeleteFolderModalOpen(true);
        setSelectedFolder(folder);
    };

    const onDeleteFolder = async () => {
        if (selectedFolder === null) return;
        setIsDeleteFolderModalLoading(true);
        const { status, getParsedError } = await DocumentalFolderService.Delete(selectedFolder.id);
        setIsDeleteFolderModalLoading(false);
        if (!status()) {
            handleToast({ title: t(getErrorMessage(getParsedError())), variant: "danger", type: "alert" });
            return;
        }

        setIsDeleteFolderModalOpen(false);
        setSelectedFolder(null);
        await getData();
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            {isDeleteFolderModalOpen && selectedFolder != null && (
                <ConfirmModal
                    title={t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_DELETE_MODAL_TITLE)}
                    description={t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_DELETE_MODAL_DESCRIPTION).replace(
                        "{0}",
                        selectedFolder.name
                    )}
                    onConfirm={onDeleteFolder}
                    onConfirmText={t(TranslationCommon.DELETE)}
                    isLoading={isDeleteFolderModalLoading}
                    onClose={() => setIsDeleteFolderModalOpen(false)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    type="delete"
                />
            )}
            {(isFolderModalOpen || (selectedFolder !== null && isEditFolderModalOpen)) && (
                <DocumentalFolderModal
                    type={isFolderModalOpen ? "create" : "edit"}
                    onClose={onCloseModalFolder}
                    onSubmit={onCreateNewFolder}
                    folderId={selectedFolder?.id}
                />
            )}
            <PageContainer
                fullHeight
                header={
                    <DocumentalFolderPageHeader
                        onChangeQuery={setQuery}
                        query={query}
                        onSearch={onSearch}
                        onChangeOrderBy={onChangeOrderBy}
                        sortDirection={sortDirection}
                        sortField={sortField}
                    />
                }
            >
                <div className="documentalFolderPage">
                    <div className="documentalFolderPage__header">
                        <p className="documentalFolderPage__totalFiles">
                            {`${total} ${
                                total === 1
                                    ? t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_FOLDER_S)
                                    : t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_FOLDER_P)
                            }`}
                        </p>
                        {hasPermissionToAdd(SecScreen.DOCUMENTAL_FOLDER) && (
                            <AddNewButton onClick={() => setIsFolderModalOpen(true)} icon={faPlusCircle}>
                                {t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_NEW_FOLDER)}
                            </AddNewButton>
                        )}
                    </div>
                    {isLoading ? (
                        <DocumentalFolderSkelleton quantity={10} />
                    ) : total === 0 ? (
                        <ListEmptyState icon={faFolder} title={t(TranslationCommon.NO_RESULTS)} />
                    ) : (
                        <div className="documentalFolderPage__container">
                            <div className="documentalFolderPage__list">
                                {data.map(({ createdDate, id, name, totalFiles }) => (
                                    <DocumentalFolder
                                        date={getDateWithTodayOrTomorrow(new Date(createdDate))}
                                        name={name}
                                        totalFiles={totalFiles}
                                        onClick={() => nav(`folder/${id}`)}
                                        moreOptionsItems={[
                                            {
                                                text: t(TranslationCommon.EDIT),
                                                onClick: () => onClickEditFolder({ createdDate, id, name, totalFiles }),
                                                hidden: !hasPermissionToEdit(SecScreen.DOCUMENTAL_FOLDER),
                                                icon: faPen,
                                            },
                                            {
                                                text: t(TranslationCommon.DELETE),
                                                onClick: () =>
                                                    onClickDeleteFolder({
                                                        createdDate,
                                                        id,
                                                        name,
                                                        totalFiles,
                                                    }),
                                                hidden: !hasPermissionToDelete(SecScreen.DOCUMENTAL_FOLDER),
                                                icon: faTrash,
                                            },
                                        ]}
                                        key={id}
                                    />
                                ))}
                            </div>
                            <Pagination
                                onClick={onChangePageIndex}
                                pageIndex={pageIndex}
                                total={total}
                                pageSize={PAGE_SIZE_DOCUMENTAL_FOLDER}
                            />
                        </div>
                    )}
                </div>
            </PageContainer>
        </>
    );
};
