import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { Legend } from "app/components_v2/Legend/Legend";
import { AuditTranslation } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type PercentageProps = {
    completedPercentatge: number;
    notSpecifiedPercentatge: number;
    nonConformityPercentatge: number;
    notApplyPercentatge: number;
};
type EditAuditLegendModalProps = {
    percentatge?: PercentageProps;
    onClose: () => void;
};

export const EditAuditLegendModal: FC<EditAuditLegendModalProps> = ({ onClose, percentatge }) => {
    const { t } = useTranslation();
    const { completedPercentatge, nonConformityPercentatge, notApplyPercentatge, notSpecifiedPercentatge } =
        percentatge || {};
    return (
        <GenericModal
            allowCloseOnEscape
            header={{ title: t(AuditTranslation.AUDIT_LEGEND_TITLE), onClose, variant: "appcc" }}
            size="fit-content"
        >
            <Legend
                items={[
                    {
                        color: "green",
                        label: t(AuditTranslation.AUDIT_COMPLETED_TITLE),
                        percentage: completedPercentatge,
                    },
                    {
                        color: "yellow",
                        label: t(AuditTranslation.AUDIT_NOT_SPECIFIED_TITLE),
                        percentage: notSpecifiedPercentatge,
                    },
                    {
                        color: "red",
                        label: t(AuditTranslation.AUDIT_NON_CONFORMITY),
                        percentage: nonConformityPercentatge,
                    },
                    {
                        color: "gray",
                        label: t(AuditTranslation.AUDIT_NOT_APPLY_TITLE),
                        percentage: notApplyPercentatge,
                    },
                ]}
            />
        </GenericModal>
    );
};
