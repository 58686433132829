import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useSuccessManager } from "app/hooks/SuccessHandler/useSuccessManager";
import PatchNoteVersionService from "app/services/01-SEG/PatchNoteVersionService";
import { faPaperPlane } from "@fortawesome/pro-regular-svg-icons";
import {
    PatchNotesTranslations,
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
} from "app/translation/translationKeys";
import { INITIAL_PATCH_NOTE } from "app/pages/01-SEG/PatchNotes/PatchNotesGrid/constants/PatchNotesConstants";
import { getInitials } from "app/helpers/Avatar/getInitials";
import { PatchNotesModalContext } from "app/state/context/PatchNotesModalContext/PatchNotesModalContext";
import { PatchNoteVersionModel } from "app/models/01-SEG/PatchNoteVersion/PatchNoteVersionModels";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { GenericModalFooter } from "../base/GenericModal/GenericModalFooter/GenericModalFooter";
import Spinner from "app/components_v2/Spinner/Spinner";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { Tag } from "app/components_v2/Tag/Tag";
import { useToast } from "app/hooks/Toast/useToast";

export const SendPatchNotesModal: FC = () => {
    const { t } = useTranslation();
    const { getErrorMessage } = useFetchErrors();
    const { handleErrorManager } = useErrorManager();
    const { handleSuccessManager } = useSuccessManager();
    const { handleToast } = useToast();
    const { patchNoteVersionId, closeModal, options } = useContext(PatchNotesModalContext);

    const [patchNoteVersion, setPatchNoteVersion] = useState<PatchNoteVersionModel>(INITIAL_PATCH_NOTE);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSendingLoading, setIsSendingLoading] = useState<boolean>(false);
    const [showSendModal, setShowSendModal] = useState<boolean>(false);

    const { name, title, patchNotes, customerInstances } = patchNoteVersion;

    const getPatchNoteVersion = async () => {
        const { data, status, getParsedError } = await PatchNoteVersionService.GetOne(patchNoteVersionId);
        if (handleErrorManager(status(), getErrorMessage(getParsedError()))) {
            closeModal();
            return;
        }
        setPatchNoteVersion(data);
        setIsLoading(false);
    };

    const sendPatchNotes = async () => {
        setIsSendingLoading(true);
        setShowSendModal(false);
        const { status, getParsedError } = await PatchNoteVersionService.SendPatchNotes(patchNoteVersionId);

        const errorMessage = getErrorMessage(getParsedError());
        if (errorMessage.includes(TranslationErrors.INVALID_EMAIL_ADDRESSESS)) {
            handleToast({
                title: t(TranslationErrors.INVALID_EMAIL_ADDRESSESS_TITLE),
                subtitle: t(TranslationErrors.INVALID_EMAIL_ADDRESSESS_SUBTITLE).replace(
                    "{0}",
                    errorMessage.split(" ")[0].replace(";", ", ")
                ),
                variant: "warning",
                type: "alert",
            });
            setIsSendingLoading(false);
            return;
        } else if (handleErrorManager(status(), errorMessage)) {
            setIsSendingLoading(false);
            return;
        }
        handleSuccessManager(t(PatchNotesTranslations.PATCH_NOTES_SEND_SUCCESSFULLY));
        setIsSendingLoading(false);
        closeModal();
    };

    useEffect(() => {
        getPatchNoteVersion();
        localStorage.removeItem("patchNoteVersionId");
        localStorage.removeItem("urlParamType");
    }, []);

    return (
        <>
            {showSendModal && (
                <ConfirmModal
                    title={`${t(PatchNotesTranslations.PATCH_NOTE_SEND_TITLE_LABEL)} (${patchNoteVersion.userTypes
                        .split(";")
                        .map((userType, i) =>
                            i < patchNoteVersion.userTypes.split(";").length && i !== 0
                                ? ` ${t(userType)}`
                                : t(userType)
                        )})`}
                    description={t(PatchNotesTranslations.PATCH_NOTE_SEND_DESCRIPTION_LABEL)}
                    onConfirmText={t(TranslationCommon.CONFIRM)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onConfirm={sendPatchNotes}
                    onClose={() => setShowSendModal(false)}
                >
                    <div className="sendPatchNotesModal__clients">
                        {customerInstances.map(({ value, label }) => (
                            <Tag
                                key={value}
                                message={label}
                                clamp={2}
                                size="auto"
                                variant="white"
                                avatar={{ id: value, name: label, initials: getInitials(label) }}
                            />
                        ))}
                    </div>
                </ConfirmModal>
            )}
            <GenericModal
                size="md"
                header={{
                    title: `${t(PatchNotesTranslations.PATCH_NOTES_VERSION_LABEL)} ${name}`,
                    onClose: closeModal,
                }}
                footer={
                    !options?.readonly ? (
                        <GenericModalFooter
                            confirmButton={{
                                text: t(TranslationKeys.SEND),
                                onClick: () => setShowSendModal(true),
                                iconLeft: faPaperPlane,
                                variant: "primary",
                            }}
                            closeButton={{
                                text: t(TranslationCommon.CANCEL),
                                onClick: closeModal,
                                buttonType: "tertiary",
                                variant: "primary",
                            }}
                            loading={!options?.readonly && isSendingLoading}
                        />
                    ) : undefined
                }
            >
                {isLoading ? (
                    <div className="sendPatchNotesModal__spinner">
                        <Spinner />
                    </div>
                ) : (
                    <div className="sendPatchNotesModal">
                        <h1 className="sendPatchNotesModal__title">{title}</h1>
                        <ul className="sendPatchNotesModal__notes">
                            {patchNotes.map(({ id, note }) => (
                                <li key={id} className="sendPatchNotesModal__note">
                                    {note}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </GenericModal>
        </>
    );
};
