import { FC } from "react";
import { AvatarSize } from "../types";
import { getAvatarColor } from "../helpers/getAvatarColor";
import { mergeClassNames } from "app/helpers/ClassNames/mergeClassNames";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type AvatarInitialsProps = {
    size: AvatarSize;
    colorId?: number;
    initials: string;
    showColor?: boolean;
    border?: boolean;
    hexColor?: string;
};

export const AvatarInitials: FC<AvatarInitialsProps> = ({ colorId, size, initials, showColor, border, hexColor }) => {
    return (
        <div
            className={mergeClassNames("avatarInitials", `avatarInitials--${size}`, {
                [`avatarInitials--${getAvatarColor(colorId)}`]: !!showColor && colorId !== undefined,
                "avatarInitials--noImage": !showColor,
                "avatarInitials--border": !!border,
            })}
            style={{ backgroundColor: hexColor }}
        >
            {!initials.length ? (
                <FontAwesomeIcon className={`avatarInitials__user`} icon={faUser} title="avatar no image" />
            ) : (
                initials
            )}
        </div>
    );
};
