import { isUserTypeAdmin } from "app/helpers/User/userType/userTypeCheck";
import { ViewActions, Views } from "../shared/Constants";
import { store } from "../state/reducer";
import { ReduxSession } from "app/models/SessionModel";

const whiteList = [Views.MY_PROFILE.toString()];

export const hasPermission = (viewCode: string) => {
    const state = store.getState();
    if (!state.session?.user.customerInstanceId) return true;
    return hasPermissionToView(state.session, viewCode);
};

export const hasPermissionToEdit = (viewCode: string) => {
    return checkPatent(viewCode, ViewActions.UPDATE);
};

export const hasPermissionToAdd = (viewCode: string) => {
    return checkPatent(viewCode, ViewActions.ADD);
};

export const hasPermissionToDelete = (viewCode: string) => {
    return checkPatent(viewCode, ViewActions.DELETE);
};

export const hasPermissionToImpersonate = (viewCode: string) => {
    return checkPatent(viewCode, ViewActions.IMPERSONATE);
};

export const hasPermissionToView = (session: ReduxSession | undefined, viewCode: string): boolean => {
    if (!session) return false;
    const { roles } = session;

    if (!session?.user.customerInstanceId || isUserTypeAdmin(session.user.userType)) return true;

    if (whiteList.includes(viewCode)) return true;

    if (roles?.some((r) => r.modules.some((m) => m.moduleCode?.toUpperCase() === viewCode))) {
        return true;
    }
    return roles?.some((r) =>
        r.modules.some((m) =>
            m.screenPatents.some(
                (s) =>
                    s.screenCode &&
                    s.screenCode.toUpperCase() === viewCode &&
                    s.patentCode &&
                    s.patentCode.toUpperCase() === ViewActions.VIEW
            )
        )
    );
};

export const hasAccessToSettings = (): boolean => {
    return checkPatent(Views.NAV_HEADER, ViewActions.SETTINGS);
};

const checkPatent = (viewCode: string, patentCode: ViewActions): boolean => {
    const { session } = store.getState();
    if (!session) return false;
    const { roles } = session;

    if (!session?.user.customerInstanceId || isUserTypeAdmin(session.user.userType)) return true;

    return roles.some((r) =>
        r.modules.some((m) =>
            m.screenPatents.some(
                (s) =>
                    s.screenCode &&
                    s.screenCode.toUpperCase() === viewCode &&
                    s.patentCode &&
                    s.patentCode.toUpperCase() === patentCode
            )
        )
    );
};
