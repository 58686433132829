import { Label } from "app/components_v2/__inputs";
import { ReadOnlyInput } from "app/components_v2/__inputs/ReadOnlyInput/ReadOnlyInput";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type DynamicFieldItemReadOnlyProps = {
    type: string;
    label: string;
    options: string[];
};

export const DynamicFieldItemReadOnly: FC<DynamicFieldItemReadOnlyProps> = ({ label, options, type }) => {
    const { t } = useTranslation();

    return (
        <div className="dynamicFieldItemReadOnly">
            {!!label.length ? <ReadOnlyInput value={label} label={type} /> : <Label label={type} />}
            {!!options.length && (
                <ul className="dynamicFieldItemReadOnly__options">
                    {options.map((option, i) => (
                        <li key={i}>
                            <ReadOnlyInput value={t(option)} />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
