import { FC, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSession } from "app/hooks/useSession";
import { PrivatePaths, PublicPaths, SecScreen } from "app/shared/Constants";
import { MarkableIcon } from "../MarkableIcon/MarkableIcon";
import {
    faListCheck,
    faStar as activeStar,
    faImage as activeImage,
    faRankingStar as activeRankingstar,
    faBell as faBellSolid,
    faCheckDouble as faCheckDoubleSolid,
    faTicketAirline as faTicketAirlineSolid,
    faLock as faLockSolid,
    faNotes as faNotesSolid,
} from "@fortawesome/pro-solid-svg-icons";
import {
    faStar as inactiveStar,
    faImage as inactiveImage,
    faRankingStar as inactiveRankingstar,
    faQrcode,
    faBell,
    faCheckDouble,
    faTicketAirline,
    faLock,
    faNotes,
} from "@fortawesome/pro-regular-svg-icons";
import { MobileFooterProps } from "./MobileFooterProps";
import { isCurrentPage } from "app/helpers/__validates/validateIsCurrentPage";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isiOS from "app/helpers/Mobile/isIos";
import { AvatarPopover } from "../__popovers/AvatarPopover/AvatarPopover";
import { HeaderContext } from "../Header/context/headerContext";
import { FullScreenQrModal } from "../__modals/FullScreenQrModal/FullScreenQrModal";
import { useVariant } from "app/hooks/useVariant";

export const MobileFooter: FC<MobileFooterProps> = () => {
    const { hasNotification, handleClearAllNotifications } = useContext(HeaderContext);
    const { pathname } = useLocation();
    const { variant } = useVariant();
    const session = useSession();
    const nav = useNavigate();

    const [showQrModal, setShowQrModal] = useState<boolean>(false);

    const isOnBoarding = session?.isOnBoarding;
    const customerInstanceId = session?.user.customerInstanceId;

    const hideFooter =
        pathname.includes(PrivatePaths.ACCEPT_POLICY) ||
        pathname.includes(PublicPaths.NEW_PASSWORD) ||
        pathname.includes(PrivatePaths.SELECT_DEPARTMENT) ||
        pathname.includes(PrivatePaths.AUDIT_INST_MANAG && PublicPaths.PRINT) ||
        pathname.includes(`${PrivatePaths.DASHBOARD}/qr`);

    const handleNotificationClick = () => {
        nav(`/${PrivatePaths.NOTIFICATION}`);
        handleClearAllNotifications();
    };

    const onScanQr = (qrCode: string) => {
        setShowQrModal(false);
        nav(`/${PrivatePaths.DASHBOARD}/qr/${qrCode}`);
    };

    const myValorationButton = hasPermissionToView(session, SecScreen.MY_VALORATION) && (
        <MarkableIcon
            variant={variant}
            isActive={isCurrentPage(PrivatePaths.FEEDBACK_TASKS)}
            onClick={() => nav(`/${PrivatePaths.FEEDBACK_TASKS}?comments=true`)}
            activeIcon={activeStar}
            inactiveIcon={inactiveStar}
        />
    );

    const reviewButton = hasPermissionToView(session, SecScreen.TASK_REVIEW) && (
        <MarkableIcon
            variant={variant}
            isActive={isCurrentPage(PrivatePaths.TASK_PHOTO)}
            onClick={() => nav(`/${PrivatePaths.TASK_PHOTO}`)}
            activeIcon={activeImage}
            inactiveIcon={inactiveImage}
        />
    );

    const analistRankingButton = hasPermissionToView(session, SecScreen.COMPANY_RANKING) && (
        <MarkableIcon
            variant={variant}
            isActive={isCurrentPage(PrivatePaths.COMPANY_RANKING)}
            onClick={() => nav(`/${PrivatePaths.COMPANY_RANKING}`)}
            activeIcon={activeRankingstar}
            inactiveIcon={inactiveRankingstar}
        />
    );

    const qrButton = (
        <>
            <div className={`containerQr  ${isiOS() ? "containerQrIos" : ""}`}>
                <FontAwesomeIcon className="qrIcon" onClick={() => setShowQrModal(!showQrModal)} icon={faQrcode} />
            </div>
            <div className="containerQr__ghostDiv"></div>
            {showQrModal && <FullScreenQrModal onClose={() => setShowQrModal(false)} onScanQr={onScanQr} />}
        </>
    );

    const auditButton = hasPermissionToView(session, SecScreen.AUDIT_INST_MANAG) && (
        <MarkableIcon
            variant={variant}
            isActive={isCurrentPage(PrivatePaths.AUDIT_INST_MANAG)}
            onClick={() => nav(`/${PrivatePaths.AUDIT_INST_MANAG}`)}
            activeIcon={faCheckDoubleSolid}
            inactiveIcon={faCheckDouble}
        />
    );

    if (hideFooter) return null;
    if (isOnBoarding)
        return (
            <div className={`mobileFooter ${isiOS() ? "paddingIos" : ""} mobileFooter--${variant}`}>
                <MarkableIcon
                    isActive={isCurrentPage(PrivatePaths.ON_BOARDING)}
                    onClick={() => nav(`/${PrivatePaths.ON_BOARDING}`)}
                    activeIcon={faTicketAirlineSolid}
                    inactiveIcon={faTicketAirline}
                    variant={variant}
                />
                <div className="mobileFooter__iconContainer">
                    <AvatarPopover position="topLeft" showBlur />
                </div>
            </div>
        );

    if (!customerInstanceId)
        return (
            <div className={`mobileFooter ${isiOS() ? "paddingIos" : ""} mobileFooter--${variant}`}>
                <MarkableIcon
                    isActive={isCurrentPage(PrivatePaths.LICENSE_PAGE)}
                    onClick={() => nav(`/${PrivatePaths.LICENSE_PAGE}`)}
                    activeIcon={faLockSolid}
                    inactiveIcon={faLock}
                    variant={variant}
                />
                <MarkableIcon
                    isActive={isCurrentPage(PrivatePaths.PATCH_NOTES)}
                    onClick={() => nav(`/${PrivatePaths.PATCH_NOTES}`)}
                    activeIcon={faNotesSolid}
                    inactiveIcon={faNotes}
                    variant={variant}
                />
                <div className="mobileFooter__iconContainer">
                    <AvatarPopover position="topLeft" showBlur />
                </div>
            </div>
        );

    return (
        <div className={`mobileFooter ${isiOS() ? "paddingIos" : ""} mobileFooter--${variant}`}>
            {/* 1. DASHBOARD */}
            <MarkableIcon
                variant={variant}
                isActive={isCurrentPage(PrivatePaths.DASHBOARD)}
                onClick={() => nav(`/${PrivatePaths.DASHBOARD}`)}
                activeIcon={faListCheck}
            />
            {/* 1. DASHBOARD END */}

            {/* 2. ND BUTTON */}
            <div className="mobileFooter__iconContainer">{reviewButton || auditButton || myValorationButton}</div>
            {/* 2. 2ND BUTTON END */}

            {/* 3. 3RD BUTTON */}
            {analistRankingButton || (!!reviewButton && auditButton) || qrButton}
            {/* 3. 3RD BUTTON END */}

            {/* 4. BELL */}
            <div className="mobileFooter__iconContainer relative">
                {hasPermissionToView(session, SecScreen.SYSTEM_NOTIFICATION) && (
                    <>
                        {hasNotification && <div className="markableIcon__notification" />}
                        <MarkableIcon
                            variant={variant}
                            isActive={isCurrentPage(PrivatePaths.NOTIFICATION)}
                            onClick={handleNotificationClick}
                            activeIcon={faBellSolid}
                            inactiveIcon={faBell}
                        />
                    </>
                )}
            </div>
            {/* 4. BELL END */}

            {/* 5. AVATAR */}
            <div className="mobileFooter__iconContainer">
                <AvatarPopover position="topLeft" showBlur />
            </div>
            {/* 5. AVATAR END */}
        </div>
    );
};
