import { getDateFormattedSelects } from "../Utilities/date/Date.utilities";

type Props = {
    closeDate?: Date;
    openDate?: Date;
};
export const isTodayBetweenDates = (dates: Props[]) => {
    return dates.some(
        ({ openDate, closeDate }) =>
            closeDate != null &&
            openDate != null &&
            getDateFormattedSelects(new Date(closeDate)) <= getDateFormattedSelects(new Date()) &&
            getDateFormattedSelects(new Date(openDate)) >= getDateFormattedSelects(new Date())
    );
};
