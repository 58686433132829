import { FC, useEffect, useState } from "react";
import { screenSize } from "../../shared/Constants";
import { SliderModal } from "../__modals/base/SliderModal/SliderModal";
import { TaskInstanceFeedbackMessageModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFeedbackMessageModel";
import { TaskPillDesktop } from "./TaskPillDesktop/TaskPillDesktop";
import { TaskPillMobile } from "./TaskPillMobile/TaskPillMobile";
import { TaskPillPhoto } from "../TaskPillPhoto/TaskPillPhoto";
import { TaskPillTranslations, TaskTranslations, TranslationKeys } from "app/translation/translationKeys";
import { TaskValorationModal } from "../__modals/TaskValorationModal/TaskValorationModal";
import { useSession } from "app/hooks";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "usehooks-ts";
import TaskInstanceFeedbackService from "app/services/02-TAR/TaskInstanceFeedbackService";
import type { ISlideChange, TaskPillMobileComponent, TaskPillProps, TaskPillState } from "./type";
import { AUDIO_RECORDER_FORMAT_TYPE } from "../AudioRecorder/helpers/audioRecorderHelper";

export const TaskPill: FC<TaskPillProps> = ({
    hour,
    startHour,
    onPillClick,
    title,
    date,
    state,
    hasPhoto,
    hasFields,
    isCritical,
    hasComents,
    user,
    isSupervisor,
    comment,
    stars,
    onStarsChange,
    showMagnifyGlass,
    displayOnly,
    carrouselPhotos,
    hideFeedback,
    hidePhotos,
    description,
    oneColumn,
    taskInstanceId,
    valorationMessage,
    starColor,
    starSize,
    audioURL,
    feedbackId,
    hideMobilePhotos,
    updateTaskInstance,
    disabled,
    completedDate,
    isMyFeedback,
    showMobileTaskPillOnly,
    nameInitials,
    editStarRating,
    taskInstanceCheckListMaxCount,
    taskInstanceCheckListCount,
    borderStyle = "box-shadow",
    isDisabledRange,
    clampComment,
    hidePadding = false,
    asignedToWorkingPositionId,
    taskType,
    isSporadic,
    startDate,
    taskTemporalityType,
    hasFieldsAndAtLeastOneIsEmpty,
}) => {
    const session = useSession();
    const { t } = useTranslation();
    const { width } = useWindowSize();

    type ICurrentImage = {
        image: string;
        index: number;
    };

    const [currentImage, setCurrentImage] = useState<ICurrentImage>({ image: "", index: 0 });
    const [showImage, setShowImage] = useState(false);
    const [isValorationModalOpen, setIsValorationModalOpen] = useState<boolean>(false);
    const [starsValoration, setStarsValoration] = useState<number>(stars || 0);
    const [lastStar, setLastStar] = useState<number>(0);
    const [message, setMessage] = useState<string>(valorationMessage || "");
    const [isSendingValoration, setIsSendingValoration] = useState<boolean>(false);
    const [valorationErrorMessage, setValorationErrorMessage] = useState<string>("");
    const [feedback, setFeedback] = useState<TaskInstanceFeedbackMessageModel>({
        id: feedbackId || 0,
        rating: 0,
        senderUserId: session?.user.id || 0,
        senderUserName: session?.user.firstName + " " + session?.user.lastName,
        taskInstanceId: 0,
        audioURL: audioURL || "",
        audioBase64: "",
        message: valorationMessage || "",
    });
    const [initialFeedback, setInitialFeedback] = useState<TaskInstanceFeedbackMessageModel>({
        id: feedbackId || 0,
        rating: 0,
        senderUserId: session?.user.id || 0,
        senderUserName: session?.user.firstName + " " + session?.user.lastName,
        taskInstanceId: 0,
        audioURL: audioURL || "",
        audioBase64: "",
        message: valorationMessage || "",
    });

    const badgetitle = (): string => {
        const mobileBadgeText: Record<TaskPillState, string> = {
            PENDING: t(TranslationKeys.TASK_START_HOUR_MOBILE),
            NEAR_END: t(TranslationKeys.TASK_ENDS_HOUR_MOBILE),
            TIMED_OUT: t(TranslationKeys.TASK_TIMED_OUT_HOUR_MOBILE),
            OUT_OF_TIME: t(TranslationKeys.TASK_OUT_OF_TIME_HOUR_MOBILE),
            IN_TIME: t(TranslationKeys.TASK_COMPLETED_HOUR_MOBILE),
        };

        if (taskType && taskType === "SPORADIC") return t(TaskTranslations.TASK_TEMPORALITY_SPORADIC);
        if (taskTemporalityType === "RANGE_DATES") {
            if (state === "PENDING" || state === "NEAR_END")
                return t(TaskPillTranslations.TASK_PILL_DATE_RANGE_PENDING_BADGE);
            if (state === "IN_TIME" || state === "OUT_OF_TIME") return `${mobileBadgeText[state]} ${hour} h`;
            return t(TranslationKeys.TASK_NOT_COMPLETED);
        }

        return disabled && state !== "IN_TIME" && state !== "OUT_OF_TIME"
            ? `${startHour} - ${hour} h`
            : `${mobileBadgeText[state]} ${hour} h`;
    };

    const isPhotoExample = state !== "IN_TIME" && state !== "OUT_OF_TIME";

    const handleCloseValorationModal = () => {
        setFeedback(initialFeedback);
        setIsValorationModalOpen(false);
        setStarsValoration(lastStar);
    };
    const handleValorate = async () => {
        setValorationErrorMessage("");
        setIsSendingValoration(true);
        const { data, status, error } = await TaskInstanceFeedbackService.SetFeedback({
            ...feedback,
            message,
            audioURL: feedback.audioURL,
            audioBase64: feedback.audioBase64,
            audioFormat: AUDIO_RECORDER_FORMAT_TYPE,
        });
        if (!status()) {
            setIsSendingValoration(false);
            console.error({ error });
            return;
        }
        updateTaskInstance && updateTaskInstance(data);
        onStarsChange && onStarsChange(starsValoration);
        setIsSendingValoration(false);
        setIsValorationModalOpen(false);
    };

    const handleStarsChange = (stars: number) => {
        setIsValorationModalOpen(true);
        setLastStar(starsValoration);
        setStarsValoration(stars);
        const feedbackValues = {
            rating: stars,
            senderUserId: session?.user.id || 0,
            senderUserName: session?.user.firstName + " " + session?.user.lastName,
            taskInstanceId: taskInstanceId || 0,
        };
        setFeedback((feedback) => ({ ...feedback, ...feedbackValues }));
        setInitialFeedback((feedback) => ({ ...feedback, ...feedbackValues }));
    };

    const handleSlideChange = ({ image, index }: ISlideChange) => {
        setCurrentImage({
            image,
            index,
        });
    };

    const showTaskPillDateOrIcons =
        !!date?.length ||
        !!completedDate?.length ||
        !!startHour?.length ||
        hasComents ||
        hasPhoto ||
        hasFields ||
        isCritical ||
        !!asignedToWorkingPositionId;

    const taskpillComponentProps: TaskPillMobileComponent = {
        asignedToWorkingPositionId,
        audioUrl: feedback.audioURL,
        badgetitle: badgetitle(),
        borderStyle,
        carrouselPhotos,
        clampComment,
        comment,
        completedDate,
        date,
        description,
        disabled,
        displayOnly,
        editStarRating,
        hasComents,
        hasFields,
        hasPhoto,
        hideMobilePhotos,
        hidePadding,
        hidePhotos,
        isCritical,
        isDisabledRange,
        isMyFeedback,
        isPhotoExample,
        isSporadic,
        isSupervisor,
        nameInitials,
        onClickMagnifyGlass: () => setShowImage(true),
        oneColumn,
        onPillClick,
        onSlideChange: handleSlideChange,
        onStarsChange: handleStarsChange,
        showMagnifyGlass,
        showTaskPillDateOrIcons,
        showTaskReview: (isSupervisor || !hideFeedback) && !!starsValoration,
        starColor,
        stars: Number(starsValoration || 0),
        starSize,
        startHour,
        state,
        taskInstanceCheckListCount,
        taskInstanceCheckListMaxCount,
        taskType,
        title,
        user,
        startDate,
        hasFieldsAndAtLeastOneIsEmpty,
    };

    const showTaskPillMobile = oneColumn || width < screenSize.TABLET || showMobileTaskPillOnly;

    useEffect(() => {
        if (carrouselPhotos) setCurrentImage({ image: carrouselPhotos[0].photo, index: 0 });
    }, [carrouselPhotos]);

    return (
        <>
            {!!showTaskPillMobile ? (
                <TaskPillMobile {...taskpillComponentProps} />
            ) : (
                <TaskPillDesktop {...taskpillComponentProps} />
            )}
            {showImage && (
                <SliderModal onClose={() => setShowImage(false)}>
                    <div className="taskpill__sliderModal">
                        {!!carrouselPhotos?.length && (
                            <TaskPillPhoto
                                taskInstancePhotos={carrouselPhotos}
                                onSlideChange={(index) =>
                                    handleSlideChange({
                                        image: carrouselPhotos[index].photo,
                                        index,
                                    })
                                }
                                currentIndex={currentImage.index}
                                fullScreen
                                isTaskDetail={oneColumn}
                                showCarrousel
                                onClickMagnifyGlass={() => setShowImage(true)}
                                showZoom={false}
                            />
                        )}
                    </div>
                </SliderModal>
            )}
            {isValorationModalOpen && (
                <TaskValorationModal
                    taskName={title}
                    user={{ ...user, nameInitials }}
                    feedback={feedback}
                    handleClose={handleCloseValorationModal}
                    handleSubmit={handleValorate}
                    message={message}
                    setMessage={setMessage}
                    setAudioUrl={(audioUrl) => setFeedback((prev) => ({ ...prev, audioURL: audioUrl }))}
                    setAudioBase64={(audioBase64) => setFeedback((prev) => ({ ...prev, audioBase64: audioBase64 }))}
                    isSending={isSendingValoration}
                    errorMessage={valorationErrorMessage}
                />
            )}
        </>
    );
};
