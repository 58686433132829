import { FC, useState } from "react";
import { useGetDynamicFieldsTranslations } from "app/hooks/dynamicFields/useGetDynamicFieldsTranslations";
import { ChecklistItemReadOnly } from "../ChecklistItemReadOnly/ChecklistItemReadOnly";
import { DynamicFieldsModel, CheckListModel, ReportType } from "app/models/02-TAR/TaskWizard";
import { DynamicFieldItemReadOnly } from "../DynamicFieldItemReadOnly/DynamicFieldItemReadOnly";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { Icon } from "app/components_v2/Icon/Icon";
import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { PreviewChecklistModal } from "app/components_v2/__modals/PreviewChecklistModal/PreviewChecklistModal";
import { PreviewDynamicFieldsModal } from "app/components_v2/__modals/PreviewDynamicFieldsModal/PreviewDynamicFieldsModal";

type DataToReportReadOnlyProps = {
    dynamicfields: DynamicFieldsModel[];
    checkList: CheckListModel[];
    reportType: ReportType;
    onEnable?: (index: number) => void;
};

export const DataToReportReadOnly: FC<DataToReportReadOnlyProps> = ({
    dynamicfields,
    checkList,
    reportType,
    onEnable,
}) => {
    const { t } = useTranslation();
    const { getDynamicFieldTranslation } = useGetDynamicFieldsTranslations();

    const [isPreviewChecklistModalVisible, setIsPreviewChecklistModalVisible] = useState<boolean>(false);
    const [isPreviewDynamicFieldsModalVisible, setIsPreviewDynamicFieldsModalVisible] = useState<boolean>(false);

    const handlePreview = () => {
        if (reportType === "CHECKLIST") {
            setIsPreviewChecklistModalVisible(true);
            return;
        }
        if (reportType === "DYNAMIC_FIELD") {
            setIsPreviewDynamicFieldsModalVisible(true);
            return;
        }
    };
    return (
        <>
            {isPreviewChecklistModalVisible && (
                <PreviewChecklistModal
                    checkList={checkList.filter(({ isDeleted, isEnabled }) => !isDeleted && isEnabled)}
                    onClose={() => setIsPreviewChecklistModalVisible(false)}
                />
            )}
            {isPreviewDynamicFieldsModalVisible && (
                <PreviewDynamicFieldsModal
                    dynamicfields={dynamicfields.filter(({ isDeleted }) => !isDeleted)}
                    onClose={() => setIsPreviewDynamicFieldsModalVisible(false)}
                />
            )}
            <div className="dataToReportReadOnly">
                <div className="dataToReportReadOnly__header">
                    <h2 className="dataToReportReadOnly__title">
                        {reportType === "DYNAMIC_FIELD"
                            ? t(TranslationKeys.DATA_TO_REPORT_DYNAMIC_FIELDS)
                            : t(TranslationKeys.TASK_DETAIL_CHECKLIST)}
                    </h2>
                    {((reportType === "CHECKLIST" && !!checkList.filter(({ name }) => !!name.length).length) ||
                        (reportType === "DYNAMIC_FIELD" &&
                            dynamicfields.filter(({ isDeleted }) => !isDeleted).length)) && (
                        <Icon icon={faEye} theme="light" onClick={handlePreview} />
                    )}
                </div>
                <div className={`dataToReportReadOnly__container dataToReportReadOnly__container`}>
                    {reportType === "DYNAMIC_FIELD" ? (
                        dynamicfields.map(
                            ({ dynamicFieldsType, label, taskFieldOptions, id, dropDownMultipleValues }) =>
                                !!dropDownMultipleValues.length ? (
                                    <DynamicFieldItemReadOnly
                                        label={label}
                                        type={getDynamicFieldTranslation(dynamicFieldsType)}
                                        options={dropDownMultipleValues.map(({ label }) => label)}
                                        key={id}
                                    />
                                ) : (
                                    <DynamicFieldItemReadOnly
                                        label={label}
                                        type={getDynamicFieldTranslation(dynamicFieldsType)}
                                        options={taskFieldOptions.map(({ label }) => label)}
                                        key={id}
                                    />
                                )
                        )
                    ) : (
                        <ChecklistItemReadOnly
                            labels={checkList.map(({ name }) => name)}
                            enableds={checkList.map(({ isEnabled }) => isEnabled)}
                            onEnable={onEnable}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
