import { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { Legend } from "app/components_v2/Legend/Legend";
import { useTranslation } from "react-i18next";
import { AuditTranslation } from "app/translation/translationKeys";

export const LegendSection: FC<SectionsShowProp> = ({ show }) => {
    const { t } = useTranslation();
    return (
        <Section title="Audit Legend" show={show}>
            <Legend
                items={[
                    { color: "green", label: t(AuditTranslation.AUDIT_COMPLETED_TITLE) },
                    { color: "yellow", label: t(AuditTranslation.AUDIT_NOT_SPECIFIED_TITLE) },
                    { color: "red", label: t(AuditTranslation.AUDIT_NON_CONFORMITY) },
                    { color: "gray", label: t(AuditTranslation.AUDIT_NOT_APPLY_TITLE) },
                ]}
            />
            <Legend
                items={[
                    { color: "green", label: t(AuditTranslation.AUDIT_COMPLETED_TITLE), percentage: 60 },
                    { color: "yellow", label: t(AuditTranslation.AUDIT_NOT_SPECIFIED_TITLE), percentage: 20 },
                    { color: "red", label: t(AuditTranslation.AUDIT_NON_CONFORMITY), percentage: 10 },
                    { color: "gray", label: t(AuditTranslation.AUDIT_NOT_APPLY_TITLE), percentage: 10 },
                ]}
            />
            <Legend
                items={[
                    {
                        color: "green",
                        label: t(AuditTranslation.AUDIT_COMPLETED_TITLE),
                        percentage: 60,
                        onClick: () => {},
                    },
                    {
                        color: "yellow",
                        label: t(AuditTranslation.AUDIT_NOT_SPECIFIED_TITLE),
                        percentage: 20,
                        onClick: () => {},
                    },
                    {
                        color: "red",
                        label: t(AuditTranslation.AUDIT_NON_CONFORMITY),
                        percentage: 10,
                        onClick: () => {},
                    },
                    {
                        color: "gray",
                        label: t(AuditTranslation.AUDIT_NOT_APPLY_TITLE),
                        percentage: 10,
                        onClick: () => {},
                    },
                ]}
            />
        </Section>
    );
};
