import { FC } from "react";
import { useCreateNotificationDistributionList } from "./hooks/useCreateNotificationDistributionList";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { useTranslation } from "react-i18next";
import {
    NotificationDistributionListTranslations,
    TranslationCommon,
    TranslationModals,
} from "app/translation/translationKeys";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { NotificationDistributionListFormValues } from "../../models/NotificationDistributionListFormValues";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { OptionsOutsideSelect, SelectOptions, Switch } from "app/components_v2/__inputs";
import { SecScreen } from "app/shared/Constants";
import Spinner from "app/components_v2/Spinner/Spinner";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { NotificationDistributionListUserOptions } from "../NotificationDistributionListUserOptions/NotificationDistributionListUserOptions";
import { NotificationDistributtionListEmailOptions } from "../NotificationDistributtionListEmailOptions/NotificationDistributtionListEmailOptions";
import { LabelAddAll } from "../LabelAddAll/LabelAddAll";

type CreateNotificationDistributionListUsersModalProps = {
    title: string;
    onClose: () => void;
    onSubmit: (values: NotificationDistributionListFormValues) => Promise<void>;
};

export const CreateNotificationDistributionListUsersModal: FC<CreateNotificationDistributionListUsersModalProps> = ({
    title,
    onClose,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();
    const {
        addAllCompaniesModalOpen,
        addAllUsersModalOpen,
        companyOptions,
        distributionListTypes,
        email,
        formValues,
        handleAddEmail,
        handleChangeCompany,
        handleChangeDistributionList,
        handleDeleteComapny,
        handleDeleteEmail,
        handleDeleteUser,
        handleInputChange,
        handleSubmit,
        isLoading,
        isLoadingSave,
        notificationDistributionListErrors,
        onConfirmAddAllCompanies,
        onConfirmAddAllUsers,
        setAddAllCompaniesModalOpen,
        setAddAllUsersModalOpen,
        setEmail,
        usersOptions,
    } = useCreateNotificationDistributionList({ onSubmit });

    const { errorFunctionality, errorCompany, errorUserList, errorEmails, errorNotSelected } =
        notificationDistributionListErrors;
    const {
        companyList,
        fK_DistributionList,
        userList,
        emails,
        notifyUsers,
        notifyEmails,
        notifyWorkers,
        notifySupervisors,
        notifyAnalists,
        notifyAdminClients,
    } = formValues;

    return (
        <GenericModal
            header={{ title, onClose }}
            size="md"
            footer={
                hasPermissionToEdit(SecScreen.DISTRIBUTION_LIST) && (
                    <GenericModalFooter
                        confirmButton={{
                            text: t(TranslationCommon.SAVE),
                            form: "notificationDistributionListUsers",
                            type: "submit",
                            disabled: !distributionListTypes.length,
                        }}
                        closeButton={{
                            text: t(TranslationCommon.CANCEL),
                            buttonType: "tertiary",
                            onClick: onClose,
                        }}
                        loading={isLoadingSave}
                    />
                )
            }
        >
            <>
                {addAllUsersModalOpen && (
                    <ConfirmModal
                        onClose={() => setAddAllUsersModalOpen(false)}
                        onConfirm={onConfirmAddAllUsers}
                        onConfirmText={t(TranslationCommon.ACCEPT)}
                        onCloseText={t(TranslationCommon.CANCEL)}
                        title={t(TranslationModals.NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_USERS_TITLE)}
                        description={t(TranslationModals.NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_USERS_DESCRIPTION)}
                    />
                )}
                {addAllCompaniesModalOpen && (
                    <ConfirmModal
                        onClose={() => setAddAllCompaniesModalOpen(false)}
                        onConfirm={onConfirmAddAllCompanies}
                        onConfirmText={t(TranslationCommon.ACCEPT)}
                        onCloseText={t(TranslationCommon.CANCEL)}
                        title={translateCustomerTypeKeys(
                            TranslationModals.NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_COMPANIES_TITLE,
                            { firstReplace: "PLURAL", isGenderSensitive: true }
                        )}
                        description={translateCustomerTypeKeys(
                            TranslationModals.NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_COMPANIES_DESCRIPTION,
                            { firstReplace: "PLURAL", isGenderSensitive: true }
                        )}
                    />
                )}
                <form onSubmit={handleSubmit} className="addUsersModal" id="notificationDistributionListUsers">
                    <div className="notificationDistributionList__modal">
                        <SelectOptions
                            isMulti={false}
                            onChange={handleChangeDistributionList}
                            options={distributionListTypes}
                            label={t(
                                NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_TYPE_LABEL
                            )}
                            selectedValue={fK_DistributionList}
                            placeholder={t(TranslationCommon.SELECT_OPTION)}
                            isLoading={isLoading}
                            errorMessage={errorFunctionality}
                        />
                        {!!fK_DistributionList.length &&
                            fK_DistributionList !== "3" &&
                            (isLoading ? (
                                <div className="notificationDistributionList__modal__spinner">
                                    <Spinner />
                                </div>
                            ) : (
                                <div className="createNotificationDistributionListUsersModal__label">
                                    <LabelAddAll
                                        label={translateCustomerTypeKeys(
                                            NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_COMPANY_LABEL,
                                            { firstReplace: "PLURAL" }
                                        )}
                                        onClick={() => setAddAllCompaniesModalOpen(true)}
                                    />
                                    <OptionsOutsideSelect
                                        onChange={(values) => handleChangeCompany(values, Number(fK_DistributionList))}
                                        options={companyOptions}
                                        values={companyList}
                                        placeholder={t(TranslationCommon.SELECT_OPTION)}
                                        isLoading={isLoading}
                                        errorMessage={errorCompany}
                                        onDeleteItem={handleDeleteComapny}
                                    />
                                </div>
                            ))}
                        {(!!formValues.companyList.length || (!isLoading && fK_DistributionList === "3")) && (
                            <div className="createNotificationDistributionListUsersModal__inputs">
                                <div className="createNotificationDistributionListUsersModal__inputs__content">
                                    <Switch
                                        label={t(
                                            NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_THROUGH_4LINK
                                        )}
                                        checked={notifyUsers}
                                        onChange={(value) => handleInputChange({ notifyUsers: value })}
                                    />
                                    {notifyUsers && (
                                        <NotificationDistributionListUserOptions
                                            errorUserList={errorUserList}
                                            isLoading={isLoading}
                                            notifyAnalists={notifyAnalists}
                                            notifySupervisors={notifySupervisors}
                                            notifyWorkers={notifyWorkers}
                                            notifyAdminClients={notifyAdminClients}
                                            selectedUsers={userList}
                                            userOptions={usersOptions}
                                            onDeleteUser={handleDeleteUser}
                                            onInputChange={handleInputChange}
                                            onAddAllUsersModalOpen={setAddAllUsersModalOpen}
                                        />
                                    )}
                                </div>
                                <div className="createNotificationDistributionListUsersModal__inputs__content">
                                    <Switch
                                        label={t(
                                            NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_BY_EMAIL
                                        )}
                                        checked={notifyEmails}
                                        onChange={(value) => handleInputChange({ notifyEmails: value })}
                                    />
                                    {notifyEmails && (
                                        <NotificationDistributtionListEmailOptions
                                            errorEmails={errorEmails}
                                            email={email}
                                            emails={emails}
                                            onAddEmail={handleAddEmail}
                                            onDeleteEmail={handleDeleteEmail}
                                            onChangeEmail={setEmail}
                                        />
                                    )}
                                </div>
                                {!!errorNotSelected.length && <ErrorMessage errorMessage={errorNotSelected} />}
                            </div>
                        )}
                    </div>
                </form>
            </>
        </GenericModal>
    );
};
