import { formatBigNumber } from "app/helpers/Utilities/number/formatBigNumbers";
import { AuditColortype } from "app/shared/types/AuditColorTypes";
import { AriaLabels } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export type MultiProgressBarItemProp = {
    value: number;
    color: AuditColortype;
};
type MultiProgressBarProps = {
    items: MultiProgressBarItemProp[];
};

export const MultiProgressBar: FC<MultiProgressBarProps> = ({ items }) => {
    const { t } = useTranslation();

    const total = items.reduce((acc, { value }) => acc + value, 0);
    return (
        <div className="multiProgressBar">
            {items.map(({ color, value }, i) => {
                const width = (value * 100) / total;
                return (
                    <div
                        className="multiProgressBar__item"
                        style={{ width: `${width}%` }}
                        aria-label={t(AriaLabels.AL_MULTIPROGRESSBAR_ITEM)}
                        key={i}
                    >
                        <p className="multiProgressBar__item__value" role="paragraph">
                            {formatBigNumber(value, 0)}
                        </p>
                        <span className={`multiProgressBar__item__bar multiProgressBar__item__bar--${color}`}></span>
                    </div>
                );
            })}
        </div>
    );
};
