import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useEditNotificationDistributionList } from "./hooks/useEditNotificationDistributionList";
import {
    NotificationDistributionListTranslations,
    TranslationCommon,
    TranslationModals,
} from "app/translation/translationKeys";
import {
    NotificationDistributionListFormValues,
    SelectedNotificationDistributionList,
} from "../../models/NotificationDistributionListFormValues";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { Switch } from "app/components_v2/__inputs";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { NotificationDistributionListUserOptions } from "../NotificationDistributionListUserOptions/NotificationDistributionListUserOptions";
import { NotificationDistributtionListEmailOptions } from "../NotificationDistributtionListEmailOptions/NotificationDistributtionListEmailOptions";

type EditCreateNotificationDistributionListUsersModalProps = {
    modalTitle: string;
    notificationDistributionList: SelectedNotificationDistributionList;
    onClose: () => void;
    onSubmit: (values: NotificationDistributionListFormValues) => Promise<void>;
};

const formId = "editNotificationDistributionListUsers";

export const EditCreateNotificationDistributionListUsersModal: FC<
    EditCreateNotificationDistributionListUsersModalProps
> = ({ onClose, onSubmit, modalTitle, notificationDistributionList }) => {
    const { t } = useTranslation();
    const {
        addAllUsersModalOpen,
        email,
        formValues,
        handleAddEmail,
        handleDeleteEmail,
        handleDeleteUser,
        handleInputChange,
        handleSubmit,
        isLoading,
        isSaving,
        onConfirmAddAllUsers,
        setAddAllUsersModalOpen,
        setEmail,
        title,
        userOptions,
        notificationDistributionListErrors,
    } = useEditNotificationDistributionList({ notificationDistributionList, onSubmit, onClose });
    const {
        fK_DistributionList,
        userList: selectedUsers,
        emails: selectedEmails,
        notifyUsers,
        notifyEmails,
        notifyWorkers: isSelectedNotifyWorkers,
        notifySupervisors: isSelectedNotifySupervisors,
        notifyAnalists: isSelectedNotifyAnalists,
        notifyAdminClients: isSelectedNotifyAdminClients,
    } = formValues;
    const { errorUserList, errorEmails, errorNotSelected } = notificationDistributionListErrors;

    return (
        <GenericModal
            header={{ title: modalTitle, onClose }}
            size="md-tall"
            footer={
                <GenericModalFooter
                    confirmButton={{
                        text: t(TranslationCommon.SAVE),
                        form: formId,
                        type: "submit",
                        disabled: isLoading,
                    }}
                    closeButton={{
                        text: t(TranslationCommon.CANCEL),
                        buttonType: "tertiary",
                        onClick: onClose,
                    }}
                    loading={isSaving}
                />
            }
        >
            <>
                {addAllUsersModalOpen && (
                    <ConfirmModal
                        onClose={() => setAddAllUsersModalOpen(false)}
                        onConfirm={onConfirmAddAllUsers}
                        onConfirmText={t(TranslationCommon.ACCEPT)}
                        onCloseText={t(TranslationCommon.CANCEL)}
                        title={t(TranslationModals.NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_USERS_TITLE)}
                        description={t(TranslationModals.NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_USERS_DESCRIPTION)}
                    />
                )}
                <form id={formId} onSubmit={handleSubmit} className="editNotificationDistributionListUsersModal">
                    <h2 className="editNotificationDistributionListUsersModal__title">{title}</h2>
                    <div className="editNotificationDistributionListUsersModal__content">
                        <Switch
                            label={t(
                                NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_USERS
                            )}
                            checked={notifyUsers}
                            onChange={(value) => handleInputChange({ notifyUsers: value })}
                        />
                        {notifyUsers && (
                            <NotificationDistributionListUserOptions
                                errorUserList={errorUserList}
                                isLoading={isLoading}
                                notifyAnalists={isSelectedNotifyAnalists}
                                notifySupervisors={isSelectedNotifySupervisors}
                                notifyWorkers={isSelectedNotifyWorkers}
                                notifyAdminClients={isSelectedNotifyAdminClients}
                                selectedUsers={selectedUsers}
                                userOptions={userOptions}
                                onDeleteUser={handleDeleteUser}
                                onInputChange={handleInputChange}
                                onAddAllUsersModalOpen={setAddAllUsersModalOpen}
                            />
                        )}
                    </div>
                    <div className="createNotificationDistributionListUsersModal__inputs__content">
                        <Switch
                            label={t(
                                NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_BY_EMAIL
                            )}
                            checked={notifyEmails}
                            onChange={(value) => handleInputChange({ notifyEmails: value })}
                        />
                        {notifyEmails && (
                            <NotificationDistributtionListEmailOptions
                                emails={selectedEmails}
                                email={email}
                                errorEmails={errorEmails}
                                onDeleteEmail={handleDeleteEmail}
                                onAddEmail={handleAddEmail}
                                onChangeEmail={setEmail}
                            />
                        )}
                    </div>
                    {errorNotSelected && <ErrorMessage errorMessage={errorNotSelected} />}
                </form>
            </>
        </GenericModal>
    );
};
