import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "../../shared/Constants";
import { CloseQRModel } from "app/models/01-SEG/CloseQRModel/CloseQRModel";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { QRManagementDto } from "app/dtos/01-SEG/QRManagementDto/QRManagementDto";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";

export default class QRManagementService {
    public static async GetOne(id: string) {
        return FetchService.get<CloseQRModel>({
            url: `${ApiPaths.SEG_API}/QRManagement/${id}`,
        });
    }

    public static async GetData(paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedResult<QRManagementDto>>({
            url: `${ApiPaths.SEG_API}/QRManagement`,
            paginationParams,
        });
    }

    public static async GetClosedQRList(id: number) {
        return FetchService.get<PaginatedResult<CloseQRModel>>({
            url: `${ApiPaths.SEG_API}/QRManagement/ClosedQRList/${id}`,
        });
    }

    public static async Edit(id: number, body: CloseQRModel[]) {
        return FetchService.put({
            url: `${ApiPaths.SEG_API}/QRManagement/${id}`,
            body: body,
        });
    }
}
