import { getDateFormattedSelects } from "app/helpers/Utilities/date/Date.utilities";

type IProps = {
    deadlineDate: Date;
    companyId: string;
    departmentId?: number;
};

export const SporadicTaskExtraparams = ({ deadlineDate, companyId, departmentId }: IProps): string => {
    const extraParams = new URLSearchParams();
    extraParams.append("deadlineDate", `${getDateFormattedSelects(deadlineDate)}`);
    extraParams.append("deadlineDate1", `${getDateFormattedSelects(deadlineDate)}`);

    if (companyId !== "-1") extraParams.append("CompanyId", companyId);
    if (departmentId) extraParams.append("AsignedToDepartmentId", String(departmentId));

    return extraParams.toString();
};
