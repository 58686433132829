import { getDateFormattedSelects } from "app/helpers/Utilities/date/Date.utilities";

type IProps = {
    companyId: string;
    openDate?: Date;
    openDate2?: Date;
    closedDate?: Date;
    closedDate2?: Date;
    assignedTo: string;
};
export const fillCounterExtraparams = ({
    companyId,
    closedDate2,
    closedDate,
    openDate2,
    openDate,
    assignedTo,
}: IProps) => {
    const extraparams = new URLSearchParams();
    if (companyId !== "-1") extraparams.append("CompanyId", companyId);
    if (!!closedDate) extraparams.append("closedDate", getDateFormattedSelects(closedDate));
    if (!!closedDate2) extraparams.append("closedDate2", getDateFormattedSelects(closedDate2));
    if (!!openDate) extraparams.append("openDate", getDateFormattedSelects(openDate));
    if (!!openDate2) extraparams.append("openDate2", getDateFormattedSelects(openDate2));
    if (!!assignedTo.length && assignedTo !== "-1") extraparams.append("assignedTo", assignedTo);

    return extraparams.toString();
};
