import { OmitedFilterData } from "app/components_v2/__filters/DashboardFilter/types";
import { DashboardFilterdata } from "app/helpers/__extraParams/dashboard/dashboardExtraparams";

export const DashboardFilterDataMapper = (filterData: OmitedFilterData, filterValue: number): DashboardFilterdata => {
    const defaultValues: Partial<DashboardFilterdata> = {
        completed: false,
        timedOut: false,
        pending: false,
        nearEnd: false,
    };

    const filterDataValues =
        filterValue === 1
            ? {
                  ...defaultValues,
                  pending: true,
                  nearEnd: true,
                  timedOut: true,
              }
            : {
                  ...defaultValues,
                  completed: true,
              };

    return { ...filterData, ...filterDataValues };
};
