import { FC } from "react";
import { useQRGrid } from "./hooks/useQRGrid";
import { useTitle } from "app/hooks";
import { useTranslation } from "react-i18next";
import {
    faCalendarXmark,
    faClose,
    faMagnifyingGlass,
    faSearch,
    faTriangleExclamation,
} from "@fortawesome/pro-regular-svg-icons";
import {
    CompanyTranslations,
    QRTranslations,
    TranslationCommon,
    TranslationKeys,
    TranslationTitles,
} from "app/translation/translationKeys";
import { PaginationDefaults, SecScreen } from "app/shared/Constants";
import { hasPermission } from "app/routes/HelperRoleBasedAccess";
import { ColumnsType } from "app/components_v2/Table/types";
import { QRManagementDto } from "app/dtos/01-SEG/QRManagementDto/QRManagementDto";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { Badge } from "app/components_v2/Badge/Badge";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { ActionButtonsModel } from "app/components_v2/Table/TableTabHeader/TableTabHeader";
import { Input } from "app/components_v2/__inputs";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { CloseQRModal } from "./components/CloseQRModal/CloseQRModal";
import { MobileQRGridRow } from "./components/MobileQRGridRow/MobileQRGridRow";
import { getTotalClossedDates } from "app/helpers/closeDates/getTotalClosedDates";

type QRGridProps = {
    companyId: number;
};

export const QRGrid: FC<QRGridProps> = ({ companyId }) => {
    const { t } = useTranslation();
    const {
        closeQRList,
        isLoadingCloseQR,
        isLoading,
        onAddNewRangeHour,
        onChangePageIndex,
        onCloseCloseQR,
        onClosingDates,
        onDeleteNewRangeHour,
        onSearch,
        onSubmitCloseQR,
        openCloseQRModal,
        pageIndex,
        QRList,
        query,
        setQuery,
        setShowSearchInput,
        showCloseQRModal,
        showSearchInput,
        total,
        selectedQR,
    } = useQRGrid({ companyId });

    useTitle(t(TranslationTitles.QR_MANAGEMENT_PAGE_TITLE));

    const columns: ColumnsType<QRManagementDto>[] = [
        {
            label: t(TranslationKeys.NAME),
            dataIndex: "name",
            render: ({ name }) => {
                return <CellTitle title={name} />;
            },
        },
        {
            label: t(QRTranslations.QR_STATUS),
            dataIndex: "Status",
            render: ({ status }) => <Badge title={t(status)} variant={status === "CLOSED" ? "red" : "green"} />,
        },
        {
            label: t(CompanyTranslations.TOTAL_CLOSURES_LABEL),
            dataIndex: "Status",
            render: ({ closeQr }) => {
                const clossedDates = getTotalClossedDates(closeQr);
                const totalClosures = clossedDates?.length || 0;
                return <CellTitle title={!!totalClosures ? totalClosures : "-"} />;
            },
            sortedType: "default",
        },
        {
            label: t(TranslationKeys.ACTIONS),
            dataIndex: "edit",
            className: "issueGrid__edit",
            alignCenter: true,
            render: (record) => (
                <div className="assetGrid__edit">
                    <CellIcons
                        icons={[
                            {
                                icon: faCalendarXmark,
                                onClick: () => openCloseQRModal(record),
                                title: t(QRTranslations.CLOSE_QR),
                                hidden: !hasPermission(SecScreen.QR_MANAGEMENT),
                            },
                        ]}
                    />
                </div>
            ),
            hidden: !hasPermission(SecScreen.QR_MANAGEMENT),
        },
    ];

    const actionButtons: ActionButtonsModel[] = [
        {
            icon: faMagnifyingGlass,
            onClick: () => setShowSearchInput(true),
            inputComponent: {
                component: (
                    <Input
                        focus
                        onChange={setQuery}
                        iconRight={!!query.length ? faSearch : faClose}
                        value={query}
                        onClickIcon={onSearch}
                        onPressEnter={onSearch}
                        whiteInput
                        transparent
                    />
                ),
                show: showSearchInput,
            },
            title: t(TranslationCommon.SEARCH),
        },
    ];

    return (
        <div className="companiesDepartment">
            {hasPermission(SecScreen.QR_MANAGEMENT) && showCloseQRModal && (
                <CloseQRModal
                    closeDates={closeQRList.map(({ closeDate, openDate }) => [
                        closeDate || undefined,
                        openDate || undefined,
                    ])}
                    qrName={selectedQR?.name || ""}
                    isLoading={isLoadingCloseQR}
                    onClose={onCloseCloseQR}
                    onClosingDates={onClosingDates}
                    onAddNewRangeHour={onAddNewRangeHour}
                    onDeleteNewRangeHour={onDeleteNewRangeHour}
                    onSubmit={onSubmitCloseQR}
                />
            )}
            <TableCollapsable
                cols={columns.filter((col) => !col.hidden)}
                data={QRList}
                placeholder={<TableError title={t(QRTranslations.NO_QRS)} icon={faTriangleExclamation} />}
                title={t(TranslationTitles.DEPARTMENT_TITLE)}
                subTitle={`${total} ${t(TranslationTitles.DEPARTMENT_TITLE)}`}
                isLoading={isLoading}
                pageIndex={pageIndex}
                pageSize={PaginationDefaults.PAGE_SIZE}
                total={total}
                onChangePageIndex={onChangePageIndex}
                actionButtons={actionButtons}
                onDoubleClick={(record) => openCloseQRModal(record)}
                mobileBody={(row) => (
                    <MobileQRGridRow data={row} key={row.id} onCloseQR={() => openCloseQRModal(row)} />
                )}
                fitHeight
            />
        </div>
    );
};
