import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { NotificationIcon } from "app/components_v2/NotificationIcon/NotificationIcon";
import { TranslationCommon } from "app/translation/translationKeys";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { NotificationVariantModel } from "app/models/NotificationVariant";
import { TableErrorSize } from "./types";

type TableErrorProps = {
    icon: IconProp;
    title?: string;
    description?: string;
    variant?: NotificationVariantModel;
    actions?: ReactNode;
    size?: TableErrorSize;
    flex?: boolean;
};

export const TableError: FC<TableErrorProps> = ({ description, icon, title, variant, actions, size = "lg", flex }) => {
    const { t } = useTranslation();
    return (
        <div className="tableError">
            <div className="tableError__container">
                <div className={`tableError__container__content${flex ? "--flex" : ""}`}>
                    <NotificationIcon variant={variant} icon={icon} size={size} />
                    <div className="tableError__container__content__textContainer">
                        <h2
                            className={`tableError__container__content__textContainer__title${
                                variant === "gray" ? "--disabled" : ""
                            }${size == "sm" ? "--sm" : ""}`}
                        >
                            {title || t(TranslationCommon.NO_RESULTS)}
                        </h2>
                        {description && (
                            <p
                                className={`tableError__container__content__textContainer__description${
                                    variant === "gray" ? "--disabled" : ""
                                }`}
                            >
                                {description}
                            </p>
                        )}
                    </div>
                </div>
                {actions && <div className="tableError__container__actions">{actions}</div>}
            </div>
        </div>
    );
};
