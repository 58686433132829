import { FC } from "react";
import { IssueBodyAssetField } from "../IssueBodyAssetField/IssueBodyAssetField";
import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";

export type IssueBodyAssetFieldListProps = {
    taskInstanceFields2?: TaskInstanceFieldModel[];
};

export const IssueBodyAssetFieldList: FC<IssueBodyAssetFieldListProps> = ({ taskInstanceFields2 = [] }) => {
    return (
        <ul className="issueBodyAssetFieldList">
            {taskInstanceFields2.map(({ asset2, taskInstanceFieldValues, label, id }) => {
                if (!asset2) return null;

                const value = taskInstanceFieldValues[0].value;
                const min = taskInstanceFieldValues[0].min;
                const max = taskInstanceFieldValues[0].max;
                const measureUnit = taskInstanceFieldValues[0].measureUnit;

                if (min == null && max == null) return null;
                if (min != null && max != null && Number(value) >= min && Number(value) <= max) return null;
                if (min != null && max == null && Number(value) >= min) return null;
                if (min == null && max != null && Number(value) <= max) return null;

                return (
                    <IssueBodyAssetField
                        max={max}
                        min={min}
                        measureUnit={measureUnit}
                        fieldValue={value}
                        name={label}
                        key={id}
                    />
                );
            })}
        </ul>
    );
};
