import { FC, ReactNode } from "react";
import { getDateFormatted } from "app/helpers/Utilities/date/Date.utilities";
import { BarDataset } from "app/components_v2/BarChart/types";
import { AuditInstanceModel } from "app/models/05-QUA/AuditInstanceModels/AuditInstanceModels";
import { UserValorationVariantModel } from "app/components_v2/UserValoration/UserValoration";
import { WhiteBox } from "app/components_v2/WhiteBox/WhiteBox";
import Spinner from "app/components_v2/Spinner/Spinner";
import { AuditReportBarChart } from "./components/AuditReportBarChart/AuditReportBarChart";
import { AuditReportGrid } from "./components/AuditReportGrid/AuditReportGrid";
import { multiplyAndFormat } from "app/helpers/Utilities/number/multiplyAndFormat";

type AuditReportProps = {
    auditInstance?: AuditInstanceModel;
    auditHistory: AuditInstanceModel[];
    isLoading: boolean;
    auditStarsNode: (grade: number | null, starVariant?: UserValorationVariantModel) => ReactNode;
};

export const AuditReport: FC<AuditReportProps> = ({ auditInstance, auditHistory, isLoading, auditStarsNode }) => {
    const roundGrade = (grade: number | null) => multiplyAndFormat(grade ?? 0, 100);
    const auditBarChartData: BarDataset[] = [
        {
            color: "green",
            data: auditHistory
                .filter(({ grade }) => grade != null && grade !== 0)
                .map(({ grade }) => Number(roundGrade(grade))),
        },
    ];

    const labels: string[] = auditHistory
        .filter(({ grade }) => grade != null && grade !== 0)
        .map(({ openDate }) => getDateFormatted(new Date(openDate)));

    return (
        <div className="fullHeight">
            {isLoading ? (
                <div className="spinnerFullHeightCenteredContainer">
                    <Spinner />
                </div>
            ) : (
                <div className="auditReport">
                    {!!auditInstance && <AuditReportGrid data={auditInstance} auditStarsNode={auditStarsNode} />}
                    <WhiteBox>
                        <AuditReportBarChart labels={labels} auditBarChartData={auditBarChartData} />
                    </WhiteBox>
                </div>
            )}
        </div>
    );
};
