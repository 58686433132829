import { FC } from "react";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "app/components_v2/Badge/Badge";
import { GetUserTypeDescription } from "app/helpers/User/userType/getUserTypeDescription";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RowPositionModel } from "app/components_v2/Table/types";
import { UserListDto } from "app/dtos/01-SEG/UserListDto";
import { Avatar } from "app/components_v2/Avatar/Avatar";
import { getUserTypeColor } from "app/helpers/User/userType/getUserTypeColor";

type MobileUserRowProps = {
    user: UserListDto;
    rowPosition: RowPositionModel;
};

export const MobileUserRow: FC<MobileUserRowProps> = ({ user, rowPosition }) => {
    const nav = useNavigate();
    const { t } = useTranslation();

    const { firstName, lastName, userCode, roleNameList, id, userType, profilePictureURL } = user;
    return (
        <div className={`mobileUserRow--${rowPosition}`} onDoubleClick={() => nav(`edit/${id}`)}>
            <div className="mobileUserRow__container">
                <div className="mobileUserRow__container__text">
                    <div className="flex--between">
                        <Avatar
                            colorId={id}
                            img={profilePictureURL || ""}
                            name={`${firstName} ${lastName}`}
                            size="xs"
                        />
                        <Badge variant={getUserTypeColor(userType)} title={GetUserTypeDescription(userType, t)} />
                    </div>
                    <p className="mobileUserRow__userCode">{userCode}</p>
                    <p className="mobileUserRow__userCode">{roleNameList?.map((roleName) => roleName)?.join(" - ")}</p>
                </div>
            </div>
            <div className="mobileUserRow__edit" onClick={() => nav(`edit/${id}`)}>
                <FontAwesomeIcon icon={faPen} className="mobileUserRow__edit__icon" />
            </div>
        </div>
    );
};
