import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "app/components_v2/Spinner/Spinner";
import { convertFileToBase64 } from "app/helpers/Media/converter/webPConverter";
import { isPhoto } from "app/helpers/__validates/media/validateIsPhoto";
import { ChangeEvent, FC, useState } from "react";
import { faFilePlus } from "@fortawesome/pro-regular-svg-icons";
import { compressImage } from "app/helpers/Media/compress/compressImage";
import { FileData } from "app/shared/types/FileDataTypes";
import { validTypes } from "app/shared/Constants";

type UploadFileProps = {
    handleFile: (base64String: string, file: File) => void;
    values?: FileData[];
    multiple?: boolean;
    maxFiles?: number;
};

export const UploadFile: FC<UploadFileProps> = ({ handleFile, multiple, maxFiles = 10, values = [] }) => {
    const [loading, setloading] = useState<boolean>(false);

    const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
        setloading(true);

        const files = e.target.files;
        if (!files) {
            setloading(false);
            return;
        }

        const chosenFiles: File[] = Array.from(files);
        const currentLength = values.length;
        const filesSliced = chosenFiles.slice(0, maxFiles - currentLength);

        for (let i = 0; i < filesSliced.length; i++) {
            const file = filesSliced[i];
            if (isPhoto(file)) {
                const compressedFiles = await compressImage({ file, quality: 0.6 });
                const base64DataUrl = await convertFileToBase64(compressedFiles);
                if (base64DataUrl == null) {
                    console.error("Something went wrong...");
                    return;
                }

                handleFile(base64DataUrl, compressedFiles as File);
                setloading(false);
            } else {
                const reader = new FileReader();

                reader.onload = (event: ProgressEvent<FileReader>) => {
                    const base64String = event.target?.result as string;

                    handleFile(base64String, file);
                    setloading(false);
                };

                file && reader.readAsDataURL(file);
            }
        }
        setloading(false);
    };

    return (
        <label className="uploadFile">
            {loading ? <Spinner /> : <FontAwesomeIcon className="uploadFile__icon" icon={faFilePlus} />}

            <input
                id={"inputFile"}
                className="uploadFile__input"
                type="file"
                onChange={handleFileUpload}
                multiple={multiple}
                accept={validTypes.join(",")}
            />
        </label>
    );
};
