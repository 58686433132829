import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CompanyTranslations, DepartmentTranslations, TranslationCommon } from "app/translation/translationKeys";
import { faCalendarXmark, faPen, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { SecScreen } from "app/shared/Constants";
import { hasPermission, hasPermissionToAdd, hasPermissionToDelete } from "app/routes/HelperRoleBasedAccess";
import { DepartmentModel } from "app/models/01-SEG/Department/DepartmentModel";
import { RowPositionModel } from "app/components_v2/Table/types";
import { Badge } from "app/components_v2/Badge/Badge";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { getTotalClossedDates } from "app/helpers/closeDates/getTotalClosedDates";
import { isTodayBetweenDates } from "app/helpers/closeDates/isTodayBetweenDates";

type DepartmentGridRowProps = {
    data: DepartmentModel;
    rowPosition: RowPositionModel;
    onEdit: () => void;
    onDelete: () => void;
    onCloseDepartment: () => void;
};

export const DepartmentGridRow: FC<DepartmentGridRowProps> = ({
    data,
    rowPosition,
    onEdit,
    onDelete,
    onCloseDepartment,
}) => {
    const { t } = useTranslation();

    const { name, closeDepartments } = data;

    const clossedDates = getTotalClossedDates(closeDepartments);
    const totalClosures = clossedDates?.length || 0;
    const isClosed = !!totalClosures && isTodayBetweenDates(clossedDates || []);

    return (
        <div className={`departmentGridRow--${rowPosition}`}>
            <div className="departmentGridRow__header">
                <p className="departmentGridRow__name">{name}</p>
                <div className="departmentGridRow__icons">
                    <Badge
                        title={isClosed ? t(TranslationCommon.CLOSED) : t(TranslationCommon.OPEN)}
                        variant={isClosed ? "red" : "green"}
                    />
                    <CellIcons
                        icons={[
                            {
                                icon: faCalendarXmark,
                                onClick: () => onCloseDepartment,
                                title: t(DepartmentTranslations.CLOSE_DEPARTMENT),
                                hidden: !hasPermission(SecScreen.CLOSE_COMPANY_DEPT),
                            },
                            {
                                icon: faPen,
                                onClick: onEdit,
                                title: t(TranslationCommon.EDIT),
                                hidden: !hasPermissionToAdd(SecScreen.TEAM_MANAGEMENT),
                            },
                            {
                                icon: faTrash,
                                onClick: onDelete,
                                title: t(TranslationCommon.DELETE),
                                hidden: !hasPermissionToDelete(SecScreen.TEAM_MANAGEMENT),
                            },
                        ]}
                    />
                </div>
            </div>
            <p className="mobileCompanyGridBody__body__totalCloures">
                {!!totalClosures
                    ? `${t(CompanyTranslations.TOTAL_CLOSURES).replace("{0}", totalClosures.toString())}`
                    : t(CompanyTranslations.NO_CLOSURES)}
            </p>
        </div>
    );
};
