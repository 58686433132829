import { FC } from "react";
import { WhiteBox } from "../WhiteBox/WhiteBox";
import { TableError } from "../Table/TableError/TableError";
import { TableErrorSize } from "../Table/TableError/types";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type ListEmptyStateProps = {
    icon: IconProp;
    title: string;
    description?: string;
    size?: TableErrorSize;
};

export const ListEmptyState: FC<ListEmptyStateProps> = ({ icon, title, size = "xl", description }) => {
    return (
        <div className="listEmptyState">
            <WhiteBox fullHeight>
                <TableError icon={icon} title={title} size={size} description={description} />
            </WhiteBox>
        </div>
    );
};
