import { useSession } from "app/hooks";
import { useTranslation } from "react-i18next";
import { TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { GetUserTypeDescription } from "app/helpers/User/userType/getUserTypeDescription";

type FormDataModel = {
    username: string;
    email: string;
    name: string;
    lastName: string;
    userType: string;
    userCode: string;
    userRoleModelList: string[];
};

export const MyInformation = () => {
    const session = useSession();
    const { t } = useTranslation();
    const formValues: FormDataModel = {
        username: session?.user.username || "",
        email: session?.user.email || "",
        name: session?.user.firstName || "",
        lastName: session?.user.lastName || "",
        userType: GetUserTypeDescription(session?.user.userType || "WORKER", t),
        userCode: session?.user.userCode || "",
        userRoleModelList: session?.user.userRoleModelList?.map(({ roleName }) => roleName) || [],
    };
    const { email, username, userType, userCode, userRoleModelList } = formValues;

    return (
        <>
            <p className="myInformation__title">{t(TranslationKeys.MY_DATA)}</p>
            <div className="myInformation">
                <div className="myInformation__item">
                    <label className="myInformation__item__label">{t(TranslationKeys.LABEL_USERNAME)}</label>
                    <p className="myInformation__item__text">{username}</p>
                </div>
                <div className="myInformation__item">
                    <label className="myInformation__item__label">{t(TranslationKeys.LABEL_EMAIL)}</label>
                    <p className="myInformation__item__text">{email} </p>
                </div>
                <div className="myInformation__item">
                    <label className="myInformation__item__label">{t(TranslationKeys.USER_TYPE)}</label>
                    <p className="myInformation__item__text">{userType}</p>
                </div>
                <div className="myInformation__item">
                    <label className="myInformation__item__label">{t(TranslationKeys.LABEL_USER_CODE)}</label>
                    <p className="myInformation__item__text">{userCode}</p>
                </div>
                <div className="myInformation__item">
                    <label className="myInformation__item__label">
                        {t(TranslationTitles.PERMISSION_TITLE_ROLE_MANAGEMENT)}
                    </label>
                    <ul className="myInformation__item__list">
                        {userRoleModelList.map((role, i) => (
                            <p className="myInformation__item__text" key={i}>
                                {role}
                            </p>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
};
