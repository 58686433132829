import { faCheckDouble } from "@fortawesome/pro-solid-svg-icons";
import { useTitle } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import AlertNotificationService from "app/services/02-TAR/AlertNotificationService";
import {
    TranslationCommon,
    TranslationKeys,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AttachedFile } from "../../AttachedFile/AttachedFile";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { GenericModalFooter } from "../base/GenericModal/GenericModalFooter/GenericModalFooter";
import { ImageModal } from "../base/ImageModal/ImageModal";
import { SliderModal } from "../base/SliderModal/SliderModal";
import { ReceiverNotificationDto } from "app/dtos/02-TAR/AlertNotification/ReceiverNotificationDto";
import { AlertNotificationsModalHeader } from "./AlertNotificationsModalHeader/AlertNotificationsModalHeader";
import { AlertNotificationsModalInfo } from "./AlertNotificationsModalInfo/AlertNotificationsModalInfo";
import { AlertNotificationModalCollapsables } from "./AlertNotificationModalCollapsables/AlertNotificationModalCollapsables";
import { isBase64Audio } from "app/helpers/Media/base64/isBase64Audio";
import { isBase64Photo } from "app/helpers/Media/base64/isBase64Image";
import { isBase64Video } from "app/helpers/Media/base64/isBase64Video";
import { isBase64File } from "app/helpers/Media/base64/isBase64File";
import { AlertModel } from "./types";
import { getDayMonthFormatedWithYearNumbers, getHoursFromDate } from "app/helpers/Utilities/date/Date.utilities";

type AlertNotificationsModalProps = {
    data: AlertModel[];
    closeModal: () => void;
    alreadyReaded?: boolean;
};

export const AlertNotificationsModal: FC<AlertNotificationsModalProps> = ({ data, closeModal, alreadyReaded }) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();

    useTitle(t(TranslationTitles.INCOMING_ALERTS_TITLE));

    const [showImage, setShowImage] = useState<boolean>(false);
    const [imageURL, setImageURL] = useState<string | undefined>(undefined);
    const [currentAlert, setCurrentAlert] = useState<AlertModel>(data[0]);
    const [alerts, setAlerts] = useState<AlertModel[]>(data);
    const [error, setError] = useState<boolean>(false);
    const [currentIndex, setCurrentIndex] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [seenUsers, setSeenUsers] = useState<ReceiverNotificationDto[]>([]);
    const [unSeenUsers, setUnSeenUsers] = useState<ReceiverNotificationDto[]>([]);

    const infoDate = `${getDayMonthFormatedWithYearNumbers(currentAlert.date.toString(), "/")} ${getHoursFromDate(
        currentAlert.date.toString()
    )}h`;
    const confirmButtonText = error
        ? t(TranslationKeys.REVIEW_LATER)
        : alreadyReaded
        ? t(TranslationCommon.CLOSE)
        : t(TranslationKeys.SEEN);

    const handleShowImage = (url: string | undefined) => {
        setImageURL(url);
        setShowImage(true);
    };

    const handleComplete = async (id: number) => {
        setIsLoading(true);
        const { status } = await AlertNotificationService.Edit(id);
        if (!status()) {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            setIsLoading(false);
            throw new Error(t(TranslationKeys.FAILED_ALERT_SENT));
        }
        setIsLoading(false);
    };

    const handleNextAlertAsync = async (id: number) => {
        try {
            if (!alreadyReaded)
                return await handleComplete(id).then(() => {
                    handleNext(id);
                });
            handleNext(id);
        } catch (error: any) {
            console.error(error);
            setError(true);
        }
    };

    const handleNext = (id: number) => {
        const alertsFiltered = alerts.filter((alert) => alert.id !== id);
        setAlerts(alertsFiltered);

        if (alertsFiltered.length >= 1) {
            setCurrentIndex((prev) => prev + 1);
            setCurrentAlert(alertsFiltered[0]);
            return;
        }

        closeModal();
    };

    const handleClickButton = () => (error ? closeModal() : handleNextAlertAsync(currentAlert.id));

    const printMedia = (type: string, url: string, key?: number) => {
        if (isBase64Audio(type)) {
            return <audio className="message__audio" src={url} controls key={key} />;
        }

        if (isBase64Photo(type)) {
            return (
                <div className="alertNotification__image" key={key}>
                    <img alt="Chat pic" onClick={() => handleShowImage(url)} className="message__img" src={url} />
                </div>
            );
        }
        if (isBase64File(type)) {
            return (
                <div className="alertNotification__attachedFile" key={key}>
                    <AttachedFile urlClick={url} message={`${t(TranslationKeys.FILE)} ${Number(key) + 1}`} />
                </div>
            );
        }
        if (isBase64Video(type)) {
            return <video className="alertNotification__video" key={key} src={url} controls></video>;
        }
    };

    const sortAndFillUsers = () => {
        const seenUsers: ReceiverNotificationDto[] = [];
        const unseenUsers: ReceiverNotificationDto[] = [];

        currentAlert.receiverNotificationList?.forEach((notification) => {
            if (notification.seenDate) {
                seenUsers.push(notification);
                return;
            }
            unseenUsers.push(notification);
        });

        setSeenUsers(seenUsers);
        setUnSeenUsers(unseenUsers);
    };

    useEffect(() => {
        currentAlert.receiverNotificationList && sortAndFillUsers();
    }, []);

    useEffect(() => {
        setAlerts(data);
    }, [data]);

    return (
        <GenericModal
            size="md"
            footer={
                <GenericModalFooter
                    confirmButton={{
                        text: confirmButtonText,
                        iconRight: alreadyReaded ? undefined : faCheckDouble,
                        onClick: handleClickButton,
                        fullWidth: true,
                        danger: error,
                    }}
                    loading={isLoading}
                />
            }
        >
            <div className="alertNotification__container">
                <AlertNotificationsModalHeader
                    actualAlertIndex={currentIndex}
                    numberOfAlerts={data.length}
                    title={currentAlert.title}
                />
                <AlertNotificationsModalInfo
                    profilePicture={currentAlert.profilePictureURL || currentAlert.senderProfilePictureURL}
                    avatarName={currentAlert.senderUserName || currentAlert.fullName}
                    colorId={currentAlert.senderUserId || 0}
                    date={infoDate}
                />
                {currentAlert.message && <p className="alertNotification__body__content">{currentAlert.message}</p>}
                {currentAlert.audioUrl && printMedia("audio/mpeg", currentAlert.audioUrl)}
                <div className="alertNotification__body__mediaFiles">
                    {currentAlert.notificationFiles &&
                        currentAlert.notificationFiles
                            .sort((a, b) => (a.fileType > b.fileType ? -1 : a.fileType < b.fileType ? 1 : 0))
                            .map((x, key) => printMedia(x.fileType, x.file, key))}
                </div>
                <AlertNotificationModalCollapsables
                    currentAlert={currentAlert}
                    seenUsers={seenUsers}
                    unSeenUsers={unSeenUsers}
                />
            </div>

            {showImage && imageURL && (
                <SliderModal onClose={() => setShowImage(false)}>
                    <ImageModal img={imageURL} />
                </SliderModal>
            )}
        </GenericModal>
    );
};
