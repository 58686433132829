import { FC } from "react";
import { WizardForm } from "app/components_v2/Wizard/WizardForm/WizardForm";
import { useTranslation } from "react-i18next";
import { SelectCompany, SelectDepartments, SelectSubDepartments, SelectUser } from "./components";
import { useStepTwo } from "./hooks";
import { StepTwoProps } from "./models";
import { TranslationKeys } from "app/translation/translationKeys";
import Spinner from "app/components_v2/Spinner/Spinner";
import { SelectWorkingPosition } from "./components/SelectWorkingPosition";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";

export const StepTwo: FC<StepTwoProps> = ({
    onChange,
    next,
    DepartmentForTask,
    SubDepartementForTask,
    companyForTask,
    userForTask,
    workingPositionForTask,
    issueId,
}) => {
    const {
        validate,
        validations,
        changeCompanySelected,
        changeDepartmentSelected,
        changeUserSelected,
        enableUser,
        isEnabled,
        companyValues,
        departmentValues,
        employeesValues,
        // subdepartmentValues,
        workingPositionValues,
        changeWorkingPositionSelected,
        enableWorkingPosition,
    } = useStepTwo(
        companyForTask,
        SubDepartementForTask,
        DepartmentForTask,
        userForTask,
        workingPositionForTask,
        onChange,
        next
    );

    const { t } = useTranslation();

    return (
        <WizardForm validate={validate}>
            {companyValues ? (
                <>
                    <SelectCompany
                        changeCompanySelected={changeCompanySelected}
                        value={companyForTask.value}
                        options={companyValues}
                        customError={validations.companyForTask}
                        disabled={!!issueId}
                    />
                    {companyForTask.value !== "" && !departmentValues.isLoading && departmentValues.values && (
                        <SelectDepartments
                            changeDepartmentSelected={changeDepartmentSelected}
                            options={departmentValues.values}
                            value={DepartmentForTask.value}
                            customError={validations.DepartmentForTask}
                        />
                    )}
                    <div className="wizardTaskStepTwoContainer">
                        {companyForTask.value !== "" &&
                            DepartmentForTask.value &&
                            (!employeesValues.isLoading &&
                            employeesValues.values &&
                            // !subdepartmentValues.isLoading &&
                            // subdepartmentValues.values &&
                            !workingPositionValues.isLoading &&
                            workingPositionValues.values ? (
                                <>
                                    <p className="wizardTaskStepTwoTitle">
                                        {t(TranslationKeys.ASIGN_EMPLOYEE_DEPARTMENT).replace(
                                            "{0}",
                                            t(TranslationKeys.TASK_FILTER_WORKING_POSITION)
                                        )}
                                    </p>
                                    {companyForTask.worksWithQr && !!validations.userOrQRForTask.length && (
                                        <ErrorMessage errorMessage={validations.userOrQRForTask} />
                                    )}
                                    <div
                                        className={`taskWizardStepTwo ${
                                            isEnabled.workingPosition ? "isEnabled" : "isDisabled"
                                        }`}
                                        onClick={enableWorkingPosition}
                                    >
                                        <SelectWorkingPosition
                                            value={workingPositionForTask.value}
                                            options={workingPositionValues.values}
                                            changeWorkingPositionelected={changeWorkingPositionSelected}
                                            isEnabled={!isEnabled.workingPosition}
                                            optional={!companyForTask.worksWithQr}
                                        />
                                    </div>

                                    <div
                                        onClick={enableUser}
                                        className={`taskWizardStepTwo ${isEnabled.user ? "isEnabled" : "isDisabled"}`}
                                    >
                                        <SelectUser
                                            changeUserSelected={changeUserSelected}
                                            value={userForTask.value}
                                            options={employeesValues.values.sort((a, b) =>
                                                a.value.localeCompare(b.value)
                                            )}
                                            isEnabled={!isEnabled.user}
                                            customError={validations.userForTask}
                                            optional={!companyForTask.worksWithQr}
                                        />
                                    </div>

                                    {/* TODO: --SUBDEPARTMENT-- */}
                                    {/* {!companyForTask.worksWithQr && (
                                        <div
                                            className={`taskWizardStepTwo ${
                                                isEnabled.subdepartment ? "isEnabled" : "isDisabled"
                                            }`}
                                            onClick={enableSubdepartments}
                                        >
                                            <SelectSubDepartments
                                                value={SubDepartementForTask.value}
                                                options={subdepartmentValues.values}
                                                changeSubDepartmentSelected={changeSubDepartmentSelected}
                                                isEnabled={!isEnabled.subdepartment}
                                            />
                                        </div>
                                    )} */}
                                </>
                            ) : (
                                <Spinner />
                            ))}
                    </div>
                </>
            ) : (
                <Spinner />
            )}
        </WizardForm>
    );
};
