import { FC } from "react";
import { AuditCommentType } from "../types";
import { NotificationIcon } from "app/components_v2/NotificationIcon/NotificationIcon";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faMessageExclamation, faPen, faTrash, faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { NotificationVariantModel } from "app/models/NotificationVariant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AriaLabels } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

type AuditCommentHeaderProps = {
    type: AuditCommentType;
    readonly?: boolean;
    avatarName: string;
    onEdit: () => void;
    onDelete: () => void;
};

export const AuditCommentHeader: FC<AuditCommentHeaderProps> = ({ avatarName, onDelete, onEdit, type, readonly }) => {
    const { t } = useTranslation();

    const icon: Record<AuditCommentType, IconProp> = {
        comment: faMessageExclamation,
        issue: faTriangleExclamation,
    };
    const notitificationVariant: Record<AuditCommentType, NotificationVariantModel> = {
        comment: "teal",
        issue: "danger",
    };

    return (
        <div className="auditCommentHeader">
            <div className="auditCommentHeader__avatar">
                <NotificationIcon icon={icon[type]} variant={notitificationVariant[type]} size="md" />
                <p className="auditCommentHeader__avatarName">{avatarName}</p>
            </div>
            {!readonly && (
                <div className="auditCommentHeader__actions">
                    <div className="auditCommentHeader__icon">
                        <FontAwesomeIcon
                            className="auditCommentHeader__icon__svg auditCommentHeader__icon__svg__edit"
                            aria-label={t(AriaLabels.AL_AUDIT_COMMENT_HEADER_EDIT)}
                            icon={faPen}
                            onClick={onEdit}
                        />
                    </div>
                    <div className="auditCommentHeader__icon">
                        <FontAwesomeIcon
                            className="auditCommentHeader__icon__svg auditCommentHeader__icon__svg__delete"
                            aria-label={t(AriaLabels.AL_AUDIT_COMMENT_HEADER_DELETE)}
                            icon={faTrash}
                            onClick={onDelete}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
