import { FC } from "react";
import { MobileTaskInstanceRow } from "../MobileTaskInstanceRow/MobileTaskInstanceRow";
import { ColumnsType } from "app/components_v2/Table/types";
import Table from "app/components_v2/Table/Table";
import { useTranslation } from "react-i18next";
import { TaskTranslations, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { convertUTCtoLocaleDate, getFullDateWithTimeReadable } from "app/helpers/Utilities/date/Date.utilities";
import { CellWithAvatar } from "app/components_v2/Table/CellWithAvatar/CellWithAvatar";
import { validateStatus } from "app/helpers/__validates/validateTaskpillStatus";
import { getValueTaskInstanceStatus } from "app/helpers/TaskInstance/getValueTaskInstanceStatus";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { faEye, faListCheck } from "@fortawesome/pro-regular-svg-icons";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { MAX_TASK_HISTORY_ROWS_CSV } from "app/shared/Constants";
import { TaskInstanceListDto } from "app/dtos/02-TAR/TaskInstance/TaskInstanceListDto";

type TaskHistoryTableProps = {
    data: TaskInstanceListDto[];
    isLoading: boolean;
    pageIndex: number;
    total: number;
    variant?: TabHeaderVariants;
    onChangePageIndex: (pageIndex: number) => void;
    openTask: (id: number) => void;
    onClickExportCsv: (queryString?: IDownLoadCsvReturn) => Promise<unknown>;
};

export const TaskHistoryTable: FC<TaskHistoryTableProps> = ({
    openTask,
    data,
    isLoading,
    onChangePageIndex,
    pageIndex,
    total,
    onClickExportCsv,
    variant = "primary",
}) => {
    const { t } = useTranslation();

    const columns: ColumnsType<TaskInstanceListDto>[] = [
        {
            label: t(TranslationKeys.NAME),
            dataIndex: "TaskName",
            className: "taskInstanceGrid__col",
            render: (record) => <CellTitle title={record.taskName} />,
        },
        {
            label: t(TranslationKeys.TASK_HISTORY_ASIGNED),
            dataIndex: "DeadlineDate",
            className: "taskInstanceGrid__col",
            render: (record) => {
                const avatarName = !!record.asignedToUserName?.length
                    ? record.asignedToUserName
                    : !!record.asignedToWorkingPositionName?.length
                    ? record.asignedToWorkingPositionName
                    : !!record.asignedToDepartmentName?.length
                    ? record.asignedToDepartmentName
                    : "";

                const colorId =
                    !!record.asignedToUserName?.length && !!record.asignedToUserId
                        ? record.asignedToUserId
                        : !!record.asignedToWorkingPositionName?.length && !!record.asignedToWorkingPositionId
                        ? record.asignedToWorkingPositionId
                        : !!record.asignedToDepartmentName?.length && !!record.asignedToDepartmentId
                        ? record.asignedToDepartmentId
                        : 0;
                const badgeTitle =
                    record.deadlineDate != null && record.taskType !== "SPORADIC"
                        ? getFullDateWithTimeReadable(convertUTCtoLocaleDate(record.deadlineDate).toString())
                        : t(TaskTranslations.TASK_TEMPORALITY_SPORADIC);
                return (
                    <CellWithAvatar
                        avatarName={avatarName}
                        colorId={colorId}
                        badgeTitle={badgeTitle}
                        badgeVariant="grey"
                        img={record.asignedToUserId != null ? record.profilePictureURL : undefined}
                    />
                );
            },
        },
        {
            label: t(TranslationKeys.TASK_HISTORY_FINISHED_BY),
            dataIndex: "FinishedDate",
            className: "taskInstanceGrid__col",
            render: (record) => {
                const statusInfo = validateStatus(record);
                const finishedDate = record.finishedDate || new Date();
                const badgeTitle = getFullDateWithTimeReadable(convertUTCtoLocaleDate(finishedDate).toString());
                const value = getValueTaskInstanceStatus({
                    t,
                    type: statusInfo,
                    date: badgeTitle,
                });
                return (
                    <CellWithAvatar
                        avatarName={record.finishedByUserName}
                        colorId={record.editedBy || 0}
                        badgeTitle={value?.value || ""}
                        badgeVariant={value?.badgeVariant || "grey"}
                        icon={value.icon}
                        img={!!record.finishedByUserName ? record.profilePictureURL : undefined}
                    />
                );
            },
        },
        {
            label: t(TranslationKeys.ACTIONS),
            dataIndex: "acciones",
            className: "taskInstanceGrid__actions",
            render: ({ id }) => (
                <CellIcons
                    icons={[
                        {
                            icon: faEye,
                            onClick: () => openTask(id),
                            title: t(TranslationCommon.VIEW),
                        },
                    ]}
                />
            ),
        },
    ];

    return (
        <div>
            <Table
                cols={columns}
                data={data}
                placeholder={<TableError icon={faListCheck} />}
                isLoading={isLoading}
                pageIndex={pageIndex}
                pageSize={10}
                total={total}
                onDoubleClick={({ id }) => {
                    openTask(id);
                }}
                variant={variant}
                onClickExportCsv={onClickExportCsv}
                onChangePageIndex={onChangePageIndex}
                mobileBody={(row, _i, rowPosition) => (
                    <MobileTaskInstanceRow
                        key={row.id}
                        data={row}
                        onClick={() => {
                            openTask(row.id);
                        }}
                        rowPosition={rowPosition}
                    />
                )}
                hasHeader={false}
                maxRowsCsv={MAX_TASK_HISTORY_ROWS_CSV}
            />
        </div>
    );
};
