import { AuditInstanceStatus } from "./types/AuditInstanceTypes";

export const AUDIT_INSTANCE_STATUS: Record<AuditInstanceStatus, AuditInstanceStatus> = {
    CLOSED: "CLOSED",
    COMPLETED: "COMPLETED",
    IN_PROGRESS: "IN_PROGRESS",
    REVIEWED: "REVIEWED",
};

export const AUDIT_INSTANCE_STATUS_ARRAY: AuditInstanceStatus[] = [
    AUDIT_INSTANCE_STATUS.IN_PROGRESS,
    AUDIT_INSTANCE_STATUS.REVIEWED,
    AUDIT_INSTANCE_STATUS.COMPLETED,
    AUDIT_INSTANCE_STATUS.CLOSED,
];
