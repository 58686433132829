import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import FetchService from "../Fetch/FetchService";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { ApiPaths } from "app/shared/Constants";
import { DepartmentNameModel } from "app/models/01-SEG/DepartmentName/DepartmentNameModel";

export default class DepartmentNameService {
    public static async GetData(paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedResult<DepartmentNameModel>>({
            url: `${ApiPaths.SEG_API}/departmentName`,
            paginationParams: paginationParams,
        });
    }

    public static async Save(departmentName: DepartmentNameModel) {
        return FetchService.post<DepartmentNameModel>({
            url: `${ApiPaths.SEG_API}/departmentName`,
            body: departmentName,
        });
    }

    public static async Edit(departmentName: DepartmentNameModel) {
        return FetchService.put<DepartmentNameModel>({
            url: `${ApiPaths.SEG_API}/departmentName/${departmentName.id}`,
            body: departmentName,
        });
    }

    public static async Delete(id: number) {
        return FetchService.delete<DepartmentNameModel>({
            url: `${ApiPaths.SEG_API}/departmentName/${id}`,
        });
    }
}
