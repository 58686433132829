import {
    getCorrectIncorrectOptions,
    isCorrectIncorrectDynamicField,
    isDropdownDynamicField,
} from "app/helpers/Task/dynamicFields/dynamicFieldHelper";
import { DynamicFieldsType, DynamicFieldsModel } from "app/models/02-TAR/TaskWizard";
import { v4 } from "uuid";
import { AssetModel2 } from "app/models/05-QUA/AssetModels/AssetModel2";

export const onSelectDynamicField = (
    dynamicFieldType: DynamicFieldsType,
    dynamicFields: DynamicFieldsModel[],
    dynamicFieldAssetOptions: AssetModel2[],
    fK_PlanAPPCC: string
): DynamicFieldsModel[] => {
    const dynamicFieldsFiltered = dynamicFields.filter(({ isDeleted }) => !isDeleted);
    if (dynamicFieldsFiltered.length >= 20) return dynamicFields;
    const lastDynamicField = dynamicFields.at(-1);
    return [
        ...dynamicFields,
        {
            dynamicFieldsType: dynamicFieldType,
            id: v4(),
            isRequired: true,
            taskFieldOptions: isCorrectIncorrectDynamicField(dynamicFieldType)
                ? getCorrectIncorrectOptions()
                : isDropdownDynamicField(dynamicFieldType)
                ? [{ id: v4(), label: "" }]
                : [],
            dropDownMultipleOptions: dynamicFieldAssetOptions.map(({ id, name }) => ({ id, label: name })),
            dropDownMultipleValues: [],
            label: "",
            isDeleted: false,
            order: lastDynamicField?.order != null ? lastDynamicField.order + 1 : 0,
            isDisabled:
                (!fK_PlanAPPCC.length || fK_PlanAPPCC === "-1") &&
                dynamicFieldType === "DROPDOWN_MULTIPLE_ASSETS_RANGE",
        },
    ];
};

export const onDynamicFieldCheckboxChange = (
    value: boolean,
    id: string,
    dynamicFields: DynamicFieldsModel[]
): DynamicFieldsModel[] => {
    return [
        ...dynamicFields.map((field) =>
            field.id === id
                ? {
                      ...field,
                      isRequired: value,
                  }
                : field
        ),
    ];
};

export const onDynamicFieldInputChange = (
    value: string,
    id: string,
    dynamicFields: DynamicFieldsModel[]
): DynamicFieldsModel[] => {
    return [
        ...dynamicFields.map((field) =>
            field.id === id
                ? {
                      ...field,
                      label: value,
                  }
                : field
        ),
    ];
};

export const onDeleteDynamicField = (id: string, dynamicFields: DynamicFieldsModel[]): DynamicFieldsModel[] => {
    const selectedDynamicField = dynamicFields.find((field) => field.id === id);
    if (!selectedDynamicField) return dynamicFields;
    if (selectedDynamicField.dbId) {
        return dynamicFields.map((field) =>
            field.id === id ? { ...field, isDeleted: true, taskFieldOptions: [] } : field
        );
    }

    return dynamicFields.filter((field) => field.id !== id);
};
export const onDeleteDynamicDropDownField = (
    id: string,
    itemId: string,
    dynamicFields: DynamicFieldsModel[]
): DynamicFieldsModel[] => {
    const selectedDynamicField = dynamicFields.find((field) => field.id === id);
    if (!selectedDynamicField) return dynamicFields;
    if (selectedDynamicField.taskFieldOptions.length < 2) return dynamicFields;
    return [
        ...dynamicFields.map((field) =>
            field.id === id
                ? {
                      ...field,
                      taskFieldOptions: [...field.taskFieldOptions.filter((taskfield) => taskfield.id !== itemId)],
                  }
                : field
        ),
    ];
};
export const onChangeDynamicDropDownField = (
    id: string,
    itemId: string,
    value: string,
    dynamicFields: DynamicFieldsModel[]
): DynamicFieldsModel[] => {
    const selectedDynamicField = dynamicFields.find((field) => field.id === id);
    if (!selectedDynamicField) return dynamicFields;
    return [
        ...dynamicFields.map((field) => {
            if (field.id !== id) return field;
            return {
                ...field,
                taskFieldOptions: [
                    ...field.taskFieldOptions.map((taskfield) => {
                        if (taskfield.id !== itemId) return taskfield;
                        return {
                            ...taskfield,
                            label: value,
                        };
                    }),
                ],
            };
        }),
    ];
};
export const onAddNewDynamicDropDownField = (id: string, dynamicFields: DynamicFieldsModel[]): DynamicFieldsModel[] => {
    const selectedDynamicField = dynamicFields.find((field) => field.id === id);
    if (!selectedDynamicField) return dynamicFields;
    if (selectedDynamicField.taskFieldOptions.length >= 10) return dynamicFields;
    return [
        ...dynamicFields.map((field) => {
            if (field.id !== id) return field;
            return {
                ...field,
                taskFieldOptions: [...field.taskFieldOptions, { id: v4(), label: "" }],
            };
        }),
    ];
};
