import { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { faArrowDownToLine, faClone, faGear, faPen } from "@fortawesome/pro-regular-svg-icons";
import { DocumentalFolder } from "app/components_v2/Documental/DocumentalFolder/DocumentalFolder";

export const DocumentalFolderSection: FC<SectionsShowProp> = ({ show }) => {
    return (
        <Section title="Documental Folder" show={show}>
            <div style={{ display: "flex", gap: "10px", width: "300px", flexDirection: "column" }}>
                <DocumentalFolder
                    date="12 Feb 2024"
                    name="Archivos nevera"
                    totalFiles={15}
                    onClick={() => {
                        console.log("documental folder click");
                    }}
                />
                <DocumentalFolder
                    date="12 Feb 2024"
                    name="Archivos nevera"
                    totalFiles={15}
                    onClick={() => {
                        console.log("documental folder click");
                    }}
                    moreOptionsItems={[
                        { text: "Descargar todo", icon: faArrowDownToLine, onClick: () => console.log("Download all") },
                        { text: "Duplicar", icon: faClone },
                        { text: "Renombrar", icon: faPen },
                        { text: "Editar Acceso", icon: faGear },
                    ]}
                />
            </div>
        </Section>
    );
};
