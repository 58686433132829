import { ExtraParams } from "app/helpers/__extraParams/taskinstance/taskInstanceFillExtraParams";
import { carrouselFilter } from "../models/ReviewTaskNav";
import { getDateFormattedSelects } from "app/helpers/Utilities/date/Date.utilities";

export const fillCarrouselExtraParams = (
    filterBy: carrouselFilter,
    { oneStar, twoStar, threeStar, fourStar, fiveStar, notEvaluatedCheck }: ExtraParams,
    startDate: Date,
    endDate?: Date,
    userId?: number | string,
    departmentId?: string | number,
    subdepartmentId?: string | number
): string => {
    let params = ``;

    params += `sortField=${filterBy}&`;

    params += `deadlineDate=${getDateFormattedSelects(startDate)}&`;
    if (endDate) params += `deadlineDate1=${getDateFormattedSelects(endDate)}&`;
    if (userId && userId != "-1") params += `userId=${userId}`;
    if (departmentId && departmentId != "-1") params += `DepartmentId=${departmentId}`;
    if (subdepartmentId && subdepartmentId != "-1") params += `SubdepartmentId=${subdepartmentId}`;
    if (notEvaluatedCheck) params += `notEvaluated=${notEvaluatedCheck}&`;
    if (fiveStar) params += `fiveStar=${fiveStar}&`;
    if (fourStar) params += `fourStar=${fourStar}&`;
    if (threeStar) params += `threeStar=${threeStar}&`;
    if (twoStar) params += `twoStar=${twoStar}&`;
    if (oneStar) params += `oneStar=${oneStar}&`;
    return params;
};
