import { BreadcrumbModel } from "app/components_v2/BreadCrumbs";
import { TabHeader } from "app/components_v2/__containers/TabHeader/TabHeader";
import { translateHelper } from "app/helpers/Translations/translateCrumb";
import { useGoBack, useSession } from "app/hooks";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { CrudHeaderAvatarProps, CrudHeaderBadge, CrudHeaderType } from "./types";
import { CrudHeaderChildren } from "./CrudHeaderChildren";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

type Tabs = {
    tabs?: ReactNode;
    grayMode?: boolean;
};

export type CrudHeaderProps = {
    avatar?: CrudHeaderAvatarProps;
    title?: string;
    onClick?: () => void;
    isLoading?: boolean;
    tabsOptions?: Tabs;
    buttonLabel?: string;
    type: CrudHeaderType;
    badge?: CrudHeaderBadge;
    isActive?: boolean;
    background?: "primary" | "transparent" | "image" | "grey";
    isClosed?: boolean;
    isCompanyEdit?: boolean;
    isGrayMode?: boolean;
    showDeleteButton?: boolean;
    secondaryButton?: SecondaryButton;
    topNode?: ReactNode;
    leftNode?: ReactNode;
    leftNodeOneColumn?: boolean;
    rightNode?: ReactNode;
    variant?: TabHeaderVariants;
    titleSkelleton?: ReactNode;
    customHeader?: ReactNode;
};

type SecondaryButton = {
    onClickSecondaryButton: () => void;
    label: string;
    icon: IconProp;
    variant?: TabHeaderVariants;
};

export const CrudHeader: FC<CrudHeaderProps> = ({
    isClosed,
    tabsOptions,
    type,
    isActive,
    variant = "primary",
    ...rest
}) => {
    const { t } = useTranslation();
    const session = useSession();
    const { getOriginPathname } = useGoBack();
    const { translateCompany } = useCustomerTypeTranslation();

    const crumb: BreadcrumbModel[] = [
        {
            name: translateHelper(getOriginPathname(), t, translateCompany),
            to: `/${getOriginPathname()}`,
        },
    ];

    return (
        <TabHeader
            showHeader={false}
            tabs={tabsOptions?.tabs}
            grayMode={isClosed || type === "disable" ? !isActive : false}
            classNameToScrollTop=".formLayoutScroll__children"
            variant={variant}
        >
            <CrudHeaderChildren
                {...rest}
                isClosed={isClosed}
                crumb={crumb}
                type={type}
                isActive={isActive}
                variant={variant}
            />
        </TabHeader>
    );
};
