import { FormLayout } from "app/components_v2/Layout/FormLayout/FormLayout";
import { RoleCollapseChecboxes } from "app/components_v2/RoleCollapseChecboxes/RoleCollapseChecboxes";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import MapModelsHelper from "app/helpers/Translations/translationHelper";
import { useSession, useTitle } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { RoleModel } from "app/models/01-SEG/Role/RoleModels";
import { ModuleScreenPatents, SecModule, SecScreenPatent } from "app/models/ModuleModels";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { InstanceService, RoleService } from "app/services";
import { PrivatePaths, SecScreen } from "app/shared/Constants";
import {
    TranslationCommon,
    TranslationKeys,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const RoleEdit = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { handleToast } = useToast();
    const nav = useNavigate();
    const session = useSession();
    const customerInstanceId = session?.user.customerInstanceId;
    const { pathname } = useLocation();

    useTitle(t(TranslationTitles.EDIT_ROLE_PAGE_TITLE));

    const [role, setRole] = useState<RoleModel>({
        id: 0,
        customerInstanceId: customerInstanceId,
        name: "",
        isActive: true,
        isTemporary: false,
        modules: [],
        usersCount: 0,
        userIds: [],
    });
    const [normalized, setNormalized] = useState<ModuleScreenPatents[]>([]);
    const [modules, setModules] = useState<SecModule[]>([]);
    const [previousModules, setPreviousModules] = useState<SecModule[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [isRolDeleting, setIsRolDeleting] = useState<boolean>(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

    const isTemporaryPage = useCallback(() => {
        return pathname.split("/").includes("temporary-roles");
    }, [pathname]);

    const hasPermissionsToSaveRol = useCallback(() => {
        return isTemporaryPage()
            ? hasPermissionToEdit(SecScreen.TEMPORARY_ROLES)
            : hasPermissionToEdit(SecScreen.ROLE_MANAGEMENT);
    }, []);

    const title = `${t(TranslationKeys.TITLE_PERMISSIONS)} ${role.name}`;

    const fetchConfig = async () => {
        setIsLoading(true);
        const confSr = await InstanceService.GetInstance(customerInstanceId!);
        const roleSr = await RoleService.GetOne(id!, customerInstanceId || 0);

        if (!confSr.status() || !roleSr.status()) {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            redirectToFatherRoute();
            setIsLoading(false);
            return;
        }
        const moduleScreenPatents: ModuleScreenPatents[] = MapModelsHelper.fromSecModuleToModuleScreenPatents(
            confSr.data.modules
        );
        setNormalized(moduleScreenPatents);

        setRole(roleSr.data);
        const roleScreenPatents = roleSr.data.modules.map((m) => ({
            moduleCode: m.moduleCode,
            screenPatents: MapModelsHelper.fromScreenPatentCodeToScreenPatents(m.screenPatents),
        }));
        setModules(roleScreenPatents);
        setPreviousModules(JSON.parse(JSON.stringify(roleScreenPatents)));
        setIsLoading(false);
    };

    const fetchTemporaryRoles = async () => {
        setIsLoading(true);
        const temporaryRoleSr = await RoleService.GetScheduledRole(id!);

        if (temporaryRoleSr.status()) {
            const confSr = await InstanceService.GetInstance(customerInstanceId!);
            const roleSr = await RoleService.GetOne(temporaryRoleSr.data.roleModel!.id, customerInstanceId || 0);

            if (confSr.status() && roleSr.status()) {
                const moduleScreenPatents: ModuleScreenPatents[] = MapModelsHelper.fromSecModuleToModuleScreenPatents(
                    confSr.data.modules
                );
                setNormalized(moduleScreenPatents);

                setRole(roleSr.data);
                const roleScreenPatents = roleSr.data.modules.map((m) => ({
                    moduleCode: m.moduleCode,
                    screenPatents: MapModelsHelper.fromScreenPatentCodeToScreenPatents(m.screenPatents),
                }));
                setModules(roleScreenPatents);
                setPreviousModules(JSON.parse(JSON.stringify(roleScreenPatents)));
                setIsLoading(false);
            } else {
                handleToast({
                    title: t(TranslationModals.TOAST_GENERIC_ERROR),
                    type: "alert",
                    variant: "danger",
                });
                redirectToFatherRoute();
                setIsLoading(false);
            }
        } else {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            redirectToFatherRoute();
            setIsLoading(false);
        }
    };

    const handleChange = useCallback((code: string, value: SecScreenPatent) => {
        setModules((prev) => {
            const tmp = [...prev];
            const found = tmp.find((m) => m.moduleCode === code);
            if (found) {
                const screen = found.screenPatents.find((s) => s.screenCode === value.screenCode);
                if (screen) screen.patents = value.patents;
                else found.screenPatents = [...found.screenPatents, value];
            } else tmp.push({ moduleCode: code, screenPatents: [value] });
            return tmp;
        });
    }, []);

    const redirectToFatherRoute = () => {
        isTemporaryPage() ? nav(`/${PrivatePaths.ROLE_MANAGEMENT}/temporary`) : nav(`/${PrivatePaths.ROLE_MANAGEMENT}`);
    };

    const showModal = () => setShowConfirmModal(true);

    const handleDelete = async () => {
        setIsRolDeleting(true);
        const service = isTemporaryPage() ? RoleService.DeleteScheduledRole(id!) : RoleService.Delete(id!);

        const { status } = await service;

        if (status()) redirectToFatherRoute();
        else
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
        setIsRolDeleting(false);
        setShowConfirmModal(false);
    };
    const handleSubmit = async () => {
        if (!role) return;
        setIsSubmiting(true);
        role.modules = modules.map((m) => ({
            moduleCode: m.moduleCode,
            screenPatents: MapModelsHelper.fromScreenPatentsToScreenPatentCode(m.screenPatents),
        }));
        role.customerInstanceId = customerInstanceId;
        setPreviousModules(modules);
        const updateSr = await RoleService.UpdatePermissions(role);
        updateSr.status()
            ? handleToast({
                  title: t(TranslationModals.SUCCESS_EDIT),
                  type: "alert",
                  variant: "success",
              })
            : handleToast({
                  title: t(TranslationModals.TOAST_GENERIC_ERROR),
                  type: "alert",
                  variant: "danger",
              });

        setIsSubmiting(false);
    };

    const handleOpenCloseModal = () => setIsCancelModalOpen(true);
    const handleCloseCloseModal = () => setIsCancelModalOpen(false);
    const showCloseModal = () => {
        if (!previousModules) return;
        !isEqual(previousModules, modules) && handleOpenCloseModal();
    };

    const handleCancel = () => {
        handleCloseCloseModal();
        setModules(JSON.parse(JSON.stringify(previousModules)));
    };

    useEffect(() => {
        isTemporaryPage() ? fetchTemporaryRoles() : fetchConfig();
    }, []);

    return (
        <>
            {isCancelModalOpen && (
                <ConfirmModal
                    onClose={handleCloseCloseModal}
                    onConfirm={handleCancel}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    description={t(TranslationCommon.ARE_YOU_SURE)}
                    title={t(TranslationKeys.UNDO_CHANGES)}
                />
            )}
            {showConfirmModal && (
                <ConfirmModal
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={() => setShowConfirmModal(false)}
                    onConfirm={() => handleDelete()}
                    description={t(TranslationCommon.ARE_YOU_SURE)}
                    title={`${t(TranslationCommon.DELETE)} ${t(TranslationKeys.LABEL_ROLE)}`}
                    isLoading={isRolDeleting}
                    type="delete"
                />
            )}
            <FormLayout
                isLoading={isLoading}
                variant="gray"
                headerOptions={{
                    buttonLabel: `${t(TranslationCommon.DELETE)} ${t(TranslationKeys.LABEL_ROLE)}`,
                    showDeleteButton: hasPermissionsToSaveRol(),
                    type: "delete",
                    onClick: showModal,
                    isLoading: isLoading,
                    title: title,
                }}
                footer={
                    hasPermissionsToSaveRol() && (
                        <GenericModalFooter
                            confirmButton={{
                                text: t(TranslationCommon.SAVE),
                                form: "userInfoForm",
                                type: "submit",
                                onClick: handleSubmit,
                            }}
                            closeButton={{
                                text: t(TranslationCommon.CANCEL),
                                onClick: showCloseModal,
                                buttonType: "tertiary",
                                disabled: isEqual(previousModules, modules),
                            }}
                            loading={isSubmiting}
                        />
                    )
                }
            >
                <div className="editRole__container__role">
                    {normalized.map((modulePaterns) => (
                        <RoleCollapseChecboxes
                            modulePaterns={modulePaterns}
                            key={modulePaterns.moduleCode}
                            modules={modules}
                            onChange={handleChange}
                            isOpen
                            disabled={
                                isTemporaryPage()
                                    ? !hasPermissionToEdit(SecScreen.TEMPORARY_ROLES)
                                    : !hasPermissionToEdit(SecScreen.ROLE_MANAGEMENT)
                            }
                        />
                    ))}
                </div>
            </FormLayout>
        </>
    );
};

export default RoleEdit;
