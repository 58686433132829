import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { TranslationKeys } from "app/translation/translationKeys";
import { BadgeVariant } from "app/components_v2/Badge/badgeTypes";
import { OpenCloseStatusCode } from "app/shared/types/OpenCloseStatusCode";
import { Badge } from "app/components_v2/Badge/Badge";
import { CellWithAvatar } from "app/components_v2/Table/CellWithAvatar/CellWithAvatar";
import { convertUTCtoLocaleDate, getFullDateWithTimeReadable } from "app/helpers/Utilities/date/Date.utilities";

type MobileRowHeaderProps = {
    colorId: number;
    name: string;
    openDate: Date;
    profilePictureUrl?: string;
    actualStatus: OpenCloseStatusCode;
    onClick?: () => void;
};

export const MobileRowHeader: FC<MobileRowHeaderProps> = ({
    actualStatus,
    colorId,
    name,
    openDate,
    onClick,
    profilePictureUrl,
}) => {
    const { t } = useTranslation();
    const variant: Record<OpenCloseStatusCode, BadgeVariant> = {
        OPEN: "red",
        CLOSED: "green",
    };
    const title: Record<OpenCloseStatusCode, string> = {
        OPEN: t(TranslationKeys.ISSUE_STATUS_OPEN),
        CLOSED: t(TranslationKeys.ISSUE_STATUS_CLOSE),
    };

    return (
        <div className="issueMobileRowHeader">
            <div className="issueMobileRowHeader__info">
                <CellWithAvatar
                    avatarName={name}
                    colorId={colorId}
                    badgeTitle={getFullDateWithTimeReadable(convertUTCtoLocaleDate(openDate).toString())}
                    badgeVariant="none"
                    img={profilePictureUrl}
                    avatarSize="sm"
                />
                <Badge variant={variant[actualStatus]} title={title[actualStatus]} />
            </div>
            {onClick && (
                <div className="issueMobileRowHeader__icon" onClick={onClick}>
                    <FontAwesomeIcon className="issueMobileRowHeader__icon__edit" icon={faPen} />
                </div>
            )}
        </div>
    );
};
