import { BorderEffect, TaskPillAvatar } from "app/components_v2/TaskPill/type";
import { calculateStar } from "app/helpers/TaskInstance/calculateStar";
import {
    convertUTCtoLocaleDate,
    getDayMonthAndHourFormatedShort,
    getDayMonthName,
    getHoursFromDateUTC,
    isToday,
} from "app/helpers/Utilities/date/Date.utilities";
import { FC, ReactNode, useEffect, useRef } from "react";
import { FilterData } from "../constants/DashboardResumeContext";
import { getAvatarInfo } from "app/helpers/TaskInstance/getAvatarInfo";
import { getTaskInstanceHour } from "app/helpers/TaskInstance/getTaskInstanceHour";
import { PaginationDefaults, TaskStatus } from "app/shared/Constants";
import { StarSizeModel, StarColorModel } from "app/shared/types/StarTypes";
import { TaskInstanceFeedbackMessageModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFeedbackMessageModel";
import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import { TaskPill } from "app/components_v2/TaskPill/TaskPill";
import { TaskPillSkelleton } from "app/components_v2/__skelletons/TaskPillSkelleton/TaskPillSkelleton";
import { useSession } from "app/hooks";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "usehooks-ts";
import { validateStatus } from "app/helpers/__validates/validateTaskpillStatus";
import i18next from "i18next";
import Pagination from "app/components_v2/Pagination/Pagination";
import TaskPillWithStarsSkelleton from "app/components_v2/__skelletons/TaskPillWithStarsSkelleton/TaskPillWithStarsSkelleton";
import { getCompletedDate } from "app/helpers/TaskInstance/getCompletedDate";
import { TranslationKeys } from "app/translation/translationKeys";
import { getStartDateText } from "app/helpers/TaskInstance/getStartDateText";
import { getLimitDate } from "app/helpers/TaskInstance/getLimitDate";
import { isDisabledRange } from "app/helpers/TaskInstance/isDisabledRange";
import { getTaskInstancePhotoBadge } from "app/helpers/TaskInstance/getTaskInstancePhotoBadge";
import { TaskInstanceListDto } from "app/dtos/02-TAR/TaskInstance/TaskInstanceListDto";
import { getInitials } from "app/helpers/Avatar/getInitials";

type TaskPillListSkeleton = {
    type: "withStars" | "withOutStars";
    hasImage?: boolean;
    variant?: "mobile" | "desktop";
};

type TaskPillListProps = {
    loadPage?: (page: number, pageSize: number) => void;
    list: TaskInstanceListDto[];
    total: number;
    filterData?: FilterData;
    isLoading?: boolean;
    onDetailClick?: (id: number, taskInstance: TaskInstanceListDto) => void;
    onCompleteSuccessTask?: (taskInstance: TaskInstanceModel) => void;
    pageIndex?: number;
    onChangePageIndex?: (value: number) => void;
    hideFeedback?: boolean;
    hidePhotos?: boolean;
    isSupervisor?: boolean;
    onStarsValued?: () => void;
    starSize?: StarSizeModel;
    paginate?: boolean;
    photoExampleRequired?: boolean;
    editable?: boolean;
    starColor?: StarColorModel;
    hideMobilePhotos?: boolean;
    skeletonOptions?: TaskPillListSkeleton;
    canBeDisabled?: boolean;
    showOneColumn?: boolean;
    removePadding?: boolean;
    pageSize?: number;
    scrollToRef?: boolean;
    borderStyle?: BorderEffect;
    quantity?: number;
    showValoratedBy?: boolean;
    user?: TaskPillAvatar;
    placeholder?: ReactNode;
};

export const TaskPillList: FC<TaskPillListProps> = ({
    loadPage,
    list,
    total,
    filterData,
    isLoading,
    onDetailClick,
    pageIndex,
    onChangePageIndex,
    hideFeedback,
    hidePhotos,
    isSupervisor,
    onStarsValued,
    starSize,
    paginate = true,
    photoExampleRequired = true,
    starColor,
    hideMobilePhotos,
    skeletonOptions,
    canBeDisabled,
    removePadding,
    showOneColumn,
    pageSize = PaginationDefaults.PAGE_SIZE,
    scrollToRef,
    borderStyle = "box-shadow",
    quantity = PaginationDefaults.PAGE_SIZE,
    showValoratedBy,
    user,
    placeholder,
}) => {
    const listRef = useRef<HTMLDivElement>(null);
    const session = useSession();
    const { t } = useTranslation();
    const { width } = useWindowSize();

    const offset = session?.user.timeZoneOffset;
    const lang = session?.user.language;

    useEffect(() => {
        const customContainer = document.querySelector(".customContainer");
        if (customContainer === null || scrollToRef) return;
        customContainer.scrollTo({ top: 0, behavior: "smooth" });
    }, [pageIndex]);

    const setComment = ({ message, senderUserName, sendDate }: TaskInstanceFeedbackMessageModel) => {
        const comment = {
            name: !!message?.length || showValoratedBy ? senderUserName : "",
            date: showValoratedBy && !!senderUserName && getDayMonthName(sendDate?.toString() || ""),
            comment: message,
        };
        return comment;
    };

    const renderPillList = (list: TaskInstanceListDto[]) => {
        if (isLoading)
            return (
                <>
                    {skeletonOptions && skeletonOptions.type === "withStars" ? (
                        <TaskPillWithStarsSkelleton
                            quantity={quantity}
                            hasImage={skeletonOptions.hasImage}
                            variant={
                                skeletonOptions.variant ? skeletonOptions.variant : width >= 901 ? "desktop" : "mobile"
                            }
                        />
                    ) : (
                        <TaskPillSkelleton quantity={quantity} />
                    )}
                </>
            );

        if (!!placeholder && !list.length) return placeholder;

        return list.map((task, i) => {
            const taskIFeedbackMsg = task.taskInstanceFeedbackMessage;
            const { starColorFeedback, starSizeFeedback } = calculateStar({
                taskIFeedbackMsg,
                starSize,
                starColor,
                userId: session?.user.id,
                userType: session?.user.userType,
                taskUserType: task.userType,
                isMyTask:
                    (task.statusCode === TaskStatus.PENDING && task.asignedToUserId === session?.user.id) ||
                    (task.statusCode === TaskStatus.COMPLETED && task.editedBy === session?.user.id),
            });

            let startHour = task.startDate ? getHoursFromDateUTC(convertUTCtoLocaleDate(task.startDate)) : undefined;

            if (task.startDate !== null) {
                const startdate = convertUTCtoLocaleDate(task.startDate);

                if (!isToday(startdate)) {
                    startHour = task.startDate
                        ? getDayMonthAndHourFormatedShort(convertUTCtoLocaleDate(task.startDate), i18next.language)
                        : undefined;
                }
            }

            const taskPillState = validateStatus(task);

            const hour = getTaskInstanceHour(task, offset);
            const date = getLimitDate({ lang: lang || "", taskInstance: task, offset });
            const startDateText = getStartDateText(task, session?.user.language || "", t);
            const completedDate = getCompletedDate(task, t);

            return (
                <TaskPill
                    isSporadic={
                        (task.taskType === "SPORADIC" || task.deadlineDate == null) &&
                        task.statusCode === TaskStatus.PENDING
                    }
                    showMagnifyGlass
                    taskType={task.taskType}
                    oneColumn={showOneColumn}
                    borderStyle={borderStyle}
                    startHour={startHour}
                    hour={hour}
                    startDate={startDateText}
                    date={date}
                    completedDate={completedDate}
                    key={i}
                    clampComment
                    user={user || getAvatarInfo(task, session)}
                    assignedToUserId={task.asignedToUserId}
                    description={task.taskDescription}
                    disabled={
                        canBeDisabled &&
                        !!task.startDate &&
                        convertUTCtoLocaleDate(task.startDate) > new Date() &&
                        task.taskTemporalityType !== "RANGE_DATES"
                    }
                    isDisabledRange={isDisabledRange(task)}
                    title={task.taskName}
                    state={taskPillState}
                    onPillClick={() => {
                        onDetailClick && onDetailClick(task.id, task);
                    }}
                    comment={taskIFeedbackMsg && setComment(taskIFeedbackMsg)}
                    stars={taskIFeedbackMsg && taskIFeedbackMsg.rating}
                    hideFeedback={hideFeedback}
                    carrouselPhotos={
                        !!task.hasPhotos && task.statusCode === TaskStatus.COMPLETED
                            ? task.taskInstancePhotos?.map((taskinstancePhoto) => {
                                  const { taskPhoto } = taskinstancePhoto;
                                  return {
                                      badgeTitle: getTaskInstancePhotoBadge(taskinstancePhoto, t),
                                      photo: taskPhoto,
                                  };
                              })
                            : task.fotoExample && photoExampleRequired
                            ? [
                                  {
                                      photo: task.fotoExample,
                                      badgeTitle: t(TranslationKeys.TASK_DETAIL_PHOTO_EXAMPLE),
                                  },
                              ]
                            : undefined
                    }
                    hidePhotos={hidePhotos}
                    isSupervisor={isSupervisor}
                    onStarsChange={() => {
                        onStarsValued && onStarsValued();
                    }}
                    taskInstanceId={task.id}
                    valorationMessage={task.taskInstanceFeedbackMessage && task.taskInstanceFeedbackMessage.message}
                    starColor={starColorFeedback}
                    starSize={starSizeFeedback}
                    audioURL={task.taskInstanceFeedbackMessage && task.taskInstanceFeedbackMessage.audioURL}
                    feedbackId={task.taskInstanceFeedbackMessage && task.taskInstanceFeedbackMessage.id}
                    isCritical={task.isCritical}
                    hasPhoto={task.isPhotoRequired}
                    hasFields={task.hasDataToReport}
                    hasComents={task.hasComments}
                    hideMobilePhotos={hideMobilePhotos}
                    isMyFeedback={
                        task.taskInstanceFeedbackMessage &&
                        task.taskInstanceFeedbackMessage.senderUserId === session?.user.id
                    }
                    nameInitials={
                        task.taskType === "SPORADIC"
                            ? getInitials(session?.user.firstName + " " + session?.user.lastName)
                            : task.nameInitials
                    }
                    asignedToWorkingPositionId={task.asignedToWorkingPositionId}
                    taskInstanceCheckListCount={task.taskInstanceCheckListCount}
                    taskInstanceCheckListMaxCount={task.taskInstanceCheckListMaxCount}
                    taskTemporalityType={task.taskTemporalityType}
                    hasFieldsAndAtLeastOneIsEmpty={task.hasFieldsAndAtLeastOneIsEmpty}
                />
            );
        });
    };

    const renderPillListWithPagination = (list: TaskInstanceListDto[], total: number) => {
        return (
            <>
                {renderPillList(list)}
                {total / pageSize >= 1 && (
                    <Pagination
                        onClick={(pageIndex) => {
                            onChangePageIndex && onChangePageIndex(pageIndex);
                            loadPage && loadPage(pageIndex, pageSize);
                        }}
                        pageIndex={pageIndex || 1}
                        total={total}
                        pageSize={pageSize}
                    />
                )}
            </>
        );
    };
    const isDateRange = () => {
        let aux = 0;
        if (filterData?.deadlineDate) aux++;
        if (filterData?.deadlineDate1) aux++;
        if (filterData?.finishedDate) aux++;
        if (filterData?.finishedDate1) aux++;

        return aux > 1;
    };

    return (
        <div className={`taskPillList ${removePadding ? "removePaddingAndMargin" : ""} `} ref={listRef}>
            {!isDateRange() && !paginate ? renderPillList(list) : renderPillListWithPagination(list, total)}
        </div>
    );
};
