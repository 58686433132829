import { FC, useRef, useState } from "react";
import { getInitials } from "app/helpers/Avatar/getInitials";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { SelectOptions } from "app/components_v2/__inputs";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { TranslationKeys } from "app/translation/translationKeys";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { useOnClickOutside } from "usehooks-ts";
import { useSession } from "app/hooks";

type TableSelectCompanyProps = {
    value: OptionModel;
    onChange: (value: OptionModel) => void;
    onClickOutside: () => void;
    variant?: TabHeaderVariants;
};

export const TableSelectCompany: FC<TableSelectCompanyProps> = ({ onChange, value, onClickOutside, variant }) => {
    const session = useSession();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const ref = useRef<HTMLDivElement | null>(null);

    const [selectedCompany, setSelectedCompany] = useState<OptionModel>(value);

    const options: OptionModel[] = [
        { label: translateCustomerTypeKeys(TranslationKeys.ALL_COMPANIES, { firstReplace: "PLURAL" }), value: "-1" },
        ...(session?.user.companies
            ?.sort((a, b) => (a.companyName || "").localeCompare(b.companyName || ""))
            .map(({ companyName, companyId, logoUrl }) => ({
                label: companyName || "",
                value: String(companyId),
                initials: getInitials(companyName || ""),
                profilePictureURL: logoUrl,
            })) || []),
    ];

    const handleChange = (value: OptionModel) => {
        setSelectedCompany(value);
        onChange(value);
    };

    useOnClickOutside(ref, () => {
        onClickOutside();
    });

    return (
        <div className="tableSelectCompany" ref={ref}>
            <SelectOptions
                selectedValue={selectedCompany.value}
                onChange={handleChange}
                options={options}
                inverted={variant === "appcc" ? undefined : true}
                isMulti={false}
                isSearchable
            />
        </div>
    );
};
