import { FC, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseCompanyTranslations, QRTranslations, TranslationCommon } from "app/translation/translationKeys";
import { DateRange } from "app/models/utilities";
import { hasOverlappingRanges } from "app/helpers/__validates/validateOverlappingRanges";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { CloseRangeDates } from "app/components_v2/CloseRangeDates/CloseRangeDates";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { Label } from "app/components_v2/__inputs";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";

type CloseQRModalProps = {
    closeDates?: DateRange[];
    isLoading: boolean;
    qrName: string;
    onClose: () => void;
    onClosingDates: (dates: Date[] | undefined[], index: number) => void;
    onAddNewRangeHour: () => void;
    onDeleteNewRangeHour: (index: number) => void;
    onSubmit: () => void;
};

export const CloseQRModal: FC<CloseQRModalProps> = ({
    closeDates,
    isLoading,
    qrName,
    onClose,
    onClosingDates,
    onAddNewRangeHour,
    onDeleteNewRangeHour,
    onSubmit,
}) => {
    const { t } = useTranslation();

    const [errorMessage, setErrorMessage] = useState<string>("");
    const [showIsClosedModal, setShowIsClosedModal] = useState<boolean>(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validate()) return;
        setShowIsClosedModal(true);
    };

    const validate = (): boolean => {
        const closeQRs = closeDates?.every(({ "0": closeDate, "1": openDate }) => !closeDate && !openDate)
            ? null
            : closeDates;

        if (
            closeQRs != null &&
            closeQRs.every(({ "0": closeDate, "1": openDate }) => !!closeDate && !!openDate) &&
            hasOverlappingRanges(closeQRs)
        ) {
            setErrorMessage(t(CloseCompanyTranslations.OVERLAPING_DATES_NOT_PERMITTED));
            return true;
        }

        return false;
    };

    const handleClosingDates = (dates: Date[] | undefined[], index: number) => {
        setIsSaveDisabled(false);
        onClosingDates(dates, index);
    };
    const handleAddNewRangeHour = () => {
        setIsSaveDisabled(false);
        onAddNewRangeHour();
    };
    const handleDeleteNewRangeHour = (index: number) => {
        setIsSaveDisabled(false);
        onDeleteNewRangeHour(index);
    };

    const onConfirmSubmit = () => {
        onSubmit();
        setShowIsClosedModal(false);
    };

    return (
        <>
            {showIsClosedModal && (
                <ConfirmModal
                    title={t(QRTranslations.CLOSED_QR_OPEN_CLOSE_CONFIRM_MODAL_TITLE)}
                    description={t(QRTranslations.CLOSED_QR_OPEN_CLOSE_CONFIRM_MODAL_DESCRIPTION)}
                    onConfirmText={t(TranslationCommon.CONFIRM)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onConfirm={onConfirmSubmit}
                    onClose={() => setShowIsClosedModal(false)}
                />
            )}
            <GenericModal
                header={{ title: t(QRTranslations.CLOSED_QR_OPEN_CLOSE_TITLE).replace("{0}", qrName), onClose }}
                size="sm"
                portal
                footer={
                    <GenericModalFooter
                        confirmButton={{
                            text: t(TranslationCommon.SAVE),
                            form: "CloseQRModal",
                            type: "submit",
                            fullWidth: true,
                            disabled: isSaveDisabled,
                        }}
                        closeButton={{
                            text: t(TranslationCommon.CANCEL),
                            onClick: onClose,
                            buttonType: "tertiary",
                            fullWidth: true,
                        }}
                        loading={!isSaveDisabled && isLoading}
                    />
                }
            >
                <form onSubmit={handleSubmit} className="closeQRModal" id="CloseQRModal">
                    <Label label={t(QRTranslations.CLOSE_QR_DATES).replace("{0}", qrName)} />
                    <CloseRangeDates
                        closeDates={closeDates}
                        onClosingDates={handleClosingDates}
                        onAddNewRangeHour={handleAddNewRangeHour}
                        onDeleteNewRangeHour={handleDeleteNewRangeHour}
                        errorMessage={errorMessage}
                    />
                </form>
            </GenericModal>
        </>
    );
};
