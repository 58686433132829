import { FC } from "react";
import { Avatar } from "app/components_v2/Avatar/Avatar";
import { TaskPillAvatar as TaskPillAvatarModel } from "../type";

export type TaskPillAvatarProps = {
    user: TaskPillAvatarModel;
    nameInitials?: string;
};

export const TaskPillAvatar: FC<TaskPillAvatarProps> = ({ user, nameInitials }) => {
    return (
        <Avatar
            avatarName={user.name}
            colorId={!user.hexColor?.length ? user.id : undefined}
            size="xs"
            name={user.name}
            img={user?.url}
            nameInitials={nameInitials}
            hexColor={user.hexColor}
        />
    );
};
