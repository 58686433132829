import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useToast } from "app/hooks/Toast/useToast";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { IssueAttachmentModel } from "app/models/05-QUA/IssueModels/IssueAttachmentModel";
import { IssueInsertModel, IssueModel } from "app/models/05-QUA/IssueModels/IssueModel";
import AuditInstanceIssueService from "app/services/05-QUA/AuditInstanceIssueService";
import IssueService from "app/services/05-QUA/IssueService";
import { IssueServiceType } from "app/shared/types/IssueTypes";
import { useEffect, useState } from "react";

type IUseIssue = {
    id: number;
    onFinish?: (issue: IssueModel) => void;
    type?: IssueServiceType;
    onError?: (httpCodeStatus: number) => void;
};

export const useIssue = ({ id, onFinish, type = "ISSUE", onError }: IUseIssue) => {
    const { getErrorMessage } = useFetchErrors();
    const { handleErrorManager } = useErrorManager();
    const { handleToast } = useToast();

    const [issue, setIssue] = useState<IssueModel | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const handleIssueChange = (values: Partial<IssueModel>) => {
        setIssue((prev) => {
            if (!prev) return issue;
            return { ...prev, ...values };
        });
    };

    const handleAddNewAttachments = (values: IssueAttachmentModel[]) => {
        if (!issue) return;
        handleIssueChange({ issueAttachments: values });
    };

    const handleFinish = async () => {
        const issueUpdated = await getData();
        if (!issueUpdated) return;
        onFinish && onFinish(issueUpdated);
    };

    const getData = async () => {
        setIsLoading(true);

        const service = type === "ISSUE" ? IssueService : AuditInstanceIssueService;
        const { data, status, getParsedError, httpCodeStatus } = await service.GetOne(id);

        if (!status()) {
            handleToast({
                title: getErrorMessage(getParsedError()),
                variant: "danger",
                type: "alert",
            });
            setIsLoading(false);
            onError && onError(httpCodeStatus);
            return null;
        }
        setIssue(data);
        setIsLoading(false);
        return data;
    };

    const onEditIssue = async (values: Partial<IssueModel>) => {
        if (!issue) return;
        if (!values.fK_IssueClassification) return;

        const { status, getParsedError } = await IssueService.EditClassification(id, values.fK_IssueClassification);

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) return;

        handleIssueChange(values);
    };

    useEffect(() => {
        getData();
    }, [id]);

    return {
        issue,
        isLoading,
        handleAddNewAttachments,
        handleFinish,
        onEditIssue,
    };
};
