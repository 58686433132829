import { SupervisorAnalistColors } from "app/shared/types/SupervisorColorTypes";
import { FC } from "react";

export type LegendItemMarkColors = SupervisorAnalistColors | "gray";
type LegendItemMarkProps = {
    color: LegendItemMarkColors;
    rounded?: boolean;
};

export const LegendItemMark: FC<LegendItemMarkProps> = ({ color, rounded }) => {
    return (
        <span className={`legendItemMark--${color} ${rounded ? "rounded" : ""}`} data-testid="legendItemMark"></span>
    );
};
