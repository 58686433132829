import { Bar } from "react-chartjs-2";
import { SupervisorAnalistDarkColor } from "../../shared/types/SupervisorColorTypes";
import { FC, useMemo } from "react";
import { BarDataset } from "./types";
import { Chart as ChartJS, ArcElement, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";

ChartJS.register(ArcElement, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend, zoomPlugin);

type BarChartProps = {
    labels: string[];
    datasets: BarDataset[];
    xMax?: number;
    yMax?: number;
    yAxisTitle?: string;
    xAxisLabelsRotation?: 0 | 45;
    containerClassName?: string;
    showLegend?: boolean;
};

export const BarChart: FC<BarChartProps> = ({
    datasets,
    labels,
    xMax = 4,
    yMax,
    yAxisTitle,
    xAxisLabelsRotation = 0,
    containerClassName = "",
    showLegend = true,
}) => {
    const backgroundColor: Record<SupervisorAnalistDarkColor, string> = {
        blue: "#eaf6fe",
        darkRed: "#fba9bc",
        green: "#9eed87",
        red: "#ffd0db",
        yellow: "#feee95",
    };

    const borderColor: Record<SupervisorAnalistDarkColor, string> = {
        blue: "#27648d",
        darkRed: "#a41837",
        green: "#229500",
        red: "#e23e63",
        yellow: "#ca8504",
    };

    const hoverBackgroundColor: Record<SupervisorAnalistDarkColor, string> = {
        blue: "#27648d",
        darkRed: "#a41837",
        green: "#74df54",
        red: "#ed5879",
        yellow: "#fac515",
    };

    const hoverBorderColor: Record<SupervisorAnalistDarkColor, string> = {
        blue: "#27648d",
        darkRed: "#a41837",
        green: "#74df54",
        red: "#ed5879",
        yellow: "#fac515",
    };

    const calculateYMax = useMemo(() => {
        return Math.max(...datasets.flatMap((dataset) => dataset.data));
    }, [datasets]);

    return (
        <div className="bar-chart">
            <div className="bar-chart__container">
                {!!yAxisTitle?.length && <p className="bar-chart__y-axis-title">{yAxisTitle}</p>}
                <div className={`bar-chart__chart-container ${containerClassName}`}>
                    <Bar
                        className="bar-chart__chart"
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                x: {
                                    beginAtZero: true,
                                    max: xMax,
                                    min: 0,
                                    ticks: {
                                        autoSkip: false,
                                        maxRotation: xAxisLabelsRotation,
                                        minRotation: xAxisLabelsRotation,
                                    },
                                },
                                y: {
                                    beginAtZero: true,
                                    max: yMax ?? calculateYMax,
                                },
                            },
                            plugins: {
                                legend: {
                                    position: "top",
                                    display: showLegend,
                                },
                                zoom: {
                                    pan: {
                                        enabled: true,
                                        mode: "x",
                                    },
                                    limits: {
                                        x: { min: 0, max: labels.length - 1 },
                                    },
                                },
                            },
                        }}
                        data={{
                            labels,
                            datasets: datasets.map(({ color, data, label }) => ({
                                label,
                                backgroundColor: backgroundColor[color],
                                borderColor: borderColor[color],
                                borderWidth: 1,
                                hoverBackgroundColor: hoverBackgroundColor[color],
                                hoverBorderColor: hoverBorderColor[color],
                                data,
                                barThickness: 20,
                            })),
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
