import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { Link, To } from "react-router-dom";

type AssetGridIssueButtonProps = {
    totalIssues: number;
    readonly?: boolean;
    to: To;
};

export const AssetGridIssueButton: FC<AssetGridIssueButtonProps> = ({ to, readonly, totalIssues }) => {
    if (readonly)
        return (
            <div className="assetGridIssueButton assetGridIssueButton--readonly">
                <p className="assetGridIssueButton__title assetGridIssueButton__title--readonly">{totalIssues}</p>
                <div className="assetGridIssueButton__icon">
                    <FontAwesomeIcon className="assetGridIssueButton__icon__svg" icon={faExclamationTriangle} />
                </div>
            </div>
        );

    return (
        <Link className="assetGridIssueButton" to={to}>
            <p className="assetGridIssueButton__title">{totalIssues}</p>
            <div className="assetGridIssueButton__icon">
                <FontAwesomeIcon className="assetGridIssueButton__icon__svg" icon={faExclamationTriangle} />
            </div>
        </Link>
    );
};
