import { FC } from "react";

type DocumentalFileSkelletonProps = {
    quantity?: number;
};
export const DocumentalFileSkelleton: FC<DocumentalFileSkelletonProps> = ({ quantity = 10 }) => {
    return (
        <div className="documentalFileSkelleton">
            {Array.from({ length: quantity }).map((_, index) => (
                <div className="documentalFileSkelleton documentalFileSkelleton__file" key={index}>
                    <div className="documentalFile__card documentalFileSkelleton__card">
                        <span className="documentalFileSkelleton__icon"></span>
                    </div>
                    <div className="documentalFile__body">
                        <p className="documentalFileSkelleton__name"></p>
                        <p className="documentalFileSkelleton__footer"></p>
                    </div>
                </div>
            ))}
        </div>
    );
};
