import { BaseTaskModel } from "app/models/02-TAR/BaseTask/BaseTaskModel";
import { BaseTaskFormModel } from "../EditBaseTask/models/EditBaseTaskFormModels";
import { mapCheckListToBaseCheckList, mapDynamicFieldsToBaseTaskFields } from "./mappers";
import { getFullDateWithTime } from "app/helpers/Utilities/date/Date.utilities";

type IBaseTaskFormModelToBaseTaskModel = {
    data: BaseTaskFormModel;
    selectedBaseTaskType: string;
    selectedPlanAPPCC: string;
    id?: string;
    offset?: number;
    fotoExampleId?: number | null;
    isDuplicate?: boolean;
};

export const BaseTaskFormModelToBaseTaskModel = ({
    data,
    selectedBaseTaskType,
    selectedPlanAPPCC,
    id,
    offset,
    fotoExampleId,
    isDuplicate = false,
}: IBaseTaskFormModelToBaseTaskModel): BaseTaskModel => {
    const {
        allowAnyData,
        checkList,
        criticalTask,
        dynamicFields,
        finish,
        isPhotoRequired,
        recurringTask,
        reportType,
        taskDesc,
        taskHours,
        taskStart,
        taskTitle,
        temporalityType,
        imageBase64,
        taskType,
        minPhotosAllowed,
    } = data;

    return {
        id: !!id?.length ? Number(id) : 0,
        name: taskTitle,
        description: taskDesc,
        customerInstanceId: 0,
        fK_BaseTaskType: Number(selectedBaseTaskType),
        type: "",
        imageBase64Content: imageBase64 ?? undefined,
        fotoExampleId: isPhotoRequired && fotoExampleId ? fotoExampleId : undefined,
        isPhotoRequired: isPhotoRequired,
        minPhotosAllowed: isPhotoRequired ? minPhotosAllowed : null,
        fK_PlanAPPCC: selectedPlanAPPCC.length ? Number(selectedPlanAPPCC) : undefined,
        allowAnyData: !!allowAnyData,
        baseTaskCheckList: mapCheckListToBaseCheckList(checkList, isDuplicate),
        baseTaskFields: mapDynamicFieldsToBaseTaskFields({ dynamicFields, baseTaskId: id, isDuplicate }),
        reportType: reportType,
        isCritical: criticalTask,
        counter: 0,
        startDate: !!taskStart.length ? getFullDateWithTime(taskStart) : "",
        endDate: finish.checked === "el" ? getFullDateWithTime(String(finish.value)) : null,
        neverEnds:
            finish.checked === "never" && recurringTask && recurringTask.isRecurrent
                ? finish.checked === "never"
                : null,
        numRecurrencies: finish.checked === "after" ? Number(finish.value) : null,
        taskTemporalityType: "NONE",
        // taskTemporalityType: temporalityType,
        // baseTaskRangeHours:
        //     temporalityType === "RANGE_DATES" || temporalityType === "SPORADIC" || temporalityType === "NONE"
        //         ? []
        //         : taskHours.map(({ hour, maxHour, type }) => {
        //               const startDateConvertd = new Date(transformMinutesHoursToDate(hour)).toISOString();
        //               let endDateConverted = null;
        //               if (type === "range" && maxHour) {
        //                   const hourToDate = new Date(transformMinutesHoursToDate(hour));
        //                   const maxHourFormated = new Date(transformMinutesHoursToDate(maxHour!));
        //                   if (hourToDate > maxHourFormated) {
        //                       maxHourFormated.setDate(maxHourFormated.getDate() + 1);
        //                   }
        //                   endDateConverted = maxHourFormated.toISOString();
        //               }

        //               return {
        //                   fK_BaseTask: !!id?.length ? Number(id) : 0,
        //                   hour: startDateConvertd,
        //                   maxHour: endDateConverted,
        //                   isDeleted: false,
        //                   id: 0,
        //               };
        //           }),
        baseTaskRangeHours: [],
        // baseTaskTemporalityDateRange: taskTemporalityDateRange.map(({ dbId, endDate, isDeleted, startDate }) => {
        //     const startDateFormatted = getFullDateWithTime(startDate);
        //     const endDateFormatted = getFullDateWithTimeEndDate(endDate, offset || DEFAULT_OFFSET);
        //     return {
        //         id: dbId || 0,
        //         startDate: startDateFormatted,
        //         endDate: endDateFormatted,
        //         isDeleted,
        //         fK_BaseTask: !!id?.length ? Number(id) : 0,
        //     };
        // }),
        baseTaskTemporalityDateRange: [],
        // baseTaskScheduler: {
        //     fK_BaseTask: !!id?.length ? Number(id) : 0,
        //     name:
        //         recurringTask && recurringTask.isRecurrent
        //             ? recurringTask.value !== "custom"
        //                 ? recurringTask.text
        //                 : `custom-every ${recurringTask.custom.repeatEvery} ${recurringTask.custom.customValue}`
        //             : "Task is not recurring",

        //     calendarDayNumber:
        //         recurringTask &&
        //         recurringTask.isRecurrent &&
        //         recurringTask.value === "custom" &&
        //         recurringTask.custom.customValue === "everyMonth"
        //             ? recurringTask.custom.selectedOptions.value === "everyMonthDayOfMonth"
        //                 ? new Date(taskStart).getDate()
        //                 : 0
        //             : 0,
        //     calendarWeekNumber:
        //         recurringTask && recurringTask.isRecurrent
        //             ? recurringTask.custom.customValue === "everyMonth"
        //                 ? recurringTask.custom.selectedOptions.value === "everyMonthFirstDayOfWeek"
        //                     ? getWeekDay(taskStart)
        //                     : 0
        //                 : recurringTask.value === "everyMonth"
        //                 ? getWeekDay(taskStart)
        //                 : 0
        //             : 0,
        //     repeatEvery: recurringTask && recurringTask.value === "custom" ? recurringTask.custom.repeatEvery : 1,
        //     monday: checkIfIsWeekDayIsSelected(recurringTask, 0, new Date(taskStart).getDay() - 1),
        //     tuesday: checkIfIsWeekDayIsSelected(recurringTask, 1, new Date(taskStart).getDay() - 1),
        //     wednesday: checkIfIsWeekDayIsSelected(recurringTask, 2, new Date(taskStart).getDay() - 1),
        //     thursday: checkIfIsWeekDayIsSelected(recurringTask, 3, new Date(taskStart).getDay() - 1),
        //     friday: checkIfIsWeekDayIsSelected(recurringTask, 4, new Date(taskStart).getDay() - 1),
        //     saturday: checkIfIsWeekDayIsSelected(recurringTask, 5, new Date(taskStart).getDay() - 1),
        //     sunday: checkIfIsWeekDayIsSelected(recurringTask, 6, new Date(taskStart).getDay() - 1),
        //     typeCode:
        //         recurringTask && recurringTask.isRecurrent && recurringTask.value === "custom"
        //             ? selectTypeCodeCustom(recurringTask.custom.customValue)
        //             : recurringTask && recurringTask.isRecurrent
        //             ? selectTypeCode(recurringTask.value)
        //             : "ONE_TIME",
        // },
        baseTaskScheduler: null,
        taskType,
    };
};
