import { FC } from "react";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { getInitials } from "app/helpers/Avatar/getInitials";
import { CloseDepartmentSeenModel } from "app/models/01-SEG/CloseDepartmentSeen/CloseDepartmentSeenModel";
import { Tag } from "app/components_v2/Tag/Tag";
import { OpenCloseStatusCode } from "app/shared/types/OpenCloseStatusCode";
import { useTranslation } from "react-i18next";

type OpenClosedTagDepartmentListProps = {
    list: CloseDepartmentSeenModel[];
    getBadgeTitle: (closeDate: Date, openDate: Date) => string;
    variant: OpenCloseStatusCode;
};

export const OpenClosedTagDepartmentList: FC<OpenClosedTagDepartmentListProps> = ({ list, getBadgeTitle, variant }) => {
    const { t } = useTranslation();

    const getTagMessage = (departmentName: string, closeDate: Date, openDate: Date) =>
        `${departmentName} ${getBadgeTitle(closeDate, openDate)}`;

    return (
        <>
            {list.map(({ id, departmentName, closeDate, openDate, state }) => (
                <div key={id} className="openClosedOptionCompanyCollapsable__companyGroup__department">
                    <Tag
                        avatar={{
                            id: id.toString(),
                            name: departmentName,
                            initials: getInitials(departmentName),
                        }}
                        message={getTagMessage(departmentName, closeDate, openDate)}
                        variant={state === "CLOSED" ? "red" : "green"}
                        disabled
                        rightIcon={faClock}
                        clamp={3}
                        size="auto"
                    />
                </div>
            ))}
        </>
    );
};
