import MapModelsHelper from "app/helpers/Translations/translationHelper";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useWizard } from "app/hooks/useWizard";
import { RoleModel } from "app/models/01-SEG/Role/RoleModels";
import { InstanceService, RoleService } from "app/services";
import { TranslationCommon, TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { headerStepNumbersModel } from "../types";
import { Wizard } from "../Wizard";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import { RoleWizardFormValues } from "./types";
import { ModuleScreenPatents, SecModule } from "app/models/ModuleModels";
import { OptionsSearch } from "app/models/FormComponentsModel";

type RolesWizardprops = {
    closeModal: () => void;
    temporal?: boolean;
};

const INITIAL_FORM_VALUES: RoleWizardFormValues = {
    roleName: "",
    date: new Date(),
    copyExistingRoles: false,
    roleSelected: { label: "", value: "" },
    permissions: [],
    userSelected: { label: "", value: "" },
};

const RolesWizard: FC<RolesWizardprops> = ({ closeModal, temporal }) => {
    const { handleToast } = useToast();
    const session = useSession();
    const { t } = useTranslation();

    const [formValues, setFormValues] = useState<RoleWizardFormValues>(INITIAL_FORM_VALUES);
    const [validateNextStep, setValidateNextStep] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState(false);
    const [normalized, setNormalized] = useState<ModuleScreenPatents[]>([]);
    const [allModules, setAllModules] = useState<SecModule[]>([]);

    const titles: headerStepNumbersModel[] = [
        { title: t(TranslationKeys.NAME) },
        { title: t(TranslationKeys.WIZARD_HEADER_TASK_REFERENCE) },
        { title: t(TranslationKeys.TITLE_PERMISSIONS) },
        { title: t(TranslationCommon.CONFIRM) },
    ];
    const { currentStepIndex, goTo, next, onStepChange } = useWizard(titles.length);

    const totalSteps = titles.length;

    const handleChange = (values: Partial<RoleWizardFormValues>) => {
        setFormValues((prev) => ({ ...prev, ...values }));
    };

    const saveRecord = async () => {
        const { permissions, roleName } = formValues;
        setIsLoading(true);
        const normalizedModules = permissions.map((m) => ({
            moduleCode: m.moduleCode,
            screenPatents: MapModelsHelper.fromScreenPatentsToScreenPatentCode(m.screenPatents),
        }));
        const roleToSave: RoleModel = {
            id: 0,
            isActive: true,
            isTemporary: !!temporal,
            modules: normalizedModules,
            name: roleName,
            customerInstanceId: session?.user.customerInstanceId,
        };
        const saveSr = temporal
            ? await RoleService.SaveScheduledRoles({
                  roleModel: roleToSave!,
                  dateFrom: formValues.date || new Date(),
                  dateTo: formValues.endDate || new Date(),
                  id: 0,
                  roleId: 0,
                  userId: Number(formValues.userSelected.value),
                  userPersonalName: formValues.userSelected.label,
              })
            : await RoleService.Save(roleToSave!);
        if (saveSr.status()) {
            handleToast({
                title: t(TranslationModals.SUCCESS_SAVE),
                type: "alert",
                variant: "success",
            });
            return true;
        }
        handleToast({
            title: t(TranslationKeys.WIZARD_ROLES_FAILED_SAVE),
            type: "alert",
            variant: "danger",
        });
        setIsLoading(false);
        return false;
    };

    const handleFinish = async () => {
        const saved = await saveRecord();
        if (saved) closeModal();
    };

    const fetchAllModules = async () => {
        const confSr = await InstanceService.GetInstance(session?.user.customerInstanceId || 0);

        if (!confSr.status()) {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        const normal: ModuleScreenPatents[] = MapModelsHelper.fromSecModuleToModuleScreenPatents(confSr.data.modules);
        setNormalized(normal);

        const roleScreenPatents = confSr.data.modules.map((m) => ({
            moduleCode: m.moduleCode,
            screenPatents: MapModelsHelper.fromScreenPatentCodeToScreenPatents(m.screenPatents).map(
                ({ patentCode, screenCode }) => ({ patentCode, screenCode })
            ),
        }));
        setAllModules(roleScreenPatents);
    };

    const fetchSelectedRole = async (roleSelected: OptionsSearch) => {
        const { data, status: srStatus } = await RoleService.GetOne(
            roleSelected.value,
            session?.user.customerInstanceId || 0
        );

        if (!srStatus()) {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            return;
        }

        const roleScreenPatents = data.modules.map((m) => ({
            moduleCode: m.moduleCode,
            screenPatents: MapModelsHelper.fromScreenPatentCodeToScreenPatents(m.screenPatents),
        }));
        setAllModules((prev) =>
            prev.map(({ moduleCode, screenPatents }, i) => {
                const newRoleFinded = roleScreenPatents.find((x) => x.moduleCode === moduleCode);

                const modules: SecModule = {
                    moduleCode,
                    screenPatents: !!newRoleFinded
                        ? screenPatents.map(({ screenCode, patentCode }) => {
                              const patentFinded = newRoleFinded.screenPatents.find((x) => x.screenCode === screenCode);

                              return {
                                  patentCode: !!patentFinded ? patentFinded.patentCode : patentCode,
                                  screenCode: !!patentFinded ? patentFinded.screenCode : screenCode,
                                  patents: !!patentFinded ? patentFinded.patents : [],
                              };
                          })
                        : screenPatents.map(({ screenCode, patentCode }) => ({ patentCode, screenCode, patents: [] })),
                };
                return modules;
            })
        );

        handleChange({
            permissions: roleScreenPatents,
        });
    };

    const steps: ReactElement[] = [
        <Step1
            onChangeData={handleChange}
            data={formValues}
            validateNextStep={setValidateNextStep}
            isTemporal={temporal}
            next={next}
        />,
        <Step2
            onChangeData={handleChange}
            data={formValues}
            validateNextStep={setValidateNextStep}
            next={next}
            fetchAllModules={fetchAllModules}
            fetchSelectedRole={fetchSelectedRole}
        />,
        <Step3
            onChangeData={handleChange}
            data={formValues}
            validateNextStep={setValidateNextStep}
            next={next}
            normalizedRoles={normalized}
            allModules={allModules}
        />,
        <Step4 data={formValues} onFinish={handleFinish} isTemporal={!!temporal} />,
    ];

    useEffect(() => {
        fetchAllModules();
    }, []);

    return (
        <Wizard
            currentStepIndex={currentStepIndex}
            headerItems={titles}
            onStepChange={onStepChange}
            step={steps[currentStepIndex - 1]}
            totalSteps={totalSteps}
            goTo={goTo}
            validateNextStep={validateNextStep}
            isLoading={isLoading}
            finishButtonText={t(TranslationCommon.CREATE)}
        />
    );
};

export default RolesWizard;
