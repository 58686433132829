import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";
import { DynamicFieldsType, ReportType } from "app/models/02-TAR/TaskWizard";

export const hasFieldsAndAtLeastOneIsEmpty = (fields: TaskInstanceFieldModel[], reportType?: ReportType) => {
    if (!fields.length) return false;
    const normalDynamicFieldsTypes: DynamicFieldsType[] = ["DATE", "DATETIME", "DATERANGE", "NUMBER", "TEXT", "TIME"];
    const dropDownDynamicFieldsTypes: DynamicFieldsType[] = [
        "DROPDOWN",
        "DROPDOWN_MULTIPLE",
        "DROPDOWN_MULTIPLE_ASSET",
        "CORRECT_INCORRECT",
    ];

    const fieldsFiltered = fields
        .filter(({ isOperative, asset2 }) => isOperative && (!asset2 || !asset2.notWorking))
        .filter(
            ({ asset2 }) =>
                reportType === undefined || reportType !== "ASSET" || (reportType && reportType === "ASSET" && !!asset2)
        );

    const normalDynamicFields = fieldsFiltered
        .filter(({ isRequired }) => isRequired)
        .filter(({ dynamicFieldType }) => normalDynamicFieldsTypes.includes(dynamicFieldType));
    const dropDownDynamicFields = fieldsFiltered
        .filter(({ isRequired }) => isRequired)
        .filter(({ dynamicFieldType }) => dropDownDynamicFieldsTypes.includes(dynamicFieldType));
    const isNormalDynamicFieldsEmpty = normalDynamicFields.some(
        ({ taskInstanceFieldValues }) => !taskInstanceFieldValues[0].value.length
    );

    const isDropDownDynamicFieldsEmpty = dropDownDynamicFields.some(({ taskInstanceFieldValues }) => {
        const tifvFlatted = taskInstanceFieldValues.flatMap(({ value }) => value);
        return tifvFlatted.every((value) => value === "" || value === "false");
    });

    return isNormalDynamicFieldsEmpty || isDropDownDynamicFieldsEmpty;
};
