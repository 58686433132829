import { AlertFilterModel } from "app/components_v2/__filters/AlertFilter/types";
import { AlertTabsModel } from "../types";
import { getDateFormattedSelects } from "app/helpers/Utilities/date/Date.utilities";

export type AlertExtraparamsProps = AlertFilterModel & {
    currentTab: AlertTabsModel;
    sessionUserId: number;
};

type receivedDatesExtraParamsProps = {
    receivedStartDate?: Date;
    receivedEndDate?: Date;
};

type sendDatesExtraParamsProps = {
    seenStartDate?: Date;
    seenEndDate?: Date;
};

export const alertExtraparams = ({
    currentTab,
    userId,
    companyId,
    departmentId,
    receivedEndDate,
    receivedStartDate,
    seenEndDate,
    seenStartDate,
    sessionUserId,
    qrId,
}: AlertExtraparamsProps) => {
    let extraParams = "";

    if (companyId.length) extraParams += `CompanyId=${companyId}&`;

    if (departmentId.length) extraParams += `DepartmentId=${departmentId}&`;

    if (currentTab === "received") extraParams += `ReceiverUserId=${userId}&`;

    if (currentTab === "sended") {
        extraParams += `SenderUserId=${userId}&`;
        extraParams += sendDatesExtraParams({ seenStartDate, seenEndDate });
    }

    if (currentTab === "programmed") {
        if (Number(userId) !== sessionUserId) extraParams += `ReceiverUserId=${userId}&`;

        extraParams += `IsProgrammed=true&SenderUserId=${sessionUserId}&`;
    }

    if (currentTab === "qr") {
        if (!!qrId.length && qrId !== "-1") extraParams += `QrId=${qrId}&`;
    }

    extraParams += receivedDatesExtraParams({ receivedEndDate, receivedStartDate });

    return extraParams;
};

const receivedDatesExtraParams = ({ receivedStartDate, receivedEndDate }: receivedDatesExtraParamsProps): string => {
    if (!receivedStartDate || !receivedEndDate) return "";
    return `receivedStartDate=${getDateFormattedSelects(receivedStartDate)}&receivedEndDate=${getDateFormattedSelects(
        receivedEndDate
    )}&`;
};

const sendDatesExtraParams = ({ seenStartDate, seenEndDate }: sendDatesExtraParamsProps): string => {
    if (!seenStartDate || !seenEndDate) return "";

    return `seenStartDate=${getDateFormattedSelects(seenStartDate)}&seenEndDate=${getDateFormattedSelects(
        seenEndDate
    )}&`;
};
