import { useEffect, useState, FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationModals, TranslationTitles } from "app/translation/translationKeys";
import { UserCentersAndDepartmentsData } from "./types";
import CompanyDepartmentBox from "app/components_v2/CompanyDepartmentBox/CompanyDepartmentBox";
import { CIDepartamentalStructureModel, SimpleDepartmentModel } from "app/models/01-SEG/CIDepartamentalStructureModel";
import { useTitle } from "app/hooks";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { Button } from "app/components_v2/__buttons/Button/Button";
import { faPlus, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { SecScreen } from "app/shared/Constants";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { Input } from "app/components_v2/__inputs";

type CentersAndDepartmentsTabProps = {
    onChangeNewData: (data: UserCentersAndDepartmentsData) => void;
    isDisabled?: boolean;
    values: CIDepartamentalStructureModel[];
    modifiedValues: CIDepartamentalStructureModel[];
};

export const CentersAndDepartmentsTab: FC<CentersAndDepartmentsTabProps> = ({
    onChangeNewData,
    isDisabled,
    modifiedValues,
    values,
}) => {
    const { t } = useTranslation();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    useTitle(
        translateCustomerTypeKeys(TranslationTitles.USER_CENTERS_AND_DEPARTMENTS_PAGE_TITLE, { firstReplace: "PLURAL" })
    );

    const [originalData, setOriginalData] = useState<CIDepartamentalStructureModel[]>([]);
    const [modifiedData, setModifiedData] = useState<CIDepartamentalStructureModel[]>([]);
    const [filteredData, setFilteredData] = useState<CIDepartamentalStructureModel[]>([]);
    const [isSelectAllCompAndDeptOpen, setIsSelectAllCompAndDeptOpen] = useState<boolean>(false);
    const [query, setQuery] = useState<string>("");
    const [isSearchInputOpen, setIsSearchInputOpen] = useState<boolean>(false);

    const handleModifyData = (companyId: number, newData: SimpleDepartmentModel[]) => {
        setModifiedData((prevData) => {
            const userCompanyData = prevData.map((company) => {
                if (company.companyId === companyId) {
                    return {
                        ...company,
                        departments: newData,
                    };
                }
                return company;
            });
            onChangeNewData({ modifiedData: userCompanyData, originalData: originalData });
            return userCompanyData;
        });
    };

    const handleChangeAssigned = (companyId: number) => {
        setModifiedData((prevData) => {
            const userCompanyData = prevData.map((company) => {
                if (company.companyId === companyId) {
                    const newCompanyAsignedStatus = !company.assigned;
                    return {
                        ...company,
                        assigned: newCompanyAsignedStatus,
                        departments: company.departments.map((department) => ({
                            ...department,
                            assigned: newCompanyAsignedStatus
                                ? company.departments.length === 1
                                    ? true
                                    : department.assigned
                                : false,
                        })),
                    };
                }
                return company;
            });
            onChangeNewData({ modifiedData: userCompanyData, originalData: originalData });

            return userCompanyData;
        });
    };

    const handleConfirmSelectAll = () => {
        setModifiedData((prev) => {
            const allSelected = prev.map(({ departments, ...company }) => ({
                ...company,
                assigned: true,
                departments: departments.map((department) => ({ ...department, assigned: true })),
            }));
            onChangeNewData({ modifiedData: allSelected, originalData: originalData });

            return allSelected;
        });
        setIsSelectAllCompAndDeptOpen(false);
    };

    const handleClickIcon = () => {
        setFilteredData(
            !!query.length
                ? modifiedData.filter(({ companyName }) => companyName.toLowerCase().includes(query.toLowerCase()))
                : modifiedData
        );
    };

    useEffect(() => {
        setOriginalData(values);
    }, [values]);

    useEffect(() => {
        setModifiedData(modifiedValues);
    }, [modifiedValues]);

    useEffect(() => {
        setFilteredData(
            modifiedValues.filter(({ companyName }) => companyName.toLowerCase().includes(query.toLowerCase()))
        );
    }, [modifiedData]);

    return (
        <>
            {isSelectAllCompAndDeptOpen && (
                <ConfirmModal
                    onClose={() => setIsSelectAllCompAndDeptOpen(false)}
                    onConfirm={handleConfirmSelectAll}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    title={translateCustomerTypeKeys(
                        TranslationModals.EDIT_FORM_USER_ADD_ALL_COMPANIES_AND_DEPARTMENTS_TITLE,
                        {
                            firstReplace: "PLURAL",
                            isGenderSensitive: true,
                        }
                    )}
                    description={translateCustomerTypeKeys(
                        TranslationModals.EDIT_FORM_USER_ADD_ALL_COMPANIES_AND_DEPARTMENTS_DESCRIPTION,
                        {
                            firstReplace: "PLURAL",
                            isGenderSensitive: true,
                        }
                    )}
                />
            )}
            <div className="userInfoForm ">
                <div className="userInfoForm--secondTab">
                    <div className="centersAndDepartmentsTab__header">
                        {hasPermissionToEdit(SecScreen.USER_MANAGEMENT) && (
                            <Button
                                text={t(TranslationCommon.ADD_ALL)}
                                onClick={() => setIsSelectAllCompAndDeptOpen(true)}
                                iconRight={faPlus}
                                type="link"
                            />
                        )}
                        <div className="centersAndDepartmentsTab__search">
                            <Input
                                onChange={setQuery}
                                value={query}
                                iconRight={faSearch}
                                onClickIcon={handleClickIcon}
                                onPressEnter={handleClickIcon}
                                focus
                                placeholder={t(TranslationCommon.SEARCH)}
                            />
                        </div>
                    </div>
                    {filteredData.map(({ assigned, companyId, companyName, departments }) => (
                        <CompanyDepartmentBox
                            assigned={assigned}
                            key={companyId}
                            title={companyName}
                            departments={departments}
                            sendModifiedData={(newData: SimpleDepartmentModel[]) =>
                                handleModifyData(companyId, newData)
                            }
                            handleToggleChecked={() => handleChangeAssigned(companyId)}
                            isDisabled={isDisabled}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};
