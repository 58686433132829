import { getDateFormattedSelects } from "app/helpers/Utilities/date/Date.utilities";
import { TaskHisotryGridSfModel } from "../../state/context/taskHistoryGridContext";

export const fillTaskHistoryExtraParams = ({
    startDate,
    endDate,
    pending,
    nearEnd,
    timedOut,
    inTime,
    outOfTime,
    userId,
    departmentId,
    subDepartmentId,
    oneStar,
    twoStar,
    threeStar,
    fourStar,
    fiveStar,
    isPhotoRequired,
    isDataReportRequired,
    isCritical,
    isAssignedToQR,
    companyId,
    hasComments,
    taskType,
    taskId,
    planId,
    assetsId,
}: TaskHisotryGridSfModel & { companyId: string; planId: string | null }) => {
    const extraparams = new URLSearchParams();

    if (planId !== null) extraparams.append("PlanId", planId);
    if (!!assetsId.length) extraparams.append("AssetsId", assetsId.map(({ value }) => value).join(";"));
    if (startDate) extraparams.append("DeadlineDate", getDateFormattedSelects(startDate));
    if (endDate) extraparams.append("DeadlineDate1", getDateFormattedSelects(endDate));
    if (!startDate && !endDate) extraparams.append("AllDates", "true");
    if (pending) extraparams.append("Pending", String(pending));
    if (nearEnd) extraparams.append("NearEnd", String(nearEnd));
    if (timedOut) extraparams.append("TimedOut", String(timedOut));
    if (inTime) extraparams.append("InTime", String(inTime));
    if (outOfTime) extraparams.append("OutOfTime", String(outOfTime));
    if (companyId && companyId != "-1") extraparams.append("CompanyId", companyId);
    if (userId && userId != "-1") extraparams.append("AsignedToUserId", userId);
    if (departmentId && departmentId != "-1") extraparams.append("AsignedToDepartmentId", departmentId);
    if (subDepartmentId && subDepartmentId != "-1") extraparams.append("AsignedToSubdepartmentId", subDepartmentId);
    if (oneStar) extraparams.append("OneStar", String(oneStar));
    if (twoStar) extraparams.append("TwoStar", String(twoStar));
    if (threeStar) extraparams.append("ThreeStar", String(threeStar));
    if (fourStar) extraparams.append("FourStar", String(fourStar));
    if (fiveStar) extraparams.append("FiveStar", String(fiveStar));
    if (isPhotoRequired) extraparams.append("IsPhotoRequired", String(isPhotoRequired));
    if (isDataReportRequired) extraparams.append("DataReport", String(isDataReportRequired));
    if (isCritical) extraparams.append("Critical", String(isCritical));
    if (isAssignedToQR) extraparams.append("isAssignedToQR", "true");
    if (hasComments) extraparams.append("HasComments", "true");
    if (taskType && taskType !== "-1") extraparams.append("taskType", taskType);
    if (!!taskId.length) extraparams.append("taskId", taskId);

    return extraparams.toString();
};
