import { TranslationKeys } from "app/translation/translationKeys";
import { TaskInstancePhotoModel } from "app/models/02-TAR/TaskInstance/TaskInstancePhotoModel";
import { convertUTCtoLocaleDate, getDayMonthAndHourFormated } from "app/helpers/Utilities/date/Date.utilities";

export const getTaskInstancePhotoBadge = ({ takenInRealTime, takenDate }: TaskInstancePhotoModel, t: Function) => {
    if (!takenInRealTime) return t(t(TranslationKeys.TASK_DETAIL_PHOTO_GALERY));
    if (!takenDate) return t(TranslationKeys.TASK_DETAIL_PHOTO_INSTANT);

    return getDayMonthAndHourFormated(convertUTCtoLocaleDate(new Date(takenDate)));
};
