import { isPhoto } from "app/helpers/__validates/media/validateIsPhoto";
import { useToast } from "app/hooks/Toast/useToast";
import { TranslationKeys, TranslationModals } from "app/translation/translationKeys";
import { t } from "i18next";
import { FC } from "react";
import { DragFileWithPreview } from "../__draggableFiles/DragFileWithPreview/DragFileWithPreview";
import { Switch } from "../__inputs";

type SwitchDragFileProps = {
    image: string | File;
    isChecked: boolean;
    handleChangePhoto: (e: any) => void;
    onDeleteImage: (value: boolean) => void;
    onChangePhoto: (image: string) => void;
    onlyImages?: boolean;
    disabledCheckbox?: boolean;
    disabledImage?: boolean;
    isImageEditable?: boolean;
};

export const SwitchDragFile: FC<SwitchDragFileProps> = ({
    image,
    isChecked,
    handleChangePhoto,
    onChangePhoto,
    onDeleteImage,
    onlyImages = true,
    disabledCheckbox,
    disabledImage,
    isImageEditable,
}) => {
    const { handleToast } = useToast();

    const handleChange = (e: any) => {
        if (onlyImages) {
            const isFilePhoto = checkIsPhoto(e);

            if (!isFilePhoto) return;
        }

        handleChangePhoto(e);
    };

    const checkIsPhoto = (e: any): boolean => {
        if (!e.target.files.length) return false;
        const file = e.target.files[0];
        const isFilePhoto = isPhoto(file);
        if (!isFilePhoto) {
            handleToast({
                type: "alert",
                title: t(TranslationModals.TOAST_PHOTO_NOT_ALLOWED_TITLE),
                subtitle: t(TranslationModals.TOAST_PHOTO_NOT_ALLOWED_SUBTITLE),
                variant: "danger",
            });
            return false;
        }

        return true;
    };

    return (
        <div className="switchDragFile">
            <div className="switchDragFile__label">
                <Switch
                    checked={isChecked}
                    onChange={onDeleteImage}
                    disabled={disabledCheckbox}
                    label={t(TranslationKeys.PHOTOGRAPHIC_REGISTER)}
                />
            </div>
            {isChecked && (
                <DragFileWithPreview
                    name="somethingToReportImage"
                    onChange={handleChange}
                    value={image}
                    setChangeImg={onChangePhoto}
                    onDeleteImg={() => onDeleteImage(false)}
                    disabled={disabledImage}
                    onlyImages={onlyImages}
                    isImageEditable={isImageEditable}
                />
            )}
        </div>
    );
};
