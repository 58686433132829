import { compressImage } from "app/helpers/Media/compress/compressImage";
import { EditTaskDisabledExtraDataModel, ExtraDataModel } from "app/models/02-TAR/Task/EditTask";

type IUseExtraDara = {
    onChange: (fields: Partial<ExtraDataModel>) => void;
    disabledFields?: EditTaskDisabledExtraDataModel;
};

export const useExtraData = ({ onChange, disabledFields }: IUseExtraDara) => {
    const onChangeImg = (img: string) => {
        onChange({
            imageBase64: img,
            fotoExampleId: null,
            somethingToReportImage: img,
        });
    };

    const handleChangePhoto = async (e: any) => {
        const photo = await compressImage({ file: e.target.files[0], quality: 0.6 });
        onChange({
            somethingToReportImage: photo as File,
        });
    };

    const onDeleteImg = (check: boolean) => {
        onChange({
            imageBase64: "",
            somethingToReportImage: "",
            fotoExampleId: null,
            isPhotoRequired: disabledFields?.disabledIsPhotoRequired ? true : check,
            minPhotosAllowed: check ? 1 : null,
        });
    };

    return {
        onChangeImg,
        handleChangePhoto,
        onDeleteImg,
    };
};
