export const AVATAR_COLORS: string[] = [
    "orange",
    "blue",
    "yellow",
    "purple",
    "green",
    "orangeDark",
    "blueLight",
    "greenLight",
    "fuchsia",
    "blueDark",
];

export const AVATAR_COLORS_HEX_MAP: Record<string, string> = {
    orange: "#e04f16",
    blue: "#1570ef",
    yellow: "#ca8504",
    purple: "#6938ef",
    green: "#099250",
    orangeDark: "#e62e05",
    blueLight: "#0086c9",
    greenLight: "#4ca30d",
    fuchsia: "#ba24d5",
    blueDark: "#155eef",
};
