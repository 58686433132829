import { PaginatedList } from "app/models/ServiceResponse/PaginatedResult";
import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { IssueInsertModel, IssueModel } from "app/models/05-QUA/IssueModels/IssueModel";
import { IssueStatusInsertModel } from "app/models/05-QUA/IssueModels/IssueStatusModels";
import { IssueCloseDto } from "app/dtos/05-QUA/Issue/IssueCloseDto";
import { IssueListDto } from "app/dtos/05-QUA/Issue/IssueListDto";
import { EditIssueDto } from "app/dtos/05-QUA/Issue/EditIssueDto";

export default class AuditInstanceIssueService {
    public static async GetOne(id: number) {
        return FetchService.get<IssueModel>({
            url: `${ApiPaths.QUA_API}/AuditInstanceIssue/${id}`,
        });
    }

    public static async GetData(paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedList<IssueListDto>>({
            url: `${ApiPaths.QUA_API}/AuditInstanceIssue`,
            paginationParams,
        });
    }

    public static async Save(issue: IssueInsertModel) {
        return FetchService.post<number>({
            url: `${ApiPaths.QUA_API}/AuditInstanceIssue`,
            body: issue,
        });
    }

    public static async Edit(id: number, issue: EditIssueDto) {
        return FetchService.put<number>({
            url: `${ApiPaths.QUA_API}/AuditInstanceIssue/${id}`,
            body: issue,
        });
    }

    public static async CloseAsync(id: number, issueCloseDto: IssueCloseDto) {
        return FetchService.put<number>({
            url: `${ApiPaths.QUA_API}/AuditInstanceIssue/${id}/Close`,
            body: issueCloseDto,
        });
    }

    public static async ReopenAsync(id: number, issueStatus: IssueStatusInsertModel) {
        return FetchService.put<number>({
            url: `${ApiPaths.QUA_API}/AuditInstanceIssue/${id}/Reopen`,
            body: issueStatus,
        });
    }

    public static async DeleteIssueByChecklistIdAsync(id: number) {
        return FetchService.delete({
            url: `${ApiPaths.QUA_API}/AuditInstanceIssue/${id}/DeleteIssueByChecklistIdAsync`,
        });
    }
}
