type ReceivedNotificationsExtraParamsProps = {
    receiverUserId?: number;
    notSeen?: boolean;
};

export const receivedNotificationsExtraParams = ({
    notSeen,
    receiverUserId,
}: ReceivedNotificationsExtraParamsProps) => {
    let extraParams = "";

    extraParams += `ReceiverUserId=${receiverUserId}&`;

    if (notSeen) extraParams += `NotSeen=${notSeen}&`;

    return extraParams;
};
