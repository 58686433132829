import { validateEmail } from "app/helpers/__validates/validateCompanyForm";
import { getInitials } from "app/helpers/Avatar/getInitials";
import { useSession } from "app/hooks";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import { TranslationErrors } from "app/translation/translationKeys";
import { t } from "i18next";
import { useState, FormEvent, useEffect, KeyboardEvent } from "react";
import {
    INITIAL_NOTIFICATION_DISTRIBUTION_LIST_FORM_VALUES,
    INITIAL_NOTIFICATION_DISTRIBUTION_LIST_ERRORS,
} from "../../../constants/NotificationDistributionListConstants";
import {
    NotificationDistributionListFormValues,
    NotificationDistributionListErrors,
} from "../../../models/NotificationDistributionListFormValues";
import { DistributionListFilterType } from "../../../models/NotificationDistributionListGridModels";
import { useToast } from "app/hooks/Toast/useToast";

type UseCreateNotificationDistributionListProps = {
    onSubmit: (values: NotificationDistributionListFormValues) => Promise<void>;
};

export const useCreateNotificationDistributionList = ({ onSubmit }: UseCreateNotificationDistributionListProps) => {
    const session = useSession();
    const { handleErrorManager } = useErrorManager();
    const { getErrorMessage } = useFetchErrors();
    const { handleToast } = useToast();

    const [usersOptions, setUsersOptions] = useState<OptionModel[]>([]);
    const [distributionListTypes, setDistributionListTypes] = useState<OptionModel[]>([]);
    const [companyOptions, setCompanyOptions] = useState<OptionModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
    const [addAllUsersModalOpen, setAddAllUsersModalOpen] = useState<boolean>(false);
    const [addAllCompaniesModalOpen, setAddAllCompaniesModalOpen] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [formValues, setFormValues] = useState<NotificationDistributionListFormValues>(
        INITIAL_NOTIFICATION_DISTRIBUTION_LIST_FORM_VALUES
    );
    const [notificationDistributionListErrors, setNotificationDistributionListErrors] =
        useState<NotificationDistributionListErrors>(INITIAL_NOTIFICATION_DISTRIBUTION_LIST_ERRORS);

    const {
        companyList,
        fK_DistributionList,
        userList,
        emails,
        notifyUsers,
        notifyEmails,
        notifyWorkers,
        notifySupervisors,
        notifyAnalists,
        notifyAdminClients,
    } = formValues;

    const handleInputChange = (values: Partial<NotificationDistributionListFormValues>) => {
        setNotificationDistributionListErrors(INITIAL_NOTIFICATION_DISTRIBUTION_LIST_ERRORS);
        setFormValues((prev) => ({ ...prev, ...values }));
    };

    const handleErrorChange = (values: Partial<NotificationDistributionListErrors>) =>
        setNotificationDistributionListErrors((prev) => ({ ...prev, ...values }));

    const distributionListFilter: Record<number, DistributionListFilterType> = {
        1: "SporadicTasksPermission",
        2: "AuditReportPermission",
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validate()) return;
        setIsLoadingSave(true);
        await onSubmit({
            ...formValues,
            userList: notifyUsers ? userList : [],
            emails: notifyEmails ? emails : [],
            notifyWorkers,
            notifySupervisors,
            notifyAnalists,
            notifyAdminClients,
        });
        setIsLoadingSave(false);
    };

    const validate = () => {
        let isValid = true;
        setNotificationDistributionListErrors(INITIAL_NOTIFICATION_DISTRIBUTION_LIST_ERRORS);

        if (!fK_DistributionList.length) {
            handleErrorChange({ errorFunctionality: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
            isValid = false;
        }

        // if (fK_DistributionList === "3") return isValid;

        if (!companyList.length && fK_DistributionList !== "3") {
            handleErrorChange({ errorCompany: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
            isValid = false;
        }

        if (!notifyUsers && !notifyEmails) {
            handleErrorChange({
                errorNotSelected: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION),
            });
            isValid = false;
        }
        const isNotAnyUserNotified =
            !userList.length && !notifyWorkers && !notifySupervisors && !notifyAnalists && !notifyAdminClients;
        if (notifyUsers && isNotAnyUserNotified) {
            handleErrorChange({ errorUserList: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
            isValid = false;
        }
        if (notifyEmails && !emails.length) {
            handleErrorChange({ errorEmails: t(TranslationErrors.ADD_AT_LEAST_ONE) });
            isValid = false;
        }

        return isValid;
    };

    const getDistributionListTypes = async () => {
        const { data, status, getParsedError } = await SegSelectorService.GetDistributionListTypes();

        const distributionErrorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), distributionErrorMessage)) return;

        if (!data.length) {
            setDistributionListTypes([]);
            return;
        }
        setDistributionListTypes(data.map((option) => ({ ...option, label: t(option.label) })));
    };

    const getUsers = async (companiesId: string, distributionListId: number) => {
        const supervisorParams = new URLSearchParams();
        if (session?.user.id) supervisorParams.append("userId", session?.user.id.toString());
        supervisorParams.append("includeMyself", "true");
        if (distributionListId == 4) supervisorParams.append("onlyAnalists", "true");

        if (!!companiesId.length) supervisorParams.append("CompaniesId", companiesId);

        if (distributionListId == 1 || distributionListId == 2)
            supervisorParams.append(distributionListFilter[distributionListId], "true");

        const supervisorAndAnalistUsersPromise = SegSelectorService.GetDistributionListUsers({
            extraParams: supervisorParams.toString(),
        });

        return supervisorAndAnalistUsersPromise;
    };

    const handleChangeDistributionList = async ({ value }: OptionModel) => {
        setIsLoading(true);
        handleInputChange({ fK_DistributionList: value });

        if (value === "3") {
            await handleChangeCompany([], Number(value));
            setIsLoading(false);
            return;
        }

        const companyOptionsPromise = SegSelectorService.GetCompanyNamesWithIds({
            extraParams: `supervisorId=${session?.user.id}`,
        });

        const { data, status, getParsedError } = await companyOptionsPromise;
        const companyErrorMessage = getErrorMessage(getParsedError());
        setFormValues((prev) => ({ ...prev, userList: [], companyList: [] }));
        if (handleErrorManager(status(), companyErrorMessage)) return;

        setCompanyOptions(
            data.map((data) => ({
                ...data,
                initials: getInitials(data.label),
            }))
        );
        setIsLoading(false);
    };

    const handleChangeCompany = async (values: OptionModel[], distributionListId: number) => {
        const companiesId = values.map(({ value }) => value).join(";");
        handleInputChange({ companyList: values });
        handleGetUsers(companiesId, distributionListId);
    };

    const handleGetUsers = async (companiesId: string, distributionListId: number) => {
        const { data, status } = await getUsers(companiesId, distributionListId);
        if (!status()) {
            setUsersOptions([]);
            setIsLoading(false);
            return;
        }

        handleInputChange({ userList: userList.filter(({ value }) => data.some((x) => x.value === value)) });
        setUsersOptions(data.map((data) => ({ ...data, initials: getInitials(data.label) })));
    };

    const handleDeleteComapny = async (id: string) => {
        const selectedCompany = companyList.find(({ value }) => value === id);

        if (!selectedCompany) return;

        if (companyList.length === 1) {
            handleInputChange({ companyList: [], userList: [] });
            return;
        }

        await handleChangeCompany(
            companyList.filter(({ value }) => value !== id),
            Number(fK_DistributionList)
        );
    };

    const handleDeleteUser = (id: string) => {
        const { userList } = formValues;
        const selectedItem = userList.find(({ value }) => value === id);
        if (!selectedItem) return;

        handleInputChange({ userList: userList.filter(({ value }) => value !== id) });
    };

    const onConfirmAddAllUsers = () => {
        handleInputChange({
            userList: [...userList, ...usersOptions.filter((uo) => !userList.some((ul) => uo.value === ul.value))],
        });
        setAddAllUsersModalOpen(false);
    };

    const onConfirmAddAllCompanies = () => {
        handleInputChange({
            companyList: [
                ...companyList,
                ...companyOptions.filter((co) => !companyList.some((cl) => co.value === cl.value)),
            ],
        });
        setAddAllCompaniesModalOpen(false);
        const companiesId = companyOptions.map(({ value }) => value).join(";");
        handleGetUsers(companiesId, Number(fK_DistributionList));
    };

    const handleAddEmail = (e?: KeyboardEvent<HTMLInputElement>) => {
        e?.stopPropagation();
        e?.preventDefault();
        if (emails.includes(email)) {
            handleToast({ title: t(TranslationErrors.EMAIL_ALREADY_ADDED), variant: "danger", type: "alert" });
            return;
        }
        if (!validateEmail(email)) {
            handleErrorChange({ errorEmails: t(TranslationErrors.EMAIL_IS_INVALID) });
            return;
        }
        handleInputChange({ emails: [...emails, email] });
        setEmail("");
    };

    const handleDeleteEmail = (index: number) => handleInputChange({ emails: emails.filter((_, i) => i !== index) });

    useEffect(() => {
        getDistributionListTypes();
    }, []);

    return {
        addAllCompaniesModalOpen,
        addAllUsersModalOpen,
        companyOptions,
        distributionListTypes,
        email,
        formValues,
        handleAddEmail,
        handleChangeCompany,
        handleChangeDistributionList,
        handleDeleteComapny,
        handleDeleteEmail,
        handleDeleteUser,
        handleInputChange,
        handleSubmit,
        isLoading,
        isLoadingSave,
        notificationDistributionListErrors,
        onConfirmAddAllCompanies,
        onConfirmAddAllUsers,
        setAddAllCompaniesModalOpen,
        setAddAllUsersModalOpen,
        setEmail,
        usersOptions,
    };
};
