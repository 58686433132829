import { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { DocumentalFile } from "app/components_v2/Documental/DocumentalFile/DocumentalFile";
import { faArrowDownToLine, faClone, faGear, faPen } from "@fortawesome/pro-regular-svg-icons";

export const DocumentalFileSection: FC<SectionsShowProp> = ({ show }) => {
    return (
        <Section title="Documental File" show={show}>
            <div
                style={{
                    display: "grid",
                    gap: "10px",
                    gridTemplateColumns: "repeat(auto-fill, 200px)",
                    width: "100%",
                }}
            >
                <DocumentalFile
                    date="12 Feb 2024"
                    onClick={() => {
                        console.log("DocumentalFile clicked");
                    }}
                    moreOptionsItems={[
                        { text: "Descargar todo", icon: faArrowDownToLine },
                        { text: "Duplicar", icon: faClone },
                        { text: "Renombrar", icon: faPen },
                        { text: "Editar Acceso", icon: faGear },
                    ]}
                    name="Documental File"
                    type="Excel"
                />
                <DocumentalFile
                    date="12 Feb 2024"
                    onClick={() => {
                        console.log("DocumentalFile clicked");
                    }}
                    moreOptionsItems={[
                        { text: "Descargar todo", icon: faArrowDownToLine },
                        { text: "Duplicar", icon: faClone },
                        { text: "Renombrar", icon: faPen },
                        { text: "Editar Acceso", icon: faGear },
                    ]}
                    name="Documental File"
                    type="Docx"
                />
                <DocumentalFile
                    date="12 Feb 2024"
                    onClick={() => {
                        console.log("DocumentalFile clicked");
                    }}
                    moreOptionsItems={[
                        { text: "Descargar todo", icon: faArrowDownToLine },
                        { text: "Duplicar", icon: faClone },
                        { text: "Renombrar", icon: faPen },
                        { text: "Editar Acceso", icon: faGear },
                    ]}
                    name="Documental File"
                    type="Pdf"
                />
                <DocumentalFile
                    date="12 Feb 2024"
                    onClick={() => {
                        console.log("DocumentalFile clicked");
                    }}
                    moreOptionsItems={[
                        { text: "Descargar todo", icon: faArrowDownToLine },
                        { text: "Duplicar", icon: faClone },
                        { text: "Renombrar", icon: faPen },
                        { text: "Editar Acceso", icon: faGear },
                    ]}
                    name="Documental File"
                    type="Video"
                />
                <DocumentalFile
                    date="12 Feb 2024"
                    onClick={() => {
                        console.log("DocumentalFile clicked");
                    }}
                    moreOptionsItems={[
                        { text: "Descargar todo", icon: faArrowDownToLine },
                        { text: "Duplicar", icon: faClone },
                        { text: "Renombrar", icon: faPen },
                        { text: "Editar Acceso", icon: faGear },
                    ]}
                    name="Documental File"
                    type="Audio"
                />
                <DocumentalFile
                    date="12 Feb 2024"
                    onClick={() => {
                        console.log("DocumentalFile clicked");
                    }}
                    moreOptionsItems={[
                        { text: "Descargar todo", icon: faArrowDownToLine },
                        { text: "Duplicar", icon: faClone },
                        { text: "Renombrar", icon: faPen },
                        { text: "Editar Acceso", icon: faGear },
                    ]}
                    name="Documental File"
                    type="Image"
                />
                <DocumentalFile
                    date="12 Feb 2024"
                    onClick={() => {
                        console.log("DocumentalFile clicked");
                    }}
                    moreOptionsItems={[
                        { text: "Descargar todo", icon: faArrowDownToLine },
                        { text: "Duplicar", icon: faClone },
                        { text: "Renombrar", icon: faPen },
                        { text: "Editar Acceso", icon: faGear },
                    ]}
                    name="Documental File"
                    type="Image"
                    imageUrl="https://az4linkblobstorage.blob.core.windows.net/az-4link-blob-storage/dd9079d5-20ab-44f5-a8f3-ba27080633e9.png"
                />
            </div>
        </Section>
    );
};
