import { DepartmentNameModel } from "app/models/01-SEG/DepartmentName/DepartmentNameModel";
import { FC } from "react";
import { ActionType } from "app/models/FormComponentsModel";
import { useTranslation } from "react-i18next";
import { DepartmentNameTranslations, TranslationCommon } from "app/translation/translationKeys";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { Input } from "app/components_v2/__inputs";
import { useDepartmentNameModal } from "./hooks/useDepartmentNameModal";

export type DepartmentNameModalProps = {
    selectedDepartmentName: DepartmentNameModel | null;
    onAddNew: (departmentName: DepartmentNameModel) => void;
    onEdit: (departmentName: DepartmentNameModel) => void;
    onCancel: () => void;
};

const formId = "DepartmentNameFormModalId";

export const DepartmentNameModal: FC<DepartmentNameModalProps> = ({
    onAddNew,
    onCancel,
    onEdit,
    selectedDepartmentName,
}) => {
    const { t } = useTranslation();

    const { action, errorMessage, onSubmit, isLoading, onNameChange, name } = useDepartmentNameModal({
        onAddNew,
        onEdit,
        selectedDepartmentName,
    });
    const getTitle = () => {
        const title: Record<ActionType, string> = {
            create: t(DepartmentNameTranslations.DEPARTMENT_NAME_ADD_TITLE),
            edit: t(DepartmentNameTranslations.DEPARTMENT_NAME_ADD_EDIT),
        };
        return title[action];
    };

    const getConfirmButton = () => {
        const confirmButtonText: Record<ActionType, string> = {
            create: t(TranslationCommon.CREATE),
            edit: t(TranslationCommon.SAVE),
        };

        return confirmButtonText[action];
    };
    return (
        <GenericModal
            size="sm"
            header={{ title: getTitle(), onClose: onCancel }}
            footer={
                <GenericModalFooter
                    confirmButton={{
                        text: getConfirmButton(),
                        form: formId,
                        type: "submit",
                    }}
                    closeButton={{
                        text: t(TranslationCommon.CANCEL),
                        buttonType: "secondary",
                        onClick: onCancel,
                    }}
                    loading={isLoading}
                />
            }
        >
            <form onSubmit={onSubmit} id={formId}>
                <Input
                    onChange={onNameChange}
                    label={t(DepartmentNameTranslations.DEPARTMENT_NAME_LABEL)}
                    placeholder={t(DepartmentNameTranslations.DEPARTMENT_NAME_PLACEHOLDER)}
                    value={name}
                    errorMessage={errorMessage}
                    focus
                />
            </form>
        </GenericModal>
    );
};
