import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRankingPill } from "app/hooks/RankingPill/useRankingPill";
import { useToast } from "app/hooks/Toast/useToast";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { PrivatePaths } from "app/shared/Constants";
import { getTaskType } from "app/helpers/Analist/getRankingStatus";
import { AnalystEntityType } from "app/shared/types/AnalyticsTypes";
import { AnalistEntityDetailRankingModel } from "app/models/03-REP/Analytics/AnalistEntityDetailRankingModel";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { RankingPill } from "app/components_v2/RankingPill/RankingPill";
import { RankingPillListSkeleton } from "app/components_v2/__skelletons/RankingPillListSkeleton/RankingPillListSkeleton";

type AnalistDetailDonutListProps = {
    entityType: AnalystEntityType;
    isLoading?: boolean;
    rankingList?: AnalistEntityDetailRankingModel[];
    taskStatus?: string;
};
export const AnalistDetailDonutList: FC<AnalistDetailDonutListProps> = ({
    entityType,
    isLoading = false,
    rankingList,
    taskStatus,
}) => {
    const nav = useNavigate();
    const { handleToast } = useToast();
    const { t } = useTranslation();
    const { calculatePercentageDependingDeviceWidth, getNumbersOfStarsDependingDeviceWidth } = useRankingPill();
    const handleRankingPillList = (type: string, id: number) => {
        if (type === "WORKER") {
            nav(`/${PrivatePaths.COMPANY_RANKING}/user/${id}`);
            return;
        }
        if (type === "DEPARTMENT") {
            nav(`/${PrivatePaths.COMPANY_RANKING}/team/${id}`);
            return;
        }
        handleToast({
            title: t(TranslationKeys.ANALIST_DISABLED_USER_TITLE),
            subtitle: t(TranslationKeys.ANALIST_DISABLED_USER_BODY),
            type: "alert",
            variant: "danger",
        });
    };

    return (
        <>
            {isLoading ? (
                <RankingPillListSkeleton quantity={4} variant="percentage" hideValoration />
            ) : taskStatus && rankingList ? (
                rankingList.map((entity: AnalistEntityDetailRankingModel) => (
                    <RankingPill
                        pillStarsBottom
                        name={entity.entityHeaderInfoModel.name}
                        hexColor={entity.entityHeaderInfoModel.hexColor}
                        jobDescription={
                            entity.entityHeaderInfoModel.jobDescription !== ""
                                ? entity.entityHeaderInfoModel.jobDescription
                                : entity.entityType === "DEPARTMENT"
                                ? t(TranslationKeys.SHARED_TIMED_OUT_TASKS)
                                : ""
                        }
                        totalTasks={entity.entityHeaderInfoModel.totalTasks}
                        thin
                        counters={[
                            {
                                counter: entity?.count ?? 0,
                                type: getTaskType(taskStatus, entity.count ?? 0),
                                percentage: calculatePercentageDependingDeviceWidth(entity.percentage || 0),
                            },
                        ]}
                        type="percentage"
                        profileURL={entity.entityHeaderInfoModel.photoURL}
                        colorId={entity.id}
                        starsValoration={entity?.entityHeaderInfoModel.avg}
                        borderType="line"
                        trend={entity.entityHeaderInfoModel.trend || 0}
                        key={entity.id}
                        onClick={() => {
                            handleRankingPillList(entity.entityType, entity.id);
                        }}
                        numberOfStars={getNumbersOfStarsDependingDeviceWidth()}
                        cursorDefault={entityType === "DEPARTMENT" && entity.entityType !== "WORKER"}
                    />
                ))
            ) : (
                <TableError icon={faListCheck} title={t(TranslationCommon.NO_DATA_FOR_YOUR_SEARCH)} />
            )}
        </>
    );
};
