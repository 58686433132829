import { getStoreSession, setLocalStorageSession } from "app/helpers/BrowserStorage/LocalStorageHandler";
import { SecScreen, PrivatePaths, PublicPaths, Views, WizardModeUrls } from "app/shared/Constants";
import { Actions } from "app/state/actions";
import { IAppState } from "app/state/reducer";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router";
import { DashboardPage, GroupCompanyPage, LicensePage, MyProfilePage, RolePage, TaskPage, UsersPage } from "app/pages";
import { SelectDepartmentWizard } from "app/components_v2/PostLogin/wizard/SelectDepartmentWizard";
import { ReviewTasksPage } from "app/pages/02-TAR/02-TAR-ReviewFotos/ReviewTasksPage";
import { FeedbackPage } from "app/pages/02-TAR/04-TAR-Feedback/FeedbackPage";
import TaskHistoryPage from "app/pages/02-TAR/09-TAR-Historic/TaskHistoryPage";
import { AlertsPage } from "app/pages/02-TAR/11-Alerts/AlertsPage";
import { DesignSysComponent } from "app/pages/02-TAR/DESIGN-SYS/DesignSysComponent";
import { NotificationPage } from "app/pages/Notification/NotificationPage";
import PrivateRoute from "./PrivateRoute";
import { PendingAlertsPage } from "app/pages/02-TAR/12-Pending-Alerts/PendingAlertsPage";
import { WorkingPositionPage } from "app/pages/02-TAR/14-WorkingPosition/WorkingPositionPage";
import { AnalystRankingPage } from "app/pages/03-REP/01-AnalystRankingPage/AnalystRankingPage";
import { TemporaryRolePage } from "app/pages/01-SEG/TemporaryRole/TemporaryRolePage";
import { ResetPasswordPage } from "app/pages/00-LOGIN/ResetPassword/ResetPasswordPage";
import { useSession } from "app/hooks";
import { LocalStorageSession, ReduxSession } from "app/models/SessionModel";
import { useUserType } from "app/hooks/useUserType";
import isiOS from "app/helpers/Mobile/isIos";
import { IssuePage } from "app/pages/05-QUA/Issue/IssuePage";
import { BaseTaskPage } from "app/pages/02-TAR/15-BaseTask/BaseTaskPage";
import { PlanAPPCCPage } from "app/pages/05-QUA/PlanAPPCC/PlanAPPCCPage";
import { TaskAPPCCPage } from "app/pages/05-QUA/TaskAPPCC/TaskAPPCCPage";
import { CorrectiveMeasurePage } from "app/pages/05-QUA/CorrectiveMeasure/CorrectiveMeasurePage";
import { AuditTemplatePage } from "app/pages/05-QUA/AuditTemplate/AuditTemplatePage";
import { AuditPage } from "app/pages/05-QUA/Audit/AuditPage";
import { AssetPage } from "app/pages/05-QUA/Asset/AssetPage";
import { FAQPage } from "app/pages/FAQ/FAQPage";
import { BaseAuditGroupCheckListItemPage } from "app/pages/05-QUA/BaseAuditGroupCheckListItem/BaseAuditGroupCheckListItemPage";
import { AutomaticRecordPage } from "app/pages/04-SEN/AutomaticRecordPage";
import { BaseTaskAPPCCPage } from "app/pages/02-TAR/15-BaseTask/BaseTaskAPPCCPage";
import { OnBoardingSteps } from "app/pages/01-SEG/OnBoarding/OnBoardingSteps/OnBoardingSteps";
import NotificationDistributionListPage from "app/pages/01-SEG/NotificationDistributionList/NotificationDistributionListPage";
import { PatchNotesPage } from "app/pages/01-SEG/PatchNotes/PatchNotesPage";
import RegistryHistoryPage from "app/pages/05-QUA/RegistryHistory/RegistryHistoryPage";
import { AssetRangePage } from "app/pages/05-QUA/AssetRange/AssetRangePage";
import { mergeProfileCompaniesAndDepartments } from "app/helpers/Company/companyHelper";
import { DocumentalPage } from "app/pages/07-DOC/DocumentalPage";

const ConfigApp: FC = () => {
    const isLoggedIn = useSelector<IAppState, boolean>((state) => state.isLoggedIn);
    const instanceId = useSelector<IAppState, number | undefined>((state) => state.customerInstanceId);
    const { wizardMode } = useSelector<IAppState, IAppState>((state) => state);

    const dispatch = useDispatch();
    const nav = useNavigate();
    const location = useLocation();
    const { isAdmin } = useUserType();
    const session = useSession();
    const isOnBoarding = !!session?.isOnBoarding;

    const companies = mergeProfileCompaniesAndDepartments(session?.user?.companies, session?.user?.temporaryCompanies);
    const hideFooter =
        location.pathname.includes(PrivatePaths.ACCEPT_POLICY) ||
        location.pathname.includes(PublicPaths.NEW_PASSWORD) ||
        location.pathname.includes(PrivatePaths.SELECT_DEPARTMENT) ||
        location.pathname.includes(`${PrivatePaths.DASHBOARD}/qr`);

    const routes = [
        {
            path: `${PrivatePaths.USER_MANAGEMENT}/*`,
            component: UsersPage,
            viewCode: SecScreen.USER_MANAGEMENT,
        },
        {
            path: `${PrivatePaths.ROLE_MANAGEMENT}/*`,
            component: RolePage,
            viewCode: SecScreen.ROLE_MANAGEMENT,
        },
        {
            path: `${PrivatePaths.TEMPORARY_ROLE_PAGE}/*`,
            component: TemporaryRolePage,
            viewCode: SecScreen.TEMPORARY_ROLES,
        },
        {
            path: `${PrivatePaths.CENTERS_MANAGEMENT}/*`,
            component: GroupCompanyPage,
            viewCode: SecScreen.CENTERS_MANAGEMENT,
        },
        {
            path: PrivatePaths.MY_PROFILE,
            component: MyProfilePage,
            viewCode: Views.MY_PROFILE,
        },
        {
            path: `${PrivatePaths.LICENSE_PAGE}/*`,
            component: LicensePage,
            viewCode: SecScreen.LICENSES,
        },
        {
            path: `${PrivatePaths.TASK_PHOTO}/*`,
            component: ReviewTasksPage,
            viewCode: SecScreen.TASK_REVIEW,
        },
        {
            path: `${PrivatePaths.FEEDBACK_TASKS}/*`,
            component: FeedbackPage,
            viewCode: SecScreen.MY_VALORATION,
        },
        {
            path: `${PrivatePaths.TASK_PAGE}/*`,
            component: TaskPage,
            viewCode: SecScreen.TASK,
        },
        {
            path: `${PrivatePaths.TASK_HISTORY}/*`,
            component: TaskHistoryPage,
            viewCode: SecScreen.TASK_HISTORY,
        },
        {
            path: `${PrivatePaths.REGISTRY_HISTORY}/*`,
            component: RegistryHistoryPage,
            viewCode: SecScreen.REGISTRY_HISTORY,
        },
        {
            path: `${PrivatePaths.COMPANY_RANKING}/*`,
            component: AnalystRankingPage,
            viewCode: SecScreen.COMPANY_RANKING,
        },
        {
            path: PrivatePaths.ALERTS,
            component: AlertsPage,
            viewCode: SecScreen.ALERTS,
        },
        {
            path: PrivatePaths.PENDING_ALERTS,
            component: PendingAlertsPage,
            viewCode: SecScreen.ALERTS,
        },
        {
            path: `${PrivatePaths.QR}/*`,
            component: WorkingPositionPage,
            viewCode: SecScreen.QR,
        },
        {
            path: `${PrivatePaths.ISSUE}/*`,
            component: IssuePage,
            viewCode: SecScreen.ISSUE,
        },
        {
            path: `${PrivatePaths.BASE_TASK_MANAGEMENT}/*`,
            component: BaseTaskPage,
            viewCode: SecScreen.BASE_TASK_MANAGEMENT,
        },
        {
            path: `${PrivatePaths.BASE_TASK_APPCC_MANAGEMENT}/*`,
            component: BaseTaskAPPCCPage,
            viewCode: SecScreen.BASE_TASK_MANAGEMENT,
        },
        {
            path: `${PrivatePaths.PLAN_APPCC}/*`,
            component: PlanAPPCCPage,
            viewCode: SecScreen.PLAN_APPCC,
        },
        {
            path: `${PrivatePaths.AUDIT_MANAGEMENT}/*`,
            component: AuditTemplatePage,
            viewCode: SecScreen.AUDIT_MANAGEMENT,
        },
        {
            path: `${PrivatePaths.AUDIT_INST_MANAG}/*`,
            component: AuditPage,
            viewCode: SecScreen.AUDIT_INST_MANAG,
        },
        {
            path: `${PrivatePaths.REGISTRY_MANAGEMENT}/*`,
            component: TaskAPPCCPage,
            viewCode: SecScreen.REGISTRY_MANAGEMENT,
        },
        {
            path: `${PrivatePaths.ASSETS}/*`,
            component: AssetPage,
            viewCode: SecScreen.ASSETS,
        },
        {
            path: `${PrivatePaths.ASSETS_RANGE}/*`,
            component: AssetRangePage,
            viewCode: SecScreen.ASSET_RANGE,
        },
        {
            path: `${PrivatePaths.CORR_MEASURE_MANAGER}/*`,
            component: CorrectiveMeasurePage,
            viewCode: SecScreen.CORR_MEASURE_MANAGER,
        },
        {
            path: `${PrivatePaths.BASE_AUDIT_GROUP_CHECKLIST_ITEM}/*`,
            component: BaseAuditGroupCheckListItemPage,
            viewCode: SecScreen.AUDIT_MANAGEMENT,
        },
        {
            path: `${PrivatePaths.AUTOMATIC_RECORDS}/*`,
            component: AutomaticRecordPage,
            viewCode: SecScreen.AUTOMATIC_RECORD,
        },
        {
            path: `${PrivatePaths.DISTRIBUTION_LIST}/*`,
            component: NotificationDistributionListPage,
            viewCode: SecScreen.DISTRIBUTION_LIST,
        },
        {
            path: `${PrivatePaths.PATCH_NOTES}/*`,
            component: PatchNotesPage,
            viewCode: SecScreen.LICENSES,
        },
        {
            path: `${PrivatePaths.DOCUMENTAL_MANAGER}/*`,
            component: DocumentalPage,
            viewCode: SecScreen.DOCUMENTAL_FOLDER,
        },
    ];

    useEffect(() => {
        const localStorageSession = getStoreSession();
        const pathname = location.pathname.substring(1);
        const hasWizardMode = WizardModeUrls.includes(pathname);
        dispatch(Actions.switchWizardMode(hasWizardMode));

        if (!isLoggedIn) return;
        if (session?.isActivationPending) {
            return nav(PublicPaths.NEW_PASSWORD);
        }
        const hasWorkingCompany = !!companies?.find(
            (company) => company.companyId === session?.workingCompany?.companyId
        );

        const shouldSetCompanyMode =
            !hasWorkingCompany ||
            (!session?.workingCompany &&
                session?.user.customerInstanceId !== null &&
                session?.user.companies?.length !== 0);

        if (!shouldSetCompanyMode || !session) return;
        const currentReduxSession: ReduxSession = {
            ...session,
            workingCompany: undefined,
            workingDepartment: undefined,
            workingSubDepartment: undefined,
        };

        const currentLocalStorageSession: LocalStorageSession = {
            workingCompany: undefined,
            workingDepartment: undefined,
            workingSubDepartment: undefined,
            isActivationPending: localStorageSession?.isActivationPending,
            refreshTokenExpiration: localStorageSession?.refreshTokenExpiration || "",
            tokenExpiration: localStorageSession?.tokenExpiration || "",
        };

        dispatch(Actions.setAppSession(currentReduxSession));
        setLocalStorageSession(currentLocalStorageSession);
        const qr = localStorage.getItem("qrCode");
        if (!qr && !isAdmin) return nav(`/${PrivatePaths.SELECT_DEPARTMENT}`);
    }, [isLoggedIn, location.pathname]);

    return (
        <main
            className={`main_content${wizardMode || hideFooter ? "--removePadding" : ""} ${
                isiOS() ? "main_content--mobile" : ""
            }`}
        >
            <Routes>
                {
                    // FIXME: Treure la ruta al acabar la formació de PortAventura
                    !isOnBoarding && <Route path={`${PrivatePaths.ON_BOARDING}/*`} element={<OnBoardingSteps />} />
                }
                <Route
                    path={PublicPaths.WILDCARD}
                    element={
                        <Navigate
                            to={
                                session?.user.customerInstanceId
                                    ? PrivatePaths.DASHBOARD
                                    : `${PrivatePaths.LICENSE_PAGE}/*`
                            }
                            replace
                        />
                    }
                />
                {routes.map(({ component, path, viewCode }) => (
                    <Route
                        key={path}
                        path={`${path}`}
                        element={<PrivateRoute component={component} viewCode={viewCode} />}
                    />
                ))}
                {!!session?.user.isActivationPending && (
                    <Route path={`${PublicPaths.NEW_PASSWORD}/*`} element={<ResetPasswordPage isNewPassword />} />
                )}
                <Route path={PrivatePaths.DESIGN_SYS} element={<DesignSysComponent />} />

                {!instanceId ? (
                    <Route path={`${PrivatePaths.LICENSE_PAGE}/*`} element={<LicensePage />} />
                ) : (
                    session?.user.companies?.length !== 0 && (
                        <Route path={PrivatePaths.SELECT_DEPARTMENT} element={<SelectDepartmentWizard />} />
                    )
                )}

                <Route path={`${PrivatePaths.DASHBOARD}/*`} element={<DashboardPage />} />
                <Route path={`${PrivatePaths.FAQS_PAGE}/*`} element={<FAQPage />} />

                <Route path={PrivatePaths.NOTIFICATION} element={<NotificationPage />} />
            </Routes>
        </main>
    );
};

export default ConfigApp;
