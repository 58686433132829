import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IssueAssetValues } from "app/state/Providers/types";
import { TranslationKeys } from "app/translation/translationKeys";
import { IssueAssetStepSubtitle } from "../IssueAssetStepSubtitle/IssueAssetStepSubtitle";
import { getAssetTaskfieldMinMaxPlaceholder } from "app/helpers/Asset/getAssetTaskfieldMinMaxPlaceholder";

type IssueAssetStepHeaderProps = {
    values: IssueAssetValues;
};

export const IssueAssetStepHeader: FC<IssueAssetStepHeaderProps> = ({ values }) => {
    const { t } = useTranslation();
    const { max, min, unit, value, assetDynamicFieldLabel } = values;
    const minMaxPlaceholder = getAssetTaskfieldMinMaxPlaceholder({ max, measureUnit: unit, min, t });
    const originalTemperature = `${value} ${unit}`;

    const subtitle = t(TranslationKeys.ISSUE_ASSET_STEP_SUBTITLE)
        .replace("{0}", assetDynamicFieldLabel)
        .replace("{1}", originalTemperature)
        .replace("{2}", `(${minMaxPlaceholder})`);

    return <IssueAssetStepSubtitle>{subtitle}</IssueAssetStepSubtitle>;
};
