import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { AuditInstanceModel } from "app/models/05-QUA/AuditInstanceModels/AuditInstanceModels";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import AuditInstanceService from "app/services/05-QUA/AuditInstanceService";
import { AUDIT_INSTANCE_STATUS } from "app/shared/Auditconstants";
import { PrivatePaths, SecScreen } from "app/shared/Constants";
import { AuditInstanceStatus } from "app/shared/types/AuditInstanceTypes";
import { IssueTranslation, TranslationErrors, TranslationKeys } from "app/translation/translationKeys";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";

type UseAuditStartParams = { auditInstanceId: number; actualStatus: AuditInstanceStatus };

export const useAuditStart = ({ auditInstanceId, actualStatus }: UseAuditStartParams) => {
    const nav = useNavigate();
    const { t } = useTranslation();
    const { getErrorMessage } = useFetchErrors();
    const { handleToast, removeToast } = useToast();
    const session = useSession();

    const [auditStartData, setAuditStartData] = useState<AuditInstanceModel | null>(null);
    const [isAuditStartLoading, setAuditStartIsLoading] = useState<boolean>(true);
    const [isAuditStartSubmiting, setAuditStartIsSubmiting] = useState<boolean>(false);
    const [isAuditStartConfirmModalOpen, setAuditStartIsConfirmModalOpen] = useState<boolean>(false);

    const fetchAuditStartData = async () => {
        if (!auditInstanceId) {
            nav(`/${PrivatePaths.AUDIT_INST_MANAG}`);
            return;
        }

        setAuditStartIsLoading(true);

        const extraParams = `status=${actualStatus}`;

        const { data, status } = await AuditInstanceService.GetOne(Number(auditInstanceId), { extraParams });

        if (!status()) {
            handleToast({ title: t(TranslationKeys.AUDIT_NOT_EXIST), variant: "danger", type: "alert" });
            nav(`/${PrivatePaths.AUDIT_INST_MANAG}`);
            return;
        }

        setAuditStartData(data);
        setAuditStartIsLoading(false);
    };

    const handleComplete = async () => {
        setAuditStartIsSubmiting(true);
        const toastId = v4();
        handleToast({
            title: t(IssueTranslation.CREATING_AUDIT_TITLE_TOAST),
            subtitle: t(IssueTranslation.CREATING_AUDIT_DESCRIPTION_TOAST),
            id: toastId,
            variant: "primary",
            type: "alert",
            isLoading: true,
        });

        const { status, getParsedError } = await AuditInstanceService.CompleteAuditInstance(Number(auditInstanceId));

        if (!status()) {
            setAuditStartIsSubmiting(false);
            removeToast(toastId);

            const errorMessage = getErrorMessage(getParsedError());
            if (errorMessage.includes(TranslationErrors.INVALID_EMAIL_ADDRESSESS)) {
                handleToast({
                    title: t(TranslationErrors.INVALID_EMAIL_ADDRESSESS_TITLE),
                    subtitle: t(TranslationErrors.INVALID_EMAIL_ADDRESSESS_SUBTITLE).replace(
                        "{0}",
                        errorMessage.split(" ")[0].replace(";", ", ")
                    ),
                    variant: "warning",
                    type: "alert",
                });
                redirectOncompleteAudit();
                return;
            } else
                handleToast({
                    title: t(TranslationKeys.AUDIT_FAILED_COMPLETE),
                    variant: "danger",
                    type: "alert",
                });
            return;
        }

        setAuditStartIsSubmiting(false);
        removeToast(toastId);
        handleToast({
            title: t(TranslationKeys.AUDIT_SUCCESS_COMPLETE),
            variant: "success",
            type: "alert",
        });
        redirectOncompleteAudit();
    };

    const redirectOncompleteAudit = () => {
        if (hasPermissionToView(session, SecScreen.AUDIT_INST_REPORT)) {
            nav(`/${PrivatePaths.AUDIT_INST_MANAG}/${auditInstanceId}/completed?tab=2`);
            return;
        }

        nav(`/${PrivatePaths.AUDIT_INST_MANAG}?audit_status=${AUDIT_INSTANCE_STATUS.REVIEWED}`);
    };

    const confirmAuditStartModalSubmit = () => {
        handleComplete();
        setAuditStartIsConfirmModalOpen(false);
    };

    const handleAuditStartConfirmModalOpen = () => {
        if (!auditStartData) return;
        if (!auditInstanceId) return;
        const isAllAuditGroupCompleted = auditStartData.auditGroupInstances.every((ag) =>
            ag.auditGroupCheckListInstances.every((agcl) => agcl.status === "COMPLETED")
        );
        if (!isAllAuditGroupCompleted) {
            handleToast({
                title: t(TranslationKeys.AUDIT_ALL_CHECKLIST_REQUIRED),
                variant: "danger",
                type: "alert",
            });
            return;
        }
        setAuditStartIsConfirmModalOpen(true);
    };

    return {
        isAuditStartSubmiting,
        isAuditStartConfirmModalOpen,
        setAuditStartIsConfirmModalOpen,
        confirmAuditStartModalSubmit,
        handleAuditStartConfirmModalOpen,
        isAuditStartLoading,
        fetchAuditStartData,
        auditStartData,
        setAuditStartData,
    };
};
