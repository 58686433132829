import { FC, FormEvent } from "react";
import { Input } from "app/components_v2/__inputs";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { ExportCsvModalProps } from "./types";
import { useExportCsvModal } from "./hooks/useExportCsvModal";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { GenericModalFooter } from "../base/GenericModal/GenericModalFooter/GenericModalFooter";
import { formatBigNumber } from "app/helpers/Utilities/number/formatBigNumbers";

export const ExportCsvModal: FC<ExportCsvModalProps> = ({
    onClose,
    onConfirm,
    title,
    pageIndex,
    total,
    variant = "primary",
    max,
}) => {
    const { t } = useTranslation();
    const { actualPage, allRegistres, errorInputValues, handleInputChange, rows, startPage, handleDownloadCsv } =
        useExportCsvModal({ pageIndex, total, max });

    const handleClickButton = () => {
        const extraParams = handleDownloadCsv();
        if (extraParams) onConfirm(extraParams);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleClickButton();
    };

    return (
        <GenericModal
            header={{ title, onClose, variant }}
            size="sm"
            footer={
                <GenericModalFooter
                    confirmButton={{
                        text: t(TranslationKeys.CSV_DOWNLOAD),
                        fullWidth: true,
                        form: "exportCsv",
                        type: "submit",
                        variant,
                    }}
                    closeButton={{
                        text: t(TranslationCommon.CANCEL),
                        buttonType: "tertiary",
                        onClick: onClose,
                        fullWidth: true,
                        variant,
                    }}
                />
            }
        >
            <form onSubmit={handleSubmit} className="exportCsv" id="exportCsv">
                <div className="exportCsv__content">
                    <div className="exportCsv__content__body">
                        <h2 className="exportCsv__content__page__subtitle">{t(TranslationKeys.ROWS)}</h2>
                        <Input
                            onChange={(value) => {
                                const totalRows = !!max && Number(value) > max ? String(max) : value;
                                handleInputChange({ rows: totalRows });
                            }}
                            label={t(TranslationKeys.ROW_AMOUNT)}
                            value={rows}
                            disabled={allRegistres}
                            placeholder={t(TranslationKeys.ROW_AMOUNT)}
                            type="number"
                            errorMessage={errorInputValues.rows}
                        />
                        <CheckBox
                            onChange={(value) => handleInputChange({ allRegistres: value })}
                            checked={allRegistres}
                            label={t(TranslationKeys.EXPORT_ALL)}
                            htmlFor={t(TranslationKeys.ROW_AMOUNT)}
                        />
                        {!!max && (
                            <p className="exportCsv__maxRows">
                                {t(TranslationKeys.MAX_CSV_ROWS).replace("{0}", formatBigNumber(max, 0))}
                            </p>
                        )}
                    </div>
                    <div className="exportCsv__content__page">
                        <h2 className="exportCsv__content__page__subtitle">{t(TranslationKeys.ROW_PAGES)}</h2>
                        <div className="exportCsv__content__page__pageNumber">
                            <Input
                                onChange={(value) => handleInputChange({ startPage: value })}
                                label={t(TranslationKeys.ROW_FROM_THE_PAGE)}
                                type="number"
                                value={startPage}
                                disabled={actualPage || allRegistres}
                                placeholder={t(TranslationKeys.ROW_FROM_THE_PAGE)}
                                errorMessage={errorInputValues.startPage}
                            />
                        </div>

                        <CheckBox
                            onChange={(value) => handleInputChange({ actualPage: value })}
                            checked={actualPage}
                            label={t(TranslationKeys.FROM_CURRENT_PAGE)}
                            disabled={allRegistres}
                            htmlFor={t(TranslationKeys.FROM_CURRENT_PAGE)}
                        />
                    </div>
                </div>
            </form>
        </GenericModal>
    );
};
