import { faChevronDown, faSearch, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabHeader } from "app/components_v2/__containers/TabHeader/TabHeader";
import { Input } from "app/components_v2/__inputs";
import {
    DocumentalFolderTranslations,
    TranslationCommon,
    TranslationKeys,
    TranslationTitles,
} from "app/translation/translationKeys";
import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentalFolderSortField } from "../../models/DocumentalFolderPageModel";
import { Popover } from "app/components_v2/Popover/Popover";
import { DropdownList } from "app/components_v2/Dropdown/DropdownList/DropdownList";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { useOnClickOutside } from "usehooks-ts";

type DocumentalFolderPageHeaderProps = {
    query: string;
    sortField: DocumentalFolderSortField;
    sortDirection: SortedTypeModel;
    onChangeQuery: (query: string) => void;
    onSearch: (q: string) => void;
    onChangeOrderBy: (sortF: DocumentalFolderSortField, sortD: SortedTypeModel) => void;
};

export const DocumentalFolderPageHeader: FC<DocumentalFolderPageHeaderProps> = ({
    onChangeQuery,
    onSearch,
    query,
    onChangeOrderBy,
    sortField,
    sortDirection,
}) => {
    const { t } = useTranslation();
    const [isOrderByModalOpen, setIsOrderByModalOpen] = useState<boolean>(false);
    const modalRef = useRef<HTMLDivElement | null>(null);

    useOnClickOutside(modalRef, () => {
        if (isOrderByModalOpen) setIsOrderByModalOpen(false);
    });

    const getText = (sortF: DocumentalFolderSortField, sortD: SortedTypeModel) => {
        if (sortF === "CreatedDate")
            return sortD === "asc"
                ? t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_ORDER_BY_CREATED_DATE_ASC)
                : t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_ORDER_BY_CREATED_DATE_DESC);
        if (sortF === "Name")
            return sortD === "asc"
                ? t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_ORDER_BY_NAME_ASC)
                : t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_ORDER_BY_NAME_DESC);

        return t(DocumentalFolderTranslations.DOCUMENTAL_FOLDER_ORDER_BY_CREATED_DATE_ASC);
    };

    const handleChangeOrderBy = (sortF: DocumentalFolderSortField, sortD: SortedTypeModel) => {
        onChangeOrderBy(sortF, sortD);
        setIsOrderByModalOpen(false);
    };

    const getDropdownItems = () => {
        const items = [
            {
                text: getText("Name", "asc"),
                onClick: () => handleChangeOrderBy("Name", "asc"),
                active: sortField === "Name" && sortDirection === "asc",
            },
            {
                text: getText("Name", "desc"),
                onClick: () => handleChangeOrderBy("Name", "desc"),
                active: sortField === "Name" && sortDirection === "desc",
            },
            {
                text: getText("CreatedDate", "asc"),
                onClick: () => handleChangeOrderBy("CreatedDate", "asc"),
                active: sortField === "CreatedDate" && sortDirection === "asc",
            },
            {
                text: getText("CreatedDate", "desc"),
                onClick: () => handleChangeOrderBy("CreatedDate", "desc"),
                active: sortField === "CreatedDate" && sortDirection === "desc",
            },
        ];
        return items;
    };

    return (
        <>
            <TabHeader paddingSize="sm" classNameToScrollTop=".customContainer" variant="primary">
                <div className="documentalFolderPageHeader">
                    <div className="documentalFolderPageHeader__header">
                        <h1 className="documentalFolderPageHeader__title">{t(TranslationTitles.DOCUMENTAL_MANAGER)}</h1>
                        <div className="documentalFolderPageHeader__search">
                            <Input
                                value={query}
                                onChange={onChangeQuery}
                                placeholder={t(TranslationCommon.SEARCH)}
                                iconRight={!!query.length ? faXmark : faSearch}
                                whiteInput
                                transparent
                                onPressEnter={() => onSearch(query)}
                                onClickIcon={() => onSearch("")}
                            />
                        </div>
                    </div>
                    <div className="documentalFolderPageHeader__filters" ref={modalRef}>
                        {isOrderByModalOpen && (
                            <Popover
                                target={modalRef.current}
                                position="bottomLeft"
                                onResize={() => setIsOrderByModalOpen(false)}
                                onScroll={() => setIsOrderByModalOpen(false)}
                                scrollTarget={document.querySelector(".customContainer")}
                                portal={false}
                            >
                                <DropdownList items={getDropdownItems()} />
                            </Popover>
                        )}
                        <div
                            className="documentalFolderPageHeader__orderBy"
                            onClick={() => setIsOrderByModalOpen((prev) => !prev)}
                        >
                            <p className="documentalFolderPageHeader__orderBy__text">
                                {t(TranslationKeys.ORDER_BY_STARS)}
                            </p>
                            <div className="documentalFolderPageHeader__orderBy__container">
                                <p className="documentalFolderPageHeader__orderBy__text">
                                    {getText(sortField, sortDirection)}
                                </p>
                                <FontAwesomeIcon icon={faChevronDown} className="feedback__order__icon" />
                            </div>
                        </div>
                    </div>
                </div>
            </TabHeader>
        </>
    );
};
