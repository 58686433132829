import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useSession } from "app/hooks";
import { useLogOut } from "app/hooks/useLogout";
import {
    faArrowRightFromBracket,
    faClipboardQuestion,
    faEnvelope,
    faFolder,
    faQrcode,
    faTemperatureList,
    faTriangleExclamation,
    faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { TranslationCommon, TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { IssueTypeCode, PrivatePaths, SecScreen } from "app/shared/Constants";
import { IssueModalContext } from "app/state/context/issueModalContext/issueModalContext";
import { IDropDownListItem } from "app/components_v2/Dropdown/types";
import { Popover, PopoverPosition } from "app/components_v2/Popover/Popover";
import { Avatar } from "app/components_v2/Avatar/Avatar";
import { DropdownList } from "app/components_v2/Dropdown/DropdownList/DropdownList";
import { DropdownListItem } from "app/components_v2/Dropdown/DropdownListItem/DropdownListItem";
import { FullScreenQrModal } from "app/components_v2/__modals/FullScreenQrModal/FullScreenQrModal";
import { useSensorQr } from "app/hooks/Sensor/useSensorQr";
import { SensorNotLinkedModal } from "app/components_v2/__modals/SensorModal/SensorNotLinkedModal/SensorNotLinkedModal";
import { SensorLinkedModal } from "app/components_v2/__modals/SensorModal/SensorLinkedModal/SensorLinkedModal";

type AvatarPopoverProps = {
    position: PopoverPosition;
    showBlur?: boolean;
};

export const AvatarPopover: FC<AvatarPopoverProps> = ({ position, showBlur }) => {
    const session = useSession();
    const { t } = useTranslation();
    const location = useLocation();
    const nav = useNavigate();
    const onLogout = useLogOut();
    const { open } = useContext(IssueModalContext);

    const [isPopoverVisble, setIsPopoverVisble] = useState<boolean>(false);
    const [avatarElement, setAvatarElement] = useState<Element | null>(null);
    const [showQrTaskScan, setShowQrTaskScan] = useState<boolean>(false);
    const [showQrSensorScan, setShowQrSensorScan] = useState<boolean>(false);
    const [showNotLinkedSensorModal, setShowNotLinkedSensorModal] = useState<boolean>(false);
    const [showLinkedSensorModal, setShowLinkedSensorModal] = useState<boolean>(false);
    const [qrCode, setQrCode] = useState<string | null>(null);

    const { getAsset, asset } = useSensorQr({
        onError: () => setShowQrSensorScan(false),
        onSuccess: (isLinked) => {
            isLinked ? setShowLinkedSensorModal(true) : setShowNotLinkedSensorModal(true);
            setShowQrSensorScan(false);
        },
    });

    const hasPermissionsToViewSensors = hasPermissionToView(session, SecScreen.AUTOMATIC_RECORD);
    const userName = `${session?.user.firstName} ${session?.user.lastName}`;
    const image = session?.user.profilePictureURL;
    const userId = session?.user.id;
    const isOnBoarding = session?.isOnBoarding;
    const isCustomerInstanceNull = session?.user.customerInstanceId == null;

    const dropdownListItems: IDropDownListItem[] = [
        {
            text: t(TranslationKeys.MY_DATA),
            active: location.pathname.includes(PrivatePaths.MY_PROFILE),
            icon: faUser,
            onClick: () => onClickItem(PrivatePaths.MY_PROFILE),
        },
        {
            text: t(TranslationKeys.MY_ALERTS),
            active: location.pathname.includes(PrivatePaths.ALERTS),
            icon: faEnvelope,
            onClick: () => onClickItem(PrivatePaths.ALERTS),
            hidden: isOnBoarding || !hasPermissionToView(session, SecScreen.ALERTS) || isCustomerInstanceNull,
        },
        {
            text: t(TranslationKeys.OPEN_ISSUE),
            icon: faTriangleExclamation,
            onClick: () => onOpenIssue(),
            hidden:
                !hasPermissionToView(session, SecScreen.OPEN_MANUAL_ISSUE) || isOnBoarding || isCustomerInstanceNull,
        },
        {
            text: t(TranslationTitles.SCAN_QR_TASK_TITLE),
            icon: faQrcode,
            onClick: () => setShowQrTaskScan(true),
            hidden: isOnBoarding || isCustomerInstanceNull,
        },
        {
            text: t(TranslationTitles.DOCUMENTAL_MANAGER),
            active: location.pathname.includes(PrivatePaths.DOCUMENTAL_MANAGER),
            icon: faFolder,
            onClick: () => onClickItem(PrivatePaths.DOCUMENTAL_MANAGER),
            hidden:
                isOnBoarding || isCustomerInstanceNull || !hasPermissionToView(session, SecScreen.DOCUMENTAL_FOLDER),
        },
        {
            text: t(TranslationTitles.SCAN_QR_SENSOR_TITLE),
            icon: faTemperatureList,
            onClick: () => setShowQrSensorScan(true),
            hidden: isOnBoarding || isCustomerInstanceNull || !hasPermissionsToViewSensors,
        },
    ];

    const logOut: IDropDownListItem = {
        text: t(TranslationCommon.LOGOUT),
        icon: faArrowRightFromBracket,
        onClick: onLogout,
    };

    const onClickItem = (path: string) => {
        nav(`/${path}`);
        setIsPopoverVisble(false);
    };

    const onOpenIssue = () => {
        open({ issueType: IssueTypeCode.GENERIC, issueServiceType: "MANUAL" });
        setIsPopoverVisble(false);
    };

    const onScanQr = (qrCode: string) => {
        setShowQrTaskScan(false);
        nav(`/${PrivatePaths.DASHBOARD}/qr/${qrCode}`);
    };

    const onScanSensorQr = async (qrCode: string) => {
        setQrCode(qrCode);
        await getAsset(qrCode);
    };

    return (
        <>
            <div className="headerItemRight" ref={setAvatarElement} onClick={() => setIsPopoverVisble(true)}>
                <Avatar avatarName={userName || ""} colorId={userId || 0} img={image} size="sm" />
            </div>
            {isPopoverVisble && (
                <Popover
                    target={avatarElement}
                    position={position}
                    onClickOutside={() => setIsPopoverVisble(false)}
                    onResize={() => setIsPopoverVisble(false)}
                    showBlur={showBlur}
                    separation={4}
                >
                    <div className="avatarPopover__list">
                        <DropdownList items={dropdownListItems} />
                        <div className="avatarPopover__list__lastItem">
                            <DropdownListItem {...logOut} />
                        </div>
                    </div>
                </Popover>
            )}
            {showQrTaskScan && <FullScreenQrModal onClose={() => setShowQrTaskScan(false)} onScanQr={onScanQr} />}
            {showQrSensorScan && (
                <FullScreenQrModal onClose={() => setShowQrSensorScan(false)} onScanQr={onScanSensorQr} type="SENSOR" />
            )}
            {showNotLinkedSensorModal && !!qrCode?.length && (
                <SensorNotLinkedModal sensorSerialNumber={qrCode} onClose={() => setShowNotLinkedSensorModal(false)} />
            )}
            {showLinkedSensorModal && !!asset && (
                <SensorLinkedModal asset={asset} onClose={() => setShowLinkedSensorModal(false)} />
            )}
        </>
    );
};
