import { ActionButtonsModel } from "app/components_v2/Table/TableTabHeader/TableTabHeader";
import { Badge } from "app/components_v2/Badge/Badge";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { ColumnsType } from "app/components_v2/Table/types";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { NotificationDistributionListModel } from "app/models/01-SEG/NotificationDistributionList/NotificationDistributionListModel";
import {
    NotificationDistributionListTranslations,
    TranslationCommon,
    TranslationKeys,
    TranslationTitles,
} from "app/translation/translationKeys";
import {
    faBellPlus,
    faBellRing,
    faEnvelope,
    faSliders,
    faTemperatureList,
    faTrash,
    faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { FC } from "react";
import { hasPermissionToAdd, hasPermissionToDelete, hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { MobileNotificationDistributionListRow } from "./components/MobileNotificationDistributionListRow/MobileNotificationDistributionListRow";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { PaginationDefaults, SecScreen } from "app/shared/Constants";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { TableSelectCompany } from "app/components_v2/Table/TableSelectCompany/TableSelectCompany";
import { useNotificationDistributionList } from "./hooks/useNotificationDistributionList";
import { useTitle } from "app/hooks";
import { useTranslation } from "react-i18next";
import { CreateNotificationDistributionListUsersModal } from "./components/CreateNotificationDistributionListUsersModal/CreateNotificationDistributionListUsersModal";
import { EditCreateNotificationDistributionListUsersModal } from "./components/EditNotificationDistributionListUsersModal/EditNotificationDistributionListUsersModal";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { NotificationDistributionListFilter } from "app/components_v2/__filters/NotificationDistributionListFilter/NotificationDistributionListFilter";
import { INITIAL_NOTIFICATION_DISTRIBUTION_LIST_SF } from "./constants/NotificationDistributionListConstants";
import { isEqual } from "lodash";

export const NotificationDistributionListGrid: FC = () => {
    const { t } = useTranslation();
    const { translateCompany, translateCustomerTypeKeys } = useCustomerTypeTranslation();

    useTitle(t(TranslationTitles.EMAIL_DISTRIBUTION_LIST_TITLE));

    const {
        currentNotificationDistributionList,
        filterIcon,
        handleSaveSF,
        handleSubmit,
        isLoading,
        isSecondaryFilterOpened,
        notificationDistributionList,
        onAddNew,
        onChangeCompany,
        onChangePageIndex,
        onChangeShowCreateModal,
        onChangeShowDeleteModal,
        onChangeShowEditModal,
        onChangeSortDirectionField,
        onClickDelete,
        onClickEdit,
        onClickFilterIcon,
        onClickOutsideCompany,
        onDelete,
        onOpenSecondaryFilter,
        pageIndex,
        selectCompany,
        sfValues,
        showCreateModal,
        showDeleteModal,
        showEditModal,
        sortDirection,
        sortField,
        total,
        confirmModalIsLoading,
    } = useNotificationDistributionList();

    const columns: ColumnsType<NotificationDistributionListModel>[] = [
        {
            dataIndex: "Functionality",
            label: t(NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_FUNCTIONALITY_LABEL),
            // sortedType: "default",
            render: ({ functionality }) => <CellTitle title={t(functionality)} />,
        },
        {
            dataIndex: "FK_Company",
            alignCenter: true,
            label: translateCompany("SINGULAR"),
            sortedType: "default",
            render: ({ companyName }) => (
                <div className="notificationDistributionList__center">
                    <CellTitle
                        title={
                            companyName ||
                            translateCustomerTypeKeys(TranslationKeys.ALL_COMPANIES, {
                                firstReplace: "PLURAL",
                                isGenderSensitive: true,
                            })
                        }
                    />
                </div>
            ),
        },
        {
            dataIndex: "Users",
            alignCenter: true,
            label: t(NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_USER_COUNT_LABEL),
            render: ({ userCount }) => (
                <div className="notificationDistributionList__center">
                    <Badge title={String(userCount)} variant="blue" icon={faUser} iconPosition="right" />
                </div>
            ),
        },
        {
            dataIndex: "Emails",
            alignCenter: true,
            label: t(NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_EMAIL_COUNT_LABEL),
            render: ({ emails }) => (
                <div className="notificationDistributionList__center">
                    <Badge
                        title={String(emails?.emails.split(";").length || 0)}
                        variant="blue"
                        icon={faEnvelope}
                        iconPosition="right"
                    />
                </div>
            ),
        },
        {
            dataIndex: "actions",
            className: "notificationDistributionList__actions",
            alignCenter: true,
            label: t(TranslationKeys.ACTIONS),
            render: (record) => (
                <CellIcons
                    icons={[
                        {
                            icon: faBellRing,
                            onClick: () => onClickEdit(record),
                            hidden: !hasPermissionToEdit(SecScreen.DISTRIBUTION_LIST),
                        },
                        {
                            icon: faTrash,
                            onClick: () => onClickDelete(record),
                            hidden: !hasPermissionToDelete(SecScreen.DISTRIBUTION_LIST),
                        },
                    ]}
                />
            ),
            hidden:
                !hasPermissionToEdit(SecScreen.DISTRIBUTION_LIST) &&
                !hasPermissionToDelete(SecScreen.DISTRIBUTION_LIST),
        },
    ];

    const actionButtons: ActionButtonsModel[] = [
        {
            icon: faBellPlus,
            onClick: onAddNew,
            hidden: !hasPermissionToAdd(SecScreen.DISTRIBUTION_LIST),
        },
        {
            icon: filterIcon,
            onClick: onClickFilterIcon,
            inputComponent: {
                component: (
                    <TableSelectCompany
                        onChange={onChangeCompany}
                        value={selectCompany.company}
                        onClickOutside={onClickOutsideCompany}
                    />
                ),
                show: selectCompany.isOpen,
            },
        },
        {
            icon: faSliders,
            onClick: () => onOpenSecondaryFilter(true),
            showMarkableIcon: !isEqual(sfValues, INITIAL_NOTIFICATION_DISTRIBUTION_LIST_SF),
        },
    ];

    return (
        <>
            {showCreateModal && (
                <CreateNotificationDistributionListUsersModal
                    title={t(
                        NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_CREATE_USERS_MODAL_TITLE
                    )}
                    onClose={() => onChangeShowCreateModal(false)}
                    onSubmit={(values) => handleSubmit(values, "create")}
                />
            )}
            {showEditModal && notificationDistributionList && (
                <EditCreateNotificationDistributionListUsersModal
                    notificationDistributionList={currentNotificationDistributionList}
                    modalTitle={t(
                        NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_EDIT_USERS_MODAL_TITLE
                    )}
                    onClose={() => onChangeShowEditModal(false)}
                    onSubmit={(values) => handleSubmit(values, "edit")}
                />
            )}
            {showDeleteModal && (
                <ConfirmModal
                    title={t(
                        NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_DELETE_TITLE_LABEL
                    )}
                    description={t(
                        NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_DELETE_DESCRIPTION_LABEL
                    )}
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onConfirm={onDelete}
                    onClose={() => onChangeShowDeleteModal(false)}
                    type="delete"
                    isLoading={confirmModalIsLoading}
                />
            )}

            <PageContainer paddingTop>
                <NotificationDistributionListFilter
                    filterData={sfValues}
                    initialFilterData={INITIAL_NOTIFICATION_DISTRIBUTION_LIST_SF}
                    isOpen={isSecondaryFilterOpened}
                    isSecondaryFilterOpen={isSecondaryFilterOpened}
                    onCancel={() => onOpenSecondaryFilter(false)}
                    onSave={handleSaveSF}
                />
                <TableCollapsable
                    title={`${t(TranslationTitles.EMAIL_DISTRIBUTION_LIST_TITLE)} ${
                        selectCompany.company.value === "-1"
                            ? `(${translateCustomerTypeKeys(TranslationKeys.ALL_COMPANIES, {
                                  firstReplace: "PLURAL",
                              })})`
                            : ""
                    }`}
                    cols={columns}
                    data={notificationDistributionList}
                    total={total}
                    placeholder={<TableError icon={faTemperatureList} />}
                    isLoading={isLoading}
                    pageIndex={pageIndex}
                    pageSize={PaginationDefaults.PAGE_SIZE}
                    onDoubleClick={onClickEdit}
                    onChangePageIndex={onChangePageIndex}
                    onChangeSortDirectionField={onChangeSortDirectionField}
                    sortDirection={sortDirection}
                    sortField={sortField}
                    actionButtons={actionButtons}
                    mobileBody={(row) => (
                        <MobileNotificationDistributionListRow
                            data={row}
                            onClickEdit={onClickEdit}
                            onClickDelete={onClickDelete}
                        />
                    )}
                />
            </PageContainer>
        </>
    );
};
