import { useToast } from "app/hooks/Toast/useToast";
import { TaskInstanceCheckListModel } from "app/models/02-TAR/TaskInstance/TaskInstanceCheckListModel";
import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";
import { TaskInstanceModel } from "app/models/02-TAR/TaskInstance/TaskInstanceModel";
import TaskInstanceService from "app/services/02-TAR/TaskInstanceService";
import { IssueAssetsModalContext } from "app/state/context/issueAssetsModalContext/issueAssetsModalContext";
import {
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import { MutableRefObject, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetchErrors } from "./useFetchErrors";
import { validateTaskDetailDynamicFields } from "app/components_v2/TaskDetailDynamicFields/TaskDetailDynamicFieldsValidation";
import { ImageDragFile } from "app/components_v2/__draggableFiles/DragFile/types";
import { MAX_ASSET_OUT_OF_RANGE_ISSUES } from "app/pages/05-QUA/Asset/constants/assetConstants";
import { UpdateTaskInstanceFieldDto } from "app/dtos/02-TAR/TaskInstanceField/UpdateTaskInstanceFieldDto";
import { hasFieldsAndAtLeastOneIsEmpty } from "app/helpers/TaskInstance/hasFieldsAndAtLeastOneIsEmpty";
import TaskInstanceFieldService from "app/services/02-TAR/TaskinstanceFieldService";
import { getDateFormattedSelects } from "app/helpers/Utilities/date/Date.utilities";

export const useCompleteTaskInstance = (
    setTaskInstance?: (value: TaskInstanceModel) => void,
    onComplete?: () => void,
    setIsConfirmModalOpenEmtpyOptionals?: (value: boolean) => void,
    onLoading?: (value: boolean) => void
) => {
    const { open: openIssueAssetModal } = useContext(IssueAssetsModalContext);
    const { handleToast } = useToast();

    const [imageCustomError, setImageCustomError] = useState<string>("");
    const [customErrors, setCustomErrors] = useState<Map<number | string, string>>();
    const [firstError, setFirstError] = useState<string | null>(null);
    const [errorFound, setErrorFound] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [checkListModalOpen, setcheckListModalOpen] = useState<boolean>(false);
    const { getErrorMessage } = useFetchErrors();
    const [dynamicFieldsErrors, setDynamicFieldsErrors] = useState<number[]>([]);
    const [temperatureOutOfRangeModalIsOpen, setTemperatureOutOfRangeModalIsOpen] = useState<boolean>(false);
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [isUpdateTaskInstanceFieldsModalVisible, setIsUpdateTaskInstanceFieldsModalVisible] =
        useState<boolean>(false);

    const { t } = useTranslation();

    const completeTaskInstance = async (
        taskInstance: TaskInstanceModel | undefined,
        fields: TaskInstanceFieldModel[]
    ) => {
        if (!taskInstance) return;

        setLoading(true);
        onLoading && onLoading(true);
        const { status, getParsedError } = await TaskInstanceService.CompleteTask(
            {
                id: taskInstance.id,
                taskInstanceFields2: fields?.map((field) => ({
                    ...field,
                    taskInstanceFieldValues: field.taskInstanceFieldValues
                        .filter((tifv) => (tifv.fK_Asset != null && tifv.value == "true") || tifv.fK_Asset == null)
                        .map((tifv) => ({
                            ...tifv,
                            id: tifv.fK_Asset == null ? tifv.id : 0,
                            value:
                                field.dynamicFieldType === "DATE"
                                    ? getDateFormattedSelects(new Date(tifv.value))
                                    : tifv.value,
                        })),
                    asset: undefined,
                    asset2: undefined,
                    assetFieldRange: undefined,
                })),
            },
            taskInstance.id
        );

        if (!status()) {
            const errorMessage = getErrorMessage(getParsedError());
            if (errorMessage.includes(TranslationErrors.INVALID_EMAIL_ADDRESSESS))
                handleToast({
                    title: t(TranslationErrors.INVALID_EMAIL_ADDRESSESS_TITLE),
                    subtitle: t(TranslationErrors.INVALID_EMAIL_ADDRESSESS_SUBTITLE).replace(
                        "{0}",
                        errorMessage.split(" ")[0].replace(";", ", ")
                    ),
                    variant: "warning",
                    type: "alert",
                    id: "1",
                });
            else {
                handleToast({
                    title: errorMessage,
                    variant: "danger",
                    type: "alert",
                });
                setLoading(false);
                onLoading && onLoading(false);
                onComplete && onComplete();
                return;
            }
        }

        const tasInstanceSr = await TaskInstanceService.GetOne(taskInstance.id);
        if (tasInstanceSr.status()) setTaskInstance && setTaskInstance(tasInstanceSr.data);
        handleToast({
            title: t(TranslationModals.TOAST_SUCCESS_TASK),
            variant: "success",
            type: "alert",
        });
        setLoading(false);
        onLoading && onLoading(false);
        onComplete && onComplete();
    };

    const handleTaskComplete = async (
        taskInstance: TaskInstanceModel | undefined,
        fields: TaskInstanceFieldModel[],
        images?: ImageDragFile[],
        taskCheckList?: TaskInstanceCheckListModel[],
        errorRefs?: MutableRefObject<HTMLDivElement[]>,
        e?: React.MouseEvent<HTMLElement>
    ) => {
        e?.preventDefault();
        let foundError: string | null = null;
        let focused = false;
        let validationCheck = true;
        let fieldsOutOfRange = false;
        const outOfRangeAssets: TaskInstanceFieldModel[] = [];
        const errors = new Map<number | string, string>();

        const inputsAllowedToHaveErrors: boolean[] = [];
        const fieldsToValidate =
            fields
                ?.filter(({ isHidden }) => !isHidden)
                .filter(({ isOperative }) => isOperative)
                .filter(({ isRequired }) => isRequired)
                .filter(
                    ({ asset2 }) =>
                        taskInstance?.reportType === undefined ||
                        taskInstance.reportType !== "ASSET" ||
                        (taskInstance.reportType && taskInstance.reportType === "ASSET" && !!asset2)
                ) || [];

        const fieldsWithAssets = fieldsToValidate.filter((field) => field.asset2 != null);
        const fieldsWithoutAssets = fieldsToValidate.filter((field) => field.asset2 == null);

        if (!!fieldsWithAssets.length) {
            fieldsToValidate?.forEach((field, i) => {
                if (!field.asset2) return;
                const taskFieldMax = field.taskInstanceFieldValues[0].max;
                const taskFieldMin = field.taskInstanceFieldValues[0].min;
                const taskFieldValue = field.taskInstanceFieldValues[0].value.replace(",", ".");

                inputsAllowedToHaveErrors[i] = true;
                if (field.asset2?.notWorking) {
                    inputsAllowedToHaveErrors[i] = false;
                    return;
                }

                if (taskFieldValue != null && !!taskFieldValue.length) {
                    if (taskInstance?.taskInstanceFields2?.some((field) => field.asset2 != null)) {
                        inputsAllowedToHaveErrors[i] = false;
                        if (
                            taskFieldMin != null &&
                            taskFieldMax != null &&
                            (taskFieldMin > Number(taskFieldValue) || taskFieldMax < Number(taskFieldValue))
                        ) {
                            const issueConsecutiveCount = field.asset2?.issueCount || 0;
                            if (issueConsecutiveCount >= MAX_ASSET_OUT_OF_RANGE_ISSUES - 1)
                                outOfRangeAssets.push(field);
                            fieldsOutOfRange = true;
                        }

                        if (taskFieldMin != null && taskFieldMax == null && taskFieldMin > Number(taskFieldValue)) {
                            const issueConsecutiveCount = field.asset2?.issueCount || 0;
                            if (issueConsecutiveCount >= MAX_ASSET_OUT_OF_RANGE_ISSUES - 1)
                                outOfRangeAssets.push(field);
                            fieldsOutOfRange = true;
                        }

                        if (taskFieldMin == null && taskFieldMax != null && taskFieldMax < Number(taskFieldValue)) {
                            const issueConsecutiveCount = field.asset2?.issueCount || 0;
                            if (issueConsecutiveCount >= MAX_ASSET_OUT_OF_RANGE_ISSUES - 1)
                                outOfRangeAssets.push(field);
                            fieldsOutOfRange = true;
                        }
                    } else errors?.set(field.id, "");
                } else {
                    validationCheck = false;
                    errors?.set(field.id, t(TranslationKeys.REPORTED_DATA_TASK));
                    if (!focused) {
                        const object = document.getElementById(field.id.toString());

                        if (field.dynamicFieldType === "DROPDOWN") object?.scrollIntoView();
                        else object?.focus();
                        focused = true;
                    }
                }
            });
        }

        if (!!fieldsWithoutAssets.length) {
            const { invalidFieldIds, hasEmptyOptionals } = validateTaskDetailDynamicFields(fieldsToValidate);
            const errorsDynamicFields = fieldsWithoutAssets ? invalidFieldIds : [];

            if (!invalidFieldIds.length && hasEmptyOptionals) {
                setIsConfirmModalOpenEmtpyOptionals && setIsConfirmModalOpenEmtpyOptionals(hasEmptyOptionals);
                return false;
            }
            setDynamicFieldsErrors(errorsDynamicFields);
            validationCheck = !errorsDynamicFields.length;
        }

        fieldsToValidate
            .filter(({ asset2, dynamicFieldType }) => asset2 && dynamicFieldType === "DROPDOWN")
            .forEach(({ taskInstanceFieldValues, id }) => {
                if (taskInstanceFieldValues.every(({ value }) => !value.length))
                    errors?.set(id, t(TranslationKeys.REPORTED_DATA_TASK));
            });

        setCustomErrors(errors);

        errorRefs &&
            errorRefs.current.forEach((ref, i) => {
                const input = ref.querySelector("input") as HTMLInputElement;
                if (input.value === "" && foundError == null && inputsAllowedToHaveErrors[i] === true) {
                    foundError = input.id;
                }
            });

        if (foundError) {
            setFirstError(foundError);
            setErrorFound((prev) => !prev);
            validationCheck = false;
        } else {
            setFirstError(null);
        }
        if (taskInstance?.isPhotoRequired && (images?.every((x) => x.isErrored) || !images?.length) && !foundError) {
            handleToast({
                title: t(TranslationModals.TOAST_WARNING_PHOTO_REQUIRED),
                variant: "danger",
                type: "alert",
            });
            setImageCustomError(t(TranslationCommon.INPUT_NOT_EMPTY));
            validationCheck = false;
        } else if (
            taskInstance?.isPhotoRequired &&
            !!taskInstance.minPhotosAllowed &&
            (images?.length || 1) < taskInstance.minPhotosAllowed
        ) {
            const errorMessage = t(TranslationModals.TOAST_PHOTO_MIN_PHOTOS_ALLOWED)
                .replace("{0}", String(taskInstance.minPhotosAllowed))
                .replace("{1}", String(images?.length || 1));
            handleToast({
                title: errorMessage,
                variant: "danger",
                type: "alert",
            });
            setImageCustomError(errorMessage);
            validationCheck = false;
        } else {
            setImageCustomError("");
        }
        if (fieldsOutOfRange && validationCheck && taskInstance?.id) {
            if (taskInstance?.generateAutomaticAssetIssue && !!outOfRangeAssets.length) {
                openIssueAssetModal({
                    taskinstanceId: taskInstance?.id,
                    assetsOutOfRange: outOfRangeAssets,
                    hasPlan: !!taskInstance.planId,
                });
            } else setTemperatureOutOfRangeModalIsOpen(true);
            return false;
        }

        if (!!taskCheckList?.length) {
            const hasEmptyChecklistItems = taskCheckList.some(({ isActive }) => isActive === null);
            if (hasEmptyChecklistItems && validationCheck) {
                setcheckListModalOpen(true);
                return false;
            }
        }

        if (validationCheck) await completeTaskInstance(taskInstance, fields);
        setIsSaved(validationCheck);
        return validationCheck;
    };

    const updateTaskInstanceFields = async (
        taskInstance: TaskInstanceModel | undefined,
        fields: TaskInstanceFieldModel[]
    ) => {
        if (!taskInstance) return;
        setLoading(true);
        onLoading && onLoading(true);
        const updateTaskInstanceFieldDto: UpdateTaskInstanceFieldDto[] = fields.map<UpdateTaskInstanceFieldDto>(
            ({ id, taskInstanceFieldValues, fK_Asset2, dynamicFieldType }) => {
                return {
                    id,
                    taskInstanceFieldValues: taskInstanceFieldValues.map((tifv) => ({
                        ...tifv,
                        id: tifv.fK_Asset == null || tifv.id !== -tifv.fK_Asset ? tifv.id : 0,
                        value: dynamicFieldType === "DATERANGE" && tifv.value.includes("undefined") ? "" : tifv.value,
                    })),
                    fK_Asset: fK_Asset2 || null,
                };
            }
        );

        const { status, getParsedError } = await TaskInstanceFieldService.UpdateByTaskInstaceIdAsync(taskInstance.id, {
            hasFieldsAndAtLeastOneIsEmpty: hasFieldsAndAtLeastOneIsEmpty(fields),
            taskInstanceFields: updateTaskInstanceFieldDto,
        });

        setLoading(false);
        onLoading && onLoading(false);
        onComplete && onComplete();

        if (!status()) {
            handleToast({
                title: getErrorMessage(getParsedError()),
                variant: "danger",
                type: "alert",
            });
            return;
        }
    };

    return {
        checkListModalOpen,
        completeTaskInstance,
        customErrors,
        dynamicFieldsErrors,
        errorFound,
        firstError,
        handleTaskComplete,
        imageCustomError,
        isSaved,
        loading,
        setcheckListModalOpen,
        setImageCustomError,
        setTemperatureOutOfRangeModalIsOpen,
        temperatureOutOfRangeModalIsOpen,
        updateTaskInstanceFields,
        isUpdateTaskInstanceFieldsModalVisible,
        setIsUpdateTaskInstanceFieldsModalVisible,
    };
};
