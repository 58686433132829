import { AuditBoxBody } from "./AuditBoxBody/AuditBoxBody";
import { AuditBoxHeader } from "./AuditBoxHeader/AuditBoxHeader";
import { AuditBoxModal } from "./AuditBoxModal/AuditBoxModal";
import { AuditGroupModel } from "app/models/05-QUA/AuditModels/AuditGroupModels";
import { AuditGroupTranslations, TranslationCommon, TranslationModals } from "app/translation/translationKeys";
import { AuditModel } from "app/models/05-QUA/AuditModels/AuditModels";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { faFileCertificate } from "@fortawesome/pro-regular-svg-icons";
import { FC, useEffect, useState } from "react";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useSuccessManager } from "app/hooks/SuccessHandler/useSuccessManager";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import AuditGroupService from "app/services/05-QUA/AuditGroupService";
import AuditService from "app/services/05-QUA/AuditService";

type AuditBoxProps = {
    audit: AuditModel;
    fetchAudits: () => Promise<void>;
};

export const AuditBox: FC<AuditBoxProps> = ({ audit, fetchAudits }) => {
    const { t } = useTranslation();
    const { getErrorMessage } = useFetchErrors();
    const { handleErrorManager } = useErrorManager();
    const { handleSuccessManager } = useSuccessManager();
    const { handleToast } = useToast();

    const [internalAudit, setInternalAudit] = useState<AuditModel>(audit);
    const [showSaveAuditGroupModal, setShowSaveAuditGroupModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);

    const fetchAuditGroup = async () => {
        const extraParams = new URLSearchParams();
        extraParams.append("AuditId", String(audit.id));
        const { data, status, getParsedError } = await AuditGroupService.GetData({
            extraParams: extraParams.toString(),
        });

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            return;
        }

        setInternalAudit((prev) => ({ ...prev, auditGroups: data.list }));
    };

    const handleSaveAuditGroup = async (value: string) => {
        setIsLoadingSave(true);
        const { status, getParsedError } = await AuditGroupService.Save({
            id: 0,
            name: value,
            fK_Audit: audit.id,
            auditGroupCheckLists: [],
            ponderation: 0,
        });

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            reset(setIsLoadingSave, setShowSaveAuditGroupModal);
            return;
        }

        handleSuccessManager(t(TranslationModals.AUDIT_GROUP_SAVE_TOAST));

        fetchAuditGroup();
        reset(setIsLoadingSave, setShowSaveAuditGroupModal);
    };

    const handleEditAudit = async (value: string, _?: number, auditGroupList?: AuditGroupModel[]) => {
        setIsLoadingEdit(true);
        const { status, getParsedError } = await AuditService.Edit(internalAudit.id, {
            ...audit,
            name: value,
            auditGroups: auditGroupList || audit.auditGroups,
        });

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            reset(setIsLoadingEdit, setShowEditModal);
            return;
        }

        handleSuccessManager(t(TranslationModals.AUDIT_EDIT_TOAST));

        reset(setIsLoadingEdit, setShowEditModal);
        await fetchAuditGroup();
    };

    const handleDeleteAudit = async () => {
        setIsLoadingDelete(true);
        const { status, getParsedError } = await AuditService.Delete(internalAudit.id);

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            reset(setIsLoadingDelete, setShowDeleteModal);
            return;
        }

        handleSuccessManager(t(TranslationModals.AUDIT_DELETE_TOAST));

        fetchAudits();
        reset(setIsLoadingDelete, setShowDeleteModal);
        await fetchAuditGroup();
    };

    const reset = (setIsLoading: (value: boolean) => void, setShowModal: (value: boolean) => void) => {
        setIsLoading(false);
        setShowModal(false);
    };

    const handleAuditGroupChange = (auditGroup: AuditGroupModel) => {
        setInternalAudit((prev) => ({
            ...prev,
            auditGroups: prev.auditGroups.map((group) => (group.id === auditGroup.id ? auditGroup : group)),
        }));
    };

    const calculateRemainingPercentage = () => {
        const total = internalAudit.auditGroups.reduce((acc, group) => acc + group.ponderation * 100, 0);
        return Math.max(100 - total, 0);
    };

    return (
        <>
            <div>
                {showSaveAuditGroupModal && (
                    <AuditBoxModal
                        isLoading={isLoadingSave}
                        onSubmit={handleSaveAuditGroup}
                        onCloseModal={() => setShowSaveAuditGroupModal(false)}
                        remainingPercentage={calculateRemainingPercentage()}
                        type="SAVE_AUDIT_GROUP"
                    />
                )}
                {showEditModal && (
                    <AuditBoxModal
                        isLoading={isLoadingEdit}
                        onSubmit={handleEditAudit}
                        onCloseModal={() => setShowEditModal(false)}
                        value={internalAudit.name}
                        type="EDIT_AUDIT"
                        auditGroupList={internalAudit.auditGroups}
                    />
                )}
                {showDeleteModal && (
                    <ConfirmModal
                        title={t(TranslationModals.DELETE_AUDIT_TITLE).replace("{0}", internalAudit.name)}
                        description={t(TranslationModals.DELETE_AUDIT_BODY)}
                        onClose={() => setShowDeleteModal(false)}
                        onConfirm={handleDeleteAudit}
                        onCloseText={t(TranslationCommon.CANCEL)}
                        onConfirmText={t(TranslationCommon.DELETE)}
                        isLoading={isLoadingDelete}
                        type="delete"
                        variant="appcc"
                    />
                )}
                <div className="auditBox">
                    <AuditBoxHeader
                        auditName={internalAudit.name}
                        onChangeShowSaveModal={setShowSaveAuditGroupModal}
                        onChangeShowEditModal={setShowEditModal}
                        onChangeShowDeleteModal={setShowDeleteModal}
                    />
                    <div className="auditBox__content">
                        {!internalAudit.auditGroups.length ? (
                            <div className="auditBox__error">
                                <TableError
                                    icon={faFileCertificate}
                                    title={t(AuditGroupTranslations.AUDIT_GROUP_EMPTY_TITLE)}
                                    description={t(AuditGroupTranslations.AUDIT_GROUP_EMPTY_BODY)}
                                />
                            </div>
                        ) : (
                            <AuditBoxBody
                                auditGroups={internalAudit.auditGroups}
                                fetchAuditGroups={fetchAuditGroup}
                                onChange={handleAuditGroupChange}
                                remainingPercentage={calculateRemainingPercentage}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
