import { FC } from "react";
import { CheckBoxRole } from "./CheckBoxRole/CheckBoxRole";
import Helper from "app/helpers/Translations/translationHelper";
import { useTranslation } from "react-i18next";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

type RoleCollapseRowProps = {
    isLastIndex: boolean;
    disabled: boolean;
    screenCode: string;
    patents: string[];
    module: Record<string, boolean>;
    onChange: (value: Record<string, boolean>) => void;
};

export const RoleCollapseRow: FC<RoleCollapseRowProps> = ({
    isLastIndex,
    disabled,
    screenCode,
    patents,
    module,
    onChange,
}) => {
    const { t } = useTranslation();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const handleCheckIndeterminate = () => {
        if (isAllChecked() && !isIndeterminate()) {
            const allModulesToFalse = Object.fromEntries(Object.keys(module).map((key) => [key, false]));
            onChange(allModulesToFalse);
            return;
        }

        const allModulesToTrue = Object.fromEntries(Object.keys(module).map((key) => [key, true]));
        onChange(allModulesToTrue);
    };

    const isIndeterminate = () => Object.values(module).some((value) => value === false);
    const isAllChecked = () => Object.values(module).some((value) => value === true);

    const handleChange = (value: Record<string, boolean>) => {
        const moduleUpdated = { ...module, ...value };
        onChange(moduleUpdated);
    };

    return (
        <div className={`roleCollapseRow${isLastIndex ? "--noBorder" : ""}`}>
            <p className="roleCollapseRow__text">
                {Helper.translateRowTitle(screenCode, t, translateCustomerTypeKeys)}
            </p>
            <CheckBoxRole
                isChecked={isAllChecked()}
                indeterminate={isIndeterminate()}
                onChange={handleCheckIndeterminate}
                disabled={disabled}
            />
            <CheckBoxRole
                isChecked={module["VIEW"]}
                onChange={(checked) => handleChange({ VIEW: checked })}
                show={patents.includes("VIEW")}
                disabled={disabled}
            />
            <CheckBoxRole
                isChecked={module["ADD"]}
                onChange={(checked) => handleChange({ ADD: checked })}
                show={patents.includes("ADD")}
                disabled={disabled}
            />
            <CheckBoxRole
                isChecked={module["UPDATE"]}
                onChange={(checked) => handleChange({ UPDATE: checked })}
                show={patents.includes("UPDATE")}
                disabled={disabled}
            />
            <CheckBoxRole
                isChecked={module["DELETE"]}
                onChange={(checked) => handleChange({ DELETE: checked })}
                show={patents.includes("DELETE")}
                disabled={disabled}
            />
        </div>
    );
};
