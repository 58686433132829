import { faChevronDown, faSearch, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { TabHeader } from "app/components_v2/__containers/TabHeader/TabHeader";
import { Input } from "app/components_v2/__inputs";
import { BreadcrumbModel } from "app/components_v2/BreadCrumbs";
import { PrivatePaths } from "app/shared/Constants";
import {
    DocumentalFileTranslations,
    TranslationCommon,
    TranslationKeys,
    TranslationTitles,
} from "app/translation/translationKeys";
import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentalFileSortField } from "../../models/DocumentalFilePageModel";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { useOnClickOutside } from "usehooks-ts";
import { Popover } from "app/components_v2/Popover/Popover";
import { DropdownList } from "app/components_v2/Dropdown/DropdownList/DropdownList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MobileHeaderTitleSkelleton } from "app/components_v2/__skelletons/MobileHeaderTitleSkelleton/MobileHeaderTitleSkelleton";

type DocumentalFilePageHeaderProps = {
    query: string;
    isLoading: boolean;
    name: string;
    sortField: DocumentalFileSortField;
    sortDirection: SortedTypeModel;
    onChangeQuery: (query: string) => void;
    onSearch: (q: string) => void;
    onChangeOrderBy: (sortF: DocumentalFileSortField, sortD: SortedTypeModel) => void;
};

export const DocumentalFilePageHeader: FC<DocumentalFilePageHeaderProps> = ({
    isLoading,
    onChangeQuery,
    onSearch,
    query,
    name,
    onChangeOrderBy,
    sortDirection,
    sortField,
}) => {
    const { t } = useTranslation();
    const [isOrderByModalOpen, setIsOrderByModalOpen] = useState<boolean>(false);
    const modalRef = useRef<HTMLDivElement | null>(null);

    const breadCrums: BreadcrumbModel[] = [
        { name: t(TranslationTitles.DOCUMENTAL_MANAGER), to: `/${PrivatePaths.DOCUMENTAL_MANAGER}` },
    ];

    useOnClickOutside(modalRef, () => {
        if (isOrderByModalOpen) setIsOrderByModalOpen(false);
    });

    const getText = (sortF: DocumentalFileSortField, sortD: SortedTypeModel) => {
        if (sortF === "CreatedDate")
            return sortD === "asc"
                ? t(DocumentalFileTranslations.DOCUMENTAL_FILE_ORDER_BY_CREATED_DATE_ASC)
                : t(DocumentalFileTranslations.DOCUMENTAL_FILE_ORDER_BY_CREATED_DATE_DESC);
        if (sortF === "Name")
            return sortD === "asc"
                ? t(DocumentalFileTranslations.DOCUMENTAL_FILE_ORDER_BY_NAME_ASC)
                : t(DocumentalFileTranslations.DOCUMENTAL_FILE_ORDER_BY_NAME_DESC);

        return t(DocumentalFileTranslations.DOCUMENTAL_FILE_ORDER_BY_CREATED_DATE_ASC);
    };

    const handleChangeOrderBy = (sortF: DocumentalFileSortField, sortD: SortedTypeModel) => {
        onChangeOrderBy(sortF, sortD);
        setIsOrderByModalOpen(false);
    };

    const getDropdownItems = () => {
        const items = [
            {
                text: getText("Name", "asc"),
                onClick: () => handleChangeOrderBy("Name", "asc"),
                active: sortField === "Name" && sortDirection === "asc",
            },
            {
                text: getText("Name", "desc"),
                onClick: () => handleChangeOrderBy("Name", "desc"),
                active: sortField === "Name" && sortDirection === "desc",
            },
            {
                text: getText("CreatedDate", "asc"),
                onClick: () => handleChangeOrderBy("CreatedDate", "asc"),
                active: sortField === "CreatedDate" && sortDirection === "asc",
            },
            {
                text: getText("CreatedDate", "desc"),
                onClick: () => handleChangeOrderBy("CreatedDate", "desc"),
                active: sortField === "CreatedDate" && sortDirection === "desc",
            },
        ];
        return items;
    };

    return (
        <TabHeader paddingSize="sm" classNameToScrollTop=".customContainer" breadCrums={breadCrums}>
            <div className="documentalFilePageHeader">
                <div className="documentalFilePageHeader__header">
                    {isLoading ? (
                        <MobileHeaderTitleSkelleton />
                    ) : (
                        <h1 className="documentalFilePageHeader__title">
                            {t(DocumentalFileTranslations.DOCUMENTAL_FILE_HEADER_TITLE).replace("{0}", name)}
                        </h1>
                    )}
                    <div className="documentalFilePageHeader__search">
                        <Input
                            value={query}
                            onChange={onChangeQuery}
                            placeholder={t(TranslationCommon.SEARCH)}
                            iconRight={!!query.length ? faXmark : faSearch}
                            whiteInput
                            transparent
                            onPressEnter={() => onSearch(query)}
                            onClickIcon={() => onSearch("")}
                        />
                    </div>
                </div>
                <div className="documentalFolderPageHeader__filters" ref={modalRef}>
                    {isOrderByModalOpen && (
                        <Popover
                            target={modalRef.current}
                            position="bottomLeft"
                            onResize={() => setIsOrderByModalOpen(false)}
                            onScroll={() => setIsOrderByModalOpen(false)}
                            scrollTarget={document.querySelector(".customContainer")}
                            portal={false}
                        >
                            <DropdownList items={getDropdownItems()} />
                        </Popover>
                    )}
                    <div
                        className="documentalFolderPageHeader__orderBy"
                        onClick={() => setIsOrderByModalOpen((prev) => !prev)}
                    >
                        <p className="documentalFolderPageHeader__orderBy__text">{t(TranslationKeys.ORDER_BY_STARS)}</p>
                        <div className="documentalFolderPageHeader__orderBy__container">
                            <p className="documentalFolderPageHeader__orderBy__text">
                                {getText(sortField, sortDirection)}
                            </p>
                            <FontAwesomeIcon icon={faChevronDown} className="feedback__order__icon" />
                        </div>
                    </div>
                </div>
            </div>
        </TabHeader>
    );
};
