import { AUDIT_INSTANCE_STATUS_ARRAY } from "app/shared/Auditconstants";
import { AuditInstanceStatus } from "app/shared/types/AuditInstanceTypes";

export const auditGridUrlParamsValidator = (searcParams: URLSearchParams) => {
    const auditSearchParams = new URLSearchParams();
    const auditStatus = searcParams.get("audit_status");

    if (auditStatus !== null && AUDIT_INSTANCE_STATUS_ARRAY.includes(auditStatus as AuditInstanceStatus))
        auditSearchParams.append("audit_status", auditStatus);

    if (auditSearchParams.size === 0) return null;

    return auditSearchParams;
};
