import {
    convertUTCtoLocaleDate,
    dateToDateStringWithHours,
    getFullDateWithTime,
    getFullDateWithTimeEndDate,
    getWeekDay,
    transformMinutesHoursToDate,
} from "app/helpers/Utilities/date/Date.utilities";
import { selectTypeCode, selectTypeCodeCustom } from "./selectTypeCode";
import { WizardTaskBody, TaskFieldModel2 } from "app/models/02-TAR/Task/TaskBody";
import {
    WizardTaskAllSteps,
    RecurrentTaskModel,
    DynamicFieldsModel,
    ReportDataList,
} from "app/models/02-TAR/TaskWizard";
import { checkIfDateRangeWeeklyIsSelected } from "../../EditTask/utilities";
import { DEFAULT_OFFSET } from "app/shared/Constants";

export const fillTaskBody = (data: WizardTaskAllSteps, instanceId?: number, isIssueTask?: boolean, offset?: number) => {
    const {
        companyForTask,
        userForTask,
        DepartmentForTask,
        SubDepartementForTask,
        workingPositionForTask,
        criticalTask,
        taskStart,
        taskDesc,
        fK_PlanAPPCC,
        finish,
        recurringTask,
        taskHours,
        taskTitle,
        imageBase64,
        somethingToReportCheckBoxImage,
        reportData,
        reportType,
        checkList,
        dynamicFields,
        fK_BaseTask,
        fotoExampleId,
        taskType,
        isCreated,
        fK_Issue,
        temporalityType,
        taskTemporalityDateRange,
        minPhotosAllowed,
    } = data;

    const body: WizardTaskBody = {
        fK_BaseTask: fK_BaseTask,
        taskType,
        customerInstanceId: instanceId ?? 0,
        name: taskTitle,
        description: taskDesc,
        fK_PlanAPPCC: !!fK_PlanAPPCC.length && fK_PlanAPPCC !== "-1" ? Number(fK_PlanAPPCC) : null,
        fK_Company: Number(companyForTask.value),
        fK_User: userForTask.value !== "" ? Number(userForTask.value) : null,
        fK_Department: DepartmentForTask.value !== "" ? Number(DepartmentForTask.value) : null,
        fK_Subdepartment: SubDepartementForTask.value !== "" ? Number(SubDepartementForTask.value) : null,
        fK_WorkingPosition: workingPositionForTask.value !== "" ? Number(workingPositionForTask.value) : null,
        fK_Foto: null,
        fotoExampleId: fotoExampleId || null,
        isPhotoRequired: somethingToReportCheckBoxImage,
        imageBase64Content: imageBase64,
        minPhotosAllowed: minPhotosAllowed || (somethingToReportCheckBoxImage ? 1 : null),
        isCritical: criticalTask,
        reportType,
        startDate: getFullDateWithTime(taskStart),
        endDate: finish.checked === "el" ? getFullDateWithTime(String(finish.value)) : null,
        neverEnds:
            finish.checked === "never" && recurringTask && recurringTask.isRecurrent
                ? finish.checked === "never"
                : null,
        numRecurrencies: finish.checked === "after" ? Number(finish.value) : null,
        taskTemporalityType: temporalityType,
        taskRangeHours:
            temporalityType === "RANGE_DATES"
                ? []
                : taskHours.map(({ hour, maxHour, type }) => {
                      // Local to User offset
                      const startDateToUserOffset = convertUTCtoLocaleDate(
                          new Date(transformMinutesHoursToDate(hour)),
                          offset,
                          false
                      );
                      const startDateConverted = dateToDateStringWithHours(startDateToUserOffset.toString());

                      let endDateConverted = null;
                      if (type === "range" && maxHour) {
                          const maxHourDateToUserOffset = convertUTCtoLocaleDate(
                              new Date(transformMinutesHoursToDate(maxHour)),
                              offset,
                              false
                          );
                          if (startDateToUserOffset > maxHourDateToUserOffset) {
                              maxHourDateToUserOffset.setDate(maxHourDateToUserOffset.getDate() + 1);
                          }
                          endDateConverted = dateToDateStringWithHours(maxHourDateToUserOffset.toString());
                      }

                      return {
                          hour: startDateConverted,
                          maxHour: endDateConverted,
                      };
                  }),
        taskTemporalityDateRange: taskTemporalityDateRange.custom.dateRange.map(({ endDate, isDeleted, startDate }) => {
            const startDateFormatted = getFullDateWithTime(startDate);
            const endDateFormatted = getFullDateWithTimeEndDate(endDate, offset || DEFAULT_OFFSET);
            const isDateRangeCustom = taskTemporalityDateRange.value === "CUSTOM";
            return {
                id: 0,
                startDate: startDateFormatted,
                endDate: endDateFormatted,
                isDeleted: isDateRangeCustom ? isDeleted : true,
            };
        }),
        taskTemporalityDateRangeScheduler:
            temporalityType === "RANGE_DATES" && taskTemporalityDateRange.value !== "CUSTOM"
                ? {
                      fK_Task: 0,
                      id: taskTemporalityDateRange.dbId || 0,
                      repeatEvery: Number(taskTemporalityDateRange.repeatEvery),
                      type: taskTemporalityDateRange.value,
                      monthlyType:
                          taskTemporalityDateRange.value === "MONTHLY" ? taskTemporalityDateRange.monthly.value : "",
                      monthlyDay:
                          taskTemporalityDateRange.value === "MONTHLY" &&
                          taskTemporalityDateRange.monthly.value !== "CUSTOM"
                              ? Number(taskTemporalityDateRange.monthly.day)
                              : null,
                      monthlyStartDay:
                          taskTemporalityDateRange.value === "MONTHLY" &&
                          taskTemporalityDateRange.monthly.value === "CUSTOM"
                              ? Number(taskTemporalityDateRange.monthly.customDays.startDay)
                              : null,
                      monthlyEndDay:
                          taskTemporalityDateRange.value === "MONTHLY" &&
                          taskTemporalityDateRange.monthly.value === "CUSTOM"
                              ? Number(taskTemporalityDateRange.monthly.customDays.endDay)
                              : null,
                      monday: checkIfDateRangeWeeklyIsSelected(taskTemporalityDateRange, 0),
                      tuesday: checkIfDateRangeWeeklyIsSelected(taskTemporalityDateRange, 1),
                      wednesday: checkIfDateRangeWeeklyIsSelected(taskTemporalityDateRange, 2),
                      thursday: checkIfDateRangeWeeklyIsSelected(taskTemporalityDateRange, 3),
                      friday: checkIfDateRangeWeeklyIsSelected(taskTemporalityDateRange, 4),
                      saturday: checkIfDateRangeWeeklyIsSelected(taskTemporalityDateRange, 5),
                      sunday: checkIfDateRangeWeeklyIsSelected(taskTemporalityDateRange, 6),
                  }
                : null,
        taskScheduler: {
            name:
                recurringTask && recurringTask.isRecurrent
                    ? recurringTask.value !== "custom"
                        ? recurringTask.text
                        : `custom-every ${recurringTask.custom.repeatEvery} ${recurringTask.custom.customValue}`
                    : "Task is not recurring",

            calendarDayNumber:
                recurringTask &&
                recurringTask.isRecurrent &&
                recurringTask.value === "custom" &&
                recurringTask.custom.customValue === "everyMonth"
                    ? recurringTask.custom.selectedOptions.value === "everyMonthDayOfMonth"
                        ? new Date(taskStart).getDate()
                        : 0
                    : 0,
            calendarWeekNumber:
                recurringTask && recurringTask.isRecurrent
                    ? recurringTask.custom.customValue === "everyMonth"
                        ? recurringTask.custom.selectedOptions.value === "everyMonthFirstDayOfWeek"
                            ? getWeekDay(taskStart)
                            : 0
                        : recurringTask.value === "everyMonth"
                        ? getWeekDay(taskStart)
                        : 0
                    : 0,

            repeatEvery: recurringTask && recurringTask.value === "custom" ? recurringTask.custom.repeatEvery : 1,
            monday: checkIfIsWeekDayIsSelected(recurringTask, 0, new Date(taskStart).getDay() - 1),
            tuesday: checkIfIsWeekDayIsSelected(recurringTask, 1, new Date(taskStart).getDay() - 1),
            wednesday: checkIfIsWeekDayIsSelected(recurringTask, 2, new Date(taskStart).getDay() - 1),
            thursday: checkIfIsWeekDayIsSelected(recurringTask, 3, new Date(taskStart).getDay() - 1),
            friday: checkIfIsWeekDayIsSelected(recurringTask, 4, new Date(taskStart).getDay() - 1),
            saturday: checkIfIsWeekDayIsSelected(recurringTask, 5, new Date(taskStart).getDay() - 1),
            sunday: checkIfIsWeekDayIsSelected(recurringTask, 6, new Date(taskStart).getDay() - 1),
            typeCode:
                recurringTask && recurringTask.isRecurrent && recurringTask.value === "custom"
                    ? selectTypeCodeCustom(recurringTask.custom.customValue)
                    : recurringTask && recurringTask.isRecurrent
                    ? selectTypeCode(recurringTask.value)
                    : "ONE_TIME",
        },
        taskCheckList:
            reportType === "CHECKLIST"
                ? checkList.map(({ name }) => ({ name, isDeleted: false, isEnabled: true, fK_BaseTaskCheckList: null }))
                : [],
        taskFields2:
            reportType === "ASSET"
                ? getAssets(reportData)
                : reportType === "DYNAMIC_FIELD"
                ? getDynamicFields(dynamicFields)
                : [],
        isCreated: isCreated ?? true,
        fK_Issue: fK_Issue ?? null,
    };

    return body;
};

const checkIfIsWeekDayIsSelected = (
    recurringTask: RecurrentTaskModel | null,
    index: number,
    dayOnWeek: number
): boolean => {
    if (!recurringTask) return false;

    const recurringTaskPermitedValues = ["everyWeek", "everyMonth", "annually"];

    if (recurringTask.isRecurrent) {
        if (recurringTask.value === "custom") {
            if (recurringTask.custom.customValue === "week") return recurringTask.custom.days[index].isActive;

            if (
                recurringTask.custom.customValue === "everyMonth" &&
                recurringTask.custom.selectedOptions.value === "everyMonthFirstDayOfWeek"
            )
                return index === dayOnWeek;
        }
    }

    if (recurringTaskPermitedValues.includes(recurringTask.value)) return index === dayOnWeek;

    if (recurringTask.value === "mondayToFriday") return index <= 4;

    return false;
};

const getDynamicFields = (dynamicFields: DynamicFieldsModel[]): TaskFieldModel2[] => {
    const taskfields: TaskFieldModel2[] = [];
    let order = 0;

    dynamicFields.forEach(
        ({ dropDownMultipleValues, dynamicFieldsType, isDeleted, isRequired, label, taskFieldOptions, dbId }) => {
            if (dropDownMultipleValues.length > 0) {
                dropDownMultipleValues.forEach((ddmv) =>
                    ddmv.assetsFields.forEach(
                        ({
                            name,
                            assetDynamicField,
                            assetFieldRangeId,
                            isDeleted,
                            isHidden,
                            assetFieldOption,
                            isRequired,
                            id,
                        }) => {
                            taskfields.push({
                                isRequired,
                                dynamicFieldType: assetDynamicField,
                                id: undefined,
                                isDeleted: !!isDeleted,
                                label: name,
                                fK_Asset2: Number(ddmv.value),
                                fK_AssetFieldRange: assetDynamicField === "NUMBER" ? assetFieldRangeId : undefined,
                                fK_Task: undefined,
                                isHidden,
                                taskFieldOptions: assetFieldOption.map(({ name }) => ({ label: name })),
                                order,
                            });
                            order++;
                        }
                    )
                );
                return;
            }

            taskfields.push({
                id: undefined,
                label,
                isRequired,
                isDeleted,
                dynamicFieldType: dynamicFieldsType,
                taskFieldOptions: taskFieldOptions.map(({ label }) => ({ label })),
                fK_Task: undefined,
                isHidden: false,
                order,
            });
            order++;
        }
    );

    return taskfields;
};

const getAssets = (reportData: ReportDataList[]): TaskFieldModel2[] => {
    return reportData
        .map(({ assetId, isDeleted, assetsFields }) =>
            assetsFields
                ? assetsFields?.map(({ name, assetDynamicField, assetFieldRangeId, isHidden }, i) => ({
                      isRequired: true,
                      dynamicFieldType: assetDynamicField,
                      isDeleted,
                      label: name,
                      fK_Asset2: assetId,
                      fK_AssetFieldRange: assetDynamicField === "NUMBER" ? assetFieldRangeId : undefined,
                      isHidden,
                      order: i,
                  }))
                : []
        )
        .flat();
};
