import { FC, useState } from "react";
import { ProviderProps } from "app/state/Providers/types";
import { SortedTypeModel } from "app/components_v2/Table/types";
import {
    INITIAL_TASK_GRID_SF_VALUES,
    INITIAL_TASK_GRID_SORT_DIRECTION,
    INITIAL_TASK_GRID_SORT_FIELD,
} from "../../constants/taskGridConstants";
import { useSelectCompanyTable } from "app/components_v2/Table/hooks/useSelectCompanyTable";
import { TaskGridContext } from "../context/taskGridContext";
import { SelectedColumnViewModel } from "../../models/taskPageModels";
import { TaskGridSecondaryFilterModel } from "../../models/taskGridSecondaryFilterModel";
import { useSearchParams } from "react-router-dom";
import { TranslationKeys } from "app/translation/translationKeys";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { selectAssigned } from "app/helpers/Task/selectAssigned";

export const TaskGridProvider: FC<ProviderProps> = ({ children }) => {
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();
    const [urlParams] = useSearchParams();

    const baseTaskId = urlParams.get("baseTaskId");

    const [pageIndex, setPageIndex] = useState<number>(1);
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [sortField, setSortField] = useState<string>(INITIAL_TASK_GRID_SORT_FIELD);
    const [sortDirection, setSortDirection] = useState<SortedTypeModel>(INITIAL_TASK_GRID_SORT_DIRECTION);
    const [secondaryFilterValues, setSecondaryFilterValues] = useState<TaskGridSecondaryFilterModel>({
        ...INITIAL_TASK_GRID_SF_VALUES,
        baseTask: baseTaskId || "-1",
    });
    const [initialRender, setInitialRender] = useState<boolean>(false);
    const [query, setQuery] = useState<string>("");

    const [selectedColumnView, setSelectedColumnView] = useState<SelectedColumnViewModel>({
        user: false,
        dept: false,
        subDept: false,
        workingPosition: false,
        all: true,
    });

    const { handleCompanyChange, selectCompany, filterIcon } = useSelectCompanyTable({
        defaultCompany: {
            label: translateCustomerTypeKeys(TranslationKeys.ALL_COMPANIES, { firstReplace: "PLURAL" }),
            value: "-1",
        },
    });

    const onSortChange = (sortF: string, sortD: SortedTypeModel) => {
        setSortField(sortF);
        setSortDirection(sortD);
    };

    const onSFChange = (sfValues: Partial<TaskGridSecondaryFilterModel>) =>
        setSecondaryFilterValues((prev) => ({ ...prev, ...sfValues }));

    const onSelectFilterButton = (value: SelectedColumnViewModel) => {
        setSelectedColumnView(value);
        onSFChange({
            responsable: {
                assignedTo: selectAssigned(value),
                text: "",
                value: "-1",
            },
            workingPositionDepartments: {
                text: "",
                value: "-1",
            },
            detail: {
                text: "",
                value: "-1",
            },
        });
    };

    return (
        <TaskGridContext.Provider
            value={{
                pageIndex,
                onChangePageIndex: setPageIndex,
                currentTab,
                onChangeCurrentTab: setCurrentTab,
                sortDirection,
                sortField,
                onSortChange,
                secondaryFilterValues,
                onSFChange,
                selectCompany,
                filterIcon,
                onCompanyChange: handleCompanyChange,
                initialRender,
                onInitialRenderChange: setInitialRender,
                selectedColumnView,
                onSelectFilterButton,
                query,
                onQueryChange: setQuery,
            }}
        >
            {children}
        </TaskGridContext.Provider>
    );
};
