import { FormEvent, useEffect, useState } from "react";

import { TranslationCommon, TranslationErrors, TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { isDropdownDynamicField } from "app/helpers/Task/dynamicFields/dynamicFieldHelper";
import { useDynamicFieldsErrorHandler } from "app/hooks/dynamicFields/useDynamicFieldsErrorHandler";
import {
    CheckListModel,
    DynamicFieldsModel,
    ReportDataList,
    WizardTaskStep3,
    ReportType,
} from "app/models/02-TAR/TaskWizard";
import { Taskfield2Error, TaskfieldOptionsError } from "app/models/02-TAR/Task/EditTask";
type IValidations = {
    somethingToReportDesc: string;
    somethingToReportImage: string;
    reportData: string;
    dynamicFields: Taskfield2Error[];
    checklist: TaskfieldOptionsError[];
};

export const useStepThree = (
    somethingToReportCheckBox: boolean,
    somethingToReportCheckBoxImage: boolean,
    reportData: ReportDataList[],
    onChange: (fields: Partial<WizardTaskStep3>) => void,
    next: () => void,
    reportType: ReportType,
    checkList: CheckListModel[],
    dynamicFields: DynamicFieldsModel[]
) => {
    const { t } = useTranslation();
    const { filterTaskFieldsWithoutErrorMessage, taskfieldOptionsDropdown } = useDynamicFieldsErrorHandler();

    const [validations, setValidations] = useState<IValidations>({
        somethingToReportDesc: "",
        somethingToReportImage: "",
        reportData: "",
        dynamicFields: [],
        checklist: [],
    });

    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let check = true;
        const aux = { ...validations };

        aux.reportData = "";

        if (reportType === "ASSET" && !reportData[0]?.value) {
            aux.reportData = t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION);
            check = false;
        }
        if (reportType === "CHECKLIST") {
            const checklist = checkList.filter(({ isDeleted }) => !isDeleted);
            const errorChecklist: TaskfieldOptionsError[] = checklist.map(({ id, name }) => ({
                errorMessage: !name.length ? t(TranslationKeys.CHECKLIST_CANNOT_BE_EMPTY) : "",
                id,
            }));

            aux.checklist = errorChecklist;
        }

        if (reportType === "DYNAMIC_FIELD") {
            if (!dynamicFields.length) {
                aux.reportData = t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION);
                check = false;
            }

            aux.dynamicFields = dynamicFields
                .map(({ id, label, dynamicFieldsType, taskFieldOptions, dropDownMultipleValues }) => ({
                    dynamicFieldsType: dynamicFieldsType,
                    errorMessage:
                        (dynamicFieldsType === "DROPDOWN_MULTIPLE_ASSETS_RANGE" &&
                            !dropDownMultipleValues.filter((ddmv) => !ddmv.isDeleted).length) ||
                        (dynamicFieldsType !== "DROPDOWN_MULTIPLE_ASSETS_RANGE" && !label.length)
                            ? t(TranslationCommon.INPUT_NOT_EMPTY)
                            : "",
                    id: id,
                    taskfieldOptions: isDropdownDynamicField(dynamicFieldsType)
                        ? taskfieldOptionsDropdown(taskFieldOptions)
                        : [],
                }))
                .filter(filterTaskFieldsWithoutErrorMessage);
            if (aux.dynamicFields.length) check = false;
        }
        setValidations({ ...aux });
        if (check) next();
    };

    useEffect(() => {
        if (somethingToReportCheckBox) return;
        onChange({ somethingToReportDesc: "" });
    }, [somethingToReportCheckBox]);

    useEffect(() => {
        if (somethingToReportCheckBoxImage) return;
        onChange({ somethingToReportImage: "" });
    }, [somethingToReportCheckBoxImage]);

    return {
        validations,
        validate,
    };
};
