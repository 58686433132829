import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useRankingPillFullWidth } from "app/hooks/RankingPill/useRankingPillFullWidth";
import { TranslationTitles } from "app/translation/translationKeys";
import { isUserTypeSupervisorOrAnalistOrAdminClient } from "app/helpers/User/userType/userTypeCheck";
import { RankingFilterModel } from "app/components_v2/__filters/RankingFilter/types";
import { BreadcrumbModel } from "app/components_v2/BreadCrumbs";
import { EntityRankingModel } from "app/models/03-REP/Ranking/EntityRankingModel";
import { RankingHeader, RankingSortBy } from "./RankingHeader/RankingHeader";
import { DataModel, RankingPillList } from "app/components_v2/RankingPillList/RankingPillList";
import { useTitle } from "app/hooks";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { DateRange } from "app/models/utilities";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

export type RankingTypePages = "USER" | "COMPANY" | "DEPARTMENT";

type RankingLayoutProps = {
    title: string;
    startDate?: Date;
    endDate?: Date;
    data: EntityRankingModel[];
    total: number;
    pageIndex: number;
    rankingTypePages: RankingTypePages;
    isLoading?: boolean;
    sortBy: RankingSortBy;
    filterData?: RankingFilterModel;
    initialfilterData?: RankingFilterModel;
    onFilter?: (value: RankingFilterModel) => void;
    onChangeSortBy: (value: RankingSortBy) => void;
    onPageIndexChange: (pageIndex: number) => void;
    onDateChange?: (dates: DateRange) => void;
    onPressEnter: (query: string) => void;
    onClickPill: (value: EntityRankingModel) => void;
    breadcrumbs?: BreadcrumbModel[];
    headerLoading?: boolean;
    hasCounters?: boolean;
};

export const RankingLayout: FC<RankingLayoutProps> = ({
    onDateChange,
    onPressEnter,
    title,
    isLoading,
    startDate,
    endDate,
    data,
    rankingTypePages,
    onClickPill,
    onPageIndexChange,
    pageIndex,
    total,
    sortBy,
    onChangeSortBy,
    filterData,
    initialfilterData,
    onFilter,
    breadcrumbs,
    headerLoading,
    hasCounters = true,
}) => {
    const { t } = useTranslation();
    const { numberOfStars } = useRankingPillFullWidth();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    useTitle(
        rankingTypePages === "COMPANY"
            ? translateCustomerTypeKeys(TranslationTitles.GLOBAL_COMPANY_RANKING_PAGE_TITLE, { firstReplace: "PLURAL" })
            : rankingTypePages === "DEPARTMENT"
            ? t(TranslationTitles.GLOBAL_DEPARTMENT_RANKING_PAGE_TITLE)
            : rankingTypePages === "USER"
            ? t(TranslationTitles.GLOBAL_USER_RANKING_PAGE_TITLE)
            : translateCustomerTypeKeys(TranslationTitles.ANALYTICS_PAGE_TITLE, { firstReplace: "PLURAL" })
    );

    return (
        <PageContainer
            header={
                <RankingHeader
                    breadcrumbs={breadcrumbs}
                    onDateChange={onDateChange}
                    onPressEnter={onPressEnter}
                    startDate={startDate}
                    endDate={endDate}
                    title={title}
                    sortBy={sortBy}
                    onChangeSortBy={onChangeSortBy}
                    filterData={filterData}
                    initialfilterData={initialfilterData}
                    onFilter={onFilter}
                    isLoading={headerLoading}
                />
            }
            fullHeight
            paddingBottom="sm"
        >
            <RankingPillList
                data={data.map((rank) => {
                    const {
                        outOfTimeCount,
                        timedOutCount,
                        id,
                        userType,
                        totalTasks: rankingTotalTask,
                        outOfTimePercentage,
                        timedOutPercentage,
                    } = rank;
                    const { name, avg, photoURL, trend, totalTasks, jobDescription, userCompanies } =
                        rank.entityHeaderInfoModel;

                    const data: DataModel = {
                        counters: hasCounters
                            ? [
                                  {
                                      counter: outOfTimeCount || 0,
                                      type: outOfTimeCount === 0 ? "defaultClock" : "yellow",
                                      percentage:
                                          outOfTimePercentage !== undefined
                                              ? Number(outOfTimePercentage?.toFixed(2))
                                              : 0,
                                  },
                                  {
                                      counter: timedOutCount || 0,
                                      type: timedOutCount === 0 ? "defaultCross" : "danger",
                                      percentage:
                                          timedOutPercentage !== undefined ? Number(timedOutPercentage?.toFixed(2)) : 0,
                                  },
                              ]
                            : undefined,
                        jobDescription: !!userCompanies.length ? userCompanies.join(", ") : jobDescription,
                        name,
                        rankingTotalTask: rankingTotalTask || 0,
                        totalTasks: totalTasks || 0,
                        colorId: id,
                        hexColor: rank.hexColor,
                        onClick: () => onClickPill(rank),
                        profileURL: photoURL || undefined,
                        starsValoration: avg ? Math.round(avg * 10) / 10 : 0,
                        trend: trend ? Math.round(trend * 10) / 10 : 0,
                        variant:
                            isUserTypeSupervisorOrAnalistOrAdminClient(userType) && rankingTypePages === "USER"
                                ? "primary"
                                : "white",
                        userType: userType || "WORKER",
                        showBorderLeft: !!userType,
                    };
                    return data;
                })}
                numberOfstars={numberOfStars}
                type="percentage"
                isLoading={isLoading}
                onPageIndexChange={onPageIndexChange}
                pageIndex={pageIndex}
                total={total}
                rankingTypePages={rankingTypePages}
            />
        </PageContainer>
    );
};
