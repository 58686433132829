import {
    convertUTCtoLocaleDate,
    getDayMonthAndHourFormatedShort,
    getDayMonthFormatedUTC,
} from "app/helpers/Utilities/date/Date.utilities";
import { TaskInstanceListDto } from "app/dtos/02-TAR/TaskInstance/TaskInstanceListDto";

type IgetLimitDate = {
    taskInstance: TaskInstanceListDto;
    lang: string;
    offset?: number;
};

export const getLimitDate = ({ lang, taskInstance, offset }: IgetLimitDate) => {
    if (taskInstance.taskTemporalityType === "RANGE_DATES" && taskInstance.deadlineDate != null)
        return getDayMonthFormatedUTC(new Date(taskInstance.deadlineDate), lang);
    if (taskInstance.taskType !== "SPORADIC" && taskInstance.deadlineDate != null)
        return getDayMonthAndHourFormatedShort(convertUTCtoLocaleDate(taskInstance.deadlineDate, offset), lang);

    return "";
};
