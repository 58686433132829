import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "app/hooks/CustomHooks";
import { TranslationCommon, TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { PaginationDefaults, SecScreen } from "app/shared/Constants";
import { hasPermission, hasPermissionToAdd } from "app/routes/HelperRoleBasedAccess";
import { ColumnsType } from "app/components_v2/Table/types";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { faCirclePlus, faMagnifyingGlass, faPen, faSliders, faUser } from "@fortawesome/pro-regular-svg-icons";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { TableSelectCompany } from "app/components_v2/Table/TableSelectCompany/TableSelectCompany";
import { MobileUserRow } from "./MobileUserRow/MobileUserRow";
import { GetUserTypeDescription } from "app/helpers/User/userType/getUserTypeDescription";
import { Badge } from "app/components_v2/Badge/Badge";
import { TableInputText } from "app/components_v2/Table/TableInputText/TableInputText";
import { UserListDto } from "app/dtos/01-SEG/UserListDto";
import { getUserTypeColor } from "app/helpers/User/userType/getUserTypeColor";
import { useNavigate } from "react-router-dom";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { useGetInvalidTranslation } from "app/hooks/User/useGetInvalidTranslation";
import { useUserGrid } from "../hooks/useUserGrid";
import { isEqual } from "lodash";
import { INITIAL_USER_GRID_SECONDARY_FILTER } from "../constants/userGridConstants";
import { UserFilter } from "app/components_v2/__filters/UserFilter/UserFilter";
import { Avatar } from "app/components_v2/Avatar/Avatar";

const UserGrid: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();
    const { getInactiveTitleAndDate } = useGetInvalidTranslation();

    const {
        currentTab,
        data,
        handleCompanyChange,
        handleExport,
        handleSubmitQuery,
        isLoading,
        onCompanyChange,
        onQueryChange,
        pageIndex,
        query,
        selectCompany,
        setShowSearchInput,
        showSearchInput,
        sortDirection,
        sortField,
        tabs,
        total,
        handlePageIndexChange,
        handleSortChange,
        filterIcon,
        handleFilterIconClick,
        secondaryFilterValues,
        handleChangeSecondaryFilterVisibility,
        isSecondaryFilterOpen,
        handleSaveSecondaryFilter,
    } = useUserGrid();

    useTitle(t(TranslationTitles.USERS_PAGE_TITLE));

    const columns: ColumnsType<UserListDto>[] = [
        {
            label: t(TranslationKeys.NAME),
            dataIndex: "firstName",
            render: ({ firstName, lastName, profilePictureURL, id }) => (
                <Avatar colorId={id} img={profilePictureURL || ""} name={`${firstName} ${lastName}`} />
            ),
            sortedType: "asc",
            className: "userGrid__firstName",
        },
        {
            label: t(TranslationKeys.TABLE_USERCODE),
            dataIndex: "userCode",
            render: (record) => <CellTitle title={record.userCode} />,
            sortedType: "default",
            className: "userGrid__userCode",
        },
        {
            label: t(TranslationKeys.USER_TYPE),
            dataIndex: "userType",
            render: (record) => (
                <Badge variant={getUserTypeColor(record.userType)} title={GetUserTypeDescription(record.userType, t)} />
            ),
            sortedType: "default",
            className: "userGrid__userCode",
        },
        {
            label: t(TranslationKeys.LABEL_DEPARTMENT_NAME),
            dataIndex: "department",
            render: (record) => <CellTitle title={record.departmentNameList?.join(" - ")} />,
            className: "userGrid__department",
        },
        {
            label: t(TranslationKeys.LABEL_INACTIVE_SINCE),
            dataIndex: "automaticActivationDate",
            render: ({ automaticActivationDate }) => (
                <Badge title={getInactiveTitleAndDate(automaticActivationDate).date} variant="grey" />
            ),
            className: "userGrid__department",
            hidden: currentTab !== 2,
        },
        {
            label: t(TranslationCommon.EDIT),
            dataIndex: "edit",
            render: (record) => (
                <CellIcons
                    icons={[
                        { icon: faPen, onClick: () => navigate(`edit/${record.id}`), title: t(TranslationCommon.EDIT) },
                    ]}
                />
            ),
            alignCenter: true,
            hidden: !hasPermission(SecScreen.USER_MANAGEMENT),
            className: "userGrid__actions",
        },
    ];

    return (
        <>
            <UserFilter
                filterData={secondaryFilterValues}
                initialFilterData={INITIAL_USER_GRID_SECONDARY_FILTER}
                isOpen={isSecondaryFilterOpen}
                isSecondaryFilterOpen={isSecondaryFilterOpen}
                onCancel={() => handleChangeSecondaryFilterVisibility(false)}
                onSave={handleSaveSecondaryFilter}
            />
            <PageContainer paddingTop>
                <TableCollapsable
                    cols={columns.filter((x) => !x.hidden)}
                    placeholder={<TableError icon={faUser} />}
                    data={data}
                    title={`${t(TranslationTitles.USERS_TITLE)} (${selectCompany.company.label})`}
                    subTitle={`${total} ${total > 1 ? t(TranslationKeys.USERS) : t(TranslationKeys.USER)}`}
                    isLoading={isLoading}
                    pageIndex={pageIndex}
                    pageSize={PaginationDefaults.PAGE_SIZE}
                    total={total}
                    onChangePageIndex={handlePageIndexChange}
                    sortField={sortField}
                    sortDirection={sortDirection}
                    onChangeSortDirectionField={handleSortChange}
                    onDoubleClick={(row) => navigate(`edit/${row.id}`)}
                    actionButtons={[
                        {
                            icon: faCirclePlus,
                            onClick: () => navigate("new"),
                            hidden: !hasPermissionToAdd(SecScreen.USER_MANAGEMENT),
                            title: t(TranslationCommon.CREATE),
                        },
                        {
                            icon: faMagnifyingGlass,
                            onClick: () => {
                                setShowSearchInput(true);
                            },
                            inputComponent: {
                                component: (
                                    <TableInputText
                                        onChange={onQueryChange}
                                        value={query}
                                        fetch={handleSubmitQuery}
                                        cleanFunction={handleSubmitQuery}
                                    />
                                ),
                                show: showSearchInput,
                            },
                            title: t(TranslationCommon.SEARCH),
                        },
                        {
                            icon: filterIcon,
                            onClick: handleFilterIconClick,
                            inputComponent: {
                                component: (
                                    <TableSelectCompany
                                        onChange={handleCompanyChange}
                                        value={selectCompany.company}
                                        onClickOutside={() => onCompanyChange({ isOpen: false })}
                                    />
                                ),
                                show: selectCompany.isOpen,
                            },
                            title: translateCustomerTypeKeys(TranslationCommon.CHANGE_COMPANY, {
                                firstReplace: "SINGULAR",
                            }),
                        },
                        {
                            icon: faSliders,
                            onClick: () => handleChangeSecondaryFilterVisibility(true),
                            showMarkableIcon: !isEqual(secondaryFilterValues, INITIAL_USER_GRID_SECONDARY_FILTER),
                            title: t(TranslationCommon.FILTER),
                        },
                    ]}
                    onClickExportCsv={handleExport}
                    tabs={tabs}
                    currentTab={currentTab}
                    mobileBody={(row, _i, rowPosition) => (
                        <MobileUserRow key={row.id} user={row} rowPosition={rowPosition} />
                    )}
                />
            </PageContainer>
        </>
    );
};

export default UserGrid;
