import { formatBigNumber } from "app/helpers/Utilities/number/formatBigNumbers";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { FC } from "react";
import { components, OptionProps } from "react-select";

type OptionWithCounterProps = OptionProps<OptionModel>;

export const OptionWithCounter: FC<OptionWithCounterProps> = ({ ...optionsProps }) => {
    const { data, isSelected } = optionsProps;
    const { label, count, value } = data;

    if (value === "-1" || value === "") return <components.Option {...optionsProps}></components.Option>;

    return (
        <components.Option {...optionsProps}>
            <div className={`optionWithCounter ${optionsProps.className}`}>
                {label}
                <span
                    className={`optionWithCounter__counter ${isSelected ? "optionWithCounter__counter--selected" : ""}`}
                >
                    ({formatBigNumber(count || 0)})
                </span>
            </div>
        </components.Option>
    );
};
