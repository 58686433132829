import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { AuditTranslation, TranslationTitles } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { StartAuditModal } from "./components/StartAuditModal/StartAuditModal";
import { faCirclePlus, faListUl, faSliders } from "@fortawesome/pro-regular-svg-icons";
import { TabHeader } from "app/components_v2/__containers/TabHeader/TabHeader";
import { useAuditGrid } from "./hooks/useAuditGrid";
import { TabPropsReduced, Tabs } from "app/components_v2/Tabs";
import { useSession, useTitle, useWindowSize } from "app/hooks";
import { AuditPillList } from "./components/AuditPillList/AuditPillList";
import { Icon } from "app/components_v2/Icon/Icon";
import { AuditFilter } from "./components/AuditFilter/AuditFilter";
import { AuditGridTabRecord, INITIAL_AUDIT_GRID_SECONDARY_FILTER } from "../constants/auditGridContants";
import { AuditHeader } from "./components/AuditHeader/AuditHeader";
import { PaginationDefaults, screenSize, SecScreen } from "app/shared/Constants";
import { AuditSelector } from "./components/AuditSelector/AuditSelector";
import { AddNewButton } from "app/components_v2/__buttons/AddNewButton/AddNewButton";
import { EditAuditLegendModal } from "../EditAudit/EditAuditLegendModal/EditAuditLegendModal";
import { hasPermissionToAdd } from "app/routes/HelperRoleBasedAccess";

export const AuditGrid = () => {
    const { t } = useTranslation();
    const session = useSession();
    const { width } = useWindowSize();

    useTitle(t(TranslationTitles.AUDIT_PAGE_TITLE));

    const {
        createNewModalIsOpen,
        currentTab,
        data,
        handleNavigate,
        handlePageIndexChange,
        isLoading,
        pageIndex,
        selectCompany,
        setCreateNewModalIsOpen,
        total,
        handleCurrentTabChange,
        counters,
        secondaryFilterValues,
        isSecondaryFilterOpen,
        setIsSecondaryFilterOpen,
        handleSaveSecondaryFilter,
        onChangeFilterButton,
        currentFilterButton,
        sortDirection,
        sortField,
        onChangeOrderBy,
        onCreateNewAudit,
        isLegendModalOpen,
        setIsLegendModalOpen,
    } = useAuditGrid();

    const tabs: TabPropsReduced[] = [
        {
            onClick: handleCurrentTabChange,
            rightCount: counters.inProgressCounter,
            text: t(AuditTranslation.AUDIT_IN_PROGRESS_TAB),
            type: "dark",
            hidden: true,
            children: (
                <AuditPillList
                    isLoading={isLoading}
                    data={data}
                    handleNavigate={handleNavigate}
                    onChangePageIndex={handlePageIndexChange}
                    pageIndex={pageIndex}
                    pageSize={PaginationDefaults.PAGE_SIZE}
                    total={total}
                    currentTab={0}
                />
            ),
        },
        {
            onClick: handleCurrentTabChange,
            rightCount: counters.completedCounter,
            text: t(AuditTranslation.AUDIT_IN_REVIEW),
            type: "dark",
            hidden: currentFilterButton === "IN_PROGRESS",
            children: (
                <AuditPillList
                    isLoading={isLoading}
                    data={data}
                    handleNavigate={handleNavigate}
                    onChangePageIndex={handlePageIndexChange}
                    pageIndex={pageIndex}
                    pageSize={PaginationDefaults.PAGE_SIZE}
                    total={total}
                    currentTab={1}
                />
            ),
        },
        {
            onClick: handleCurrentTabChange,
            rightCount: counters.reviewedCounter,
            text: t(AuditTranslation.AUDIT_REVIEWED),
            type: "dark",
            hidden: currentFilterButton === "IN_PROGRESS",
            children: (
                <AuditPillList
                    isLoading={isLoading}
                    data={data}
                    handleNavigate={handleNavigate}
                    onChangePageIndex={handlePageIndexChange}
                    pageIndex={pageIndex}
                    pageSize={PaginationDefaults.PAGE_SIZE}
                    total={total}
                    currentTab={2}
                />
            ),
        },
        {
            onClick: handleCurrentTabChange,
            rightCount: counters.closedCounter,
            text: t(AuditTranslation.AUDIT_CLOSED_TAB),
            type: "dark",
            hidden: currentFilterButton === "IN_PROGRESS",
            children: (
                <AuditPillList
                    isLoading={isLoading}
                    data={data}
                    handleNavigate={handleNavigate}
                    onChangePageIndex={handlePageIndexChange}
                    pageIndex={pageIndex}
                    pageSize={PaginationDefaults.PAGE_SIZE}
                    total={total}
                    currentTab={3}
                />
            ),
        },
    ];

    return (
        <>
            {isLegendModalOpen && <EditAuditLegendModal onClose={() => setIsLegendModalOpen(false)} />}
            {createNewModalIsOpen && (
                <StartAuditModal onClose={() => setCreateNewModalIsOpen(false)} onSubmit={onCreateNewAudit} />
            )}
            <PageContainer
                fullHeight
                header={
                    <TabHeader
                        tabs={
                            <>
                                <Tabs tabs={tabs} currentTab={currentTab} hideChildren />
                                <div className="auditOrderFilterContainer">
                                    <Icon
                                        icon={faSliders}
                                        onClick={() => setIsSecondaryFilterOpen(true)}
                                        theme="dark"
                                    />
                                </div>
                            </>
                        }
                        variant="appcc"
                    >
                        <AuditFilter
                            filterData={secondaryFilterValues}
                            initialFilterData={{
                                ...INITIAL_AUDIT_GRID_SECONDARY_FILTER,
                                companyId: session?.workingCompany?.companyId
                                    ? String(session?.workingCompany?.companyId)
                                    : "-1",
                                companyName: session?.workingCompany?.companyName,
                            }}
                            isOpen={isSecondaryFilterOpen}
                            isSecondaryFilterOpen={isSecondaryFilterOpen}
                            onCancel={() => setIsSecondaryFilterOpen(false)}
                            onSave={handleSaveSecondaryFilter}
                            currentTab={AuditGridTabRecord[currentTab]}
                            variant="appcc"
                        />
                        <AuditHeader
                            onClick={onChangeFilterButton}
                            currentFilterButton={currentFilterButton}
                            counters={{
                                DONE: counters.reviewedCounter + counters.completedCounter + counters.closedCounter,
                                IN_PROGRESS: counters.inProgressCounter,
                            }}
                            title={`${t(TranslationTitles.AUDIT_GRID_TITLE)} (${selectCompany.company.label})`}
                        />
                    </TabHeader>
                }
            >
                <div className="auditGrid">
                    <div className="auditOrderFilterContainer">
                        <AuditSelector
                            sortDirection={sortDirection}
                            sortField={sortField}
                            onSortChange={onChangeOrderBy}
                        />
                        <div className="auditOrderFilterContainer__buttons">
                            <AddNewButton onClick={() => setIsLegendModalOpen(true)} variant="appcc" icon={faListUl}>
                                {width >= screenSize.MOBILE ? t(AuditTranslation.AUDIT_SHOW_LEGEND) : ""}
                            </AddNewButton>
                            {hasPermissionToAdd(SecScreen.AUDIT_INST_MANAG) && (
                                <AddNewButton
                                    onClick={() => setCreateNewModalIsOpen(true)}
                                    variant="appcc"
                                    icon={faCirclePlus}
                                >
                                    {width >= screenSize.MOBILE ? t(AuditTranslation.AUDIT_OPEN_AUDIT) : ""}
                                </AddNewButton>
                            )}
                        </div>
                    </div>
                    <div className="auditGrid__list">{tabs[currentTab].children}</div>
                </div>
            </PageContainer>
        </>
    );
};
