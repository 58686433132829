import { ReactNode, useState } from "react";
import { ColumnsType } from "../types";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";

type TableBody<T> = {
    data: T[];
    columns: ColumnsType<T>[];
    pageSize: number;
    placeholder: ReactNode;
    isLoading?: boolean;
    allowCheckbox?: boolean;
    onChangeChecked?: (value: T[]) => void;
    checkedPage?: T[];
    onDoubleClick?: (row: T) => void;
    variant?: TabHeaderVariants;
};

export const TableBody = <T extends object>({
    columns,
    data,
    pageSize,
    placeholder,
    isLoading,
    allowCheckbox,
    checkedPage = [],
    onChangeChecked,
    onDoubleClick,
    variant = "primary",
}: TableBody<T>) => {
    const [selectedRow, setSelectedRow] = useState<number>();
    return (
        <>
            {!!data.length ? (
                data.map((row, i) => (
                    <tr
                        key={i}
                        className={`tableBody${
                            selectedRow === i ||
                            //@ts-ignore
                            !!checkedPage.find((checkedItem) => row?.id === checkedItem?.id)
                                ? `--${variant}--focused`
                                : ""
                        }`}
                        onClick={() => setSelectedRow(i === selectedRow ? undefined : i)}
                        onDoubleClick={() => onDoubleClick && onDoubleClick(row)}
                    >
                        {allowCheckbox && (
                            <td className={`tableBody__row`}>
                                <CheckBox
                                    checked={
                                        !!checkedPage.find(
                                            //@ts-ignore
                                            (checkedItem) => row?.id === checkedItem?.id
                                        )
                                    }
                                    onChange={(checked) => {
                                        if (!checked) {
                                            const value = checkedPage.filter((check) => {
                                                //@ts-ignore
                                                return row?.id !== check?.id;
                                            });
                                            onChangeChecked && onChangeChecked(value);
                                            return;
                                        }
                                        //@ts-ignore
                                        onChangeChecked && onChangeChecked([...checkedPage, row]);
                                    }}
                                />
                            </td>
                        )}
                        {columns.map((col, colIndex) => (
                            <td key={colIndex} className={`tableBody__row`}>
                                {col.render(row, i)}
                            </td>
                        ))}
                    </tr>
                ))
            ) : (
                <>
                    {!isLoading && (
                        <tr>
                            <td
                                className="tableBody__placeholder"
                                colSpan={allowCheckbox ? columns.length + 1 : columns.length}
                            >
                                {placeholder}
                            </td>
                        </tr>
                    )}
                </>
            )}
            {data.length < pageSize && !!data.length && <tr></tr>}
        </>
    );
};
