import { convertUTCtoLocaleDate, getFormatedFullDateAndHours } from "app/helpers/Utilities/date/Date.utilities";
import { TaskReviewUser } from "app/models/02-TAR/TaskReview";
import { ReviewCarrouselItemModel } from "app/pages/02-TAR/02-TAR-ReviewFotos/models";

export const ReviewUserMapper = (user: TaskReviewUser[], offset: number): ReviewCarrouselItemModel[] => {
    return user.map(({ id, firstName, lastName, profilePictureURL, ojito, customerInstanceId, scannedDate }) => {
        return {
            id: id,
            text: `${firstName} ${lastName}`,
            img: profilePictureURL || "",
            notSeenNoti: ojito,
            customerInstanceId: customerInstanceId || 0,
            scannedDate: !!scannedDate?.length
                ? getFormatedFullDateAndHours(convertUTCtoLocaleDate(new Date(scannedDate), offset))
                : null,
        };
    });
};
