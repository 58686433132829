import { SelectWithDisplay, SelectWithDisplayItems } from "app/components_v2/SelectWithDisplay";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { ReportDataList, ReportType } from "app/models/02-TAR/TaskWizard";
import { TranslationKeys } from "app/translation/translationKeys";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { FieldValues } from "../SelectDataToReport";
import { Icon } from "app/components_v2/Icon/Icon";
import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { PreviewAssetsModal } from "app/components_v2/__modals/PreviewAssetsModal/PreviewAssetsModal";
import { mapReportDataToAssetPreview } from "./helpers/mapReportDataToAssetPreview";

type SelectDataToReportAssetProps = {
    selectedValue: ReportType;
    isLoading: boolean;
    isErrored: boolean;
    options: OptionModel[];
    customError?: string;
    items: SelectWithDisplayItems[];
    reportData: ReportDataList[];
    disabled?: boolean;
    onDeleteAsset: (id: number) => void;
    onChange: (field: Partial<FieldValues>) => void;
};

export const SelectDataToReportAsset: FC<SelectDataToReportAssetProps> = ({
    selectedValue,
    isErrored,
    isLoading,
    items,
    onDeleteAsset,
    options,
    customError,
    onChange,
    reportData,
    disabled,
}) => {
    const { t } = useTranslation();

    const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);

    const assetsFiltered = reportData.filter(({ isDeleted }) => !isDeleted);
    return (
        <>
            {isPreviewModalVisible && (
                <PreviewAssetsModal
                    assets={mapReportDataToAssetPreview(reportData)}
                    onClose={() => setIsPreviewModalVisible(false)}
                />
            )}
            <div className="selectDataToReportAsset">
                <div className="selectDataToReportAsset__header">
                    <h2 className="selectDataToReportAsset__title">
                        {selectedValue === "ASSET" ? t(TranslationKeys.ASSETS) : t(TranslationKeys.ASSETS_RANGE)}
                    </h2>
                    {!!assetsFiltered.length && (
                        <Icon icon={faEye} theme="light" onClick={() => setIsPreviewModalVisible(true)} />
                    )}
                </div>
                <SelectWithDisplay
                    isLoading={isLoading}
                    isErrored={!!isErrored}
                    options={options}
                    customError={customError}
                    items={items}
                    onClick={onDeleteAsset}
                    onChange={({ value }) =>
                        onChange({
                            selectableValue: selectedValue,
                            fieldValue: { id: value, value },
                        })
                    }
                    value={t(TranslationKeys.ASSET_SELECT_OPTION)}
                    placeholder={t(TranslationKeys.ASSET_SELECT_OPTION)}
                    reportData={reportData}
                    disabled={disabled}
                />
            </div>
        </>
    );
};
