import { FC } from "react";
import { CellPhoto } from "app/components_v2/Table/CellPhoto/CellPhoto";
import { convertUTCtoLocaleDate, getFullDateWithTimeReadable } from "app/helpers/Utilities/date/Date.utilities";
import { CellWithAvatar } from "app/components_v2/Table/CellWithAvatar/CellWithAvatar";
import { AuditInstanceListDto } from "app/dtos/05-QUA/AuditInstance/AuditInstanceListDto";
import { MultiProgressBar, MultiProgressBarItemProp } from "app/components_v2/MultiProgressBar/MultiProgressBar";
import { UserValoration } from "app/components_v2/UserValoration/UserValoration";
import { Badge } from "app/components_v2/Badge/Badge";
import { multiplyAndFormat } from "app/helpers/Utilities/number/multiplyAndFormat";
import { AuditPillStars } from "./AuditPillStars/AuditPillStars";
import { useWindowSize } from "usehooks-ts";
import { screenSize } from "app/shared/Constants";

type AuditPillProps = {
    auditInstance: AuditInstanceListDto;
    onPillClick: () => void;
};
export const AuditPill: FC<AuditPillProps> = ({ auditInstance, onPillClick }) => {
    const { width } = useWindowSize();

    const {
        closeDate,
        openDate,
        auditInstanceCheckListItemsTotal,
        auditName,
        companyLogoUrl,
        companyName,
        grade,
        openBy,
        openByProfilePictureUrl,
        openByUserFullName,
        starAvg,
        status,
        closeBy,
        closeByProfilePictureUrl,
        closeByUserFullName,
    } = auditInstance;
    const pillDate = closeDate
        ? getFullDateWithTimeReadable(convertUTCtoLocaleDate(new Date(closeDate)).toString() || "") + "h"
        : getFullDateWithTimeReadable(convertUTCtoLocaleDate(new Date(openDate)).toString() || "") + "h";

    const { completedTotal, nonConformityTotal, notApplyTotal, notSpecifiedTotal } = auditInstanceCheckListItemsTotal;
    const multipleProgressBarItems: MultiProgressBarItemProp[] = [
        { color: "green", value: completedTotal },
        { color: "yellow", value: notSpecifiedTotal },
        { color: "red", value: nonConformityTotal },
        { color: "gray", value: notApplyTotal },
    ];
    return (
        <div className="auditPill" onClick={onPillClick}>
            <div className={`auditPill__section${status !== "IN_PROGRESS" ? "--center" : ""}`}>
                <div className="auditPill__header">
                    <p className={`auditPill__section__title text_clamp`}>{auditName}</p>
                    {grade != null && width <= screenSize.MOBILE && <AuditPillStars grade={grade} starAvg={starAvg} />}
                </div>
                <div className="auditPill__section__progressBar">
                    <MultiProgressBar items={multipleProgressBarItems.filter(({ value }) => value > 0)} />
                </div>
            </div>
            {grade != null && width > screenSize.MOBILE && (
                <div className="auditPill__valoration">
                    <AuditPillStars grade={grade} starAvg={starAvg} />
                </div>
            )}
            <div className="auditPill__section--row">
                <div className="auditPill__section__item">
                    <CellPhoto name={companyName} img={companyLogoUrl} size="xs" />
                </div>
                <div className="auditPill__section__item">
                    <CellWithAvatar
                        avatarName={status === "IN_PROGRESS" ? openByUserFullName : closeByUserFullName}
                        colorId={status === "IN_PROGRESS" ? openBy : closeBy || 0}
                        badgeTitle={pillDate}
                        badgeVariant="none"
                        img={status === "IN_PROGRESS" ? openByProfilePictureUrl : closeByProfilePictureUrl || undefined}
                        avatarSize="sm"
                    />
                </div>
            </div>
        </div>
    );
};
