import { QRManagementDto } from "app/dtos/01-SEG/QRManagementDto/QRManagementDto";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useSuccessManager } from "app/hooks/SuccessHandler/useSuccessManager";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import QRManagementService from "app/services/01-SEG/QRManagementService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QRManagementGridModel } from "../models/QRManagementModels";
import { QRTranslations } from "app/translation/translationKeys";
import { convertUTCtoLocaleDate } from "app/helpers/Utilities/date/Date.utilities";
import { CloseQRModel } from "app/models/01-SEG/CloseQRModel/CloseQRModel";

type UseQRGridProps = {
    companyId: number;
};

export const useQRGrid = ({ companyId }: UseQRGridProps) => {
    const { t } = useTranslation();
    const { getErrorMessage } = useFetchErrors();
    const { handleErrorManager } = useErrorManager();
    const { handleSuccessManager } = useSuccessManager();

    const [QRList, setQRList] = useState<QRManagementDto[]>([]);
    const [closeQRList, setCloseQRList] = useState<CloseQRModel[]>([]);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [query, setQuery] = useState<string>("");
    const [total, setTotal] = useState<number>(0);
    const [showSearchInput, setShowSearchInput] = useState<boolean>(!!query.length);
    const [selectedQR, setSelectedQR] = useState<QRManagementDto | null>(null);
    const [showCloseQRModal, setShowCloseQRModal] = useState<boolean>(false);
    const [isLoadingCloseQR, setIsLoadingCloseQR] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getData = async ({ pi }: QRManagementGridModel = {}) => {
        setIsLoading(true);

        const pageIndexFetch = pi !== undefined ? pi : pageIndex - 1;

        const { data, status, getParsedError } = await QRManagementService.GetData({
            extraParams: `companyId=${companyId}`,
            pageIndex: pageIndexFetch,
            query,
        });
        if (handleErrorManager(status(), getErrorMessage(getParsedError()))) {
            setTotal(0);
            setQRList([]);
            return;
        }

        setQRList(data.list);
        setTotal(data.count);
        setIsLoading(false);
    };

    const getQRList = async (id: number) => {
        setIsLoadingCloseQR(true);

        const { data, status, getParsedError } = await QRManagementService.GetClosedQRList(id);
        if (handleErrorManager(status(), getErrorMessage(getParsedError()))) {
            setCloseQRList([]);
            setIsLoadingCloseQR(false);
            return;
        }

        setCloseQRList(data.list);
        setIsLoadingCloseQR(false);
    };

    const onChangePageIndex = (pi: number) => {
        setPageIndex(pi);
        getData({ pi: pi - 1 });
    };

    const onSearch = () => {
        setShowSearchInput(false);
        getData({ pi: 0 });
        onChangePageIndex(1);
    };

    const openCloseQRModal = async (qr: QRManagementDto) => {
        setSelectedQR(qr);
        getQRList(qr.id);
        setShowCloseQRModal(true);
    };

    const onCloseCloseQR = () => {
        setSelectedQR(null);
        setShowCloseQRModal(false);
        setCloseQRList([]);
    };

    const onSubmitCloseQR = async () => {
        if (!selectedQR) return;
        setIsLoadingCloseQR(true);

        const { id } = selectedQR;
        const { status, getParsedError } = await QRManagementService.Edit(id, closeQRList ?? []);
        if (handleErrorManager(status(), getErrorMessage(getParsedError()))) {
            setIsLoadingCloseQR(false);
            return;
        }

        const successMessage =
            closeQRList == null || !closeQRList.length
                ? t(QRTranslations.OPEN_QR_SUCCESSFULLY)
                : t(QRTranslations.CLOSED_QR_SUCCESSFULLY);
        handleSuccessManager(successMessage);
        getData();
        setShowCloseQRModal(false);
        setIsLoadingCloseQR(false);
    };

    const onClosingDates = (dates: Date[] | undefined[], index: number) => {
        if (!selectedQR) return;

        if (!closeQRList) return;
        if (!dates) return;

        const [start, end] = dates;

        setCloseQRList(
            closeQRList.map((cc, i) => {
                if (i === index)
                    return { ...cc, closeDate: start ? convertUTCtoLocaleDate(start) : null, openDate: null };
                return cc;
            })
        );
        if (end)
            setCloseQRList(
                closeQRList.map((cc, i) => {
                    if (i === index)
                        return {
                            ...cc,
                            closeDate: start ? convertUTCtoLocaleDate(start) : null,
                            openDate: end ? convertUTCtoLocaleDate(end) : null,
                        };
                    return cc;
                })
            );
    };

    const onAddNewRangeHour = () => {
        if (!selectedQR) return;

        if (!closeQRList.length) {
            setCloseQRList([{ id: 0, fK_WorkingPosition: selectedQR.id, closeDate: new Date(), openDate: new Date() }]);
            return;
        }
        setCloseQRList((prev) => [
            ...prev,
            { id: 0, fK_WorkingPosition: selectedQR.id, closeDate: new Date(), openDate: new Date() },
        ]);
    };

    const onDeleteNewRangeHour = (index: number) => {
        if (!selectedQR) return;
        const filteredCloseQR = closeQRList?.filter((_, i) => i !== index);
        setCloseQRList(!!filteredCloseQR.length ? filteredCloseQR : []);
    };

    useEffect(() => {
        getData();
    }, []);

    return {
        closeQRList,
        isLoading,
        isLoadingCloseQR,
        onAddNewRangeHour,
        onChangePageIndex,
        onCloseCloseQR,
        onClosingDates,
        onDeleteNewRangeHour,
        onSearch,
        onSubmitCloseQR,
        openCloseQRModal,
        pageIndex,
        QRList,
        query,
        setQuery,
        setShowSearchInput,
        showCloseQRModal,
        showSearchInput,
        total,
        selectedQR,
    };
};
