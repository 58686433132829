import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSession } from "app/hooks/useSession";
import { useAuditStart } from "../tabs/AuditStart/hook/useAuditStart";
import { useAuditIssues } from "../tabs/AuditIssues/hook/useAuditIssues";
import { useAuditReport } from "../tabs/AuditReport/hooks/useAuditReport";
import { useEditAuditHeader } from "./useEditAuditHeader";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";
import { SecScreen } from "app/shared/Constants";
import { hasPermissionToEdit, hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { AuditInstanceStatus } from "app/shared/types/AuditInstanceTypes";
import { AuditTranslation, TranslationKeys } from "app/translation/translationKeys";
import { AuditStart } from "../tabs/AuditStart/AuditStart";
import { AuditIssues } from "../tabs/AuditIssues/AuditIssues";
import { AuditReport } from "../tabs/AuditReport/AuditReport";
import { TabPropsReduced } from "app/components_v2/Tabs/Tabs";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { INITIAL_AUDIT_REPORT_FILTER_DATA } from "../tabs/AuditReport/constants/AuditReportConstants";
import { AuditReportSFModel } from "../tabs/AuditReport/models/AuditReportSFModel";
import { faListUl } from "@fortawesome/pro-regular-svg-icons";

type UseEditAuditParams = {
    auditInstanceId: number;
    actualStatus: AuditInstanceStatus;
};

export const useEditAudit = ({ auditInstanceId, actualStatus }: UseEditAuditParams) => {
    const session = useSession();
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { changeWorkingCompanyByCompanyId } = useChangeWorkingSession();
    const nav = useNavigate();
    const tab = searchParams.get("tab");
    const companyId = searchParams.get("companyId");
    const auditId = searchParams.get("auditId");

    const {
        isAuditStartSubmiting,
        isAuditStartConfirmModalOpen,
        setAuditStartIsConfirmModalOpen,
        confirmAuditStartModalSubmit,
        handleAuditStartConfirmModalOpen,
        isAuditStartLoading,
        fetchAuditStartData,
        auditStartData,
        setAuditStartData,
    } = useAuditStart({ auditInstanceId, actualStatus });

    const {
        fetchAuditIssuesData,
        isAuditIssuesLoading,
        auditIssuesList,
        auditIssuesPageIndex,
        setAuditIssuesPageIndex,
        totalAuditIssueList,
        handleAuditIssuesConfirmModalOpen,
        confirmAuditIssuesModalSubmit,
        isAuditIssuesConfirmModalOpen,
        setAuditIssuesIsConfirmModalOpen,
        isCompleting,
        isCloseAuditModalOpen,
        isClosing,
        setIsCloseAuditModalOpen,
        handleCloseAudit,
    } = useAuditIssues({ auditInstanceId, actualStatus });

    const { isEditAuditHeaderLoading, editAuditHeaderData, fetchEditAuditHeaderData } =
        useEditAuditHeader(auditInstanceId);

    const hideTab1 = !hasPermissionToView(session, SecScreen.AUDIT_INST_REV_NC);
    const hideTab2 = !hasPermissionToView(session, SecScreen.AUDIT_INST_REPORT) || actualStatus === "IN_PROGRESS";
    const hideTab0 = hideTab1 && hideTab2;
    const initialTabsToBeInSecondTab: AuditInstanceStatus[] = ["REVIEWED", "COMPLETED"];
    const initialCurrentTab = !!tab
        ? // Check Url Tab
          tab === "1" && !hideTab1
            ? 1
            : tab === "2" && !hideTab2
            ? 2
            : 0
        : // Set Default Tab
        initialTabsToBeInSecondTab.includes(actualStatus) && !hideTab1
        ? 1
        : 0;
    const [currentTab, setCurrentTab] = useState(initialCurrentTab);
    const [filterData, setFilterData] = useState<AuditReportSFModel>(INITIAL_AUDIT_REPORT_FILTER_DATA);
    const [isSFOpen, setIsSFOpen] = useState<boolean>(false);
    const [isLegendModalOpen, setIsLegendModalOpen] = useState<boolean>(false);

    const { auditHistory, auditInstance, isLoading, fetchAuditReportData, renderAuditStars } = useAuditReport({
        auditInstanceId,
        actualStatus,
        auditId: editAuditHeaderData?.fK_Audit,
        companyId: editAuditHeaderData?.fK_Company,
    });

    const handleCurrentTabChange = (tab: number) => {
        if (tab === currentTab) return;
        setSearchParams("");
        setCurrentTab(tab);
        getAuditDetailData(tab);
    };

    const handlePrintAuditReport = () => {
        const printExtraParams = new URLSearchParams();
        if (editAuditHeaderData?.fK_Audit !== undefined)
            printExtraParams.append("auditId", editAuditHeaderData?.fK_Audit.toString());
        if (editAuditHeaderData?.fK_Company !== undefined)
            printExtraParams.append("companyId", editAuditHeaderData?.fK_Company.toString());
        if (!!filterData.baseAuditGroupCheckListItemTypeId.length)
            printExtraParams.append(
                "baseAuditGroupCheckListItemTypeId",
                filterData.baseAuditGroupCheckListItemTypeId.toString()
            );
        if (!!filterData.baseAuditGroupCheckListItemTypeLabel.length)
            printExtraParams.append(
                "baseAuditGroupCheckListItemTypeLabel",
                filterData.baseAuditGroupCheckListItemTypeLabel.toString()
            );
        nav(`print?${printExtraParams.toString()}`);
    };

    const tabs: TabPropsReduced[] = [
        {
            onClick: () => handleCurrentTabChange(0),
            rightCount: editAuditHeaderData?.auditCounter,
            text: t(AuditTranslation.AUDIT_TITLE),
            type: "dark",
            children: (
                <AuditStart
                    auditInstanceId={auditInstanceId}
                    actualStatus={actualStatus}
                    isAuditInstanceLoading={isAuditStartLoading}
                    auditInstance={auditStartData}
                    setAuditInstance={setAuditStartData}
                    fetchEditAuditHeaderData={fetchEditAuditHeaderData}
                />
            ),
            hidden: hideTab0,
        },
        {
            onClick: () => handleCurrentTabChange(1),
            rightCount: editAuditHeaderData?.issueCounter,
            text: t(AuditTranslation.AUDIT_NON_CONFORMITY),
            type: "dark",
            children: (
                <AuditIssues
                    fetchData={fetchAuditIssuesData}
                    isLoading={isAuditIssuesLoading}
                    issueList={auditIssuesList}
                    pageIndex={auditIssuesPageIndex}
                    setPageIndex={setAuditIssuesPageIndex}
                    totalIssueList={totalAuditIssueList}
                    fetchEditAuditHeaderData={fetchEditAuditHeaderData}
                    auditStatus={actualStatus}
                />
            ),
            hidden: hideTab1,
        },
        {
            onClick: () => handleCurrentTabChange(2),
            rightCount: 0,
            text: t(AuditTranslation.AUDIT_REPORT),
            type: "dark",
            children: (
                <AuditReport
                    auditInstance={auditInstance}
                    auditHistory={auditHistory}
                    isLoading={isLoading}
                    auditStarsNode={renderAuditStars}
                />
            ),
            hidden: hideTab2,
        },
    ];

    const footers: {
        show: boolean;
        footer: ReactNode;
    }[] = [
        {
            show: actualStatus === "IN_PROGRESS" && hasPermissionToEdit(SecScreen.AUDIT_INST_MANAG),
            footer: (
                <GenericModalFooter
                    confirmButton={{
                        text: t(TranslationKeys.AUDIT_FINISH),
                        type: "submit",
                        onClick: handleAuditStartConfirmModalOpen,
                        variant: "appcc",
                    }}
                    closeButton={{
                        text: t(AuditTranslation.AUDIT_SHOW_LEGEND),
                        onClick: () => setIsLegendModalOpen(true),
                        variant: "appcc",
                        buttonType: "link",
                        iconLeft: faListUl,
                    }}
                    loading={isAuditStartSubmiting}
                />
            ),
        },
        {
            show:
                ((actualStatus === "IN_PROGRESS" || actualStatus === "REVIEWED") &&
                    hasPermissionToEdit(SecScreen.AUDIT_INST_MANAG)) ||
                (actualStatus === "COMPLETED" && hasPermissionToEdit(SecScreen.AUDIT_INST_REV_NC)),
            footer: (
                <GenericModalFooter
                    confirmButton={{
                        text:
                            actualStatus === "IN_PROGRESS"
                                ? t(TranslationKeys.AUDIT_FINISH)
                                : actualStatus === "COMPLETED"
                                ? t(AuditTranslation.AUDIT_FINISH_REVIEW)
                                : t(AuditTranslation.AUDIT_CLOSE_AUDIT),
                        type: "submit",
                        onClick:
                            actualStatus === "IN_PROGRESS"
                                ? handleAuditStartConfirmModalOpen
                                : actualStatus === "COMPLETED"
                                ? handleAuditIssuesConfirmModalOpen
                                : () => setIsCloseAuditModalOpen(true),
                        variant: "appcc",
                    }}
                    closeButton={{
                        text: t(AuditTranslation.AUDIT_SHOW_LEGEND),
                        onClick: () => setIsLegendModalOpen(true),
                        variant: "appcc",
                        buttonType: "link",
                        iconLeft: faListUl,
                    }}
                    loading={isAuditStartSubmiting || isCompleting || isClosing}
                />
            ),
        },
    ];

    const onSFChange = async (values: AuditReportSFModel) => {
        setFilterData(values);
        setIsSFOpen(false);
        await fetchAuditReportData(
            Number(companyId),
            Number(auditId),
            Number(values.baseAuditGroupCheckListItemTypeId)
        );
    };

    const getAuditDetailData = async (tab: number) => {
        fetchEditAuditHeaderData();
        if (tab === 0) await fetchAuditStartData();
        if (tab === 1) await fetchAuditIssuesData({});
        if (tab === 2)
            await fetchAuditReportData(
                Number(companyId),
                Number(auditId),
                Number(filterData.baseAuditGroupCheckListItemTypeId)
            );
    };

    useEffect(() => {
        if (!!companyId) changeWorkingCompanyByCompanyId(Number(companyId));
        if (!!tab) {
            setSearchParams("");
            if (Number(tab) !== currentTab) setCurrentTab(Number(tab));
            getAuditDetailData(Number(tab));
            return;
        }
    }, [tab, filterData.baseAuditGroupCheckListItemTypeId, auditInstanceId]);

    useEffect(() => {
        getAuditDetailData(currentTab);
    }, []);

    return {
        auditInstance,
        confirmAuditIssuesModalSubmit,
        confirmAuditStartModalSubmit,
        currentTab,
        editAuditHeaderData,
        filterData,
        footers,
        handleCloseAudit,
        handlePrintAuditReport,
        isAuditIssuesConfirmModalOpen,
        isAuditStartConfirmModalOpen,
        isCloseAuditModalOpen,
        isEditAuditHeaderLoading,
        isSFOpen,
        onSFChange,
        onSFOpen: setIsSFOpen,
        renderAuditStars,
        setAuditIssuesIsConfirmModalOpen,
        setAuditStartIsConfirmModalOpen,
        setIsCloseAuditModalOpen,
        tabs,
        setIsLegendModalOpen,
        isLegendModalOpen,
    };
};
