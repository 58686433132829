import { BaseTaskFormModel } from "../models/EditBaseTaskFormModels";
import { BaseTaskFormModelToBaseTaskModel } from "../../helpers/BaseTaskFormModelToBaseTaskModel";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { getMonthName } from "app/helpers/Utilities/date/Date.utilities";
import { hasPermissionToAdd, hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { INITIAL_DATA_BASE_TASK_MODEL } from "../../EditBaseTask/constants/editBaseTaskConstants";
import { isHoursOutOfRange } from "app/helpers/__validates/task/validateTaskHours";
import { mapBaseTaskToDataTaskModel } from "../../helpers/mappers";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { PrivatePaths, SecScreen } from "app/shared/Constants";
import { TranslationModals } from "app/translation/translationKeys";
import { useEditBaseTaskErrors } from "./useEditBaseTaskErrors";
import QuaSelectorService from "app/services/05-QUA/QuaSelectorService";
import { useInputTaskData } from "app/pages/02-TAR/07-TAR-CRUD/EditTask/Components/TaskData/hooks/useInputTaskData";
import { useGoBack, useSession } from "app/hooks";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import BaseTaskAPPCCService from "app/services/05-QUA/BaseTaskAPPCCService";
import BaseTaskService from "app/services/02-TAR/BaseTaskService";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import { AssetModel2 } from "app/models/05-QUA/AssetModels/AssetModel2";
import { ASSET_PAGES } from "app/shared/AssetConstants";
import AssetService from "app/services/05-QUA/AssetService";
import { OptionModel } from "app/models/02-TAR/OptionModel";

export const useEditBaseTask = () => {
    const nav = useNavigate();
    const session = useSession();
    const { id } = useParams();
    const { handleToast, removeToast } = useToast();
    const { t } = useTranslation();
    const [urlParams] = useSearchParams();
    const { pathname } = useLocation();
    const { handleErrorManager } = useErrorManager();
    const { getOriginPathname } = useGoBack();

    const requiredPlanAPPCC = pathname.includes(PrivatePaths.BASE_TASK_APPCC_MANAGEMENT);
    const actualBaseService = requiredPlanAPPCC ? BaseTaskAPPCCService : BaseTaskService;

    const [dataTask, setDataTask] = useState<BaseTaskFormModel>(INITIAL_DATA_BASE_TASK_MODEL);
    const [dataTaskCopy, setDataTaskCopy] = useState<BaseTaskFormModel>(INITIAL_DATA_BASE_TASK_MODEL);
    const [baseTaskTypes, setBaseTaskTypes] = useState<OptionsSearch[]>([]);
    const [selectedBaseTaskType, setSelectedBaseTaskType] = useState<OptionsSearch>({
        label: "",
        value: "",
    });
    const [planAPPCCOptions, setPlanAPPCCOptions] = useState<OptionsSearch[]>([]);
    const [selectedPlanAPPCC, setSelectedPlanAPPCC] = useState<OptionsSearch>({
        label: "",
        value: "",
    });
    const [dynamicFieldAssetOptions, setDynamicFieldAssetOptions] = useState<AssetModel2[]>([]);

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showBaseTaskTitleModal, setShowBaseTaskTitleModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [showModalHoursOutOfTime, setShowModalHoursOutOfTime] = useState<boolean>(false);
    const [isLoadingDeleteTaskConfirmModal, setIsLoadingDeleteTaskConfirmModal] = useState<boolean>(false);

    const offset = session?.user.timeZoneOffset;

    const {
        isBaseTaskPopoverLoading,
        onChangeInputValue,
        baseTaskOptions,
        isBaseTaskPopoverVisible,
        setIsBaseTaskPopoverVisible,
        onFocusInputValue,
    } = useInputTaskData({
        hasPlanAPPCC: !!requiredPlanAPPCC,
        onChange: (newValue) => onChange({ taskTitle: newValue }),
        value: dataTask.taskTitle,
        id: id ? Number(id) : undefined,
    });

    const { validate, validations } = useEditBaseTaskErrors(
        dataTask,
        selectedBaseTaskType,
        selectedPlanAPPCC,
        requiredPlanAPPCC
    );

    const isCreatePage = useCallback(() => {
        return pathname.split("/").includes("new");
    }, [pathname]);

    const isDuplicatePage = useCallback(() => {
        return pathname.split("/").includes("duplicate");
    }, [pathname]);

    const isEditPage = useCallback(() => {
        return pathname.split("/").includes("edit");
    }, [pathname]);

    const isDisabled = () => {
        return isCreatePage()
            ? !hasPermissionToAdd(SecScreen.BASE_TASK_MANAGEMENT)
            : !hasPermissionToEdit(SecScreen.BASE_TASK_MANAGEMENT);
    };

    const fetchSelectors = async () => {
        setIsLoading(true);

        const baseTaskTypeSr = await TarSelectorService.GetBaseTaskTypes();

        if (handleErrorManager(baseTaskTypeSr.status(), baseTaskTypeSr.getParsedError())) return;
        setBaseTaskTypes(baseTaskTypeSr.data);

        if (requiredPlanAPPCC) {
            const planAPPCCSr = await QuaSelectorService.GetPlansAPPCC();
            if (handleErrorManager(planAPPCCSr.status(), planAPPCCSr.getParsedError())) return;
            setPlanAPPCCOptions(planAPPCCSr.data);
        }
        setIsLoading(false);
    };

    const fetchAssetDynamicFieldOptions = async (fK_PlanAPPCC?: number) => {
        if (!requiredPlanAPPCC) return [];
        const extraParams = new URLSearchParams();
        extraParams.append("AssetCategory", ASSET_PAGES.ASSET_RANGE);
        extraParams.append("IsDeleted", "false");
        if (fK_PlanAPPCC) extraParams.append("FK_PlanAPPCC", String(fK_PlanAPPCC));
        const assetsSr = await AssetService.GetFullPublicList({
            extraParams: extraParams.toString(),
        });
        const assetOptions = assetsSr.data.list;
        setDynamicFieldAssetOptions(assetOptions);

        return assetOptions;
    };

    const fetchBaseTask = async () => {
        setIsLoading(true);

        const baseTaskSr = await actualBaseService.GetOne(Number(id));
        const baseTaskTypeSr = await TarSelectorService.GetBaseTaskTypes();
        const assetOptions = await fetchAssetDynamicFieldOptions(baseTaskSr.data.fK_PlanAPPCC);

        if (handleErrorManager(baseTaskSr.status(), t(baseTaskSr.getParsedError()))) {
            nav(getOriginPathname());
            return;
        }
        if (handleErrorManager(baseTaskTypeSr.status(), t(baseTaskTypeSr.getParsedError()))) return;

        setBaseTaskTypes(baseTaskTypeSr.data);
        setSelectedBaseTaskType({
            label: baseTaskSr.data.type,
            value: String(baseTaskSr.data.fK_BaseTaskType),
        });

        const formDataTask = mapBaseTaskToDataTaskModel(baseTaskSr.data, assetOptions);
        setDataTask(formDataTask);
        setDataTaskCopy(formDataTask);

        if (requiredPlanAPPCC) {
            const planAPPCCSr = await QuaSelectorService.GetPlansAPPCC();
            if (handleErrorManager(planAPPCCSr.status(), planAPPCCSr.getParsedError())) return;
            setPlanAPPCCOptions(planAPPCCSr.data);
            if (!!baseTaskSr.data.fK_PlanAPPCC && !!baseTaskSr.data.planName?.length)
                setSelectedPlanAPPCC({
                    label: baseTaskSr.data.planName,
                    value: String(baseTaskSr.data.fK_PlanAPPCC),
                });
        }

        setIsLoading(false);
    };

    const handleCancel = async () => {
        !isCreatePage() && fetchBaseTask();
        setDataTask(dataTaskCopy);
        setShowCancelModal(false);
    };

    const handleDelete = async () => {
        setIsLoadingDeleteTaskConfirmModal(true);
        const { status, getParsedError } = await actualBaseService.Delete(Number(id));
        setIsLoadingDeleteTaskConfirmModal(false);

        if (handleErrorManager(status(), getParsedError())) return;

        nav(requiredPlanAPPCC ? PrivatePaths.BASE_TASK_APPCC_MANAGEMENT : PrivatePaths.BASE_TASK_MANAGEMENT);
    };

    const onChange = (data: Partial<BaseTaskFormModel>) => setDataTask((prev) => ({ ...prev, ...data }));

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validate(e)) return;
        if (isHoursOutOfRange(dataTask.taskHours)) {
            setShowModalHoursOutOfTime(true);
            return;
        }

        if (baseTaskOptions.length) {
            setShowBaseTaskTitleModal(true);
            return;
        }
        saveBaseTask();
    };

    const saveBaseTask = () => {
        if (isCreatePage() || isDuplicatePage()) {
            setIsSaving(true);
            handleSaveNewBaseTask();
            return;
        }

        setShowUpdateModal(true);
    };

    const handleSaveNewBaseTask = async () => {
        const saveBaseTask = BaseTaskFormModelToBaseTaskModel({
            data: dataTask,
            selectedBaseTaskType: selectedBaseTaskType.value,
            selectedPlanAPPCC: selectedPlanAPPCC.value,
            offset,
            isDuplicate: isDuplicatePage(),
            fotoExampleId: isDuplicatePage() ? dataTask.fotoExampleId : null,
        });

        const { status, getParsedError, data } = await actualBaseService.Save(saveBaseTask);

        if (handleErrorManager(status(), getParsedError())) {
            setIsSaving(false);
            return;
        }

        handleToast({
            title: requiredPlanAPPCC
                ? t(TranslationModals.TOAST_SAVE_APPCC_BASE_TASK)
                : t(TranslationModals.TOAST_SAVE_BASE_TASK),
            variant: "success",
            type: "alert",
        });

        setIsSaving(false);
        handleNavigate(data);
    };

    const handleNavigate = (id: number) => {
        const prevPath = urlParams.get("prevPath");
        const baseTaskPath = requiredPlanAPPCC
            ? PrivatePaths.BASE_TASK_APPCC_MANAGEMENT
            : PrivatePaths.BASE_TASK_MANAGEMENT;

        const pathToRedirect = prevPath ? `${prevPath}?baseTaskId=${id}` : `/${baseTaskPath}`;
        nav(pathToRedirect);
    };

    const navigateToCreateTask = () => {
        const path = requiredPlanAPPCC ? PrivatePaths.REGISTRY_MANAGEMENT : PrivatePaths.TASK_PAGE;
        nav(`/${path}/fastNew?baseTaskId=${id}`);
    };

    const navigateToDuplicateTask = () => {
        const path = requiredPlanAPPCC ? PrivatePaths.BASE_TASK_APPCC_MANAGEMENT : PrivatePaths.BASE_TASK_MANAGEMENT;
        nav(`/${path}/duplicate/${id}`);
    };

    const handleEditBaseTask = async () => {
        setIsSaving(true);
        setShowUpdateModal(false);
        const toastId = v4();
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        handleToast({
            title: t(TranslationModals.GENERATING_TASKS_TITLE),
            subtitle: t(TranslationModals.GENERATING_TASKS_DESCRIPTION)
                .replace("{0}", String(tomorrow.getDate()))
                .replace("{1}", getMonthName(tomorrow.toString())),
            variant: "primary",
            type: "alert",
            isLoading: true,
            id: toastId,
        });

        const editedBaseTask = BaseTaskFormModelToBaseTaskModel({
            data: dataTask,
            selectedBaseTaskType: selectedBaseTaskType.value,
            selectedPlanAPPCC: selectedPlanAPPCC.value,
            id,
            offset,
            fotoExampleId: dataTaskCopy.fotoExampleId,
        });

        const { status, getParsedError } = await actualBaseService.Edit(Number(id), editedBaseTask);

        if (handleErrorManager(status(), getParsedError())) {
            removeToast(toastId);
            setIsSaving(false);
            return;
        }

        removeToast(toastId);
        handleToast({
            title: requiredPlanAPPCC
                ? t(TranslationModals.TOAST_EDIT_APPCC_BASE_TASK)
                : t(TranslationModals.TOAST_EDIT_BASE_TASK),
            variant: "success",
            type: "alert",
        });
        setIsSaving(false);
        nav(requiredPlanAPPCC ? PrivatePaths.BASE_TASK_APPCC_MANAGEMENT : PrivatePaths.BASE_TASK_MANAGEMENT);
    };

    const handleSubmitWithSameTitle = () => {
        setShowBaseTaskTitleModal(false);
        saveBaseTask();
    };

    const onConfirmModalHoursOutOfTime = () => {
        setShowModalHoursOutOfTime(false);
        saveBaseTask();
    };

    const onCloseModalHoursOutOfTime = () => {
        setShowModalHoursOutOfTime(false);
    };

    const handleSelectAppccPlan = async (fk_PlanOptions: OptionModel) => {
        if (!fk_PlanOptions.value.length) return;
        if (fk_PlanOptions.value === selectedPlanAPPCC.value) return;
        setSelectedPlanAPPCC(fk_PlanOptions);
        const assetOptions = await fetchAssetDynamicFieldOptions(Number(fk_PlanOptions.value));
        setDynamicFieldAssetOptions(assetOptions);
        onChange({
            fK_PlanAPPCC: fk_PlanOptions.value,
            dynamicFields: dataTask.dynamicFields.map((df) => ({
                ...df,
                isDisabled: false,
                dropDownMultipleValues: df.dropDownMultipleValues
                    .map((ddmv) => ({
                        ...ddmv,
                        isDeleted: true,
                        assetsFields: ddmv.assetsFields.map((af) => ({ ...af, isDeleted: true })),
                    }))
                    .filter((ddmv) => ddmv.dbId || !ddmv.isDeleted),
                dropDownMultipleOptions: assetOptions.map((assetOption) => ({
                    id: assetOption.id,
                    label: assetOption.name,
                })),
            })),
        });
    };

    useEffect(() => {
        if (isCreatePage()) {
            fetchSelectors();
            fetchAssetDynamicFieldOptions();
            return;
        }

        fetchBaseTask();
    }, []);

    return {
        showDeleteModal,
        setShowDeleteModal,
        handleDelete,
        showCancelModal,
        setShowCancelModal,
        handleCancel,
        isLoading,
        isCreatePage,
        isDisabled,
        isSaving,
        onSubmit,
        dataTask,
        dataTaskCopy,
        onChange,
        selectedBaseTaskType,
        setSelectedBaseTaskType,
        baseTaskTypes,
        setBaseTaskTypes,
        selectedPlanAPPCC,
        setSelectedPlanAPPCC,
        planAPPCCOptions,
        validations,
        showUpdateModal,
        setShowUpdateModal,
        handleEditBaseTask,
        requiredPlanAPPCC,
        isBaseTaskPopoverLoading,
        onChangeInputValue,
        baseTaskOptions,
        isBaseTaskPopoverVisible,
        setIsBaseTaskPopoverVisible,
        handleSubmitWithSameTitle,
        setShowBaseTaskTitleModal,
        showBaseTaskTitleModal,
        onFocusInputValue,
        navigateToCreateTask,
        showModalHoursOutOfTime,
        onConfirmModalHoursOutOfTime,
        onCloseModalHoursOutOfTime,
        isDuplicatePage,
        isEditPage,
        isLoadingDeleteTaskConfirmModal,
        navigateToDuplicateTask,
        dynamicFieldAssetOptions,
        handleSelectAppccPlan,
    };
};
