import { formatBigNumber } from "app/helpers/Utilities/number/formatBigNumbers";
import { FC, useState } from "react";

export type TabProps = {
    id: number;
    text?: string;
    onClick: (current: number) => void;
    current?: boolean;
    leftCount?: number;
    rightCount?: number;
    type?: "dark" | "transparent";
    size?: "xs" | "md";
    fullWidth?: boolean;
    isLoading?: boolean;
    height?: 40 | 50;
};

export const Tab: FC<TabProps> = ({
    id,
    text,
    onClick,
    current = false,
    leftCount,
    rightCount,
    type,
    size = "xs",
    fullWidth,
    isLoading = false,
    height = 40,
}) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const handleClick = () => {
        if (isLoading) return;
        onClick(id);
    };

    return (
        <div
            className={`tab${type === "dark" ? "--dark" : ""}${current ? "--current" : ""}--${size} ${
                isFocused ? `tab--focus--${size}` : ""
            } ${fullWidth ? "tab--fullWidth" : ""} tab--${height}`}
            onClick={handleClick}
            onMouseDown={() => setIsFocused(true)}
            onMouseUp={() => setIsFocused(false)}
            onMouseLeave={() => setIsFocused(false)}
        >
            <p>
                {!!leftCount && <> ({formatBigNumber(leftCount)})</>} {text && text}
                {!!rightCount && <> ({formatBigNumber(rightCount)})</>}
            </p>
        </div>
    );
};
