import { FC } from "react";
import isiOS from "app/helpers/Mobile/isIos";
import { Button, ButtonProps } from "../Button/Button";

type FloatingButtonProps = ButtonProps & {
    title?: string;
};

export const FloatingButton: FC<FloatingButtonProps> = ({ title, ...buttonProps }) => {
    return (
        <div className={`floatingButton  ${isiOS() ? "floatingButton--ios" : ""}`} title={title}>
            <Button {...buttonProps} />
        </div>
    );
};
