import { FC } from "react";
import { Section, SectionsShowProp } from "../Section/Section";
import { AuditComment } from "app/components_v2/AuditComment/AuditComment";

export const AuditCommentSection: FC<SectionsShowProp> = ({ show }) => {
    return (
        <Section title="Audit Comment" show={show}>
            <AuditComment
                type={"issue"}
                description={"Lorem ipsum dolor sit amet consectetur. Eget molestie odio más información."}
                avatarName={"María Martinez"}
                createdAt={"26/09/2023"}
                onEdit={() => {}}
                onDelete={() => {}}
            />
            <AuditComment
                type={"comment"}
                description={"Lorem ipsum dolor sit amet consectetur. Eget molestie odio más información."}
                avatarName={"María Martinez"}
                createdAt={"26/09/2023"}
                onEdit={() => {}}
                onDelete={() => {}}
                readonly
            />
        </Section>
    );
};
