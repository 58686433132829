import { FC, MouseEvent } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle, faClose, faXmarkCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowSize } from "usehooks-ts";
import { Avatar } from "../Avatar/Avatar";
import { AvatarTagProps, TagClamp, TagSize, TagVariant } from "./types";

type TagProps = {
    chatMode?: "mine" | "other";
    size?: TagSize;
    variant?: TagVariant;
    message: string;
    leftIcon?: IconProp;
    rightIcon?: IconProp;
    onClick?: () => void;
    urlClick?: string;
    onClose?: () => void;
    width?: "auto";
    disabled?: boolean;
    avatar?: AvatarTagProps;
    pointer?: boolean;
    clamp?: TagClamp;
};

export const Tag: FC<TagProps> = ({
    message,
    onClick,
    urlClick,
    onClose,
    chatMode,
    size = "xs",
    leftIcon,
    rightIcon,
    variant = "primary",
    width,
    disabled,
    avatar,
    pointer = true,
    clamp = 1,
}) => {
    const { width: windowWidth } = useWindowSize();
    const handleClose = (e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>) => {
        e.stopPropagation();
        onClose && onClose();
    };

    return urlClick ? (
        <a
            className={`tag--${size} ${chatMode ? `tag--${chatMode}` : ""} tag--${variant} ${
                width && windowWidth > 400 ? "width--auto" : ""
            } ${pointer ? "pointer" : ""}`}
            href={urlClick}
        >
            {leftIcon && <FontAwesomeIcon className={`tag__icon--${variant}`} icon={leftIcon} />}
            <p className={`tag__text--${variant} text_clamp text_clamp-${clamp}`}>{message}</p>
            {onClose && !disabled && (
                <FontAwesomeIcon
                    className={`tag__icon--${variant} ${pointer ? "pointer" : ""}`}
                    onClick={handleClose}
                    icon={faClose}
                />
            )}
        </a>
    ) : (
        <div
            className={`tag--${size} ${chatMode ? `tag--${chatMode}` : ""} tag--${variant} ${
                width && windowWidth > 400 ? "width--auto" : ""
            }`}
            onClick={onClick}
        >
            {leftIcon && <FontAwesomeIcon className={`tag__icon--${variant}`} icon={leftIcon} />}
            {avatar && (
                <Avatar
                    size={avatar.size || "xs"}
                    colorId={
                        !isNaN(Number(avatar.id)) || !avatar.avatarHexColor?.length ? Number(avatar.id) : undefined
                    }
                    nameInitials={avatar.initials}
                    img={avatar.profilePictureURL}
                    avatarName={avatar.name}
                    hexColor={avatar.avatarHexColor}
                />
            )}
            {rightIcon && <FontAwesomeIcon className={`tag__icon--${variant}`} icon={rightIcon} />}
            <div className="tag__textContainer">
                <p className={`tag__text--${variant} text_clamp text_clamp-${clamp}`}>{message}</p>
                {!!avatar?.subtitle?.length && <p className="tag__subtitle">{avatar?.subtitle}</p>}
            </div>
            {onClose && !disabled && (
                <FontAwesomeIcon className={`tag__icon--${variant} pointer`} onClick={handleClose} icon={faClose} />
            )}
            {(variant === "red" || variant === "green") && !disabled && (
                <FontAwesomeIcon
                    className={`tag__icon--${variant} `}
                    icon={variant === "green" ? faCheckCircle : faXmarkCircle}
                />
            )}
        </div>
    );
};
