import { FC } from "react";
import { FilterButton } from "../__buttons/FilterButton/FilterButton";
import { faCircleXmark } from "@fortawesome/pro-regular-svg-icons";
import StarRatingComponent from "../Starsvaloration/StarsValoration";
import airplane from "../../../assets/trend-icons/airplane.svg";
import bicycle from "../../../assets/trend-icons/bicycle.svg";
import car from "../../../assets/trend-icons/car.svg";
import excavator from "../../../assets/trend-icons/excavator.svg";
import helicopter from "../../../assets/trend-icons/helicopter.svg";
import rocket from "../../../assets/trend-icons/rocket.svg";
import scooter from "../../../assets/trend-icons/scooter.svg";

import { Badge } from "../Badge/Badge";
import { faArrowRightLong, faArrowTrendDown, faArrowTrendUp } from "@fortawesome/pro-regular-svg-icons";

import { formatBigNumber } from "app/helpers/Utilities/number/formatBigNumbers";
import { BadgeVariant } from "../Badge/badgeTypes";

export type UserValorationVariantModel = "gray" | "white";

type UserValorationProps = {
    valoration: number;
    timedOut?: number;
    variant?: UserValorationVariantModel;
    total?: string;
    size?: "md" | "xl";
    startSize?: "md" | "xs";
    justify?: "center" | "start" | "end";
    roundedTrend?: number;
    width?: "100" | "auto";
    maxStars?: number;
    showIcons?: boolean;
    showZeroValoration?: boolean;
    showValoration?: boolean;
    showTrend?: boolean;
};

export const UserValoration: FC<UserValorationProps> = ({
    valoration,
    timedOut,
    variant = "white",
    total,
    size = "md",
    startSize = "md",
    justify = "center",
    roundedTrend,
    width = "100",
    maxStars,
    showIcons = true,
    showZeroValoration,
    showValoration = true,
    showTrend = true,
}) => {
    if (!valoration && !showZeroValoration) return null;
    const valorationRounded = Number(valoration.toFixed(1));

    const getIcon = () => {
        switch (true) {
            case valorationRounded < 3:
                return scooter;
            case valorationRounded < 3.5:
                return bicycle;
            case valorationRounded < 4:
                return excavator;
            case valorationRounded < 4.4:
                return car;
            case valorationRounded < 4.5:
                return helicopter;
            case valorationRounded <= 4.8:
                return airplane;
            default:
                return rocket;
        }
    };

    const getBadgeIcon = (trend: number) => {
        if (trend >= 100) return undefined;
        if (trend > 0) return faArrowTrendUp;
        if (trend < 0) return faArrowTrendDown;
        return faArrowRightLong;
    };

    const getBadgeVariant = (trend: number): BadgeVariant => {
        if (trend > 0) return "green";
        if (trend < 0) return "red";
        return "grey";
    };

    const getBadgeTitle = (trend: number): string => {
        if (trend >= 100) return "+99";
        if (trend <= -100) return "-99";
        return `${trend}`;
    };

    return (
        <div className={`userValoration--${justify} userValoration--width-${width}`}>
            {showValoration && (
                <p
                    className={`userValoration__valoration${
                        variant ? `--${variant}` : ""
                    } userValoration__valoration--${size}`}
                >
                    {Number(Math.min(valoration, 5)).toFixed(1)}
                </p>
            )}
            <div className="userValoration__stars">
                <StarRatingComponent rating={valoration} maxStars={maxStars} />
            </div>
            {timedOut && <FilterButton counter={String(timedOut)} iconPosition="left" icon={faCircleXmark} />}
            {showIcons && <img className="userValoration__emoji" src={getIcon()} />}
            {total && (
                <p className={`userValoration__total--${variant}`}>
                    {total !== "0" && `(${formatBigNumber(Number(total))})`}
                </p>
            )}
            {showTrend && roundedTrend != null && (
                <Badge
                    icon={getBadgeIcon(roundedTrend)}
                    variant={getBadgeVariant(roundedTrend)}
                    title={`${getBadgeTitle(roundedTrend)}%`}
                    iconPosition="left"
                    size="sm"
                />
            )}
        </div>
    );
};
