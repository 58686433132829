import { Avatar } from "app/components_v2/Avatar/Avatar";
import { FC } from "react";
import { getReasignedId } from "../../utilities/getReasignedId";
import { getReasignedProfilePicture } from "../../utilities/getReasignedProfilePicture";
import { SelectAssignedModel } from "app/models/02-TAR/TaskPage";
import { TaskListDto } from "app/dtos/02-TAR/Task/TaskListDto";

type ResponsibleAvatarProps = {
    record: TaskListDto;
    tab: SelectAssignedModel;
};

export const ResponsibleAvatar: FC<ResponsibleAvatarProps> = ({ record, tab }) => {
    const {
        activeReassignedTo,
        user,
        workingPosition,
        subdepartment,
        department,
        fK_User,
        fK_WorkingPosition,
        fK_Subdepartment,
        fK_Department,
        departmentAvatarHexColor,
    } = record;

    const reassignedToName = activeReassignedTo?.reassignedToName;

    const avatarConfigMap: Record<
        SelectAssignedModel,
        { avatarName: string; colorId: number; img?: string; hexColor?: string }
    > = {
        all: {
            avatarName: reassignedToName || user || workingPosition || subdepartment || department || "",
            colorId:
                getReasignedId(
                    fK_User || fK_WorkingPosition || fK_Subdepartment || fK_Department,
                    activeReassignedTo
                ) || 0,
            img: getReasignedProfilePicture(record) || undefined,
            hexColor: !fK_WorkingPosition && !fK_User && !!fK_Department ? departmentAvatarHexColor : undefined,
        },
        user: {
            avatarName: reassignedToName || user || "",
            colorId: getReasignedId(fK_User, activeReassignedTo) || 0,
            img: getReasignedProfilePicture(record) || undefined,
        },
        department: {
            avatarName: reassignedToName || department || "",
            colorId: getReasignedId(fK_Department, activeReassignedTo) || 0,
            img: undefined,
            hexColor: departmentAvatarHexColor,
        },
        workingPosition: {
            avatarName: workingPosition || "",
            colorId: fK_WorkingPosition || 0,
            img: undefined,
        },
        subDepartment: {
            avatarName: reassignedToName || subdepartment || "",
            colorId: getReasignedId(fK_Subdepartment, activeReassignedTo) || 0,
            img: undefined,
        },
    };

    const { avatarName, colorId, img, hexColor } = avatarConfigMap[tab] || avatarConfigMap.subDepartment;

    return (
        <Avatar
            avatarName={avatarName}
            colorId={!hexColor?.length ? colorId : undefined}
            hexColor={hexColor}
            name={avatarName}
            size="xs"
            img={img}
        />
    );
};
