import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { faSend } from "@fortawesome/pro-regular-svg-icons";
import { GenericModalFooter } from "../base/GenericModal/GenericModalFooter/GenericModalFooter";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { DateTimePicker } from "app/components_v2/__inputs/DateTimePicker/DateTimePicker";
import { getDateFormattedWithHoursSelects } from "app/helpers/Utilities/date/Date.utilities";

export type ScheduleNotificationModalProps = {
    closeModal: () => void;
    onSubmit: (value: Date) => void;
};

export const ScheduleNotificationModal: FC<ScheduleNotificationModalProps> = ({ closeModal, onSubmit }) => {
    const { t } = useTranslation();

    const minDate = getDateFormattedWithHoursSelects(new Date());
    const [dateTime, setDateTime] = useState<string>(minDate);

    const handleSetNextDayAt8am = () => {
        const nextDay = new Date();
        nextDay.setDate(nextDay.getDate() + 1);
        nextDay.setHours(8, 0, 0, 0);
        setDateTime(getDateFormattedWithHoursSelects(nextDay));
    };

    const handleSetNextDayAt4pm = () => {
        const nextDay = new Date();
        nextDay.setDate(nextDay.getDate() + 1);
        nextDay.setHours(16, 0, 0, 0);
        setDateTime(getDateFormattedWithHoursSelects(nextDay));
    };

    const handleSetNextMondayAt8am = () => {
        const nextMonday = new Date();
        nextMonday.setDate(nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7));
        nextMonday.setHours(8, 0, 0, 0);
        setDateTime(getDateFormattedWithHoursSelects(nextMonday));
    };

    const handleSubmit = () => {
        const newDateTime = new Date(dateTime);
        onSubmit(newDateTime);
        closeModal();
    };

    return (
        <GenericModal
            header={{ title: t(TranslationKeys.SCHEDULE_SEND), onClose: closeModal }}
            size="sm"
            footer={
                <GenericModalFooter
                    confirmButton={{
                        text: t(TranslationKeys.SEND),
                        iconRight: faSend,
                        onClick: handleSubmit,
                    }}
                    closeButton={{
                        text: t(TranslationCommon.CANCEL),
                        onClick: closeModal,
                        buttonType: "tertiary",
                    }}
                />
            }
        >
            <div className="scheduleNotificationModal">
                <div className="scheduleNotificationModal__button" onClick={handleSetNextDayAt8am}>
                    {t(TranslationKeys.SCHEDULE_SEND_NEXT_DAY_MORNING)}
                </div>

                <div className="scheduleNotificationModal__button" onClick={handleSetNextDayAt4pm}>
                    {t(TranslationKeys.SCHEDULE_SEND_NEXT_DAY_AFTERNOON)}
                </div>

                <div className="scheduleNotificationModal__button" onClick={handleSetNextMondayAt8am}>
                    {t(TranslationKeys.SCHEDULE_SEND_NEXT_MONDAY)}
                </div>
                <DateTimePicker value={dateTime} min={minDate} onChange={setDateTime} />
            </div>
        </GenericModal>
    );
};
