import { AuditFilterTranslations, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { AuditGridSFModel } from "../../../state/context/auditGridContext";
import { DateRangePicker } from "app/components_v2/__inputs";
import { FC, useEffect, useState } from "react";
import { getInitials } from "app/helpers/Avatar/getInitials";
import { isEqual } from "lodash";
import { OpenCloseStatusCode } from "app/shared/types/OpenCloseStatusCode";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { SecondaryFilter } from "app/components_v2/SecondaryFilter/SecondaryFilter";
import { SelectOptions } from "app/components_v2/__inputs/SelectOptions/SelectOptions";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { useSession } from "app/hooks/useSession";
import { useTranslation } from "react-i18next";
import QuaSelectorService from "app/services/05-QUA/QuaSelectorService";
import { getDateFormattedSelects } from "app/helpers/Utilities/date/Date.utilities";
import { AuditInstanceStatus } from "app/shared/types/AuditInstanceTypes";

type AuditFilterProps = {
    filterData: AuditGridSFModel;
    initialFilterData: AuditGridSFModel;
    isSecondaryFilterOpen: boolean;
    onCancel?: () => void;
    onSave?: (values: AuditGridSFModel) => void;
    isOpen: boolean;
    currentTab: AuditInstanceStatus;
    variant?: TabHeaderVariants;
};

export const AuditFilter: FC<AuditFilterProps> = ({
    filterData,
    initialFilterData,
    isSecondaryFilterOpen,
    onCancel,
    onSave,
    isOpen,
    currentTab,
    variant = "primary",
}) => {
    const { t } = useTranslation();
    const session = useSession();
    const { translateCompany, translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const [internalFilterData, setInternalFilterData] = useState<AuditGridSFModel>(filterData);
    const [userOptions, setUserOptions] = useState<OptionModel[]>([]);
    const [isUserLoading, setIsUserLoading] = useState<boolean>(true);

    const userLabel: Record<AuditInstanceStatus, string> = {
        IN_PROGRESS: t(AuditFilterTranslations.AUDIT_FILTER_OPENED_BY),
        COMPLETED: t(AuditFilterTranslations.AUDIT_FILTER_COMPLETED_BY),
        REVIEWED: t(AuditFilterTranslations.AUDIT_FILTER_REVIEWED_BY),
        CLOSED: t(AuditFilterTranslations.AUDIT_FILTER_CLOSED_BY),
    };

    const handleInputChange = (values: Partial<AuditGridSFModel>) =>
        setInternalFilterData((prev) => ({ ...prev, ...values }));

    const handleClickOutside = () => {
        if (!isOpen) return;
        handleInputChange(filterData);
        onCancel && onCancel();
    };

    const handleReset = () => {
        handleInputChange({
            ...initialFilterData,
            companyName: session?.workingCompany?.companyName,
        });
    };

    const handleSave = () => onSave && onSave(internalFilterData);

    const getUsers = async (openDate?: Date, openDate2?: Date, closeDate?: Date, CloseDate2?: Date) => {
        setIsUserLoading(true);
        const extraParams = new URLSearchParams();

        if (!!closeDate) extraParams.append("closeDate", getDateFormattedSelects(closeDate));
        if (!!closeDate) extraParams.append("closeDate2", getDateFormattedSelects(CloseDate2));
        if (!!openDate) extraParams.append("openDate", getDateFormattedSelects(openDate));
        if (!!openDate2) extraParams.append("openDate2", getDateFormattedSelects(openDate2));

        extraParams.append("status", currentTab);
        if (!!internalFilterData.companyId?.length && internalFilterData.companyId != "-1")
            extraParams.append("CompanyId", internalFilterData.companyId || "");

        const { data, status } = await QuaSelectorService.GetAuditInstanceUsers({
            extraParams: extraParams.toString(),
        });
        setIsUserLoading(false);
        setUserOptions(status() ? data : []);
    };

    const handleOpenDateChange = (openDate?: Date, openDate2?: Date) => {
        const isAllTwoDatesValid = !!openDate && !!openDate2;
        handleInputChange({
            openDate,
            openDate2,
            assignedTo: isAllTwoDatesValid ? "-1" : internalFilterData.assignedTo,
        });
        if (!openDate || !openDate2) return;

        getUsers(openDate, openDate2);
    };

    const handleClosedDateChange = (closedDate?: Date, closedDate2?: Date) => {
        const isAllTwoDatesValid = !!closedDate && !!closedDate2;
        handleInputChange({
            closedDate,
            closedDate2,
            assignedTo: isAllTwoDatesValid ? "-1" : internalFilterData.assignedTo,
        });
        if (!closedDate || !closedDate2) return;

        getUsers(closedDate, closedDate2);
    };

    const companyOptions: OptionModel[] = [
        { label: translateCustomerTypeKeys(TranslationKeys.ALL_COMPANIES, { firstReplace: "PLURAL" }), value: "-1" },
        ...(session?.user.companies?.map(({ companyName, companyId, logoUrl }) => ({
            label: companyName || "",
            value: String(companyId),
            initials: getInitials(companyName || ""),
            profilePictureURL: logoUrl,
        })) || []),
    ];

    useEffect(() => {
        if (!isOpen) return;
        getUsers(
            internalFilterData.openDate,
            internalFilterData.openDate2,
            internalFilterData.closedDate,
            internalFilterData.closedDate2
        );
    }, [isOpen]);

    return (
        <SecondaryFilter
            isOpen={isSecondaryFilterOpen}
            onClickOutside={handleClickOutside}
            onCancel={handleClickOutside}
            onReset={handleReset}
            onSave={handleSave}
            showResetFilter={!isEqual(initialFilterData, internalFilterData)}
            variant={variant}
        >
            <SelectOptions
                label={translateCompany("PLURAL")}
                selectedValue={internalFilterData.companyId}
                placeholder={t(TranslationCommon.SELECT_OPTION)}
                options={companyOptions}
                onChange={({ label, value }) => handleInputChange({ companyName: t(label), companyId: value })}
                isMulti={false}
            />
            <DateRangePicker
                startDate={internalFilterData.openDate}
                endDate={internalFilterData.openDate2}
                onChange={([openDate, openDate2]) => handleOpenDateChange(openDate, openDate2)}
                label={t(AuditFilterTranslations.AUDIT_FILTER_START_DATE)}
                showMoreOptions
            />
            {currentTab === "CLOSED" && (
                <DateRangePicker
                    startDate={internalFilterData.closedDate}
                    endDate={internalFilterData.closedDate2}
                    onChange={([closedDate, closedDate2]) => handleClosedDateChange(closedDate, closedDate2)}
                    label={t(AuditFilterTranslations.AUDIT_FILTER_CLOSED_DATE)}
                    showMoreOptions
                />
            )}
            <SelectOptions
                label={userLabel[currentTab]}
                selectedValue={internalFilterData.assignedTo}
                placeholder={t(TranslationCommon.SELECT_OPTION)}
                options={userOptions}
                onChange={({ value }) => handleInputChange({ assignedTo: value })}
                isMulti={false}
                isLoading={isUserLoading}
            />
        </SecondaryFilter>
    );
};
