import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Avatar } from "../Avatar/Avatar";
import { NotificationIconSize, NotificationIconType } from "./types";
import {
    faCircleInfo,
    faCircleExclamation,
    faTriangleExclamation,
    faCircleCheck,
    faStar,
    faCheckDouble,
    faSparkles,
    faListCheck,
    faMessageExclamation,
} from "@fortawesome/pro-regular-svg-icons";
import { NotificationVariantModel } from "app/models/NotificationVariant";

type NotificationIconProps = {
    variant?: NotificationVariantModel;
    type?: NotificationIconType;
    size?: NotificationIconSize;
    icon?: IconProp;
    profilePictureURL?: string;
    nameInitials?: string;
    userId?: number;
    ariaLabel?: string;
    iconTitle?: string;
};

export const NotificationIcon: FC<NotificationIconProps> = ({
    variant = "primary",
    type = "notification",
    size = "md",
    icon,
    profilePictureURL,
    nameInitials,
    userId,
    ariaLabel,
    iconTitle = "notification title",
}) => {
    const iconVariant: Record<NotificationVariantModel, IconProp> = {
        danger: faCircleExclamation,
        warning: faTriangleExclamation,
        success: faCircleCheck,
        primary: faCircleInfo,
        gray: faCircleInfo,
        "star-danger": faStar,
        "star-info": faStar,
        "star-yellow": faStar,
        "sporadic-task": faListCheck,
        "audit-report": faCheckDouble,
        "patch-notes": faSparkles,
        "audit-nc": faTriangleExclamation,
        "issue-task-temp": faCircleExclamation,
        "issue-task": faCircleExclamation,
        teal: faMessageExclamation,
    };

    return (
        <div
            className={`notificationIcon notificationIcon--${type} notificationIcon--${type}--${variant} notificationIcon--${type}--${size} notificationIcon--${type}--${variant}--${size}`}
            aria-label={ariaLabel}
        >
            {profilePictureURL || nameInitials ? (
                <Avatar
                    nameInitials={nameInitials}
                    colorId={userId || 1}
                    img={profilePictureURL}
                    avatarName={nameInitials}
                />
            ) : (
                <FontAwesomeIcon
                    icon={icon ?? iconVariant[variant]}
                    title={iconTitle}
                    className={`notificationIcon__icon--${variant} notificationIcon__icon--${type} notificationIcon__icon--${size}`}
                />
            )}
        </div>
    );
};
