import { FC, useState, MouseEvent } from "react";
import { useWindowSize } from "usehooks-ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightAndDownLeftFromCenter } from "@fortawesome/pro-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Zoom } from "swiper";
import { SlideItem } from "../Slider/SlideItem/SlideItem";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/zoom";
import { screenSize } from "app/shared/Constants";

type TaskInstancePhotos = {
    photo: string;
    badgeTitle: string;
};

type TaskPillPhotoProps = {
    taskInstancePhotos: TaskInstancePhotos[];
    onClick?: () => void;
    showZoom?: boolean;
    onClickMagnifyGlass?: () => void;
    onSlideChange?: (item: number) => void;
    isTaskDetail?: boolean;
    showPhotoCounter?: boolean;
    currentIndex?: number;
    fullScreen?: boolean;
    showCarrousel?: boolean;
};

export const TaskPillPhoto: FC<TaskPillPhotoProps> = ({
    taskInstancePhotos,
    onClick,
    showZoom = true,
    onClickMagnifyGlass,
    onSlideChange,
    isTaskDetail,
    showPhotoCounter = true,
    currentIndex,
    fullScreen,
    showCarrousel,
}) => {
    const { width } = useWindowSize();
    const [currentImage, setCurrentImage] = useState<number>(currentIndex || 0);
    const [isZoomed, setIsZoomed] = useState<boolean>(false);

    const handleImageChange = (index: number) => {
        onSlideChange && onSlideChange(index);
        setCurrentImage(index + 1);
    };

    const handleZoom = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
        e.stopPropagation();
        onClickMagnifyGlass && onClickMagnifyGlass();
    };

    if (width >= screenSize.TABLET && !isTaskDetail && !showCarrousel) {
        return (
            <div className={`singleImage${fullScreen ? "--fullScreen" : ""}`}>
                <span className="singleImage__total">{taskInstancePhotos.length}</span>
                <img
                    className={`singleImage__img${fullScreen ? "--fullScreen" : ""}`}
                    alt="Example.png"
                    src={taskInstancePhotos[0].photo}
                />
                {showZoom && (
                    <div className="zoomSlider zoomSlider--desktop" onClick={handleZoom}>
                        <FontAwesomeIcon className="zoomSlider__icon" icon={faUpRightAndDownLeftFromCenter} />
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="taskpill-slider">
            <Swiper
                spaceBetween={fullScreen ? "20" : "50"}
                onSlideChange={(swpier) => {
                    handleImageChange(swpier.realIndex);
                }}
                modules={[Pagination, Navigation, Zoom]}
                onClick={() => {
                    onClick && onClick();
                }}
                navigation={taskInstancePhotos.length > 1 && width >= screenSize.TABLET ? true : false}
                zoom={{ maxRatio: 3, minRatio: 1 }}
                slidesPerView={1}
                pagination={{ el: ".taskpill-slider__pagination" }}
                initialSlide={currentImage}
                onZoomChange={(_swipper, scale) => {
                    setIsZoomed(scale > 1);
                }}
            >
                {showZoom && (
                    <div className="zoomSlider" onClick={handleZoom}>
                        <FontAwesomeIcon className="zoomSlider__icon" icon={faUpRightAndDownLeftFromCenter} />
                    </div>
                )}
                {taskInstancePhotos.map(({ badgeTitle, photo }, i) => (
                    <SwiperSlide className={`sliderElement${fullScreen ? "--fullScreen" : ""}`} key={i}>
                        <div className="swiper-zoom-container">
                            <SlideItem
                                currentIndex={i + 1}
                                show={true}
                                src={photo}
                                totalImages={taskInstancePhotos?.length || 0}
                                showMagnifyGlass={showZoom}
                                onClickMagnifyGlass={onClickMagnifyGlass}
                                fullScreen={fullScreen}
                                badgeNumber={` ${i + 1} / ${taskInstancePhotos.length}`}
                                badgeTitle={!isZoomed ? badgeTitle : undefined}
                                isTaskDetail={isTaskDetail}
                                showPhotoCounter={showPhotoCounter && !isZoomed}
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="taskpill-slider__pagination" />
        </div>
    );
};
