import { AuditChecklistItem } from "../AuditChecklistItem/AuditChecklistItem";
import { AuditGroupCheckListItemInstanceValues } from "app/shared/types/AuditInstanceTypes";
import { AuditTranslation, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { FC } from "react";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { useAuditChecklistModal } from "./hooks/useAuditChecklistModal";
import { useTranslation } from "react-i18next";
import Spinner from "app/components_v2/Spinner/Spinner";
import { AuditGroupCheckListModalActions } from "../AuditGroupCheckListModalActions/AuditGroupCheckListModalActions";
import { AuditGroupCheckAllValues } from "../../types";
import { groupBy } from "app/helpers/Utilities/polyfill/groupBy";

type AuditChecklistModalProps = {
    checklistId: number;
    groupTitle: string;
    groupSubtitle: string;
    readonly: boolean;
    allowComplete: boolean;
    auditInstanceId: number;
    auditGroupName: string;
    onChange: (id: number, selectedValue: AuditGroupCheckListItemInstanceValues, fk_task: number | null) => void;
    onCheckAllChecklist: (value: AuditGroupCheckListItemInstanceValues) => void;
    onClose: () => void;
    onComplete: (checklistId: number) => void;
};

export const AuditChecklistModal: FC<AuditChecklistModalProps> = ({
    checklistId,
    onClose,
    groupTitle,
    groupSubtitle,
    onChange,
    readonly,
    onComplete,
    allowComplete,
    onCheckAllChecklist,
    auditInstanceId,
    auditGroupName,
}) => {
    const { t } = useTranslation();

    const {
        checklistItems,
        handleCheckListItemchange,
        handleSubmit,
        isLoading,
        isSubmiting,
        handleCheckAll,
        handleCheckAllOpenModal,
        isCheckAllModalOpen,
        selectedChecklistValue,
        setIsCheckAllModalOpen,
    } = useAuditChecklistModal({ checklistId, onChange, onClose, onComplete, onCheckAllChecklist });

    const percentatges = groupBy(checklistItems, ({ value }) => value);

    const checkAllTitle: Record<AuditGroupCheckAllValues, string> = {
        OK: t(AuditTranslation.AUDIT_CHECK_ALL_OK_TITLE),
        NA: t(AuditTranslation.AUDIT_CHECK_ALL_NA_TITLE),
    };

    const checkAllDesctription: Record<AuditGroupCheckAllValues, string> = {
        OK: t(AuditTranslation.AUDIT_CHECK_ALL_OK_DESC),
        NA: t(AuditTranslation.AUDIT_CHECK_ALL_NA_DESC),
    };

    return (
        <>
            {isCheckAllModalOpen && selectedChecklistValue && (
                <ConfirmModal
                    onConfirm={handleCheckAll}
                    onConfirmText={t(TranslationCommon.CONFIRM)}
                    description={checkAllDesctription[selectedChecklistValue]}
                    onClose={() => setIsCheckAllModalOpen(false)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    title={checkAllTitle[selectedChecklistValue]}
                    variant="appcc"
                />
            )}
            <GenericModal
                header={{
                    title: groupTitle,
                    subtitle: groupSubtitle,
                    onClose,
                    variant: "appcc",
                }}
                footer={
                    allowComplete ? (
                        <GenericModalFooter
                            confirmButton={{
                                text:
                                    checklistItems.every(({ value }) => value === "NA") ||
                                    checklistItems.every(({ value }) => value === "") ||
                                    (checklistItems.some(({ value }) => value === "") &&
                                        checklistItems.some(({ value }) => value === "NA"))
                                        ? t(AuditTranslation.AUDIT_NOT_APPLY)
                                        : t(TranslationKeys.COMPLETE_CHECKLIST),
                                onClick: handleSubmit,
                                variant: "appcc",
                            }}
                            closeButton={{ text: t(TranslationCommon.CLOSE), onClick: onClose, variant: "appcc" }}
                            loading={isSubmiting}
                        />
                    ) : undefined
                }
                size="lg"
                hideChildrenPadding
                allowCloseOnEscape={false}
            >
                <div className="auditChecklistModal">
                    {isLoading ? (
                        <div className="auditChecklistModal__spinner">
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            <div className="auditChecklistModal__form">
                                <AuditGroupCheckListModalActions
                                    percentatges={{
                                        completedPercentatge:
                                            ((percentatges.OK?.length || 0) * 100) / checklistItems.length,
                                        nonConformityPercentatge:
                                            ((percentatges.KO?.length || 0) * 100) / checklistItems.length,
                                        notApplyPercentatge:
                                            ((percentatges.NA?.length || 0) * 100) / checklistItems.length,
                                        notSpecifiedPercentatge:
                                            ((percentatges?.[""]?.length || 0) * 100) / checklistItems.length,
                                    }}
                                    readonly={readonly || !allowComplete}
                                    onCheckAll={handleCheckAllOpenModal}
                                />
                                {checklistItems
                                    .sort((a, b) => (a.order || 0) - (b.order || 0))
                                    .map((checklistitem) => {
                                        const { id } = checklistitem;
                                        return (
                                            <AuditChecklistItem
                                                {...checklistitem}
                                                key={id}
                                                onChange={handleCheckListItemchange}
                                                readonly={readonly}
                                                auditModelOptions={{
                                                    auditInstanceId,
                                                    auditInfo: {
                                                        auditGroupName,
                                                        auditChecklisItemtName: checklistitem.name,
                                                    },
                                                }}
                                            />
                                        );
                                    })}
                            </div>
                        </>
                    )}
                </div>
            </GenericModal>
        </>
    );
};
