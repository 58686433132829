import { Taskfield2Error, TaskfieldOptionsError } from "app/models/02-TAR/Task/EditTask";
import { DynamicTaskFieldOption } from "app/models/02-TAR/TaskWizard";
import { TranslationCommon } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

export const useDynamicFieldsErrorHandler = () => {
    const { t } = useTranslation();
    const taskfieldOptionsDropdown = (taskFieldOptions: DynamicTaskFieldOption[]) => {
        return taskFieldOptions
            .map(({ label, id }) => ({
                errorMessage: !label.length ? t(TranslationCommon.INPUT_NOT_EMPTY) : "",
                id: id,
            }))
            .filter(({ errorMessage }) => errorMessage);
    };

    const filterTaskFieldsWithoutErrorMessage = ({ errorMessage, taskfieldOptions }: Taskfield2Error) => {
        const taskfieldOptionsWithErrorMessage = taskfieldOptions.filter(({ errorMessage }) => errorMessage);
        if (taskfieldOptionsWithErrorMessage.length === 0 && !errorMessage) return false;
        return true;
    };

    const filterTaskCheckListWithoutErrorMessage = ({ errorMessage }: TaskfieldOptionsError) => {
        if (errorMessage.length === 0) return false;
        return true;
    };

    return {
        taskfieldOptionsDropdown,
        filterTaskFieldsWithoutErrorMessage,
        filterTaskCheckListWithoutErrorMessage,
    };
};
