import { FC, MouseEvent, useRef, useState, CSSProperties } from "react";
import { IDropDownListItem } from "../../Dropdown/types";
import { useOnClickOutside } from "usehooks-ts";
import { NotificationIcon } from "../../NotificationIcon/NotificationIcon";
import {
    faFileAudio,
    faFileDoc,
    faFileExcel,
    faFilePdf,
    faFileVideo,
    faImage,
} from "@fortawesome/pro-regular-svg-icons";
import { DocumentalMoreOptions } from "../DocumentalMoreOptions/DocumentalMoreOptions";
import { DocumentalFileType } from "app/models/07-DOC/DocumentalFileType";
import { NotificationVariantModel } from "app/models/NotificationVariant";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type DocumentalFileProps = {
    moreOptionsItems?: IDropDownListItem[];
    date: string;
    type: DocumentalFileType;
    name: string;
    imageUrl?: string;
    onClick: () => void;
};

export const DocumentalFile: FC<DocumentalFileProps> = ({
    onClick,
    moreOptionsItems = [],
    date,
    name,
    type,
    imageUrl,
}) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
    const [documentalFileElement, setDocumentalFileElement] = useState<Element | null>(null);

    const handleClickMoreOptions = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
        e.stopPropagation();
        if (!isPopoverOpen) setIsPopoverOpen(true);
    };

    const notificationIconVariant: Record<DocumentalFileType, NotificationVariantModel> = {
        Excel: "success",
        Docx: "primary",
        Pdf: "danger",
        Image: "warning",
        Video: "warning",
        Audio: "gray",
        Document: "primary",
    };

    const notificationIconIcon: Record<DocumentalFileType, IconProp> = {
        Excel: faFileExcel,
        Docx: faFileDoc,
        Pdf: faFilePdf,
        Image: faImage,
        Video: faFileVideo,
        Audio: faFileAudio,
        Document: faFileDoc,
    };

    return (
        <div
            className="documentalFile"
            onMouseUp={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClick();
            }}
        >
            <div className="documentalFile__card">
                {!!imageUrl?.length && type === "Image" ? (
                    <img className="documentalFile__card__image" src={imageUrl} alt="Documental File" />
                ) : (
                    <NotificationIcon
                        variant={notificationIconVariant[type] || "primary"}
                        icon={notificationIconIcon[type] || faFileDoc}
                        size="md"
                    />
                )}
                {moreOptionsItems.filter(({ hidden }) => !hidden).length > 0 && (
                    <DocumentalMoreOptions
                        options={moreOptionsItems.map((item) => ({
                            ...item,
                            onClick: () => {
                                item.onClick && item.onClick();
                                setIsPopoverOpen(false);
                            },
                        }))}
                        onClick={handleClickMoreOptions}
                        isOpen={isPopoverOpen}
                        onResize={() => isPopoverOpen && setIsPopoverOpen(false)}
                        onScroll={() => isPopoverOpen && setIsPopoverOpen(false)}
                        onClickOutside={() => isPopoverOpen && setIsPopoverOpen(false)}
                        setElement={setDocumentalFileElement}
                        target={documentalFileElement}
                    />
                )}
            </div>
            <div className="documentalFile__body">
                <p className="documentalFile__name">{name}</p>
                <div className="documentalFile__info">
                    <p className="documentalFile__date">{date}</p>
                    <span className="documentalFile__divider">•</span>
                    <p className="documentalFile__type">{type}</p>
                </div>
            </div>
        </div>
    );
};
