import {
    AuditBoxModalErrors,
    AuditBoxModalPonderationFormValues,
    AuditBoxModalProps,
    AuditType,
    AuditTypeValues,
    ErrorAuditBoxModalPonderationsItem,
} from "./types/AuditBoxModalTypes";
import { AuditGroupTranslations, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { FC, FormEvent, useEffect, useState } from "react";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { INITIAL_AUDIT_BOX_MODAL_ERRORS } from "./constants/AuditBoxModalConstants";
import { Input } from "app/components_v2/__inputs";
import { useTranslation } from "react-i18next";
import { Divider } from "app/components_v2/Divider/Divider";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { multiplyAndFormat } from "app/helpers/Utilities/number/multiplyAndFormat";

export const AuditBoxModal: FC<AuditBoxModalProps> = ({
    isLoading,
    onSubmit,
    onCloseModal,
    type,
    value = "",
    ponderation,
    remainingPercentage,
    auditGroupList,
}) => {
    const { t } = useTranslation();

    const isSaveAuditGroup = type === "SAVE_AUDIT_GROUP";
    const isEditAudit = type === "EDIT_AUDIT";

    const roundTwoDecimals = (value: number) => {
        if (value % 1 !== 0) {
            return multiplyAndFormat(value, 100) / 100;
        }
        return value;
    };

    const calculateInitialPonderation = () => {
        return ponderation !== undefined && ponderation !== 0
            ? String(roundTwoDecimals(ponderation * 100))
            : remainingPercentage
              ? String(roundTwoDecimals(remainingPercentage))
              : "0";
    };

    const [name, setName] = useState<string>(value);
    const [ponderationPercentage, setPonderationPercentage] = useState<string>(calculateInitialPonderation());
    const [errors, setErrors] = useState<AuditBoxModalErrors>(INITIAL_AUDIT_BOX_MODAL_ERRORS);
    const [ponderationFormValues, setPonderationFormValues] = useState<AuditBoxModalPonderationFormValues[]>([]);

    const { errorName, errorPonderationList } = errors;

    const auditRecord: Record<AuditType, AuditTypeValues> = {
        SAVE_AUDIT: { title: t(TranslationKeys.NEW_AUDIT), inputLabel: t(TranslationKeys.AUDIT_NAME_LABEL) },
        EDIT_AUDIT: { title: t(TranslationKeys.EDIT_AUDIT), inputLabel: t(TranslationKeys.AUDIT_NAME_LABEL) },
        SAVE_AUDIT_GROUP: {
            title: t(AuditGroupTranslations.NEW_AUDIT_GROUP),
            inputLabel: t(AuditGroupTranslations.AUDIT_GROUP_NAME_LABEL),
        },
        EDIT_AUDIT_GROUP: {
            title: t(AuditGroupTranslations.EDIT_AUDIT_GROUP),
            inputLabel: t(AuditGroupTranslations.AUDIT_GROUP_NAME_LABEL),
        },
        SAVE_AUDIT_GROUP_CHECKLIST: {
            title: t(TranslationKeys.NEW_AUDIT_GROUP_CHECKLIST),
            inputLabel: t(TranslationKeys.AUDIT_GROUP_CHECKLIST_NAME_LABEL),
        },
        EDIT_AUDIT_GROUP_CHECKLIST: {
            title: t(TranslationKeys.EDIT_AUDIT_GROUP_CHECKLIST),
            inputLabel: t(TranslationKeys.AUDIT_GROUP_CHECKLIST_NAME_LABEL),
        },
    };

    const handleErorrChange = (values: Partial<AuditBoxModalErrors>) => setErrors((prev) => ({ ...prev, ...values }));

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!validate()) return;
        if (isSaveAuditGroup) {
            onSubmit(name);
            return;
        }

        if (isEditAudit) {
            onSubmit(
                name,
                undefined,
                auditGroupList?.map((agl) => {
                    const selectedPonderation = ponderationFormValues.find(({ id }) => id === agl.id);

                    return {
                        ...agl,
                        ponderation: selectedPonderation != null ? Number(selectedPonderation.value) : agl.ponderation,
                    };
                })
            );
            return;
        }
        onSubmit(name);
    };

    const validate = (): boolean => {
        handleErorrChange(INITIAL_AUDIT_BOX_MODAL_ERRORS);
        if (!name?.length) {
            handleErorrChange({ errorName: t(TranslationCommon.INPUT_NOT_EMPTY) });
            return false;
        }

        if (isEditAudit) {
            const ponderationErrorItem: ErrorAuditBoxModalPonderationsItem[] = ponderationFormValues.map(
                ({ id, value }) => {
                    let errorMessage = "";
                    const numberValue = Number(value);

                    if (!value.length) errorMessage = t(TranslationCommon.INPUT_NOT_EMPTY);
                    if (numberValue < 0) errorMessage = t(AuditGroupTranslations.AUDIT_GROUP_PONDERATION_NOT_NEGATIVE);
                    if (numberValue > 100)
                        errorMessage = t(AuditGroupTranslations.AUDIT_GROUP_PONDERATION_EXCEDED_ERROR).replace(
                            "{0}",
                            "100"
                        );

                    return { id, error: errorMessage };
                }
            );

            const hasErrorInPonderationItem = ponderationErrorItem.some(({ error }) => error.length > 0);
            const totalPonderation = parseFloat(
                ponderationFormValues.reduce((acc, { value }) => acc + Number(value), 0).toFixed(2)
            );
            const isTotalPonderationValid = totalPonderation <= 100.01 && totalPonderation >= 99.99;

            const errorTotalPonderation = !isTotalPonderationValid
                ? t(AuditGroupTranslations.AUDIT_GROUP_PONDERATION_NOT_100).replace("{0}", String(totalPonderation))
                : "";

            handleErorrChange({
                errorPonderationList: {
                    errorPonderations: !hasErrorInPonderationItem ? errorTotalPonderation : "",
                    errorPonderationsItem: ponderationErrorItem,
                },
            });

            return !hasErrorInPonderationItem && isTotalPonderationValid;
        }

        return true;
    };

    useEffect(() => {
        setPonderationPercentage(calculateInitialPonderation());
    }, [ponderation]);

    useEffect(() => {
        if (!auditGroupList?.length) {
            setPonderationFormValues([]);
            return;
        }
        setPonderationFormValues(
            () =>
                auditGroupList?.map(({ id, ponderation }) => ({
                    id,
                    value: String(multiplyAndFormat(ponderation, 100)),
                }))
        );
    }, [auditGroupList]);

    return (
        <GenericModal
            size="sm"
            header={{ title: auditRecord[type].title, onClose: onCloseModal, variant: "appcc" }}
            footer={
                <GenericModalFooter
                    confirmButton={{
                        text: t(TranslationCommon.SAVE),
                        form: "editAudit",
                        type: "submit",
                        variant: "appcc",
                    }}
                    closeButton={{ text: t(TranslationCommon.CANCEL), onClick: onCloseModal, variant: "appcc" }}
                    loading={isLoading}
                />
            }
        >
            <form id="editAudit" onSubmit={handleSubmit} className="auditBoxModal" noValidate>
                <Input
                    label={auditRecord[type].inputLabel}
                    placeholder={auditRecord[type].inputLabel}
                    value={name}
                    onChange={setName}
                    errorMessage={errorName}
                    focus
                />
                {isEditAudit && (
                    <>
                        <Divider />
                        {auditGroupList?.map(({ name, ponderation: itemPonderation, id }) => {
                            const selectedPonderation = ponderationFormValues.find((pfv) => pfv.id === id);
                            const { errorPonderationsItem } = errorPonderationList;
                            const selectedError = errorPonderationsItem.find((error) => error.id === id)?.error || "";
                            return (
                                <Input
                                    label={`${name} (${multiplyAndFormat(itemPonderation, 100)}%)`}
                                    placeholder={t(AuditGroupTranslations.AUDIT_GROUP_PONDERATION_LABEL)}
                                    value={selectedPonderation?.value || ""}
                                    onChange={(ponderationValue) => {
                                        setPonderationFormValues((prev) =>
                                            prev.map((ponderationFormValue) =>
                                                ponderationFormValue.id === id
                                                    ? { ...ponderationFormValue, value: ponderationValue }
                                                    : ponderationFormValue
                                            )
                                        );
                                    }}
                                    errorMessage={selectedError}
                                    type="number"
                                    key={id}
                                />
                            );
                        })}
                        {errorPonderationList.errorPonderations && (
                            <ErrorMessage errorMessage={errorPonderationList.errorPonderations} />
                        )}
                    </>
                )}
            </form>
        </GenericModal>
    );
};
