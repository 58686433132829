import { SelectCompanyModel } from "app/components_v2/Table/hooks/useSelectCompanyTable";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { createContext } from "react";
import { AuditHeaderButtons } from "../../AuditGrid/components/AuditHeader/types";

export type AuditGridContextModel = {
    currentTab: number;
    onChangeCurrentTab: (tab: number) => void;
    pageIndex: number;
    onChangePageIndex: (pageIndex: number) => void;
    sortField: string;
    sortDirection: SortedTypeModel;
    onSortChange: (sortF: string, sortD: SortedTypeModel) => void;
    selectCompany: SelectCompanyModel;
    secondaryFilterValues: AuditGridSFModel;
    onSFChange: (sfValues: Partial<AuditGridSFModel>) => void;
    currentFilterButton: AuditHeaderButtons;
    onChangeFilterButton: (filterButton: AuditHeaderButtons) => void;
    onCompanyChange: (value: Partial<SelectCompanyModel>) => void;
};

export type AuditGridSFModel = {
    query?: string;
    companyName?: string;
    companyId?: string;
    openDate?: Date;
    openDate2?: Date;
    closedDate?: Date;
    closedDate2?: Date;
    assignedTo: string;
};

export const AuditGridContext = createContext<AuditGridContextModel>({} as AuditGridContextModel);
