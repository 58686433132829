import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";

type validateTaskDetailDynamicFieldsReturn = {
    invalidFieldIds: number[];
    hasEmptyOptionals: boolean;
};
export const validateTaskDetailDynamicFields = (
    fields: TaskInstanceFieldModel[]
): validateTaskDetailDynamicFieldsReturn => {
    const invalidFieldIds: number[] = [];
    let hasEmptyOptionals: boolean = false;
    fields.forEach((field) => {
        if (!!field.asset2) return;

        if (!field.isRequired) {
            hasEmptyOptionals = true;
            return;
        }

        if (field.taskInstanceFieldValues.length === 0) {
            invalidFieldIds.push(field.id);
            return;
        }

        if (field.dynamicFieldType === "DATERANGE" && field.taskInstanceFieldValues[0].value.includes("undefined")) {
            invalidFieldIds.push(field.id);
            return;
        }

        if (field.dynamicFieldType === "DROPDOWN" || field.dynamicFieldType === "DROPDOWN_MULTIPLE") {
            if (!field.taskInstanceFieldValues.some((value) => value.value === "true")) invalidFieldIds.push(field.id);
            return;
        }

        if (
            !field.taskInstanceFieldValues.every(({ value }) => value !== "") &&
            field.dynamicFieldType !== "DROPDOWN_MULTIPLE_ASSET"
        ) {
            invalidFieldIds.push(field.id);
            return;
        }

        if (
            !field.taskInstanceFieldValues.every(({ value }) => value !== "") &&
            field.dynamicFieldType !== "DROPDOWN_MULTIPLE_ASSET"
        ) {
            invalidFieldIds.push(field.id);
            return;
        }

        if (
            !field.taskInstanceFieldValues.some(({ value }) => value === "true") &&
            field.dynamicFieldType === "DROPDOWN_MULTIPLE_ASSET"
        ) {
            invalidFieldIds.push(field.id);
            return;
        }
    });

    return { invalidFieldIds, hasEmptyOptionals };
};
