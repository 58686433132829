import { useSession } from "./useSession";
import { useDispatch } from "react-redux";
import { Actions } from "app/state/actions";
import { setWorkingEntity } from "app/helpers/BrowserStorage/LocalStorageHandler";
import { ChangeCompanyModel } from "app/models/ChangeCompanyModel";
import { UserCompanyModel } from "app/models/01-SEG/UserCompany/UserCompanyModel";
import { mergeProfileCompaniesAndDepartments } from "app/helpers/Company/companyHelper";

export const useChangeWorkingSession = () => {
    const session = useSession();
    const dispatch = useDispatch();

    const changeWorkingCompanyByDepartmentId = (selectedDepartmentId: number) => {
        const companies = mergeProfileCompaniesAndDepartments(
            session?.user?.companies,
            session?.user?.temporaryCompanies
        );
        if (!companies) return;

        let selectedCompany: UserCompanyModel | undefined = undefined;

        companies.forEach((company) => {
            const department = company.departments?.find(({ departmentId }) => departmentId === selectedDepartmentId);
            if (department !== undefined) {
                selectedCompany = company;
            }
        });

        if (!selectedCompany) return;

        const workingCompany: ChangeCompanyModel = {
            workingCompany: selectedCompany,
            workingDepartment: undefined,
            workingSubDepartment: undefined,
        };
        changeWorkingCompany(workingCompany);
    };

    const changeWorkingCompanyByCompanyId = (selectedCompanyId: number) => {
        const companies = mergeProfileCompaniesAndDepartments(
            session?.user?.companies,
            session?.user?.temporaryCompanies
        );
        if (!companies) return;
        const selectedCompany: UserCompanyModel | undefined = companies.find(
            ({ companyId }) => companyId === selectedCompanyId
        );
        if (!selectedCompany) return;

        const workingCompany: ChangeCompanyModel = {
            workingCompany: selectedCompany,
            workingDepartment: undefined,
            workingSubDepartment: undefined,
        };
        changeWorkingCompany(workingCompany);
    };

    const changeWorkingCompany = (workingCompany: ChangeCompanyModel) => {
        dispatch(Actions.changeCompany(workingCompany));
        setWorkingEntity(workingCompany);
    };

    return { changeWorkingCompany, changeWorkingCompanyByDepartmentId, changeWorkingCompanyByCompanyId };
};
