import { MultiProgressBarItemProp } from "app/components_v2/MultiProgressBar/MultiProgressBar";
import { AuditGroupCheckListItemInstanceModel } from "app/models/05-QUA/AuditInstanceModels/AuditGroupCheckListItemInstanceModel";
import { AuditGroupCheckListItemInstanceValues } from "app/shared/types/AuditInstanceTypes";

export const getAuditGroupChecklistProgressBarValues = (
    auditGroupCheckListItemInstances: AuditGroupCheckListItemInstanceModel[]
) => {
    const multiProgressBarItems: MultiProgressBarItemProp[] = [
        { color: "green", value: 0 },
        { color: "yellow", value: 0 },
        { color: "red", value: 0 },
        { color: "gray", value: 0 },
    ];

    const auditValueKeys: Record<AuditGroupCheckListItemInstanceValues, number> = {
        OK: 0,
        "": 1,
        KO: 2,
        NA: 3,
    };

    auditGroupCheckListItemInstances.forEach(({ value }) => {
        multiProgressBarItems[auditValueKeys[value]].value += 1;
    });

    return multiProgressBarItems;
};
