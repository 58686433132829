import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { ReviewWorkerListModel } from "../models";
import { TaskPillList } from "../../01-TAR-Dashboard/TaskPillList/TaskPillList";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons";
import { TaskDetailModal } from "../../01-TAR-Dashboard/TaskDetail/TaskDetailModal";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { TaskInstanceListDto } from "app/dtos/02-TAR/TaskInstance/TaskInstanceListDto";
import { hasPermission } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";

export type ReviewWorkerListProps = {
    page?: ReviewWorkerListModel;
    taskInstanceList: TaskInstanceListDto[];
    pageIndex: number;
    isLoading: boolean;
    total: number;
    onStarsValued: () => void;
    onChangePageIndex: (pi: number) => void;
    onStarsChange: () => void;
};

export const ReviewWorkerList: FC<ReviewWorkerListProps> = ({
    page,
    pageIndex,
    taskInstanceList,
    isLoading,
    total,
    onStarsValued,
    onChangePageIndex,
    onStarsChange,
}) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedTaskInstanceId, setSelectedTaskInstanceId] = useState<number>();
    const { t } = useTranslation();

    const handleTaskInstanceClick = (id: number) => {
        setSelectedTaskInstanceId(id);
        setIsModalOpen(true);
    };

    return (
        <>
            {!taskInstanceList.length && !isLoading ? (
                <div className="fullHeight">
                    <TableError icon={faListCheck} title={t(TranslationKeys.EMPTY_LIST_TASKI)} size="xl" />
                </div>
            ) : (
                <TaskPillList
                    isLoading={isLoading}
                    onDetailClick={handleTaskInstanceClick}
                    list={taskInstanceList}
                    total={total || 0}
                    onChangePageIndex={onChangePageIndex}
                    pageIndex={pageIndex}
                    isSupervisor={page !== "Pending"}
                    onStarsValued={onStarsValued}
                    paginate
                    editable={false}
                    hideFeedback
                    canBeDisabled
                />
            )}
            {isModalOpen && selectedTaskInstanceId && (
                <TaskDetailModal
                    taskInstanceId={String(selectedTaskInstanceId)}
                    onClose={() => setIsModalOpen(false)}
                    editTaskDetail={false}
                    isReviewPhotos={page !== "Pending"}
                    onStarsChange={onStarsChange}
                    allowCompleteTask={hasPermission(SecScreen.COMPLETE_TASK_REV)}
                />
            )}
        </>
    );
};
