import { FC } from "react";
import { OptionsOutsideSelect, TextArea } from "app/components_v2/__inputs";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { useTranslation } from "react-i18next";
import { useIssueHistoryReopen } from "./hooks/useIssueHistoryReopen";
import { issueHistoryIcon } from "../constants/issueHistoryConstants";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import { OpenCloseStatusCode } from "app/shared/types/OpenCloseStatusCode";
import { IssueTranslation, TranslationKeys } from "app/translation/translationKeys";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { Button } from "app/components_v2/__buttons/Button/Button";
import { IssueServiceType } from "app/shared/types/IssueTypes";

type IssueHistoryReopenProps = {
    actualStatus: OpenCloseStatusCode;
    id: number;
    variant?: TabHeaderVariants;
    onFinish: () => void;
    type?: IssueServiceType;
};

const formId = "issueHistoryReopen_form";

export const IssueHistoryReopen: FC<IssueHistoryReopenProps> = ({
    actualStatus,
    id,
    variant = "primary",
    onFinish,
    type = "ISSUE",
}) => {
    const { t } = useTranslation();

    const {
        correctiveMeassureOptions,
        errorsFormValues,
        formValues,
        isLoading,
        isModalVisible,
        isSubmitting,
        onAddCorrectiveMeasure,
        onDeleteCorrectiveMeasure,
        onSubmit,
        onChangeVisibilityModal,
        onClose,
        onInputChange,
    } = useIssueHistoryReopen({ actualStatus, id, onFinish, type });

    const { correctiveMeassures, description } = formValues;
    const { errorCorrectiveMeassures, errorDescription } = errorsFormValues;

    const title: Record<OpenCloseStatusCode, string> = {
        OPEN: t(IssueTranslation.ISSUE_HISTORY_CLOSE_ISSUE),
        CLOSED: t(IssueTranslation.ISSUE_HISTORY_REOPEN_ISSUE),
    };

    const hasPermissions: Record<OpenCloseStatusCode, boolean> = {
        OPEN: hasPermissionToEdit(SecScreen.CLOSE_ISSUE) || hasPermissionToEdit(SecScreen.AUDIT_INST_REV_NC),
        CLOSED: hasPermissionToEdit(SecScreen.REOPEN_ISSUE) || hasPermissionToEdit(SecScreen.AUDIT_INST_REV_NC),
    };

    return (
        <>
            {hasPermissions[actualStatus] && (
                <Button
                    text={title[actualStatus]}
                    iconLeft={issueHistoryIcon[actualStatus]}
                    onClick={() => onChangeVisibilityModal(true)}
                    variant={variant}
                />
            )}
            {isModalVisible && (
                <GenericModal
                    header={{ title: title[actualStatus], onClose: onClose, variant }}
                    footer={
                        <GenericModalFooter
                            confirmButton={{
                                text: title[actualStatus],
                                fullWidth: true,
                                iconLeft: issueHistoryIcon[actualStatus],
                                form: formId,
                                type: "submit",
                                variant,
                            }}
                            loading={isSubmitting}
                        />
                    }
                    size="sm"
                    allowCloseOnEscape
                >
                    <form id={formId} onSubmit={onSubmit} className="issueHistoryReopen">
                        <div className="issueHistoryReopen__container">
                            <TextArea
                                text={description}
                                onChange={(newDesc) => onInputChange({ description: newDesc })}
                                placeholder={t(IssueTranslation.ISSUE_HISTORY_COMMENT_PLACEHOLDER)}
                                errorMessage={errorDescription}
                                focus
                                maxLength={250}
                                showTranscription
                            />
                            {actualStatus === "OPEN" && (
                                <OptionsOutsideSelect
                                    onChange={onAddCorrectiveMeasure}
                                    onDeleteItem={onDeleteCorrectiveMeasure}
                                    options={correctiveMeassureOptions}
                                    values={correctiveMeassures}
                                    label={t(TranslationKeys.CORRECTIVE_MEASSURE_LABEL)}
                                    isSearchable
                                    placeholder={t(TranslationKeys.CORRECTIVE_MEASSURE_PLACEHOLDER)}
                                    isLoading={isLoading}
                                    tagOptions={{ clamp: 2, size: "auto" }}
                                    errorMessage={errorCorrectiveMeassures}
                                />
                            )}
                        </div>
                    </form>
                </GenericModal>
            )}
        </>
    );
};
