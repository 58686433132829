import { FC } from "react";
import { DynamicFieldItem } from "./DynamicFieldItem/DynamicFieldItem";
import { DynamicFieldItemDropdown } from "./DynamicFieldItemDropdown/DynamicFieldItemDropdown";
import { Taskfield2Error } from "app/models/02-TAR/Task/EditTask";
import { isDropdownDynamicField } from "app/helpers/Task/dynamicFields/dynamicFieldHelper";
import { DynamicFieldsModel } from "app/models/02-TAR/TaskWizard";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { DynamicFieldItemDropdownMultiple } from "./DynamicFieldItemDropdownMultiple/DynamicFieldItemDropdownMultiple";

type DynamicFieldsProps = {
    isLoadingDynamicFields: boolean;
    dynamicFields: DynamicFieldsModel[];
    errors: Taskfield2Error[];
    focus?: boolean;
    disabled?: boolean;
    onInputChange: (value: string, id: string) => void;
    onCheckboxChange: (value: boolean, id: string) => void;
    onDeleteDynamicField: (id: string) => void;
    onDeleteDynamicDropDownField: (id: string, itemId: string) => void;
    onChangeDynamicDropDownField: (id: string, itemId: string, value: string) => void;
    onAddNewDynamicDropDownField: (id: string) => void;
    onChangeDynamicDropDownAssetField: (selectedValue: OptionModel, id: string) => void;
    onDeleteDynamicDropDownAssetField: (id: string, itemId: string) => void;
};

export const DynamicFields: FC<DynamicFieldsProps> = ({
    errors,
    focus,
    dynamicFields,
    onInputChange,
    onCheckboxChange,
    onDeleteDynamicField,
    onDeleteDynamicDropDownField,
    onChangeDynamicDropDownField,
    onAddNewDynamicDropDownField,
    onChangeDynamicDropDownAssetField,
    onDeleteDynamicDropDownAssetField,
    disabled,
}) => {
    const dynamicFieldsFiltered = dynamicFields.filter(({ isDeleted }) => !isDeleted);

    if (!dynamicFieldsFiltered.length) return null;

    return (
        <div className={"dynamicFields__container"}>
            {dynamicFieldsFiltered.map(
                ({
                    dynamicFieldsType,
                    id,
                    isRequired,
                    label,
                    taskFieldOptions,
                    dropDownMultipleOptions,
                    dropDownMultipleValues,
                    isDisabled,
                }) =>
                    isDropdownDynamicField(dynamicFieldsType) ? (
                        <DynamicFieldItemDropdown
                            isRequired={isRequired}
                            onChange={(value) => onInputChange(value, id)}
                            onCheckboxChange={(value) => onCheckboxChange(value, id)}
                            value={label}
                            id={id}
                            key={id}
                            onDelete={() => onDeleteDynamicField(id)}
                            onDeleteItem={(itemId) => onDeleteDynamicDropDownField(id, itemId)}
                            onChangeItem={(itemId, value) => onChangeDynamicDropDownField(id, itemId, value)}
                            onAddNewItem={() => onAddNewDynamicDropDownField(id)}
                            items={taskFieldOptions}
                            hideAddNew={taskFieldOptions.length >= 10}
                            error={errors.find((error) => error.id === id)}
                            focus={focus}
                            disabled={disabled || !!isDisabled}
                            dynamicFieldType={dynamicFieldsType}
                        />
                    ) : dynamicFieldsType === "DROPDOWN_MULTIPLE_ASSETS_RANGE" ? (
                        <DynamicFieldItemDropdownMultiple
                            id={id}
                            key={id}
                            checked={isRequired}
                            options={dropDownMultipleOptions}
                            values={dropDownMultipleValues}
                            disabled={disabled || !!isDisabled}
                            errorMessage={errors.find((x) => x.id === id)?.errorMessage}
                            onDeleteItem={() => onDeleteDynamicField(id)}
                            onChange={(selectedValue) => onChangeDynamicDropDownAssetField(selectedValue, id)}
                            onDeleteValue={(itemId) => onDeleteDynamicDropDownAssetField(id, itemId)}
                        />
                    ) : (
                        <DynamicFieldItem
                            checked={isRequired}
                            dynamicFieldType={dynamicFieldsType}
                            onChange={(value) => onInputChange(value, id)}
                            onChangeCheckbox={(value) => onCheckboxChange(value, id)}
                            value={label}
                            id={id}
                            key={id}
                            onDeleteItem={() => onDeleteDynamicField(id)}
                            errorMessage={errors.find((x) => x.id === id)?.errorMessage}
                            focus={focus}
                            disabled={disabled || !!isDisabled}
                        />
                    )
            )}
        </div>
    );
};
