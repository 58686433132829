import { IssueClassificationModel } from "app/models/05-QUA/IssueClassificationModels/IssueClassificationModel";
import { FC } from "react";
import { useIssueClassificationModal } from "./hooks/useIssueClassificationModal";
import { ActionType } from "app/models/FormComponentsModel";
import { useTranslation } from "react-i18next";
import { IssueTranslation, TranslationCommon } from "app/translation/translationKeys";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { GenericModalFooter } from "../base/GenericModal/GenericModalFooter/GenericModalFooter";
import { Input } from "app/components_v2/__inputs";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";

export type IssueClassificationModalProps = {
    variant?: TabHeaderVariants;
    selectedIssueClassification: IssueClassificationModel | null;
    onAddNew: (issueClassification: IssueClassificationModel) => void;
    onEdit: (issueClassification: IssueClassificationModel) => void;
    onCancel: () => void;
};
const formId = "IssueClassificationModal";

export const IssueClassificationModal: FC<IssueClassificationModalProps> = ({
    onAddNew,
    onCancel,
    onEdit,
    selectedIssueClassification,
    variant = "primary",
}) => {
    const { t } = useTranslation();

    const { action, errorMessage, isLoading, name, onNameChange, onSubmit } = useIssueClassificationModal({
        onAddNew,
        onEdit,
        selectedIssueClassification,
    });

    const getTitle = () => {
        const title: Record<ActionType, string> = {
            create: t(IssueTranslation.NEW_ISSUE_CLASSIFICATION),
            edit: t(IssueTranslation.EDIT_ISSUE_CLASSIFICATION),
        };
        return title[action];
    };

    const getConfirmButton = () => {
        const confirmButtonText: Record<ActionType, string> = {
            create: t(TranslationCommon.CREATE),
            edit: t(TranslationCommon.SAVE),
        };

        return confirmButtonText[action];
    };
    return (
        <GenericModal
            size="sm"
            header={{ title: getTitle(), onClose: onCancel, variant }}
            footer={
                <GenericModalFooter
                    confirmButton={{
                        text: getConfirmButton(),
                        form: formId,
                        type: "submit",
                        variant,
                    }}
                    closeButton={{
                        text: t(TranslationCommon.CANCEL),
                        buttonType: "secondary",
                        onClick: onCancel,
                        variant,
                    }}
                    loading={isLoading}
                />
            }
        >
            <form onSubmit={onSubmit} id={formId}>
                <Input
                    onChange={onNameChange}
                    label={t(IssueTranslation.ISSUE_CLASSIFICATION)}
                    placeholder={t(IssueTranslation.ISSUE_CLASSIFICATION)}
                    value={name}
                    errorMessage={errorMessage}
                    focus
                />
            </form>
        </GenericModal>
    );
};
