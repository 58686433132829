import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { DocumentalFilePageHeader } from "./DocumentalFilePageHeader/DocumentalFilePageHeader";
import { useEffect, useRef, useState } from "react";
import { DocumentalFolderDto } from "app/dtos/07-DOC/documentalFolder/DocumentalFolderDto";
import { useNavigate, useParams } from "react-router-dom";
import DocumentalFolderService from "app/services/07-DOC/DocumentalFolderService";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import DocumentalFileService from "app/services/07-DOC/DocumentalFileService";
import { PrivatePaths, SecScreen } from "app/shared/Constants";
import { DocumentalFileListDto } from "app/dtos/07-DOC/documentalFile/DocumentalFileListDto";
import { DocumentalFileTranslations, DragFileTranslations, TranslationCommon } from "app/translation/translationKeys";
import { hasPermissionToAdd, hasPermissionToDelete } from "app/routes/HelperRoleBasedAccess";
import { AddNewButton } from "app/components_v2/__buttons/AddNewButton/AddNewButton";
import { faArrowUpFromBracket, faFile, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { ListEmptyState } from "app/components_v2/ListEmptyState/ListEmptyState";
import Pagination from "app/components_v2/Pagination/Pagination";
import { DocumentalFile } from "app/components_v2/Documental/DocumentalFile/DocumentalFile";
import { DocumentalFileSortField } from "../models/DocumentalFilePageModel";
import {
    ALLOWED_DOCUMENTAL_FILE_EXTENSIONS,
    INITIAL_DOCUMENTAL_FILE_SORT_DIRECTION,
    INITIAL_DOCUMENTAL_FILE_SORT_FIELD,
    MAX_FILES_UPLOAD_AT_THE_SAME_TIME,
} from "../constants/DocumentalFilePageConstants";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { CreateDocumentalFileDto } from "app/dtos/07-DOC/documentalFile/CreateDocumentalFileDto";
import { compressImage } from "app/helpers/Media/compress/compressImage";
import { convertFileToBase64 } from "app/helpers/Media/converter/webPConverter";
import { getDocumentalFileTypeByExtension } from "../helpers/getDocumentalFileTypeByExtension";
import { v4 } from "uuid";
import { donwloadFile } from "app/helpers/Utilities/download/donwloadFile";
import { PAGE_SIZE_DOCUMENTAL_FOLDER } from "../constants/DocumentalFolderPageConstants";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { DocumentalFileSkelleton } from "app/components_v2/__skelletons/DocumentalFileSkelleton/DocumentalFileSkelleton";
import { getDateWithTodayOrTomorrow } from "app/helpers/Utilities/date/Date.utilities";

type IGetData = {
    pi?: number;
    q?: string;
    sortF?: DocumentalFileSortField;
    sortD?: SortedTypeModel;
};
export const DocumentalFilePage = () => {
    const { t } = useTranslation();
    const nav = useNavigate();
    const { handleToast, removeToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const { id } = useParams<{ id: string }>();
    const fileRef = useRef<HTMLInputElement | null>(null);

    const [data, setData] = useState<DocumentalFileListDto[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [isListLoading, setIsListLoading] = useState<boolean>(true);
    const [selectedFolder, setSelectedFolder] = useState<DocumentalFolderDto | null>(null);
    const [isFolderLoading, setIsFolderLoading] = useState<boolean>(true);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [query, setQuery] = useState<string>("");
    const [sortField, setSortField] = useState<DocumentalFileSortField>(INITIAL_DOCUMENTAL_FILE_SORT_FIELD);
    const [sortDirection, setSortDirection] = useState<SortedTypeModel>(INITIAL_DOCUMENTAL_FILE_SORT_DIRECTION);
    const [selectedFile, setSelectedFile] = useState<DocumentalFileListDto | null>(null);
    const [isDeleteFileModalOpen, setIsDeleteFileModalOpen] = useState<boolean>(false);
    const [isDeleteFileModalLoading, setIsDeleteFileModalLoading] = useState<boolean>(false);

    const getFolder = async () => {
        setIsFolderLoading(true);
        const { data: folderData, status, getParsedError } = await DocumentalFolderService.GetOne(Number(id));

        setIsFolderLoading(false);

        if (!status()) {
            nav(`/${PrivatePaths.DOCUMENTAL_MANAGER}`);
            handleToast({ title: t(getErrorMessage(getParsedError())), variant: "danger", type: "alert" });
            return;
        }

        setSelectedFolder(folderData);
    };

    const getData = async ({ pi, q, sortD, sortF }: IGetData = {}) => {
        setIsListLoading(true);
        const piFetch = pi !== undefined ? pi : pageIndex - 1;
        const queryFetch = q !== undefined ? q : query;
        const sortDFetch = sortD !== undefined ? sortD : sortDirection;
        const sortFFetch = sortF !== undefined ? sortF : sortField;

        const {
            data: fileData,
            status,
            getParsedError,
        } = await DocumentalFileService.GetData(Number(id), {
            pageIndex: piFetch,
            query: queryFetch,
            pageSize: PAGE_SIZE_DOCUMENTAL_FOLDER,
            sortField: sortFFetch,
            sortDirection: sortDFetch,
        });

        setIsListLoading(false);
        if (!status()) {
            handleToast({ title: t(getErrorMessage(getParsedError())), variant: "danger", type: "alert" });
            return;
        }

        setData(fileData.list);
        setTotal(fileData.count);
    };

    const onChangePageIndex = async (pi: number) => {
        setPageIndex(pi);
        await getData({ pi: pi - 1 });
    };

    const onSearch = async (q: string) => {
        setPageIndex(1);
        setQuery(q);
        await getData({ pi: 0, q });
    };

    const onChangeOrderBy = async (sortF: DocumentalFileSortField, sortD: SortedTypeModel) => {
        setSortField(sortF);
        setSortDirection(sortD);
        await getData({ sortD, sortF });
    };

    const onUploadFiles = async (files: FileList | null) => {
        if (!files?.length) return;
        const chosenFiles: File[] = Array.from(files);
        if (chosenFiles.length > MAX_FILES_UPLOAD_AT_THE_SAME_TIME) {
            handleToast({
                title: t(DocumentalFileTranslations.DOCUMENTAL_FILE_MAX_ALLOWED_UPLOAD_AT_THE_SAME_TIME),
                type: "alert",
                variant: "warning",
            });
            return;
        }
        const dragFileImages = filterFileTypes(chosenFiles);
        if (!dragFileImages.length) {
            handleToast({ title: t(DragFileTranslations.INVALID_FILE_TYPE), type: "alert", variant: "warning" });
            return;
        }

        const filesToUpload: CreateDocumentalFileDto[] = [];

        const toastId = v4();
        handleToast({
            title:
                dragFileImages.length === 1
                    ? t(DocumentalFileTranslations.DOCUMENTAL_FILE_UPLOADING_S)
                    : t(DocumentalFileTranslations.DOCUMENTAL_FILE_UPLOADING_P),
            id: toastId,
            isLoading: true,
        });

        for (const file of dragFileImages) {
            const extension = file.name.split(".").pop()?.toLowerCase();
            if (!extension) continue;
            const type = getDocumentalFileTypeByExtension(extension);
            if (!type) continue;

            if (type !== "Image") {
                const fileBase64 = await convertFileToBase64(file);
                if (!fileBase64) return;

                filesToUpload.push({
                    customerInstanceId: 0,
                    dimension: "",
                    extension: `.${extension}`,
                    fileSize: file.size,
                    name: file.name,
                    type,
                    fK_File: 0,
                    frontId: v4(),
                    imageBase64Content: fileBase64,
                });
                continue;
            }
            const attachment = await compressImage({ file });
            const base64DataUrl = await convertFileToBase64(attachment);
            if (!base64DataUrl) continue;

            filesToUpload.push({
                customerInstanceId: 0,
                dimension: "",
                extension: `.${extension}`,
                fileSize: file.size,
                name: file.name,
                type,
                fK_File: 0,
                frontId: v4(),
                imageBase64Content: base64DataUrl,
            });
        }

        const { status, getParsedError } = await DocumentalFileService.UploadFiles(Number(id), filesToUpload);
        removeToast(toastId);
        if (!status()) {
            handleToast({ title: t(getErrorMessage(getParsedError())), variant: "danger", type: "alert" });
            return;
        }

        handleToast({
            title:
                dragFileImages.length === 1
                    ? t(DocumentalFileTranslations.DOCUMENTAL_FILE_UPLOAD_SUCCESS_S)
                    : t(DocumentalFileTranslations.DOCUMENTAL_FILE_UPLOAD_SUCCESS_P),
            variant: "success",
            type: "alert",
        });
        await getData();
    };

    const filterFileTypes = (files: File[]) => {
        return files.filter((file) => {
            const extension = file.name.split(".").pop()?.toLowerCase();
            if (!extension) return false;

            return Object.values(ALLOWED_DOCUMENTAL_FILE_EXTENSIONS).some((extList) => extList.includes(extension));
        });
    };

    const onClickDeleteFile = (file: DocumentalFileListDto) => {
        setSelectedFile(file);
        setIsDeleteFileModalOpen(true);
    };

    const onDeleteFolder = async () => {
        if (selectedFile === null) return;
        setIsDeleteFileModalLoading(true);
        const { status, getParsedError } = await DocumentalFileService.Delete(selectedFile.id);
        setIsDeleteFileModalLoading(false);
        if (!status()) {
            handleToast({ title: t(getErrorMessage(getParsedError())), variant: "danger", type: "alert" });
            return;
        }

        setIsDeleteFileModalOpen(false);
        setSelectedFile(null);
        await getData();
    };

    useEffect(() => {
        getFolder();
        getData();
    }, []);

    return (
        <>
            {isDeleteFileModalOpen && selectedFile != null && (
                <ConfirmModal
                    title={t(DocumentalFileTranslations.DOCUMENTAL_FILE_DELETE_TITLE)}
                    description={t(DocumentalFileTranslations.DOCUMENTAL_FILE_DELETE_DESCRIPTION).replace(
                        "{0}",
                        selectedFile.name
                    )}
                    onConfirm={onDeleteFolder}
                    onConfirmText={t(TranslationCommon.DELETE)}
                    isLoading={isDeleteFileModalLoading}
                    onClose={() => setIsDeleteFileModalOpen(false)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    type="delete"
                />
            )}
            <PageContainer
                fullHeight
                header={
                    <DocumentalFilePageHeader
                        isLoading={isFolderLoading}
                        name={selectedFolder?.name || ""}
                        onChangeQuery={setQuery}
                        onSearch={onSearch}
                        query={query}
                        onChangeOrderBy={onChangeOrderBy}
                        sortDirection={sortDirection}
                        sortField={sortField}
                    />
                }
            >
                <div className="documentalFilePage">
                    <div className="documentalFilePage__header">
                        <p className="documentalFilePage__totalFiles">
                            {`${total} ${
                                total === 1
                                    ? t(DocumentalFileTranslations.DOCUMENTAL_FILE_TOTAL_S)
                                    : t(DocumentalFileTranslations.DOCUMENTAL_FILE_TOTAL_P)
                            }`}
                        </p>
                        {hasPermissionToAdd(SecScreen.DOCUMENTAL_FOLDER) && (
                            <>
                                <AddNewButton
                                    onClick={() => fileRef.current && fileRef.current.click()}
                                    icon={faArrowUpFromBracket}
                                >
                                    {t(DocumentalFileTranslations.DOCUMENTAL_FILE_NEW_FILE)}
                                </AddNewButton>
                                <input
                                    type="file"
                                    onChange={(e) => onUploadFiles(e.target.files)}
                                    value=""
                                    hidden={true}
                                    ref={fileRef}
                                    className="mainInputFile__input"
                                    multiple
                                />
                            </>
                        )}
                    </div>
                    {isListLoading ? (
                        <DocumentalFileSkelleton quantity={10} />
                    ) : total === 0 ? (
                        <ListEmptyState icon={faFile} title={t(TranslationCommon.NO_RESULTS)} />
                    ) : (
                        <div className="documentalFilePage__container">
                            <div className="documentalFilePage__list">
                                {data.map(
                                    ({
                                        documentalFileUrl,
                                        id: fileId,
                                        name,
                                        type,
                                        createdDate,
                                        customerInstanceId,
                                        extension,
                                        fK_DocumentalFolder,
                                    }) => (
                                        <DocumentalFile
                                            date={getDateWithTodayOrTomorrow(new Date(createdDate))}
                                            name={name}
                                            onClick={() => donwloadFile(documentalFileUrl, name)}
                                            type={type}
                                            imageUrl={documentalFileUrl}
                                            moreOptionsItems={[
                                                {
                                                    text: t(TranslationCommon.DELETE),
                                                    onClick: () =>
                                                        onClickDeleteFile({
                                                            documentalFileUrl,
                                                            id: fileId,
                                                            name,
                                                            type,
                                                            createdDate,
                                                            customerInstanceId,
                                                            extension,
                                                            fK_DocumentalFolder,
                                                        }),
                                                    hidden: !hasPermissionToDelete(SecScreen.DOCUMENTAL_FOLDER),
                                                    icon: faTrash,
                                                },
                                            ]}
                                            key={fileId}
                                        />
                                    )
                                )}
                            </div>
                            <Pagination
                                onClick={onChangePageIndex}
                                pageIndex={pageIndex}
                                total={total}
                                pageSize={PAGE_SIZE_DOCUMENTAL_FOLDER}
                            />
                        </div>
                    )}
                </div>
            </PageContainer>
        </>
    );
};
